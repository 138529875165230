<template>
  <v-layout height="600" class="">    
    <v-layout v-if="false"> 
        <mbs-page-spinner :show="true" />  
    </v-layout>     
    <v-container v-else grid-list-lg> 
        <v-layout row wrap>  
            <!-- //others -->
            <v-flex xs6 sm4 lg3 v-for="(link, index) in MyLinks" :key="index">
                <v-hover>
                    <template v-slot="{ hover  }">
                        <!-- :to="link.link" -->
                        <v-card  
                           
                            :elevation="hover ?12:''"
                            hover  rounded="lg"
                            height="100%"> 
                            <div v-if="MBS.actions.TEXT_UP(link.name) === MBS.actions.TEXT_UP('My profile')">
                                <v-img v-if="ud?ud.photoURL:false" 
                                    aspect-ratio="1.8" 
                                    :src="ud.photoURL"
                                    lazy-src="https://picsum.photos/10/6?image=3"  >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                                /> 
                                        </v-row>
                                    </template>
                                </v-img>
                                    
                                <v-responsive  v-else   
                                    class="primary darken-1"
                                    aspect-ratio="1.8" > 
                                    <v-layout fill-height justify-center align-center>
                                        <v-avatar
                                            size="80"
                                            color="primary darken-2" > 
                                            <span class="white--text headline">
                                                {{us.displayName ? us.displayName[0] : ""}}
                                            </span>  
                                        </v-avatar>
                                    </v-layout>
                                </v-responsive> 
                            </div> 

                            <!--Not my profile--> 
                            <v-responsive v-else aspect-ratio="1.5"  >
                                <v-card height="100%" :color="link.image_background_color" tile>
                                    <v-layout v-if="false" class="ma-0" align-center justify-center fill-height > 
                                        <v-card color="transparent" :width="link.image_size?link.image_size+'%':'50%'" tile flat>
                                            <v-img   
                                                position="center"
                                                :src="link.image?link.image:MBS.data.noImg" > 
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>  
                                        </v-card>
                                    </v-layout>
                                    <v-layout v-else-if="link.icon" class="ma-0" fill-height justify-center align-center>
                                        <v-icon size="60">{{link.icon}}</v-icon>
                                    </v-layout>  
                                </v-card>
                            </v-responsive>
                            <v-card-title >
                                <v-layout class="ma-0">
                                    <div class="headline" >{{link.name}}</div>
                                    <v-spacer></v-spacer> 
                                    <!-- <v-tooltip bottom  > 
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" icon >
                                                <v-icon color="primary">info_outline</v-icon>
                                            </v-btn> 
                                        </template>
                                        <span>More Info</span>
                                    </v-tooltip>  -->
                                </v-layout>
                            </v-card-title> 
                        </v-card> 
                    </template>
                </v-hover>
            </v-flex>

            <v-flex xs6 sm4 lg3 v-for="(link, index) in MY_LINKS" >
                <v-hover>
                    <template v-slot="{ hover  }">
                        <v-card  
                            :to="link.link"
                                
                            :elevation="hover ?12:''" class="b2"
                            hover  rounded="xl"
                            height="100%">  
                            <v-responsive  aspect-ratio="1.5"  >
                                <v-card rounded="xl" height="100%" :color="link.image_background_color" >
                                    <v-layout class="ma-0" align-center justify-center fill-height > 
                                        <v-card rounded="xl" color="transparent" height="100%" 
                                            :width="link.image_size?link.image_size+'%':'50%'" tile flat>
                                            <v-menu    offset-y v-if="false">
                                                <template v-slot:activator="{ on }">
                                                    <div>
                                                        <v-layout  class="py-5" > 
                                                            <v-spacer></v-spacer> 
                                                            <v-btn 
                                                                dark
                                                                icon
                                                                v-on="on" >
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn> 
                                                        </v-layout> 
                                                    </div>
                                                </template> 
                                                <v-list v-if="true"> 
                                                    <v-list-item @click="VIEW_ACCOUNT(link)">
                                                        <v-list-item-action><v-icon>mdi-eye</v-icon></v-list-item-action>
                                                        <v-list-item-title>View Account</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="EDIT_ACCOUNT(link)">
                                                        <v-list-item-action><v-icon>edit</v-icon></v-list-item-action>
                                                        <v-list-item-title>Edit Account</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="ARCHIVE_ACCOUNT(link)">
                                                        <v-list-item-action><v-icon>delete</v-icon></v-list-item-action>
                                                        <v-list-item-title>Archive Account</v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item @click="DELETE_ACCOUNT(link)">
                                                        <v-list-item-action><v-icon>delete</v-icon></v-list-item-action>
                                                        <v-list-item-title>Delete Account</v-list-item-title>
                                                    </v-list-item> -->
                                                        
                                                </v-list> 
                                            </v-menu>  
                                            <v-layout fill-height  align-center justify-center column> 
                                                
                                                <v-icon class="mt-3" size="50" color=" darken-">mdi-coffee-maker</v-icon>
                                            </v-layout>
                                            <v-img  v-if="false"  
                                                :src="link.image?link.image:MBS.data.noImg" >  
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>  
                                            
                                        </v-card>
                                    </v-layout>  
                                </v-card>
                            </v-responsive>
                            <v-card-title >
                                <v-layout class="ma-0" >
                                    <div class="headline" >{{link.name}}</div> 
                                </v-layout>
                            </v-card-title> 
                        </v-card> 
                    </template>
                </v-hover>
            </v-flex>
        </v-layout> 
    </v-container>  
  </v-layout> 
</template>

<script>
import DATA from '../../../plugins/DATA'
import {mapState} from "vuex"
let PAGE_NAME = "MY-ACCOUNT"
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_SHOW:false, 
                DATA:DATA
            }
        },
        mounted() {
            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500)
             
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({ 
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 

            }),   
            ItemOrdersData(){  
                return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
            },  
            LocalItemOrdersData(){  
                return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
            },  
            AllItemOrders(){  
                let local = this.LocalItemOrdersData
                let live = this.ItemOrdersData
                console.log(live,'l---------');
                return live
            },  
            ItemOrderOptionsData(){  
                return this.JoinedItemOrderOptions?this.JoinedItemOrderOptions:this.ItemOrderOptions
            }, 
            /////////
            MyLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("orders",1,true) 
                return thisLinks
            },
            MY_LINKS(){  
                let accounts = this.ItemOrderOptionsData 
                if(!accounts){return null}
                let allLinks = []
                accounts.forEach(element => {
                    allLinks.push({
                        ...element,
                        name:element.name,
                        link:this.MBS.actions.COMPANY_LINK("/orders/"+element.code,true),
                        icon:'link', 
                        image_background_color:'primary lighten-2',
                        image_size:'80',
                        
                    })
                });  
                return allLinks
            },
        },
        methods: { 
            clicked(){},
            //LOAD
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 
