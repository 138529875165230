 
import DATA from './DATA'
import { store } from './../store'
import { openDB, deleteDB, wrap, unwrap } from 'idb';  
let NAME = "INDEX-DB"  
let DB_PROMISE;
const REQUEST_TIMER = 1;


const initializeDB = async(name)=>{ 
    try {  
        if (!('indexedDB' in window)) { 
            store.dispatch("ERROR",{
                error:'This browser doesn\'t support IndexedDB',
                from:'initializeDB',
                page:NAME, 
            })
            return null;
        } 
        DB_PROMISE = await openDB(DATA.APP.SERVER2,DATA.APP.SERVER_VERSION,{
            upgrade(db,oldVersion,newVersion,transaction){
                createStore(db)
                if (oldVersion == 0) {
                } else { 
                } 
            }
        }) 
        return DB_PROMISE
    } catch (error) {
        store.dispatch("ERROR",{
            error:{
                error:error,
                name:name,
            },
            from:'initializeDB',
            page:NAME, 
        })
    }
} 
const createStore = (db)=>{ 
    try {
        if (!db.objectStoreNames.contains(DATA.ITEMS.ERRORS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ERRORS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.PERMISSIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PERMISSIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.BILLING_PLANS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.BILLING_PLANS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.BILLING_PLAN_FEATURES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.BILLING_PLAN_FEATURES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.CURRENT_BILLING_PLANS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.CURRENT_BILLING_PLANS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.SUBSCRIPTIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.SUBSCRIPTIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.USER_DETAILS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.USER_DETAILS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.USER_LIVES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.USER_LIVES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.USER_GROUPS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.USER_GROUPS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.MESSAGES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.MESSAGES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.COUNTRIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.COUNTRIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
         if (!db.objectStoreNames.contains(DATA.ITEMS.REGIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.REGIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.DISTRICTS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.DISTRICTS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.ID_TYPES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ID_TYPES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.VISITERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.VISITERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.ATTENDANCES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ATTENDANCES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.DESIGNATIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.DESIGNATIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        
        if (!db.objectStoreNames.contains(DATA.ITEMS.MEASUREMENT_UNITS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.MEASUREMENT_UNITS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.COMPANIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.COMPANIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.SELECTED_COMPANIES.value.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.SELECTED_COMPANIES.value.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.MY_COMPANIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.MY_COMPANIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.COMPANY_TYPES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.COMPANY_TYPES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.COMPANY_SIZES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.COMPANY_SIZES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.COMPANY_USERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.COMPANY_USERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.COMPANY_USER_GROUPS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.COMPANY_USER_GROUPS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        
    
        if (!db.objectStoreNames.contains(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.DEVICES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.DEVICES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_DEVICES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_DEVICES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.CARDS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.CARDS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
         if (!db.objectStoreNames.contains(DATA.ITEMS.PRICING_TIERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PRICING_TIERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.TILLS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.TILLS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.DAYS_SHIFTS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.DAYS_SHIFTS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_DAYS_SHIFTS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_DAYS_SHIFTS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        // if (!db.objectStoreNames.contains(DATA.ITEMS.CASHUPS.values.toUpperCase())) {
        //     db.createObjectStore(DATA.ITEMS.CASHUPS.values.toUpperCase(),{keyPath:"key"}) 
        // }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_CASHUPS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_CASHUPS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.STOCK_OUTLETS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.STOCK_OUTLETS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.STOCK_ORDERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.STOCK_ORDERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.STOCK_ORDER_ITEMS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.STOCK_ORDER_ITEMS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        // if (!db.objectStoreNames.contains(DATA.ITEMS.STOCKS_RETURN.values.toUpperCase())) {
        //     db.createObjectStore(DATA.ITEMS.STOCKS_RETURN.values.toUpperCase(),{keyPath:"key"}) 
        // }else{  }
        // if (!db.objectStoreNames.contains(DATA.ITEMS.SALE_ORDERS.values.toUpperCase())) {
        //     db.createObjectStore(DATA.ITEMS.SALE_ORDERS.values.toUpperCase(),{keyPath:"key"}) 
        // }else{  }
        // if (!db.objectStoreNames.contains(DATA.ITEMS.SALE_ORDER_ITEMS.values.toUpperCase())) {
        //     db.createObjectStore(DATA.ITEMS.SALE_ORDER_ITEMS.values.toUpperCase(),{keyPath:"key"}) 
        // }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_STOCK_ADJUSTMENT_DETAILS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_STOCK_ADJUSTMENT_DETAILS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_SELECTED_STOCK_ADJUSTMENTS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_SELECTED_STOCK_ADJUSTMENTS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_SALE_ORDERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_SALE_ORDERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_SALE_ORDER_ITEMS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_SALE_ORDER_ITEMS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_SALES_RETURN.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_SALES_RETURN.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_SELECTED_ITEMS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_SELECTED_ITEMS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_SELECTED_WAREHOUSE_ITEMS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_SELECTED_WAREHOUSE_ITEMS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.QUOTATIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.QUOTATIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_QUOTATIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_QUOTATIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.BRANDS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.BRANDS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.MANUFACTURERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.MANUFACTURERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.SUPPLIERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.SUPPLIERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.SUPPLIER_CATEGORIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.SUPPLIER_CATEGORIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.CUSTOMERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.CUSTOMERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.CUSTOMER_GROUPS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.CUSTOMER_GROUPS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.CUSTOMER_GROUPS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.CUSTOMER_GROUPS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.PATIENT_ORDERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PATIENT_ORDERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.PATIENT_HISTORIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PATIENT_HISTORIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.ITEMS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ITEMS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.ITEM_CATEGORIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ITEM_CATEGORIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.ITEM_DEPARTMENTS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ITEM_DEPARTMENTS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.ITEM_ORDERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ITEM_ORDERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.ITEM_ORDER_OPTIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ITEM_ORDER_OPTIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_ITEM_ORDERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_ITEM_ORDERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.ITEM_KITS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ITEM_KITS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        
        if (!db.objectStoreNames.contains(DATA.ITEMS.ASSETS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ASSETS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.ASSET_TYPES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.ASSET_TYPES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.PAYABLES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PAYABLES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.RECEIVABLES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.RECEIVABLES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.PAID_INVOICES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PAID_INVOICES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.UNPAID_INVOICES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.UNPAID_INVOICES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.CREDITORS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.CREDITORS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.DEBTORS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.DEBTORS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.PAYMENTS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PAYMENTS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.EXPENSES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.EXPENSES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.EXPENSE_CATEGORIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.EXPENSE_CATEGORIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.EXPENSE_ACCOUNTS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.EXPENSE_ACCOUNTS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.TRACKINGS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.TRACKINGS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.TRACKING_UPDATES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.TRACKING_UPDATES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.TRACKING_KEYS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.TRACKING_KEYS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.COMPANY_SETTINGS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.COMPANY_SETTINGS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.EXPIRIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.EXPIRIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.PAYMENT_METHODS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PAYMENT_METHODS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.MONEY_ACCOUNTS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.MONEY_ACCOUNTS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.MONEY_ACCOUNT_TRANSACTIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.MONEY_ACCOUNT_TRANSACTIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 

        if (!db.objectStoreNames.contains(DATA.ITEMS.STOCK_TAKING_ORDERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.STOCK_TAKING_ORDERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  }
        if (!db.objectStoreNames.contains(DATA.ITEMS.STOCK_TAKINGS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.STOCK_TAKINGS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.PAGE_HISTORIES.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.PAGE_HISTORIES.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
        if (!db.objectStoreNames.contains(DATA.ITEMS.LOCAL_WAREHOUSE_ORDERS.values.toUpperCase())) {
            db.createObjectStore(DATA.ITEMS.LOCAL_WAREHOUSE_ORDERS.values.toUpperCase(),{keyPath:"key"}) 
        }else{  } 
    } catch (error) {
        store.dispatch("ERROR",{
            error: error ,
            from:'createStore',
            page:NAME, 
        })
    } 
} 
const addData =  (name,data,company_key,clear)=>{
    return new Promise((resolve,reject)=>{
        setTimeout(async() => {
            let response = {
                from:'addData',
                page:NAME, 
                name:name, 
                data:data, 
                clear:clear, 
            }
            try {   
                let db = await initializeDB(name) 
                if (!db.objectStoreNames.contains(name.toUpperCase())) { 
                    reject({
                        ...response,
                        error:'store not define'
                    }) 
                    return
                } 
                let transaction = db.transaction(name.toUpperCase(),'readwrite')
                let db_store = await transaction.objectStore(name.toUpperCase())
                if (clear) {
                    db_store.clear()
                }
                let value = null
                let data_after = null

                //UPDATE IF DATA
                if (data) { 
                    if (Array.isArray(data)) {
                        value = await Promise.all(data.map(item=>{
                            return  db_store.put(item) 
                        }))
                    } else {
                        value = await db_store.put(data) 
                    }
                }else{
                    console.log('no...store....data-------');
                }

                //
                if (true) {
                    if (company_key) {
                        data_after = await db_store.get(company_key)
                    }else {
                        data_after = await db_store.getAll()  
                    } 
                }
                await transaction.done  
                
                resolve({
                    response:'success',
                    action:'delete',
                    success:true,
                    data:value,
                    data_after:data_after,
                    clear:clear,
                    name:name
                }) 
                
            }catch (error) {
                store.dispatch("ERROR",{
                    ...response,
                    error:{
                        error: error,
                        data:data,
                        name:name,
                    },  
                })
                reject({
                    ...response,
                    error:error
                })
            } 
        }, REQUEST_TIMER);
    })
}
const updateData =  (name,data,company_key,clear)=>{
    return new Promise((resolve,reject)=>{
        setTimeout(async() => {
            let response = {
                from:'addData',
                page:NAME, 
                name:name, 
                data:data, 
                clear:clear, 
            }
            try {   
                let db = await initializeDB(name) 
                if (!db.objectStoreNames.contains(name.toUpperCase())) { 
                    reject({
                        ...response,
                        error:'store not define'
                    }) 
                    return
                } 
                let transaction = db.transaction(name.toUpperCase(),'readwrite')
                let db_store = await transaction.objectStore(name.toUpperCase())
                if (clear) {
                    db_store.clear()
                }
                let value = null
                let data_after = null

                //UPDATE IF DATA
                if (data) { 
                    if (Array.isArray(data)) {
                        value = await Promise.all(data.map(item=>{
                            return  db_store.put(item) 
                        }))
                    } else {
                        db_store
                        value = await db_store.put(data) 
                    }
                }else{
                    console.log('no...store....data-------');
                }

                //
                if (true) {
                    if (company_key) {
                        data_after = await db_store.get(company_key)
                    }else {
                        data_after = await db_store.getAll()  
                    } 
                }
                await transaction.done  
                
                resolve({
                    response:'success',
                    action:'delete',
                    success:true,
                    data:value,
                    data_after:data_after,
                    clear:clear,
                    name:name
                }) 
                
            }catch (error) {
                store.dispatch("ERROR",{
                    ...response,
                    error:{
                        error: error,
                        data:data,
                        name:name,
                    },  
                })
                reject({
                    ...response,
                    error:error
                })
            } 
        }, REQUEST_TIMER);
    })
}
const deleteStore = (name)=>{
    return new Promise((resolve,reject)=>{
        let response={
            from:'deleteStore',
            page:NAME, 
            name:name, 
        }
        setTimeout(async() => {
            try {  
                    let db = await initializeDB(name) 
                    if (!db.objectStoreNames.contains(name.toUpperCase())) { 
                        reject({
                            ...response,
                            error:'store not define'
                        }) 
                        return
                    } 
                    let transaction = db.transaction(name.toUpperCase(),'readwrite')
                    let db_store = await transaction.objectStore(name.toUpperCase())
                    db_store.clear()
                    await transaction.done  
                    resolve({
                        response:'success', 
                        success:true,
                        name:name
                    }) 
                
            } catch (error) {
                store.dispatch("ERROR",{
                    ...response,
                    error:{
                        error: error, 
                        name:name,
                    },  
                })
                reject({
                    ...response,
                    error:error,
                })
            }
        }, REQUEST_TIMER); 
    })
}
const deleteStoreId =  (name,key,items,company_key)=>{
    return new Promise((resolve,reject)=>{
        let response={
            from:'deleteStoreId',
            page:NAME, 
            name:name, 
            key:key, 
        }
        setTimeout(async() => {
            try {  
                if (!key && !items) {
                    reject({...response,error:'No Key or Items'})
                    return
                }
                let db = await initializeDB(name,key) 
                if (!db.objectStoreNames.contains(name.toUpperCase())) { 
                    reject({
                        ...response,
                        error:'store not define',
                        name:name,
                        key:key
                    }) 
                    return
                } 
                let transaction = db.transaction(name.toUpperCase(),'readwrite')
                let db_store = await transaction.objectStore(name.toUpperCase())
                //to do
                // if (company_key) {
                //     let res = await db_store.get(company_key)
                //     console.log(res,'db res............');
                // }else {
                // }
                let data_after = null

                //deleting
                if (key) {
                    await db_store.delete(key)   
                }else if (items) {
                    for (const item of items) {
                        await db_store.delete(item.key) 
                    }
                }
                //
                if (true) {
                    if (company_key) {
                        data_after = await db_store.get(company_key)
                    }else {
                        data_after = await db_store.getAll()  
                    } 
                }

                await transaction.done  
                resolve({
                    response:'success', 
                    success:true,
                    name:name,
                    data_after:data_after,
                    action:'delete', 
                    key:key
                })  
            } catch (error) {
                store.dispatch("ERROR",{
                    ...response,
                    error:{
                        error: error, 
                        name:name,
                        key:key
                    }, 
                    
                })
                reject({
                    ...response,
                    error:error
                })
            }
        }, REQUEST_TIMER);
    })
}
const getData = (name,key,update_store,company_key)=>{
    return new Promise((resolve,reject)=>{
        let response={
            from:'getData',
            page:NAME, 
            name:name, 
            key:key, 
            update_store:update_store, 
            company_key:company_key, 
        }
        setTimeout(async() => {
            try {  
                let db = await initializeDB(name) 
                if (!db.objectStoreNames.contains(name.toUpperCase())) { 
                    reject({
                        ...response,
                        error:'store not define'
                    }) 
                    return
                } 
                let transaction = db.transaction(name.toUpperCase(),'readwrite')
                let db_store = await transaction.objectStore(name.toUpperCase()) 
                let data = []
                if (company_key) {
                    data = await db_store.get(company_key)
                }else if (key) {
                    data = await db_store.get(key)
                } else {
                    data = await db_store.getAll()  
                }
                await transaction.done  
                if (data && company_key) {
                    data = data?data.data:null
                }
                if (update_store ) { 
                    await updateStore(name,data)
                }
                resolve({
                    data:data,
                    success:true,
                    response:'success',
                    isArray:key?false:true,
                    update_store:update_store
                })  
            } catch (error) {
                store.dispatch("ERROR",{
                    ...response,
                    error:error,
                })
                reject({
                    ...response,
                    error:error
                })
            }
        }, REQUEST_TIMER);
    })
} 
const updateStore = (name,data)=>{
    return new Promise(async(resolve,reject)=>{
        let response={
            from:'updateStore',
            page:NAME, 
            name:name,
            data:data,
        }
        try { 
            if (!name) { 
                reject({
                    ...response,
                    error:"payload required"
                }) 
                return
            } 
            let user_state = store.state?store.state.users:null
            let item_state = store.state?store.state.items:null
            if (!user_state || !item_state) { 
                reject({
                    ...response,
                    error:{
                        error:'no sate',
                        data:data,
                        name:name,
                        store:store
                    }, 
                }) 
                return
            }
             
            if (name.toUpperCase()===DATA.ITEMS.ERRORS.values.toUpperCase()) { 
                item_state.ERRORS = data
            }else if (name.toUpperCase()===DATA.ITEMS.USERS.values.toUpperCase()) {
                user_state.USERS = data
            }else if (name.toUpperCase()===DATA.ITEMS.USER_DETAILS.values.toUpperCase()) {
                user_state.USER_DETAILS = data    
            }else if (name.toUpperCase()===DATA.ITEMS.USER_LIVES.values.toUpperCase()) {
                user_state.USER_LIVES = data    
            }else if (name.toUpperCase()===DATA.ITEMS.USER_GROUPS.values.toUpperCase()) {
                user_state.USER_GROUPS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.PERMISSIONS.values.toUpperCase()) {
                user_state.PERMISSIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.BILLING_PLANS.values.toUpperCase()) {
                user_state.BILLING_PLANS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.BILLING_PLAN_FEATURES.values.toUpperCase()) {
                user_state.BILLING_PLAN_FEATURES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.CURRENT_BILLING_PLANS.values.toUpperCase()) {
                user_state.CURRENT_BILLING_PLANS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.SUBSCRIPTIONS.values.toUpperCase()) {
                user_state.SUBSCRIPTIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.VISITERS.values.toUpperCase()) {
                user_state.VISITERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.MESSAGES.values.toUpperCase()) {
                item_state.MESSAGES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.COUNTRIES.values.toUpperCase()) {
                item_state.COUNTRIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.REGIONS.values.toUpperCase()) {
                item_state.REGIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.DISTRICTS.values.toUpperCase()) {
                item_state.DISTRICTS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.ID_TYPES.values.toUpperCase()) {
                item_state.ID_TYPES = data     
            } 
            
            else if (name.toUpperCase()===DATA.ITEMS.MEASUREMENT_UNITS.values.toUpperCase()) {
                item_state.MEASUREMENT_UNITS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.COMPANIES.values.toUpperCase()) {
                item_state.COMPANIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.SELECTED_COMPANIES.value.toUpperCase()) {
                item_state.SELECTED_COMPANIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.MY_COMPANIES.values.toUpperCase()) {
                item_state.MY_COMPANIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_TYPES.values.toUpperCase()) {
                item_state.COMPANY_TYPES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_SIZES.values.toUpperCase()) {
                item_state.COMPANY_SIZES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_USERS.values.toUpperCase()) {
                item_state.COMPANY_USERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_USER_GROUPS.values.toUpperCase()) {
                item_state.COMPANY_USER_GROUPS = data     
            }
            
            else if (name.toUpperCase()===DATA.ITEMS.COMPANY_QUICK_ACTIONS.values.toUpperCase()) {
                item_state.COMPANY_QUICK_ACTIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.DEVICES.values.toUpperCase()) {
                item_state.DEVICES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_DEVICES.values.toUpperCase()) {
                item_state.LOCAL_DEVICES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.CONNECTED_PRINTERS.values.toUpperCase()) {
                item_state.CONNECTED_PRINTERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.CARDS.values.toUpperCase()) {
                item_state.CARDS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.PRICING_TIERS.values.toUpperCase()) {
                item_state.PRICING_TIERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.TILLS.values.toUpperCase()) {
                item_state.TILLS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.DAYS_SHIFTS.values.toUpperCase()) {
                item_state.DAYS_SHIFTS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase()) {
                item_state.DAYS_SHIFTS_SALES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_DAYS_SHIFTS.values.toUpperCase()) {
                item_state.LOCAL_DAYS_SHIFTS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.CASHUPS.values.toUpperCase()) {
                item_state.CASHUPS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_CASHUPS.values.toUpperCase()) {
                item_state.LOCAL_CASHUPS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.STOCK_OUTLETS.values.toUpperCase()) {
                item_state.STOCK_OUTLETS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.STOCK_ORDERS.values.toUpperCase()) {
                item_state.STOCK_ORDERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.STOCK_ORDER_ITEMS.values.toUpperCase()) {
                item_state.STOCK_ORDER_ITEMS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_STOCK_ADJUSTMENT_DETAILS.values.toUpperCase()) {
                item_state.LOCAL_STOCK_ADJUSTMENT_DETAILS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_SELECTED_STOCK_ADJUSTMENTS.values.toUpperCase()) {
                item_state.LOCAL_SELECTED_STOCK_ADJUSTMENTS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_SALE_ORDERS.values.toUpperCase()) {
                item_state.LOCAL_SALE_ORDERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_SALE_ORDER_ITEMS.values.toUpperCase()) {
                item_state.LOCAL_SALE_ORDER_ITEMS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_SALES_RETURN.values.toUpperCase()) {
                item_state.LOCAL_SALES_RETURN = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_SELECTED_ITEMS.values.toUpperCase()) {
                item_state.LOCAL_SELECTED_ITEMS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values.toUpperCase()) {
                item_state.LOCAL_SELECTED_SALES_ITEMS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_SELECTED_WAREHOUSE_ITEMS.values.toUpperCase()) {
                item_state.LOCAL_SELECTED_WAREHOUSE_ITEMS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.BRANDS.values.toUpperCase()) {
                item_state.BRANDS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.MANUFACTURERS.values.toUpperCase()) {
                item_state.MANUFACTURERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.QUOTATIONS_AND_INVOICES.values.toUpperCase()) {
                item_state.QUOTATIONS_AND_INVOICES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.QUOTATIONS.values.toUpperCase()) {
                item_state.QUOTATIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_QUOTATIONS.values.toUpperCase()) {
                item_state.LOCAL_QUOTATIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.SUPPLIERS.values.toUpperCase()) {
                item_state.SUPPLIERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.SUPPLIER_CATEGORIES.values.toUpperCase()) {
                item_state.SUPPLIER_CATEGORIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.CUSTOMERS.values.toUpperCase()) {
                item_state.CUSTOMERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.CUSTOMER_GROUPS.values.toUpperCase()) {
                item_state.CUSTOMER_GROUPS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.PATIENT_ORDERS.values.toUpperCase()) {
                item_state.PATIENT_ORDERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.PATIENT_HISTORIES.values.toUpperCase()) {
                item_state.PATIENT_HISTORIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.ITEMS.values.toUpperCase()) {
                item_state.ITEMS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.ITEM_CATEGORIES.values.toUpperCase()) {
                item_state.ITEM_CATEGORIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.ITEM_DEPARTMENTS.values.toUpperCase()) {
                item_state.ITEM_DEPARTMENTS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.ITEM_ORDERS.values.toUpperCase()) {
                item_state.ITEM_ORDERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_ITEM_ORDERS.values.toUpperCase()) {
                item_state.LOCAL_ITEM_ORDERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.ITEM_ORDER_OPTIONS.values.toUpperCase()) {
                item_state.ITEM_ORDER_OPTIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.ITEM_KITS.values.toUpperCase()) {
                item_state.ITEM_KITS = data     
            }
            
            else if (name.toUpperCase()===DATA.ITEMS.ASSETS.values.toUpperCase()) {
                item_state.ASSETS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.ASSET_TYPES.values.toUpperCase()) {
                item_state.ASSET_TYPES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.PAYABLES.values.toUpperCase()) {
                item_state.PAYABLES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.RECEIVABLES.values.toUpperCase()) {
                item_state.RECEIVABLES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.PAID_INVOICES.values.toUpperCase()) {
                item_state.PAID_INVOICES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.UNPAID_INVOICES.values.toUpperCase()) {
                item_state.UNPAID_INVOICES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.CREDITORS.values.toUpperCase()) {
                item_state.CREDITORS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.DEBTORS.values.toUpperCase()) {
                item_state.DEBTORS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.PAYMENTS.values.toUpperCase()) {
                item_state.PAYMENTS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.EXPENSES.values.toUpperCase()) {
                item_state.EXPENSES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.EXPENSE_CATEGORIES.values.toUpperCase()) {
                item_state.EXPENSE_CATEGORIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.EXPENSE_ACCOUNTS.values.toUpperCase()) {
                item_state.EXPENSE_ACCOUNTS = data     
            }
            
            else if (name.toUpperCase()===DATA.ITEMS.TRACKINGS.values.toUpperCase()) {
                item_state.TRACKINGS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.TRACKING_UPDATES.values.toUpperCase()) {
                item_state.TRACKING_UPDATES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.TRACKING_KEYS.values.toUpperCase()) {
                item_state.TRACKING_KEYS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_SETTINGS.values.toUpperCase()) {
                item_state.COMPANY_SETTINGS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.EXPIRIES.values.toUpperCase()) {
                item_state.EXPIRIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values.toUpperCase()) {
                item_state.DEFAULT_PAYMENT_METHODS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.MONEY_ACCOUNTS.values.toUpperCase()) {
                item_state.MONEY_ACCOUNTS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.MONEY_ACCOUNT_TRANSACTIONS.values.toUpperCase()) {
                item_state.MONEY_ACCOUNT_TRANSACTIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.STOCK_TAKING_ORDERS.values.toUpperCase()) {
                item_state.STOCK_TAKING_ORDERS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.STOCK_TAKINGS.values.toUpperCase()) {
                item_state.STOCK_TAKINGS = data     
            }
            else if (name.toUpperCase()===DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values.toUpperCase()) {
                item_state.LOCAL_JOINED_STOCK_TAKING = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values.toUpperCase()) {
                item_state.LOCAL_STOCK_TAKING_ITEMS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.PAGE_HISTORIES.values.toUpperCase()) {
                item_state.PAGE_HISTORIES = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values.toUpperCase()) {
                item_state.LOCAL_WAREHOUSE_SESSIONS = data     
            }else if (name.toUpperCase()===DATA.ITEMS.LOCAL_WAREHOUSE_ORDERS.values.toUpperCase()) {
                item_state.LOCAL_WAREHOUSE_ORDERS = data     
            }
            else{
                reject({
                    ...response,
                    error:'name not found', 
                })
                return
            }  
            resolve({
                response:'success',
                data:data,
                name:name
            })
        } catch (error) {
            store.dispatch("ERROR",{
                ...response,
                error:error, 
            })
        }
    })
} 

const addItems = (payload)=>{
    return new Promise((resolve,reject)=>{
        let response={
            from:'addItems',
            page:NAME, 
            payload:payload
        } 
        setTimeout(async() => {
            try {  
                let name = payload?payload.name:null
                let data = payload?payload.data:null
                let clear = payload?payload.clear:null
                let company_key = payload?payload.company_key:null
                let update_from_db = payload?payload.update_from_db:null
                let update_locals = payload?payload.update_locals:null
                if (!name ) {  
                    reject({
                        ...response,
                        error:"payload required",
                    })
                    return
                }   
                if (company_key) {
                    data = {
                        key:company_key,
                        data:data
                    }
                }
                let res = await addData(name,data,company_key,clear) 
                if (!update_from_db) {
                    store.dispatch("update_from_db",payload)  
                } 
                if (update_locals) {
                    store.dispatch("on_update_locals",{
                        ...payload,
                        ...res
                    })  
                } 
                resolve({
                    ...res,
                    success:true,
                })  
            } catch (error) {
                reject({
                    ...response,
                    error:error
                }) 
            }
        }, REQUEST_TIMER);
    })
}
const updateItems = (payload)=>{
    return new Promise((resolve,reject)=>{
        let response={
            from:'updateItems',
            page:NAME, 
            payload:payload
        } 
        setTimeout(async() => {
            try {  
                let name = payload?payload.name:null
                let data = payload?payload.data:null
                let clear = payload?payload.clear:null
                let company_key = payload?payload.company_key:null
                let update_from_db = payload?payload.update_from_db:null
                let update_locals = payload?payload.update_locals:null
                if (!name ) {  
                    reject({
                        ...response,
                        error:"payload required",
                    })
                    return
                }   
                if (company_key) {
                    data = {
                        key:company_key,
                        data:data
                    }
                }

                //UPDATE IF DATA
                if (data) { 
                    if (Array.isArray(data)) {
                        let updated_data = []
                        for (const item of data) {
                            const current_data_res = await getItems({
                                key:item.key,
                                name:name,
                                company_key:company_key
                            })
                            const current_data = current_data_res?.data
                            if (current_data) {
                                updated_data.push({
                                    ...current_data,
                                    ...item
                                })
                            }else{
                                updated_data.push({ 
                                    ...item
                                })
                            }
                        }
                        data = updated_data
                    } else {
                        const current_data_res = await getItems({
                            key:data.key,
                            name:name,
                            company_key:company_key
                        })
                        const current_data = current_data_res?.data
                        if (current_data) {
                            data = {
                                ...current_data,
                                ...data
                            }
                        }

                        console.log(current_data,'current_data............//');
                        console.log(data,'data............//');
                    }
                } 
                let res = await addData(name,data,company_key,clear) 
                if (!update_from_db) {
                    store.dispatch("update_from_db",payload)  
                } 
                if (update_locals) {
                    store.dispatch("on_update_locals",{
                        ...payload,
                        ...res
                    })  
                } 
                resolve({
                    ...res,
                    success:true,
                })  
            } catch (error) {
                reject({
                    ...response,
                    error:error
                }) 
            }
        }, REQUEST_TIMER);
    })
} 
const getItems = (payload)=>{
    return new Promise((resolve,reject)=>{
        let response={
            from:'getItems',
            payload:payload,
            page:NAME, 
        } 
        setTimeout(async() => { 
            try {  
                let name = payload?payload.name:null
                let key = payload?payload.key:null
                let update_store = payload?payload.update_store:null
                let company_key = payload?payload.company_key:null
                let update_from_db = payload?payload.update_from_db:null
                if (!name) { 
                    reject({
                        ...response,
                        error:"payload required"
                    })
                    return
                }   
                let res = await getData(name,key,update_store,company_key)   
                if (!update_from_db) {
                    store.dispatch("update_from_db",payload)  
                } 
                resolve({
                    ...res,
                    success:true,
                })
            } catch (error) { 
                reject({
                    ...response,
                    error:error
                }) 
            }   
        }, REQUEST_TIMER);
    })
}  
const getUserDetails = ()=>{
    return new Promise(async(resolve,reject)=>{ 
        try {  
            let current = await getItems({
                name:DATA.ITEMS.USER_LIVES.values,
                key:DATA.APP.USER_TIMELINE.current
            }) 
            let action_request = await getItems({
                name:DATA.ITEMS.USER_LIVES.values,
                key:DATA.APP.USER_TIMELINE.action_request
            }) 
            let logged = await getItems({
                name:DATA.ITEMS.USER_LIVES.values,
                key:DATA.APP.USER_TIMELINE.logged_out
            })
            
            let current_uid = current?current.data?current.data.uid:null:null
            let action_request_uid = action_request?action_request.data?action_request.data.uid:null:null
            let logged_uid = logged?logged.data?logged.data.uid:null:null
            let user_details_data = null
    
            if (action_request_uid) {
                user_details_data = await getItems({
                    name:DATA.ITEMS.USER_DETAILS.values,
                    key:action_request_uid
                })
            }else if (current_uid) {
                user_details_data = await getItems({
                    name:DATA.ITEMS.USER_DETAILS.values,
                    key:current_uid
                })
            } 
            let  user_details = user_details_data?user_details_data.data:null 
             
            resolve({
                response:'success',
                current_uid:current_uid,
                action_request_uid:action_request_uid,
                logged_uid:logged_uid,
                user_details:user_details,
            })  
        } catch (error) {
            store.dispatch("ERROR",{
                error:error,
                from:'getUserDetails',
                page:NAME, 
            })
            reject({
                error:error,
                from:'getUserDetails',
                page:NAME, 
                response:'not success'
            })   
        }
    })
} 
const deleteItems = (payload)=>{
    return new Promise((resolve,reject)=>{
        let response={
            from:'deleteItems',
            payload:payload,
            page:NAME, 
        } 
        setTimeout(async() => {
            try { 
                let name = payload?payload.name:null
                let key = payload?payload.key:null
                let items = payload?payload.items:null
                let company_key = payload?payload.company_key:null
                let update_locals = payload?payload.update_locals:null
                if (!name) { 
                    reject({
                        ...response,
                        error:"payload required"
                    })
                    return
                } 
                let res = null
                if (key||items) { 
                    res  =  await deleteStoreId(name,key,items,company_key) 
                } else {
                    res  =  await deleteStore(name,company_key)  
                }
                if (update_locals) {
                    store.dispatch("on_update_locals",{
                        ...payload,
                        ...res
                    })  
                } 
                resolve({
                    ...res,
                    success:true
                })
            } catch (error) { 
                reject({
                    ...response,
                    error:error
                })
            }
        }, 10);
    })
} 
export default  { 
    addItems:addItems, 
    updateItems:updateItems, 
    getItems:getItems, 
    getUserDetails:getUserDetails,
    deleteItems:deleteItems, 
};