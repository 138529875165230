<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card >  
                        <!-- <mbs-page-tab 
                            :items="MY_LINKS"/>  -->
                        <mbs-item-manager
                            :table_title="'Pharmacy'"
                            :table_title_icon="'mdi-pill-multiple'"
                            :add_vertical_lines="true"
                            v-model="table_input"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEMS"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"  
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="AllPatientData"   
                            :table_tabs="TabItems"   
                            :is_joined="true"  
                            :path_item="PATH_ITEM"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                            @on_row_clicked="VIEW_ORDER"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
            <div>
                <!-- View ORDER  DIALOG -->
                <v-dialog
                    v-model="dialog_view_order"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="100%"
                    transition="dialog-transition" >
                    <v-card rounded="lg" v-if="dialog_view_order" class="b1" > 
                        <v-card-title class="primary">
                            <v-layout class="font-weight-bold b2--text" align-center> 
                                <v-avatar   size="18" class="white mr-2">
                                    <v-avatar  
                                        size="16"
                                        :color="FindStageColor(SELECTED_ORDER.stage)" />  
                                </v-avatar>
                                <span >PATIENT</span> 
                                <span class="mx-1">@</span>  
                                <span class="mr-1">{{ MBS.actions.TEXT_UP(FindStageName(SELECTED_ORDER.stage)) }}</span>     
                                <v-icon dark class="mx-2" v-if="SELECTED_ORDER.medical_scheme_key">mdi-medical-bag</v-icon>
                            </v-layout> 
                            <v-spacer></v-spacer> 
                            <v-btn class="ml-9" @click="dialog_view_order=false" icon dark><v-icon >close</v-icon></v-btn>
                        </v-card-title>    
                        <v-card-text class="b1">
                            <br>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-card outlined :height="vsh-288" class="mbs-scroll-y">
                                        <v-card-text> 
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-action>
                                                        <v-btn outlined large icon ><v-icon>mdi-human-wheelchair</v-icon></v-btn>
                                                    </v-list-item-action>
                                                    <v-list-item-content >
                                                        <v-list-item-title class="pointer click " @click="MBS.actions.copyText(SELECTED_ORDER.customer_name,true)">{{ SELECTED_ORDER.customer_name }}</v-list-item-title>
                                                        <v-list-item-subtitle class="pointer click " @click="MBS.actions.copyText(SELECTED_ORDER.customer.phone_number,true)" v-if="SELECTED_ORDER.customer">{{ SELECTED_ORDER.customer.phone_number }}</v-list-item-subtitle>
                                                        <v-list-item-subtitle class="font-weight-bold not-f2" v-if="SELECTED_ORDER.customer">{{ SELECTED_ORDER.customer.location_address }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <div class="text-right">
                                                            <div class="secondary--text font-weight-bold">{{ SELECTED_ORDER.gender }}</div>
                                                            <div>{{ SELECTED_ORDER.age }} Years Old</div>
                                                        </div>
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <v-divider v-if="SELECTED_ORDER.expand_details"></v-divider>
                                                <v-list-item @click="
                                                        SELECTED_ORDER.expand_details=!SELECTED_ORDER.expand_details 
                                                        if (SELECTED_ORDER.expand_billing_order) {
                                                            SELECTED_ORDER.expand_billing_order=false
                                                        }
                                                    ">
                                                    <v-list-item-content><div class="font-weight-bold">Order Details</div></v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn  icon>
                                                            <v-icon v-if="SELECTED_ORDER.expand_details">mdi-chevron-up</v-icon>
                                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <!-- <div v-if="SELECTED_ORDER.expand_details" class="px-3" >  -->
                                                <div v-if="true" class="px-3" > 
                                                    <v-layout align-center class="my-1">
                                                        <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                                                        <div>Initiated Time:</div>
                                                        <div class="ml-2">{{ SELECTED_ORDER.created_at_ }}</div>
                                                    </v-layout> 
                                                    <v-layout align-center class="my-1 pointer click " @click="MBS.actions.copyText(MBS.actions.NUMBER_SAME_LENGTH(SELECTED_ORDER.customer_code,10),true)">
                                                        <v-icon class="mr-2">mdi-barcode-scan</v-icon>
                                                        <div>Patient Code:</div>
                                                        <div class="ml-2">{{ MBS.actions.NUMBER_SAME_LENGTH(SELECTED_ORDER.customer_code,10) }}</div>
                                                    </v-layout> 
                                                    <v-layout align-center class="my-1 pointer click " @click="MBS.actions.copyText(SELECTED_ORDER.key,true)">
                                                        <v-icon class="mr-2">mdi-receipt</v-icon>
                                                        <div>Order Number:</div>
                                                        <div class="ml-2">{{ SELECTED_ORDER.key }}</div>
                                                    </v-layout>
                                                    <v-layout align-center class="my-1">
                                                        <v-icon class="mr-2">mdi-medical-bag</v-icon>
                                                        <div>Medical Scheme:</div>
                                                        <div class="ml-2">{{ SELECTED_ORDER.medical_scheme_key}}</div>
                                                    </v-layout>
                                                    <v-layout align-center class="my-1">
                                                        <v-icon class="mr-2">mdi-list-status</v-icon>
                                                        <div>Status:</div>
                                                        <div class="ml-2">{{ SELECTED_ORDER.order_status }}</div>
                                                    </v-layout>
                                                    <v-layout align-center class="my-1">
                                                        <v-icon class="mr-2">mdi-cash-register</v-icon>
                                                        <div>Initiated By:</div>
                                                        <div class="ml-2">{{ SELECTED_ORDER.teller_name }}</div>
                                                    </v-layout> 
                                                    <v-layout column class="my-1  ">
                                                        <v-card class="pa-4 mt-4 mb-4  " outlined flat>
                                                            <mbs-input-fields 
                                                                v-model="input"
                                                                :field_items="patient_triage_description"
                                                                />
                                                        </v-card>
                                                        
                                                        <v-card class="pa-4" outlined flat>
                                                            <mbs-input-fields 
                                                                v-model="input"
                                                                :field_items="patient_description"
                                                                />
                                                        </v-card>
                                                    </v-layout> 
                                                </div>
                                            </v-list>  
                                        </v-card-text> 
                                    </v-card>  
                                </v-col>
                                <v-col cols="12" sm="6" class="pl-0">
                                    <v-card outlined> 
                                        <mbs-search-select-items 
                                            v-model="output_searched"
                                            :show_toolbar="true"
                                            :show_table="false"
                                            @searched_item_key="SEARCHED_ITEM"
                                        />
                                        
                                        <mbs-sales-selected-items
                                            v-model="output_selected"
                                            :selling_model="output_searched?output_searched.selling_model:''"
                                            :height="vsh_selected_items_table" 
                                            @item_multiple_select="(value)=>item_multiple_select=value"
                                            @checked_items="(value)=>checked_items=value"
                                            />  
                                    </v-card>
                                </v-col>

                            </v-row>
                        </v-card-text>  
                        <v-divider></v-divider>
                        <v-card-actions class="mr-2 mb-2 ">
                            <!-- {{ output_selected?output_selected.selected_total_sold_price_:0.00 }}--
                            <v-spacer/> 
                            <v-menu v-if="MBS.actions.SIZE(hospitalStages)>0" 
                                :close-on-content-click="true" 
                                offset-y  offset-overflow
                                :nudge-width="90" rounded="xl"
                                >
                                <template v-slot:activator="{ on }">  
                                    <v-btn v-on="on"  color="primary" value="cancel">
                                        <v-icon right>
                                            mdi-account-arrow-right
                                        </v-icon>
                                        <span class="hidden-sm-and-down">Move Patient</span> 
                                        <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                    </v-btn>  
                                </template> 

                                <v-card>
                                    <v-card-title primary-title class="primary white--text"  >
                                        Move Patient to:
                                    </v-card-title>
                                    <v-list>
                                        <v-list-item v-for="(stage, index) in hospitalStages" 
                                        :key="index" @click="CONFIRM_UPDATING_ORDER(SELECTED_ORDER,{
                                                stage:stage.key,
                                                description:input.description,
                                                consultation_info:input.consultation_info,
                                            })">
                                            {{stage.name}}
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-menu>  
                             --> 
                             <v-flex > 
                                <!-- <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0 &&  !SELECTED_ORDER.discharged" -->
                                <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0 "
                                    v-model="menu_select_order_option" 
                                    :close-on-content-click="true" 
                                    offset-y  offset-overflow
                                    :nudge-width="90" rounded="xl"
                                    >
                                    <template v-slot:activator="{ on }">   
                                        <v-card v-on="on"    min-height="45" max-height="55"    
                                            :disabled="MBS.actions.SIZE(output_selected?output_selected.selected_items:null)<1 || inputLoading" 
                                            :flat="MBS.actions.SIZE(output_selected?output_selected.selected_items:null)<1" 
                                            hover  
                                            class="v-btn py-2" 
                                            :class="vs.smAndDown?'':''"
                                            :color="MBS.actions.SIZE(output_selected?output_selected.selected_items:null)<1?'grey darken-1':'primary'" dark >
                                            <v-layout class="ma-0 px-4" fill-height align-center justify-center v-if="!inputLoading">
                                                <div class="font-weight-bold not-f7">Bill Patient</div>
                                                <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(output_selected?output_selected.selected_items:null)}} items</div>
                                                <v-spacer></v-spacer>
                                                <div class="font-weight-bold not-f7">MK {{MBS.actions.money(output_selected?output_selected.selected_total_sold_price_:0)}}</div> 
                                            
                                                <v-icon class="ml-2 mr-1">arrow_drop_down</v-icon>
                                                </v-layout> 
                                            <v-layout v-else class="ma-0 pa-2" justify-center>
                                                <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                            </v-layout>
                                        </v-card> 
                                    </template> 
                
                                    <v-card>
                                        <v-card-title primary-title class="primary white--text"  >
                                            Select Bill / Order Option
                                        </v-card-title>
                                        <v-list>
                                            <v-list-item v-for="(order_option, index) in ItemOrderOptionsData" :key="index" 
                                                @click="CONFIRM_BILLING_PATIENT(order_option,SELECTED_ORDER,output_selected?output_selected.sale_order:null)">
                                                {{order_option.name}}
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>  
                             </v-flex>
                            <v-btn  min-height="51" max-height="55" width="150"
                                @click="dialog_view_order=false" 
                                class="ml-1 not-f8"
                                color="secondary " >
                                <div class="not-f6">Cancel</div>    
                            </v-btn>
                        </v-card-actions>
                    </v-card> 
                </v-dialog> 
                
                <!-- CHECK IN PATIENT  DIALOG -->
                <v-dialog
                    v-model="dialog_checkin_patient"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="700px"
                    transition="dialog-transition" >
                    <form @submit.prevent="CONFIRM_CHECKIN_PATIENT(SEARCHED_PATIENT,{order_status:'checkin'},input)"> 
                        <v-card rounded="lg" v-if="dialog_checkin_patient" > 
                            <v-card-title class="primary">
                                <div class="font-weight-bold b2--text"> 
                                    <span >CHECK-IN PATIENT</span> 
                                </div> 
                                <v-spacer></v-spacer> 
                                <v-btn class="ml-9" @click="dialog_checkin_patient=false" icon dark><v-icon >close</v-icon></v-btn>
                            </v-card-title>    
                            <v-card-text class="b1">
                                <br>
                                <v-card outlined>
                                    <v-card-text> 
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-btn outlined large icon ><v-icon>mdi-human-wheelchair</v-icon></v-btn>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ SEARCHED_PATIENT.name }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ SEARCHED_PATIENT.phone_number }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <div>
                                                        <div>{{ SEARCHED_PATIENT.gender }}</div>
                                                        <div>{{ SEARCHED_PATIENT.age }} Years Old</div>
                                                    </div>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list> 
                                        <v-list>
                                            <v-list-item @click="
                                                        SELECTED_ORDER.expand_details=!SEARCHED_PATIENT.expand_details 
                                                        if (SEARCHED_PATIENT.expand_items) {
                                                            SEARCHED_PATIENT.expand_items=false
                                                        }
                                                    ">
                                                <v-list-item-content><div class="font-weight-bold">Patient Details</div></v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn  icon>
                                                        <v-icon v-if="SEARCHED_PATIENT.expand_details">mdi-chevron-up</v-icon>
                                                        <v-icon v-else>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list> 
                                        <div v-if="SEARCHED_PATIENT.expand_details"> 
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                                <div>Order Number:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.key }}</div>
                                            </v-layout>

                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-cash-register</v-icon>
                                                <div>Initiated By:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.teller_name }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                                                <div>Initiated Time:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.created_at_ }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-timer-settings-outline</v-icon>
                                                <div>Estimated Time:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.estimated_time?SEARCHED_PATIENT.estimated_time:"..." }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-list-status</v-icon>
                                                <div>Status:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.order_status }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">home</v-icon>
                                                <div>Description:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.description }}</div>
                                            </v-layout> 
                                        </div> 
                                    </v-card-text> 
                                </v-card>

                                <!-- <v-card class="mt-2 py-4 px-4" outlined> 
                                    <mbs-input-fields
                                        v-model="input"
                                        :update_item="true" 
                                        :field_items="patient_checkin_fields"
                                        :select_items="Selectors" 
                                        />
                                </v-card> -->

     
                            </v-card-text>  
                            <!-- <v-card-actions>
                                <v-spacer/> 
                                <v-btn-toggle  dark  class="mb-2 mr-2" >
                                    <v-btn  @click="dialog_checkin_patient=false"  color="primary" value="exit">
                                        <span class="hidden-sm-and-down mx-3">Cancel</span>
                                        <v-icon>mdi-close</v-icon> 
                                    </v-btn> 
                                    <v-btn type="submit"  color="primary" value="cancel">
                                        <span class="hidden-sm-and-down">Checkin</span> 
                                        <v-icon right>
                                            mdi-head-check
                                        </v-icon>
                                    </v-btn>  
                                </v-btn-toggle>
                            </v-card-actions> -->
                        </v-card> 
                    </form>
                </v-dialog>  
                
                <!-- SCAN PATIENT -->
                <v-dialog
                    v-model="dialog_search_patient"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" v-if="dialog_search_patient" > 
                        <!-- <v-card-title class="primary">
                            <div class="font-weight-bold b2--text"> 
                                <span >CHECK-IN PATIENT</span> 
                            </div> 
                            <v-spacer></v-spacer> 
                            <v-btn class="ml-9" @click="dialog_search_patient=false" icon dark><v-icon >close</v-icon></v-btn>
                        </v-card-title>     -->
                        <v-card-text class="b1">
                            <v-card height="300" class="mt-9 mb-4 mx-2" outlined>
                                <v-layout fill-height align-center justify-center column>
                                    <div class="mb-9"> 
                                        <v-toolbar @click=" " rounded="" dark flat 
                                            :color="'primary'" 
                                            class="ma-">
                                            <v-autocomplete  class="mr-"
                                                v-model="search_item" 
                                                :items="CustomersData" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                :label="'Search for Patient'"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                :filter="(item, queryText, itemText) => { 
                                                    let index = (a,b)=>{
                                                            if(!a||!b){return false}
                                                            a = ''+a; b = ''+b
                                                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                        } 
                                                        let first = index(itemText,queryText)
                                                        let second = item?index(item.code,queryText):false
                                                        let third = item?index(item.brand_name,queryText):false
                                                        let forth = item?index(item.category_name,queryText):false
                                                        if(!item){return first}  
                                                        return first || second || third || forth 
                                                }"
                                                >
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>mdi-human-wheelchair</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-html="data.item.phone_number"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-html="data.item.next_of_kin_name"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>   
                                        </v-toolbar> 
                                    </div>

                                    <v-icon size="80">mdi-barcode-scan</v-icon>
                                </v-layout> 
                            </v-card>
                             
                        </v-card-text>  
                    </v-card> 
                </v-dialog>  

                <div v-if="ADDING_ITEM">
                    <mbs-item-manager 
                        :show="false" 
                        :add_item_action="ADDING_ITEM.add_item_action"
                        :name_items="ADDING_ITEM.names"
                        :name_item="ADDING_ITEM.name"
                        :value_items="ADDING_ITEM.values"
                        :value_item="ADDING_ITEM.value"
                        :field_items="ADDING_ITEM.fields"   
                        :path_item="ADDING_ITEM.path"  
                        :select_items="Selectors"  
                        @ADDED="ADDED" 
                    /> 
                </div>
            </div>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import DB from '../../../plugins/DB'
    import {mapState} from "vuex"
    let PAGE_NAME = 'PHARMACY'    
    export default {
        name:PAGE_NAME, 
        props:['id','order_code'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                input:null, 
                input:null, 
                inputLoading:false, 
                output_searched:{}, 
                output_selected:{}, 
                table_input:null,   
                search_item:null, 
                SELECTED_ORDER:null, 
                SEARCHED_PATIENT:null, 
                SELECTED_PATIENT:null, 
                dialog_view_order:false, 
                dialog_search_patient:false, 
                dialog_checkin_patient:false, 
                menuPaymentOptions:false, 
                menu_select_order_option:false, 
                NAME_ITEMS:DATA.ITEMS.PATIENTS.names,
                NAME_ITEM:DATA.ITEMS.PATIENTS.name,
                VALUE_ITEMS:DATA.ITEMS.PATIENTS.values,
                VALUE_ITEM:DATA.ITEMS.PATIENTS.value,       
                table_actions:[ 
                    // {type:"action",btn:true,icon:"mdi-head-check",action:"checkin_patient",color:"secondary", outlined:false,  text:"Check-in Patient"},   
                    // {type:"action",btn:true,icon:"mdi-human-wheelchair",action:"register_patient",color:"secondary", outlined:false,  text:"Register New Patient"},   
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                DATA:DATA,
                ADDING_ITEM:null,  
                patient_checkin_fields:[  
                    {type:"autocomplete",value:"medical_scheme_key",label:"Select Scheme",label_top:"Patient Medical Scheme (Optional)",
                        select:{
                            name:'MedicalSchemes',
                            value:'key',
                            text:'name'
                        },
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-medical-bag',
                                tile:true
                            },
                            item_title:['name'],
                            item_subtitle:['full_name']
                        }, 
                    required:false,sm:12,md:12,step_name:"Name"},     
                    {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12,step_name:"Details"},   
                ], 
                patient_triage_description:[      
                    {type:"text",value:"patient_weight",label:"Enter Weight",label_top:"Patient Weight (Optional)",
                        required:true,sm:12,md:6,disabled:true,step_name:"Patient"},  
                    {type:"text",value:"patient_bp",label:"Enter BP",label_top:"Patient BP (Optional)",
                        required:true,sm:12,md:6,disabled:true,step_name:"Patient"},  
                    {type:"text",value:"patient_pulse_rate",label:"Enter Pulse Rate",label_top:"Patient Pulse Rate (Optional)",
                        required:true,sm:12,md:6,disabled:true,step_name:"Patient"},  
                    {type:"text",value:"patient_temperature",label:"Enter Temperature ",label_top:"Patient Temperature (Optional)",
                        required:true,sm:12,md:6,disabled:true,step_name:"Patient"},  
                    {type:"text",value:"patient_oxygen_circulation",label:"Enter Oxygen Circulation ",label_top:"Patient Oxygen Circulation (Optional)",
                        required:true,sm:12,md:12,disabled:true,step_name:"Patient"},   
                ], 
                patient_description:[     
                {type:"textarea",value:"patient_examination",label:"Enter Examination",label_top:"Patient Examination (Optional)",
                        required:true,sm:12,md:6,disabled:true,step_name:"Patient"},  
                    {type:"textarea",value:"patient_investigation",label:"Enter Investigation",label_top:"Patient Investigation (Optional)",
                        required:true,sm:12,md:6,disabled:false,step_name:"Patient"},   

                    {type:"textarea",value:"patient_diagnosis",label:"Enter Diagnosis",label_top:"Patient Diagnosis (Optional)",
                        required:true,sm:12,md:6,disabled:false,step_name:"Patient"},  
                    {type:"textarea",value:"patient_management",label:"Enter Management",label_top:"Patient Management (Optional)",
                        required:true,sm:12,md:6,disabled:false,step_name:"Patient"},   
                    {type:"textarea",value:"description",label:"Description",label_tops:"Description (Optional)",
                        required:false,sm:12,md:12,step_name:"Details",disabled:false},   
                ], 
                all_stages:[
                    // {code:1,color:'brown',key:"checkin",name:"Checkin"},
                    // {code:2,color:'red',key:"waiting_triage",name:"Waiting for Triage"}, 
                    // {code:2,color:'yellow',key:"triage",name:"Triage"}, 
                    // {code:4,color:'red',key:"waiting_consultation",name:"Wait for Consultation"},
                    // {code:4,color:'blue',key:"consultation",name:"Consultation"},
                    // {code:4,color:'yellow',key:"waiting_lab_test",name:"Wait for Lab Testing"},
                    // {code:4,color:'yellow',key:"waiting_lab_test",name:"Lab Testing"},
                    // {code:4,color:'blue',key:"waiting_scanning",name:"Wait for Scanning"},
                    // {code:4,color:'blue',key:"scanning",name:"Scanning"},
                    {code:5,color:'error',key:"pharmacy",name:"Pharmacy"},
                    {code:6,color:'yellow',key:"billing",name:"Billing"},
                    {code:7,color:'green',key:"billing_paid",name:"Billing Paid"},
                    {code:8,color:'primary',key:"discharged",name:"Discharged"},
                ]
            }
        },
        created() {
            try {
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN); 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: { 
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            vsh_selected_items_table(){
                let vs = this.vs
                let vsh = this.vsh
                let mdAndDown = vs?vs.mdAndDown:null
                let order_options = this.ItemOrderOptionsData
                let CompanySettings = this.CompanySettings
                let allow_total_discount = CompanySettings?CompanySettings.allow_total_discount:false
                let height = vsh-350

                if (mdAndDown) {
                    height = height-50
                } 
                if (allow_total_discount) {
                    height = height-50
                } 
                return height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({ 
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 

                Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()],
                JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                PatientOrders: state=> state.items[(DATA.ITEMS.PATIENT_ORDERS.values).toUpperCase()],
                JoinedPatientOrders: state=> state.join[(DATA.ITEMS.PATIENT_ORDERS.values).toUpperCase()],
                PatientHistories: state=> state.items[(DATA.ITEMS.PATIENT_HISTORIES.values).toUpperCase()],
                JoinedPatientHistories: state=> state.join[(DATA.ITEMS.PATIENT_HISTORIES.values).toUpperCase()],

            }),   
            ItemOrdersData(){  
                return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
            },
            PatientOrdersData(){   
                return this.JoinedPatientOrders?this.JoinedPatientOrders:this.PatientOrders
            }, 
            PatientHistoriesData(){  
                console.log(this.PatientHistories,'P---------------');
                return this.JoinedPatientHistories?this.JoinedPatientHistories:this.PatientHistories
            }, 
            AllPatientData(){  
                let order_data = this.PatientOrdersData
                let history_data = this.PatientHistoriesData
                let data = []
                if (order_data) {
                    data = [...order_data]
                }
                console.log(history_data,'history_data,,,,,');
                if (history_data) {
                    history_data.forEach(item => {
                        data.push({
                            ...item,
                            discharged:true
                        })
                    });
                }
                return data
            },  
            LocalItemOrdersData(){  
                return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
            },  
            AllItemOrders(){  
                const local = this.LocalItemOrdersData
                const live = this.ItemOrdersData 
                const find = key=>{
                    if(!live){return null}
                    return live.find(order=>order.key == key)
                }
                let items = []
                if (this.MBS.actions.SIZE(live)>0) {
                    items.push(...live)
                }
                if (this.MBS.actions.SIZE(local)>0) {
                    local.forEach(element => {
                        let uploaded_order = find(element.key)
                        if (!uploaded_order) {
                            items.push(element)
                        }
                    });
                }
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("key",false))    
                return list 
            },  
            ThisItemOrders(){  
                let item_orders = this.AllItemOrders
                let orderOption = this.ThisOrderOption
                let order_option_key = orderOption?orderOption.key:null 
                if(!item_orders || !order_option_key){return null}
                let item = item_orders.filter(order=>order.order_option_key == order_option_key) 
                return item
            },
            ItemOrderOptionsData(){  
                return this.JoinedItemOrderOptions?this.JoinedItemOrderOptions:this.ItemOrderOptions
            }, 
            TabItems(){  
                return [ 
                    {name:"Waiting",show_if:(this_,item)=>{  
                            const index = item.stage?item.stage.indexOf('pharmacy'):-1
                            return index!=-1
                        },indicator:{
                        color:'error',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                    // {name:"Billing",show_if:(this_,item)=>{  
                    //         return item.stage=='billing'
                    //     },indicator:{
                    //     color:'yellow',
                    //     value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    // }},  
                    // {name:"Paid",show_if:(this_,item)=>{  
                    //         return item.stage=='billing_paid'
                    //     },indicator:{
                    //     color:'success',
                    //     value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    // }}, 
                    
                    {name:"Discharged",show_if:(this_,item)=>{  
                            return item.stage=='discharged'||item.discharged==true
                        },indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }}, 
                ]
            },  
            hospitalStages(){
                let input = this.table_input
                let tab = input?input.current_table_tab:0
                let stages = null
                if (tab==0) {
                    stages = [ 
                    // {code:4,key:"waiting_lab_test",name:"Wait for Lab Test"},
                    // {code:4,key:"waiting_scanning",name:"Wait for Scanning"},
                    // {code:4,key:"waiting_dental",name:"Dental Department"},
                    // {code:4,key:"waiting_maternity",name:"Maternity Ward"},
                    // {code:4,key:"waiting_admission",name:"Admission"},
                    // {code:5,key:"pharmacy",name:"Pharmacy"},
                    {code:6,key:"discharged",name:"Discharged"},
                    ] 
                } else {
                    stages = [   
                    ]
                }  
                return stages
            }, 
            FindStage(){ 
                return key=>{
                    return this.all_stages.find(stage=>stage.key==key)
                }
            },
            FindStageName(){ 
                return key=>{
                    return this.FindStage(key)?this.FindStage(key).name:'...'
                }
            },
            FindStageColor(){ 
                return key=>{
                    return this.FindStage(key)?this.FindStage(key).color:''
                }
            },
            route(){
                return this.$route
            },
            
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("orders",1,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.patient_orders,true) 
                return path
            }, 
            PATH_ITEM_HISTORIES(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.patient_histories,true) 
                return path
            }, 
            HEADER_ITEMS(){
                let input = this.table_input
                let tab = input?input.current_table_tab:null
                let headers = null
                if (tab == 1||tab == 2) {
                    headers = [ 
                        {id:1,name:"General",title:true}, 
                        {id:0,name:"",value:'icon_image',color:"primary",tile:true,icon:"mdi-human-wheelchair",size:35,show:true}, 
                        {id:0,name:"Number",value:'no',show:false},   
                        {id:0,name:"Time",value:'local_created_at_',show:true},         
                        {id:0,name:"Gender",value:"gender",align:"left",show:true},       
                        {id:0,name:"Age",value:"age",align:"center",show:true},       
                        {id:0,name:"Name",value:"customer_name",show:true},       
                        {id:0,name:"Status",value:"stage",show:true},       
                        {id:0,name:"estimated",value:"estimated_time",show:false},       
                        {id:0,name:"Description",value:"description",show:false},       
                        {id:0,name:"update",value:'updated_at_',show:false},   
                        {id:0,name:"action",align:"right",show:true,item:[ 
                            {name:"View Order",action:"view_order",icon:"mdi-database-eye"},  
                        ]}, 
                    ]
                } else {
                    headers = [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",tile:true,icon:"mdi-human-wheelchair",size:35,show:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Time",value:'local_created_at_',show:true},         
                    {id:0,name:"Gender",value:"gender",align:"left",show:true},       
                    {id:0,name:"Age",value:"age",align:"center",show:true},       
                    {id:0,name:"Name",value:"customer_name",show:true},       
                    {id:0,name:"Status",value:"stage",show:false},       
                    {id:0,name:"estimated",value:"estimated_time",show:false},       
                    {id:0,name:"Description",value:"description",show:false},       
                    {id:0,name:"update",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Order",action:"view_order",icon:"mdi-database-eye"},   
                        // {name:"Flag as Processing",action:"flag_as_processing",icon:"mdi-blender"},  
                    ]}, 
                    ]
                    
                } 
                return headers
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },  
            Selectors(){ 
                let Customers = this.CustomersData  
                return  {   
                    Customers: Customers, 
                }
            }, 
            CustomersData(){  
                let Customers = this.Customers
                let JoinedCustomers = this.JoinedCustomers 
                return  JoinedCustomers?JoinedCustomers:Customers  
            } 
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Orders","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "register_patient") {
                        this.REGISTER_PATIENT()
                    }else if (action == "checkin_patient") {
                        this.SELECTED_ORDER={}
                        this.dialog_search_patient = true
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item,option){
                try {     
                    if (action == "edit_item") {
                        this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                        this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                        this.ADDING_ITEM.fields =  DATA.FIELDS.items
                        this.ADDING_ITEM.add_item_action =  {
                            action_do:'Edit',
                            action_doing:'Editing'
                        }
                    }else if (action == "view_order") {
                        this.VIEW_ORDER(item)
                    }else if (action == "flag_as_processing") {
                        this.CONFIRM_UPDATING_ORDER(item,{order_status:"processing"})
                    }else if (action == "flag_as_completed") {
                        this.CONFIRM_UPDATING_ORDER(item,{order_status:"completed"})
                    }else{
                        console.log(action,item,option,'action,item,option..........');
                        this.MBS.actions.dialog(null,true,false,"Coming Soon!..","Current not working. Try again later.",null,"OK") 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            async CLEAR_SELECTED_ITEMS(){
                try {  
                    let res =  await DB.deleteItems({ 
                        name:DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values, 
                        update_locals:true, 
                    })
                    console.log(res,'res clear data......');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLEAR_SELECTED_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            VIEW_ORDER(order){
                try {     
                    this.SELECTED_ORDER = {...order,expand_billing_order:true,expand_details:false}
                    this.input={
                        patient_weight:order?order.patient_weight?order.patient_weight:' ':' ',
                        patient_bp:order?order.patient_bp?order.patient_bp:' ':' ',
                        patient_pulse_rate:order?order.patient_pulse_rate?order.patient_pulse_rate:' ':' ',
                        patient_temperature:order?order.patient_temperature?order.patient_temperature:' ':' ',
                        patient_oxygen_circulation:order?order.patient_oxygen_circulation?order.patient_oxygen_circulation:' ':' ',

                        consultation_info:order?order.consultation_info?order.consultation_info:'':'',
                        patient_examination:order?order.patient_examination?order.patient_examination:'':'',
                        patient_investigation:order?order.patient_investigation?order.patient_investigation:'':'',
                        patient_diagnosis:order?order.patient_diagnosis?order.patient_diagnosis:'':'',
                        patient_management:order?order.patient_management?order.patient_management:'':'',
                         
                        description:order?order.description?order.description:'':'',
                    }
                    this.dialog_view_order = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_UPDATING_ORDER(order,data){
                try {     
                    let key=order?order.key:null 
                    let customer_code=order?order.customer_code:null 
                    let path = this.PATH_ITEM+""+key
                    let path_histories = this.PATH_ITEM_HISTORIES+customer_code+"/"+key
                    let action_code = PAGE_NAME+"=UPDATE-ITEM-ORDER"

                    let new_data = {  
                        ...data
                    }
                    if (new_data.stage=='discharged') {
                        //todo.........
                        let short_order = this.PatientOrders?this.PatientOrders.find(ord=>ord.key == key):null
                        if (short_order) {
                            new_data={
                                ...short_order,
                                ...new_data
                            }
                            action_code = PAGE_NAME+"=DISCHARGE-PATIENT"
                        } else {
                            let mbs_text = this.MBS.text.error_0 
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes, 
                            })
                            return
                        }  
                    } 
                    let mbs_text = this.MBS.text.item_action("Patient","Move")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:action_code,
                            data:new_data,
                            path:path,
                            path_histories:path_histories,
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_BILLING_PATIENT(order_option,bill_sale_order,sale_order){
                try {     
                    // let key=order?order.key:null 
                    // let customer_code=order?order.customer_code:null 
                    // let path = this.PATH_ITEM+""+key
                    // let path_histories = this.PATH_ITEM_HISTORIES+customer_code+"/"+key
                    let action_code = PAGE_NAME+"=BILLING-PATIENT"

                    let mbs_text = this.MBS.text.item_action("Patient Order","Send")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        icon:'mdi-cash-register',
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:'Send & Print Order',  
                        btnNo:"Send Patient Order",  
                        action:{
                            code:action_code,
                            order_option:order_option,
                            bill_sale_order:bill_sale_order,
                            sale_order:sale_order,
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            BILLING_PATIENT(order_option,bill_sale_order,sale_order,print_bill){
                try { 
                    console.log(order_option,'order_option........');
                    console.log(bill_sale_order,'bill_sale_order........');
                    console.log(sale_order,'sale_order........');
                    let selected_order_key=bill_sale_order?bill_sale_order.key:null
                    let sale_order_key=sale_order?sale_order.key:null
                    let order_option_key=order_option?order_option.key:null
                    let customer_key=bill_sale_order?bill_sale_order.customer_key:null
                    let path = this.PATH_ITEM+""+selected_order_key
                    let path2 = this.PATH_ITEM_HISTORIES+""+selected_order_key
                    let order={}
                    if(sale_order){
                        order={
                            ...sale_order,
                            customer_key:customer_key,
                            patient_order_key:selected_order_key,
                            hospital_order:true,
                        }
                    }if(order_option_key){
                        order.order_option=order_option
                        order.order_option_key=order_option_key
                    }
                    console.log(order,'order.....');
 
                    // this.inputLoading = false
                    // return
                    DB.addItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                        data:order
                    }).then(res=>{
                        this.MBS.events.$emit("SUBMIT_ITEM_ORDERS")
                        console.log(res, ' belling..........111111');
                        return this.$store.dispatch("fi_update",{
                            action:this.VALUE_ITEM,
                            path:path2,
                            data:{
                                ...bill_sale_order,
                                sale_order_key:sale_order_key,
                                stage:'billing',
                            }, 
                        })
                    }).then(res=>{
                        console.log(res, ' belling..........222222');
                        return this.$store.dispatch("fi_delete",{
                            action:this.VALUE_ITEM,
                            path:path,
                            data:{
                                ...bill_sale_order,
                                sale_order_key:sale_order_key,
                                stage:'billing',
                            }, 
                        })
                    }).then(res=>{
                        console.log(res, ' belling..........33333');
                        this.inputLoading = false
                        this.dialog_view_order = false
                        this.MBS.events.$emit("SUBMIT_ITEM_ORDERS")
                        this.CLEAR_SELECTED_ITEMS()
                        if (print_bill) {
                            setTimeout(() => { 
                                this.PRINT_ORDER_OPTION(order,'bill_sale_order')
                            }, 500);
                        }
                    
                        //
                        let mbs_text = this.MBS.text.item_action_success("ORDER","Updated")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        let mbs_text = this.MBS.text.item_action_fail("ORDER","Updated")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                    
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'BILLING_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_CHECKIN_PATIENT(order,data,input){
                try {     
                    let customer_key=order?order.key:null
                    let customer_code=order?order.code:null
                    let description=input?input.description:null
                    let medical_scheme_key=input?input.medical_scheme_key:null
                    console.log(input,'1111......');
                    // let day_shift_key=order?order.day_shift_key:null 
                    let moment = this.MBS.date.moment
                    let local_created_at = moment?moment().valueOf():null
                    let new_data = { 
                        customer_key:customer_key,
                        customer_code:customer_code,
                        stage:'checkin',
                        local_created_at:local_created_at,
                        key:local_created_at,
                    }
                    if (medical_scheme_key) {
                        new_data.medical_scheme_key = medical_scheme_key
                    }if (description) {
                        new_data.description = description
                    } 
                    let mbs_text = this.MBS.text.item_action("Patient","Checkin")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=CHECKIN-PATIENT",
                            data:new_data,
                            path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.patient_orders,true)+local_created_at
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_CHECKIN_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RELOAD_ITEM_ORDERS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEM_ORDERS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEM_ORDERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEM_ORDERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },

            
            //ADDING ITEMS
            REGISTER_PATIENT(input){
                try {  
                    this.ADDING_ITEM = {...DATA.ITEMS.PATIENTS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.customers,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.patients
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Register New',
                        action_doing:'Registering New'
                    }  
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.PATIENTS.name,
                            action:"add_item",
                            input:{name:input}
                        }) 
                        
                    }, 50);

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REGISTER_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADDED(res){
                try { 
                    // this.RELOAD_DATA()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SEARCH_PATIENT(key,searched_patient){
                try { 
                    this.search_item=""
                    if (!searched_patient) {
                        const patients = this.CustomersData
                        if (!patients) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"No Patients",
                                text:"We find no patient. Please make sure that you are connected and try again or register patients.",
                                btnYes:"Refresh & Try Again",
                                btnNo:"Register Patient",
                                action:{
                                    code:""
                                }
                            })
                            return 
                        }
                        searched_patient = patients.find(patient=>patient.key==key) 
                    }
                    this.SEARCHED_PATIENT = searched_patient
                    this.dialog_search_patient = false
                    this.dialog_checkin_patient = true
 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SEARCH_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            FIND_CODE(code){
                try { 
                    const patients = this.CustomersData
                    const orders = this.PatientOrdersData
                    const histories = this.PatientHistories 
                    if (orders) {
                        let order = orders.find(item=>item.customer_code == code)
                        if (order) {
                            this.VIEW_ORDER(order) 
                            return
                        }
                        if (patients) {
                            let patient = patients.find(item=>item.code == code)
                            if (patient) {
                                this.SEARCH_PATIENT(null,patient)
                                return
                            }
                        }
                    } 
                    this.MBS.actions.dialog({
                        show:true,
                        title:"Error",
                        text:"Error while scanning the patient ("+code+"). Make sure patient is registered, refresh and try again",
                        btnYes:"Okay"
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SEARCH_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SEARCHED_ITEM(item_key){
                try { 
                    console.log(item_key,'item..............')
                    this.MBS.events.$emit("ADD_SELECTED_SELLING_ITEMS",{
                            item_key:item_key, 
                        })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SEARCH_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            ON_QR_SCAN(code){
                try {
                    if (code) {  
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 

                        }else{ 
                            this.FIND_CODE(code)
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=UPDATE-ITEM-ORDER') {
                            if (action.YES) {  
                                setTimeout(() => { 
                                    this.dialog_view_order=false
                                    let path = action.path 
                                    this.$store.dispatch("fi_update",{
                                        action:this.VALUE_ITEM,
                                        path:path,
                                        data:action.data, 
                                    }).then(res=>{
                                        let mbs_text = this.MBS.text.item_action_success("ORDER","Updated")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        let mbs_text = this.MBS.text.item_action_fail("ORDER","Updated")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=DISCHARGE-PATIENT') {
                            if (action.YES) {  
                                setTimeout(() => { 
                                    this.dialog_view_order=false
                                    let path = action.path 
                                    let path_histories = action.path_histories 

                                    this.$store.dispatch("fi_update",{
                                        action:this.VALUE_ITEM,
                                        path:path, 
                                        data:action.data, 
                                    }).then(res=>{
                                        return this.$store.dispatch("fi_update",{
                                            action:this.VALUE_ITEM,
                                            path:path_histories,
                                            data:action.data, 
                                        })
                                    }).then(res=>{
                                        let mbs_text = this.MBS.text.item_action_success("ORDER","Updated")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        let mbs_text = this.MBS.text.item_action_fail("ORDER","Updated")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=CHECKIN-PATIENT') {
                            if (action.YES) {  
                                setTimeout(() => { 
                                    this.dialog_view_order=false
                                    let path = action.path 
                                    // console.log(action,'action.....');
                                    // return
                                    this.$store.dispatch("fi_update",{
                                        action:this.VALUE_ITEM,
                                        path:path,
                                        data:action.data, 
                                    }).then(res=>{
                                        let mbs_text = this.MBS.text.item_action_success("Patient","Checkin")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                        this.dialog_checkin_patient = false
                                    }).catch(error=>{
                                        let mbs_text = this.MBS.text.item_action_fail("Patient","Checkin")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=BILLING-PATIENT') {
                            if (action.YES) {  
                                setTimeout(() => { 
                                    this.BILLING_PATIENT(action.order_option,action.bill_sale_order,action.sale_order,true)
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {
                                setTimeout(() => { 
                                    this.BILLING_PATIENT(action.order_option,action.bill_sale_order,action.sale_order)
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEM_ORDERS()
                            } else {
                                
                            }
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            beforeDestroy(){
                try {   
                    this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                    this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'beforeDestroy',
                        page:PAGE_NAME, 
                    })
                }
            },
            FEEDBACKS(time){  
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        watch: {  
            search_item(value){ 
                if (value) {
                    this.SEARCH_PATIENT(value) 
                }
            }, 
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
