<template>
    <div>   
        <v-divider></v-divider>
        <mbs-page-tab 
            :items="MY_LINKS"/> 
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>

        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <!-- <v-card >  -->
                        <v-card class="my-2 pa-2 pr-5" outlined>
                            <v-list v-if="ThisAccount">
                                <v-list-item>
                                    <v-list-item-action> <v-icon size="80" class="mr-2">mdi-cash-fast</v-icon> </v-list-item-action> 
                                    <v-list-item-content> 
                                        <v-list-item-title>
                                            <div class="font-weight-bold not-f7 grey--text">{{MBS.actions.TEXT_UP(ThisAccount.name)}} PREPAYMENT ACCOUNT</div> 
                                        </v-list-item-title> 
                                        <v-list-item-subtitle class="font-weight-bold not-f6 --text">{{ ThisAccount.account_number}}</v-list-item-subtitle> 
                                        <v-list-item-subtitle class="font-weight-bold font-italic not-f4  secondary--text">{{ ThisAccount.bank_name}}</v-list-item-subtitle> 
                                    </v-list-item-content> 
                                    <v-list-item-action>
                                        <div class="text-right">
                                            <div class="font-weight-bold not-f5 grey--text">TOTAL BALANCE</div>
                                            <div class="font-weight-bold not-f8 grey--text text--darken-1">
                                                <span class="not-f4">MWK</span>
                                                {{ MBS.actions.money(ThisAccount.balance_amount) 
                                            }}</div>
                                        </div> 
                                    </v-list-item-action>
                                </v-list-item> 
                            </v-list>
                        </v-card> 
                        <v-card v-if="ThisAccount">
                            <mbs-item-manager
                                :table_tabs="TABLE_TABS"
                                :add_vertical_lines="true"
                                :top_label="false"
                                :filter_date="true" 
                                :filter_date_value="'local_created_at'"
                                :filter_default_days="'90'"
                                :name_items=" ThisAccount.name+' '+NAME_ITEM"
                                :name_item="NAME_ITEM"
                                :value_items="VALUE_ITEMS"
                                :value_item="VALUE_ITEM"
                                :field_items="FIELD_ITEMS"
                                :header_items="HEADER_ITEMS" 
                                :data_items="Transactions"   
                                :select_items="DataSource" 
                                :path_item="SALES_PATH"   
                                :total_item_values="TOTAL_ITEM_VALUES"   
    
                                :table_actions="TableAction" 
                                @SELECT_TABLE_ACTION="TABLE_ACTION"
                                @SELECT_ITEM_ACTION="ITEM_ACTION"
                            /> 
                        </v-card>
                        <v-card v-else :height="vsh-350" flat color="transparent">
                            <v-layout row wrap fill-height justify-center align-center>
                                <v-icon>warning</v-icon>
                                <div>Not Found</div>
                            </v-layout>
                        </v-card>
                    <!-- </v-card> -->
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            <!-- TRANSFER MONEY -->
            <v-dialog v-if="dialog_cash_transfer"
                v-model="dialog_cash_transfer"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="xl">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">TRANSFER MONEY</div> 
                        <v-spacer></v-spacer>
                        <v-btn icon  dark @click="dialog_cash_transfer=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card outlined class="ma-5"> 
                        <v-card-text >
                            <br>   
                            <form @submit.prevent="CASH_TRANSFER_TO_ACCOUNT()">
                                <mbs-input-fields  
                                    v-model="input"
                                    :select_items="DataSource" 
                                    :field_items="DATA.FIELDS.cash_transfer" 
                                    />  
                                <v-layout class="ma-0">
                                    <v-spacer></v-spacer>
                                    <v-btn :loading="inputLoading" color="primary" 
                                        large type="submit" class="mt-5 mb-2 mr-1">Submit</v-btn>
                                </v-layout>
                            </form>
                        </v-card-text> 
                    </v-card>
                </v-card> 
            </v-dialog>  
            
            <!-- BANK RECONCILIATION -->
            <v-dialog v-if="dialog_bank_reconciliation"
                v-model="dialog_bank_reconciliation"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="xl">
                    <v-card-title class="primary" >
                        
                        <div class="font-weight-bold b2--text">BANK RECONCILIATION</div> 
                        <v-spacer></v-spacer>
                        <v-btn icon  dark @click="dialog_bank_reconciliation=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text >
                        <!-- <v-layout justify-center>
                            <v-icon   size="120" class="mr-2">mdi-bank-transfer</v-icon>
                        </v-layout> -->
                        <br>   
                        <form @submit.prevent="BANK_RECONCILIATION()">
                            <mbs-input-fields  
                                v-model="input"
                                :select_items="DataSource" 
                                :field_items="DATA.FIELDS.bank_reconciliation" 
                                />  
                            <v-layout class="ma-0">
                                <v-spacer></v-spacer>
                                <v-btn   :loading="inputLoading" color="primary" 
                                    large type="submit" class="mt-5 mb-2 mr-1">Submit</v-btn>
                            </v-layout>
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.INCOMES.values    
    export default {
        name:PAGE_NAME, 
        props:['id','account_code'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.CASHBOOKS.names,
                NAME_ITEM:DATA.ITEMS.CASHBOOKS.name,
                VALUE_ITEMS:DATA.ITEMS.CASHBOOKS.values,
                VALUE_ITEM:DATA.ITEMS.CASHBOOKS.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],  
                TOTAL_ITEM_VALUES:[  
                    {name:'Total Deposits',value:'total_deposit',show_value:'total_deposit_',money:true},
                    {name:'Total Payments',value:'total_paid',show_value:'total_paid_',money:true},
                    // {name:'Total Balance',value:'total_balance',show_value:'total_balance_',money:true},
                ],
                dialog_cash_transfer:false,
                dialog_bank_reconciliation:false,
                input:{},
                inputLoading:false,
                DATA:DATA,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
             
            /////////
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.payments,true) 
                return path
            },
            back_MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("prepayment",3,true) 
                return thisLinks
            }, 
            MY_LINKS(){  
                let accounts = this.ReceivableAccountData 
                if(!accounts){return null}
                let allLinks = []
                accounts.forEach(element => {
                    allLinks.push({
                        name:element.name,
                        link:this.MBS.actions.COMPANY_LINK("/office/accounts/prepayment/"+element.code,true),
                        icon:'link',
                        image:'/future_tours/svg/SVG/my_parcel.svg',
                        image_background_color:'primary lighten-2',
                        image_size:'80',
                        
                    })
                });  
                return allLinks
            },
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            },  
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'created_at_',show:true},     
                    {id:0,name:"updated Date",value:'updated_at_',show:false},   
                    {id:0,name:"Payee",value:"payee_name",show:true},       
                    {id:0,name:"Transaction",value:"order_type_name",show:true},       
                    {id:0,name:"Transaction key",value:"transaction_key",align:"center",show:false},     
                    {id:0,name:"description",value:"description",show:true},         
                    {id:0,name:"Deposit",value:"total_deposit_",align:"right",show:true},       
                    {id:0,name:"Payment",value:"total_paid_",align:"right",show:true},       
                    {id:0,name:"Balance",value:"total_balance_",align:"right",show:true},       
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Sales Report",action:"view_sales_report",icon:"mdi-eye",show_if:item=>item.order_type==DATA.ITEMS.DAYS_SHIFTS_SALES.values}, 
                        {name:"View Transactions",action:"view_transaction",icon:"mdi-eye",show_if:item=>item.order_type!=DATA.ITEMS.DAYS_SHIFTS_SALES.values}, 
                        // {name:"Edit Receivable Account",action:"edit_item",icon:"edit"}, 
                        // {name:"Archive Receivable Account",action:"archive_items",icon:"mdi-delete"}, 
                    ]},  
                ]
            }, 
            TABLE_TABS(){
                return [  
                    {
                        name:"Deposits (Debts)",
                        // show_if:(this_,item)=>this_.MBS.actions.SIZE(item.unpaid_invoices),
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{ 
                                let size = 0
                                if (data) {
                                    data.forEach(item => {
                                        size = size+MBS.SIZE(item.unpaid_invoices)
                                    }); 
                                } 
                                return size
                            }
                        }
                    },
                    {
                        name:"Payments (Credits)",
                        // show_if:(this_,item)=>this_.MBS.actions.SIZE(item.paid_invoices),
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{
                                let size = 0
                                if (data) {
                                    data.forEach(item => {
                                        size = size+MBS.SIZE(item.paid_invoices)
                                    }); 
                                } 
                                return size
                            }
                        }
                    }, 
                ]
            },
            TableAction(){
                // let Transactions = this.Transactions
                // if (this.MBS.actions.SIZE(Transactions)>0) {
                //     return []
                // }  
                return [ 
                    {type:"action",btn:true,icon:'mdi-bank-transfer',action:"bank_reconciliation",color:"secondary", outlined:false,  text:"Reconciliation"},   
                    {type:"action",btn:true,icon:'mdi-cash-fast',action:"cash_transfer",color:"secondary", outlined:false,  text:"Money Transfer"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},
                    {type:"action",icon:"mdi-pencil",action:"add_opening_balance",color:"secondary", outlined:false,  text:"Edit Account"},  
                    {type:"action",icon:"mdi-delete",action:"archive",color:"secondary", outlined:false,  text:"Archive Account"},  
                ]
            },  
            ThisAccount(){  
                let account_code = this.account_code
                let ReceivableAccountData = this.ReceivableAccountData 
                if (!account_code) {
                    return  null
                }else if (account_code == 0) {
                    return {
                        name:"Cash Account",
                        code:0
                    }
                } else {
                    if(!ReceivableAccountData){return null}
                    let moneyAccount = ReceivableAccountData.find(account=>{
                        return account.code == account_code
                    }) 
                    return moneyAccount
                } 
            }, 

            //
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
            }),
            ...mapState({
                ReceivablesAccounts: state=> state.items[(DATA.ITEMS.RECEIVABLES.values).toUpperCase()],
                JoinedReceivablesAccounts: state=> state.join[(DATA.ITEMS.RECEIVABLES.values).toUpperCase()],
                PaidInvoices: state=> state.items[(DATA.ITEMS.PAID_INVOICES.values).toUpperCase()],
                JoinedPaidInvoices: state=> state.join[(DATA.ITEMS.PAID_INVOICES.values).toUpperCase()],
                UnpaidInvoices: state=> state.items[(DATA.ITEMS.UNPAID_INVOICES.values).toUpperCase()],
                JoinedUnpaidInvoices: state=> state.join[(DATA.ITEMS.UNPAID_INVOICES.values).toUpperCase()],
                
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                 
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()],
            }),  
            ReceivableAccountData(){ 
                let items = []
                let DefaultMoneyAccount = null 
                let ReceivablesAccounts = this.JoinedReceivablesAccounts?this.JoinedReceivablesAccounts:this.ReceivablesAccounts
                if(DefaultMoneyAccount){
                    items = [...DefaultMoneyAccount]
                }
                if(ReceivablesAccounts){ 
                    ReceivablesAccounts.forEach(account => {
                        let index = items.findIndex(def=>def.key == account.key)
                        if (index == -1) {
                            items.push(account)
                        } else {
                            items[index] = {...items[index],...account}
                        }
                        
                    });
                }  
                return items
            },
            PaidInvoicesData(){ 
                return this.JoinedPaidInvoices?this.JoinedPaidInvoices:this.PaidInvoices
            },
            UnpaidInvoicesData(){ 
                return this.JoinedUnpaidInvoices?this.JoinedUnpaidInvoices:this.UnpaidInvoices
            },
            SaleOrdersData(){ 
                return this.JoinedSaleOrders?this.JoinedSaleOrders:this.SaleOrders
            },
            ExpensesData(){ 
                return this.JoinedExpenses?this.JoinedExpenses:this.Expenses
            }, 
            DaysShiftsData(){ 
                return this.JoinedDaysShifts?this.JoinedDaysShifts:this.DaysShifts
            },  

            OverpaidInvoices(){
                let paid = this.PaidInvoicesData
                console.log(paid,'paid..................');
                console.log(this.ThisAccount,'ThisAccount..................');
                return paid
            },
            Transactions(){   
                let Expenses = this.FilteredExpenses 
                let Payments = this.OverpaidInvoices 
                let items = []
                let moment = this.MBS.date.moment

                 
                if (Expenses) { 
                    Expenses.forEach(element => {
                        items.push({
                            ...element,
                            order_type:DATA.ITEMS.EXPENSES.values,
                            local_created_at :  moment?moment(element.created_at).valueOf():element.created_at
                        })
                    }); 
                }if (Payments) { 
                    Payments.forEach(element => {
                        items.push({
                            ...element,
                            order_type:DATA.ITEMS.PAYMENTS.values,
                            local_created_at :  moment?moment(element.created_at).valueOf():element.created_at
                        })
                    }); 
                }
                  
                if(this.MBS.actions.SIZE(items)==0){return null} 
                let join =  this.MBS.actions.JOIN_CASHBOOKS(items)  

                // console.log(join,'join.....');
                return join
            },   
            DataSource(){ 
                let ReceivablesAccounts = this.ReceivableAccountData  
                return { 
                    ReceivablesAccounts:ReceivablesAccounts, 
                }
            },
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    let account = this.ThisAccount
                    if (action == "cash_transfer") {
                        this.input = { }
                        if (account) {
                            this.input = { 
                                current_balance:this.toNumber(account.balance),
                                from_money_account_key:account.key,
                            }
                        }
                        this.dialog_cash_transfer = true
                    }else if (action == "bank_reconciliation") {
                        this.input = {}
                        if (account) {
                            this.input = { 
                                current_balance:this.toNumber(account.balance),
                                money_account_key:account.key
                            }
                        }
                        this.dialog_bank_reconciliation = true
                    }else if (action == "received_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    // let link = "/stock/stock-control/"+(item?item.key:null)
                    // const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    console.log(item,"item...-.");
                    let order_type = item?item.order_type:null
                    if (action == "view_sales_report") {
                        let link = "/day-shift-report/"+(item?item.key:null)
                        const path = this.MBS.actions.COMPANY_LINK(link,true)  
                        this.MBS.actions.go(path)
                    }
                    if (action == "view_transaction") {
                        if (order_type === DATA.ITEMS.PAYMENTS.value) {
                            let receipt_key = item?item.key:null
                            this.MBS.actions.go("/payment-receipt/"+receipt_key)
                        }else if (order_type === DATA.ITEMS.SALE_ORDERS.value) {
                            let receipt_key = item?item.key:null
                            this.MBS.actions.go("/sales-receipt/"+receipt_key)
                        } else {
                            let mbs_text = this.MBS.text.option_not_available("View transactions")
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes,
                            }) 
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_OPENING_BALANCE(){
                try {
                    let input = this.input
                    let amount = input?this.toNumber(input.amount):null
                    let code = input?input.code:null
                    let date = input?input.date:null
                    let description = input?input.description:null 
                    let payment_details={
                        code:code,
                        amount:amount,
                        transaction_key:code,
                        transaction_type:DATA.ITEMS.OPENING_BALANCE.value,
                    }
                    if (date) {
                        payment_details.date = date
                    }if (description) {
                        payment_details.description = description
                    }
                    
                    if (!code || !amount) {
                        console.log(this.input,'payload required.......');
                    }else{
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.OPENING_BALANCE.value,"Add")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=ADD-OPENING-BALANCE",
                                data:payment_details
                            }
                        })

                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_OPENING_BALANCE',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
            CASH_TRANSFER_TO_ACCOUNT(){
                try {
                    let us = this.us
                    let uid = us?us.uid:null
                    let company = this.SELECTED_COMPANY
                    let company_key = company?company.company_key:null
                    let input = this.input
                    let amount = input?this.toNumber(input.amount):null
                    let current_balance = input?this.toNumber(input.current_balance):null
                    let code = input?input.code:null
                    let from_money_account_key = input?input.from_money_account_key:null
                    let to_money_account_key = input?input.to_money_account_key:null
                    let date = input?input.date:null
                    let description = input?input.description:null 
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null
                    let payment_details={
                        code:code,
                        amount:amount,
                        from_money_account_key:from_money_account_key,
                        to_money_account_key:to_money_account_key,
                        current_balance:current_balance,
                        local_created_at:key,
                        company_key:company_key,
                        created_uid:uid,
                        transaction_type:"cash_transfer",
                    }
                    if (date) {
                        payment_details.date = date
                    }if (description) {
                        payment_details.description = description
                    }
                    
                    if (!code || !company_key) {
                        console.log(this.input,'payload required.......');
                    }else{
                        let mbs_text = this.MBS.text.item_action("Money","Transfer")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-bank",
                            title:mbs_text.title,
                            text:mbs_text.text+" Enter your password to confirm this action.",
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=BANK-CASH-TRANSFER",
                                data:payment_details
                            }
                        })

                        console.log(payment_details,'payment_details......');

                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_TRANSFER_TO_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
            BANK_RECONCILIATION(){
                try {
                    let us = this.us
                    let uid = us?us.uid:null
                    let company = this.SELECTED_COMPANY
                    let company_key = company?company.company_key:null
                    let input = this.input
                    let amount = input?this.toNumber(input.amount):null
                    let current_balance = input?this.toNumber(input.current_balance):null
                    let code = input?input.code:null
                    let money_account_key = input?input.money_account_key:null
                    let date = input?input.date:null
                    let description = input?input.description:null 
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null
                    let payment_details={
                        code:code,
                        amount:amount,
                        money_account_key:money_account_key,
                        current_balance:current_balance,
                        local_created_at:key,
                        company_key:company_key,
                        created_uid:uid,
                        transaction_type:"reconciliation_balance",
                    }
                    if (date) {
                        payment_details.date = date
                    }if (description) {
                        payment_details.description = description
                    }
                    
                    if (!code || !company_key) {
                        console.log(this.input,'payload required.......');
                    }else{
                        let mbs_text = this.MBS.text.item_action("Money","Transfer")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-bank",
                            title:mbs_text.title,
                            text:mbs_text.text+" Enter your password to confirm this action.",
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=BANK-RECONCILIATION",
                                data:payment_details
                            }
                        })

                        console.log(payment_details,'payment_details......');

                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'BANK_RECONCILIATION',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-OPENING-BALANCE') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.$store.dispatch("fi_add",{
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_cash_transfer = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.OPENING_BALANCE.value,"Adding")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.OPENING_BALANCE.value,"Adding")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=BANK-RECONCILIATION') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.MBS.actions.POST_REQUEST({
                                        action:this.VALUE_ITEM,
                                        path:"bankReconciliation",
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        console.log(res,'res............');
                                        this.inputLoading = false
                                        this.dialog_cash_transfer = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.OPENING_BALANCE.value,"Adding")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.OPENING_BALANCE.value,"Adding")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=BANK-CASH-TRANSFER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.MBS.actions.POST_REQUEST({
                                        action:this.VALUE_ITEM,
                                        path:"bankCashTransfer",
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        console.log(res,'res............');
                                        this.inputLoading = false
                                        this.dialog_cash_transfer = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.MONEY_ACCOUNTS.value,"Transferring")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.OPENING_BALANCE.value,"Transfering")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },   
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
