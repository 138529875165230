<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 36.19 38.24"
  :fill="iconColor" xml:space="preserve">

    <path d="M9.28,3.18,13.5,2.05l.22.79.54,2c.11.43.29.53.73.42l2.25-.6c.43-.12.54-.31.43-.73-.24-.92-.49-1.84-.75-2.8L21.15,0l3.3,12.32L12.58,15.5Z"/><path d="M15.58,38.24a5.51,5.51,0,1,1,5.5-5.52A5.52,5.52,0,0,1,15.58,38.24ZM12.9,32.75a2.68,2.68,0,1,0,2.66-2.7A2.68,2.68,0,0,0,12.9,32.75Z"/><path d="M7.91,6.66l1.27-.34C11,13,12.76,19.69,14.55,26.38l-1.26.34Q10.61,16.7,7.91,6.66Z"/><path d="M21.94,31.71l-.34-1.26,14.25-3.82.34,1.26Z"/><path d="M7.42,32.87a8.2,8.2,0,0,1,2.87-6.33.83.83,0,0,1,.21-.16.45.45,0,0,1,.57.67,2.35,2.35,0,0,1-.33.3,7.18,7.18,0,0,0-2.3,6.59c.05.35-.08.58-.36.62s-.49-.13-.54-.48S7.45,33.18,7.42,32.87Z"/><path d="M20.8,14.25l.81,3-2.05.55-.82-3Z"/><path d="M.66,8.7A.63.63,0,0,1,0,8.16a.62.62,0,0,1,.48-.7L2.31,7l1.75-.46A.63.63,0,1,1,4.4,7.72l-3.6,1Z"/><path d="M15.06,4.3l-.67-2.49L16,1.37l.67,2.48Z"/><path d="M15.58,34.49a1.76,1.76,0,0,1-1.77-1.75,1.77,1.77,0,1,1,3.53,0A1.75,1.75,0,0,1,15.58,34.49Z"/><path d="M26.72,12.66l-5,1.35c0,.13.06.22.08.31.28,1,.55,2,.82,3,.1.4,0,.6-.4.7l-2.73.73c-.39.1-.58,0-.69-.41l-.48-1.8c-.14-.51-.28-1-.42-1.55l-5,1.36,2.65,9.91.17,0a6.36,6.36,0,0,1,5.43,3.1c.11.19.22.16.38.12l6.44-1.72,2.7-.73Q28.66,19.86,26.72,12.66ZM25.59,24.45a.51.51,0,0,1,.34-.62l1.31-.39a.45.45,0,0,1,.23,0h0a.48.48,0,0,1,.36.34.43.43,0,0,1,0,.25.2.2,0,0,1,0,.13s0,0,0,0a.18.18,0,0,1-.05.07.6.6,0,0,1-.21.14l-1.31.38A.5.5,0,0,1,25.59,24.45ZM28.08,26l-1.31.39a.5.5,0,0,1-.62-.34.62.62,0,0,1,0-.27.35.35,0,0,1,.08-.16.49.49,0,0,1,.13-.12l0,0h0a.15.15,0,0,1,.07,0L27.8,25a.5.5,0,0,1,.62.33A.51.51,0,0,1,28.08,26Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
