import DATA from "./DATA"  
import  { 
    toDateTime, 
    toDate,
    toTime,
    TEXT_UP,
    TEXT_UP1,
    TEXT_UP11,
    TEXT_UP0,
    TEXT_EQ,
    TEXT_SPLIT_N,
    TEXT_SPACE0,
    SIZE, 
} from '../plugins/MBS_actions'  
let NAME = "CONDUCTOR"
 
const conductor = store=>{  
    store.subscribe(mutation=>{ 
        if (
            mutation.type === "setItems"
            ||mutation.type === "setLocals"
            ||mutation.type === "updateItemsByKey"
            ) {
            let name = TEXT_UP(mutation.payload?mutation.payload.name:null)  
            let isUpdates = mutation.type === "updateItemsByKey"?true:false

            //JOIN
            if (name) { 
                //Rest item before join
                if (!isUpdates) {
                    store.commit("setJoinedItems",{
                        name:name,
                        data:null, 
                    }) 
                }
                if (name===DATA.ITEMS.LOCAL_SALE_ORDERS.values.toUpperCase()) { 
                    store.dispatch("join",{
                        ...mutation.payload,
                        name:"ALL_"+name,
                        add:true,
                        isUpdates:isUpdates
                    }).then(res=>{ 
                    }).catch(error=>{
                        console.log(error,'error.....');
                    })       
                }
                if (name===DATA.ITEMS.LOCAL_ITEM_ORDERS.values.toUpperCase()) { 
                    store.dispatch("join",{
                        ...mutation.payload,
                        add:true,
                        isUpdates:isUpdates
                    }).then(res=>{ 
                    }).catch(error=>{
                        console.log(error,'error.....');
                    })       
                }
                //Join Items
                let index = name.indexOf("ALL_") 
                // if (index === 0 || name === DATA.ITEMS.LOCAL_SALE_ORDERS.values ) {    
                if (index === 0 ) {    
                    setTimeout(() => { 
                        store.dispatch("join",{
                            ...mutation.payload,
                            add:true,
                            isUpdates:isUpdates
                        })
                        .then(res=>{
                            if (name === "ALL_ITEMS") { 
                                //console.log(res,"Join success---");
                                //console.timeEnd("JOIN----:"+name+":---")
                            }
                        }).catch(error=>{
                            if (name === "ALL_ITEMS") {  
                                //console.log(error,"join error---");
                                //console.timeEnd("JOIN----:"+name+":---")
                            }
                        })   
                    }, 1);
                } 
                
                //Join Items
                let local_index = name.indexOf("LOCAL_") 
                if (local_index === 0) {    
                    setTimeout(() => { 
                        store.dispatch("join",{
                            ...mutation.payload,
                            add:true,
                            isUpdates:isUpdates
                        })
                        .then(res=>{
                            if (name === "ALL_ITEMS") { 
                                //console.log(res,"Join success---");
                                //console.timeEnd("JOIN----:"+name+":---")
                            }
                        }).catch(error=>{
                            if (name === "ALL_ITEMS") {  
                                //console.log(error,"join error---");
                                //console.timeEnd("JOIN----:"+name+":---")
                            }
                        })   
                    }, 1);
                } 
            } 

            if (false) {
                //CALCULATE STOCK
                if (name===TEXT_UP("ALL_"+DATA.ITEMS.STOCK_ORDERS.values)) { 
                    setTimeout(() => { 
                        //console.time("calculate_stock")
                        store.dispatch("calculate_stock",{
                            ...mutation.payload,
                            add:true
                        })
                        .then(res=>{
                            //console.log(res,'success...');
                            //console.timeEnd("calculate_stock")
                        }).catch(error=>{
                            //console.log(error,'error....');
                            //console.timeEnd("calculate_stock")
                        })  
                    }, 1);
                } 
                // CALCULATE SALES 
                if (name===TEXT_UP("ALL_"+DATA.ITEMS.SALE_ORDERS.values)) { 
                    setTimeout(() => { 
                        //console.time("calculate_sales")
                        store.dispatch("calculate_sales",{
                            ...mutation.payload,
                            add:true
                        })
                        .then(res=>{
                            //console.log(res,'success...');
                            //console.timeEnd("calculate_sales")
                        }).catch(error=>{
                            //console.log(error,'error....');
                            //console.timeEnd("calculate_sales")
                        })  
                    }, 1);
                } 
                
                //CALCULATE ITEM BY STOCK
                if (name==="ALL_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase()) {
                    setTimeout(() => { 
                        //console.time("Calculate by stock**")
                        store.dispatch("calculate_items",{ 
                            add:true,
                            all:true,
                        }).then(res=>{
                            //console.log(res,'success...'); 
                            //console.timeEnd("Calculate by stock**")
                        }).catch(error=>{
                            //console.log(error,'error....'); 
                            //console.timeEnd("Calculate by stock**")
                        }) 
                         
                    }, 1);   
                }
                
                //CALCULATE ITEM BY SALES
                if (name==="ALL_"+DATA.ITEMS.SALES.values.toUpperCase()) {
                    setTimeout(() => { 
                        //console.time("Calculate by Sales+++++")
                        store.dispatch("calculate_items",{ 
                            add:true,
                            all:true,
                        }).then(res=>{
                            //console.log(res,'success...'); 
                            //console.timeEnd("Calculate by Sales+++++")
                        }).catch(error=>{
                            //console.log(error,'error....'); 
                            //console.timeEnd("Calculate by Sales+++++")
                        }) 
                         
                    }, 1);   
                } 
            }   
        } 
        if (mutation.type === "setJoinedItems"||mutation.type === "updateJoinedItemsByKey") {
            let name = TEXT_UP(mutation.payload?mutation.payload.name:null)
            let data = mutation.payload?mutation.payload.data:null 
            let isUpdates = mutation.type === "updateJoinedItemsByKey"?true:false
            //CALCULATE 
            if (false) {
                if (name) {
                    //Rest item before calculate
                    store.commit("setCalculatedItems",{
                        name:name,
                        data:null,
                    }) 
                    if (name===TEXT_UP("ALL_"+DATA.ITEMS.ITEMS.values)) {
                        // CALCULATE ITEMS  AFTER JOIN  
                        //console.log("c--"+name+SIZE(data));
                        if (SIZE(data)>0) {
                            setTimeout(() => {
                                //console.time("calculate join items")
                                store.dispatch("calculate_items",{ 
                                    data:data,
                                    add:true,
                                    all:true,
                                }).then(res=>{
                                    //console.log(res,"s---");
                                    //console.timeEnd("calculate join items")
                                }).catch(error=>{
                                    //console.log(error,"e---");
                                    //console.timeEnd("calculate join items")
                                }) 
                            }, 1); 
                        }
                    }  
                }  
            }
        } 
    }) 
}  
export  {  
    conductor
};