<template>
    <div>   
        <v-divider></v-divider> 
        <mbs-page-tab 
            :items="MY_LINKS"/> 
        <v-divider></v-divider> 
        <v-divider></v-divider> 
        <v-divider></v-divider> 
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-item-manager
                            :filters="FILTERS"
                            :table_title="'Manage Methods'"
                            :table_title_icon="'mdi-account-cash'"
                            :add_vertical_lines="true"
                            v-model="input"
                            :filter_date="true"
                            :filter_date_only_display="true"
                            :filter_default_days="'90'"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :field_items="DATA.FIELDS.payment_methods"
                            :header_items="HEADER_ITEMS" 
                            :data_items="PaymentMethodsData"   
                            :is_joined="true"  
                            :path_item="PATH_ITEM"   
                            :select_items="Selectors"  

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
  import DATA from '../../../../plugins/DATA' 
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.PAYMENT_METHODS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.PAYMENT_METHODS.names,
                NAME_ITEM:DATA.ITEMS.PAYMENT_METHODS.name,
                VALUE_ITEMS:DATA.ITEMS.PAYMENT_METHODS.values,
                VALUE_ITEM:DATA.ITEMS.PAYMENT_METHODS.value,       
                table_actions:[
                    {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add PAYMENT METHOD"},  
                ],
                DATA:DATA,
                input:null,
                filter_date:null,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: { 
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            },  
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("company",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.payment_methods,true) 
                return path
            }, 
            
            moment(){  
                return this.MBS.date.moment
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                MoneyAccounts: state=> state.items[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                JoinedMoneyAccounts: state=> state.join[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()],
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
            }),  

            //data
            PaymentMethodsData(){
                let day_payments = this.CalculatedDayPaymentMethods
                let stockOrders = this.PaidStockOrders
                let expenses = this.PaidExpenses
                let payments = this.DateFiltered_Payments 
                let items = []
                let payment_methods = []
                let DefaultPaymentMethod = this.DefaultPaymentMethod 
                let PaymentMethods= this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
                if(DefaultPaymentMethod){
                    items = [...DefaultPaymentMethod]
                }
                if(PaymentMethods){
                    items= [
                        ...items,
                        ...PaymentMethods
                    ]
                } 

                if (day_payments) {  
                    items.forEach(method => {
                        //transactions
                        let day_transactions = day_payments?day_payments.filter(p=>p.code==method.code):null
                        let stock_order_transactions = stockOrders?stockOrders.filter(p=>p.payment_method_code==method.code):null
                        let expenses_transactions = expenses?expenses.filter(p=>p.payment_method_code==method.code):null
                        let in_payments_transactions = payments?payments.filter(p=>p.payment_method_code==method.code && p.transaction_type == DATA.ITEMS.INVOICES.value):null
                        let out_payments_transactions = payments?payments.filter(p=>p.payment_method_code==method.code&& p.transaction_type != DATA.ITEMS.INVOICES.value):null
                        
                        //amounts
                        let day_amount = 0
                        let stock_order_amount = 0
                        let expenses_amount = 0
                        let in_payments_amount = 0
                        let out_payments_amount = 0

                        if (day_transactions) {
                            day_transactions.forEach(transaction => {
                                day_amount = day_amount+this.toNumber(transaction.amount)
                            });
                        }
                        if (expenses_transactions) {
                            expenses_transactions.forEach(transaction => {
                                expenses_amount = expenses_amount+this.toNumber(transaction.amount)
                            });
                        } 
                        if (stock_order_transactions) {
                            stock_order_transactions.forEach(transaction => {
                                stock_order_amount = stock_order_amount+this.toNumber(transaction.total_price)
                            });
                        }
                        if (in_payments_transactions) {
                            in_payments_transactions.forEach(transaction => {
                                in_payments_amount = in_payments_amount+this.toNumber(transaction.amount)
                            });
                        }
                        if (out_payments_transactions) {
                            out_payments_transactions.forEach(transaction => {
                                out_payments_amount = out_payments_amount+this.toNumber(transaction.amount)
                            });
                        }


                        let received = in_payments_amount+day_amount
                        let paid = out_payments_amount+stock_order_amount+expenses_amount
                        let balance = received  - paid
                        payment_methods.push({
                            ...method,
                            received:received,
                            received_:this.MBS.actions.money(received),
                            paid:paid,
                            paid_:this.MBS.actions.money(paid),
                            balance:balance,
                            balance_:this.MBS.actions.money(balance),
                        })
                    });  
                } else {
                    payment_methods = items
                } 
                return payment_methods
            },
            MoneyAccountsData(){ 
                let items = []
                let DefaultMoneyAccount = this.DefaultMoneyAccount 
                let MoneyAccounts = this.JoinedMoneyAccounts?this.JoinedMoneyAccounts:this.MoneyAccounts
                if(DefaultMoneyAccount){
                    items = [...DefaultMoneyAccount]
                }
                if(MoneyAccounts){
                    items= [
                        ...items,
                        ...MoneyAccounts
                    ]
                }
                return items
            },
            ExpensesData(){ 
                return this.JoinedExpenses?this.JoinedExpenses:this.Expenses
            },
            QuotationsAndInvoicesData(){ 
                return this.JoinedQuotationsAndInvoices?this.JoinedQuotationsAndInvoices:this.QuotationsAndInvoices
            },
            DaysShiftsData(){ 
                return this.JoinedDaysShifts?this.JoinedDaysShifts:this.DaysShifts
            }, 
            PaymentsData(){ 
                return this.JoinedPayments?this.JoinedPayments:this.Payments
            },
            StockOrdersData(){ 
                return this.JoinedStockOrders?this.JoinedStockOrders:this.StockOrders
            },
            QuotationsAndInvoicesData(){ 
                return this.JoinedQuotationsAndInvoices?this.JoinedQuotationsAndInvoices:this.QuotationsAndInvoices
            }, 
            Selectors(){ 
                let MoneyAccounts = this.MoneyAccountsData
                let PaymentMethods = this.PaymentMethodsData
                return {
                    MoneyAccounts:MoneyAccounts,
                    PaymentMethods:PaymentMethods
                }
            },  
            FILTERS(){ 
                return [   
                    {
                        type:"autocomplete",value:"key",prepend_inner_icon:'mdi-account-cash',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Payment",
                        select:{
                            name:'PaymentMethods',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"money_account_code",barcode:true,prepend_inner_icon:'mdi-cash-sync',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Account",
                        select:{
                            name:'MoneyAccounts',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    },     
                ]
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:false},     
                    {id:0,name:"Name",value:"name",show:true},       
                    {id:0,name:"Account",value:"money_account_name",show:true},       
                    {id:0,name:"Description",value:"description",show:true},       
                    {id:0,name:"Received (MWK)",value:"received_",align:"right",show:true},       
                    {id:0,name:"Paid (MWK)",value:"paid_",align:"right",show:true},       
                    // {id:0,name:"Balance (MWK)",value:"balance_",align:"right",show:true},       
                    {id:0,name:"create",value:'created_at_',show:false},    
                    {id:0,name:"update",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"edit"}, 
                        {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            }, 

            //date filtered
            DateFiltered_Expenses(){ 
                let items = this.ExpensesData 
                let filter_date = this.filter_date
                let from_date = filter_date?filter_date.from_date:null
                let to_date = filter_date?filter_date.to_date:null 
                if(!items){return null}
                let filtered_item = items.filter(item => {
                    let mDate = null
                    let date = null 
                    date = this.moment(item.created_at).format("yyyy-MM-D")
                    mDate = this.moment(date) 
                    let between = mDate.isBetween(from_date,to_date,'','[]')  
                    return between
                });  
                return filtered_item
            }, 
            DateFiltered_StockOrders(){ 
                let items = this.StockOrdersData 
                let filter_date = this.filter_date
                let from_date = filter_date?filter_date.from_date:null
                let to_date = filter_date?filter_date.to_date:null 
                if(!items){return null}
                let filtered_item = items.filter(item => {
                    let mDate = null
                    let date = null 
                    date = this.moment(item.created_at).format("yyyy-MM-D")
                    mDate = this.moment(date) 
                    let between = mDate.isBetween(from_date,to_date,'','[]')  
                    return between
                });  
                return filtered_item
            }, 
            DateFiltered_DaysShifts(){ 
                let items = this.DaysShiftsData 
                let filter_date = this.filter_date
                let from_date = filter_date?filter_date.from_date:null
                let to_date = filter_date?filter_date.to_date:null 
                if(!items){return null}
                let filtered_item = items.filter(item => {
                    let mDate = null
                    let date = null 
                    date = this.moment(item.local_close_at).format("yyyy-MM-D")
                    mDate = this.moment(date) 
                    let between = mDate.isBetween(from_date,to_date,'','[]')  
                    return between
                });  
                console.log(items,';;;;;');
                return filtered_item
            }, 
            DateFiltered_Payments(){ 
                let items = this.PaymentsData 
                let filter_date = this.filter_date
                let from_date = filter_date?filter_date.from_date:null
                let to_date = filter_date?filter_date.to_date:null 
                if(!items){return null}
                let filtered_item = items.filter(item => {
                    let mDate = null
                    let date = null 
                    date = this.moment(item.created_at).format("yyyy-MM-D")
                    mDate = this.moment(date) 
                    let between = mDate.isBetween(from_date,to_date,'','[]')  
                    return between
                });  
                return filtered_item
            }, 


            PaidStockOrders(){
                let orders = this.DateFiltered_StockOrders 
                if(!orders){return null} 
                let filtered_items = orders.filter(order=>{
                    return !order.invoice_code &&
                        order.order_type == DATA.ITEMS.STOCK_RECEIVES.value  &&
                        order.payment_method_code != "bill"
                })
                return filtered_items
            },
            PaidExpenses(){
                let orders = this.DateFiltered_Expenses 
                if(!orders){return null} 
                let filtered_items = orders.filter(order=>{
                    return order.payment_method_code != "bill"
                })
                return filtered_items
            },

            
            DaysShiftsCashPayments(){
                let days = this.DateFiltered_DaysShifts  
                if(!days){return null} 
                let cashes = []
                days.forEach(element => {
                    cashes.push({
                        key:element.key,
                        transaction_key:element.key,
                        transaction_type:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        order_type:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        name:element.close_date_time_,
                        amount:element.total_closing_cash,
                        operating_date:element.operating_date,
                        created_at:element.local_close_at,
                        created_uid:element.created_uid,
                    })
                }); 
                return this.MBS.actions.SIZE(cashes)>0?cashes:null
            }, 
            DaysShiftsOtherPayments(){
                let days = this.DateFiltered_DaysShifts 
                return code=>{ 
                    let cashes = [] 
                    if(!days){return null}  
                    days.forEach(day => {
                        let total_cashup = this.MBS.actions.CALCULATE_TOTAL_CASHUPS(day.joined_cashups)
                        let all_other_option_payments = total_cashup?total_cashup.all_other_option_payments:null
                        if (all_other_option_payments) {
                            let payments = all_other_option_payments.filter(option=>{
                                return option.money_account_code  == code
                            }) 
                            if (payments) {
                                let details = {
                                    key:day.key,
                                    transaction_key:day.key,
                                    transaction_type:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                                    order_type:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                                    name:day.close_date_time_,
                                    // amount:payment.amount,
                                    amount:0,
                                    operating_date:day.operating_date,
                                    created_at:day.local_close_at,
                                    created_uid:day.created_uid,
                                    description:day.closed?"Closed":'Running.....',
                                }
                                payments.forEach(payment => {
                                    details.amount = details.amount+payment.amount
                                });
                                cashes.push(details) 
                            }
                        }  
                    });


                    return this.MBS.actions.SIZE(cashes)>0?cashes:null
                }
            }, 
            CalculatedDayPaymentMethods(){
                let days = this.DateFiltered_DaysShifts 
                let all_payments = [] 
                if(!days){return null}  
                days.forEach(day => {
                    //Add Cash payment
                    let cash_index = all_payments.findIndex(day=>day.code=="cash")
                    if (cash_index == -1) {
                        all_payments.push({
                            code:"cash",
                            amount:this.toNumber(day.total_closing_cash)
                        })
                    } else {
                        all_payments[cash_index].amount=all_payments[cash_index].amount+this.toNumber(day.total_closing_cash)
                    }

                    //Add Other
                    let total_cashup = this.MBS.actions.CALCULATE_TOTAL_CASHUPS(day.joined_cashups)
                    let all_other_option_payments = total_cashup?total_cashup.all_other_option_payments:null
                    if (all_other_option_payments) {
                        all_other_option_payments.forEach(pay => {
                            let pay_index = all_payments.findIndex(p=>p.code==pay.payment_option_code)
                            if (pay_index == -1) {
                                all_payments.push({
                                    ...pay,
                                    amount:this.toNumber(pay.amount),
                                    code:pay.payment_option_code
                                })
                            } else {
                                all_payments[pay_index].amount=all_payments[pay_index].amount+this.toNumber(pay.amount)
                            }
                        });
                        
                    } 
                });
                console.log(all_payments,'all_payments...');
                return  all_payments
            }, 

            DaysShiftsPayments(){
                let ThisMoneyAccount = this.ThisMoneyAccount
                let money_account_code = ThisMoneyAccount?ThisMoneyAccount.code:null 
                let cashes = money_account_code?this.DaysShiftsOtherPayments(money_account_code):this.DaysShiftsCashPayments 

                return cashes
            }, 
            Join_PaymentMethods(){
                let payments = this.PaymentMethodsData
                if(!payments){return null}

            }
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){  
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
              
        },
        watch: {  
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            input(v){
                if (v) {
                    this.filter_date = v.filter_date
                }
            }
        },
    }
</script>
 
