<template>
    <div class="text-xs-center"> 
    <v-dialog
      v-model="mbs_progress_dialog.show" 
      :persistent="mbs_progress_dialog.fixed"
      :width="mbs_progress_dialog.size?mbs_progress_dialog.size:300" >
      <v-card
        :color="mbs_progress_dialog.color"
        dark class="pa-2" >
        <v-card-title v-if="mbs_progress_dialog.title" class="headline"
            primary-title >
            {{mbs_progress_dialog.title}}
        </v-card-title> 
        <v-card-text >
          {{mbs_progress_dialog.text}}
          <v-progress-linear
            indeterminate
            :color="mbs_progress_dialog.color==='white'?'primary':'white'"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    props:['notShow', ],
    created(){    
    },
    updated(){ 
    },
    data(){
      return{
          show:true, 
      } 
    },
   
    computed:{
        mbs_progress_dialog(){
            return  this.$store.getters.getMBS_progress_dialog
        }
    },
    methods:{
         
    },
    watch:{
        mbs_progress_dialog(value){ 
        },
         
    } 
}
</script>
