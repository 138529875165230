<template>
<div v-if="header.show">
   
    <!-- <br><br><br> -->
    <!-- //SUBTOOLBAR -->
    <div v-if="header.type==='subToolbar'"> 
        <mbs-sub-toolbar />  
    </div>
    <v-card v-else
        :height="header.height?header.height:vsh" 
        :color="header.color+' lighten-2'"
        tile=""> 
        <!-- //IMAGE -->
        <div v-if="header.images.length>0">
            <mbs-header-img v-if="header.type==='image'"
                :link="header.images?header.images[0].link:''"
                :height="header.height?header.height:vsh" 
                :color="header.color+' '" 
                :opacity="header.images?header.images[0].opacity:0.5"
                :title="header.images?header.images[0].title:''"
                :text="header.images?header.images[0].text:''"/>  
        </div>
        <!-- //IMAGES -->
        <div v-if="header.images.length>0">
            <mbs-header-imgs v-if="header.type==='images'"
                :height="header.height?header.height:vsh" 
                :color="header.color+' '" 
                :items="header.images" />    
        </div> 
    </v-card>  
</div>
</template>

<script>
 
export default {
    props:['height','color','color2','size','space'],
    data(){
        return{
            show:false, 
            
        }
    },
    created(){
            this.MBS.events.$on('onScroll', this.onScroll);  
    },
    mounted(){  
        let s = this.MBS.actions.scrolled
        this.setToolbar(s)
    },
    computed:{
        //commony
        mbs(){
            return this.MBS.actions
        },
        vs(){
                return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height-160
        },  
        vsn(){
            return this.vs.name
        }, 
        header(){ 
            return this.$store.getters.getMBS_header
        }      
         
    },
    methods:{
        onScroll(e,s){ 
            this.setToolbar(s)
        },
        navOpen(s){
            this.$emit('navOpen',s)    
        },
        setToolbar(s){ 
            if(s<80){
                this.MBS.actions.toolbar({
                    textColor:"white",
                    color:"primary",
                    // textColor:"primary",
                    // color:"white", 
                    show:true,  
                    flat:true,
                    fixed:false
                }) 
            }else if(s>80){
                this.MBS.actions.toolbar({
                    textColor:"white",
                    color:"primary",
                    // textColor:"primary",
                    // color:"white",
                    show:true, 
                    flat:false,
                    fixed:true
                }) 
            }  
        }, 
    },
    watch:{
      
    }
    

}
</script>
<style>
  
</style>

