<template>
    <v-card flat color="transparent">
        <v-layout 
            :style="'--color:'+MBS.color.black+'; --size:1px; '"
            row wrap class="ma-0" justify-center>
            <v-card outlined  class="ma-1" v-for="(action,index) in ITEMS" :key="index" 
             width="105"  @click="DISABLED(action)?'':ITEM_ACTION(action)">
                <v-menu  v-if="action.menu"
                    v-model="menu_item_select[index]"
                    :close-on-content-click="true" 
                    offset-y  offset-overflow
                    :nudge-width="action.menu.width?action.menu.width:90" 
                    rounded="xl" >

                    <template v-slot:activator="{ on, attrs }">   
                        <v-card v-on="on" height="80" 
                            :disabled="DISABLED(action)" 
                            :color="DISABLED(action)?'grey':action.color?action.color:'primary'"  >
                            <v-layout fill-height class="ma-0" column>
                                <!-- <v-spacer></v-spacer> -->
                                <v-card height="90%" width="100%" flat  class="" color="p" >
                                    <v-layout column fill-height justify-center align-center class="ma-0 pa-2 pt-3">
                                        <v-icon v-if="action.icon">{{action.icon}}</v-icon>
                                        <div class="font-weight-bold grey--text text--darken-2  not-f text-center"
                                            :class="MBS.actions.SIZE(action.name)>11?'not-f3':''">{{action.name}}</div> 
                                    </v-layout>
                                </v-card>
                            </v-layout> 
                        </v-card>
                    </template> 

                    <v-card   
                        color="lighten-1" class="">  
                        <v-card-title class="secondary white--text py-" dark>
                            {{action.menu.name}} 
                        </v-card-title> 
                        <v-list dense> 
                            <div v-for="(item,index) in action.menu.items" :key="index">
                                <v-list-item 
                                    @click="ITEM_ACTION(item)">
                                    <v-list-item-action class="ma-2">
                                        <v-icon>{{item.icon}}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        {{item.name}}
                                    </v-list-item-content>
                                </v-list-item> 
                            </div>
                        </v-list>  
                    </v-card>  
                </v-menu> 
                <v-card v-else height="80" 
                    :disabled="DISABLED(action)" 
                    :color="DISABLED(action)?'grey':action.color?action.color:'primary'"  >
                    <v-layout fill-height class="ma-0 mbs-borde" column>  
                        <!-- <v-spacer></v-spacer> -->
                        <v-card height="90%" width="100%" flat  class="" color="p" >
                            <v-layout column fill-height justify-center align-center class="ma-0 pa-2 pt-3">
                                <v-icon v-if="action.icon">{{action.icon}}</v-icon>
                                <div class="font-weight-bold grey--text text--darken-2  not-f text-center"
                                    :class="MBS.actions.SIZE(action.name)>11?'not-f3':''">{{action.name}}</div> 
                            </v-layout>
                        </v-card>
                    </v-layout> 
                </v-card>
            </v-card> 
        </v-layout> 
    </v-card>
</template>

<script>
    import DATA from '../../plugins/DATA';
    let PAGE_NAME  = "ACTIONS_FIELD" 
    
    export default {
        name:PAGE_NAME,
        props:[
            'show','value', 
            'items',
            'data',
            'disabled',
            'disabled_types',
            'disabled_fields',  
            'select_items', 
            'update_item',  
        ],
        data() {
            return { 
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
  
                excelSelecter:false,
                EXCEL_DATA_ITEMS:[],
                generatedCode:null, 
                inputCode:null, 
                inputCodeLoading:false, 
                menu_date_picker:{},
                menu_time_picker:{},
                menu_item_select:{},
                dialog_time_picker:{},
                dialogInput:{},
                search:'',  
                search_input:null,   
                input:this.value,
                selected_field_action:null, 
            }
        },
        created() {
            try {
                // this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {   
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            ITEMS(){  
                let data = this.data
                let items = this.items 
                if (!items) {return null} 
                let filtered_items = items.filter(item=>{
                    let show_if = true 
                    if (typeof item.show_if == "function") {
                        show_if = item.show_if(data)
                    }else if (typeof item.show_if == "boolean") {
                        show_if = item.show_if
                    }else{
                         
                    }
                    return show_if
                })  
                return filtered_items
            }, 
            Disable(){  
                let disabled = this.disabled
                let disabled_fields = this.disabled_fields
                let disabled_types = this.disabled_types
                if(disabled){return true}
                return (action)=>{
                    if (this.MBS.actions.SIZE(disabled_fields)>0) {
                        return disabled_types.some(item=>{return item == action.action})
                    }
                    if (this.MBS.actions.SIZE(disabled_types)>0) { 
                        let isType = disabled_types.some(item=>{return item == action.type}) 
                        return isType
                    }
                    return false
                }
            },
            DISABLED(){  
                let data = this.data 
                return item=>{
                    let disabled = true 
                    if (typeof item.disable == "function") {
                        disabled = item.disable(data)
                    }else if (typeof item.disable == "boolean") {
                        disabled = item.disable
                    }else{
                        disabled = this.Disable(item)
                    }
                    return disabled
                }
            }, 
              
        },
        methods: {   
            ITEM_ACTION(action){
                try {   
                    this.selected_field_action = action
                    setTimeout(() => { 
                        this.$emit("ITEM_ACTION",action.action,action)
                    }, 0); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            
            //---------------[MAIN FUNCTIONS]------------  
            DIALOG_YES(action){ 
                try {
                    if (action.code) {  
                        if (action.code ===PAGE_NAME+'=AUTO-GENERATE-CODE') { 
                            if (action.YES) {
                                this.EXCEL_DATA_ITEMS= this.MBS.actions.CODE_AUTO_GENERATE(this.EXCEL_DATA_ITEMS) 
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=AUTO-SET-CODE') { 
                            if (action.YES) {
                                this.inputCodeLoading = true
                                setTimeout(() => { 
                                    this.setAutoCode()
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        },
        watch: { 
            us(v){ 
            },  
            input(currentValue) {  
                this.$emit('input', {...currentValue})
            },     
        },
  }
</script>