<template>
 
    <v-snackbar  
        v-model="show"
        :color="sn.color?sn.color:'primary'"
        :timeout="sn.time"
        :app="sn.app"
        :top="sn.y === 'top'"
        :bottom="sn.y === 'bottom'"
        :right="sn.x === 'right'"
        :left="sn.x === 'left'"
        :multi-line="sn.lines"
        :centered="sn.center"
        :vertical="sn.vertical === 'vertical'">
            <div :class="sn.textColor?sn.textColor+'--text':'white--text'">{{sn.text }}</div>
        <v-btn 
            text 
            :color="sn.textColor?sn.textColor:'white'" 
            @click.native="snackbarClose()">Close</v-btn>
    </v-snackbar> 
 
</template>

<script>
export default { 
    data(){
        return{
            show:false
        }
       
    },
    computed:{
        sn(){
            return  this.$store.getters.getMBS_snackbar
        }
    },
    mounted(){
        this.showSnack()
    },
    methods:{
        onNotNo(){
            this.$emit('notNo')
        },
        snackbarClose(value){
            this.MBS.actions.snackbar()
        },
        showSnack(){
            this.show=this.sn.show
        }
    },
    watch:{
        show(value){
            if(!value){
                this.MBS.actions.snackbar({
                    show:false
                })
            } 
        },
        sn(value){
            this.showSnack()
        }

    }
}
</script>
