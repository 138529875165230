<template>
<v-layout fill-height="" justify-center="" align-center="">
  <v-progress-circular 
    :value="20"
    height="15"
    color="primary"
    indeterminate/> 
  <!-- <div>
    <v-row>
      <v-col>
        <img src="@/assets/logo/mbs-1.svg" alt="logo" 
          class="ma-0 pa-0" 
          width="100px"> 
      </v-col>
      <v-col class="ml-0 pl-0">
        <img src="@/assets/logo/mbs.svg" alt="logo" class="pb-1" width="120px"> 
        <v-progress-linear class="ma-0 pa-0"
        height="5"
        color="primary"
        indeterminate/>  
      </v-col>
    </v-row>
  </div>  -->
</v-layout>
</template>

<script>
  export default {
    data: () => ({
       imgsss:'logo/mbe-logo.svg'
    })
  }
</script> 
