<template>
    <div>   
        <v-divider></v-divider>
        <mbs-page-tab 
            :items="MyLinks"/> 
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <!-- <v-btn @click="testPrinter()">text//</v-btn> -->
                    <br>
                    <v-card > 
                        <mbs-item-manager
                            :table_tabs="TABLE_TABS"
                            :table_title="'Manage Designations'"
                            :table_title_icon="'mdi-sofa-single'"
                            :add_vertical_lines="true"
                            :row_click="true" 
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :field_items="DATA.FIELDS.designations"
                            :header_items="HEADER_ITEMS" 
                            :data_items="DesignationsData"      
                            :is_joined="true" 
                            :select_items="Selectors"   
                            :path_item="PATH_ITEM"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.DESIGNATIONS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.DESIGNATIONS.names,
                NAME_ITEM:DATA.ITEMS.DESIGNATIONS.name,
                VALUE_ITEMS:DATA.ITEMS.DESIGNATIONS.values,
                VALUE_ITEM:DATA.ITEMS.DESIGNATIONS.value,       
                table_actions:[
                    {icon:"add",type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Designation"},  
                ],
                DATA:DATA
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: { 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Designations: state=> state.items[(DATA.ITEMS.DESIGNATIONS.values).toUpperCase()],
                JoinedDesignations: state=> state.join[(DATA.ITEMS.DESIGNATIONS.values).toUpperCase()], 
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            }),  
            DesignationsData(){
                console.log(this.Designations,'Designations.................');
                return this.JoinedDesignations?this.JoinedDesignations:this.Designations
            }, 
            StockOutletsData(){
                let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets  
                return items 
            },
            Selectors(){ 
                let StockOutlets = [{name:"All",code:'all',key:"all"}]
                if (this.StockOutletsData ) {
                    StockOutlets.push(...this.StockOutletsData )
                }
                return{
                    Outlets: StockOutlets, 
                }
            },
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            },  
            MyLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("payroll",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.designations,true) 
                return path
            }, 
            TABLE_TABS(){
                let outlets = this.StockOutletsData
                console.log(outlets,'out....');
                let taps = [
                    {
                        name:"All",
                        show_if:(this_,item)=>true,
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{  
                                return MBS.SIZE(data)
                            }
                        }
                    },
                ]
                if (outlets) {
                    outlets.forEach(outlet => {
                        taps.push({
                            name:outlet.name+" Expenses",
                            show_if:(this_,item)=>item.outlet_code==outlet.code,
                            indicator:{
                                color:'primary',
                                value:(this_,MBS,data)=>{  
                                    return MBS.SIZE(data)
                                }
                            }
                        })
                    });
                }
                return  taps
            },
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',icon:"mdi-sofa-single",size:35,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:true},          
                    {id:0,name:"Name",value:"name",show:true},       
                    {id:0,name:"Outlet",value:"outlet_name",show:true},    
                    {id:0,name:"description",value:'description',show:false},    
                    {id:0,name:"create",value:'created_at_',show:false},    
                    {id:0,name:"update",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"edit"}, 
                        {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },  
        },
        methods: {  
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }  
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){  
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        watch: {  
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
