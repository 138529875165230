<template> 
  <v-layout 
    column 
    v-scroll="onScroll" 
    fill-height @contextmenu="preventContextMenu">   
        <!-- NAVIGATION DRAWER --> 
      <mbs-navbar :navShow="navOpen" /> 
      <mbs-toolbar  @navOpen="navOpen =! navOpen"/> 
      <v-main class="b1"> 
        <mbs-access-page v-model="ACCESS_PAGE"/>
        <v-layout v-if="ACCESS_PAGE" column  fill-height>
          <mbs-header @navOpen="navOpen =! navOpen"/>      
          <router-view  />    
          <v-spacer></v-spacer>
          <mbs-footer/>   
        </v-layout> 
      </v-main> 
      <mbs-dialog/> 
      <mbs-dialog-confirm-password/> 
      <mbs-dialog-input/> 
      <mbs-dialog-progress/> 
      <mbs-snackbar/>  
      <mbs-user-checkin/>  
      <!-- {{ JoinedDaysShifts }} -->
      <!-- <v-layout v-if="!online" justify-center align-baseline class="offline">
        <v-card  class="py-2 px-10 white--text" color="red">
          <div class="font-weight-bold">You're offline...</div>
        </v-card>
      </v-layout> -->
  </v-layout> 
</template>

<script>
    import DATA from '../../plugins/DATA'
    import DB from '../../plugins/DB'
    import {mapState} from "vuex" 
    let PAGE_NAME = 'MainActivity' 
    export default{  
        name: PAGE_NAME, 
        data () {
          return { 
            navOpen:false,
            scrolled:null, 
            ACCESS_LOADING:true,
            ACCESS_DIALOG:true,
            ACCESS_PAGE:false,
            interval: {},
            show:true,
            SUBMIT_SALES_LOAD:false,
            SUBMIT_SALES_WAIT:false,
            SUBMIT_ITEM_ORDERS_LOAD:false,
            SUBMIT_ITEM_ORDERS_WAIT:false,
            SUBMIT_DAY_SHIFT_LOAD:false,
            SUBMIT_DAY_SHIFT_WAIT:false,
            SUBMIT_CASHUPS_LOAD:false,
            SUBMIT_CASHUPS_WAIT:false,
            SUBMIT_CASHUPS_WAIT:false,
            LOADING_INITIATE_COMPANY_ITEMS:false,
            DATE:null
          } 
        },  
        async created(){
          try { 
            this.$store.dispatch("setUpTime",new Date())    
            setInterval(() => {
              this.LIVE_TIME() 
            }, 1000);
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);
            this.MBS.events.$on('SUBMIT_SALES', this.SUBMIT_SALES);
            this.MBS.events.$on('SUBMIT_ITEM_ORDERS', this.SUBMIT_ITEM_ORDERS);  
            this.MBS.events.$on('SUBMIT_JOINED_STOCK_TAKING', this.SUBMIT_JOINED_STOCK_TAKING);
            this.MBS.events.$on('SUBMIT_STOCK_TAKING_ITEMS', this.SUBMIT_STOCK_TAKING_ITEMS);
            this.MBS.events.$on('SUBMIT_PAGE_HISTORIES', this.SUBMIT_PAGE_HISTORIES);
            this.MBS.events.$on('SUBMIT_WAREHOUSE_SESSIONS', this.SUBMIT_WAREHOUSE_SESSIONS);
            this.MBS.events.$on('SUBMIT_WAREHOUSE_ORDERS', this.SUBMIT_WAREHOUSE_ORDERS);
            this.addHistory()
            this.REDIRECT() 
            this.USER_MONITOR(100) 
            this.INITIATE_BILLING_FEATURES_DB()
            this.INITIATE_COMPANY_DB(this.SelectedCompanyKey)
            
 
            setTimeout(() => {
              this.CHECK_URL_COMPANY() 
            }, 1000);
            // let data = this.MBS.data.browser()  
            // this.$store.dispatch('fi_add_visiter',{
            //   data:data
            // })  
            
          } catch (error) {
            this.MBS.actions.error({
              error:error,
              from:'created',
              page:PAGE_NAME, 
            }) 
          }
        },
        mounted () {
          try { 
            this.interval = setInterval(() => {
              this.show = false
              setTimeout(() => {
                this.show = true
              }, 50);
            }, 2000) 
            this.SUBMIT_SALES()
            this.SUBMIT_ITEM_ORDERS()
            this.SUBMIT_WAREHOUSE_SESSIONS()
            this.SUBMIT_WAREHOUSE_ORDERS()
            this.SUBMIT_JOINED_STOCK_TAKING()
            this.SUBMIT_STOCK_TAKING_ITEMS()
            this.NAV_CONTROLLER(true,'mounted.')
            this.UPDATE_K_CONNECTOR()
            this.UPDATE_CONNECTED_PRINTERS()
            this.INITIATE_COMPANY_ITEMS_DB()  
          } catch (error) {
            this.MBS.actions.error({
              error:error,
              from:'mounted',
              page:PAGE_NAME, 
            }) 
          }
        },
        computed: { 
          ...mapState({
              loading:state=>state.load.loading,
              processes:state=>state.load.processes,
              responses:state=>state.load.responses,
          }),
          ...mapState({
              us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
              ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
              online: state=> state.users[(DATA.ITEMS.ONLINE_STATUSES.value).toUpperCase()],
              SelectedCompanyKey: state=> state.items["SELECTED_COMPANY_KEY"],
          }),
          ...mapState({
              CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()],  
              BillingPlans: state=> state.items[(DATA.ITEMS.BILLING_PLANS.values).toUpperCase()],
              JoinedBillingPlans: state=> state.join[(DATA.ITEMS.BILLING_PLANS.values).toUpperCase()], 
              BillingPlanFeatures: state=> state.items[(DATA.ITEMS.BILLING_PLAN_FEATURES.values).toUpperCase()],
              JoinedBillingPlanFeatures: state=> state.join[(DATA.ITEMS.BILLING_PLAN_FEATURES.values).toUpperCase()], 
              
              DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
              JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
              LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
              JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()], 
              LocalCashups: state=> state.items[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
              JoinedLocalCashups: state=> state.join[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],     
              LocalStockTakingItems: state=> state.items[(DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values).toUpperCase()],
              LocalJoinedStockTaking: state=> state.items[(DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values).toUpperCase()],
              DownloadRequests: state=> state.items[(DATA.ITEMS.DOWNLOAD_REQUESTS.values).toUpperCase()], 
          }),  
          route(){
            return this.$route
          },
          vs(){ 
            return this.$vuetify.breakpoint
          }, 
          vs_width(){ 
            let vs = this.vs
            return vs.width
          }, 
          us(){
            return this.$store.getters.getUser
          }, 
          ud(){
            return this.$store.getters.getUserDetails
          }, 
          upTime(){
            return this.$store.getters.getUpTime
          }, 
          loggedTime(){
            return this.$store.getters.getLoggedTime
          },  
          online(){   
              return this.$store.getters.getOnlineStatus
          },
          SelectedCompany(){ 
            return this.$store.getters.getSelectedCompany
          }, 
          myCompanies(){ 
            return this.$store.getters.getMyCompanies
          },  
          nav_data(){ 
            return this.$store.getters.getMBS_nav
          }, 
          CompanyDataNames(){
            let names = [

              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.CURRENT_BILLING_PLANS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.SUBSCRIPTIONS.values},

              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.SELECTED_COMPANIES.value},

              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.COMPANY_SETTINGS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.PAYMENT_METHODS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.MONEY_ACCOUNTS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.COMPANY_USERS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.COMPANY_USER_GROUPS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.DESIGNATIONS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.STOCK_OUTLETS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.COMPANY_QUICK_ACTIONS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.DEVICES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.CARDS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.PRICING_TIERS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.TILLS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.STOCK_TAKING_ORDERS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.STOCK_TAKINGS.values},
              
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.QUOTATIONS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.BRANDS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.MANUFACTURERS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.SUPPLIERS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.SUPPLIER_CATEGORIES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.CUSTOMERS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.CUSTOMER_GROUPS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.ITEM_CATEGORIES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.ITEM_DEPARTMENTS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.ITEM_ORDERS.values}, 
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.ITEM_ORDER_OPTIONS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.PATIENT_ORDERS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.PATIENT_HISTORIES.values},
              // {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.ITEM_KITS.values},
              

              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.STOCK_ORDERS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.QUOTATIONS_AND_INVOICES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.ASSETS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.RECEIVABLES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.PAID_INVOICES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.UNPAID_INVOICES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.CREDITORS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.DEBTORS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.PAYMENTS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.EXPENSES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.EXPENSE_CATEGORIES.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.EXPENSE_ACCOUNTS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.EXPIRIES.values},

              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.DAYS_SHIFTS.values}, 
              // {data_save:false,on:false,get:true,cash:true,name:DATA.ITEMS.ITEMS.values},
              {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.ATTENDANCES.values},
              

              // {data_save:true,on:false,get:true,cash:true,name:DATA.ITEMS.ITEMS.values},
              // {data_save:false,on:false,get:true,cash:true,name:DATA.ITEMS.DAYS_SHIFTS_SALES.values},
              // {data_save:true,on:false,get:true,cash:true,name:DATA.ITEMS.DAYS_SHIFTS_SALES.values},
            ]
            return names
          }
        }, 
        //---------------methods-----------------//
        methods: { 
          preventContextMenu(event) {
            event.preventDefault(); // Prevent the default context menu
          },   
          navOpener(s){ 
            try {
              this.navOpen=!this.navOpen  
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'navOpener',
                page:PAGE_NAME, 
              })
            }
          },
          onScroll (e) { 
            try {
              this.scrolled = window.pageYOffset || document.documentElement.scrollTop
              this.MBS.actions.scrolled = window.pageYOffset || document.documentElement.scrollTop
              this.MBS.events.$emit('onScroll',e,this.scrolled )  
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'onScroll',
                page:PAGE_NAME, 
              })
            } 
          },   
          addHistory(){
            try {
              let route = this.route
              this.$store.dispatch('add_history',{
                data:route
              }) 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'addHistory',
                page:PAGE_NAME, 
              })
            }
          },
          REDIRECT(){
            try {
              let name = this.$route.name
              let fullPath = this.$route.fullPath
              let links = this.$router.options.routes
              if (fullPath ==="/@") {
                this.MBS.actions.go('/directories')
                return
              }
              if (!name) {
                this.MBS.actions.go('/')
                if (fullPath ==="/index.html") {
                  return
                }
                if (fullPath ==="/index") {
                  return
                }
                if (fullPath ==="/home") {
                  return
                }
                this.MBS.actions.dialog({
                  show:true, 
                  title:"404",
                  text:"You are redirected Home. The page ("+fullPath+"), was not found.",
                  btnYes:"OK"
                })
              } 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'REDIRECT',
                page:PAGE_NAME, 
              })
            }
          }, 
          SUB_TOOLBAR(){
            try {
              this.MBS.actions.subToolbar({ 
                show:true,
                action:{name:null}
              }) 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'SUB_TOOLBAR',
                page:PAGE_NAME, 
              })
            }
          }, 
          async INITIATE_BILLING_DB(){
            try { 
                if (false) {  
                    this.$store.dispatch('fi_tracker',{
                      company:null,
                      names:[ 
                        {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.BILLING_PLANS.values},  
                      ]
                    }).then(res=>{   
                    }).catch(error=>{
                      console.log(error,"error......");
                    })  
                }  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'INITIATE_BILLING_DB',
                    page:PAGE_NAME, 
                })
            }  
          },
          async INITIATE_BILLING_FEATURES_DB(){
            try { 
                if (false) {  
                    this.$store.dispatch('fi_tracker',{
                      company:null,
                      names:[  
                        {data_save:false,on:true,get:true,cash:true,name:DATA.ITEMS.BILLING_PLAN_FEATURES.values}, 
                      ]
                    }).then(res=>{   
                    }).catch(error=>{
                      console.log(error,"error......");
                    })  
                }  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'INITIATE_BILLING_FEATURES_DB',
                    page:PAGE_NAME, 
                })
            }  
          },
          async INITIATE_COMPANY_DB(company_key){
            try { 
                let settings = this.CompanySettings
                let activate_stock_level = settings?settings.stock_levels:null
                let on_item = activate_stock_level?true:false 
                if (company_key) {
                    //--------------[locals]
                    let res_locals = await this.$store.dispatch('initiate_company_locals',{
                      company_key:company_key
                    })  
                    let res_company = await this.$store.dispatch('initialize_company_data_ref',{
                      company_key:company_key
                    })   
                    let res_online = await this.$store.dispatch('fi_tracker',{
                      company_key:company_key,
                      names:this.CompanyDataNames
                    })  
                }else{ 

                }  
            } catch (error) {
              console.log(error,'INITIATE_COMPANY_DB.....err');
                this.MBS.actions.error({
                    error:error,
                    from:'INITIATE_COMPANY_DB',
                    page:PAGE_NAME, 
                })
            }  
          },
          async INITIATE_COMPANY_ITEMS_DB(settings){
            try {
              if (!settings) {
                settings=this.CompanySettings
              }
              let DownloadRequests = this.DownloadRequests
              let item_request = DownloadRequests?DownloadRequests.find(item=>item.name=="items"):null
              let on = item_request?item_request.on:null
              let run_size = this.toNumber(item_request?item_request.run_size:null)

              let company = this.SelectedCompany
              let activate_stock_level = settings?settings.activate_stock_level:null
              let on_item = activate_stock_level?true:false
              let company_key = company?company.company_key:null  
              if (company_key){
                if (this.LOADING_INITIATE_COMPANY_ITEMS) {
                  setTimeout(() => {
                   this.INITIATE_COMPANY_ITEMS_DB()
                  }, 5000);
                } else { 
                  if (on_item&&!on) {   
                    this.LOADING_INITIATE_COMPANY_ITEMS = true
                    this.$store.dispatch('fi_tracker',{
                      company:company,
                      names:[ 
                        {data_save:false,on:on_item,get:true,cash:true,name:DATA.ITEMS.ITEMS.values}, 
                      ]
                    }).then(res=>{  
                      this.LOADING_INITIATE_COMPANY_ITEMS = false
                    }).catch(error=>{
                      this.LOADING_INITIATE_COMPANY_ITEMS = false
                      console.log(error,"error......");
                    })  
                  }else if(!on_item&&on) {   
                    this.LOADING_INITIATE_COMPANY_ITEMS = true
                    this.$store.dispatch('off_refs',{
                      company:company,
                      name:DATA.ITEMS.ITEMS.values
                    }).then(res=>{  
                      return this.$store.dispatch('fi_tracker',{
                        company:company,
                        names:[ 
                          {data_save:false,on:on_item,get:true,cash:true,name:DATA.ITEMS.ITEMS.values}, 
                        ]
                      })
                    }).then(res=>{  
                      this.LOADING_INITIATE_COMPANY_ITEMS = false
                    }).catch(error=>{
                      this.LOADING_INITIATE_COMPANY_ITEMS = false
                      console.log(error,"error......");
                    })  
                  }else if(!on_item&&!run_size) {   
                    this.LOADING_INITIATE_COMPANY_ITEMS = true
                    this.$store.dispatch('off_refs',{
                      company:company,
                      name:DATA.ITEMS.ITEMS.values
                    }).then(res=>{  
                      return this.$store.dispatch('fi_tracker',{
                        company:company,
                        names:[ 
                          {data_save:false,on:on_item,get:true,cash:true,name:DATA.ITEMS.ITEMS.values}, 
                        ]
                      })
                    }).then(res=>{  
                      this.LOADING_INITIATE_COMPANY_ITEMS = false
                    }).catch(error=>{
                      this.LOADING_INITIATE_COMPANY_ITEMS = false
                      console.log(error,"error......");
                    })  
                  }else{ 
                  } 
                } 
              }
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'INITIATE_COMPANY_ITEMS_DB',
                    page:PAGE_NAME, 
                })
            }  
          },
          
          async SELECT_COMPANY(company){
            try { 
                await this.MBS.actions.SELECT_COMPANY(company) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SELECT_COMPANY',
                    page:PAGE_NAME, 
                })
            }  
          },
          CHECK_URL_COMPANY(){
              try { 
                  let myCompanies = this.myCompanies  
                  let SelectedCompanyKey = this.SelectedCompanyKey 
                  let route = this.route 
                  let params = route?route.params:null
                  let id = params?params.id:null   
                  // console.log(id,'id.............');
                  // console.log(myCompanies,'myCompanies.............');
                  if (myCompanies&&id) {
                    let thisCompany = myCompanies.find(company=>{
                      return company.company_key == id
                    }) 
                    if (SelectedCompanyKey != id) {
                        if (thisCompany) { 
                          // console.log(thisCompany,'thisCompany.....22');
                          this.SELECT_COMPANY(thisCompany)
                        }  
                      }
                  } 
              } catch (error) {
                  this.MBS.actions.error({
                      error:error,
                      from:'CHECK_URL_COMPANY',
                      page:PAGE_NAME, 
                  })
              }  
          }, 

          //---------------[MAIN FUNCTIONS]------------
          NAV_CONTROLLER(start,from){ 
            try {
              let us = this.us
              let ud = this.ud
              let vs = this.vs
              let vs_width = this.vs_width
              let mobile = vs?vs.mobile:false 
              let nav_data = this.nav_data
              let name = this.$route.name
              let fullPath = this.$route.fullPath
              let links = this.$router.options.routes
              let nav = (data)=>{
                setTimeout(() => {
                  this.MBS.actions.nav(data)
                }, 10);
              } 
 

              if (start) {
                nav_data = {
                  ...nav_data,
                  show:vs.smAndDown?false:true,
                  min:vs.smAndDown?false:true
                }
              }  
              
              //pages
              if (fullPath ==="/home" || fullPath ==="/") {  
                if (us) {
                  nav_data = {
                    ...nav_data,
                    show:true, 
                  }
                }else{
                  nav_data = {
                    ...nav_data,
                    show:false, 
                  }
                } 
              }else if (fullPath ==="/signup") { 
                nav_data = {
                  ...nav_data,
                  show:false, 
                }
              }  
              nav(nav_data) 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'NAV_CONTROLLER',
                page:PAGE_NAME, 
              }) 
            }
          }, 
          LIVE_TIME(){ 
            try {
              if (!this.DATE) {
                this.DATE = this.MBS.date
              }
              if (this.DATE) {
                  this.DATE.LIVE_DATE = new Date() 
              }  
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'LIVE_TIME',
                page:PAGE_NAME, 
              }) 
            }
          }, 

          async UPDATE_K_CONNECTOR(){ 
            try { 
              this.MBS.actions.K_CONNECT() 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'UPDATE_K_CONNECTOR',
                page:PAGE_NAME, 
              }) 
            }
          },
          async UPDATE_CONNECTED_PRINTERS(){ 
            try { 
              let res = await this.MBS.actions.GET_CONNECTED_PRINTERS() 
              let data = res?res.data:null  
              await this.$store.dispatch("add_items",{
                name:DATA.ITEMS.CONNECTED_PRINTERS.values,
                data:data
              }) 
              
            } catch (error) { 
            }
          },
          async UPDATE_LOCAL_SALES(){ 
            try { 
              let LocalSaleOrders = this.LocalSaleOrders
              let res = await DB.getItems({
                name:DATA.ITEMS.LOCAL_SALE_ORDERS.values
              })  
              let data = res?res.data:null
              let size1 = this.MBS.actions.SIZE(data)
              let size2 = this.MBS.actions.SIZE(LocalSaleOrders)
              if (size1 != size2) {
                await this.$store.dispatch("add_items",{
                  name:DATA.ITEMS.LOCAL_SALE_ORDERS.values,
                  data:data
                }) 
              }else{}
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'UPDATE_LOCAL_SALES',
                page:PAGE_NAME, 
              }) 
            }
          },
          async UPDATE_LOCAL_ITEM_ORDERS(){ 
            try { 
              let LocalSaleOrders = this.LocalSaleOrders
              let res = await DB.getItems({
                name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values
              })  
              let data = res?res.data:null
              let size1 = this.MBS.actions.SIZE(data)
              let size2 = this.MBS.actions.SIZE(LocalSaleOrders)
              // if (size1 != size2) {
              //   await this.$store.dispatch("add_items",{
              //     name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
              //     data:data
              //   }) 
              // }else{}
              await this.$store.dispatch("add_items",{
                  name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                  data:data
                }) 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'UPDATE_LOCAL_ITEM_ORDERS',
                page:PAGE_NAME, 
              }) 
            }
          }, 
          async UPDATE_LOCAL_JOIN_STOCK_TAKING(){ 
            try {   
              let res = await DB.getItems({
                name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values
              })  
              let data = res?res.data:null  
              await this.$store.dispatch("add_items",{
                name:DATA.ITEMS.LOCAL_JOINED_STOCK_TAKING.values,
                data:this.MBS.actions.SIZE(data)>0?data[0]:null
              }) 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'UPDATE_LOCAL_JOIN_STOCK_TAKING ---',
                page:PAGE_NAME, 
              }) 
            }
          },
          async UPDATE_LOCAL_STOCK_TAKING_ITEMS(){ 
            try {   
              const res = await DB.getItems({
                name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values
              })  
              const data = res?res.data:null     
              await this.$store.dispatch("add_items",{
                name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values,
                data:data
              })  
              return data 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'UPDATE_LOCAL_STOCK_TAKING_ITEMS',
                page:PAGE_NAME, 
              }) 
            }
          },
          async UPDATE_PAGE_HISTORIES(){ 
            try {   
              const res = await DB.getItems({
                name:DATA.ITEMS.PAGE_HISTORIES.values
              })  
              const data = res?res.data:null     
              await this.$store.dispatch("add_items",{
                name:DATA.ITEMS.PAGE_HISTORIES.values,
                data:data
              })  
              return data 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'UPDATE_PAGE_HISTORIES',
                page:PAGE_NAME, 
              }) 
            }
          },
          async UPDATE_LOCAL_WAREHOUSE_SESSIONS(){ 
            try {  
              let LocalCashups = this.LocalCashups
              let res = await DB.getItems({
                name:DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values
              })  
              let data = res?res.data:null
              let size1 = this.MBS.actions.SIZE(data)
              let size2 = this.MBS.actions.SIZE(LocalCashups) 
              
              await this.$store.dispatch("add_items",{
                name:DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values,
                data:data
              }) 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'UPDATE_LOCAL_WAREHOUSE_SESSIONS',
                page:PAGE_NAME, 
              }) 
            }
          },
          async UPDATE_LOCAL_WAREHOUSE_ORDERS(){ 
            try {  
              let LocalCashups = this.LocalCashups
              let res = await DB.getItems({
                name:DATA.ITEMS.LOCAL_WAREHOUSE_ORDERS.values
              })  
              let data = res?res.data:null
              let size1 = this.MBS.actions.SIZE(data)
              let size2 = this.MBS.actions.SIZE(LocalCashups) 
              
              await this.$store.dispatch("add_items",{
                name:DATA.ITEMS.LOCAL_WAREHOUSE_ORDERS.values,
                data:data
              }) 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'UPDATE_LOCAL_WAREHOUSE_ORDERS',
                page:PAGE_NAME, 
              }) 
            }
          },
          async Back_SUBMIT_SALES(){ 
            try { 
              this.UPDATE_LOCAL_SALES()
              
              if (this.SUBMIT_SALES_LOAD) {
                this.SUBMIT_SALES_WAIT = true 
              } else {
                this.SUBMIT_SALES_LOAD = true
                DB.getItems({
                  name:DATA.ITEMS.LOCAL_SALE_ORDERS.values
                }).then(async res=>{ 
                  let orders = res?res.data:null 
                  let submit_errors = false
                  let successes = []
                  let errors = []
                  if (orders) {
                    for (const order of orders) {
                      let order_key = order.key
                      let day_shift_key = order.day_shift_key
                      let company_key = order.company_key

                      if (!order.uploaded) {
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts_sales+day_shift_key+"/"+order_key,true,company_key) 
                        if (order_key && path && company_key) {
                          try {
                            let upRes = await this.$store.dispatch('fi_update',{
                              action:DATA.ITEMS.SALE_ORDERS.values,
                              data:{...order,uploaded:true},
                              path:path
                            })  
                            //-------UPDATE ORDER AFTER UPLOAD
                            await DB.addItems({
                              name:DATA.ITEMS.LOCAL_SALE_ORDERS.values, 
                              data:{...order,uploaded:true}
                            })
                            //-------DELETE AFTER UPLOAD 
                            // let oder_delete = await DB.deleteItems({
                            //   key:order_key,
                            //   name:DATA.ITEMS.LOCAL_SALE_ORDERS.values,
                            //}) 
                            successes.push(upRes)
                          } catch (error) {
                            errors.push(error)
                            submit_errors = true
                          }
                        }else{
                          submit_errors = true
                        }   
                      }else{ 
                      }
                    } 
                  } 
                  this.SUBMIT_SALES_LOAD = false 
                  if (this.SUBMIT_SALES_WAIT) {
                    this.SUBMIT_SALES_WAIT = false
                    this.SUBMIT_SALES() 
                  } 
                }).catch(error=>{
                  this.MBS.actions.error({
                    error:error,
                    from:'SUBMIT_SALES',
                    page:PAGE_NAME, 
                  }) 
                }) 
              }
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_SALES',
                page:PAGE_NAME, 
              }) 
            }
          }, 
          async SUBMIT_SALES(){ 
            try { 
              this.UPDATE_LOCAL_SALES() 
              if (this.SUBMIT_SALES_LOAD) {
                this.SUBMIT_SALES_WAIT = true 
              } else {
                this.SUBMIT_SALES_LOAD = true
                this.$store.dispatch("fi_uploadSales")
                .then(async res=>{  
                  this.SUBMIT_SALES_LOAD = false 
                  if (this.SUBMIT_SALES_WAIT) {
                    this.SUBMIT_SALES_WAIT = false
                    this.SUBMIT_SALES() 
                  } 
                }).catch(error=>{
                  this.SUBMIT_SALES_LOAD = false 
                  if (this.SUBMIT_SALES_WAIT) {
                    this.SUBMIT_SALES_WAIT = false
                    this.SUBMIT_SALES() 
                  } 
                  this.MBS.actions.error({
                    error:error,
                    from:'SUBMIT_SALES',
                    page:PAGE_NAME, 
                  }) 
                }) 
              }
            } catch (error) {
              this.SUBMIT_SALES_LOAD = false 
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_SALES',
                page:PAGE_NAME, 
              }) 
            }
          },
          async SUBMIT_ITEM_ORDERS(){ 
            try {  
              this.UPDATE_LOCAL_ITEM_ORDERS() 
              if (this.SUBMIT_ITEM_ORDERS_LOAD) {
                this.SUBMIT_ITEM_ORDERS_WAIT = true 
              } else { 
                this.SUBMIT_ITEM_ORDERS_LOAD = true
                this.$store.dispatch("fi_uploadItemOrders")
                .then(async res=>{  
                  this.SUBMIT_ITEM_ORDERS_LOAD = false 
                  if (this.SUBMIT_ITEM_ORDERS_WAIT) {
                    this.SUBMIT_ITEM_ORDERS_WAIT = false
                    this.SUBMIT_ITEM_ORDERS() 
                  } 
                }).catch(error=>{
                  this.SUBMIT_ITEM_ORDERS_LOAD = false 
                  if (this.SUBMIT_ITEM_ORDERS_WAIT) {
                    this.SUBMIT_ITEM_ORDERS_WAIT = false
                    this.SUBMIT_ITEM_ORDERS() 
                  } 
                  this.MBS.actions.error({
                    error:error,
                    from:'SUBMIT_ITEM_ORDERS',
                    page:PAGE_NAME, 
                  }) 
                }) 
              }
            } catch (error) {
              this.SUBMIT_ITEM_ORDERS_LOAD = false 
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_ITEM_ORDERS',
                page:PAGE_NAME, 
              }) 
            }
          }, 
          async SUBMIT_JOINED_STOCK_TAKING(page){ 
            try {   
              this.UPDATE_LOCAL_JOIN_STOCK_TAKING() 
            } catch (error) {
              this.SUBMIT_CASHUPS_LOAD = false
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_JOINED_STOCK_TAKING',
                page:PAGE_NAME, 
              }) 
            }
          },
          async Old_SUBMIT_STOCK_TAKING_ITEMS(page){ 
            try {  
              let data = await this.UPDATE_LOCAL_STOCK_TAKING_ITEMS()
              let uid = this.us?this.us.uid:null
              let stock_taking_order_key = this.LocalJoinedStockTaking?this.LocalJoinedStockTaking.key:null
              let parentPath = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+stock_taking_order_key+"/items/",true)
             
              if (data && stock_taking_order_key) {
                let updateDetails = {}
                let submitted_keys = []
                data.forEach(item => { 
                  if (stock_taking_order_key == item.stock_taking_order_key) {
                    updateDetails[item.key] = {
                      key:item.key,
                      name:item.name,
                      cost_price:item.cost_price?item.cost_price:0,
                      wholesale_price:item.wholesale_price?item.wholesale_price:0,
                      retail_price:item.retail_price?item.retail_price:0,
                      quantity:item.quantity?item.quantity:0,
                      new_quantity:item.new_quantity?item.new_quantity:0,
                      number_quantity:item.number_quantity?item.number_quantity:0,
                      submitted:uid,
                    }
                    submitted_keys.push(item.key)  
                  }
                }); 


                this.$store.dispatch("fi_update",{
                  action:DATA.ITEMS.STOCK_TAKING_ORDERS.values,
                  path:parentPath,
                  data:updateDetails,
                  no_frag:true,
                  us:this.us
                }).then(res=>{ 
                  let size = this.MBS.actions.SIZE(submitted_keys)
                  submitted_keys.forEach( async (key,index) => {
                    await DB.deleteItems({
                      name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values,
                      key:key
                    }) 

                    if (size== (index+1)) {
                      this.UPDATE_LOCAL_STOCK_TAKING_ITEMS() 
                    }
                  });
                }).catch(error=>{
                  console.log(error,'error');
                })
                
              } 
            } catch (error) {
              this.SUBMIT_CASHUPS_LOAD = false
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_STOCK_TAKING_ITEMS',
                page:PAGE_NAME, 
              }) 
            }
          },
          async SUBMIT_STOCK_TAKING_ITEMS(page){ 
            try {  
              let data = await this.UPDATE_LOCAL_STOCK_TAKING_ITEMS()  
              if (data) { 
                if (this.online) {
                  let upload_items = []
                  let with_uploading_items = []
                  data.forEach( item => {  
                    let itemPath = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_taking_orders+item.stock_taking_order_key+"/items/"+item.key+'/',true) 
                    let uploadDetails = {
                      key:item.key?item.key:null,
                      code:item.code?item.code:null,
                      name:item.name?item.name:null,
                      item_kits:item.item_kits?item.item_kits:null,
                      cost_price:item.cost_price?item.cost_price:0,
                      wholesale_price:item.wholesale_price?item.wholesale_price:0,
                      retail_price:item.retail_price?item.retail_price:0,
                      new_quantity:item.new_quantity?item.new_quantity:0,
                      quantity:item.quantity?item.quantity:0,

                      number_opening_stocks:item.number_opening_stocks?item.number_opening_stocks:0,
                      number_stock_receives:item.number_stock_receives?item.number_stock_receives:0,
                      number_stock_transfers:item.number_stock_transfers?item.number_stock_transfers:0,
                      number_stocks_returns:item.number_stocks_returns?item.number_stocks_returns:0,
                      number_stock_adjustments:item.number_stock_adjustments?item.number_stock_adjustments:0,
                      number_sales:item.number_sales?item.number_sales:0,
                      number_quantity:item.number_quantity?item.number_quantity:0, 

                      path:itemPath?itemPath:null
                    } 
                    upload_items.push(uploadDetails)
                    with_uploading_items.push({
                      ...item,
                        uploading:true
                    }) 
                  });  
                  DB.addItems({
                      data:with_uploading_items,
                      name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values, 
                      clear:false
                  }).then(res=>{ 
                    this.UPDATE_LOCAL_STOCK_TAKING_ITEMS()
                    return this.$store.dispatch("fi_update",{
                      action:DATA.ITEMS.STOCK_TAKING_ORDERS.values,
                      // path:itemPath,
                      multiple:true,
                      data:upload_items
                    })
                  }).then(async res=>{  
                    for (const item of upload_items) {
                      await DB.deleteItems({ 
                          name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values, 
                          key:item.key
                      })
                    }
                    return 
                  }).then(()=>{
                      this.UPDATE_LOCAL_STOCK_TAKING_ITEMS()
                  }).catch(error=>{
                    console.log(error,'error....................');
                    DB.addItems({
                      data:with_uploading_items,
                      name:DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values, 
                      clear:false
                    }).then(()=>{
                      this.UPDATE_LOCAL_STOCK_TAKING_ITEMS()
                    })
                  }) 
                }else{ 
                }
              } 
            } catch (error) {
              this.SUBMIT_CASHUPS_LOAD = false
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_STOCK_TAKING_ITEMS',
                page:PAGE_NAME, 
              }) 
            }
          },
          async SUBMIT_PAGE_HISTORIES(page){ 
            try { 
              let data = await this.UPDATE_PAGE_HISTORIES()
              //TODO 
            } catch (error) {
              this.SUBMIT_CASHUPS_LOAD = false
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_PAGE_HISTORIES',
                page:PAGE_NAME, 
              }) 
            }
          },
          async SUBMIT_WAREHOUSE_SESSIONS(page){ 
            try {  
              this.UPDATE_LOCAL_WAREHOUSE_SESSIONS()
              // if (this.SUBMIT_CASHUPS_LOAD) {
              //   this.SUBMIT_CASHUPS_WAIT = true  
              // } else { 
              //   this.SUBMIT_CASHUPS_LOAD = true
              //   this.$store.dispatch("fi_uploadCashups")
              //   .then(async res=>{   
              //     this.SUBMIT_CASHUPS_LOAD = false 
              //     if (this.SUBMIT_CASHUPS_WAIT) { 
              //       this.SUBMIT_CASHUPS_WAIT = false
              //       
              //     } 
              //   }).catch(error=>{ 
              //     this.SUBMIT_CASHUPS_LOAD = false
              //     if (this.SUBMIT_CASHUPS_WAIT) { 
              //       this.SUBMIT_CASHUPS_WAIT = false
              //        
              //     }
              //     this.MBS.actions.error({
              //       error:error,
              //       from:'SUBMIT_CASHUPS',
              //       page:PAGE_NAME, 
              //     }) 
              //   })  
              // }
            } catch (error) {
              // this.SUBMIT_CASHUPS_LOAD = false
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_WAREHOUSE_SESSIONS',
                page:PAGE_NAME, 
              }) 
            }
          },
          async SUBMIT_WAREHOUSE_ORDERS(page){ 
            try {  
              this.UPDATE_LOCAL_WAREHOUSE_ORDERS()
              // if (this.SUBMIT_CASHUPS_LOAD) {
              //   this.SUBMIT_CASHUPS_WAIT = true  
              // } else { 
              //   this.SUBMIT_CASHUPS_LOAD = true
              //   this.$store.dispatch("fi_uploadCashups")
              //   .then(async res=>{   
              //     this.SUBMIT_CASHUPS_LOAD = false 
              //     if (this.SUBMIT_CASHUPS_WAIT) { 
              //       this.SUBMIT_CASHUPS_WAIT = false
              //       this.SUBMIT_CASHUPS()  
              //     } 
              //   }).catch(error=>{ 
              //     this.SUBMIT_CASHUPS_LOAD = false
              //     if (this.SUBMIT_CASHUPS_WAIT) { 
              //       this.SUBMIT_CASHUPS_WAIT = false
              //       this.SUBMIT_CASHUPS()  
              //     }
              //     this.MBS.actions.error({
              //       error:error,
              //       from:'SUBMIT_CASHUPS',
              //       page:PAGE_NAME, 
              //     }) 
              //   })  
              // }
            } catch (error) {
              // this.SUBMIT_CASHUPS_LOAD = false
              this.MBS.actions.error({
                error:error,
                from:'SUBMIT_WAREHOUSE_ORDERS',
                page:PAGE_NAME, 
              }) 
            }
          },
          
          async GET_LOCAL_CASHUP(){
            try { 
                this.inputLoading = true
                let res = await DB.getItems({
                    name:DATA.ITEMS.LOCAL_CASHUPS.values
                }) 
                this.inputLoading = false
                let allLocalCashups = res?res.data:null
                let localCashup = allLocalCashups?allLocalCashups.find(item=>{
                    return !item.closing_cash
                }):null 
                let SelectedCompanyKey = this.SelectedCompanyKey   
                let myCompanies = this.myCompanies   
                
                if (localCashup && myCompanies && !SelectedCompanyKey) {
                  let thisCompany = myCompanies.find(company=>{
                    return company.company_key == localCashup.company_key
                  }) 
                  if (thisCompany) {
                    await this.SELECT_COMPANY(thisCompany)
                  }
                }else{ 
                }
                this.MBS.actions.UPDATE_LOCAL_CASHUP(localCashup,allLocalCashups)
                return allLocalCashups
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'GET_LOCAL_CASHUP',
                    page:PAGE_NAME, 
                }) 
            } 
            return null
          },
          DIALOG_YES(action){ 
            try { 
              if (action) { 
                  if (action.code ==="=GO") {
                    if (action.YES) {
                        this.MBS.actions.go(action.to) 
                    } else {
                      
                    }
                  } 
              } 
            } catch (error) {
              this.MBS.actions.error({
                error:error,
                from:'DIALOG_YES',
                page:PAGE_NAME, 
              }) 
            }
          }, 
          async USER_MONITOR(time=500){
            try {
                setTimeout(async() => {
                  try {
                    let us = this.us
                    let ud = this.ud
                    let upTime = this.upTime
                    let loggedTime = this.loggedTime
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses
                    let access = this.MBS.actions.GET_ACCESS()  
                    
                    let user_details_data = await DB.getUserDetails()
                    let current_uid = user_details_data.current_uid
                    let action_request_uid = user_details_data.action_request_uid
                    let logged_uid = user_details_data.logged_uid
                    let  user_details = user_details_data.user_details  
                    if (us) {
                      if (action_request_uid) { 
                        //
                      } 
                      if (current_uid) { 
                        this.$store.dispatch('fi_get_user_items',{
                          name:DATA.ITEMS.USER_DETAILS.values,
                          on:true, 
                          cash:true, 
                          get:true
                        }) 
                      } 
                      
                      if (user_details) {
                        this.$store.dispatch('setUserDetails',{
                          ...user_details,
                          action_request_uid:action_request_uid,
                          current_uid:current_uid
                        })
                      } 
                      //IF ADMIN
                      if (us.email === DATA.APP.ADMIN_EMAIL) {
                        this.$store.dispatch('fi_get_item',{
                          name:DATA.ITEMS.ERRORS.values,
                          on:true, 
                          cash:true, 
                          get:true
                        })   
                        // this.$store.dispatch('fi_get_user_items',{
                        //   name:DATA.ITEMS.VISITERS.values,
                        //   on:true, 
                        //   cash:true, 
                        //   get:true
                        // }) 
                      }  
                    }else{
                      this.$store.dispatch('setUserDetails',null)
                    } 
                  } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'USER_MONITOR',
                        page:PAGE_NAME, 
                    })
                  }
                }, time); 
            } catch (error) {
              this.MBS.actions.error({
                  error:error,
                  from:'USER_MONITOR',
                  page:PAGE_NAME, 
              })
            }
          },
        },
        beforeDestroy(){ 
          this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
          this.MBS.events.$off('SUBMIT_SALES', this.SUBMIT_SALES);    
          this.MBS.events.$off('SUBMIT_ITEM_ORDERS', this.SUBMIT_ITEM_ORDERS);         
          this.MBS.events.$off('SUBMIT_WAREHOUSE_SESSIONS', this.SUBMIT_WAREHOUSE_SESSIONS);    
          this.MBS.events.$off('SUBMIT_WAREHOUSE_ORDERS', this.SUBMIT_WAREHOUSE_ORDERS);    
          this.MBS.events.$off('SUBMIT_STOCK_TAKING_ITEMS', this.SUBMIT_STOCK_TAKING_ITEMS);    
          this.SUBMIT_SALES()
          this.SUBMIT_ITEM_ORDERS() 
          this.SUBMIT_WAREHOUSE_SESSIONS()
          this.SUBMIT_WAREHOUSE_ORDERS()
          this.SUBMIT_STOCK_TAKING_ITEMS()
        },
        watch:{ 
          us(user){ 
            this.USER_MONITOR(1)
            this.NAV_CONTROLLER(true,'us..')
            this.INITIATE_BILLING_FEATURES_DB()
          },
          ud(user){ 
            // this.NAV_CONTROLLER(false,'ud')
            // console.log(user,'user.....');
            // let companies = user?user.companies:null
            // let child_keys = [] 
            // let company_key = this.SELECTED_COMPANIES
            // if(companies){ 
            //   let path = DATA.PATHS.companies 
            //   for (const key in companies) {
            //     child_keys.push(path+key)  
            //   } 
            //   this.$store.dispatch('fi_get_my_company',{
            //     company_key:company_key,
            //     name:DATA.ITEMS.COMPANIES.values,
            //     child_keys:child_keys,
            //     on:true
            //   }).then(res=>{
            //     console.log(res,'res......');
            //   }).catch(error=>{
            //     console.log(error,'err..........');
            //   })

            // }
          },
          vs(user){ 
            this.NAV_CONTROLLER(false,'vs..')
          },
          vs_width(user){ 
            this.NAV_CONTROLLER(true,'vs_width')
          },
          route(value){ 
            this.addHistory()
            this.REDIRECT()
            this.SUB_TOOLBAR() 
            this.CHECK_URL_COMPANY() 
            this.NAV_CONTROLLER(false,'route')
          },
          loading(value){  
          },
          processes(value){  
          },
          responses(value){ 
             
          },
          myCompanies(value){
            this.CHECK_URL_COMPANY()  
          }, 
          SelectedCompany(value){ 
          }, 
          SelectedCompanyKey(value){ 
            let us = this.us
            let uid = us?us.uid:null
            let company_key = value 
            if (company_key && uid) {
              this.$store.dispatch("fi_update",{
                path:"USER_STATUSES/"+uid,
                action:"status",
                no_frag:true,
                data:{company_key:company_key},
                us:us
              }).then(res=>{ 
              }).catch(error=>{
                console.log(error,'e....');
              }) 
            }
            this.INITIATE_COMPANY_DB(value)  
          },
          CompanySettings(value){ 
            this.INITIATE_COMPANY_ITEMS_DB(value)  
          }, 
          online(value){ 
            this.SUBMIT_SALES()
            this.SUBMIT_ITEM_ORDERS() 
            this.SUBMIT_STOCK_TAKING_ITEMS()
            this.INITIATE_BILLING_FEATURES_DB()
          }, 
          LocalCashups(cashups){ 
            let localCashup = cashups?cashups.find(item=>{
              return !item.closing_cash
            }):null 
            let SelectedCompanyKey = this.SelectedCompanyKey   
            let myCompanies = this.myCompanies  
             
            if (localCashup && myCompanies && !SelectedCompanyKey) {
              let thisCompany = myCompanies.find(company=>{
                return company.company_key == localCashup.company_key
              }) 
              if (thisCompany) {
                this.SELECT_COMPANY(thisCompany)
              }
            }else{ 
            }
            this.MBS.actions.UPDATE_LOCAL_CASHUP(localCashup,cashups)
          },  
          BillingPlans(value){ 
          },  
          BillingPlanFeatures(value){
            this.INITIATE_BILLING_DB() 
          }
        }   
    } 
</script>
<style >
  .offline{
    position: fixed !important;
    width: 100%; 
    bottom: 0px;
    margin: 10px;
    margin-top: 100px;
    z-index: 10000;

  }
</style>

 
