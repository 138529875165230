import Vue from 'vue';  
import { store } from '../store'; 
import DATA from './DATA'; 


export const TextBus = new Vue({
    data(){
        return{
            lang:'english',  
        }
    }, 
    computed:{
        us(){
            let returnUser = store.getters.getUser
            return returnUser
        },  
        ud(){
            let returnUser = store.getters.getUserD
            return returnUser
        }, 

        //----------[INTERNET CONNECTION]-------//
        internet_connection_error(){
            let eng = {
                title:'INTERNET ERROR',
                text:"Please, make sure you have a working internet and try again.",
                btnYes:"OK",
                btnNo:"OK"
            }
            return eng
        }, 
        action_require_internet(){
            return (action)=>{
                let eng = {
                    title:"YOU ARE OFFLINE",
                    text:this.TEXT_UP(action)+", requires a working internet. Make sure you are connected and try again.",
                    btnYes:"TRY AGAIN",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        //----------[ERROR]-------//
        error_0(){
            let eng = {
                title:'ERROR',
                text:"Something went wrong. Please try later",
                btnYes:"OK",
                btnNo:"OK"
            }
            return eng
        }, 
        //----------[PASSWORD]-------//
        password_mismatch(){
            let eng = {
                title:'PASSWORD MISMATCH',
                text:"Your password and confirmation password do not match.",
                btnYes:"OK",
                btnNo:"CANCEL"
            }
            return eng
        }, 
        password_short(){
            let eng = {
                title:'SHORT PASSWORD',
                text:"Short password are easy to guess. Try one with at least 8 characters",
                btnYes:"OK",
                btnNo:"CANCEL"
            }
            return eng
        }, 
        password_no(){
            let eng = {
                title:'PASSWORD ERROR',
                text:"Strong password required",
                btnYes:"OK",
                btnNo:"CANCEL"
            }
            return eng
        },    
        //----------[PHONE NUMBER]-------//
        phone_number_success_confirmed(){
            return name=>{
                let eng = {
                    title:'SUCCESSFUL CONFIRMED',
                    text:"The phone number ("+this.TEXT_UP(name)+") successfully confirmed.",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng
            }
        }, 
        phone_number_already_registered(){
            return name=>{
                let eng = {
                    title:'REGISTERED ALREADY',
                    text:"Phone number ("+name+") has already been registered.",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        phone_number_mast_be_verified(){
            return name=>{
                let eng = {
                    title:'VERIFY YOUR PHONE NUMBER',
                    text:"Your phone number ("+name+") needs to be verified for the security issues.",
                    btnYes:"VERIFY",
                    btnNo:"NOT NOW"
                }
                return eng 
            }
        },

        //----------[REGISTER ]-------//
        number_not_registered(){
            return phoneNumber=>{
                let eng = {
                    title:'NOT REGISTERED',
                    text:"your phone number ("+phoneNumber+") seams not be register. Please, register first.",
                    btnYes:"REGISTER",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        number_not_properly_registered(){
            return phoneNumber=>{
                let eng = {
                    title:'NOT PROPERLY REGISTERED',
                    text:"your phone number ("+phoneNumber+") seams not be completely register. Please, register first.",
                    btnYes:"REGISTER",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        user_registered_verify_number(){
            return name=>{
                let eng = {
                    title:'USER REGISTERED SUCCESSFUL',
                    text:"The user ("+this.TEXT_UP(name)+") successfully registered. Please, continue and verify the phone number.",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        verification_sent(){
            return name=>{
                let eng = {
                    title:'VERIFICATION SENT',
                    text:"SMS with the verification code was successfully sent to ("+this.TEXT_UP(name)+"). Continue by entering the sent code.",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        
        cancel_registering(){
            return name=>{
                let eng = {
                    title:'CANCEL REGISTERING',
                    text:"Are you sure you want to cancel registering ("+this.TEXT_UP(name)+")?",
                    btnYes:"CANCEL",
                    btnNo:"NO"
                }
                return eng
            }
        }, 
        cancel_number_verification(){
            return name=>{
                let eng = {
                    title:'CANCEL NUMBER VERIFICATION',
                    text:"Are you sure you want to cancel number verification for ("+this.TEXT_UP(name)+")?",
                    btnYes:"CANCEL",
                    btnNo:"NO"
                }
                return eng
            }
        }, 

        //----------[SIGN ]-------//
        signup_continue_add_information(){
            return phoneNumber=>{
                let eng = {
                    title:'REGISTERING ('+phoneNumber+")",
                    text:"Enter your details to continue registering ("+phoneNumber+"). You can click Cancel Button to start again and register another number.",
                    btnYes:"CONTINUE",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        signup_continue_add_password(){
            let eng = {
                title:'REGISTERING ('+this.us?this.us.phoneNumber:'..'+")",
                text:"Continue setting up password to finish registering.",
                btnYes:"CONTINUE",
                btnNo:"CANCEL"
            }
            return eng
        },
        signup_verify_continue_add_password(){
            let eng = {
                title:'VERIFY PHONE',
                text:"Verify this phone number and set up password to finish registering.",
                btnYes:"CONTINUE",
                btnNo:"CANCEL"
            }
            return eng
        },
        signup_already_registered(){
            let eng = {
                title:'REGISTERED ALREADY',
                text:"You phone number has already been register. Enter your password to login. You can click Forget Password button to recover password",
                btnYes:"Login",
                btnNo:"Forget Password"
            }
            return eng
        },
        

        //----------[Login ]-------//
        login_successful(){
            let eng = {
                title:'SUCCESSFUL',
                text:"You have successful login.",
                btnYes:"OK",
                btnNo:""
            }
            return eng
        },
        login_wrong_password(){
            let eng = {
                title:'Login failed',
                text:"You enter wrong password. Please check and try again. You can click Forget Password button to recover password",
                btnYes:"Login",
                btnNo:"Forgot password"
            }
            return eng
        },

        //----------[CODE]-------//
        check_code_error_0(){
            let eng = {
                title:'ERROR CODE',
                text:"Code can not be zero (0). Try another code or click AUTO GENERATE CODE BUTTON to generate a unique code.",
                btnYes:"AUTO GENERATE CODE",
                btnNo:"CANCEL"
            }
            return eng
        }, 
        check_code_error_exist(){
            let eng = {
                title:'ERROR CODE',
                text:"This Code has already been used. Try another code or click AUTO GENERATE CODE BUTTON to generate a unique code.",
                btnYes:"AUTO GENERATE CODE",
                btnNo:"CANCEL"
            }
            return eng
        }, 
        check_code_error_might_exist(){
            let eng = {
                title:'ERROR CODE',
                text:"This Code might have already been used. Try another code or click AUTO GENERATE CODE BUTTON to generate a unique code.",
                btnYes:"AUTO GENERATE CODE",
                btnNo:"CANCEL"
            }
            return eng
        }, 
        check_code_mast_use_auto_code(){
            let eng = {
                title:'ERROR AUTO CODE',
                text:"This action require Auto generated code. Click AUTO GENERATE CODE BUTTON to generate a unique code.",
                btnYes:"AUTO GENERATE CODE",
                btnNo:"CANCEL"
            }
            return eng
        }, 
        check_code_error_unique_code(){
            let eng = (item)=>  {
                return {
                    title:'ERROR CODE',
                    text:"Please, make sure each "+(item?item:'item')+" has a valid unique code. you can fix this by clicking AUTO GENERATE CODE button, to auto generate code.",
                    btnYes:"AUTO GENERATE CODE",
                    btnNo:"CANCEL" 
                }
            }
            return eng
        }, 
        generate_code_successful(){
            let eng = {
                title:'GENERATED',
                text:"Code, successfully generated.",
                btnYes:"OK",
                btnNo:""
            }
            return eng
        }, 
         
        //----------[CASHUP]-------//
        //add
        upload_before_closing_cashup(){
            return name=>{
                let eng = {
                    title:'UPLOAD SALES FIRST ',
                    text:"You have un-uploaded sales. Please make sure you are connected to internet and Upload the sales. Z-Read might not balance if not upload sales.",
                    btnYes:"UPLOAD SALES",
                    btnNo:"CLOSE & UPLOAD LATER"
                }
                return eng 
            }
        },
        
        //----------[ACTION]-------//
        //add
        action_cancel(){
            return name=>{
                let eng = {
                    title:'CANCEL '+this.TEXT_UP(name),
                    text:"Are you sure, you want to cancel this action before finish "+this.TEXT_UP(name)+"?",
                    btnYes:"CANCEL",
                    btnNo:"NO"
                }
                return eng 
            }
        },
        
        //----------[ITEM]-------//
        //add
        add_item(){
            return name=>{
                let eng = {
                    title:'ADD '+this.TEXT_UP(name),
                    text:"Are you sure you want to add this "+this.TEXT_UP(name)+"?",
                    btnYes:"ADD",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        add_item_permission_denied(){
            return name=>{
                let eng = {
                    title:'PERMISSION DENIED',
                    text:"Sorry, you have no "+this.TEXT_UP(name)+" write permission. Contact system admin for more info.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        },
        add_item_error_not_selected(){
            return name=>{
                let eng = {
                    title:this.TEXT_UP(name)+' ERROR',
                    text:"Make sure you select "+this.TEXT_UP(name)+" and try again",
                    btnYes:"Ok",
                    btnNo:""
                }
                return eng
            }
        },
        add_item_error_exist(){
            return name=>{
                let eng = {
                    title:'EXIST',
                    text:this.TEXT_UP(name)+", already been added. ",
                    btnYes:"Ok",
                    btnNo:""
                }
                return eng
            }
        }, 
        add_item_success(){
            return name=>{
                let eng = {
                    title:'SUCCESSFUL',
                    text:this.TEXT_UP(name)+", successfully added.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        add_item_fail(){
            return name=>{
                let eng = {
                    title:'NOT SUCCESSFUL',
                    text:"Error while adding "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 

        //add with action
        item_action(){
            return (name,action)=>{
                let eng = {
                    title:this.TEXT_UP(action)+' '+this.TEXT_UP(name),
                    text:"Are you sure you want to "+this.TEXT_UP(action)+" "+this.TEXT_UP(name)+"?",
                    btnYes:this.TEXT_UP(action),
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        item_action_permission_denied(){
            return (name,action)=>{
                let eng = {
                    title:'PERMISSION DENIED',
                    text:"Sorry, you have no "+this.TEXT_UP(name)+" "+this.TEXT_UP(action)+" permission. Contact system admin for more info.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        item_action_error_exist(){
            return (name,action)=>{
                let eng = {
                    title:'EXIST',
                    text:this.TEXT_UP(name)+", already been "+this.TEXT_UP(action)+". ",
                    btnYes:"Ok",
                    btnNo:""
                }
                return eng
            }
        }, 
        item_action_success(){
            return (name,action)=>{
                let eng = {
                    title:'SUCCESSFUL',
                    text:this.TEXT_UP(action)+" "+this.TEXT_UP(name)+", successful.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        item_action_fail(){
            return (name,action)=>{
                let eng = {
                    title:'NOT SUCCESSFUL',
                    text:"Error while "+this.TEXT_UP(action)+" "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        confirm_action(){
            return (name,action)=>{
                let eng = {
                    title:'CONFIRM '+this.TEXT_UP(action)+' '+this.TEXT_UP(name),
                    text:"Enter your password to confirm "+this.TEXT_UP(action)+" "+this.TEXT_UP(name)+".",
                    btnYes:'CONFIRM',
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        //option
        option_not_available(){
            return (name)=>{
                let eng = {
                    title:'OPTION NOT AVAILABLE',
                    text:this.TEXT_UP(name)+" option, is current not available. for more information contact ("+DATA.APP.ADMIN_PHONE+") system admin.",
                    btnYes:'OK',
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        //upload
        upload_item(){
            return name=>{
                let eng = {
                    title:'UPLOAD '+this.TEXT_UP(name),
                    text:"Are you sure you want to upload "+this.TEXT_UP(name)+"?",
                    btnYes:"UPLOAD",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        upload_selected_excel_item(){
            return name=>{
                let eng = {
                    title:'UPLOAD '+this.TEXT_UP(name),
                    text:"Are you sure you want to upload "+this.TEXT_UP(name)+" from selected excel file.?",
                    btnYes:"UPLOAD",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        upload_and_replace_item(){
            return name=>{
                let eng = {
                    title:'UPLOAD '+this.TEXT_UP(name),
                    text:"The current "+this.TEXT_UP(name)+" list will be overridden with this new list",
                    btnYes:"UPLOAD",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        upload_item_success(){
            return name=>{
                let eng = {
                    title:'SUCCESSFUL',
                    text:this.TEXT_UP(name)+", successfully uploaded.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        upload_item_fail(){
            return name=>{
                let eng = {
                    title:'NOT SUCCESSFUL',
                    text:"Error while uploading "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        //edit
        edit_item(){
            return name=>{
                let eng = {
                    title:'EDIT '+this.TEXT_UP(name),
                    text:"Are you sure you want to edit this "+this.TEXT_UP(name)+"?",
                    btnYes:"EDIT",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        edit_item_permission_denied(){
            return name=>{
                let eng = {
                    title:'PERMISSION DENIED',
                    text:"Sorry, you have no "+this.TEXT_UP(name)+" edit permission. Contact system admin for more info.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        },
        edit_item_success(){
            return name=>{
                let eng = {
                    title:'SUCCESSFUL',
                    text:this.TEXT_UP(name)+", successfully edited.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        edit_item_fail(){
            return name=>{
                let eng = {
                    title:'NOT SUCCESSFUL',
                    text:"Error while editing "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        //edit
        update_item(){
            return name=>{
                let eng = {
                    title:'UPDATE '+this.TEXT_UP(name),
                    text:"Are you sure you want to update this "+this.TEXT_UP(name)+"?",
                    btnYes:"UPDATE",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        update_item_permission_denied(){
            return name=>{
                let eng = {
                    title:'PERMISSION DENIED',
                    text:"Sorry, you have no "+this.TEXT_UP(name)+" update permission. Contact system admin for more info.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        },
        update_item_success(){
            return name=>{
                let eng = {
                    title:'SUCCESSFUL',
                    text:this.TEXT_UP1(name)+", successfully updated.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        update_item_fail(){
            return name=>{
                let eng = {
                    title:'NOT SUCCESSFUL',
                    text:"Error while updating "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        //remove
        remove_item(){
            return name=>{
                let eng = {
                    title:'REMOVE '+this.TEXT_UP(name),
                    text:"Are you sure, you want to remove this "+this.TEXT_UP(name)+" the list?",
                    btnYes:"REMOVE",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        remove_item_success(){
            return name=>{
                let eng = {
                    title:'SUCCESSFUL',
                    text:this.TEXT_UP(name)+", successfully removed.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        remove_item_fail(){
            return name=>{
                let eng = {
                    title:'NOT SUCCESSFUL',
                    text:"Error while removing "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        //delete
        delete_item(){
            return name=>{
                let eng = {
                    title:'DELETE '+this.TEXT_UP(name),
                    text:"Are you sure you want to delete this "+this.TEXT_UP(name)+"?",
                    btnYes:"DELETE",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        delete_item_all(){
            return name=>{
                let eng = {
                    title:'DELETE ALL '+this.TEXT_UP(name),
                    text:"Are you sure you want to delete all "+this.TEXT_UP(name)+"?",
                    btnYes:"DELETE",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        delete_item_permission_denied(){
            return name=>{
                let eng = {
                    title:'PERMISSION DENIED',
                    text:"Sorry, you have no "+this.TEXT_UP(name)+" delete permission. Contact system admin for more info.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        },
        delete_item_success(){
            return name=>{
                let eng = {
                    title:'SUCCESSFUL',
                    text:this.TEXT_UP(name)+", successfully deleted.",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        }, 
        delete_item_fail(){
            return name=>{
                let eng = {
                    title:'NOT SUCCESSFUL',
                    text:"Error while deleting "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        },  
        delete_image_first(){
            return name=>{
                let eng = {
                    title:'DELETE IMAGE FIRST',
                    text:"This "+this.TEXT_UP(name)+", has image. To continue you must delete image first.",
                    btnYes:"DELETE",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },

        //till
        till_is_busy(){
            return  till=>{ 
                let name = till?till.name:name
                let teller_name = till?till.teller_name:null
                let operating_date_ = till?till.operating_date_:null
                let eng = {
                    title:'TILL IN USE',
                    text:"The selected till (Name:"+(name?name:'...')+", Teller:"+(teller_name?teller_name:'...')+", Shift:"+operating_date_+"), is running. It must be closed first before using it again.",
                    btnYes:"MANAGE TILL(S)",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        
        //ticket booking
        booking_ticket_success(){
            return seats=>{
                let size = seats?seats.length:0
                let name = size>1?DATA.ITEMS.BUS_SEATS.names:DATA.ITEMS.BUS_SEATS.name
                let eng = {
                    title:'SUCCESSFUL',
                    text:"You have successfully booked ticket for the selected "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        booking_ticket_failed(){
            return seats=>{
                let size = seats?seats.length:0
                let name = size>1?DATA.ITEMS.BUS_SEATS.names:DATA.ITEMS.BUS_SEATS.name
                let eng = {
                    title:'NOT SUCCESSFUL',
                    text:"Something went wrong while trying to book ticket for the selected "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        hold_bus_seat_success(){
            return seats=>{
                let size = seats?seats.length:0
                let name = size>1?DATA.ITEMS.BUS_SEATS.names:DATA.ITEMS.BUS_SEATS.name
                let eng = {
                    title:'SUCCESSFULLY HOLD',
                    text:"Selected "+this.TEXT_UP(name)+(size>1?' are':' is')+" temporary hold for you to finish booking.",
                    btnYes:"CONTINUE",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        hold_bus_seat_fail(){
            return seats=>{
                let size = seats?seats.length:0
                let name = size>1?DATA.ITEMS.BUS_SEATS.names:DATA.ITEMS.BUS_SEATS.name
                let eng = {
                    title:'ERROR HOLDING '+this.TEXT_UP(name),
                    text:"There was error while trying to hold "+this.TEXT_UP(name)+".",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        already_hold_bus_seat(){
            return seats=>{
                let size = seats?seats.length:0
                let name = size>1?DATA.ITEMS.BUS_SEATS.names:DATA.ITEMS.BUS_SEATS.name
                let eng = {
                    title:'ERROR HOLDING '+this.TEXT_UP(name),
                    text:(size>1?'Some of the selected ':"Selected ")+this.TEXT_UP(name)+" already held.",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        already_booked_bus_seat(){
            return seats=>{
                let size = seats?seats.length:0
                let name = size>1?DATA.ITEMS.BUS_SEATS.names:DATA.ITEMS.BUS_SEATS.name
                let eng = {
                    title:'ERROR BOOKING '+this.TEXT_UP(name),
                    text:(size>1?'Some of the selected ':"Selected ")+this.TEXT_UP(name)+" already booked.",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },

        //EXCEL
        working_sheet_not_found(){
            return name=>{
                let eng = {
                    title:this.TEXT_UP(name)+' WORKING SHEET NOT FOUND',
                    text:"The select file does not contain  "+this.TEXT_UP(name)+" work sheet. Please, make sure you selected the right file and try again.",
                    btnYes:"TRY AGAIN",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        //FIELD
        field_required(){
            return name=>{
                let eng = {
                    title:this.TEXT_UP(name)+' FIELD REQUIRED',
                    text:"The "+this.TEXT_UP(name)+" is required. to continue, make sure you fill all the required fields.",
                    btnYes:"OK",
                    btnNo:"CANCEL"
                }
                return eng
            }
        }, 
        
        //COMPANIES
        company_success_created(){
            return (name)=>{
                let eng = {
                    title:'COMPANY SUCCESSFUL CREATED',
                    text:this.TEXT_UP(name)+", successfully created. Wait for kipCount activation. For more information contact (+265 993 259 202)",
                    btnYes:"OK",
                    btnNo:""
                }
                return eng
            }
        },
        switch_company(){
            return name=>{
                let eng = {
                    title:'SWITCH COMPANY',
                    text:"Are you sure you want to switch company to "+this.TEXT_UP(name)+"?",
                    btnYes:"SWITCH",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        switch_company_confirm(){
            return name=>{
                let eng = {
                    title:'SWITCH COMPANY',
                    text:"Are you sure you want to switch company to "+this.TEXT_UP(name)+"? Enter your password to confirm switching company.",
                    btnYes:"SWITCH",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        close_register_before_switch_company(){
            return name=>{
                let eng = {
                    title:'CLOSE REGISTER FIRST',
                    text:"You must close the register before switching the current company to "+this.TEXT_UP(name)+".",
                    btnYes:"CLOSE REGISTER",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },
        close_register_before_logout(){
            return name=>{
                let eng = {
                    title:'CLOSE REGISTER FIRST',
                    text:"You must close the register before logging out.",
                    btnYes:"CLOSE REGISTER",
                    btnNo:"CANCEL"
                }
                return eng
            }
        },  
        close_register_before_z_read(){
            return name=>{
                let eng = {
                    title:'CLOSE REGISTER FIRST',
                    text:"You must close the register before closing the shift.",
                    btnYes:"OK", 
                }
                return eng
            }
        },
        open_register_before_receive_payment(){
            return name=>{
                let eng = {
                    title:'OPEN REGISTER FIRST',
                    text:"You must open the register before receiving cash. Go to sales dashboard and open register.",
                    btnYes:"OK", 
                    btnNo:"Not now", 
                }
                return eng
            }
        },  

        //IMPORT
        import_update(){
            return (name)=>{
                let eng = {
                    title:'IMPORT '+this.TEXT_UP(name)+' AND UPDATE DATA',
                    text:"Are you sure you want to import selected "+this.TEXT_UP(name)+"? The imported data will be added to the system existing data without deleting. ",
                    btnYes:'UPDATE',
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },
        import_overwrite(){
            return (name)=>{
                let eng = {
                    title:'IMPORT '+this.TEXT_UP(name)+' AND OVERWRITE DATA',
                    text:"Are you sure you want to import selected "+this.TEXT_UP(name)+" and overwrite already existing data? All existing data will be replaced by imported data. ",
                    btnYes:"OVERWRITE",
                    btnNo:"CANCEL"
                }
                return eng 
            }
        },

    }, 
    methods:{
        //text
        TEXT_UP(text){
            if (!text) {return null}
            text = ''+text
            let tx = text.toUpperCase()
            return tx
        },
        TEXT_UP1(text){
            if (!text) {return null}
            let tx = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
            return tx
        },
        TEXT_UP11(text){
            if (!text) {return null}
            var splitStr = text.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) { 
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            } 
            return splitStr.join(' '); 
        },
        TEXT_UP0(text){
            if (!text) {return null} 
            return text.toLowerCase(); 
        },
    }
});
 