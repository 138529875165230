<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 36.85 40.51"
  :fill="iconColor" xml:space="preserve">

    <rect x="16.03" y="14.27" width="3.25" height="6.34"/><path d="M30.28,36.93V14.27H21.87V28.16H13.45V14.27H7.63V0H0V2.58H5V31.89H5.2a7.41,7.41,0,0,1,7.4,7.4v.22H32.79V36.93ZM19.05,34V31.38H25.1V34Z"/><path d="M8.6,35.88a4.82,4.82,0,1,0,0,6.81A4.82,4.82,0,0,0,8.6,35.88ZM5.18,41.07a1.66,1.66,0,1,1,1.65-1.65A1.65,1.65,0,0,1,5.18,41.07Z"/><rect x="13.45" y="14.27" width="0.96" height="13.87"/><rect x="20.89" y="14.27" width="1.81" height="13.87"/><rect x="14.67" y="18.65" width="7.32" height="13.87" transform="translate(-7.26 43.92) rotate(-90)"/>

  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
