 
<template>
    <v-layout fill-height align-center justify-center class="b2 lighten-1">
        <v-container grid-list-xs>
            <v-layout justify-center>
                <v-col cols="12" md="10" lg="8">
                    <v-card tile  min-width="">
                        <v-row>
                            <v-col sm="5" cols="12" class="pr-0">
                                <v-layout fill-height align-center justify-center>
                                    <v-avatar
                                        size="150"
                                        color="primary"> 
                                    </v-avatar>  
                                </v-layout>
                            </v-col> 
                            
                            <v-col sm="7" cols="12" class="pl-0">
                                <v-layout   fill-height align-center justify-center  >
                                    <v-divider vertical></v-divider> 
                                    <v-spacer></v-spacer>
                                    <v-card color="transparent" height="100%" width="95%"   flat class="py-5" >
                                        <v-card-title primary-title class="text-center">
                                            <v-layout justify-center>
                                                <h2 >Welcome to mPOS</h2>  
                                            </v-layout>
                                        </v-card-title>
                                        <v-card-text>
                                            <form class="mt-5" id="form_login" @submit.prevent="LOGIN(input)">
                                                <v-text-field
                                                    name="name"
                                                    type="email"
                                                    label="Username" 
                                                    id="username"
                                                    filled required
                                                    v-model="input.username" /> 
                                                    
                                                <v-text-field 
                                                    name="password"
                                                    label="Username" 
                                                    id="password"
                                                    filled required
                                                    v-model="input.password" 
                                                    autocomplete="one-time-code"  
                                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :rules="[rules.required, rules.min]"
                                                    :type="showPassword ? 'text' : 'password'" 
                                                    hint="At least 8 characters"
                                                    counter
                                                    @click:append="showPassword = !showPassword"/>  
                                                <v-btn type="submit" tile class="mt-5" width="100%" color="primary">Login</v-btn>
                                            </form>  
                                        </v-card-text>
                                    </v-card>
                                    <v-spacer></v-spacer>
                                </v-layout> 
                            </v-col>
                        </v-row>
                    </v-card>  
                </v-col> 
            </v-layout> 
        </v-container>
    </v-layout>
</template> 
<script> 
    import DATA from '../../plugins/DATA'
    let PAGE_NAME = 'ACCESS_PAGE' 

    export default {
        props:['value','backgroundColor','items','time'],
        data(){
            return{
                show:true,   
                showPassword:false,   
                ACCESS_LOADING:true,
                ACCESS:this.value,
                input:{},
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v?(v.length >= 8):false || 'Min 8 characters',
                    emailMatch: () => (`The email and password you entered don't match`),
                },
            }
        },
        created(){ 
             
        },
        mounted(){   
            this.ACCESS_PAGE(100)
        },
        computed:{
            route(){
                return this.$route
            },
            vs(){ 
                return this.$vuetify.breakpoint
            }, 
            us(){
                return this.$store.getters.getUser
            }, 
            ud(){
                return this.$store.getters.getUserDetails
            }, 
            upTime(){
                return this.$store.getters.getUpTime
            }, 
            loggedTime(){
                return this.$store.getters.getLoggedTime
            }, 
            loading(){  
                return this.$store.getters.getLoading
            },
            processes(){  
                return this.$store.getters.getProcesses
            }, 
            responses(){  
                return this.$store.getters.getResponses
            }, 
        },
        methods:{ 
            LOGIN(input){
                try {
                    if (input) {
                        this.$store.dispatch('fi_signWithEmail',{
                            email:input.username,
                            password:input.password
                        }).then(res=>{
                            console.log(res,"res.........");
                        }).catch(error=>{
                            console.log(error,'error.......');
                        })
                    }else{

                    }
                    console.log(input,'............');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ACCESS_PAGE',
                        page:PAGE_NAME, 
                    })
                }
            },
            ACCESS_PAGE(time=500){
                try {
                    setTimeout(() => {
                        let us = this.us
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses
                        let access = this.MBS.actions.GET_ACCESS() 
                        
                         

                    }, time); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ACCESS_PAGE',
                        page:PAGE_NAME, 
                    })
                }
            },
        },
        watch:{ 
            ACCESS(value){
                this.$emit('input',value)
            },
            us(user){
                this.ACCESS_PAGE(10) 
            },
            ud(user){
                this.ACCESS_PAGE(1) 
            },
            route(value){ 
                if (!value.hash) { 
                    // this.ACCESS = true
                    // this.ACCESS_LOADING = true  
                } 
                this.ACCESS_PAGE()
            },
            loading(value){ 
                this.ACCESS_PAGE()
            },
            processes(value){ 
                this.ACCESS_PAGE(1)
            },
            responses(value){ 
                this.ACCESS_PAGE(1)
            } 
        } 

    }
</script>
<style>
  
</style> 