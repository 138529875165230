<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :table_tabs="tableTabs"
                            :top_label="false"
                            :filter_date="false"
                            :filter_date_value="'created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="CardsDataItems"   
                            :path_item="CARD_PATH"   
                            :select_items="Selectors"  
                            :field_items="DATA.FIELDS.card_add_user"
                            :total_item_values="TOTAL_ITEM_VALUES"    
                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            <!-- GIVEN AMOUNT -->
            <v-dialog
                v-model="dialog_pay_bill"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">Pay Bill</div> 
                        <v-spacer></v-spacer>
                        <v-btn icon  dark @click="dialog_pay_bill=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text v-if="selected_item">
                        <br>
                        <v-list v-if="selected_item" dense class="mb-5" >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Payee</v-list-item-title>
                                    <v-list-item-subtitle>{{selected_item.payee_name}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title>Amount</v-list-item-title>
                                    <v-list-item-subtitle>{{selected_item.amount_}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title>Paid</v-list-item-title>
                                    <v-list-item-subtitle>{{selected_item.total_paid_}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title>Balance</v-list-item-title>
                                    <v-list-item-subtitle>{{selected_item.balance_}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>  
                        <v-divider class="mx-2 mb-5"></v-divider>
                        <form @submit.prevent="MAKE_PAYMENT(selected_item,input)">
                            <v-layout row wrap class="ma-0 ">
                                <v-flex xs6 sm12>
                                    <div class="ml-2">Payment Option</div>
                                    <v-autocomplete required
                                        v-model="input.payment_method_code"
                                        :items="PaymentMethodsData"
                                        item-text="name"
                                        item-value="code"
                                        filled  label="Selected Payment Option"
                                        v-mask="currencyMask"
                                        hint="0.00" class="mx-2"
                                    ></v-autocomplete> 
                                </v-flex>
                                <v-flex xs6>
                                    <div class="ml-2">Pay amount</div>
                                    <v-text-field required
                                        v-model="input.amount"
                                        filled  
                                        v-mask="currencyMask"
                                        hint="0.00" class="mx-2"
                                    ></v-text-field> 
                                </v-flex>
                                <v-flex xs6>
                                    <div class="ml-2">Balance After Pay</div>
                                    <v-text-field   
                                        :value="MBS.actions.money(selected_item.balance-toNumber(input.amount))"
                                        filled   disabled
                                        v-mask="currencyMask"
                                        hint="0.00" class="mx-2"
                                    ></v-text-field>  
                                </v-flex> 
                            </v-layout> 
                            <v-layout class="ma-0">
                                <v-spacer></v-spacer>
                                <v-btn :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1">Make Payment</v-btn>
                            </v-layout>
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  

            <!-- SELECTED CARD -->
            <v-dialog
                v-model="dialog_selected_card"
                scrollable   
                :persistent="true" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="SELECTED_CARD">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">
                            <span v-if="!SELECTED_CARD.card_customer_key">BLANK CARD</span>
                            <span v-else>CARD</span> 
                        </div> 
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold secondary--text not-f7 ">Bal: mwk{{MBS.actions.money(SELECTED_CARD.balance_amount)}}</div>
                        <v-btn class="ml-9" @click="DESELECT_CARD" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <v-layout column class="py-2" justify-center align-center v-if="SELECTED_CARD.customer"> 
                            <v-card class=" px-5 " outlined>
                                <div class="text-center font-weight-bold grey--text text--darken-2 not-f5 pt-2 pb-1">{{SELECTED_CARD.customer_name}}</div>
                                <v-divider></v-divider>
                                <v-alert v-if="!SELECTED_CARD.active"
                                    text class="ma-0 mt-3 py-2" outlined
                                    color="error"
                                    icon="error" >
                                        Card not Active.
                                </v-alert>
                                <v-alert  v-else
                                    text class="ma-0 mt-3 py-2" outlined
                                    color="success"
                                    icon="mdi-credit-card-check" >
                                        Card is Active.
                                </v-alert>
                                <v-list class="pa-0">
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-avatar
                                                size="90"
                                                color="b2" > 
                                                <v-avatar
                                                    size="40" tile  > 
                                                    <v-icon>{{mdiAccountCreditCard}}</v-icon>
                                                </v-avatar> 
                                            </v-avatar>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Type:{{SELECTED_CARD.card_type_name}}</v-list-item-subtitle> 
                                            <v-list-item-subtitle>No.: {{SELECTED_CARD.code}}</v-list-item-subtitle> 
                                            <v-list-item-subtitle>Exp.: {{SELECTED_CARD.card_expire_date}}</v-list-item-subtitle> 
                                            <v-list-item-subtitle>Bal.: mwk{{MBS.actions.money(SELECTED_CARD.balance_amount)}}</v-list-item-subtitle>  
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                            
                        </v-layout> 
                        <v-layout v-if="!SELECTED_CARD.card_customer_key" justify-center>
                            <v-avatar out class="mt-5"
                                color="b2" size="100" > 
                                <v-avatar  color="white"
                                    size="98"  >
                                    <VAvatar
                                        size="50"  tile> 
                                        <v-icon>{{mdiAccountCreditCard}}</v-icon>
                                    </VAvatar>
                                </v-avatar> 
                            </v-avatar>
                        </v-layout>
                        <form @submit.prevent="!SELECTED_CARD.card_customer_key?
                            CARD_ATTACH_CUSTOMER(SELECTED_CARD,input.card_customer_key):
                            CREATE_CARD_ORDER(input.card_load_amount)">
                           

                            <!-- //IF CUSTOMER -->
                            <v-layout row wrap class="mx-0 my-3 "  > 
                                 
                                
                                <v-flex xs12 v-if="true">
                                    <v-btn  :disabled="SELECTED_CARD.active"   @click="ACTIVATE_CARD(SELECTED_CARD)"
                                        class="mt-3" 
                                        :loading="inputLoading"
                                        color="secondary" height="50" width="100%">
                                        <div class="not-f5">ACTIVATE CARD</div>
                                    </v-btn>   
                                </v-flex> 
                                <v-flex xs12 v-if="true">
                                    <v-btn  :disabled="!SELECTED_CARD.active"   @click="DISABLE_CARD(SELECTED_CARD)"
                                        class="mt-3" 
                                        :loading="inputLoading"
                                        color="secondary" height="50" width="100%">
                                        <div class="not-f5">DISABLE CARD</div>
                                    </v-btn>   
                                </v-flex> 
                                <v-flex xs12 v-if="true">
                                    <v-layout row wrap class="ma-0 my-3">
                                        <v-flex  >   
                                            <v-btn   @click="VIEW_CARD_HISTORY"
                                                :loading="inputLoading"
                                                color="secondary" height="50" width="100%">
                                                <div class="not-f5">CARD HISTORY</div>
                                            </v-btn>   
                                        </v-flex> 
                                        <!-- <v-btn   @click="DESELECT_CARD"
                                            :loading="inputLoading" class="ml-2" 
                                            color="primary" height="50"  >
                                            <div class="not-f5">CANCEL</div>
                                        </v-btn>     -->
                                    </v-layout>
                                </v-flex> 
                                
                                <v-flex xs12 v-if="SELECTED_CARD_OPTION=='load_money'" class="mt-3 mb-3">
                                    <v-layout row wrap class="ma-0">
                                        <v-flex >
                                            <v-btn type="submit"   
                                                :loading="inputLoading"
                                                color="secondary" height="50" width="100%" >
                                                <div class="not-f5">CONTINUE & PAY</div>
                                            </v-btn>    
                                        </v-flex> 
                                        <v-btn   @click="DESELECT_CARD"
                                            :loading="inputLoading" class="ml-2" 
                                            color="primary" height="50"  >
                                            <div class="not-f5">CANCEL</div>
                                        </v-btn>    
                                    </v-layout>
                                </v-flex> 
                            </v-layout> 
                        </form>
                    </v-card-text> 
                    <v-divider></v-divider>
                    <v-card-actions class="b1 pa-0"  v-if="SELECTED_CARD.card_customer_key">
                        <v-btn @click="DESELECT_CARD" height="50" color="primary" text width="100%">CLOSE</v-btn>
                    </v-card-actions>
                </v-card> 
            </v-dialog> 
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    import { mdiAccountCreditCard } from '@mdi/js';
    let PAGE_NAME = DATA.ITEMS.CARDS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.CARDS.names,
                NAME_ITEM:DATA.ITEMS.CARDS.name,
                VALUE_ITEMS:DATA.ITEMS.CARDS.values,
                VALUE_ITEM:DATA.ITEMS.CARDS.value,  
                dialog_pay_bill:false,
                dialog_selected_card:false, 
                input:{},
                input_amount:null,
                input_balance:null,
                inputLoading:null,
                selected_item:null,

                   
                table_actions:[ 
                    //{type:"btn",action:"add_invoice",color:"secondary", outlined:false,  text:"Add Received Invoice/Bill"},  
                    // {type:"btn",action:"manage_invoices",color:"secondary", outlined:false,  text:"Manage Invoice/Bill "},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},
                ],
                TOTAL_ITEM_VALUES:[  
                    {name:'Loaded Amount',value:'loaded_amount',show_value:'loaded_amount_',money:true},
                    {name:'Used Amount',value:'used_amount',show_value:'used_amount_',money:true},
                    {name:'Total Balance',value:'balance_amount',show_value:'balance_amount_',money:true},
                ],
                tableTabs:[
                    {name:"Active",show_if:item=>item.active&&item.card_customer_key}, 
                    {name:"Disabled",show_if:item=>!item.active&&item.card_customer_key}, 
                    {name:"Expired",show_if:item=>false}, 
                    {name:"Blank",show_if:item=>!item.card_customer_key}, 
                    {name:"All Cards",show_if:item=>true}, 
                    // {name:"Over Paid Bills",show_if:item=>item.balance<=0},
                    // {name:"Less Paid Bills",show_if:item=>item.balance<=0},
                ],
                DATA:DATA,
                SELECTED_CARD_KEY:null, 
                SELECTED_CARD_OPTION:null, 
                mdiAccountCreditCard:mdiAccountCreditCard, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 

            /////////
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.cards,true) 
                return path
            },
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("office",1,true) 
                return thisLinks
            }, 
            CARD_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.cards,true) 
                return path
            },  
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true},  
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true}, 
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"Exp. date",value:'card_expire_date',show:true},   
                    {id:0,name:"time",value:'created_at_time',show:false},   
                    {id:0,name:"Card No.",value:'code',align:"center",show:true},          
                    {id:0,name:"Type",value:'card_type_name',align:"center",show:true},          
                    {id:0,name:"User Name",value:'user_name',show:true},  
                    {id:0,name:"Loaded (MWK)",value:'loaded_amount_',align:'right',show:true},   
                    {id:0,name:"Used (MWK)",value:'used_amount_',align:'right',show:true},   
                    {id:0,name:"Balance (MWK)",value:'balance_amount_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Card",action:"view_card",icon:"mdi-eye",show_if:item=>true},  
                        {name:"Card History",action:"card_history",icon:"mdi-clipboard-text-clock",show_if:item=>true},  
                        {name:"Activate Card",action:"activate_card",icon:"mdi-toggle-switch",show_if:item=>!item.active}, 
                        {name:"Disable Card",action:"disable_card",icon:"mdi-toggle-switch-off",show_if:item=>item.active}, 
                    ]}, 
                ]
            },  
            ////


            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Cards: state=> state.items[(DATA.ITEMS.CARDS.values).toUpperCase()],
                JoinedCards: state=> state.join[(DATA.ITEMS.CARDS.values).toUpperCase()],
                CustomersData: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()],
                JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()],
                CustomerGroupsData: state=> state.items[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()],
                JoinedCustomerGroups: state=> state.join[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()],
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
            }), 
            CardsDataItems(){
                return this.JoinedCards?this.JoinedCards:this.Cards
            },
            CustomersData(){
                return this.JoinedCustomers?this.JoinedCustomers:this.Customers
            },
            CustomerGroupsData(){
                return this.JoinedCustomerGroups?this.JoinedCustomerGroups:this.Customers
            },
            Selectors(){ 
                let Customers = this.CustomersData 
                let CustomerGroups = this.CustomerGroupsData 


                console.log(Customers,CustomerGroups,'........ss');
                return{
                    Customers: Customers, 
                    CustomerGroups: CustomerGroups, 
                }
            },
            SELECTED_CARD(){
                let cards = this.CardsDataItems
                let card_key = this.SELECTED_CARD_KEY
                if(!cards){return null}
                let thisCard = cards.find(card=>card.key==card_key)
                return thisCard
            },
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "manage_invoices") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true))
                    } if (action == "manage_invoices") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try {
                    if (action == "view_card") {  
                        this.SELECT_CARD(null,item.code)
                    }else if (action == "activate_card") {
                        this.ACTIVATE_CARD(item)
                    }else if (action == "disable_card") {
                        this.DISABLE_CARD(item)
                    }else if (action == "card_history") {
                        // let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/"+item.key,true)
                        // this.MBS.actions.go(link)
                        let mbs_text = this.MBS.text.option_not_available("Card History")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            MAKE_PAYMENT(item,input){
                try {
                    let amount = input?input.amount:null
                    let payment_method_code = input?input.payment_method_code:null
                    amount = this.toNumber(amount)
                    let transaction_key = item?item.key:null
                    let transaction_type = item?item.order_type:null
                    console.log(item);
                    let payment_details={
                        amount:amount,
                        payment_method_code:payment_method_code,
                        transaction_key:transaction_key,
                        transaction_type:transaction_type,
                    }
                    if (!transaction_key || !amount) {
                        console.log(item,amount,'payload required.......');
                    }else{
                        let mbs_text = this.MBS.text.item_action("Bill","Pay")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=PAY-BILL",
                                data:payment_details
                            }
                        }) 
                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            //CARD ACTIONS
            SELECT_CARD(card_key, card_code){
                try { 
                    let cards = this.CardsDataItems
                    if(!cards){
                        this.MBS.actions.dialog({
                            show:true,
                            title:"CARD ERROR",
                            text:"No card found. Please, make sure you are connect to the internet and have active cards.",
                            btnYes:"OKAY"
                        })
                        return
                    }
                    let thisCard = cards.find(card=>this.MBS.actions.showCard(card.code)==this.MBS.actions.showCard(card_code))
                    if (!thisCard) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:"ERROR",
                            text:"Card not found. Please, make sure you are connect to the internet and try again.",
                            btnYes:"OKAY"
                        })
                        return
                    } else { 
                        if (thisCard.customer) {
                            this.input.customer_key = thisCard.customer.key
                        }
                        this.SELECTED_CARD_KEY = thisCard.key
                        this.dialog_selected_card = true 
                        
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_CARD',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            DESELECT_CARD(){
                try { 
                    this.SELECTED_CARD_KEY=null
                    this.SELECTED_CARD_OPTION=null
                    this.dialog_selected_card=false 
                    console.log('deselected.....');

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DESELECT_CARD',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            VIEW_CARD_HISTORY(){
                try { 
                    this.MBS.actions.dialog({
                        show:true,
                        title:"CARD HISTORY",
                        text:"Card history option, is currently not available.",
                        btnYes:"OKAY"
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_CARD_HISTORY',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            DISABLE_CARD(card){
                try { 
                    if (!card) {
                        console.log(card,card_customer_key); 
                        return
                    }
                    let mbs_text = this.MBS.text.item_action("Card","Disable")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=DISABLE-CARD",
                            data:{
                                key:card.key, 
                                active:false
                            } 
                        } 
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DISABLE_CARD',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ACTIVATE_CARD(card){
                try { 
                    if (!card) {
                        console.log(card,card_customer_key); 
                        return
                    }
                    let mbs_text = this.MBS.text.item_action("Card","Activate")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=DISABLE-CARD",
                            data:{
                                key:card.key, 
                                active:true
                            } 
                        } 
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ACTIVATE_CARD',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=PAY-BILL') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.$store.dispatch("fi_add",{
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_pay_bill = false
                                         this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success("Bill","Paying")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Bill","Paying")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=DISABLE-CARD') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    let link = DATA.PATHS.cards+action.data.key+"/"
                                    let f_detail = {
                                        action:DATA.ITEMS.ITEMS.values,
                                        path:this.MBS.actions.COMPANY_LINK(link,true),
                                        data:action.data,
                                        us:this.us
                                    } 
                                    this.input.loading = true 
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog(null,'Updating...')
                                    this.$store.dispatch("fi_update",f_detail)
                                    .then(res=>{ 
                                        let mbs_text = this.MBS.text.update_item_success(DATA.ITEMS.CARDS.name)
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,  
                                        }) 
                                        this.input.loading = false 
                                        this.inputLoading = false 
                                        this.MBS.actions.progressDialog() 
                                        this.MBS.actions.restartResponses() 
                                    })
                                    .catch(error=>{ 
                                        let mbs_text = this.MBS.text.update_item_fail(DATA.ITEMS.CARDS.name)
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes, 
                                        })  
                                        this.input.loading = false
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        this.MBS.actions.restartResponses()

                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);     
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            ON_QR_SCAN(code){
                try {
                    if (code) { 
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{
                            this.MBS.actions.dialog()
                            // let first = code.slice(0,1)
                            // let last = code.slice(-1)
                            // let mid = code.slice(1,-1)
                            let band1 = code.indexOf("%")!=-1?true:false
                            let band2 = code.indexOf(";")!=-1?true:false
                            let band3 = code.indexOf("+")!=-1?true:false
                            let close = code.slice(-1) 

                            if ((band1||band2||band3) && close=="?") {
                                let first_string = code.split("?")[0]  
                                code = first_string.slice(1) 
                                this.DESELECT_CARD()
                                this.SELECT_CARD(null,code)  
                            } else {  
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"ERROR",
                                    text:"Please, Scan again.",
                                    btnYes:"OK"
                                })
                            }  
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
            this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
