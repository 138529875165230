<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :filters="FILTERS"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="DayShiftsCashups" 
                            :select_items="DataSource"    
                            :is_joined="true"
                            :path_item="SALES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"  
                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div> 
        </div>
    </div> 
</template>

<script>
    import DATA from "../../../../../plugins/DATA"
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.CASHUPS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.CASHUPS.names,
                NAME_ITEM:DATA.ITEMS.CASHUPS.name,
                VALUE_ITEMS:DATA.ITEMS.CASHUPS.values,
                VALUE_ITEM:DATA.ITEMS.CASHUPS.value,  
    
                table_actions:[  
                     {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                     {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  

                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'TOTAL SALES',value:'total_sales',show_value:'total_sales_',money:true},
                    {name:'TOTAL CLOSING',value:'closing_cash',show_value:"closing_cash_",money:true},
                ], 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },  
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("sales",3,true) 
                return thisLinks
            }, 
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Cashups: state=> state.items[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                JoinedCashups: state=> state.join[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],  
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
            }), 
            TillsData(){
                return this.JoinedTills?this.JoinedTills:this.Tills
            }, 
            OutletsData(){ 
                let Outlets = this.Outlets
                let JoinedOutlets = this.JoinedOutlets
                let items = JoinedOutlets?JoinedOutlets:Outlets 
                return items
            }, 
            Users(){
                let items  =this.$store.getters.getCompanyUsers
                let list = items?[...items]:null
                if(!list){return null}
                list.sort(this.MBS.actions.dynamicSort('created_at',false))
                return list
            }, 
            JoinedUsers(){
                let users = this.Users
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            DataSource(){ 
                let Tills = this.TillsData 
                let Tellers = this.JoinedUsers 
                let Outlets = this.OutletsData
                let DayShifts = this.DayShiftsData
                let Cashups = this.DayShiftsCashups  
                return { 
                    Tills:Tills,  
                    Tellers:Tellers,  
                    Outlets:Outlets,
                    DayShifts:DayShifts,
                    Cashups:Cashups, 
                }
            },

            
            FILTERS(){ 
                return [  
                    {
                        type:"autocomplete",value:"uid",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Teller Name",
                        select:{
                            name:'Tellers',
                            value:'uid',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, {
                        type:"autocomplete",value:"till_key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Till Name",
                        select:{
                            name:'Tills',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },    
                    {
                        type:"autocomplete",value:"day_shift_key",barcode:true,prepend_inner_icon:'mdi-network-pos',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Day Shift",
                        select:{
                            name:'DayShifts',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-network-pos',
                                tile:true
                            },
                            item_title:['outlet_name'],
                            item_subtitle:['teller_name','operating_date_']
                        },
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"outlet_code",barcode:true,prepend_inner_icon:'mdi-sitemap',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Outlet",
                        select:{
                            name:'Outlets',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },    
                ]
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",tile:true,size:40,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Open",value:'local_created_at_',show:true},   
                    // {id:0,name:"Open",value:'open_time_',show:false},   
                    {id:0,name:"close",value:'close_date_time_',show:true},   
                    {id:0,name:"shift",value:'operating_date_',show:true},   
                    {id:0,name:"code",value:"key",align:"center",show:false},         
                    {id:0,name:"Outlet",value:"outlet_name",show:false},      
                    {id:0,name:"Teller",value:"teller_name",show:true},      
                    {id:0,name:"Till",value:"till_name",show:true},      
                    {id:0,name:"Open Balance",value:'opening_cash_',align:'right',show:false},    
                    {id:0,name:"Close Balance",value:'closing_cash_',align:'right',show:true},    
                    {id:0,name:"Total Sales",value:'total_sales_',align:'right',show:true},    
                    {id:0,name:"Expected",value:'expected_cash_',align:'right',show:false},    
                    {id:0,name:"cash payments",value:'total_cash_payments_',align:'right',show:false},    
                    {id:0,name:"other Payments",value:'total_other_option_payments_',align:'right',show:false},    
                    {id:0,name:"On Account",value:'sales_on_account_',align:'right',show:false},    
                    {id:0,name:"short/overages",value:'shortage_',align:'right',show:false},    
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Cashup",action:"view_order",icon:"mdi-eye"}, 
                        // {name:"Archive Order",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            }, 
            CashupData(){ 
                let Cashups = this.Cashups
                let JoinedCashups = this.JoinedCashups
                let items = JoinedCashups?JoinedCashups:Cashups  
                console.log(Cashups,'Cashups......');
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list 
            },
            DayShiftsData(){ 
                let DayShifts = this.DayShifts
                let JoinedDayShifts = this.JoinedDayShifts
                let items = JoinedDayShifts?JoinedDayShifts:DayShifts  
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list 
            },
            DayShiftsCashups(){
                let days = this.DayShiftsData
                if(!days){return null}
                let cashups = []
                days.forEach(element => {
                    if (element.joined_cashups) {
                        cashups = [...cashups,...element.joined_cashups]
                    }
                }); 
                if(!cashups){return null} 
                let list = [...cashups]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                console.log(list,'***---------');
                return list 
            }
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.CASHUPS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-DAY-SHIFTS", 
                            } 
                        })
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let cashup_key = item?item.key:null
                    let day_shift_key = item?item.day_shift_key:null
                    console.log(item,action,'-----');
                    if (action == "view_order") {
                        if (!cashup_key||!day_shift_key) { 
                            return
                        }
                        let link = "/cashups/"+day_shift_key+"/"+cashup_key
                        console.log(link,'l----------');
                        const path = this.MBS.actions.COMPANY_LINK(link,true) 
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            RELOAD_DAY_SHIFTS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.CASHUPS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.CASHUPS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=RELOAD-DAY-SHIFTS') {
                            if (action.YES) {
                                this.RELOAD_DAY_SHIFTS()
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
