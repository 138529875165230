<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 500 500" 
  :fill="iconColor" xml:space="preserve">
 
<ellipse cx="250.8" cy="469.6" :fill="iconColor" opacity=".2" rx="93.4" ry="18.6"/>
<path d="M256 11c-81 0-147 67-147 149 0 81 146 222 147 223 2-1 148-142 148-223 0-82-66-149-148-149zm3 315c-2 2-4 2-6 0-17-17-110-109-110-165 0-61 51-111 113-111s113 50 113 111c0 56-92 148-110 165z" class="st1"/>
<path d="M226 114l30 85 30-85h31v117h-24v-32l3-55-32 87h-17l-31-87 2 55v32h-24V114h32zM412 336c-13-7-31-13-53-17a17 17 0 10-7 33c34 7 48 15 52 19-3 3-13 11-42 18a467 467 0 01-209 0c-30-7-40-15-43-18 4-4 18-12 53-19a17 17 0 00-7-33c-22 4-40 10-53 17-18 9-27 21-27 35 0 21 20 38 60 49 33 8 76 13 121 13s89-5 121-13c41-11 61-28 61-49 0-14-9-26-27-35z" class="st1"/>
</svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
