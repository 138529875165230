<template> 
    <svg 
        id="Layer_1" 
        data-name="Layer 1" 
        viewBox="0 0 503 463"
        :fill="iconColor">
         
        <path  
            d="M321 114c10 1 21-2 30-7 12-6 24-13 37-10a19 19 0 0114 11c5 11-3 21-11 29l-35 37-1 1-1 1-34 35a5 5 0 005 8l46-8 4-1h1c18-3 39-3 47 14 3 7 3 15 0 22-4 8-10 14-16 19a109 109 0 01-13 9L118 426C63 456 6 488-43 527c-3 3 1 8 4 5 106-75 225-129 343-183 39-18 80-40 116-60 18-10 34-24 38-45a59 59 0 00-63-69 5 5 0 01-4-8l25-24c7-8 14-15 18-24s5-21-2-29c-5-7-13-9-21-10s-16 2-23 4l-30 8a3 3 0 01-3-6l13-15c-33 11-38 24-47 43" class="cls-1" transform="translate(44 -70)"/>
        <path  
            d="M107 105L52 440l28-15a19 19 0 0010-14l56-341-24 13a30 30 0 00-15 22zM282 96l-39 241 26-15a25 25 0 0012-17l35-216a6 6 0 00-9-7z" class="cls-1" transform="translate(44 -70)"/>
        <path  
            opacity=".4"
            d="M179 233a5 5 0 008 1l49-51 12-48-51 53a3 3 0 01-5-2l-25-77-11 48" transform="translate(44 -70)"/>
    </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
