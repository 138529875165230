import Vue from 'vue'
import Vuex from 'vuex'
import  { 
  toDateTime, 
  toDate,
  toTime,
  TEXT_UP,
  TEXT_UP1,
  TEXT_UP11,
  TEXT_UP0,
  TEXT_EQ,
  TEXT_SPLIT_N,
  TEXT_SPACE0, 
} from './../../plugins/MBS_actions' 
// // import * as firebase from 'firebase'
// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';
// ///import { FirebaseAuth } from '../../../node_modules/@firebase/auth-types';

Vue.use(Vuex)

export default{
    state: { 
      loading:false, 
      responses:{
        name:"",
        res:null,
        val: null
      },
      processes:[], 
      advance_processes:{}
        
    },
    //___________________-GETTERS-_________________
    getters: { 
      getLoading(state) {
        return state.loading
      }, 
      getProcesses(state) {
        return state.processes
      },  
      getResponses(state) {
        return state.responses
      }, 
       
    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      updateLoading(state, payload) {
        state.loading = payload
      }, 
      updateResponses(state, payload) {
        state.responses = payload
      },
      updateProcesses(state, payload) {
        let processes = [...state.processes]
        let add = payload.add
        let remove = payload.remove
        let name = payload.name
        let val = payload.val 
        //filter
        let newProccesses = processes.filter(process=>{
          return process.name != name;
        }); 
        state.processes = newProccesses
         
        if (add) {
          state.processes.push({
            name:name,
            val:val
          })  
        }
        
        if(remove){
          state.processes = []
        }   
      },  
      advanceProcess(state, payload) { 
        let name = payload.name
        let action = payload.action
        let start = payload.start
        let end = payload.end
        let loading = payload.loading
        let response = payload.response  
        if(name && action){
          let thisProcess = state.processes[TEXT_UP(action+"__"+name)]
          if (thisProcess) {
            start = start!=null?start:thisProcess.start
            end = end!=null?end:thisProcess.end
            loading = loading!=null?loading:thisProcess.loading
            response = response!=null?response:thisProcess.response
          } 
          state.processes[TEXT_UP(action+"__"+name)] = {
            name:TEXT_UP0(name),
            start:start,
            end:end,
            loading:loading,
            response:response,
          }
        }   
      },   
    },
    //____________________-ACTIONS-_____________________
    actions: { 
      //-------------[SETTER]---------------
      setLoading({ commit,state}, payload) { 
        commit('updateLoading', payload)
      }, 
      setProcesses({ commit, state }, payload) { 
        commit('updateProcesses', payload)
      }, 
      setResponses({ commit, state }, payload) { 
        commit('updateError', {
          res: payload.res,
          val: payload.val
        }) 
      }, 
      restartResponses({state}){
        state.responses = {
          res: null,
          val: null
        }
      } 
    }
}