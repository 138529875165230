<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 26.32 21.59"
  :fill="iconColor" xml:space="preserve">

    <path d="M25.48,8.17a2.3,2.3,0,0,0-.64,0,1.73,1.73,0,0,1-1.41-.54l-.5-.49c-.39-.38-.77-.77-1.19-1.12a.59.59,0,0,1-.24-.51q0-2,0-4A1.39,1.39,0,0,0,20,0H3.45A1.39,1.39,0,0,0,1.94,1.5c0,.92,0,1.84,0,2.75,0,.26,0,.52,0,.77a.49.49,0,0,0,.47.46.5.5,0,0,0,.52-.4,1.62,1.62,0,0,0,0-.31V1.56C3,1.14,3.08,1,3.5,1H9V5.46A.54.54,0,0,0,9.3,6,.55.55,0,0,0,9.9,6c.54-.33,1.08-.66,1.61-1a.33.33,0,0,1,.41,0c.52.34,1.05.65,1.57,1a.54.54,0,0,0,.92-.5V1H20c.4,0,.51.11.51.52,0,1.18,0,2.36,0,3.55v.2H17a1.22,1.22,0,0,0-1.25,1.63,5.93,5.93,0,0,0,.22.63H12.17a1.49,1.49,0,0,0-1.5,1.22,3.86,3.86,0,0,0,0,.76c-1.06.19-1.59.69-1.6,1.52s.5,1.35,1.62,1.57c-.1,1.21.19,1.65,1.34,2,0,.21,0,.43,0,.64a1.51,1.51,0,0,0,1.6,1.35h6.87c0,.43,0,.89,0,1.35s-.1.57-.57.57H8.21A4.21,4.21,0,0,0,3,13.41V9.92a2.32,2.32,0,0,0,0-.46.48.48,0,0,0-.51-.38A.49.49,0,0,0,2,9.51a1.61,1.61,0,0,0,0,.31c0,1.28,0,2.55,0,3.83a.4.4,0,0,1-.19.38A4.13,4.13,0,0,0,.13,16.39c0,.19-.09.38-.13.58v.92A.78.78,0,0,1,.05,18,4.17,4.17,0,0,0,7.6,19.8.47.47,0,0,1,8,19.57H20a1.41,1.41,0,0,0,1.52-1.5,11.52,11.52,0,0,0,0-1.44.94.94,0,0,1,.47-1,2.6,2.6,0,0,1,1.65-.54c.53,0,1.06,0,1.59,0a1.06,1.06,0,0,0,1.11-1.05c0-1.64,0-3.29,0-4.93A1,1,0,0,0,25.48,8.17ZM13.37,4.63c-.42-.26-.82-.49-1.2-.75a.69.69,0,0,0-.89,0c-.38.26-.78.49-1.21.76V1h3.3ZM4.18,20.57a3.15,3.15,0,1,1,3.14-3.14A3.16,3.16,0,0,1,4.18,20.57Zm21.1-6.45H23.41a2.89,2.89,0,0,0-1.78.55l-.13.09c-1.26,1-1.12.82-2.56.83H13.62c-.38,0-.6-.18-.6-.48s.23-.5.61-.5h1.6a.5.5,0,0,0,.5-.49.52.52,0,0,0-.47-.52H12.08a.46.46,0,0,1-.41-.43.45.45,0,0,1,.34-.51,1.19,1.19,0,0,1,.33,0h2.82a.51.51,0,0,0,.57-.5.54.54,0,0,0-.56-.52H10.68c-.37,0-.6-.2-.59-.5s.23-.48.6-.48h4.52a.52.52,0,0,0,0-1h-3c-.39,0-.61-.18-.6-.5s.22-.48.6-.48h7.27c.48,0,.71-.16.72-.51s-.23-.52-.73-.52-1.11,0-1.67,0a1.11,1.11,0,0,1-1.08-.9c-.05-.24,0-.33.26-.33,1,0,2,0,3,0A2,2,0,0,1,21.35,7c.41.38.79.79,1.18,1.18a2.94,2.94,0,0,0,1.66,1,6.51,6.51,0,0,0,.77,0h.32Z"/>
    
    <path d="M2.44,7.79A.52.52,0,0,0,3,7.26a.51.51,0,0,0-.52-.49.5.5,0,0,0-.5.51A.5.5,0,0,0,2.44,7.79Z"/>
    
    <path class="cls-1" d="M24.19,9.12a2.94,2.94,0,0,1-1.66-1c-.39-.39-.77-.8-1.18-1.18A2,2,0,0,0,20,6.34c-1,0-2,0-3,0-.25,0-.31.09-.26.33a1.11,1.11,0,0,0,1.08.9c.56,0,1.11,0,1.67,0s.73.17.73.52-.24.51-.72.51H12.27c-.38,0-.6.17-.6.48s.21.5.6.5h3a.52.52,0,0,1,0,1H10.69c-.37,0-.6.19-.6.48s.22.5.59.5h4.49a.54.54,0,0,1,.56.52.51.51,0,0,1-.57.5H12.34a1.19,1.19,0,0,0-.33,0,.45.45,0,0,0-.34.51.46.46,0,0,0,.41.43h3.18a.52.52,0,0,1,.47.52.5.5,0,0,1-.5.49h-1.6c-.38,0-.62.19-.61.5s.22.47.6.48h5.32c1.44,0,1.3.14,2.56-.83l.13-.09a2.89,2.89,0,0,1,1.78-.55h1.87v-5H25A6.51,6.51,0,0,1,24.19,9.12Z"/>
    
    <path class="cls-1" d="M4.17,14.27a3.15,3.15,0,1,0,3.15,3.16A3.15,3.15,0,0,0,4.17,14.27ZM5.8,16.79c-.44.54-.89,1.06-1.33,1.59l-.29.34a.5.5,0,0,1-.71.05c-.29-.23-.58-.48-.86-.73a.49.49,0,0,1-.07-.71.52.52,0,0,1,.74-.06l.46.38,1.07-1.27L5,16.12a.51.51,0,1,1,.78.67Z"/>
    <path class="cls-1" d="M10.07,4.64c.43-.27.83-.5,1.21-.76a.69.69,0,0,1,.89,0c.38.26.78.49,1.2.75V1h-3.3Z"/>
    <path d="M5,16.12l-.21.26L3.74,17.65l-.46-.38a.52.52,0,0,0-.74.06.49.49,0,0,0,.07.71c.28.25.57.5.86.73a.5.5,0,0,0,.71-.05l.29-.34c.44-.53.89-1.05,1.33-1.59A.51.51,0,1,0,5,16.12Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
