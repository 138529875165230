 
<template>
<v-card  flat color="transparent"> 
        <div class="card-form">
            <div class="card-list">
                <div class="card-item" :class="{ '-active' : isCardFlipped }">
                <div class="card-item__side -front">
                    <div class="card-item__focus" :class="{'-active' : focusElementStyle }" :style="focusElementStyle" ref="focusElement"></div>
                    <div class="card-item__cover">
                    <img
                        :src="'system/creditCard/images/' + currentCardBackground + '.jpeg'" class="card-item__bg">
                    </div>
                    
                    <div class="card-item__wrapper">
                    <div class="card-item__top">
                        <img src="system/creditCard/images/chip.png" class="card-item__chip">
                        <div class="card-item__type">
                        <transition name="slide-fade-up">
                            <img :src="'system/creditCard/images/' + getCardType + '.png'" v-if="getCardType" :key="getCardType" alt="" class="card-item__typeImg">
                        </transition>
                        </div>
                    </div>
                    <label for="cardNumber" class="card-item__number" ref="cardNumber">
                        <template v-if="getCardType === 'amex'">
                        <span v-for="(n, $index) in amexCardMask" :key="$index">
                        <transition name="slide-fade-up">
                            <div
                            class="card-item__numberItem"
                            v-if="$index > 4 && $index < 14 && cardNumber.length > $index && n.trim() !== ''"
                            >*</div>
                            <div class="card-item__numberItem"
                            :class="{ '-active' : n.trim() === '' }"
                            :key="$index" v-else-if="cardNumber.length > $index">
                            {{cardNumber[$index]}}
                            </div>
                            <div
                            class="card-item__numberItem"
                            :class="{ '-active' : n.trim() === '' }"
                            v-else
                            :key="$index + 1"
                            >{{n}}</div>
                        </transition>
                        </span>
                        </template>

                        <template v-else>
                        <span v-for="(n, $index) in otherCardMask" :key="$index">
                            <transition name="slide-fade-up">
                            <div
                                class="card-item__numberItem"
                                v-if="$index > 4 && $index < 15 && cardNumber.length > $index && n.trim() !== ''"
                            >*</div>
                            <div class="card-item__numberItem"
                                :class="{ '-active' : n.trim() === '' }"
                                :key="$index" v-else-if="cardNumber.length > $index">
                                {{cardNumber[$index]}}
                            </div>
                            <div
                                class="card-item__numberItem"
                                :class="{ '-active' : n.trim() === '' }"
                                v-else
                                :key="$index + 1"
                            >{{n}}</div>
                            </transition>
                        </span>
                        </template>
                    </label>
                    <div class="card-item__content">
                        <label for="cardName" class="card-item__info" ref="cardName">
                        <div class="card-item__holder">Card Holder</div>
                        <transition name="slide-fade-up">
                            <div class="card-item__name" v-if="cardName.length" key="1">
                            <transition-group name="slide-fade-right">
                                <span class="card-item__nameItem" v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')" v-if="$index === $index" :key="$index + 1">{{n}}</span>
                            </transition-group>
                            </div>
                            <div class="card-item__name" v-else key="2">Full Name</div>
                        </transition>
                        </label>
                        <div class="card-item__date" ref="cardDate">
                        <label for="cardMonth" class="card-item__dateTitle">Expires</label>
                        <label for="cardMonth" class="card-item__dateItem">
                            <transition name="slide-fade-up">
                            <span v-if="cardMonth" :key="cardMonth">{{cardMonth}}</span>
                            <span v-else key="2">MM</span>
                            </transition>
                        </label>
                        /
                        <label for="cardYear" class="card-item__dateItem">
                            <transition name="slide-fade-up">
                            <span v-if="cardYear" :key="cardYear">{{String(cardYear).slice(2,4)}}</span>
                            <span v-else key="2">YY</span>
                            </transition>
                        </label>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="card-item__side -back">
                    <div class="card-item__cover">
                    <img
                    :src="'system/creditCard/images/' + currentCardBackground + '.jpeg'" class="card-item__bg">
                    </div>
                    <div class="card-item__band"></div>
                    <div class="card-item__cvv">
                        <div class="card-item__cvvTitle">CVV</div>
                        <div class="card-item__cvvBand">
                        <span v-for="(n, $index) in cardCvv" :key="$index">
                            *
                        </span>

                    </div>
                        <div class="card-item__type">
                            <img :src="'system/creditCard/images/' + getCardType + '.png'" v-if="getCardType" class="card-item__typeImg">
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
            <div class="card-form__inner">
                <div class="card-input">
                <label for="cardNumber" class="card-input__label">Card Number</label>
                <input type="text" id="cardNumber" class="card-input__input"  v-mask="generateCardNumberMask"  v-model="cardNumber" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardNumber" autocomplete="off">
                </div>
                <div class="card-input">
                <label for="cardName" class="card-input__label">Card Holders</label>
                <input type="text" id="cardName" class="card-input__input" v-model="cardName" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardName" autocomplete="off">
                </div>
                <div class="card-form__row">
                <div class="card-form__col">
                    <div class="card-form__group">
                    <label for="cardMonth" class="card-input__label">Expiration Date</label>
                    <select class="card-input__input -select" id="cardMonth" v-model="cardMonth" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                        <option value="" disabled selected>Month</option>
                        <option :value="n < 10 ? '0' + n : n" v-for="n in 12" :disabled="n < minCardMonth" :key="n">
                            {{n < 10 ? '0' + n : n}}
                        </option>
                    </select>
                    <select class="card-input__input -select" id="cardYear" v-model="cardYear" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                        <option value="" disabled selected>Year</option>
                        <option :value="$index + minCardYear" v-for="(n, $index) in 12" :key="n">
                            {{$index + minCardYear}}
                        </option>
                    </select>
                    </div>
                </div>
                <div class="card-form__col -cvv">
                    <div class="card-input">
                    <label for="cardCvv" class="card-input__label">CVV</label>
                    <input type="text" class="card-input__input" id="cardCvv"   maxlength="4" v-model="cardCvv" v-on:focus="flipCard(true)" v-on:blur="flipCard(false)" autocomplete="off" v-mask="'####'"  >
                    </div>
                </div>
                </div>

                <!-- <button class="card-form__button primary">
                Submit
                </button> -->
                <!-- <v-layout justify-center>
                    <v-btn tile width="100%" color="primary">Submit</v-btn> 
                </v-layout> -->

            </div>
        </div> 
    <!-- <div class="wrapper" id="app">
    </div> -->
</v-card>
</template> 
<script>  
    export default {
        props:['value','color','backgroundColor',],
        data(){
            return{
                // currentCardBackground: Math.floor(Math.random()* 25 + 1),  
                currentCardBackground:14,  
                cardName: "",
                cardNumber: "",
                cardMonth: "",
                cardYear: "",
                cardCvv: "",
                minCardYear: new Date().getFullYear(),
                amexCardMask: "#### ###### #####",
                otherCardMask: "#### #### #### ####",
                cardNumberTemp: "",
                isCardFlipped: false,
                focusElementStyle: null,
                isInputFocused: false,
                model:this.value,
                cardData:{}
            }
        },
        created(){ 
            this.initiateModel() 
        },
        mounted(){  
            this.cardNumberTemp = this.otherCardMask;
            document.getElementById("cardNumber").focus(); 
        },
        computed:{
            getCardType () {
                let number = this.cardNumber; 
                let re = new RegExp("^4");
                if (number.match(re) != null) return "visa";

                re = new RegExp("^(34|37)");
                if (number.match(re) != null) return "amex";

                re = new RegExp("^5[1-5]");
                if (number.match(re) != null) return "mastercard";

                re = new RegExp("^6011");
                if (number.match(re) != null) return "discover";
                
                re = new RegExp('^9792')
                if (number.match(re) != null) return 'troy'

                return "visa"; // default type
            },
            generateCardNumberMask () {
                return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
            },
            minCardMonth () {
                if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
                return 1;
            },
             
        },
        methods:{ 
            flipCard (status) {
                this.isCardFlipped = status;
            },
            focusInput (e) {
                this.isInputFocused = true;
                let targetRef = e.target.dataset.ref;
                let target = this.$refs[targetRef];
                this.focusElementStyle = {
                    width: `${target.offsetWidth}px`,
                    height: `${target.offsetHeight}px`,
                    transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
                }
            },
            blurInput() {
                let vm = this;
                setTimeout(() => {
                    if (!vm.isInputFocused) {
                    vm.focusElementStyle = null;
                    }
                }, 300);
                vm.isInputFocused = false;
            },
            initiateModel(){
                let value = this.model
                let pan = value?value.pan:""
                let cardholderName = value?value.cardholderName:""
                let cvv = value?value.cvv:""
                let year = value?value.year:""
                let month = value?value.month:""
                this.cardNumber = pan?pan:""
                this.cardName = cardholderName?cardholderName:""
                this.cardCvv = cvv?cvv:""
                this.cardYear = year?year:""
                this.cardMonth = month?month:""
            },
        },
        watch:{ 
            model(value){
                this.initiateModel()
            },
            cardName(value){
                this.cardData = {
                    ...this.cardData,
                    cardholderName:value
                } 
            },
            cardNumber(value){
                this.cardData = {
                    ...this.cardData,
                    pan:value
                } 
            }, 
            cardCvv(value){
                this.cardData = {
                    ...this.cardData,
                    cvv:value
                } 
            },
            cardYear (value) {
                if (this.cardMonth < this.minCardMonth) {
                    this.cardMonth = "";
                }
                this.cardData = {
                    ...this.cardData,
                    year:value
                } 
            },
            cardMonth(value){
                this.cardData = {
                    ...this.cardData,
                    month:value
                } 
            },
            cardData(value){
                this.$emit('input', value)
            }
        } 

    }
</script>
<style lang="scss" scoped>
   @import "../../plugins/creditCard.scss";  
</style> 