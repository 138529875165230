<template>  
    <v-card 
        :flat="SUBTOOLBAR_VISIBLE"
        v-if="alertToolbar.show " 
        class="mb-"
        :class="SUBTOOLBAR_VISIBLE?'':'fixed-top'" 
        width="100%" tile :color="alertToolbar.min?alertColor():'b1'"
        :style="SUBTOOLBAR_VISIBLE?'':'top:'+(tb.height+(progressToolbar.show?progressToolbar.height:0))+'px'">
        <v-container grid-list-xs :class="alertToolbar.min?'py-0':''" >
            <v-alert v-if="!alertToolbar.min"
                v-model="alert"
                tile 
                :type="alertToolbar.type"  
                :icon="alertIcon()?alertIcon():null"
                border="left"
                elevation="2" 
                colored-border >
                <v-layout row wrap>
                    {{alertToolbar.text}}   
                    <v-spacer></v-spacer>
                    <v-btn text tile @click="minimizeErrorToolbar()" >{{alertToolbar.btnNo}}</v-btn>
                     
                    <v-menu 
                        v-model="menuAlert" 
                        :close-on-content-click="false" 
                        bottom="" 
                        offset-y
                        :nudge-width="300">
                        <template v-slot:activator="{ on }">  
                             <v-btn v-on="on"   :color="alertColor()" dark tile>{{alertToolbar.btnYes}}</v-btn> 
                            
                        </template> 

                        <v-card  
                            color="lighten-1" class="">   
                            <v-card-title class="headline  " :class="alertColor()">
                                <v-list-item dark>
                                    <v-list-item-action>
                                        <v-avatar
                                            size="38"
                                            :color="alertColor()+' darken-1' ">
                                            <v-icon>{{alertIcon()}}</v-icon>
                                        </v-avatar> 
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{alertToolbar.text}}    
                                        </v-list-item-title>
                                        <!-- <v-list-item-subtitle  >
                                            -- {{us?us.displayName:'..'}} --
                                        </v-list-item-subtitle> -->
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn @click="menuAlert=!menuAlert" dark="" icon=""><v-icon>close</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>  
                                
                            </v-card-title> 
                             
                            <v-virtual-scroll 
                                v-if="menuAlert"
                                :bench="2"
                                :items="alertToolbarItems"
                                height="300"
                                item-height="60"  > 
                                <template v-slot="{ item,index }">
                                        <v-list-item  
                                            :key="index" :to="item.link" @click="minimizeErrorToolbar()">
                                            <v-list-item-action>
                                                <v-btn 
                                                    :color="alertColor(item.type)" 
                                                    outlined icon>
                                                        <v-icon size="24px">{{item.icon?item.icon:alertIcon(item.type)}}</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item> 
                                        <v-divider inset></v-divider> 
                                </template>  
                            </v-virtual-scroll> 
                            
                            <v-divider></v-divider>
                                
                            <v-card-actions class="b1">
                                <v-layout class="pa-2  ">
                                    <v-spacer></v-spacer>
                                    <div class="mr-2">
                                        
                                    </div>
                                    <v-btn 
                                        :small = "true"
                                         tile  
                                        :color="alertColor()"
                                        @click="minimizeErrorToolbar()" >
                                        <div class="px-2">Later</div> 
                                    </v-btn> 
                                </v-layout> 
                            </v-card-actions>
                        </v-card>  
                    </v-menu>
                    
                </v-layout>
            </v-alert>
            <v-layout v-else align-center  > 
                <v-btn @click="minimizeErrorToolbar()" icon dark class="mr-1">
                    <v-icon dark >{{alertIcon()}}</v-icon>
                </v-btn> 
                <div class="white--text">{{alertToolbar.text}}</div>
                <v-spacer></v-spacer>
                <v-btn @click="minimizeErrorToolbar()" icon  dark>
                    <v-icon dark >expand_more</v-icon>
                </v-btn> 
            </v-layout> 
            <div v-show="false">{{ud}}</div>
            
        </v-container>
    </v-card>
</template>

<script>

import DATA from "../../plugins/DATA"
 
export default {
    props:["flat"],
    data(){
        return{
            alert: true,
            show:false,  
            menuAlert:false,
            SUBTOOLBAR_VISIBLE:false,
            DATA:DATA,
            actionAlert:null
        }
    },
    created() {
        try { 
            this.MBS.events.$on('SUBTOOLBAR_VISIBILITY', this.SUBTOOLBAR_VISIBILITY);  
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){    
        this.calculateAlerts(this.ud)
        this.ALERT_UI(this.alertToolbarItems)
    },
    computed:{ 
        route(){
            return this.$route
        },
        vs(){
            return this.$vuetify.breakpoint
        },  
        us(){
            return this.$store.getters.getUser
        }, 
        ud(){
            let ud = this.$store.getters.getUserDetails
            // this.calculateAlerts(ud)
            return ud
        },  
        tb(){ 
            return this.$store.getters.getMBS_toolbar
        },
        progressToolbar(){ 
            return this.$store.getters.getMBS_progress_toolbar
        },
        alertToolbar(){ 
            return this.$store.getters.getMBS_alert_toolbar
        }, 
        alertToolbarItems(){ 
            let alert = this.$store.getters.getAlertItems 
            return alert
        }, 
        alertColor(){
            let alert = this.alertToolbar
            let type = alert?alert.type:null
            let aColor = alert?alert.color:null
            return alertType=>{
                if (alertType) {
                    type = alertType
                }
                let color = ''
                if (type=="info") {
                    color = "info"
                }else  if(type=="warning") {
                    color = "warning"
                }else  if(type=="error") {
                    color = "error"
                }
                return aColor?aColor:color 
            }
        },
        alertIcon(){
            let alert = this.alertToolbar
            let type = alert?alert.type:null
            let aIcon = alert?alert.icon:null
            return alertType=>{
                if (alertType) {
                    type = alertType
                }
                let icon = 'mdi-alert-circle-outline'
                if (type=="info") {
                    icon = "mdi-alert-circle-outline"
                }else  if(type=="warning") {
                    icon = "mdi-alert-outline "
                }else  if(type=="error") {
                    icon = "mdi-alert-octagon-outline "
                }
                return aIcon?aIcon:icon
            }
        },  
        online(){  
            console.log(this.LocalCashup,111);
            return this.$store.getters.getOnlineStatus
        }, 
    },
    methods:{
        minimizeErrorToolbar(){
            this.MBS.actions.alertDialog({
                ...this.alertToolbar,
                min:this.alertToolbar.min?false:true
            })
            this.menuAlert = false
        },
        findAlertItem(value,type){
            let items = this.$store.getters.findAlertItem(value,type)
            return items
        },
        filterAlertItem(value,type){
            let items = this.$store.getters.filterAlertItem(value,type)
            return items
        },
        ALERT_UI(items){
            // if (this.actionAlert) {
            //     clearTimeout(this.actionAlert)
            //     console.log('clearTimeout.......');
            // }
            this.actionAlert = setTimeout(() => {
                let route = this.route
                let fullPath = route?route.fullPath:null 
                if (fullPath=="/signup") {
                    this.MBS.actions.alertDialog() 
                    return
                }
                let allItems = items
                let errorItems = this.filterAlertItem('error','type')
                let warningItems = this.filterAlertItem('warning','type')
                let infoItems = this.filterAlertItem('info','type')
                let sizeAll = this.MBS.actions.SIZE(allItems)
                let sizeError = this.MBS.actions.SIZE(errorItems)
                let sizeWarning = this.MBS.actions.SIZE(warningItems)
                let sizeInfo = this.MBS.actions.SIZE(infoItems)
 
                if (sizeAll>0) {
                    let type = "info"
                    if (sizeWarning>0) {
                        type ="warning"
                    }
                    if (sizeError>0) {
                        type ="error"
                    }
                    // this.MBS.actions.alertDialog({ 
                    //     show:true,
                    //     min:this.alertToolbar?this.alertToolbar.min:false,
                    //     type:type, 
                    //     text:"Finish setting up your profile ("+sizeAll+(sizeAll>1?' issues':'issue')+") "
                    // })
                }else{
                    this.MBS.actions.alertDialog()
                } 
            }, 1);
            
        }, 
        calculateAlerts(ud){ 
            if (ud) { 
                let password_protected = ud?ud.password_protected:null
                let date_of_birth = ud?ud.date_of_birth:null 
                let next_of_kin_names = ud?ud.next_of_kin_names:null
                let id_type = ud?ud.id_type :null
                let location_country = ud?ud.location_country:null
                let image = ud?ud.photoURL:null
                let id_verified = ud?ud.id_verified:null
                let toAlertItems = []

                this.MBS.actions.deleteAllAlertItems()  

                if (!password_protected) { 
                    this.MBS.actions.addIfNoAlertItem(DATA.ALERTS.verify_phone_number) 
                }else{
                    this.MBS.actions.deleteAlertItem(DATA.ALERTS.verify_phone_number)  
                }
                if (!image) { 
                    this.MBS.actions.addIfNoAlertItem(DATA.ALERTS.upload_profile_picture) 
                }else{
                    this.MBS.actions.deleteAlertItem(DATA.ALERTS.upload_profile_picture)  
                }
                if (!date_of_birth) { 
                    this.MBS.actions.addIfNoAlertItem(DATA.ALERTS.enter_date_of_birth) 
                }else{
                    this.MBS.actions.deleteAlertItem(DATA.ALERTS.enter_date_of_birth)  
                }
                if (!next_of_kin_names) {  
                    this.MBS.actions.addIfNoAlertItem(DATA.ALERTS.enter_next_of_kin_info) 
                }else{
                    this.MBS.actions.deleteAlertItem(DATA.ALERTS.enter_next_of_kin_info)  
                }
                if (!id_type) {  
                    this.MBS.actions.addIfNoAlertItem(DATA.ALERTS.enter_id_info) 
                }else{
                    this.MBS.actions.deleteAlertItem(DATA.ALERTS.enter_id_info)  
                }
                if (!location_country) {  
                    this.MBS.actions.addIfNoAlertItem(DATA.ALERTS.enter_location_address) 
                }else{
                    this.MBS.actions.deleteAlertItem(DATA.ALERTS.enter_location_address)  
                }
                 
            }else{
                this.MBS.actions.deleteAllAlertItems()  
            } 
        },
        SUBTOOLBAR_VISIBILITY(value){
            this.SUBTOOLBAR_VISIBLE = value.visible 
        },
        
    },
    watch:{
        route(value){ 
            this.calculateAlerts(this.ud)
            this.ALERT_UI(this.alertToolbarItems) 
        },
        ud(value){ 
            this.calculateAlerts(value)
            this.ALERT_UI(this.alertToolbarItems) 
        },
        alertToolbarItems(value){ 
            this.ALERT_UI(value) 
        },
        alertToolbar(value){ 
        } 
    } 
}
</script>
<style> 
    .fixed-top{
        position: fixed !important; 
        z-index: 4; 
    }  
</style>

