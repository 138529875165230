import Vue from 'vue'
import goTo from 'vuetify/lib/services/goto'
import { store } from '../store' 
import VueRouter from 'vue-router'

import HomeView from '../views/activity/Home.vue'
import SalesReceipt from '../views/activity/SalesReceipt.vue'
import PaymentReceipt from '../views/activity/PaymentReceipt.vue'
import SalesQuotation from '../views/activity/SalesQuotation.vue'
import SalesInvoice from '../views/activity/SalesInvoice.vue'
import SalesInvoiceStatement from '../views/activity/SalesInvoiceStatement.vue'

import SignUp from '../views/activity/account/SignUp.vue'
import RecoverPassword from '../views/activity/account/RecoverPassword.vue'
import MyAccount from '../views/activity/account/MyAccount.vue'
import MyProfile from '../views/activity/account/MyProfile.vue'
import Companies from '../views/activity/account/Companies.vue'
import Messages from '../views/activity/account/Messages.vue'
import Sale from '../views/activity/sale/Sale.vue'
import MySalesHistory from '../views/activity/sale/MySalesHistory.vue'
import MyQuotations from '../views/activity/sale/MyQuotations.vue'
import MyCashup from '../views/activity/sale/MyCashup.vue'
import DayShifts from '../views/activity/sale/DayShifts.vue'
import SalesSettings from '../views/activity/sale/SalesSettings.vue'
import StartDay from '../views/activity/sale/StartDay.vue'
import ManageShift from '../views/activity/sale/ManageShift.vue'
import ZRead from '../views/activity/sale/ZRead.vue'
import ViewCashup from '../views/activity/sale/ViewCashup.vue'
import XRead from '../views/activity/sale/XRead.vue'
import SalesReadReport from '../views/activity/sale/SalesReadReport.vue'

import Warehouse from '../views/activity/warehouse/Warehouse.vue'
import StockTransferred from '../views/activity/warehouse/StockTransferred.vue'

import Orders from '../views/activity/orders/Orders.vue'
import ViewProcessingOrder from '../views/activity/orders/ViewProcessingOrder.vue'
import CustomerDisplay from '../views/activity/orders/CustomerDisplay.vue'
import ProcessingDisplay from '../views/activity/orders/ProcessingDisplay.vue'

import Catalog from '../views/activity/catalog/Catalog.vue'
import ItemCategories from '../views/activity/catalog/ItemCategories.vue'
import CatalogItemDepartments from '../views/activity/catalog/ItemDepartments.vue'
import PriceBook from '../views/activity/catalog/PriceBook.vue'
import Brands from '../views/activity/catalog/Brands.vue'
import Manufacturers from '../views/activity/catalog/Manufacturers.vue'
import GiftCards from '../views/activity/catalog/GiftCards.vue'
  
//stock
import Stock from '../views/activity/stock/Stock.vue'
import StockItems from '../views/activity/stock/Items.vue'
import StockSettings from '../views/activity/stock/manage/StockSettings.vue'
import ManageStock from '../views/activity/stock/manage/ManageStock.vue'
import ViewStock from '../views/activity/stock/manage/ViewStock.vue'
import StockItemKit from '../views/activity/stock/ItemKit.vue'
import ItemDepartments from '../views/activity/stock/manage/department/ItemDepartments.vue'
import ViewItemDepartment from '../views/activity/stock/manage/department/ViewItemDepartment.vue'
import StockControl from '../views/activity/stock/StockControl.vue'
import ManageStockTake from '../views/activity/stock/stock_take/ManageStockTake.vue'
import StockTaking from '../views/activity/stock/stock_take/StockTaking.vue'
import ViewStockTake from '../views/activity/stock/stock_take/ViewStockTake.vue'
import StockOrder from '../views/activity/stock/StockOrder.vue'
import StockReceive from '../views/activity/stock/StockReceive.vue'
import ReceiveStockWithInvoice from '../views/activity/stock/ReceiveStockWithInvoice.vue'
import StockReturn from '../views/activity/stock/StockReturn.vue'
import StockTransfer from '../views/activity/stock/StockTransfer.vue'
import AdjustStock from '../views/activity/stock/AdjustStock.vue'
import SalesReturn from '../views/activity/stock/SalesReturn.vue'
import OpeningStock from '../views/activity/stock/OpeningStock.vue'
import Quotations_Invoices from '../views/activity/stock/Quotations&Invoices.vue'
import RequestQuotation from '../views/activity/stock/RequestQuotation.vue'
import ReceiveQuotation from '../views/activity/stock/ReceiveQuotation.vue'
import ReceiveInvoice from '../views/activity/stock/ReceiveInvoice.vue'
import ViewOrder from '../views/activity/stock/ViewOrder.vue'
import Expiries from '../views/activity/stock/Expiries.vue'
import AddExpiries from '../views/activity/stock/AddExpiries.vue'
import Outlets from '../views/activity/stock/Outlets.vue'

//hospital
import Hospital from '../views/activity/hospital/Hospital.vue'
import Reception from '../views/activity/hospital/Reception.vue'
import Triage from '../views/activity/hospital/Triage.vue'
import Appointments from '../views/activity/hospital/Appointments.vue'
import Consultations from '../views/activity/hospital/Consultations.vue'
import Laboratory from '../views/activity/hospital/Laboratory.vue'
import Scanning from '../views/activity/hospital/Scanning.vue'
import MaternityWard from '../views/activity/hospital/MaternityWard.vue'
import DentalDepartment from '../views/activity/hospital/DentalDepartment.vue'
import Admission from '../views/activity/hospital/Admission.vue'
import Pharmacy from '../views/activity/hospital/Pharmacy.vue'
import Doctors from '../views/activity/hospital/Doctors.vue'
import Patients from '../views/activity/hospital/Patients.vue'

//office
import Office from '../views/activity/office/Office.vue'
import Reports from '../views/activity/office/reports/Reports.vue'
import ProfitAndLossReport from '../views/activity/office/reports/ProfitAndLossReport.vue'
import SalesReports from '../views/activity/office/reports/sales/SalesReports.vue'
import SalesItems from '../views/activity/office/reports/sales/SalesItems.vue'
import SalesTransactions from '../views/activity/office/reports/sales/SalesTransactions.vue'
import OfficeDayShifts from '../views/activity/office/reports/sales/DayShifts.vue'
import Cashups from '../views/activity/office/reports/sales/Cashups.vue'
import IncomeReport from '../views/activity/office/reports/IncomeReport.vue'
import PurchasesReport from '../views/activity/office/reports/PurchasesReport.vue'
import ExpensesReport from '../views/activity/office/reports/ExpensesReport.vue'
import DebtorsReport from '../views/activity/office/reports/DebtorsReport.vue'
import CreditorsReport from '../views/activity/office/reports/CreditorsReport.vue'
import VATReport from '../views/activity/office/reports/VATReport.vue'
import Accounts from '../views/activity/office/accounts/Accounts.vue'
import AccountsReceivable from '../views/activity/office/accounts/AccountsReceivable.vue'
import ReceivableAccounts from '../views/activity/office/accounts/receivable/ReceivableAccounts.vue'
import ViewReceivableAccounts from '../views/activity/office/accounts/receivable/ViewReceivableAccounts.vue'
import PrepaymentAccounts from '../views/activity/office/accounts/prepayment/PrepaymentAccounts.vue'
import ViewPrepaymentAccount from '../views/activity/office/accounts/prepayment/ViewPrepaymentAccount.vue'
import AccountsPayable from '../views/activity/office/accounts/AccountsPayable.vue'
import ExpensesAccount from '../views/activity/office/accounts/ExpensesAccount.vue'
import ExpensesCategories from '../views/activity/office/accounts/ExpensesCategories.vue' 
import CashAndBanks from '../views/activity/office/accounts/cash_and_banks/CashAndBanks.vue'
import ViewCashAndBanks  from '../views/activity/office/accounts/cash_and_banks/ViewCashAndBanks.vue'
import IncomeAccount from '../views/activity/office/accounts/IncomeAccount.vue' 
import Payables from '../views/activity/office/Payables.vue'
import Receivables from '../views/activity/office/Receivables.vue'
import Expenses from '../views/activity/office/expenses/Expenses.vue'
import Assets from '../views/activity/office/assets/Assets.vue'
import AssetTypes from '../views/activity/office/assets/AssetTypes.vue'
import People from '../views/activity/office/people/People.vue'
import Customers from '../views/activity/office/people/Customers.vue'
import CustomerGroups from '../views/activity/office/people/CustomerGroups.vue'
import Suppliers from '../views/activity/office/people/Suppliers.vue'
import SupplierCategories from '../views/activity/office/people/SupplierCategories.vue'
import ManageCards from '../views/activity/office/cards/ManageCards.vue'

import Payroll from '../views/activity/office/payroll/Payroll.vue'
import Attendances from '../views/activity/office/payroll/Attendances.vue'
import Employees from '../views/activity/office/payroll/Employees.vue'
import EmployeesSchedules from '../views/activity/office/payroll/EmployeesSchedules.vue'
import EmployeesSalaries from '../views/activity/office/payroll/EmployeesSalaries.vue'
import Designations from '../views/activity/office/payroll/Designations.vue'

//SETTINGS

import SubscriptionsAndBilling from '../views/activity/settings/SubscriptionsAndBilling' 
import CompanySettings from '../views/activity/settings/company/CompanySettings'
import CompanyProfile from '../views/activity/settings/company/CompanyProfile' 
import GeneralSettings from '../views/activity/settings/company/GeneralSettings'
import ConfigTills from '../views/activity/settings/ConfigTills.vue'
import TemplateSettings from '../views/activity/settings/company/TemplateSettings'
import PaymentMethods from '../views/activity/settings/company/PaymentMethods.vue'
import SetupOrderOptions from '../views/activity/settings/company/SetupOrderOptions.vue'


import ImportAndExport from '../views/activity/settings/ImportAndExport.vue'
import ConvertOldData from '../views/activity/settings/ConvertOldData.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sales-receipt/:id',
    name: 'Sales Receipt',
    props: true,
    component: SalesReceipt
  },
  {
    path: '/payment-receipt/:id',
    name: 'Payment Receipt',
    props: true,
    component: PaymentReceipt
  },
  {
    path: '/sales-quotation/:id',
    name: 'Quotation',
    props: true,
    component: SalesQuotation
  },
  {
    path: '/sales-invoice/:id',
    name: 'Sales Invoice',
    props: true,
    component: SalesInvoice
  },
  {
    path: '/sales-invoice-statement/:id',
    name: 'Sales Invoice Statement',
    props: true,
    component: SalesInvoiceStatement
  },

  //accounts
  {
    path: '/signup',
    name: 'Signup', 
    component: SignUp
  },
  {
    path: '/recover-password',
    name: 'Recover Password', 
    component: RecoverPassword
  },

  {
    path: '/account',
    name: 'My Account', 
    component: MyAccount
  },
  {
    path: '/account/profile',
    name: 'My Profile', 
    component: MyProfile
  },
  {
    path: '/account/companies',
    name: 'Companies', 
    component: Companies
  }, 
  {
    path: '/account/messages',
    name: 'Messages', 
    component: Messages
  }, 

  //Sales
  {
    path: '/:id/sale',
    props: true,
    name: 'Sale', 
    component: Sale
  },{
    path: '/:id/sale/history',
    props: true,
    name: 'My Sales History', 
    component: MySalesHistory
  },{
    path: '/:id/sale/quotations',
    props: true,
    name: 'My Quotations', 
    component: MyQuotations
  },{
    path: '/:id/sale/days-shifts',
    props: true,
    name: 'My Day Shifts', 
    component: DayShifts
  },{
    path: '/:id/sale/cashup',
    props: true,
    name: 'My Cashup', 
    component: MyCashup
  },{
    path: '/:id/sale/settings',
    props: true,
    name: 'Sales Settings', 
    component: SalesSettings
  },{
    path: '/:id/sale/start-day',
    props: true,
    name: 'Start Day', 
    component: StartDay
  },{
    path: '/:id/manage-shift/:day_key',
    props: true,
    name: 'Manage Day Shift', 
    component: ManageShift
  },{
    path: '/:id/cashups/:day_key/:cash_key',
    props: true,
    name: 'View Cashup', 
    component: ViewCashup
  },{
    path: '/:id/z-read/:day_key',
    props: true,
    name: 'Z Read', 
    component: ZRead
  },{
    path: '/:id/x-read/:day_key',
    props: true,
    name: 'X Read', 
    component: XRead
  },{
    path: '/:id/day-shift-report/:day_key',
    props: true,
    name: 'Day Shift Report', 
    component: SalesReadReport
  },

  //Sales
  {
    path: '/:id/warehouse',
    props: true,
    name: 'Warehouse', 
    component: Warehouse
  },{
    path: '/:id/warehouse/transferred',
    props: true,
    name: 'Transferred Stock', 
    component: StockTransferred
  },
  
  //Orders
  {
    path: '/:id/orders',
    props: true,
    name: 'Order placement', 
    component: Orders
  },{
    path: '/:id/orders/:order_code',
    props: true,
    name: 'Processing Order', 
    component: ViewProcessingOrder
  },{
    path: '/:id/orders/customer-display',
    props: true,
    name: 'Customer Display', 
    component: CustomerDisplay
  },{
    path: '/:id/orders/processing-display',
    props: true,
    name: 'Processing Display', 
    component: ProcessingDisplay
  },
  
  //Catalog
  {
    path: '/:id/catalog',
    props: true,
    name: 'Catalog', 
    component: Catalog
  },{
    path: '/:id/catalog/item-categories',
    props: true,
    name: 'Item Categories', 
    component: ItemCategories
  },{
    path: '/:id/catalog/item-departments',
    props: true,
    name: 'Item Department', 
    component: CatalogItemDepartments
  },{
    path: '/:id/catalog/price-book',
    props: true,
    name: 'Price Book', 
    component: PriceBook
  },{
    path: '/:id/catalog/brands',
    props: true,
    name: 'Brands', 
    component: Brands
  },{
    path: '/:id/catalog/manufacturers',
    props: true,
    name: 'Manufacturers', 
    component: Manufacturers
  },{
    path: '/:id/catalog/gift-cards',
    props: true,
    name: 'Gift Cards', 
    component: GiftCards
  }, 

  //Stock
  {
    path: '/:id/stock',
    props: true,
    name: 'Stock', 
    component: Stock
  },
  {
    path: '/:id/stock/items',
    props: true,
    name: 'Items', 
    component: StockItems
  },
  {
    path: '/:id/stock/manage/items',
    props: true,
    name: 'Manage Items', 
    component: StockItems
  },
  {
    path: '/:id/stock/settings',
    props: true,
    name: 'Stock Settings', 
    component: StockSettings
  },
  {
    path: '/:id/stock/manage',
    props: true,
    name: 'Manage Stock', 
    component: ManageStock
  },
  {
    path: '/:id/stock/manage/:stock_outlet_code',
    props: true,
    name: 'View Stock', 
    component: ViewStock
  },
  {
    path: '/:id/stock/item-kit',
    props: true,
    name: 'Item Kit', 
    component: StockItemKit
  },
  {
    path: '/:id/stock/manage/item-kit',
    props: true,
    name: 'Manage Item Kit', 
    component: StockItemKit
  },
  {
    path: '/:id/stock/departments',
    props: true,
    name: 'Item Departments', 
    component: ItemDepartments
  },
  {
    path: '/:id/stock/departments/:item_department_code',
    props: true,
    name: 'View Department', 
    component: ViewItemDepartment
  },
  {
    path: '/:id/stock/stock-control',
    props: true,
    name: 'Stock Control', 
    component: StockControl
  },
  
  {
    path: '/:id/stock/stock-take',
    props: true,
    name: 'Manage Stock Tak', 
    component: ManageStockTake
  },
  {
    path: '/:id/stock/stock-take/join',
    props: true,
    name: 'Stock Taking', 
    component: StockTaking
  },{
    path: '/:id/stock/stock-take/view/:stock_take_key',
    props: true,
    name: 'View Stock Take', 
    component: ViewStockTake
  },
  
  {
    path: '/:id/stock/stock-control/order-stock',
    props: true,
    name: 'Stock Order', 
    component: StockOrder
  },{
    path: '/:id/stock/stock-control/receive-stock',
    props: true,
    name: 'Stock Receive', 
    component: StockReceive
  },{
    path: '/:id/stock/stock-control/receive-stock-with-invoice',
    props: true,
    name: 'Stock With Invoice', 
    component: ReceiveStockWithInvoice
  },{
    path: '/:id/stock/stock-control/return-stock',
    props: true,
    name: 'Stock Return', 
    component: StockReturn
  },{
    path: '/:id/stock/stock-control/transfer-stock',
    props: true,
    name: 'Stock Transfer', 
    component: StockTransfer
  },{
    path: '/:id/stock/stock-control/adjust-stock',
    props: true,
    name: 'Adjust Transfer', 
    component: AdjustStock
  },{
    path: '/:id/stock/stock-control/sales-return',
    props: true,
    name: 'Sales Return', 
    component: SalesReturn
  },{
    path: '/:id/stock/stock-control/opening-stock',
    props: true,
    name: 'Opening Stock', 
    component: OpeningStock
  },
  
  {
    path: '/:id/stock/quotations&invoices',
    props: true,
    name: 'Quotations & Invoices', 
    component: Quotations_Invoices
  },{
    path: '/:id/stock/Quotations&Invoices/request-quotation',
    props: true,
    name: 'Request Quotation', 
    component: RequestQuotation
  },{
    path: '/:id/stock/Quotations&Invoices/receive-quotation',
    props: true,
    name: 'Received Quotation', 
    component: ReceiveQuotation
  },{
    path: '/:id/stock/Quotations&Invoices/receive-invoice',
    props: true,
    name: 'Received Invoice', 
    component: ReceiveInvoice
  },

  {
    path: '/:id/stock/stock-control/:order_key',
    props: true,
    name: 'View Order', 
    component: ViewOrder
  },
  
  {
    path: '/:id/stock/outlets',
    props: true,
    name: 'Outlets', 
    component: Outlets
  },
  
  {
    path: '/:id/stock/expiries',
    props: true,
    name: 'Expiries', 
    component: Expiries
  },{
    path: '/:id/stock/expiries/add',
    props: true,
    name: 'Add Expiries', 
    component: AddExpiries
  },
  
  
  
  

  //HOSPITAL
  {
    path: '/:id/hospital',
    props: true,
    name: 'Hospital', 
    component: Hospital
  },{
    path: '/:id/hospital/reception',
    props: true,
    name: 'Reception', 
    component: Reception
  },{
    path: '/:id/hospital/triage',
    props: true,
    name: 'Triage', 
    component: Triage
  },{
    path: '/:id/hospital/appointments',
    props: true,
    name: 'Appointments', 
    component: Appointments
  },{
    path: '/:id/hospital/consultations',
    props: true,
    name: 'Consultations', 
    component: Consultations
  },{
    path: '/:id/hospital/laboratory',
    props: true,
    name: 'Laboratory', 
    component: Laboratory
  },{
    path: '/:id/hospital/scanning',
    props: true,
    name: 'Scanning', 
    component: Scanning
  },{
    path: '/:id/hospital/admission',
    props: true,
    name: 'Admission', 
    component: Admission
  },{
    path: '/:id/hospital/pharmacy',
    props: true,
    name: 'Pharmacy', 
    component: Pharmacy
  },{
    path: '/:id/hospital/maternity',
    props: true,
    name: 'Maternity Ward', 
    component: MaternityWard
  },{
    path: '/:id/hospital/dental',
    props: true,
    name: 'Dental Department', 
    component: DentalDepartment
  },{
    path: '/:id/hospital/doctors',
    props: true,
    name: 'Doctors', 
    component: Doctors
  },{
    path: '/:id/hospital/patients',
    props: true,
    name: 'Patients', 
    component: Patients
  },

  // //reports
  // {
  //   path: '/:id/office/reports',
  //   props: true,
  //   name: 'Reports', 
  //   component: Reports
  // },
  
  
  //OFFICE
  {
    path: '/:id/office',
    props: true,
    name: 'Office', 
    component: Office
  },
  //reports
  {
    path: '/:id/office/reports',
    props: true,
    name: 'Reports', 
    component: Reports
  },
  {
    path: '/:id/office/reports/profit&loss',
    props: true,
    name: 'Profit And Loss', 
    component: ProfitAndLossReport
  }, //sales
  {
    path: '/:id/office/reports/sales',
    props: true,
    name: 'Sales Reports', 
    component: SalesReports
  }, {
    path: '/:id/office/reports/sales/items',
    props: true,
    name: 'Sales Items', 
    component: SalesItems
  },{
    path: '/:id/office/reports/sales/transactions',
    props: true,
    name: 'Sales Transactions', 
    component: SalesTransactions
  }, {
    path: '/:id/office/reports/sales/day-shifts',
    props: true,
    name: 'Day Shifts', 
    component: OfficeDayShifts
  }, {
    path: '/:id/office/reports/sales/cashups',
    props: true,
    name: 'Cashups', 
    component: Cashups
  }, 
  {
    path: '/:id/office/reports/income',
    props: true,
    name: 'Income Report', 
    component: IncomeReport
  }, {
    path: '/:id/office/reports/purchases',
    props: true,
    name: 'Purchases Report', 
    component: PurchasesReport
  }, 
  {
    path: '/:id/office/reports/expenses',
    name: 'Expenses Report', 
    component: ExpensesReport
  }, {
    path: '/:id/office/reports/debtors',
    name: 'Debtors Report',  
    component: DebtorsReport
  }, {
    path: '/:id/office/reports/creditors',
    name: 'Creditors Report', 
    component: CreditorsReport
  }, {
    path: '/:id/office/reports/vat',
    name: 'VAT Report', 
    component: VATReport
  }, 
  //accounts
  {
    path: '/:id/office/accounts',
    props: true,
    name: 'Accounts', 
    component: Accounts
  },{
    path: '/:id/office/accounts/cash-and-banks',
    props: true,
    name: 'Money Accounts', 
    component: CashAndBanks
  },
  {
    path: '/:id/office/accounts/cash-and-banks/:money_account_code',
    props: true,
    name: 'View Money Account', 
    component: ViewCashAndBanks
  },{
    path: '/:id/office/accounts/cash-and-banks/0',
    props: true,
    name: 'Cash Account', 
    component: ViewCashAndBanks
  },{
    path: '/:id/office/accounts/payable',
    props: true,
    name: 'Payable Accounts', 
    component: AccountsPayable
  },
  // {
  //   path: '/:id/office/accounts/receivable',
  //   props: true,
  //   name: 'Receivable Accounts', 
  //   component: AccountsReceivable
  // },
  
  {
    path: '/:id/office/accounts/receivable',
    props: true,
    name: 'Receivable Accounts', 
    component: ReceivableAccounts
  },
  {
    path: '/:id/office/accounts/receivable/:account_code',
    props: true,
    name: 'View Receivable', 
    component: ViewReceivableAccounts
  },
  {
    path: '/:id/office/accounts/prepayment',
    props: true,
    name: 'Prepayment', 
    component: PrepaymentAccounts
  },
  {
    path: '/:id/office/accounts/prepayment/:account_code',
    props: true,
    name: 'View Prepayment', 
    component: ViewPrepaymentAccount
  },{
    path: '/:id/office/accounts/expenses',
    props: true,
    name: 'Expanse Accounts', 
    component: ExpensesAccount
  },{
    path: '/:id/office/accounts/expenses/categories',
    props: true,
    name: 'Expanse Categories', 
    component: ExpensesCategories
  },
  
  
  {
    path: '/:id/office/accounts/Income',
    props: true,
    name: 'IncomeAccount', 
    component: IncomeAccount
  },
  
  
  
  //Payables
   {
    path: '/:id/office/payables',
    props: true,
    name: 'Payables', 
    component: Payables
  },
  //Receivables
   {
    path: '/:id/office/receivables',
    props: true,
    name: 'Receivables', 
    component: Receivables
  },
   //Expenses
   {
    path: '/:id/office/expenses',
    props: true,
    name: 'Expenses', 
    component: Expenses
  },
  //assets
  {
    path: '/:id/office/assets',
    props: true,
    name: 'Manage Assets', 
    component: Assets
  },{
    path: '/:id/office/assets/types',
    props: true,
    name: 'Asset Types', 
    component: AssetTypes
  },
  //people
  // {
  //   path: '/:id/office/people',
  //   props: true,
  //   name: 'People', 
  //   component: People
  // },
  {
    path: '/:id/office/customers',
    name: 'Customers', 
    component: Customers
  },{
    path: '/:id/office/customers/groups',
    name: 'Customer Groups', 
    component: CustomerGroups
  },
  {
    path: '/:id/office/suppliers',
    props: true,
    name: 'Suppliers ', 
    component: Suppliers
  },{
    path: '/:id/office/suppliers/categories',
    props: true,
    name: 'Supplier Categories', 
    component: SupplierCategories
  },
  //Manage Cards  
  {
    path: '/:id/office/cards',
    props: true,
    name: 'Manage Cards', 
    component: ManageCards
  }, 
  
  //Payroll 
  {
    path: '/:id/office/payroll',
    props: true,
    name: 'Payroll', 
    component: Payroll
  },{
    path: '/:id/office/payroll/employees',
    props: true,
    name: 'Employees', 
    component: Employees
  },{
    path: '/:id/office/payroll/employees-schedules',
    props: true,
    name: 'Employee\'s Schedules', 
    component: EmployeesSchedules
  },{
    path: '/:id/office/payroll/employees-salaries',
    props: true,
    name: 'Employee\'s Salaries', 
    component: EmployeesSalaries
  },{
    path: '/:id/office/payroll/attendances',
    props: true,
    name: 'Attendances', 
    component: Attendances
  },{
    path: '/:id/office/payroll/designations',
    props: true,
    name: 'Designations', 
    component: Designations
  }, 

  

  
 
 

  //SETTINGS
  {
    path: '/:id/settings',
    props: true,
    name: 'Settings', 
    component: () => import('../views/activity/settings/Settings.vue')
  },

  //Manage Tills  
  {
    path: '/:id/settings/billing',
    props: true,
    name: 'Subscription & Billing', 
    component: SubscriptionsAndBilling
  }, 

  //company
  {
    path: '/:id/settings/company',
     props: true,
    name: 'Company Settings', 
    component: () => import('../views/activity/settings/company/CompanySettings.vue')
  },{
    path: '/:id/settings/company/profile',
     props: true,
    name: 'Company Profile', 
    component: () => import('../views/activity/settings/company/CompanyProfile.vue')
  },{
    path: '/:id/settings/company/general',
     props: true,
    name: 'General Settings', 
    component: () => import('../views/activity/settings/company/GeneralSettings.vue')
  },{
    path: '/:id/settings/company/template',
    props: true,
    name: 'Template Settings', 
    component:TemplateSettings
  }, 
  {
    path: '/:id/settings/company/order-options',
    props: true,
    name: 'Setup Order Options', 
    component: SetupOrderOptions
  }, 
  {
    // path: '/:id/office/accounts/payment-methods',
    path: '/:id/settings/company/payment-methods',
    props: true,
    name: 'Payment Methods', 
    component: PaymentMethods
  },

  //Security
  {
    path: '/:id/settings/security',
     props: true,
    name: 'Security Settings', 
    component: () => import('../views/activity/settings/security/SecuritySettings.vue')
  },
  {
    path: '/:id/settings/security/manage-users',
     props: true,
    name: 'Manage Users', 
    component: () => import('../views/activity/settings/security/ManageUsers.vue')
  },
  {
    path: '/:id/settings/security/user-roles',
    props: true,
    name: 'User Groups', 
    component: () => import('../views/activity/settings/security/UserGroups.vue')
  }, 
  {
    path: '/:id/settings/security/register-user',
    props: true,
    name: 'Register Company User', 
    component: () => import('../views/activity/account/RegisterUser.vue')
  },  
  //Manage Tills  
  {
    path: '/:id/settings/tills',
    props: true,
    name: 'Config Tills', 
    component: ConfigTills
  }, 
  //Devices
  {
    path: '/:id/settings/devices',
     props: true,
    name: 'Devices', 
    component: () => import('../views/activity/settings/devices/DeviceSettings.vue')
  },{
    path: '/:id/settings/devices/scanners',
     props: true,
    name: 'Scanners', 
    component: () => import('../views/activity/settings/devices/Scanners.vue')
  },{
    path: '/:id/settings/devices/printers',
     props: true,
    name: 'Printers', 
    component: () => import('../views/activity/settings/devices/Printers.vue')
  },{
    path: '/:id/settings/devices/servers',
     props: true,
    name: 'Servers', 
    component: () => import('../views/activity/settings/devices/Servers.vue')
  },{
    path: '/:id/settings/devices/computers',
     props: true,
    name: 'Computers', 
    component: () => import('../views/activity/settings/devices/Computers.vue')
  },{
    path: '/:id/settings/devices/scales',
     props: true,
    name: 'Scales', 
    component: () => import('../views/activity/settings/devices/Scales.vue')
  },{
    path: '/:id/settings/devices/customer-displays',
     props: true,
    name: 'Customer Display', 
    component: () => import('../views/activity/settings/devices/CustomerDisplays.vue')
  },

  //Cards
  {
    path: '/:id/settings/cards',
     props: true,
    name: 'Cards', 
    component: () => import('../views/activity/settings/cards/CardSettings.vue')
  },{
    path: '/:id/settings/cards/magnetic-cards',
     props: true,
    name: 'Magnetic Cards', 
    component: () => import('../views/activity/settings/cards/MagneticCards.vue')
  },{
    path: '/:id/settings/cards/swiping-cards',
    props: true,
    name: 'Swiping Cards', 
    component: () => import('../views/activity/settings/cards/SwipingCards.vue')
  },

  //import
  {
    path: '/:id/settings/import-export',
    props: true,
    name: 'Import and Export', 
    component: ImportAndExport
  },
  //old data
  {
    path: '/:id/settings/old-data',
    props: true,
    name: 'Convert Old Data', 
    component: ConvertOldData
  },
  
  //archive
  {
    path: '/:id/settings/archive',
    props: true,
    name: 'Archive', 
    component: () => import('../views/activity/settings/archive/Archive.vue')
  },
  
  {
    path: '/:id/settings/archive/expenses',
    props: true,
    name: 'Archived Expenses', 
    component: () => import('../views/activity/settings/archive/Archive_Expenses.vue')
  },{
    path: '/:id/settings/archive/expenses-accounts',
    props: true,
    name: 'Archived Expenses Accounts', 
    component: () => import('../views/activity/settings/archive/Archive_ExpensesAccounts.vue')
  },{
    path: '/:id/settings/archive/receivables',
    props: true,
    name: 'Archived Receivable Accounts', 
    component: () => import('../views/activity/settings/archive/Archive_ReceivableAccounts.vue')
  },{
    path: '/:id/settings/archive/stock-orders',
    props: true,
    name: 'Archived Stock Order', 
    component: () => import('../views/activity/settings/archive/Archive_StockOrders.vue')
  },{
    path: '/:id/settings/archive/quotations&invoices',
    props: true,
    name: 'Archived Quotations & Invoices', 
    component: () => import('../views/activity/settings/archive/Archive_QuotationsAndInvoices.vue')
  },{
    path: '/:id/settings/archive/outlets',
    props: true,
    name: 'Archived Outlets', 
    component: () => import('../views/activity/settings/archive/Archive_Outlets.vue')
  },{
    path: '/:id/settings/archive/items',
    props: true,
    name: 'Archived Items', 
    component: () => import('../views/activity/settings/archive/Archive_Items.vue')
  },{
    path: '/:id/settings/archive/item-kits',
    props: true,
    name: 'Archived Item Kits', 
    component: () => import('../views/activity/settings/archive/Archive_ItemKits.vue')
  },{
    path: '/:id/settings/archive/item-categories',
    props: true,
    name: 'Archived Item Categories', 
    component: () => import('../views/activity/settings/archive/Archive_ItemCategories.vue')
  },{
    path: '/:id/settings/archive/brands',
    props: true,
    name: 'Archived Brands', 
    component: () => import('../views/activity/settings/archive/Archive_Brands.vue')
  },{
    path: '/:id/settings/archive/suppliers',
    props: true,
    name: 'Archived Supplies', 
    component: () => import('../views/activity/settings/archive/Archive_Suppliers.vue')
  },{
    path: '/:id/settings/archive/customers',
    props: true,
    name: 'Archived Customers', 
    component: () => import('../views/activity/settings/archive/Archive_Employees.vue')
  },

  

  //Cpanel
  {
    path: '/cpanel',
    name: 'Cpanel', 
    component: () => import('../views/activity/cpanel/Cpanel.vue')
  },
  {
    path: '/cpanel/errors',
    name: 'Errors', 
    component: () => import('../views/activity/cpanel/Errors.vue')
  },
  //users
  {
    path: '/cpanel/users&permissions',
    name: 'Users & Permissions', 
    component: () => import('../views/activity/cpanel/users/UsersAndPermissions.vue')
  },{
    path: '/cpanel/users&permissions/users',
    name: 'Users', 
    component: () => import('../views/activity/cpanel/users/Users.vue')
  },{
    path: '/cpanel/users&permissions/user-groups',
    name: 'Main User Groups', 
    component: () => import('../views/activity/cpanel/users/UserGroups.vue')
  },{
    path: '/cpanel/users&permissions/permissions',
    name: 'Permissions', 
    component: () => import('../views/activity/cpanel/users/Permissions.vue')
  },{
    path: '/cpanel/users&permissions/register-user',
    name: 'Register Users', 
    component: () => import('../views/activity/account/RegisterUser.vue')
  },
  //
  {
    path: '/cpanel/companies',
    name: 'Manage Companies', 
    component: () => import('../views/activity/cpanel/Companies.vue')
  },
  {
    path: '/cpanel/visiters',
    name: 'Visiters', 
    component: () => import('../views/activity/cpanel/Visiters.vue')
  },//locations
  {
    path: '/cpanel/locations',
    name: 'Locations', 
    component: () => import('../views/activity/cpanel/locations/Locations.vue')
  },
  {
    path: '/cpanel/locations/countries',
    name: 'Countries', 
    component: () => import('../views/activity/cpanel/locations/Countries.vue')
  },
  {
    path: '/cpanel/locations/regions',
    name: 'Regions', 
    component: () => import('../views/activity/cpanel/locations/Regions.vue')
  },
  {
    path: '/cpanel/locations/districts',
    name: 'Districts', 
    component: () => import('../views/activity/cpanel/locations/Districts.vue')
  },
  //Billing Plans
  {
    path: '/cpanel/billing',
    name: 'Billing', 
    component: () => import('../views/activity/cpanel/billing/Billing.vue')
  },
  {
    path: '/cpanel/billing/plans',
    name: 'Billing Plans', 
    component: () => import('../views/activity/cpanel/billing/BillingPlans.vue')
  },{
    path: '/cpanel/billing/features',
    name: 'Billing Plan Features', 
    component: () => import('../views/activity/cpanel/billing/BillingPlanFeatures.vue')
  },
  //System Setup 
  {
    path: '/cpanel/system-setup',
    name: 'System Setup', 
    component: () => import('../views/activity/cpanel/systemSetup/SystemSetup.vue')
  },
  {
    path: '/cpanel/system-setup/company-types',
    name: 'Company Types', 
    component: () => import('../views/activity/cpanel/systemSetup/CompanyTypes.vue')
  },
  {
    path: '/cpanel/system-setup/company-sizes',
    name: 'Company Sizes', 
    component: () => import('../views/activity/cpanel/systemSetup/CompanySizes.vue')
  },
  {
    path: '/cpanel/system-setup/measurement-units',
    name: 'Measurement Units', 
    component: () => import('../views/activity/cpanel/systemSetup/MeasurementUnits.vue')
  },
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0
    let PAGE_LOAD = store.getters.getPAGE_LOAD

    if (to.hash) { 
      if (PAGE_LOAD) {
        scrollTo = to.hash 
      }else{
        redirect(to)
      }
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }  
    return goTo(scrollTo,{offset:120,duration: 1000,})
    return { x: 0, y: 0 }
  },
  routes,
  mode:"history"
})

export default router
