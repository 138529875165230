<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card >  
                        <!-- <mbs-page-tab 
                            :items="MY_LINKS"/>  -->
                        <mbs-item-manager
                            :table_title="ThisOrderOption?ThisOrderOption.name:'-- Order'"
                            :table_title_icon="'mdi-coffee-maker'"
                            :add_vertical_lines="true"
                            v-model="input"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="'Order'"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"  
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="ThisItemOrders"   
                            :table_tabs="TabItems"   
                            :is_joined="true"  
                            :path_item="PATH_ITEM"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                            @on_row_clicked="VIEW_ORDER"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
            <div>
                <!-- View ORDER  DIALOG -->
                <v-dialog
                    v-model="dialog_view_order"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="700px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" v-if="dialog_view_order" > 
                        <v-card-title class="primary">
                            <div class="font-weight-bold b2--text"> 
                                <span >ORDER</span> 
                            </div> 
                            <v-spacer></v-spacer> 
                            <v-btn class="ml-9" @click="dialog_view_order=false" icon dark><v-icon >close</v-icon></v-btn>
                        </v-card-title>    
                        <v-card-text class="b1">
                            <br>
                            <v-card outlined>
                                <v-card-text> 
                                    <v-layout align-center class="my-1">
                                        <v-icon class="mr-2">mdi-receipt</v-icon>
                                        <div>Customer:</div>
                                        <v-spacer/>
                                        <div class="ml-2 font-weight-bold">{{ SELECTED_ORDER.customer_name }}</div>
                                    </v-layout>
                                    <v-layout align-center class="my-1">
                                        <v-icon class="mr-2">mdi-receipt</v-icon>
                                        <div>Total Amount:</div>
                                        <v-spacer/>
                                        <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ SELECTED_ORDER.total_sold_price_ }}</div>
                                    </v-layout>
                                </v-card-text> 
                            </v-card>

                            <v-card outlined class="mt-2">
                                <v-list>
                                    <v-list-item @click="
                                            SELECTED_ORDER.expand_items=!SELECTED_ORDER.expand_items 
                                            if (SELECTED_ORDER.expand_details) {
                                                SELECTED_ORDER.expand_details = false
                                            }
                                        ">
                                        <v-list-item-content><div class="font-weight-bold ">{{MBS.actions.SIZE(SELECTED_ORDER.joined_items)}} Item(s)</div></v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn  icon>
                                                <v-icon v-if="SELECTED_ORDER.expand_items">mdi-chevron-up</v-icon>
                                                <v-icon v-else>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list> 
                                <!-- <v-divider></v-divider> -->
                                <v-simple-table v-if="SELECTED_ORDER.expand_items"
                                    fixed-header
                                    :height="vsh-590">
                                    <template v-slot:default>
                                    <thead>
                                        <tr class=" table-background ">
                                            <th class="text-left with-divide font-weight-bold">
                                                Name
                                            </th>
                                            <th class="text-left with-divide  ">
                                                price
                                            </th>
                                            <th class="text-left with-divide  ">
                                                Quantity
                                            </th>
                                            <th class="text-left with-divide ">
                                                Total Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in SELECTED_ORDER.joined_items"  >
                                            <td class="with-divider ">{{ item.name }}</td>
                                            <td class="with-divider">{{ MBS.actions.money(item.sold_price) }}</td>
                                            <td class="with-divider">{{ item.quantity }}</td>
                                            <td class="with-">{{ MBS.actions.money(item.quantity*item.sold_price) }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>

                            <v-card class="pa-2 mt-2" outlined>
                                <v-list>
                                    <v-list-item @click="
                                                SELECTED_ORDER.expand_details=!SELECTED_ORDER.expand_details 
                                                if (SELECTED_ORDER.expand_items) {
                                                    SELECTED_ORDER.expand_items=false
                                                }
                                            ">
                                        <v-list-item-content><div class="font-weight-bold">Order Details</div></v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn  icon>
                                                <v-icon v-if="SELECTED_ORDER.expand_details">mdi-chevron-up</v-icon>
                                                <v-icon v-else>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list> 
                                <div v-if="SELECTED_ORDER.expand_details"> 
                                    <v-layout align-center class="my-1">
                                        <v-icon class="mr-2">mdi-receipt</v-icon>
                                        <div>Order Number:</div>
                                        <div class="ml-2">{{ SELECTED_ORDER.key }}</div>
                                    </v-layout>
                                    <v-layout align-center class="my-1">
                                        <v-icon class="mr-2">mdi-cash-register</v-icon>
                                        <div>Initiated By:</div>
                                        <div class="ml-2">{{ SELECTED_ORDER.teller_name }}</div>
                                    </v-layout>
                                    <v-layout align-center class="my-1">
                                        <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                                        <div>Initiated Time:</div>
                                        <div class="ml-2">{{ SELECTED_ORDER.created_at_ }}</div>
                                    </v-layout>
                                    <v-layout align-center class="my-1">
                                        <v-icon class="mr-2">mdi-timer-settings-outline</v-icon>
                                        <div>Estimated Time:</div>
                                        <div class="ml-2">{{ SELECTED_ORDER.estimated_time?SELECTED_ORDER.estimated_time:"..." }}</div>
                                    </v-layout>
                                    <v-layout align-center class="my-1">
                                        <v-icon class="mr-2">mdi-list-status</v-icon>
                                        <div>Status:</div>
                                        <div class="ml-2">{{ SELECTED_ORDER.order_status }}</div>
                                    </v-layout>
                                    <v-layout align-center class="my-1">
                                        <v-icon class="mr-2">home</v-icon>
                                        <div>Description:</div>
                                        <div class="ml-2">{{ SELECTED_ORDER.description }}</div>
                                    </v-layout> 
                                </div>
                            </v-card>
                        </v-card-text>  
                        <v-card-actions>
                            <v-spacer/>
                            <!-- <v-btn color="primary" ><v-icon>close</v-icon>Cancel</v-btn>
                            <v-btn color="primary" ><v-icon>edit</v-icon>Edit</v-btn>
                            <v-btn color="primary" ><v-icon>mdi-cash</v-icon>Pay</v-btn> -->

                            <!-- <v-btn-toggle  dark  class="mb-2 mr-2" >
                                <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                    <span class="hidden-sm-and-down mx-3">EXIT</span>
                                    <v-icon>mdi-exit-to-app</v-icon> 
                                </v-btn>
                                
                                <v-btn  @click="CONFIRM_DELETE_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="cancel">
                                    <span class="hidden-sm-and-down">Cancel</span> 
                                    <v-icon right>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>

                                <v-btn @click="EDIT_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="center">
                                    <span class="hidden-sm-and-down">Update</span> 
                                    <v-icon right>
                                        edit
                                    </v-icon>
                                </v-btn>
                                
                                <v-btn @click="PRINT_ORDER_OPTION(SELECTED_ORDER,'bill_sale_order')"  color="primary" value="center">
                                    <span class="hidden-sm-and-down">Print</span> 
                                    <v-icon right>
                                        print
                                    </v-icon>
                                </v-btn>

                                <v-btn  @click="PAY_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="right">
                                    <span class="hidden-sm-and-down">Pay</span> 
                                    <v-icon right>
                                        mdi-cash
                                    </v-icon>
                                </v-btn> 
                            </v-btn-toggle> -->
                            <v-btn-toggle  dark  class="mb-2 mr-2" >
                                <v-btn  @click="CONFIRM_UPDATING_ORDER(SELECTED_ORDER,{order_status:'processing'})"  color="primary" value="exit">
                                    <span class="hidden-sm-and-down mx-3">Processing</span>
                                    <v-icon>mdi-blender</v-icon> 
                                </v-btn>
                                
                                <v-btn  @click="CONFIRM_UPDATING_ORDER(SELECTED_ORDER,{order_status:'completed'})"  color="primary" value="cancel">
                                    <span class="hidden-sm-and-down">Completed</span> 
                                    <v-icon right>
                                        mdi-coffee-maker-check-outline
                                    </v-icon>
                                </v-btn>  
                            </v-btn-toggle>
                        </v-card-actions>
                    </v-card> 
                </v-dialog>  
            </div>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.ITEM_ORDERS.values    
    export default {
        name:PAGE_NAME, 
        props:['id','order_code'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                input:null, 
                input:null, 
                SELECTED_ORDER:null, 
                dialog_view_order:false, 
                NAME_ITEMS:DATA.ITEMS.ITEM_ORDERS.names,
                NAME_ITEM:DATA.ITEMS.ITEM_ORDERS.name,
                VALUE_ITEMS:DATA.ITEMS.ITEM_ORDERS.values,
                VALUE_ITEM:DATA.ITEMS.ITEM_ORDERS.value,       
                table_actions:[
                    // {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Band"},  
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                DATA:DATA
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: { 
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({ 
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 

            }),   
            ItemOrdersData(){  
                return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
            },  
            LocalItemOrdersData(){  
                return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
            },  
            AllItemOrders(){  
                let local = this.LocalItemOrdersData
                let live = this.ItemOrdersData 
                return live
            },  
            ThisOrderOption(){ 
                let order_code = this.order_code
                let orders = this.ItemOrderOptionsData
                console.log(order_code,'order_code....');
                console.log(orders,'orders....');
                if(!orders || !order_code){return null}
                let item = orders.find(order=>order.code == order_code) 
                return item
            },
            ThisItemOrders(){  
                let item_orders = this.AllItemOrders
                let orderOption = this.ThisOrderOption
                let order_option_key = orderOption?orderOption.key:null 
                if(!item_orders || !order_option_key){return null}
                let item = item_orders.filter(order=>order.order_option_key == order_option_key) 
                return item
            },
            ItemOrderOptionsData(){  
                return this.JoinedItemOrderOptions?this.JoinedItemOrderOptions:this.ItemOrderOptions
            }, 
            TabItems(){  
                return [
                    {name:"New Orders",show_if:(this_,item)=>{  
                            return !item.order_status
                        },indicator:{
                        color:'blue',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},
                    
                    {name:"Processing",show_if:(this_,item)=>{ 
                            return item.order_status=='processing'
                        },indicator:{
                        color:'yellow',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                    {name:"Completed",show_if:(this_,item)=>{  
                            return item.order_status=='completed'
                        },indicator:{
                        color:'success',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                    {name:"All Orders",show_if:(this_,item)=>true,indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }}, 
                ]
            },  
            route(){
                return this.$route
            },
            
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("orders",1,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.item_orders,true) 
                return path
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",tile:true,size:35,show:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Initiated at",value:'local_created_at_',show:true},    
                    {id:0,name:"Receipt No.",value:'key',align:"center",show:true},     
                    {id:0,name:"Name",value:"customer_name",show:true},       
                    {id:0,name:"Status",value:"order_status",show:false},       
                    {id:0,name:"estimated",value:"estimated_time",show:false},       
                    {id:0,name:"price",value:"total_sold_price_",align:"right",show:true},       
                    {id:0,name:"Description",value:"description",show:false},       
                    {id:0,name:"update",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Order",action:"view_order",icon:"mdi-database-eye"}, 
                        // {name:"Update Estimated Time",action:"update_estimated",icon:"mdi-timer-sync-outline"}, 
                        {name:"Flag as Processing",action:"flag_as_processing",icon:"mdi-blender"}, 
                        {name:"Flag as Completed",action:"flag_as_completed",icon:"mdi-coffee-maker-check-outline"}, 
                        // {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },  
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                     if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Orders","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item,option){
                try {     
                    if (action == "edit_item") {
                        this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                        this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                        this.ADDING_ITEM.fields =  DATA.FIELDS.items
                        this.ADDING_ITEM.add_item_action =  {
                            action_do:'Edit',
                            action_doing:'Editing'
                        }
                    }else if (action == "view_order") {
                        this.VIEW_ORDER(item)
                    }else if (action == "flag_as_processing") {
                        this.CONFIRM_UPDATING_ORDER(item,{order_status:"processing"})
                    }else if (action == "flag_as_completed") {
                        this.CONFIRM_UPDATING_ORDER(item,{order_status:"completed"})
                    }else{
                        console.log(action,item,option,'action,item,option..........');
                        this.MBS.actions.dialog(null,true,false,"Coming Soon!..","Current not working. Try again later.",null,"OK") 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            VIEW_ORDER(order){
                try {    
                    console.log(order,'cccccccccccc.....');  
                    this.SELECTED_ORDER = {...order,expand_items:true,expand_details:false}
                    this.dialog_view_order = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_UPDATING_ORDER(order,data){
                try {     
                    let key=order?order.key:null
                    let day_shift_key=order?order.day_shift_key:null 
                    let new_data = { 
                        ...data
                    }
                    let mbs_text = this.MBS.text.item_action("Order","Update")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=UPDATE-ITEM-ORDER",
                            data:new_data,
                            path:this.PATH_ITEM+day_shift_key+"/"+order.key
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RELOAD_ITEM_ORDERS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEM_ORDERS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEM_ORDERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEM_ORDERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=UPDATE-ITEM-ORDER') {
                            if (action.YES) {  
                                setTimeout(() => { 
                                    this.dialog_view_order=false
                                    let path = action.path 
                                    this.$store.dispatch("fi_update",{
                                        action:this.VALUE_ITEM,
                                        path:path,
                                        data:action.data, 
                                    }).then(res=>{
                                        let mbs_text = this.MBS.text.item_action_success("ORDER","Updated")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        let mbs_text = this.MBS.text.item_action_fail("ORDER","Updated")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEM_ORDERS()
                            } else {
                                
                            }
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            beforeDestroy(){
                try {   
                    this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'beforeDestroy',
                        page:PAGE_NAME, 
                    })
                }
            },
            FEEDBACKS(time){  
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        watch: {  
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
