<template>
  <v-app class="mbs-no-highlight"> 
    <v-layout fill-height="">
      <ActivityShow v-if="show"  />
      <ActivityMain v-else  />
      <!-- <ComingSoon /> -->
    </v-layout>
  </v-app>
</template>

<script> 
let activity = "App";
import ActivityMain from'./views/mainActivity/ActivityMain'
import ActivityShow from'./views/mainActivity/ActivityShow'
import ComingSoon from'./views/mainActivity/ComingSoon'

export default {
  name: activity,  
  components:{
    ActivityShow,
    ActivityMain,
    ComingSoon,
  },
  data: () => ({
    show:true,
    navOpen:false,
    mini: false,
    startTime: false,
    f1Key: '',
      f2Key: '',
      f3Key: '',
  }),
  created(){ 
    let self = this
    setTimeout(()=>{
        self.startTime=true
    }, 2500); 
  },
  mounted(){  
    window.addEventListener('keydown', this.handleKeydown);
    let self = this  
    document.onreadystatechange = ()=>{
      if (document.readyState == "complete") { 
          if (self.startTime===true) {
            self.startApp(500) 
          } else { 
            self.startApp(2500)        
          } 
      }else{ 
      }  
    }
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  computed: { 
  },
  methods: { 
    startApp(t){
      var self=this
      setTimeout(()=>{
          self.show=false
      },t); 
    }, 

    handleKeydown(event) {
      const preventKeys = [
       'F1','F2','F3','F4','F12',
      ];
      let prevent = preventKeys.findIndex(key=>key==event.key)==-1?false:true
      if (prevent) {
        event.preventDefault();
      }
      // switch (event.key) {
      //   case 'F1':
      //     this.f1Key = 'F1 Pressed';
      //     event.preventDefault(); // Prevent default browser action
      //     break;
      //   case 'F2':
      //     this.f2Key = 'F2 Pressed';
      //     event.preventDefault(); // Prevent default browser action
      //     break;
      //   case 'F3':
      //     this.f3Key = 'F3 Pressed';
      //     event.preventDefault(); // Prevent default browser action
      //     break;
      // }      
    }, 
  },
};
</script>
