<template>
  <v-card width="100%" :height="height?height:'100%'" flat color="transparent" tile>
    <v-layout class="ma-0 pa-2" 
      fill-height="" 
      justify-center="" 
      align-center="" >
        <v-progress-circular 
            :size="size?size:80"
            :width="width?width:10" 
            :color="color?color:'primary'"
            indeterminate=""/>   
    </v-layout>  
  </v-card>
</template>

<script>
export default {
  props:["color","size","width","height"],
  data: () => ({
    
  }),
  computed: {
     
  },  
  methods: {
     
  },
};
</script>
