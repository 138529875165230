<template>
    <v-dialog
        v-model="dialog_attendance"
        scrollable   
        :persistent="true" :overlay="false"
        max-width="1000px"
        transition="dialog-transition" >
        <v-card rounded="lg" class="b1" >
            <!-- //------------TITLE -->
            <v-card-title class="primary">
                <div class="font-weight-bold b2--text">
                    ATTENDANCE
                </div> 
                <v-spacer></v-spacer>
                <v-btn class="ml-9" @click="dialog_attendance=false" icon dark><v-icon >close</v-icon></v-btn>
            </v-card-title>

            <v-card-text  class="pt-5">
                <v-row>
                    <v-col cols="12" sm="5"  >
                        <v-card v-if="SelectedUser?.checkin_attendance"  tile height="100%"  class="overflow-auto">
                            <v-card-text>
                                <h3>{{SelectedUser.checkin_attendance.outlet_name}}</h3>
                                <v-card outlined class=" mt-3"> 
                                    <v-card outlined class="b1 pa-2">
                                        <div class="font-weight-bold mb-2">Check In: {{MBS.date.convert(SelectedUser.checkin_attendance.checkin_date,null,3)}}</div>
                                        <v-divider></v-divider>
                                        <v-list class="b1">
                                            <v-list-item>
                                                <v-list-item-action class="pa-0 ma-0 mr-3">
                                                    <v-avatar 
                                                        v-if="SelectedUser.photoURL"
                                                        size="55"  >
                                                        <v-img :src="SelectedUser.photoURL" lazy-src="https://picsum.photos/10/6?image=3" >
                                                            <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center">
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                        /> 
                                                                </v-row>
                                                            </template>
                                                        </v-img>
                                                    </v-avatar> 
                                                    <v-avatar v-else
                                                        size="55" 
                                                        color="secondary" class="white--text" >
                                                        <div v-if="SelectedUser">
                                                            {{MBS.actions.TEXT_UP(SelectedUser?.name.slice(0,1))}} 
                                                        </div>
                                                    </v-avatar>  
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ SelectedUser.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        <span>Phone:</span> {{ SelectedUser.phone_number }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle>
                                                        <span>Card:</span> {{ SelectedUser.card_code }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list> 
                                    </v-card>
                                </v-card>
                                <v-layout column justify-center align-center class="mt-3">
                                    <v-progress-circular
                                        :rotate="-90"
                                        :size="120"
                                        :width="15"
                                        :value="CheckTimePercent(SelectedUser.checkin_attendance)"
                                        color="primary"
                                        >
                                        <div class="font-weight-bold ">{{CheckTime(SelectedUser.checkin_attendance)}}</div>
                                        
                                        <!-- {{ (SelectedUser.checkin_attendance.diff_minute*100/(24*60)).toFixed(2) }} -->
                                    </v-progress-circular>
                                    <v-layout class="mt-3"> 
                                        <v-btn @click="SUBMIT_CHECKOUT(SelectedUser,SelectedUser.checkin_attendance)" class="px-9" rounded color="primary">Check Out</v-btn>
                                    </v-layout>
                                </v-layout>  
                            </v-card-text>
                        </v-card>
                        <v-card v-else>
                            <v-card-text  >
                                <br> 
                                <!-- <v-layout justify-center>
                                    <v-avatar out class="mt-"
                                        color="b2" size="100" > 
                                        <v-avatar  color="white"
                                            size="98"  >
                                            <VAvatar
                                                size="50"  tile> 
                                                <v-icon>{{mdiAccountCreditCard}}</v-icon>
                                            </VAvatar>
                                        </v-avatar> 
                                    </v-avatar>
                                </v-layout> -->
                                <v-card class="mt-9" v-if="selected_user" outlined>
                                    <v-layout class="mx-0 pa-2">
                                        <div>Check In:</div>
                                        <div  class="ml-2 font-weight-bold">{{MBS.date.convert(input_date,null,4)}} </div>  
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-list class="b1">
                                        <v-list-item>
                                            <v-list-item-action class="pa-0 ma-0 mr-3">
                                                <v-avatar 
                                                    v-if="SelectedUser.photoURL"
                                                    size="55"  >
                                                    <v-img :src="SelectedUser.photoURL" lazy-src="https://picsum.photos/10/6?image=3" >
                                                        <template v-slot:placeholder>
                                                            <v-row
                                                                class="fill-height ma-0"
                                                                align="center"
                                                                justify="center">
                                                                <v-progress-circular
                                                                    indeterminate
                                                                    color="grey lighten-5"
                                                                    /> 
                                                            </v-row>
                                                        </template>
                                                    </v-img>
                                                </v-avatar> 
                                                <v-avatar v-else
                                                    size="55" 
                                                    color="secondary" class="white--text" >
                                                    <div v-if="SelectedUser">
                                                        {{MBS.actions.TEXT_UP(SelectedUser?.name.slice(0,1))}} 
                                                    </div>
                                                </v-avatar>  
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ SelectedUser.name }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <span>Phone:</span> {{ SelectedUser.phone_number }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    <span>Card:</span> {{ SelectedUser.card_code }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list> 
                                </v-card>
                                <form @submit.prevent="SUBMIT_CHECKIN_USER(input,selected_user,input_date)">  
                                    <mbs-input-fields class="mt-5 mb-3"
                                        v-model="input" 
                                        :field_items="fields"
                                        :select_items="DataSource" 
                                        /> 

                                    <v-layout row wrap class="mx-0 mb-3 " v-if="true">   
                                            <v-flex xs12   class="mt-3 mb-3">
                                                <v-layout row wrap class="ma-0">
                                                    <v-flex  >
                                                        <v-btn v-if="true"  type="submit"   
                                                            :loading="inputLoading"
                                                            color="secondary" height="50" width="100%" >
                                                            <div class="not-f5">
                                                                <!-- <v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon>  -->
                                                                CHECK-IN</div>
                                                        </v-btn>   
                                                        <v-btn v-else type="submit"   
                                                            :loading="inputLoading"
                                                            color="secondary" height="50" width="100%" >
                                                            <div class="not-f5">CHECK-OUT</div>
                                                        </v-btn>    
                                                    </v-flex> 
                                                    <v-btn   @click="dialog_attendance=false"
                                                        :loading="inputLoading" class="ml-2" 
                                                        color="primary" height="50"  >
                                                        <div class="not-f5">CANCEL</div>
                                                    </v-btn>    
                                                </v-layout>
                                            </v-flex> 
                                        </v-layout> 
                                </form>
                            </v-card-text> 
                        </v-card>   
                    </v-col>

                    <v-col cols="12" sm="7" class="pl-0" >
                        <v-card  height="100%"   tile  >   
                            <v-toolbar color="primary" dark dense>
                                <v-icon class="mr-2" >mdi-calendar-text-outline</v-icon>
                                <v-toolbar-title>History</v-toolbar-title>
                            </v-toolbar>
                            <v-simple-table dense class="pa-2">
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Outlet
                                        </th>
                                        <th class="text-left">
                                            Day Shift
                                        </th>
                                        <th class="text-left">
                                            In
                                        </th>
                                        <th class="text-left">
                                            Out
                                        </th>
                                        <th class="text-left">
                                            Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in SelectedUser?.all_attendances"
                                    :key="item.name"
                                    >
                                    <td class=" text-center with-divider-lr  ma-0 pa-0">{{ item.outlet_name}}</td>
                                    <td class=" with-divider ">{{ item.operating_date_ }}</td>
                                    <td class=" with-divider ">{{ item.checkin_date_time }}</td> 
                                    <td class=" with-divider ">{{ item.checkout_date_time }}</td> 
                                    <td class=" with-divider text-right">{{ CheckTime(item) }}</td> 
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row> 
            </v-card-text>

            <!-- //----------BODY --> 
            <v-divider></v-divider>
          
        </v-card> 
    </v-dialog> 
</template>

<script>  
    import DATA from "../../plugins/DATA"
    import {mapState} from "vuex"
    import { mdiAccountCreditCard } from '@mdi/js';
    let NAME = 'CHECKIN'
    export default {
        name:NAME,
        props:['data','type','printer','com_printer'], 
        data(){
            return{
                show:true,
                dialog_attendance:false,
                DATA:DATA,
                printSVG:null,
                inputLoading:false,
                mdiAccountCreditCard:mdiAccountCreditCard, 
                selected_user:null, 
                input:{},
                input_date:null,
                fields:[
                    {type:"autocomplete",value:"day_shift_key",label:"Select Shift",label_top:" Day Shift", 
                        select:{
                            name:'DayShifts',
                            value:'key',
                            text:'outlet_name'
                        },
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                let third = item?index(item.outlet_name,queryText):false
                                if(!item){return first} 
                                if(!third){return first || second } 
                                return first || second || third 
                            },
                            item_icon:{
                                name:'mdi-cash-register',
                                tile:true
                            },
                            item_title:['operating_date'],
                            item_subtitle:['outlet_name','',],
                        },
                        action:[  
                            // { 
                            //     icon:"add",
                            //     item_values:"day_shifts",
                            //     name:"Start Shift",
                            //     link:"/sale/start-day",
                            //     company_link:true,
                            //     append:true
                            // },{ 
                            //     icon:"add",
                            //     item_values:"day_shifts",
                            //     name:"Manage Day Shifts",
                            //     link:"/sale/days-shifts",
                            //     company_link:true,
                            //     append:true
                            // }
                        ],
                    required:true,disabled:false,sm:12,md:12},
                ]

            } 
        }, 
        created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN); 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:NAME, 
                }) 
            }
        },
        mounted(){
             
        },
        computed:{
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Users: state=> state.users[(DATA.ITEMS.USERS.values).toUpperCase()],
                // JoinedUsers: state=> state.join[(DATA.ITEMS.USERS.values).toUpperCase()], 
                CompanyUsers: state=> state.items[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],
                // JoinedCompanyUsers: state=> state.join[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()], 
                CompanyUserGroups: state=> state.users[(DATA.ITEMS.COMPANY_USER_GROUPS.values).toUpperCase()],
                JoinedCompanyUserGroups: state=> state.join[(DATA.ITEMS.COMPANY_USER_GROUPS.values).toUpperCase()],  
                Cards: state=> state.items[(DATA.ITEMS.CARDS.values).toUpperCase()],
                JoinedCards: state=> state.join[(DATA.ITEMS.CARDS.values).toUpperCase()],  
                Designations: state=> state.items[(DATA.ITEMS.DESIGNATIONS.values).toUpperCase()],
                JoinedDesignations: state=> state.join[(DATA.ITEMS.DESIGNATIONS.values).toUpperCase()], 
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 

                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalDaysShifts: state=> state.locals[(DATA.ITEMS.LOCAL_DAYS_SHIFTS.values).toUpperCase()], 
                LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()], 
                mbs_card_swipe: state=> state.mbs['mbs_card_swipe'],
                Attendances: state=> state.items[(DATA.ITEMS.ATTENDANCES.values).toUpperCase()],
                JoinedAttendances: state=> state.join[(DATA.ITEMS.ATTENDANCES.values).toUpperCase()], 
            }),
            DaysShiftsData(){ 
                let DaysShifts = this.DaysShifts
                let JoinedDaysShifts = this.JoinedDaysShifts
                let items = JoinedDaysShifts?JoinedDaysShifts:DaysShifts
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list 
            },  
            RunningDayShifts(){
                let days =this.DaysShiftsData
                if(!days){return null}
                let filtered = days.filter(day=>!day.closed)
                return filtered
            },
            DesignationsData(){ 
                return this.JoinedDesignations?this.JoinedDesignations:this.Designations
            }, 
            StockOutletsData(){
                let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets  
                return items 
            },
            JoinedUsers(){
                let users = this.Users
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            JoinedCompanyUsers(){
                let users = this.CompanyUsers
                let join = this.MBS.actions.JOIN_USERS(users) 
                return join
            }, 
            CompanyUsersData(){  
                return this.JoinedCompanyUsers?this.JoinedCompanyUsers:this.CompanyUsers
            }, 
            AttendancesData(){ 
                let items = this.JoinedAttendances?this.JoinedAttendances:this.Attendances
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("key",false))    
                return list
            }, 
            Employees(){  
                let user = this.CompanyUsersData
                if(!user){return null}
                let employees = user.filter(user=>user.designation_code)
                return employees
            }, 
            CompanyUserGroupsData(){ 
                return this.JoinedCompanyUserGroups?this.JoinedCompanyUserGroups:this.CompanyUserGroups
            }, 
            CardsData(){ 
                return this.JoinedCards?this.JoinedCards:this.Cards
            }, 
            DataSource(){ 
                let DaysShifts =this.RunningDayShifts
                return {
                    DayShifts:DaysShifts
                }
            }, 
            SelectedUser(){
                let user = this.selected_user
                let attendances = this.AttendancesData
                if(!user){return null}
                if(!attendances){return user}
                let all = attendances.filter(item=>item.uid == user.uid )
                let checkin = all?all.filter(item=>!item.checkout_date):null
                let checkout = all?all.filter(item=>item.checkout_date):null
                let data = {
                    ...user,
                    all_attendances:all,
                    checkin_attendance:checkin?checkin[0]:null,
                    checkin_attendances:checkin,
                    checkout_attendances:checkout,
                    isIn:checkin?true:false
                } 
                return data
            },
            HEADER_ITEMS(){ 
                let tiers = this.AllPricingTiers
                let headers = [
                    {id:1,name:"General",title:true},
                    {id:0,name:"image",show:false},
                    {id:0,name:"name",value:'name',show:false}, 
                    {id:0,name:"surname",value:'surname',show:false}, 
                    {id:0,name:"names",value:'names',show:false}, 
                    {id:0,name:"Phone",value:'phone_number',show:false},   
                    {id:0,name:"Designation",value:'designation_name',show:false},      
                    {id:0,name:"User Group",value:'company_user_group_name',show:false},      
                    {id:0,name:"Outlet",value:'outlet_name',show:true},      
                    {id:0,name:"Card",value:'card_code',show:false},      
                    {id:0,name:"Day shift",value:'operating_date_',show:true},  
                    {id:0,name:"Check-in",value:'checkin_date_time',show:true},   
                    {id:0,name:"Check-out",value:'checkout_date_time',show:true},    
                    {id:0,name:"Production",value:'attend_time',show:true},  
                    {id:0,name:"action",align:"center",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Remove "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ] 
                return headers 
            }, 
            liveTime(){
                let time = this.MBS.date.live 
                return time
            },
            CheckTimePercent(){  
                return attendance=>{ 
                    let live = this.liveTime
                    let moment = this.MBS.date.moment
                    let checkin_date = attendance?attendance.checkin_date:null 
                    let diff_hours = moment(live).diff(checkin_date,'hours')
                    let diff_minute = moment(live).diff(checkin_date,'minutes')
                    return (diff_minute*100/(24*60)).toFixed(2) 
                } 
            },
            CheckTime(){  
                return attendance=>{ 
                    let live = attendance.checkout_date? attendance.checkout_date:this.liveTime
                    let moment = this.MBS.date.moment
                    let checkin_date = attendance?attendance.checkin_date:null 
                    let diff_hours = moment(live).diff(checkin_date,'hours')
                    let diff_minute = moment(live).diff(checkin_date,'minutes')
                    let diff_seconds = moment(live).diff(checkin_date,'seconds')
                    let sec = diff_seconds - (diff_minute*60)
                    let min = diff_minute - (diff_hours*60)
                    return ''+diff_hours+'h '+min+'m \n\n'+sec+'s'
                } 
            },
        },
        methods:{
            CHECKIN_USER(code){ 
                try {
                    let users = this.CompanyUsersData
                    let us = this.us
                    if (users && us) {
                        let check_user = users.find(user=>user.card_code ==code)
                        if (check_user) {
                            this.selected_user = check_user
                            this.input_date = new Date
                            this.dialog_attendance = true
                        }
                        console.log(code,'code.............');
                        console.log(users,'users.............');
                        console.log(check_user,'check_user.............');

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECKIN_USER',
                        page:NAME, 
                    }) 
                }  
            }, 
            SUBMIT_CHECKIN_USER(input,selected_user,input_date){ 
                try { 

                    let day_shift_key = input?.day_shift_key
                    let uid = selected_user?.uid
                    let card_code = selected_user?.card_code
                    let moment = this.MBS.date.moment
                    let checkin_date = moment(input_date).valueOf()

                    let data = {
                        day_shift_key:day_shift_key,
                        uid:uid,
                        card_code:card_code,
                        checkin_date:checkin_date, 
                    }
                    

                    let mbs_text = this.MBS.text.item_action("To Outlet","Check-in")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        icon:'mdi-calendar-plus',
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnNo:mbs_text.btnNo,
                        btnYes:mbs_text.btnYes,
                        action:{
                            code:NAME+"=CHECKIN-USER",
                            data:data

                        }
                    })
                    console.log(input,'input.............');
                    console.log(selected_user,'selected_user.............');
                    console.log(input_date,'input_date.............');
                    console.log(data,'data.............');

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_CHECKIN_USER',
                        page:NAME, 
                    }) 
                }  
            },
            SUBMIT_CHECKOUT(selected_user, attendance){ 
                try { 

                    let day_shift_key = attendance?.day_shift_key
                    let uid = attendance?.uid
                    let card_code = attendance?.card_code
                    let checkin_date = attendance?.checkin_date
                    let moment = this.MBS.date.moment
                    let checkout_date = moment().valueOf()

                    let data = {
                        day_shift_key:day_shift_key,
                        uid:uid,
                        card_code:card_code,
                        checkin_date:checkin_date,
                        checkout_date:checkout_date, 
                    }
                    

                    let mbs_text = this.MBS.text.item_action(" ","Check Out")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        icon:'mdi-calendar-check',
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnNo:mbs_text.btnNo,
                        btnYes:mbs_text.btnYes,
                        action:{
                            code:NAME+"=CHECKIN-USER",
                            data:data

                        }
                    }) 
                    console.log(selected_user,'selected_user.............'); 
                    console.log(data,'data.............');

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_CHECKIN_USER',
                        page:NAME, 
                    }) 
                }  
            }, 
            
            ////
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===NAME+'=PAY-BILL') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.$store.dispatch("fi_add",{
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_pay_bill = false
                                         this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success("Bill","Paying")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Bill","Paying")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        }  
                        if (action.code ===NAME+'=CHECKIN-USER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    let data = action.data
                                    let link = DATA.PATHS.attendances+data.day_shift_key+"/"+data.checkin_date
                                    let f_detail = {
                                        action:DATA.ITEMS.ATTENDANCES.values,
                                        path:this.MBS.actions.COMPANY_LINK(link,true),
                                        data:data,
                                        us:this.us
                                    } 
                                    this.input.loading = true 
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog(null,'Updating...')
                                    this.$store.dispatch("fi_update",f_detail)
                                    .then(res=>{ 
                                        let mbs_text = this.MBS.text.update_item_success("Attendance")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,  
                                        }) 
                                        this.input.loading = false 
                                        this.inputLoading = false 
                                        this.dialog_attendance = false 
                                        this.MBS.actions.progressDialog() 
                                        this.MBS.actions.restartResponses() 
                                        console.log(res,'res...........');
                                    })
                                    .catch(error=>{ 
                                        let mbs_text = this.MBS.text.update_item_fail("Attendance")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes, 
                                        })  
                                        this.input.loading = false
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        this.MBS.actions.restartResponses()

                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);     
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:NAME, 
                    }) 
                }  
            },  
            ON_QR_SCAN(code){
                try {
                    console.log( this.mbs_card_swipe,' this.mbs_card_swipe');
                    let page = this.mbs_card_swipe?.page
                    if(!page){
                        if (code) {  
                            let size = this.MBS.actions.SIZE(code)
                            if (size<3) { 
                            }else{
                                this.MBS.actions.dialog()
                                // let first = code.slice(0,1)
                                // let last = code.slice(-1)
                                // let mid = code.slice(1,-1)
                                let band1 = code.indexOf("%")!=-1?true:false
                                let band2 = code.indexOf(";")!=-1?true:false
                                let band3 = code.indexOf("+")!=-1?true:false
                                let close = code.slice(-1) 
    
                                if ((band1||band2||band3) && close=="?") {
                                    let first_string = code.split("?")[0]  
                                    code = first_string.slice(1) 
                                    this.CHECKIN_USER(code)
                                } else {  
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"ERROR",
                                        text:"Please, Scan again.",
                                        btnYes:"OK"
                                    })
                                }  
                            }
                        } 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:NAME, 
                    })  
                } 
            },

        },
        beforeDestroy(){
            try {   
                this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:NAME, 
                })
            }
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
