<template>
<v-layout column fill-height="" justify-center="" align-center="">
    <!-- <mbs-bus-logo  class="pb-5 "
      size="200"
      :type="2" 
      />  -->
    <div class="not-f8 font-weight-bold ml-5 c5--text ">Our site is launching soon..</div>
    <div class="not-f font-weight-bol ml-5 sc5--tex ">info@futuretoursmw.com</div>
   
</v-layout>
</template>

<script>
  export default {
    data: () => ({
       imgsss:'logo/mbe-logo.svg'
    })
  }
</script> 
