<template>
    <div>
        <!-- <v-btn ref="regisButton" @click="clicked()" color="success">Download</v-btn>
        <v-btn  @click="EXPORT_EXCEL()" color="error">Download</v-btn> -->
        
        <vue-excel-xlsx v-show="false" 
            ref="download_items" 
            :data="DATA_ITEMS"
            :columns="HEADER_ITEMS"
            :file-name="file_name?file_name:'kipcount file'"
            :file-type="'xlsx'"
            :sheet-name="sheet_name?sheet_name:'items'"
            >
        </vue-excel-xlsx>
        <!-- <export-excel
            class   = "btn btn-default"
            :data   = "json_data"
            :fields = "json_fields"
            worksheet = "My Worksheet"
            name    = "filename.xls">
        
            Download Excel (you can customize this with html code!)
        
        </export-excel> -->
    </div>
</template>

<script>
    import DATA from "../../plugins/DATA"
    let NAME = 'EXCEL-EXPORT'
    export default {
        name:NAME,
        props:['file_name','sheet_name','data_items','header_items'], 
        data(){
            return{
                show:true,
                DATA:DATA, 
                input:{}, 

                columns : [
                    {
                        label: "Product",
                        field: "product",
                    },
                    {
                        label: "Price",
                        field: "price",
                        dataFormat: this.priceFormat
                    },
                    {
                        label: "Quantity",
                        field: "quantity",
                    },
                    {
                        label: "Country",
                        field: "address.country",
                    },
                    {
                        label: "City",
                        field: "address.city",
                    }
                ],
                data : [
                    {
                        product: "Beverage",
                        price: 10,
                        quantity: 2,
                        address: {
                            country: 'Armenia',
                            city: 'Yerevan',
                        }
                    },
                    {
                        product: "Snack",
                        price: 12,
                        quantity: 6,
                        address: {
                            country: 'Armenia',
                            city: 'Yerevan',
                        }
                    },
                    {
                        product: "Beverage",
                        price: 10,
                        quantity: 5,
                        address: {
                            country: 'Armenia',
                            city: 'Yerevan',
                        }
                    },
                    {
                        product: "Snack",
                        price: 12,
                        quantity: 3,
                        address: {
                            country: 'Armenia',
                            city: 'Yerevan',
                        }
                    }
                ],
                json_fields: {
                    'Complete name': 'name',
                    'City': 'city',
                    'Telephone': 'phone.mobile',
                    'Telephone 2' : {
                        field: 'phone.landline',
                        callback: (value) => {
                            return `Landline Phone - ${value}`;
                        }
                    },
                },
                json_data: [
                    {
                        'name': 'Tony Peña',
                        'city': 'New York',
                        'country': 'United States',
                        'birthdate': '1978-03-15',
                        'phone': {
                            'mobile': '1-541-754-3010',
                            'landline': '(541) 754-3010'
                        }
                    },
                    {
                        'name': 'Thessaloniki',
                        'city': 'Athens',
                        'country': 'Greece',
                        'birthdate': '1987-11-23',
                        'phone': {
                            'mobile': '+1 855 275 5071',
                            'landline': '(2741) 2621-244'
                        }
                    }
                ],
                json_meta: [
                    [
                        {
                            'key': 'charset',
                            'value': 'utf-8'
                        }
                    ]
                ],
            } 
        }, 
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('EXPORT_EXCEL', this.EXPORT_EXCEL);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:NAME, 
                }) 
            }
        },
        computed:{
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            DATA_ITEMS(){
                let data_items = this.data_items
                if(!data_items){return null}
                return data_items
            }, 
            HEADER_ITEMS(){
                let header_items = this.header_items
                if(!header_items){return null}
                let headers = []
                header_items.forEach(element => {
                    if (element.name && element.show && element.name != 'action') {
                        headers.push({
                            label: element.name,
                            field: element.value?element.value:this.MBS.actions.TEXT_UP0(element.name),
                        })  
                    }
                });
                // console.log(headers,'headers..-..');
                return headers
            },  

        },
        methods:{ 
            EXPORT_EXCEL(action){
                let download_items = this.$refs.download_items 
                if (download_items) {
                    download_items.$el.click() 
                }
            }, 
            DIALOG_YES(action){ 
                try {
                    if (this.input.check) {
                        this.input.check = false
                        if (action.code) { 
                            if (action.code ===NAME+'=ADD-ITEM') {
                                if (action.YES) {
                                      
                                } else {
                                    
                                }
                            }   
                        }  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:NAME, 
                    }) 
                }  
            },
            
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
