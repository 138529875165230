<template>
    <div class="b1">   
        <v-container grid-list-xl> 
            <div v-if="Joined_SelectedOrder"> 
                <!-- <v-toolbar dense color="primary" class="mt-2" dark>
                     
                    <h1 class="b2--text  ">{{Joined_SelectedOrder.type_name}}</h1>
                    <template v-if="$vuetify.breakpoint.mdAndUp">

                        <v-spacer></v-spacer>
                        <v-divider vertical></v-divider> 

                        <v-btn-toggle
                            v-model="toggle_multiple"
                            color="secondary"
                            dense
                            group
                            multiple
                        >
                            <v-btn
                            :value="1"
                            text
                            >
                            <v-icon>mdi-format-bold</v-icon>
                            </v-btn>

                            <v-btn
                            :value="2"
                            text
                            >
                            <v-icon>mdi-format-italic</v-icon>
                            </v-btn>

                            <v-btn
                            :value="3"
                            text
                            >
                            <v-icon>mdi-format-underline</v-icon>
                            </v-btn>

                            <v-btn
                            :value="4"
                            text
                            >
                            <v-icon>mdi-format-color-fill</v-icon>
                            </v-btn>
                        </v-btn-toggle>

                        <div class="mx-4"></div>

                        <v-btn-toggle
                            v-model="toggle_exclusive"
                            color="secondary"
                            dense
                            group
                        >
                            <v-btn
                            :value="1"
                            text
                            >
                            <v-icon>mdi-format-align-left</v-icon>
                            </v-btn>

                            <v-btn
                            :value="2"
                            text
                            >
                            <v-icon>mdi-format-align-center</v-icon>
                            </v-btn>

                            <v-btn
                            :value="3"
                            text
                            >
                            <v-icon>mdi-format-align-right</v-icon>
                            </v-btn>

                            <v-btn
                            :value="4"
                            text
                            >
                            <v-icon>mdi-format-align-justify</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </template>
                </v-toolbar> -->
                <v-toolbar dense color="primary" class="mt-2" dark>
                     
                    <h1 class="b2--text  ">{{Joined_SelectedOrder.type_name}}</h1>
                    <template v-if="$vuetify.breakpoint.mdAndUp">

                        <v-spacer></v-spacer>
                        <v-divider vertical></v-divider> 

                        <v-btn 
                            icon @click="ON_PDF()">
                            <v-icon>mdi-file-pdf-box </v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_EXCEL()">
                            <v-icon>mdi-microsoft-excel </v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_EMAIL()">
                            <v-icon>mdi-email-outline</v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_PRINTING()">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>
                <br>  
                <v-layout column justify-center class="ma-0">
                    <mbs-stock-order-temp1 
                        :stock_order="Joined_SelectedOrder"
                        /> 
                </v-layout> 
                <br> 
            </div> 
        </v-container>  
        <div> 
            <mbs-auto-print
                v-if="autoPrint"
                :show="true"  
                :type="typePrint"  
                :data="itemPrint" /> 
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    let PAGE_NAME = "VIEW-ORDER"  
    export default {
        name:PAGE_NAME, 
        props:['order_key'], 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                quotation_code:null, 
                show_input:true, 

                itemPrint:null,
                typePrint:null,
                autoPrint:false,
 
         
                toggle_exclusive: 2,
                toggle_multiple: [1, 2, 3],
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            ///////// 

            SelectedOrder(){  
                let item = this.$store.getters.getStockOrderByKey(this.order_key)  
                if(!item){
                    item = this.$store.getters.getQuotationAndInvoiceByKey(this.order_key)   
                }
                return item
            },   
            Joined_SelectedOrder(){
                let item = this.SelectedOrder
                let joined = this.MBS.actions.JOIN_STOCK_ORDER(item) 
                return joined
            },
            PaymentMethods(){
                return this.$store.getters.getDefaultPaymentMethods
            },     
            ITEMS(){
                return this.$store.getters.getItems
            },  
            Suppliers(){ 
                return this.$store.getters.getSuppliers
            },  
            Outlets(){
                return this.$store.getters.getStockOutlets
            },  
            QuotationsAndInvoices(){
                return this.$store.getters.getQuotationsAndInvoices
            }, 
            Joined_QuotationsAndInvoices(){
                let items = this.QuotationsAndInvoices 
                let joined = this.MBS.actions.JOIN({
                    items:items,
                    name:DATA.ITEMS.QUOTATIONS_AND_INVOICES.values
                })
                return joined
            }, 
            RequestForQuotations(){
                let items = this.Joined_QuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Quotations(){
                let items = this.Joined_QuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.QUOTATIONS.value)  
                });
                return filtered_items
            },  
            JOINED_ITEMS(){ 
                let items = this.ITEMS 
                let joined = this.MBS.actions.JOIN({
                    items:items,
                    name:DATA.ITEMS.ITEMS.values
                })
                return joined
            },  
            CALCULATE_TOTAL_QT_PRICE(){
                return items=>{
                    let total_price = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let price = this.toNumber(item.price)
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            total_price = total_price + (quantity*(price-discount))
                        });  
                        return total_price
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_QT_PRICE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){
                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let RequestForQuotations = this.RequestForQuotations
                let Quotations = this.Quotations
                return {
                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    RequestForQuotations:RequestForQuotations,
                    Quotations:Quotations,
                }
            },
            DisabledFields(){
                let order_code = this.quotation_code
                if (order_code) {
                    return ['supplier_code']
                }
                return null
            }
        },
        methods: { 
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_PRINTING(){
                try { 
                    let stock_order = this.Joined_SelectedOrder  
                    this.autoPrint = false
                    setTimeout(() => {
                        this.itemPrint = {
                            stock_order:stock_order, 
                        }
                        this.typePrint = 'stock_order'
                        this.autoPrint = true  
                    }, 5);
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(v){  
                let quotation_code =v?v.quotation_code:null 
                if ( quotation_code && quotation_code != this.quotation_code) {
                    this.quotation_code = quotation_code
                    this.ADD_REQUEST_FOR_QUOTATION(quotation_code) 
                }else{ 
                }
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
