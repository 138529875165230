<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 357.05 109.47"
  :fill="iconColor" xml:space="preserve"> 

   <polygon :fill="cls1" points="125.78 107.89 143.85 1.84 172.77 1.84 154.67 107.89 125.78 107.89 125.78 107.89"/><path :fill="cls1" d="M259.52,4.46A74.33,74.33,0,0,0,233.61,0c-28.56,0-48.68,14.38-48.86,35-.16,15.24,14.36,23.74,25.33,28.81,11.26,5.2,15,8.52,15,13.16-.08,7.11-9,10.35-17.3,10.35-11.57,0-17.71-1.6-27.21-5.56l-3.72-1.68-4.06,23.74c6.75,3,19.23,5.52,32.2,5.65,30.4,0,50.11-14.21,50.34-36.24.11-12.05-7.59-21.23-24.26-28.8-10.11-4.91-16.31-8.18-16.24-13.15,0-4.41,5.24-9.13,16.56-9.13A53.11,53.11,0,0,1,253,26.22l2.61,1.22,3.91-23Z"/><path :fill="cls1" d="M333.65,1.94H311.31c-6.92,0-12.1,1.89-15.14,8.8l-42.92,97.19h30.36s4.95-13.06,6.08-15.93l37,0c.86,3.72,3.51,15.89,3.51,15.89h26.83l-23.4-106ZM298,70.31c2.39-6.12,11.52-29.65,11.52-29.65-.17.28,2.37-6.15,3.83-10.13l2,9.15L322,70.31Z"/><path :fill="cls1" d="M101.54,1.92,73.23,74.24l-3-14.69C65,42.6,48.54,24.24,30.18,15.06l25.88,92.75,30.58,0L132.15,1.92Z"/><path :fill="cls2" d="M47,1.85H.36L0,4.06c36.26,8.78,60.26,30,70.21,55.49L60.09,10.81c-1.75-6.72-6.83-8.72-13.11-9Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
    data(){
      return{
        cls1:this.iconColor=='currentColor'?"#0058a0":this.iconColor,
        cls2:this.iconColor=='currentColor'?"#faa61a":this.iconColor, 
      }
    },
    mounted(){ 
    }
      
  }
</script>
 
