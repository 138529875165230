<template  >
<v-layout column>
    <!-- //LOADING -->
    <v-card  v-if="!PAGE_LOAD" 
        :height="vs.height-260" >
        <mbs-page-spinner 
            height="500"/> 
    </v-card>

    <!-- //SHOW-PAGE -->
    <div v-else class=" pt-3">  
        <v-container grid-list-xs>
            <v-card flat tile>
                <v-stepper v-model="step" vertical > 
                    <v-card tile>
                            <mbs-page-tab
                            :items="pageTaps" />  
                        </v-card>
                    <h2 class="headline pt-4 pl-2  ">RECOVER PASSWORD</h2> 
                    <div  >
                        <!-- Step 1 signup with Phone-->
                        <v-stepper-step 
                            :complete="step > 1" 
                            step="1">
                            Phone number 
                        </v-stepper-step>
                        <v-stepper-content step="1">
                            <v-card
                                color="grey  lighten-2 primary--text " 
                                class="mb-5 pa-2">
                                <h2 class="font-weight-light">PHONE NUMBER</h2>
                                <v-card-text>
                                    <v-layout row wrap >
                                        <v-flex xs12 md4 >
                                            <v-layout class="mr-1"> 
                                                <v-autocomplete
                                                v-model="select.country"
                                                :items="countryNames" 
                                                filled
                                                background-color="white"
                                                label="Country" >
                                                    <template 
                                                        slot="selection"
                                                        slot-scope="data" >
                                                        <v-chip 
                                                            :input-value="data.selected"
                                                            :disabled="data.disabled"
                                                            :key="JSON.stringify(data.item)"
                                                            class="v-chip--select-multi"
                                                            
                                                            @input="data.parent.selectItem(data.item)" >
                                                            <v-avatar v-if="select.country!==''" 
                                                                class="mr-2" >
                                                                    <span 
                                                                        style="font-size:30px"   >
                                                                        <flag :iso="select.country.split(':')[0]" />   
                                                                    </span> 
                                                            </v-avatar> 
                                                            {{ data.item.split(': ')[1] }}
                                                        </v-chip> 
                                                    </template>
                                                </v-autocomplete>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <v-text-field
                                                filled
                                                background-color="white"
                                                id="inputPhone"  
                                                v-model="inputPhone"
                                                append-icon="phone" 
                                                label="Phone Number"></v-text-field> 
                                        </v-flex> 
                                    </v-layout>
                                
                                </v-card-text>
                                <small >{{select.accountType==='Business account'?'To continue, please enter your business  phone number':'Enter your personal phone number to continue.'}}</small>
                            </v-card>
                            
                            <v-btn  
                                @click="CHECK_USER()"
                                :loading="load"
                                :disabled="!GET_PHONE_NUMBER(true).valid"
                                color="primary" >Continue
                                <v-icon  >chevron_right</v-icon>
                            </v-btn>
                            <v-btn 
                                text 
                                @click="CANCEL_SIGNING()">Cancel</v-btn>
                        </v-stepper-content> 

                        <!-- Step 2 confirm number -->
                        <v-stepper-step 
                            :complete="step > 2" 
                            step="2">
                            Verify user's phone number
                        </v-stepper-step>
                        <v-stepper-content step="2">
                            <v-card
                                color="grey  lighten-2 primary--text " 
                                class="mb-5 pa-2">
                                <v-card-title > 
                                    <h2 class="font-weight-light">VERIFY USER'S PHONE NUMBER</h2>
                                    <v-spacer></v-spacer> 
                                </v-card-title>
                                <v-card-text> 
                                    <v-card>
                                        <v-layout align-center class="pa-4 ">
                                            <div class="not-f7 font-weight-bold">{{GET_PHONE_NUMBER()?GET_PHONE_NUMBER(true).international:".."}}</div>
                                        </v-layout> 
                                    </v-card>
                                </v-card-text>  
                                <small>Click the Verify Button bellow to send verification code sms to the users phone number.</small>
                            </v-card> 
                            <v-btn 
                                @click.native="NEXT_STEP(1,1000)">
                                <v-icon>chevron_left</v-icon>
                                Back
                            </v-btn>
                            <v-btn  @click.native="NEXT_STEP('number',10000)"
                                id="bth_verify_number"
                                ref="bthVerifyNumber"
                                :disabled="!GET_PHONE_NUMBER(true).valid"
                                color="primary" 
                                :loading="load" >Verify
                                <v-icon class="ml-2" >message</v-icon>
                            </v-btn>
                            <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                        </v-stepper-content> 

                        <!-- Step 3 confirm number -->
                        <v-stepper-step 
                            :complete="step > 3" 
                            step="3">
                            Confirm phone number
                        </v-stepper-step>
                        <v-stepper-content step="3">
                            <v-card
                                color="grey  lighten-2 primary--text " 
                                class="mb-5 pa-2">
                                <v-card-title > 
                                    <h2 class="font-weight-light">CONFIRM YOUR PHONE NUMBER</h2>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="SIGN_PHONE_NUMBER()"
                                        small=""
                                        dark="" 
                                        class="blue" >Resend 
                                        <v-icon >refresh</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text> 
                                    <v-layout align-center>
                                        <v-text-field 
                                            filled
                                            background-color="white"
                                            :mask="mask.code"
                                            v-model="input.phoneConfirm"
                                            append-icon="announcement"
                                            type="number"
                                            label="Enter confirmation code"></v-text-field>
                                    </v-layout>
                                </v-card-text>  
                                <small>Please, Enter the confirmation code sent to your number.You can click Resend button to resend the code, if it's taking too long.</small>
                            </v-card>
                            <v-btn 
                                @click.native="step = 2">
                                <v-icon  >chevron_left</v-icon>
                                Back
                            </v-btn>
                            <v-btn 
                                @click.native="NEXT_STEP('CONFIRM_PHONE_NUMBER',1)"
                                :disabled="(input.phoneConfirm?input.phoneConfirm.length:0)<6"
                                color="primary" :loading="load"
                                >Continue <v-icon  >chevron_right</v-icon>
                            </v-btn>
                            <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                        </v-stepper-content> 
                        
                        <!-- step 4 setup password --> 
                        <v-stepper-step 
                            :complete="step > 4"
                            step="4">Setup new password
                        </v-stepper-step>
                        <v-stepper-content step="4">
                            <form id="setEmailForm" @submit.prevent="ADD_USER_PASSWORD()">
                                <v-card
                                    color="grey  lighten-2 primary--text " 
                                    class="mb-5 pa-2"
                                    >
                                    <h2 class="font-weight-light">Create user Password</h2>
                                    <v-card-text > 
                                    <!-- todo -->  
                                        <v-layout align-center>
                                            <v-text-field 
                                            filled autocomplete="one-time-code"  
                                            background-color="white"
                                            prepend-inner-icon="lock"
                                            v-model="input.password"
                                            :append-icon="psw1 ? 'visibility' : 'visibility_off'"
                                            @click:append="() => (psw1 = !psw1)"
                                            :type="psw1 ? 'password' : 'text'"
                                            name="input-10-1"
                                            label="Enter your password"
                                            hint="At least 8 characters"
                                            min="6"
                                            required 
                                            counter></v-text-field>
                                            
                                        </v-layout>
                                        <v-layout align-center>
                                            <v-text-field 
                                            filled autocomplete="one-time-code"  
                                            background-color="white"
                                            prepend-inner-icon="lock"
                                            v-model="input.password_confirm"
                                            :append-icon="psw1 ? 'visibility' : 'visibility_off'"
                                            @click:append="() => (psw1 = !psw1)"
                                            :type="psw1 ? 'password' : 'text'"
                                            name="input-10-1"
                                            label="Password_confirm"
                                            hint="At least 8 characters"
                                            min="8"
                                            required 
                                            counter></v-text-field> 
                                        </v-layout>
                                        
                                    </v-card-text>
                                        <small>Create your User Password. it will be used for signing and other security issues.</small>
                                </v-card>
                                <v-btn 
                                    @click.native="step = 4">
                                    <v-icon  >chevron_left</v-icon>
                                    Back
                                </v-btn>
                                <v-btn 
                                    :loading="load"
                                    type="submit"
                                    color="primary"  
                                >Continue
                                    <v-icon  >chevron_right</v-icon>
                                </v-btn>
                                
                                <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                            </form>   
                        </v-stepper-content>
                    </div>  
                </v-stepper>   
            </v-card>
            <v-layout justify-center align-baseline class="pt-5">
                <div><v-icon class="mt-2 mr-1" color="b2 ">$mbs</v-icon></div>
                <div class="b2--text">Kipcount V{{DATA.APP.VERSION}}</div> 
            </v-layout>
        </v-container>
    </div> 
</v-layout> 
</template>
 
<script>
    //firebase
    import firebase  from 'firebase/app'
    import 'firebase/auth' 
    import DATA from '../../../plugins/DATA'
    import mbs from '../../../store/mbs'

    let PAGE_NAME = 'RECOVER_PASSWORD' 
    export default {
        name:PAGE_NAME,
        data () {
        return {   
            PAGE_LOAD:false, 
            DATA:DATA,
            pageTaps:[
                {name:"Register",link:"/signup"},
                {name:"Recover Password",link:"/recover-password"},
            ], 
            
            psw1: true,   
            step: 1,
            load:false, 
            signProcess:1,
            confirmRetry:1,
            recaptch:false,
            reverify: false,
            //   
            select:{
                signOption:'',
                accountType:'',
                businessType:'',
                country:''
            }, 
            input:{
                password:'',
                passwordConfirm:'',
            },
            inputPhone:'', 
            //-----------mask---------------
            mask: { 
                phone:'phone', 
            }, 
            
            //firebase
            confirmationResult:'', 
            BROCK_NO_STATUS:false
            
        }
        },
        created(){ 
            try {
                if (this.us) {
                    this.MBS.actions.logout(true) 
                }
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                })
            }
        },
        mounted(){ 
            try {  
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar',  
                    height:80 
                })
                this.MBS.actions.subToolbar({
                    show:true,
                    height:70,
                    flat:false,
                    color:'primary',
                    textColor:'white', 
                    side:'right'
                }) 
                this.FEEDBACKS()    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                })
            }
        },
        computed: {
            //common 
            vs(){
                return this.$vuetify.breakpoint
            }, 
            us(){
                let returnUser = this.$store.getters.getUser
                return returnUser
            }, 
            ud(){
                let returnUser = this.$store.getters.getUserDetails
                return returnUser
            }, 
            loading(){ 
                const loading = this.$store.getters.getLoading
                return loading
            },
            processes(){ 
                const process = this.$store.getters.getProcesses
                return process
            }, 
            responses(){ 
                const re = this.$store.getters.getResponses
                return re
            },

            ///////// 
            districts(){ 
                const re = this.$store.getters.getDistricts
                return re
            },
            locations(){
                let districts =[]
                let all = this.$store.getters.getCustomCountries
                if(!all){return null}
                all.forEach(country => {
                    let allD = country.districts
                    allD.forEach(dis => {
                        districts.push(dis.name+' ('+dis.region+' Region)')
                    })
                })
                return districts   
            },
            countryNames(){
                let names = []
                let countries = this.$store.getters.getCountries 
                for (const key in countries) {
                    const cn = countries[key]
                    if (cn.alpha2!== undefined) {
                        names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                    } 
                } 
                //names=['Mw: Malawi (+265)']
                return names
            }, 
            iLocation(){ 
                const i = this.$store.getters.getIpLocation
                return i
            },
            GET_PHONE_NUMBER(){
                try {
                    let us = this.us  
                    let input_country = ''+this.select.country
                    let input_phone = ''+this.inputPhone 
                    let countryNames = this.countryNames 
                    let PHONE = this.MBS.phone 
                    return all =>{
                        let current_phone = ''+input_phone 
                        let ip_phone = null 
                        let ip_countryPhone = null 

                        if (input_phone) {
                            input_phone = input_phone.replace(/[^0-9+]/g,'')
                        }if (input_country) {
                            input_country = input_country.replace(/[^0-9]/g,'')
                        } 

                        if (input_phone) { 
                            ip_phone = PHONE(input_phone)
                        }if (input_country) { 
                            ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                        } 

                        if (ip_phone) {
                            let possible = ip_phone.g?ip_phone.g.possible:null
                            if (possible) {
                                current_phone = ip_phone.g

                                if (countryNames) { 
                                    let regionCode = current_phone.regionCode
                                    let countryName = countryNames.find(item=>{
                                        return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                    })
                                    if (countryName != this.select.country) {
                                        this.select.country = countryName
                                    } 
                                }
                            }
                        }
                        if (ip_countryPhone) {
                            let possible = ip_countryPhone.a?ip_countryPhone.a.possible:null
                            if (possible) {
                                current_phone = ip_countryPhone.a
                            }
                        }else{

                        }

                        if (current_phone.valid) { 
                            if (''+this.inputPhone != ''+current_phone.number.international ) { 
                                setTimeout(() => {
                                    this.inputPhone = ''+current_phone.number.international  
                                }, 1);
                            }
                        } 
                        // console.log(input_phone,'input_phone.........');
                        // console.log(input_country,'input_country.........');  
                        // console.log(ip_countryPhone,'ip_countryPhone.........');
                        // console.log(ip_phone,'ip_phone.........');  
                        // console.log(current_phone,'current_phone.........'); 

                        if (all) {
                            return {
                                ...current_phone,
                                ...current_phone.number,
                                phone:current_phone.number?current_phone.number.e164:'',
                                phone_number:current_phone.number?current_phone.number.e164:'',

                            }
                        } else {
                            return current_phone.number?current_phone.number.e164:''
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }  
            },
        }, 
        methods: {   
            createRecaptach(){
                try {
                    const self = this;
                    self.signProcess =2 
                    setTimeout(()=>{ 
                        if (!window.recaptchaVerifier) {   
                            // Start Firebase invisible reCAPTCHA verifier
                            firebase.auth().useDeviceLanguage();
                            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('bth_verify_number', {
                                size: 'invisible',
                                callback: (res) => {
                                    // reCAPTCHA solved, allow signInWithPhone_number.
                                    self. SIGN_PHONE_NUMBER();
                                    // self.notSnack(true,12000,'Ckecking number','top')
                                },
                                'expired-callback': function(res) { 
                                    self.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"TIME OUT",
                                        text:"To continue Registering, Click Continue Button or Cancel Button to cancel.",
                                        btnYes:"Continue",
                                        btnNoTxt:"Cancel",
                                        action:{
                                            data:PAGE_NAME+"=REGISTER-TIMOUT"
                                        }
                                    })
                                    // Response expired. Ask user to solve reCAPTCHA again.
                                    // ...
                                    // self.notSnack(true,5000,'recaptach error. start again.','top')
                                }
                            });
                            self.setRecaptach() 
                            console.log('recaptch set') 
                        } else {
                            self.reSetRecaptach()
                            console.log('recaptch reSet') 
                        }
                        
                    },50)
            
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'createRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            },
            setRecaptach(){
                try {
                    window.recaptchaVerifier.render().then((widgetId) => {
                        window.recaptchaWidgetId = widgetId;
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'setRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            },
            reSetRecaptach(){
                try {
                    // window.recaptchaVerifier.render().then((widgetId)=> {
                    //     grecaptcha.reset(widgetId)
                    // })
                    window.recaptchaVerifier = null
                    this.createRecaptach() 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'reSetRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            },

            CHECK_PHONE_NUMBER(){
                try {
                    this.MBS.actions.snackbar({
                        show:true,
                        time:5000,
                        y:"bottom",
                        color:"primary",
                        text:"Checking Phone Number..."
                    })
                    //us
                     
                    let PHONE = this.GET_PHONE_NUMBER(true)
                    if (PHONE.valid) {
                        return PHONE.phone
                    }  
                    this.MBS.actions.dialog({
                        show:true,
                        title:"ERROR",
                        text:"Please, make sure you enter valid phone number.",
                        btnYes:'OK'
                    })
                    return null
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }  
            },
            async CHECK_USER(){
                try {   
                    let PHONE = this.GET_PHONE_NUMBER(true)
                    if (PHONE.valid) { 
                        this.load = true
                        try {
                            let phone_number = PHONE.phone 
                            let prams = {
                                phone_number:phone_number,
                                key:DATA.APP.key.apiKey
                            }
                            let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/checkUser',prams)
                            let body = res?res.body:null
                            this.load = false
                            let user = body?body.user:null
                            let user_details = body?body.user_details:null 
                            let self_registered = user_details?user_details.self_registered:null
                            let password_protected = user_details?user_details.password_protected:null
                            let phone_number_verified = user_details?user_details.phone_number_verified:null 
                            console.log(user,'user....');
                            if (phone_number_verified) {
                                this.NEXT_STEP(2,10)
                            }else if (user) {
                                let mbs_text = this.MBS.text.number_not_properly_registered(this.GET_PHONE_NUMBER())
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:mbs_text.title,
                                    text:mbs_text.text,
                                    btnYes:mbs_text.btnYes,
                                    action:{
                                        code:"=GO",
                                        to:"/signup" 
                                    },
                                })
                            } else {
                                let mbs_text = this.MBS.text.number_not_registered(this.GET_PHONE_NUMBER())
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:mbs_text.title,
                                    text:mbs_text.text,
                                    btnYes:mbs_text.btnYes,
                                    action:{
                                        code:"=GO",
                                        to:"/signup" 
                                    },
                                })
                            } 
                        } catch (error) {
                            this.load = false
                            this.ALREADY_REGISTERED_US = null
                            this.ALREADY_REGISTERED_UD = null
                            this.USER_ALREADY_REGISTERED = false
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.error_0.title,
                                text:this.MBS.text.error_0.text,
                                btnYes:this.MBS.text.error_0.btnYes,
                            })
                            this.MBS.actions.error({
                                error:{
                                    error:error,
                                    phone_number:PHONE
                                },
                                from:'CHECK_USER',
                                page:PAGE_NAME, 
                            }) 
                        }
                    } else {
                        this.MBS.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.error_0.title,
                            text:this.MBS.text.error_0.text,
                            btnYes:this.MBS.text.error_0.btnYes,
                        })
                        this.MBS.actions.error({
                            error:{
                                error:'Invalid Phone Number',
                                phone_number:PHONE
                            },
                            from:'CHECK_USER',
                            page:PAGE_NAME, 
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_USER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            REVERIFY_NUMBER(){
                try {
                    this.load = true
                    let number = this.us?this.us.phoneNumber:null
                    if (number) {
                        this.select.signOption = 'Use Phone Number'
                        this.reSetRecaptach()
                        this.NEXT_STEP(2)
                        let phone = new this.MBS.phone(number)
                        let b = phone?phone.b:null
                        if(b.b){
                            this.inputPhone = b.b[2]
                            const bthVerifyNumber = this.$refs.bthVerifyNumber
                            setTimeout(() => {
                                // console.log(bthVerifyNumber);

                                // bthVerifyNumber.click(this.NEXT_STEP('number',10000)) 
                            },1000);  
                        }

                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REVERIFY_NUMBER',
                        page:PAGE_NAME, 
                    })
                }
            },  
            SIGN_PHONE_NUMBER(){
                try {
                    this.load=false
                    let phone = this.GET_PHONE_NUMBER() 
                    let self = this; 
                    var appVerifier = window.recaptchaVerifier 
                    if (!phone) {
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.error_0.title,
                            text:this.MBS.text.error_0.text,
                            btnYes:this.MBS.text.error_0.btnYes,
                            action:{
                                data:PAGE_NAME+"=RELOAD-PAGE"
                            }
                        })
                        return null
                    } else if (!appVerifier) {
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.error_0.title,
                            text:this.MBS.text.error_0.text,
                            btnYes:this.MBS.text.error_0.btnYes,
                            action:{
                                data:PAGE_NAME+"=RELOAD-PAGE"
                            }
                        })
                        return null
                    }   
                    self.MBS.actions.snackbar()
                    self.MBS.actions.progressDialog({
                        show:true, 
                        text:"Sending verification code..."
                    }) 
                    firebase.auth().signInWithPhoneNumber(phone, appVerifier).then(confirmationResult=> { 
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.snackbar() 
                        self.MBS.actions.dialog({
                            show:true,
                            title:"VERIFICATION SENT",
                            text:'Verification SMS sent to ('+phone+'). Please, check your phone.',
                            btnYes:"OK"
                        })  
                        self.confirmationResult = confirmationResult;
                        self.NEXT_STEP(3)
                    }).catch(error=>{
                        //Error; SMS not sent  
                        this.MBS.actions.error({
                            error:error,
                            from:'SIGN_PHONE_NUMBER',
                            page:PAGE_NAME, 
                        })
                        if (error.code==='auth/too-many-requests') {
                            self.MBS.actions.dialog({
                                show:true,
                                title:"ERROR",
                                text:error.message,
                                btnYes:'OK',
                                action:{
                                    code:"=GO",
                                    to:'/'
                                }
                            })  
                        } else {
                            self.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.error_0.title,
                                text:this.MBS.text.error_0.text,
                                btnYes:this.MBS.text.error_0.btnYes,
                                action:{
                                    data:PAGE_NAME+"=RELOAD-PAGE"
                                }
                            })
                        }
                        self.signProcess =0 
                    });  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SIGN_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.error_0.title,
                        text:this.MBS.text.error_0.text,
                        btnYes:this.MBS.text.error_0.btnYes,
                        action:{
                            data:PAGE_NAME+"=RELOAD-PAGE"
                        }
                    })
                }
            },
            CONFIRM_PHONE_NUMBER(){
                try {
                    let phone = this.GET_PHONE_NUMBER()
                    let self = this 
                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        text:"Verifying code.."
                    })
                    var code = this.input.phoneConfirm
                    this.confirmationResult.confirm(code)
                    .then(result=>{
                        // User signed in successfully. )
                        self.load=false
                        self.MBS.actions.progressDialog(false) 
                        //verify new user
                        let isNewUser = result.additionalUserInfo.isNewUser
                        if(isNewUser){  
                        }else{
                        }
                        self.NEXT_STEP(4,10)
                        let mbs_text = self.MBS.text.phone_number_success_confirmed(phone)
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })
                         
                    })
                    .catch(function (error) {
                            // User couldn't sign in (bad verification code?)
                            //todo
                            self.load=false
                            self.MBS.actions.progressDialog(false)
                            self.confirmRetry=self.confirmRetry++ 
                            self.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"ERROR",
                                text:error.message,
                                btnYes:"OK"
                            }) 
                            self.MBS.actions.error({
                                error:error,
                                from:'CONFIRM_PHONE_NUMBER',
                                page:PAGE_NAME, 
                            })
                        });
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            ADD_USER_PASSWORD(){
                try { 
                    let password = this.input.password
                    let password_confirm = this.input.password_confirm
                    let checked = this.MBS.actions.PASSWORD_CHECKER(password,password_confirm,true)
                    let checked_password = checked?checked.checked:null
                    if(!checked_password){return} 
                    this.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        text:"Updating password.."
                    }) 
                    this.load=true 
                    var user = this.us
                    self = this 
                    user.updatePassword(password)
                    .then(()=>{
                        self.MBS.actions.progressDialog({
                            show:true,
                            fixed:true,
                            text:"Updating password..."
                        })
                        return  firebase.database().ref('USERS/'+user.uid).update({ 
                            password_protected:true, 
                            p_uid:self.MBS.crypt.encrypt(password) 
                        })
                    })
                    .then(()=> {   
                        self.load=false
                        self.MBS.actions.progressDialog()
                        let mbs_text = self.MBS.text.update_item_success('Password')
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            action:{
                                code:"=GO",
                                to:"/account"
                            }
                        }) 
                        self.MBS.actions.go('/account') 
                        return 
                    })
                    .catch(function(error) { 
                        self.load=false
                        self.MBS.actions.progressDialog() 
                        self.MBS.actions.error({
                            error:error,
                            from:'ADD_USER_PASSWORD',
                            page:PAGE_NAME, 
                        })
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.error_0.title,
                            text:this.MBS.text.error_0.text,
                            btnYes:this.MBS.text.error_0.btnYes,
                            action:{
                                data:PAGE_NAME+"=RELOAD-PAGE"
                            }
                        })
                    }) 
                
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_USER_PASSWORD',
                        page:PAGE_NAME, 
                    })
                    this.load=false
                    this.MBS.actions.progressDialog() 
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.error_0.title,
                        text:this.MBS.text.error_0.text,
                        btnYes:this.MBS.text.error_0.btnYes,
                        action:{
                            data:PAGE_NAME+"=RELOAD-PAGE"
                        }
                    })
                }
            },  
            NEXT_STEP (s,t) {
                try {
                    if (s==='number') {
                        this.load=true
                        setTimeout(()=>{
                            if (this.load===true) {
                            this.load=false
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"NETWORK ERROR",
                                text:"Slow network is detected. Please, try again.",
                                btnYes:"OK",
                                action:{
                                    code:PAGE_NAME+"=RELOAD-PAGE"
                                }
                            }) 
                            } 
                        },t) 
                    }else if (s==='CONFIRM_PHONE_NUMBER') {
                        this.load=true
                        setTimeout(()=>{
                            this.CONFIRM_PHONE_NUMBER()
                        },t) 
                    } else {
                        this.load=true
                        setTimeout(()=>{
                            this.step=s
                            this.load=false
                        },t) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    })
                } 
            },
            CANCEL_SIGNING(){
                try { 
                    let mbs_text = this.MBS.text.action_cancel('Password Recovering')
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNoTxt,
                        action:{
                            code:"=GO",
                            to:"/"
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_SIGNING',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            
            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) { 
                        if (action.code ===PAGE_NAME+"=REGISTER-TIMEOUT") {
                            
                        } 
                        if (action.code ===PAGE_NAME+"=RELOAD-PAGE") {
                            this.MBS.actions.RELOAD_PAGE()
                        }    
                        if (action.code ===PAGE_NAME+"=REVERIFY-NUMBER") {
                            if (action.YES) { 
                                this.select.signOption = 'Use Phone Number'
                                this.REVERIFY_NUMBER()
                            } else { 
                            }
                        }

                        
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            FEEDBACKS(time =1000,event){
                try { 
                    setTimeout(()=>{
                        let us = this.us
                        let ud = this.ud
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses 
                        let i = this.iLocation 
                        
         
                        if (i) {
                            this.select.country = i.country.code+ ': '+i.country.name+' ('+i.country.callingCode+')' 
                        }else{
                            //this.$store.dispatch('setILocation')
                        }  
                        //Check process 
                        if (ps) {
                            let logging = ps.find(process=>{
                                return process.name == 'logging'
                            }) 
        
                            if (logging) {
                                this.PAGE_LOAD =false
                            }else{ 
                                this.PAGE_LOAD = true
                            }     
                        }else{ 
                            this.PAGE_LOAD = true
                        }    
                    },time) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })
                }
            },   
        },
        beforeDestroy(){
            let us = this.us 
            if (us) {
                let password = this.input.password
                let password_confirm = this.input.password_confirm
                let checked = this.MBS.actions.PASSWORD_CHECKER(password,password_confirm)
                let checked_password = checked?checked.checked:null
                if (!checked_password) {
                    this.MBS.actions.logout(true)  
                }
            } 
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch:{ 
            us(value){
                this.FEEDBACKS(1000)
            },
            processes(value){
                this.FEEDBACKS(1000)
            },
            loading(value){
                this.FEEDBACKS(1000)
            }, 
            iLocation(value){
                this.FEEDBACKS(1)
            }, 
            PAGE_LOAD(value){
                if(value){
                    this.reSetRecaptach()
                }
            }
        }
    }
</script>