<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 212.79 127.33"
  :fill="iconColor" xml:space="preserve"> 

   <path :fill="cls1" d="M151.13,0A63.41,63.41,0,0,0,108.4,16.48a63.66,63.66,0,0,1,0,94.37A63.66,63.66,0,1,0,151.13,0Z"/><path :fill="cls2" d="M85.46,63.66a63.49,63.49,0,0,1,20.93-47.18h0a63.67,63.67,0,1,0,0,94.37h0A63.53,63.53,0,0,1,85.46,63.66Z"/><path :fill="cls3"   opacity="0.7" d="M128.32,63.66A63.48,63.48,0,0,0,107.4,16.48h0a63.65,63.65,0,0,0,0,94.37h0A63.49,63.49,0,0,0,128.32,63.66Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
    data(){
      return{
        cls1:this.iconColor=='currentColor'?"#f8981d":this.iconColor,
        cls2:this.iconColor=='currentColor'?"#cb2026":this.iconColor,
        cls3:this.iconColor=='currentColor'?"#cb2026":this.iconColor,
        cls4:this.iconColor=='currentColor'?"":this.iconColor,
        cls5:this.iconColor=='currentColor'?"#22205f":this.iconColor,
      }
    },
    mounted(){
      console.log(this.iconColor,'icons,.........s');
    }
      
  }
</script>
 
