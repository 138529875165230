<template>
  <div  >
    <v-tabs v-model="model"
      grow=""
      :dark="dark == false?false:true"
      :background-color="color?color:'primary'"
      show-arrows>
          <v-tab v-for="(item, index) in allItems" 
            :key="index" :to="item.link2?item.link2:item.link" >{{item.name}} 
            <span v-if="item.indicator">
              <v-chip  small="" :color="item.indicator.color" class="ml-2">{{item.indicator.value}}</v-chip> 
            </span>
          </v-tab>   
    </v-tabs> 
  </div>
</template>

<script>
export default {
  props:["items","value","dark","color",] ,
  data (){
    return {
      tabs:[ 
        {link:"",name:"Tab 1"}, 
      ],
      model:this.value 
    }
  },
  computed: {
    phoneSize(){ 
      let  width = this.not.vs.width 
      if(width<1000){
        return true
      }else{
        return false
      }
    },
    us(){
      return this.not.us
    },
    allItems(){
      if (!this.items) {
        return this.tabs
      }else{ 
        return {...this.items}
      }
    }
  },
  methods: { 
    clicked(){}
  },
  watch: {
    model(currentValue) {
      this.$emit('input', currentValue)
    }
  }
};
</script>
