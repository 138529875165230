<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            v-model="input_table"
                            :add_vertical_lines="true"
                            :table_tabs="tableTabs"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_only_display="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"  
                            :header_items="HEADER_ITEMS" 
                            :data_items="CalculatedItems"   
                            :path_item="SOCK_ORDER_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"   
                            :table_actions="table_actions" 
                            :is_joined="true"
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.PURCHASES.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.PURCHASES.names,
                NAME_ITEM:DATA.ITEMS.PURCHASES.name,
                VALUE_ITEMS:DATA.ITEMS.PURCHASES.values,
                VALUE_ITEM:DATA.ITEMS.PURCHASES.value,  
    
                table_actions:[
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                TOTAL_ITEM_VALUES:[ 
                    // {name:'Purchases (QTY)',value:'total_quantity'},
                    {name:'Total Purchases',value:'total_purchased_price',show_value:'total_purchased_price_',money:true},
                ],
                tableTabs:[
                    {name:"Purchased Item(s)",show_if:(this_,item)=>true},
                    {name:"Aggregated Item(s)",show_if:(this_,item)=>true},  
                ],
                filter_date:null,
                filteredDate:null,
                currentTableTab:null,
                input_table:null, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("reports",2,true) 
                return thisLinks
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()], 
            }), 

            StockOrdersData(){ 
                let StockOrders = this.StockOrders 
                let JoinedStockOrders = this.JoinedStockOrders
                let items = JoinedStockOrders?JoinedStockOrders:StockOrders 
                items = items?items.filter(item=>item.order_type == DATA.ITEMS.STOCK_RECEIVES.value):null
                if(!items){return null}
                let list = [...items] 
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false))  
                return list
            },  
            moment(){  
                return this.MBS.date.moment
            }, 
            AggregatedItems(){
                let SaleOrders = this.StockOrdersData
                let moment = this.moment
                let filteredDate = this.filteredDate
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null  
                if(!SaleOrders){return null}
                let items = []
                SaleOrders.forEach(order => {
                    let date = moment(order.delivery_date).format("yyyy-MM-D")
                    let momentDate = moment(date) 
                    let isBetween = momentDate.isBetween(from_date,to_date,'','[]')   
                    if (isBetween) {
                        let unjoined_items = order.items
                        let joined_items = order.joined_items
                        let purchased_items = joined_items?joined_items:unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(purchased_items)
                        let average_discount = total_discount/item_size
                        if (purchased_items) {
                            purchased_items.forEach(item => { 
                                let index = items.findIndex(it=>{
                                    return it.item_key  == item.item_key
                                }) 
                                let purchased_quantity = this.toNumber(item.quantity)
                                let purchased_cost_price = this.toNumber(item.cost_price)*purchased_quantity
                                let purchased_discount = (this.toNumber(item.discount)*purchased_quantity)+average_discount 
                                let total_purchased_price = purchased_cost_price-purchased_discount

                                if (index == -1) { 
                                    items.push({
                                        ...item, 
                                        purchased_quantity:purchased_quantity,
                                        purchased_cost_price:purchased_cost_price,
                                        purchased_discount:purchased_discount, 
                                        total_purchased_price:total_purchased_price,
                                        number_of_orders:1,
                                         
                                        purchased_cost_price_:this.MBS.actions.money( purchased_cost_price),
                                        purchased_discount_:this.MBS.actions.money( purchased_discount), 
                                        total_purchased_price_:this.MBS.actions.money( total_purchased_price),
                                    })
                                } else {
                                    items[index].purchased_quantity = items[index].purchased_quantity+purchased_quantity
                                    items[index].purchased_cost_price = items[index].purchased_cost_price+purchased_cost_price
                                    items[index].purchased_discount = items[index].purchased_discount+purchased_discount 
                                    items[index].total_purchased_price = items[index].total_purchased_price+total_purchased_price
                                    items[index].number_of_orders = items[index].number_of_orders+1
                                    
                                    items[index].sold_quantity_ = this.MBS.actions.money( items[index].purchased_quantity)
                                    items[index].purchased_cost_price_ = this.MBS.actions.money( items[index].purchased_cost_price)
                                    items[index].purchased_discount_ = this.MBS.actions.money( items[index].purchased_discount)
                                    items[index].sold_price_ = this.MBS.actions.money( items[index].sold_price)
                                    items[index].total_purchased_price_ = this.MBS.actions.money( items[index].total_purchased_price)
                                }    
                            }); 
                        }
                    }     
                });  
                return items
            },
            PerTransactionItems(){ 
                let SaleOrders = this.StockOrdersData
                let moment = this.moment
                let filteredDate = this.filteredDate
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null
                if(!SaleOrders){return null}
                let items = []
                SaleOrders.forEach(order => {  
                    let date = moment(order.delivery_date).format("yyyy-MM-D")
                    let momentDate = moment(date) 
                    let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    if (isBetween) {
                        let unjoined_items = order.items
                        let joined_items = order.joined_items
                        let purchased_items = joined_items?joined_items:unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(purchased_items)
                        let average_discount = total_discount/item_size
                        if (purchased_items) {
                            purchased_items.forEach(item => {  
                                let purchased_quantity = this.toNumber(item.quantity)
                                let purchased_cost_price = this.toNumber(item.purchased_cost_price)*purchased_quantity
                                let purchased_discount = (this.toNumber(item.discount)*purchased_quantity)+average_discount
                                let cost_price = this.toNumber(item.cost_price)
                                let total_purchased_price = (cost_price*purchased_quantity)-purchased_discount 
                                items.push({
                                    ...item, 
                                    purchased_quantity:purchased_quantity,
                                    purchased_cost_price:purchased_cost_price,
                                    purchased_discount:purchased_discount,
                                    cost_price:cost_price,
                                    total_purchased_price:total_purchased_price,
                                     
                                    purchased_cost_price_:this.MBS.actions.money( purchased_cost_price),
                                    purchased_discount_:this.MBS.actions.money( purchased_discount),
                                    cost_price_:this.MBS.actions.money( cost_price),
                                    total_purchased_price_:this.MBS.actions.money( total_purchased_price),
                                    
                                    transaction_key:order.key,

                                    order:order,
                                    order_key:order.key,
                                    order_code:order.code,
                                    order_type:order.order_type,
                                    order_type_name:order.type_name,
                                    order_created_at_server:order.created_at_server,
                                    order_created_at_server_:order.created_at_server_,
                                    order_delivery_date:order.delivery_date,
                                    order_supplier:order.supplier,
                                    order_supplier_code:order.supplier_code,
                                    order_supplier_name:order.supplier_name,
                                    total_cost_price:(this.toNumber(item.cost_price)-this.toNumber(item.discount))*this.toNumber(item.quantity),
                                    total_cost_price_:this.MBS.actions.money((this.toNumber(item.cost_price)-this.toNumber(item.discount))*this.toNumber(item.quantity))
                                })
                                     
                            });
                            
                        }
                    }     
                });   
                return items
            },
            CalculatedItems(){
                const tap = this.currentTableTab
                const perTransaction = this.PerTransactionItems
                const aggregated = this.AggregatedItems 
                const items = tap==0?perTransaction:aggregated 

                // console.log(items,'iiiiiiiiiiiiiiii');
                return items
                if (tap==0) { 
                    return this.AggregatedItems
                }else{
                    return this.AggregatedItems
                }
            }, 
            HEADER_ITEMS1(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    // {id:0,name:"",value:'icon',color:"",size:null,show:true},   
                    // {id:0,name:"Number",value:'no',show:true}, 
                    {id:0,name:"Delivered @",value:'order_delivery_date',show:true},   
                    {id:0,name:"code",align:"left",show:true},          
                    {id:0,name:"Order",value:'order_type_name',show:false},     
                    {id:0,name:"Order Code",value:'order_code',align:"center",show:true},     
                    {id:0,name:"Supplier",value:"order_supplier_name",show:true},        
                    {id:0,name:"items",value:'name',align:'left',show:true},   
                    {id:0,name:"QTY",value:'quantity',align:'center',show:true},   
                    {id:0,name:"Discount",value:'discount',align:'center',show:true},   
                    {id:0,name:"Cost",value:'cost_price_',align:'right',show:true},   
                    {id:0,name:"Total",value:'total_cost_price_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Quotation/Invoice",action:"view_order_details",icon:"mdi-eye"}, 
                        {name:"Archive Quotation/Invoice",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            }, 
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Barcode",value:'code',show:false},           
                    {id:0,name:"item",value:"name",show:true},     
                    {id:0,name:"orders",value:"number_of_orders",align:'center',show:true},     
                    {id:0,name:"quantity",value:'purchased_quantity',align:'center',show:true},   
                    {id:0,name:"Discount",value:'purchased_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'purchased_cost_price_',align:'right',show:true},   
                    // {id:0,name:"Sale Price",value:'total_purchased_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS(){
                let tap = this.currentTableTab
                if (tap==0) {
                    return this.HEADER_ITEMS1
                }else{
                    return this.HEADER_ITEMS2
                }
            },  
            SOCK_ORDER_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_orders,true) 
                return path
            },  
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "manage_invoices") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            input_table(value){ 
                if (value) {
                    this.filteredDate = value.filter_date
                    this.currentTableTab = value.current_table_tab
                } 
            } 
        },
    }
</script>
 
