<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 500 500" 
  :fill="iconColor" xml:space="preserve">

    <path d="M207.34,188.2c0-21.74-.21-44,.07-66.22.19-15.71,12-21.34,24.39-23.69C267,91.6,302.25,84,337.85,80.68c71.34-6.53,142.25-1.6,211.87,16.47,25.51,6.63,28.94,11.34,29,37.15,0,17.92,0,35.83,0,54.4-34.63,9.43-44.86,33.62-43.9,66.4,1.1,37.74-.12,75.55.33,113.32.19,15.86-7,17.95-21,16.16-20.46-2.63-41.21-3.55-61.87-3.89-41.26-.68-82.54-.9-123.8-.43-18.23.2-36.69,1.13-54.58,4.32-16.76,3-23.15-.1-23.23-17.23-.19-38.39,0-76.79-.08-115.19C250.53,215.11,242.05,202.39,207.34,188.2Z" transform="translate(-173.59 -77.93)"/>

    <path d="M612.64,344.44c0,28.47-.26,56.94.08,85.41.24,19.66-8,33.14-26.58,40.07-12.73,4.74-25.8,8.8-38,14.64-12.38,5.92-23.74,13.94-35.84,20.48-8.58,4.64-17.26,10.38-26.56,12.17a489.45,489.45,0,0,1-186.28.3c-7.66-1.47-17.4-3.8-21.67-9.25-14.6-18.68-36.22-22-56.15-29.4-8.68-3.23-17.32-6.63-25.74-10.49C181.71,461.88,173.68,451,173.67,435q-.07-91-.08-182c0-14.8,7.49-24.3,22.06-24.35,15.74-.06,22.74,10.47,22.75,25.75,0,45.18.35,90.36-.22,135.54-.13,10.75,2.64,16.94,13.33,20.43,21.82,7.13,41.71,18.81,67.12,8.36,11.63-4.78,27.82,2.69,41.91,2.54,41.25-.46,82.48-2,123.73-3,9.22-.23,19.92-3.06,27.42.54,26.86,12.89,47.43-4.59,69.73-12.56,3.66-1.31,6.06-10.92,6.12-16.73.5-45.18.26-90.36.3-135.54,0-15.4,7.4-25.65,23.1-25.32,14.6.31,21.75,10.12,21.68,24.8,0,3.71,0,7.42,0,11.14Q612.65,304.52,612.64,344.44Z" transform="translate(-173.59 -77.93)"/>
  
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
