<template>
    <div>   
        <v-divider></v-divider>  
        <mbs-page-tab 
            :items="FULL_LINKS"/>   
        <v-divider></v-divider>  
        <v-divider></v-divider>  
        <v-divider></v-divider>  
        <v-container grid-list-xl> 
            <v-layout class="mt-1" align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <v-card :min-height="vsh/2" >   
                        <mbs-item-manager  v-model="input_table"
                            :table_title="'Employees Schedules'"
                            :table_title_icon="'mdi-clipboard-text-clock'"
                            :add_vertical_lines="true"
                            :row_click="true"
                            :row_click_action="true"
                            :show_select="false"
                            :height="null"
                            :fixed_header="false"
                            add_item_title="ADD SALARY"
                            :add_item_action="{
                                action_do:'ADD',
                                action_doing:'ADDING'
                            }"
                            :table_tabs="TABLE_TABS"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="ADDING_ITEM?ADDING_ITEM.fields:DATA.FIELDS.add_employee" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="EmployeesSchedules"  
                            :is_joined="false"  
                            :path_item="PATH_ITEM"  
                            :select_items="DataSource"   

                            :table_actions="TableActions" 
                            @ON_ITEM_SELECTED="selected_items=$event"
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                            @ADDED="ADDED"
                            @EDITED="EDITED"
                            @DELETED="DELETED"
                        />  
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  


        <!-- //DIALOGS -->
        <div> 
            <!-- add user -->
            <div v-if="dialog_add_employees_salary">
                <v-dialog v-model="dialog_add_employees_salary" persistent max-width="800px"> 
                    <v-card>
                        <v-card-title class="primary white--text" dark>
                            <span class="headline">ADD EMPLOYEE SCHEDULE</span>
                            <v-spacer></v-spacer> 
                            <v-btn icon dark @click="dialog_add_employees_salary=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text> 
                            <v-container grid-list-lg>
                                <br>
                                <form id="updateUserForm" @submit.prevent="SUBMIT_EMPLOYEE_SALARY()">
                                    <mbs-input-fields v-if="true"
                                        v-model="input"
                                        :field_items="DATA.FIELDS.add_employee_schedule"
                                        :select_items="DataSource"
                                        /> 
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text="" @click="dialog_add_employees_salary=false">CANCEL</v-btn>
                                        <v-btn color="primary" :loading="inputLoading" dark="" type="submit">Add Salary </v-btn>
                                    </v-card-actions>
                                </form> 
                            </v-container>
                            <small>Click Update button to save changes</small>
                        </v-card-text> 
                    </v-card>
                </v-dialog>  
            </div>
            <!-- edit -->
            <div v-if="dialog_edit_user">
                <v-dialog v-model="dialog_edit_user" persistent max-width="800px"> 
                    <v-card>
                        <v-card-title class="primary white--text" dark>
                            <span class="headline">UPDATE EMPLOYEE SALARY</span>
                            <v-spacer></v-spacer> 
                            <v-btn icon dark @click="dialog_edit_user=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text> 
                            <v-container grid-list-lg>
                                <br>
                                <form id="updateUserForm" @submit.prevent="UPDATE_USER()">
                                    <mbs-input-fields v-if="true"
                                        v-model="input"
                                        :field_items="DATA.FIELDS.add_employee_salary"
                                        :select_items="DataSource"
                                        /> 
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text="" @click="dialog_edit_user=false">CANCEL</v-btn>
                                    <v-btn color="primary" :loading="input.loading" dark="" type="submit">Update Employee Salary</v-btn>
                                    </v-card-actions>
                                </form> 
                            </v-container>
                            <small>Click Update button to save changes</small>
                        </v-card-text> 
                    </v-card>
                </v-dialog>  
            </div>

            <!-- //VIEW ATTENDANCE  -->
            <v-dialog
                v-model="dialog_view_attendance"
                scrollable   
                :persistent="true" :overlay="false"
                max-width="1000px"
                transition="dialog-transition" >
                <v-card rounded="lg" class="b1" >
                    <!-- //------------TITLE -->
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">
                            ATTENDANCE
                        </div> 
                        <v-spacer></v-spacer>
                        <v-btn class="ml-9" @click="dialog_view_attendance=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>

                    <v-card-text  class="pt-5">
                        <v-row>
                            <v-col cols="12" sm="5"  >
                                <v-card v-if="selected_employee?.last_attendance"  tile height="100%"  class="overflow-auto">
                                    <v-card-text>
                                        <h3>{{selected_employee?.last_attendance?.outlet_name}}</h3>
                                        <v-card outlined class=" mt-3"> 
                                            <v-card outlined class="b1 pa-2">
                                                <div class="font-weight-bold mb-2">Check In: {{MBS.date.convert(selected_employee?.last_attendance?.checkin_date,null,4)}}</div>
                                                <v-divider></v-divider>
                                                <v-list class="b1">
                                                    <v-list-item>
                                                        <v-list-item-action class="pa-0 ma-0 mr-3">
                                                            <v-avatar 
                                                                v-if="selected_employee.photoURL"
                                                                size="55"  >
                                                                <v-img :src="selected_employee.photoURL" lazy-src="https://picsum.photos/10/6?image=3" >
                                                                    <template v-slot:placeholder>
                                                                        <v-row
                                                                            class="fill-height ma-0"
                                                                            align="center"
                                                                            justify="center">
                                                                            <v-progress-circular
                                                                                indeterminate
                                                                                color="grey lighten-5"
                                                                                /> 
                                                                        </v-row>
                                                                    </template>
                                                                </v-img>
                                                            </v-avatar> 
                                                            <v-avatar v-else
                                                                size="55" 
                                                                color="secondary" class="white--text" >
                                                                <div v-if="selected_employee">
                                                                    {{MBS.actions.TEXT_UP(selected_employee?.name.slice(0,1))}} 
                                                                </div>
                                                            </v-avatar>  
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ selected_employee.name }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <span>Phone:</span> {{ selected_employee.phone_number }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle>
                                                                <span>Card:</span> {{ selected_employee.card_code }}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list> 
                                            </v-card>
                                        </v-card>
                                        <v-layout column justify-center align-center class="mt-3">
                                            <v-progress-circular
                                                :rotate="-90"
                                                :size="120"
                                                :width="15"
                                                :value="CheckTimePercent(selected_employee.last_attendance)"
                                                color="primary"
                                                >
                                                <div class="font-weight-bold ">{{CheckTime(selected_employee.last_attendance)}}</div>
                                                
                                                <!-- {{ (selected_employee.checkin_attendance.diff_minute*100/(24*60)).toFixed(2) }} -->
                                            </v-progress-circular>
                                            <v-layout class="mt-3"> 
                                                <v-btn @click="SUBMIT_CHECKOUT(selected_employee,selected_employee.checkin_attendance)" class="px-9" rounded color="primary">Check Out</v-btn>
                                            </v-layout>
                                        </v-layout>  
                                    </v-card-text>
                                </v-card>  
                            </v-col>

                            <v-col cols="12" sm="7" class="pl-0" >
                                <v-card  height="100%"   tile  >   
                                    <v-toolbar color="primary" dark dense>
                                        <v-icon class="mr-2" >mdi-calendar-text-outline</v-icon>
                                        <v-toolbar-title>History</v-toolbar-title>
                                    </v-toolbar>
                                    <v-simple-table dense class="pa-2">
                                        <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Outlet
                                                </th>
                                                <th class="text-left">
                                                    Day Shift
                                                </th>
                                                <th class="text-left">
                                                    In
                                                </th>
                                                <th class="text-left">
                                                    Out
                                                </th>
                                                <th class="text-left">
                                                    Time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="item in selected_employee?.attendances"
                                            :key="item.name"
                                            >
                                            <td class=" text-center with-divider-lr  ma-0 pa-0">{{ item?.outlet_name}}</td>
                                            <td class=" with-divider ">{{ item.operating_date_ }}</td>
                                            <td class=" with-divider ">{{ item.checkin_date_time }}</td> 
                                            <td class=" with-divider ">{{ item.checkout_date_time }}</td> 
                                            <td class=" with-divider text-right">{{ CheckTime(item) }}</td> 
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                        </v-row> 
                    </v-card-text>

                     
                </v-card> 
            </v-dialog> 
        </div> 

        <div v-if="ADDING_ITEM">
            <mbs-item-manager 
                :show="false" 
                :add_item_action="ADDING_ITEM.add_item_action"
                :edit_item_action="ADDING_ITEM.edit_item_action"
                :name_items="ADDING_ITEM.names"
                :name_item="ADDING_ITEM.name"
                :value_items="ADDING_ITEM.values"
                :value_item="ADDING_ITEM.value"
                :field_items="ADDING_ITEM.fields"   
                :data_items="ItemsData"  
                :is_joined="true" 
                :path_item="ADDING_ITEM.path"  
                :select_items="DataSource"  
                @ADDED="ADDED" 
            /> 
        </div> 
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex" 
    let PAGE_NAME = DATA.ITEMS.ITEMS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                dialog_scanned_item:false,
                dialog_view_attendance:false,
                SCANNED_ITEM:null,
                input_table:{show_select:false},
                selected_items:null,
                selected_employee:null,

                NAME_ITEMS:DATA.ITEMS.USERS.names,
                NAME_ITEM:DATA.ITEMS.USERS.name,
                VALUE_ITEMS:DATA.ITEMS.USERS.values,
                VALUE_ITEM:DATA.ITEMS.USERS.value,  
                DATA:DATA, 
                ADDING_ITEM:null,
                dialog_edit_user:false,
                dialog_add_employees_salary:false,
                input:{},
                inputLoading:false,
            }
        },
        created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                // console.log(this.SelectedCompany);
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
                this.FEEDBACKS(1000)  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },   
            FULL_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("payroll",2,true) 
                return thisLinks
            },
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_users,true) 
                return path
            },  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Users: state=> state.users[(DATA.ITEMS.USERS.values).toUpperCase()],
                // JoinedUsers: state=> state.join[(DATA.ITEMS.USERS.values).toUpperCase()], 
                CompanyUsers: state=> state.items[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],
                // JoinedCompanyUsers: state=> state.join[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()], 
                CompanyUserGroups: state=> state.users[(DATA.ITEMS.COMPANY_USER_GROUPS.values).toUpperCase()],
                JoinedCompanyUserGroups: state=> state.join[(DATA.ITEMS.COMPANY_USER_GROUPS.values).toUpperCase()],  
                Cards: state=> state.items[(DATA.ITEMS.CARDS.values).toUpperCase()],
                JoinedCards: state=> state.join[(DATA.ITEMS.CARDS.values).toUpperCase()],  
                Designations: state=> state.items[(DATA.ITEMS.DESIGNATIONS.values).toUpperCase()],
                JoinedDesignations: state=> state.join[(DATA.ITEMS.DESIGNATIONS.values).toUpperCase()], 
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                Attendances: state=> state.items[(DATA.ITEMS.ATTENDANCES.values).toUpperCase()],
                JoinedAttendances: state=> state.join[(DATA.ITEMS.ATTENDANCES.values).toUpperCase()], 
                SalaryTypes: state=> state.app['SALARY_TYPES'], 
                DaysOfWeek: state=> state.app['DAYS_OF_WEEK'], 
            }),
            DesignationsData(){ 
                return this.JoinedDesignations?this.JoinedDesignations:this.Designations
            }, 
            AttendancesData(){ 
                let items = this.JoinedAttendances?this.JoinedAttendances:this.Attendances
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("key",false))    
                return list
            }, 
            StockOutletsData(){
                let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets  
                return items 
            },
            JoinedUsers(){
                let users = this.Users
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            JoinedCompanyUsers(){
                let users = this.CompanyUsers
                let join = this.MBS.actions.JOIN_USERS(users) 
                return join
            }, 
            CompanyUsersData(){ 
                console.log(this.JoinedCompanyUsers,'....');
                return this.JoinedCompanyUsers?this.JoinedCompanyUsers:this.CompanyUsers
            }, 
            Employees(){  
                let user = this.CompanyUsersData
                if(!user){return null}
                let employees = user.filter(user=>user.designation_code)
                return employees
            }, 
            EmployeesWithSchedules(){  
                let user = this.CompanyUsersData
                if(!user){return null}
                let employees = user.filter(user=>user.schedules)
                return employees
            }, 
            EmployeesSchedules(){  
                let users = this.EmployeesWithSchedules
                
                if(!users){return null}
                let all_salaries = []
                users.forEach(user => {
                    let schedules = user?.schedules
                    let employ = {}
                    for (const day_key in schedules) {
                        let schedule = schedules[day_key]
                        let start_time = schedule?schedule.start_time:"--"
                        let end_time = schedule?schedule.end_time:"--"
                        console.log(schedule,'schedules..................................');
                        employ[day_key] = schedule
                        employ[day_key+"_"] = "Start: "+start_time+"  End: "+end_time
                    } 
                    all_salaries.push({
                        ...employ, 
                        user:user,
                        user_name:user?.name,
                        key:user?.key,
                        uid:user?.uid,
                        phone_number:user?.phone_number,
                        card_code:user?.card_code,
                        designation_name:user?.designation_name, 
                        designation_code:user?.designation_code, 
                    })
                }); 
                console.log(all_salaries,'salaries.... ........');
                return all_salaries
            }, 
            CompanyUserGroupsData(){ 
                return this.JoinedCompanyUserGroups?this.JoinedCompanyUserGroups:this.CompanyUserGroups
            }, 
            CardsData(){ 
                return this.JoinedCards?this.JoinedCards:this.Cards
            },
             
            EmployeesAttendances() {
                let employees = this.Employees;
                let attendances = this.AttendancesData;
                let moment = this.moment;
                if (!employees || !attendances) return null;

                let items = [];
                employees.forEach(employee => {
                    let attends = attendances.filter(at => at.uid === employee.uid);
                    let last_attendance = attends.length > 0 ? attends[0] : null;
                    let unclosed_attendance = [];
                    let closed_attendance = [];
                    let has_unclosed_attendance = false;
                    let day_shifts = [];
                    let day_shifts_size = 0;
                    let diff_hours = 0;
                    let diff_minutes = 0;

                    if (attends) {
                        attends.forEach(attend => {
                            //check days
                            let day = attend.day_shift
                            if(day){
                                let index  = day_shifts.indexOf(d=>d.key == day.key)
                                if(index == -1){
                                    day_shifts.push(day)
                                    day_shifts_size++
                                }else{
                                    day_shifts_size++ 
                                } 
                            }
                            let checkin_date = attend.checkin_date
                            let checkout_date = attend.checkout_date
                            diff_hours = diff_hours+moment(checkout_date).diff(checkin_date,'hours')
                            diff_minutes = diff_minutes+moment(checkout_date).diff(checkin_date,'minutes')

                            if (checkout_date) {
                                closed_attendance.push(attend);
                            } else {
                                unclosed_attendance.push(attend);
                                has_unclosed_attendance = true;
                            }
                        });
                    }

                    items.push({
                        ...employee,
                        attendances: attends,
                        last_attendance: last_attendance,
                        last_checkin_date_time: last_attendance?.checkin_date_time,
                        last_checkout_date_time: last_attendance?.checkout_date_time,
                        last_checkout_date: last_attendance?.checkout_date,
                        last_attend_time: last_attendance?.attend_time,
                        closed_attendance: closed_attendance,
                        unclosed_attendance: unclosed_attendance,
                        has_unclosed_attendance: has_unclosed_attendance,
                        day_shifts: day_shifts,
                        day_shifts_size: day_shifts_size,
                        diff_hours: diff_hours,
                        diff_minutes: diff_minutes,
                        total_house: (diff_minutes / 60).toFixed(2),
                    });
                });
 
                return items;
            },
            
            TableActions(){
                let selected_items = this.selected_items
                let input_table = this.input_table
                let show_select = input_table?input_table.show_select:false
                let main_action = [
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",btn:true,icon:'mdi-plus',action:"add_action_item",color:"secondary", outlined:false,  text:"Add Employee Schedule"},    
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ] 
                return  main_action
            },
            TABLE_TABS(){ 
                let taps = [
                    {
                        name:"Weekly",
                        show_if:(this_,item)=>true,
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{  
                                return MBS.SIZE(data)
                            }
                        }
                    },{
                        name:"Monthly",
                        show_if:(this_,item)=>false,
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{  
                                return MBS.SIZE(data)
                            }
                        }
                    },{
                        name:"Yearly",
                        show_if:(this_,item)=>false,
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{  
                                return MBS.SIZE(data)
                            }
                        }
                    },
                ] 
                return  taps
            },
            FILTERS(){ 
                return [ 
                    {
                        type:"autocomplete",value:"department_code",prepend_inner_icon:'mdi-format-list-group',clearable:true,filled:false,multiple:true
                        ,chips:true,deletable_chips:true,label:"Department",
                        select:{
                            name:'ItemDepartments',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"category_code",prepend_inner_icon:'mdi-shape-plus',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Category",
                        select:{
                            name:'ItemCategories',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"manufacturer_code",prepend_inner_icon:'mdi-factory',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Manufacturer",
                        select:{
                            name:'Manufacturers',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"item_code",search_value:"code",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Barcode",
                        select:{
                            name:'Items',
                            value:'code',
                            text:'code'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Item Name",
                        select:{
                            name:'Items',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    }, 
                    
                        
                ]
            }, 
            HEADER_ITEMS(){ 
                let days = this.DaysOfWeek
                console.log(days,'*********--------------------');
                let headers = [
                    {id:1,name:"General",title:true},
                    {id:0,name:"image",align:"center",progress:(this_,item)=>item.has_unclosed_attendance,show:true}, 
                    {id:0,name:"Employee",value:'user_name',show:true}, 
                    {id:0,name:"Phone",value:'phone_number',show:false},   
                    {id:0,name:"Designation",value:'designation_name',show:false},         
                    {id:0,name:"Card",value:'card_code',show:false},      
                ] 

                days.forEach(day => {
                    headers.push({
                        id:0,
                        name:day.name,
                        value:day.key+"_",
                        align:"right",
                        show:true
                    })
                });
                headers.push({
                    id:0,name:"action",align:"center",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Remove "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]
                }) 
                return headers 
            },  
            DataSource(){ 
                let Users = this.JoinedUsers
                let CompanyUsers = this.CompanyUsersData
                let UserGroups = this.CompanyUserGroupsData 
                let Cards = this.CardsData 
                let Designations = this.DesignationsData 
                let SalaryTypes = this.SalaryTypes 
                let DaysOfWeek = this.DaysOfWeek 
                let StockOutlets = [{name:"All",code:'all',key:"all"}]
                if (this.StockOutletsData ) {
                    StockOutlets.push(...this.StockOutletsData )
                }
                return  {   
                    Users: Users, 
                    CompanyUsers: CompanyUsers, 
                    UserGroups: UserGroups, 
                    Cards: Cards, 
                    Designations: Designations, 
                    Outlets: StockOutlets, 
                    SalaryTypes: SalaryTypes, 
                    DaysOfWeek: DaysOfWeek, 
                }
            },
            moment(){ 
                return this.MBS.date.moment
            }, 
            liveTime(){
                let time = this.MBS.date.live 
                return time
            },
            CheckTimePercent(){  
                return attendance=>{ 
                    let live = this.liveTime
                    let moment = this.MBS.date.moment
                    let checkin_date = attendance?attendance.checkin_date:null 
                    let diff_hours = moment(live).diff(checkin_date,'hours')
                    let diff_minute = moment(live).diff(checkin_date,'minutes')
                    return (diff_minute*100/(24*60)).toFixed(2) 
                } 
            },
            CheckTime(){  
                return attendance=>{ 
                    if(!attendance){return ''}
                    let live = attendance.checkout_date? attendance.checkout_date:this.liveTime
                    let moment = this.MBS.date.moment
                    let checkin_date = attendance?attendance.checkin_date:null 
                    let diff_hours = moment(live).diff(checkin_date,'hours')
                    let diff_minute = moment(live).diff(checkin_date,'minutes')
                    let diff_seconds = moment(live).diff(checkin_date,'seconds')
                    let sec = diff_seconds - (diff_minute*60)
                    let min = diff_minute - (diff_hours*60)
                    return ''+diff_hours+'h '+min+'m \n\n'+sec+'s'
                } 
            },
        },
        methods: {  
            TABLE_ACTION(action,item){
                try { 
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "add_action_item") {
                        this.ADD_EMPLOYEE_SALARY()
                    }else if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Employees Data","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ITEM_ACTION(action,item,option){
                try {     
                    console.log(action,'***************');
                    if (action == "on_row_clicked") {
                        // this.VIEW_SELECTED_EMPLOYEE(item)
                    }else if (action == "edit_action_item") {
                        this.EDIT_USER(item)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            VIEW_SELECTED_EMPLOYEE(item){
                try {      
                    this.selected_employee = item
                    this.dialog_view_attendance = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_SELECTED_EMPLOYEE',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CANCEL_SELECTED_ITEMS(items){
                try {     
                    console.log('oo...............');
                    this.MBS.events.$emit('CANCEL_SELECTED_ITEMS')
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_SELECTED_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CREATE_ITEM(input,barcode){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'add',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{
                                name:input,
                                code:barcode,
                            }
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM(item){
                try {      
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.edit_item_action =  {
                        action_do:'Edit',
                        action_doing:'Editing'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item,
                            // input:{
                            //     name:input,
                            //     code:barcode,
                            // }
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ITEM_ADD_KITS(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEM_KITS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.Add_items_kit
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEM_KITS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ADD_KITS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM_PRICE(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.edit_item_price
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM_PRICE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ITEM_DEPARTMENT_AND_CATEGORY(item){
                try {    
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.edit_items_department_and_category
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM_DEPARTMENT_AND_CATEGORY',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADDED(res){
                try { 
                    this.RELOAD_COMPANY_USERS()
                    //a
                    console.log(res,'--------------added');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDITED(res){
                try {
                    console.log(res,'--------------edited');
                    // this.RELOAD_COMPANY_USERS()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CREATE_SCANNED_ITEM(code){
                try {
                    this.dialog_scanned_item = false
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{code:code}
                        })  
                    }, 50);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_SCANNED_ITEM(item){
                try {
                    this.dialog_scanned_item = false
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"edit_item",
                            item:item
                        })  
                    }, 50);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETED(res){
                try {
                    this.RELOAD_COMPANY_USERS()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RELOAD_COMPANY_USERS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?.company_key
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.COMPANY_USERS.values,
                        on:true, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.COMPANY_USERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
            RELOAD_PAUSE_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"off_refs..."
                    })
                    this.$store.dispatch('off_refs',{
                        name:DATA.ITEMS.ITEMS.values,
                        on:true, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'off_refs res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
            SELECT_SCANNED_ITEM(item_key, item_code){
                try {  
                    let items = this.AllItems 
                    this.SCANNED_ITEM = {code:item_code,key:item_key}
                    if (items) { 
                        let item_selected = items.find(item=>{
                            return  item_key?item.key == item_key:item.code == item_code
                        })
                        if (!item_selected) {
                            this.SCANNED_ITEM.item_code_not_found = true
                        } else {
                            this.SCANNED_ITEM = item_selected
                        }
                        this.dialog_scanned_item = true
                        console.log(item_selected,"item_selected.........."); 
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_SCANNED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
 
            ADD_EMPLOYEE_SALARY(){
                try {    
                    this.input = {}
                    this.inputLoading = false
                    this.dialog_add_employees_salary = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_EMPLOYEE_SALARY',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SUBMIT_EMPLOYEE_SALARY(){
                try {    
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else { 
                        let input = this.input
                        let user = input?input.user:null
                        let key = user?user.key:null 
                        let day_key = input?input.day_key:null 
                        let start_time = input?input.start_time:null 
                        let end_time = input?input.end_time:null  
                        let description = input?input.description:null   

                        let selectedCompany = this.selectedCompany
                        let company_key = selectedCompany?selectedCompany.key:null
                        let user_details = { 
                            key:key,
                            uid:key,
                            day_key:day_key, 
                            start_time:start_time, 
                            end_time:end_time, 
                            company_key:company_key
                        }
                        if (description) {
                            user_details.description=description
                        }
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_users+key+"/schedules/"+day_key,true) 
                        let mbs_text = this.MBS.text.item_action("Salary","Add")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=UPDATE-USER",
                                data:user_details,
                                path:path,
                            }
                        })  
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_EMPLOYEE_SALARY',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            EDIT_USER(user){
                try {    
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else {  
                        this.inputCode = null
                        this.input = {}  
                        this.input.company_user_group_code = user.company_user_group?user.company_user_group.code:'' 
                        this.input.key = user.key  
                        this.input.company_key = user.company_key  
                        this.dialog_edit_user= true 

                        try {
                            this.input.password = user.p_uid?this.MBS.crypt.decrypt(user.p_uid):null
                            this.input.p_uid = user.p_uid 
                            this.input.confirm_password = user.p_uid?this.MBS.crypt.decrypt(user.p_uid):null
                        } catch (error) {
                            this.MBS.actions.error({
                                error:{
                                    error:error,
                                    data:user,
                                    type:'Password error',
                                },
                                from:'EDIT_USER',
                                page:PAGE_NAME, 
                            }) 
                        }
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            DELETE_USER(user){
                try {    
                    let selectedCompany = this.selectedCompany
                    let company_key = selectedCompany?selectedCompany.key:null
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else {  
                        let key = user.key  
                        if (!key) {
                            let mbs_text = this.MBS.text.error_0
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes, 
                            })  
                        } else {
                            this.input.check = true
                            let mbs_text = this.MBS.text.delete_item('user')
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes,
                                btnNo:mbs_text.btnNo,
                                action:{
                                    code:PAGE_NAME+"=CONFIRM-DELETE-USER",
                                    data:{
                                        ...user,
                                        company_key:company_key
                                    }
                                }
                            })  
                        }
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            ADMIN_RIGHT(user){
                try {
                    let admin = user.admin
                    let title = ''
                    let text = ''
                    let btnYes = ''
                    let btnNo = 'Cancel'
                    if (admin) {
                        title = "REMOVE ADMIN RIGHT"
                        text = "Are you sure you want to remove admin right for the user ("+user.phone_number+")?"
                        btnYes = 'REMOVE'
                    } else {
                        title = "ADD ADMIN RIGHT"
                        text = "Are you sure you want to add admin right for the user ("+user.phone_number+")?"
                        btnYes = 'ADD'
                    }
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:title,
                        text:text,
                        btnNo:btnNo,
                        btnYes:btnYes

                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ADMIN',
                        page:PAGE_NAME, 
                    }) 
                }
            },  
            UPDATE_USER(){  
                try {   
                    let key = this.input.key 
                    let company_user_group_code = this.input.company_user_group_code 
                    let company_key = this.input.company_key 
                    if (!company_user_group_code) {
                        let mbs_text = this.MBS.text.add_item_error_not_selected('User Group')
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })   
                    }else { 
                        let data = {
                            key:key, 
                            company_user_group_code:company_user_group_code, 
                            company_key:company_key, 
                        }   
                        let mbs_text = this.MBS.text.update_item('user')
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=UPDATE-USER",
                                data:data
                            }
                        })   
                    }
                } catch (error) { 
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_USER',
                        page:PAGE_NAME, 
                    })
                }
            },
            

            async DELETE_ALL_ITEMS(){
                try {
                    let items = this.ItemsData
                    let size = this.MBS.actions.SIZE(items)
                    let progress = 0

                     
                    if(!items){
                        console.log(items,size,"no items.......");
                    } 
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Deleting ("+size+"/"+progress+") ..."
                    })

                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)

                    
                    for (const item of items) {
                        let res = await this.$store.dispatch('fi_delete',{
                            action:DATA.ITEMS.ITEMS.values+"__", 
                            path:path+item.key, 
                        }) 
                        progress = progress+1
                        this.MBS.actions.progressDialog({ 
                            show:true,
                            text:"Deleting ("+size+"/"+progress+") ..."
                        })
                        console.log(res,'res....');
                    }
                    this.MBS.actions.progressDialog()
                } catch (error) {
                    
                }

            },
            
            //---------------[MAIN FUNCTIONS]------------ 
            ON_QR_SCAN(code){
                try {
                    if (code) { 
                        //check cashup
                        // if (!this.LocalCashup) { 
                        //     this.MBS.actions.dialog({
                        //         show:true,
                        //         fixed:true,
                        //         title:"OPEN REGISTER FIRST",
                        //         text:"You must open the Register first before you can scan.",
                        //         btnYes:"OK"
                        //     })
                        //     return
                        // }
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 

                        }else{
                            this.SELECT_SCANNED_ITEM(null,code) 
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_COMPANY_USERS()
                            } else {
                                
                            }
                        }
                        if (action.code ===PAGE_NAME+'=ADD-USER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.inputLoading = true  
                                    this.$store.dispatch("fi_update",{ 
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM+action.data.key,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false  
                                        this.dialog_add_employees_salary = false  
                                        console.log(res,'res....');
                                    }).catch(error=>{
                                        console.log(error,'errr.....');
                                        this.inputLoading = false  
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            } 
                        } 
                        if (action.code ===PAGE_NAME+'=UPDATE-USER') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.inputLoading = true  
                                    this.$store.dispatch("fi_update",{ 
                                        action:this.VALUE_ITEM,
                                        path:action.path,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false  
                                        this.dialog_add_employees_salary = false  
                                        console.log(res,'res....');
                                    }).catch(error=>{
                                        console.log(error,'err.....');
                                        this.inputLoading = false  
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            } 
                        } 
                        if (action.code ===PAGE_NAME+'=CONFIRM-DELETE-USER') {
                            if (this.input.check) {  
                                this.input.check = false
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = true 
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm deleting this USER",btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=DELETE-USER",
                                            }
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } else {
                                    
                                }
                            }  
                        }   
                        if (action.code ===PAGE_NAME+'=DELETE-USER') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(async() => { 
                                        this.input.check = false
                                        this.input.loading = true 
                                        let password = this.MBS.crypt.encrypt(action.password) 
                                        if (password!==this.ud.p_uid) {
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                            this.input.loading = true 
                                            this.$store.dispatch("fi_delete",{ 
                                                action:this.VALUE_ITEM,
                                                path:this.Path+action.data.key  
                                            }).then(res=>{
                                                return this.$store.dispatch("fi_delete",{ 
                                                    action:this.VALUE_ITEM,
                                                    path:"/USERS/"+action.data.key+"/companies/"+action.data.company_key 
                                                })
                                            }).then(res=>{
                                                this.inputLoading = false  
                                                this.dialog_add_employees_salary = false  
                                                console.log(res,'res....');
                                            }).catch(error=>{
                                                console.log(error,'errr.....');
                                                this.inputLoading = false  
                                            })
                                        } 
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            if (!gettingItems) {
                                this.PAGE_LOAD =true
                            }  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch: {  
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            }, 
            selected_items(value){
                console.log(value,'selected_items.................');
            }, 
        },
    }
</script>
 
