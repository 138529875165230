<template>
    <div>   
        <v-container grid-list-xl>  
            <v-card class="mb-5 mt- pb-5" color="transparent" flat >  
                <v-toolbar  rounded="" outlined    color="primary" class="m-2" dark>  
                    <template >
                        <v-icon class="mr-2">mdi-receipt</v-icon>
                        <v-toolbar-title>Quotation: {{id}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-divider vertical></v-divider> 

                        <v-btn 
                            icon @click="ON_PDF()">
                            <v-icon>mdi-file-pdf-box </v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_EXCEL()">
                            <v-icon>mdi-microsoft-excel </v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_EMAIL()">
                            <v-icon>mdi-email-outline</v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_PRINTING()">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>

                <v-layout justify-center class="ma-0 pa-9" > 
                    <mbs-pdf-sales-temp1 v-if="QuotationSettings?
                        QuotationSettings.display_options=='pdf_view'?true:false
                        :false"
                        :title="auto_print.print_title" 
                        :data="ThisSaleOrder" /> 
                    <!-- <mbs-invoice-temp1 v-else
                        :title="auto_print.print_title" 
                        :data="ThisSaleOrder" />   -->
                    <mbs-sales-temp1 v-else
                        :title="auto_print.print_title" 
                        :data="ThisSaleOrder" />  
                </v-layout>
            </v-card>
        </v-container>   
        <mbs-auto-print
            v-if="auto_print.printing"
            @finish_print="FINISH_PRINT"
            :show="true"  
            :auto_print="auto_print.auto"
            :printer_name="auto_print.printer_name"
            :type="auto_print.print_type" 
            :title="auto_print.print_title" 
            :data="auto_print.print_data" 
            :display_option="auto_print.display_option" 
            />  
    </div> 
</template>

<script>
    import DATA from '../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.QUOTATIONS.values    
    export default {
        name:PAGE_NAME, 
        props:['id','type'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
 

                auto_print:{
                    auto:false,
                    printing:false, 
                    print_data:null,
                    print_type:'quotation',
                    print_title:'QUOTATION',
                    com_printer:false, 
                    printer_name:null,
                    printing_action:null, 
                    display_option:'', 
                },   
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },    
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("sale",1,true) 
                return thisLinks
            }, 
            QUOTATION_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.issued_quotations,true) 
                return path
            },  
             
            //

            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({ 
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Quotations: state=> state.items[(DATA.ITEMS.QUOTATIONS.values).toUpperCase()],
                JoinedQuotations: state=> state.join[(DATA.ITEMS.QUOTATIONS.values).toUpperCase()],  
                LocalQuotations: state=> state.items[(DATA.ITEMS.LOCAL_QUOTATIONS.values).toUpperCase()],
                JoinedLocalQuotations: state=> state.join[(DATA.ITEMS.LOCAL_QUOTATIONS.values).toUpperCase()],
            }), 
            QuotationsData(){
                let Quotations = this.Quotations
                let JoinedQuotations = this.JoinedQuotations
                return JoinedQuotations?JoinedQuotations:Quotations
            },
            LocalQuotationsData(){  
                const LocalQuotations = this.LocalQuotations
                const JoinedLocalQuotations = this.JoinedLocalQuotations
                let items = JoinedLocalQuotations?JoinedLocalQuotations:LocalQuotations  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            BothSaleOrders(){
                let orders = this.QuotationsData
                let localOrders = this.LocalQuotationsData
                console.log(orders,'list....');
                console.log(localOrders,'list....');
                if(!orders && !localOrders){return null}
                if(orders && !localOrders){return orders}
                if(!orders && localOrders){return localOrders}

                let both = [...orders]
                localOrders.forEach(element => {
                    let item = orders.find(item=>{
                        return item.key == element.key
                    })
                    if (!item) {
                        both.push(element)
                    } 
                });
                let list = [...both]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                
                return list
            },
            ThisSaleOrder(){ 
                let orders = this.BothSaleOrders
                if(!orders || !this.id){return null}
                let items = orders.find(order=>{
                    return order.key == this.id
                })
                return items
            },  
            QuotationSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null 
                let quotation = template_settings?template_settings.quotation:null 
                console.log(settings,'........');
                return quotation
            }, 
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "order_stock") {
                        let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/sales-return",true)
                        this.MBS.actions.go(link)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FINISH_PRINT(action,payload){
                try { 
                    let canceled = payload?payload.canceled:null
                    if (canceled) {
                        
                    }else{
                        setTimeout(() => {
                                // this.MBS.actions.go(-1)   
                        }, 1); 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            ON_PDF(){
                try { 
                    this.MBS.events.$emit("PRINT_PDF",{
                        save:true
                    })
                    return
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_PRINTING(){
                try { 
                    let lastSale = this.ThisSaleOrder 
                    let printer = this.CurrentReceiptPrinter
                    let printer_name = printer?printer.name:null
                    let printer_type_name = printer?printer.printer_type_name:null 
                    this.auto_print.printing = false
                    this.auto_print.display_option = this.QuotationSettings?this.QuotationSettings.display_options=='pdf_view'?'pdf':'':''
                    setTimeout(() => {
                        if (printer_type_name) {
                            // this.auto_print.auto = true
                        }
                        this.auto_print.printer_name = printer_name
                        this.auto_print.print_data = lastSale 
                        this.auto_print.printing = true   
                    }, 5); 
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
