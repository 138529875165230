<template>
  <v-layout height="600" class="">    
    <v-layout v-if="false">
        <mbs-page-spinner :show="true" />   
    </v-layout>     
    <v-container v-else grid-list-lg>  
        <v-row class="mt-10">
            <v-col cols="12">
                <v-btn @click="ADD_OLD_DATA()"  width="100%" large="" color="primary">START</v-btn>
            </v-col>
            <v-col cols="12">
                <v-btn @click="DOWNLOAD_CASHUP()"  width="100%" large="" color="primary">Convert Cashups</v-btn>
            </v-col>
            <v-col  cols="12">
                <v-btn @click="DOWNLOAD_STOCK_ORDERS()" width="100%" large="" color="primary">Convert Stock Orders</v-btn>
            </v-col>
            
            <v-col  cols="12">
                <v-btn @click="DOWNLOAD_SALE_ORDERS()" width="100%" large="" color="primary">Convert Sales Orders</v-btn>
            </v-col>
        </v-row>
    </v-container>  
  </v-layout> 
</template>

<script>
import DATA from '../../../plugins/DATA' 
import firebase from 'firebase/app' 
import 'firebase/database'
import {mapState,mapGetters } from "vuex"
let PAGE_NAME = "OLD-DATA"
    export default {
        name:PAGE_NAME, 
        data() {
        return {
            PAGE_SHOW:false, 
            DATA:DATA,
            show:false,
            load: false,   
            CASHUPS_DATA: null,    
        }
        },
        created(){ 
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                })
            }
        },
        mounted() {
            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500)
             
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                SalesOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                DayShiftsSales: state=> state.app[(DATA.ITEMS.DAYS_SHIFTS_SALES.values).toUpperCase()], 
                Cashups: state=> state.app[(DATA.ITEMS.CASHUPS.values).toUpperCase()], 
            }), 
            
        },
        methods: {  
            CONVERT_CASHUP(){
                try {
                    let mbs_text = this.MBS.text.item_action("Cashup","Convert")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CANCEL-IMPORT"
                        }
                    })   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONVERT_CASHUP',
                        page:PAGE_NAME, 
                    })
                } 
            },  
            ADD_OLD_DATA(){
                try {
                    let old_data = DATA.PATHS.old_data 
                    let old_data_path = this.MBS.actions.COMPANY_LINK(old_data,true)
                    console.log(old_data_path,"---------[begin]-------------");
                    let details = {
                        CASHUPS:"11",
                        SALE_ORDERS:"11",
                        STOCK_ORDERS:"11",
                    }
                    let ref = firebase.database().ref(old_data_path) 
                    ref.update(details)
                    .then(res=>{
                        console.log(res,"res.......");
                    }).catch(error=>{
                        console.log(error,"error......");
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONVERT_CASHUP',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            DOWNLOAD_CASHUP(){
                try {
                    let old_data = DATA.PATHS.old_data+this.MBS.actions.TEXT_UP(DATA.ITEMS.CASHUPS.values)+"/"
                    let old_data_path = this.MBS.actions.COMPANY_LINK(old_data,true)
                    console.log(old_data_path,"---------[begin]-------------");
                    let ref = firebase.database().ref(old_data_path) 
                    ref.once('value').then(DataSnapshot=>{
                        let data = DataSnapshot.val()
                        console.log(data,'----------[Starts]-----------');
                        for (const key in data) {
                            let cashup = data[key] 
                            let cashup_key = key
                            let day_shift_key = cashup.day_shift_key 
                            let cashup_path = this.MBS.actions.COMPANY_LINK(
                                DATA.PATHS.days_shifts+day_shift_key+"/cashups/"+cashup_key,true)
                            let cashup_ref = firebase.database().ref(cashup_path)
                            cashup_ref.set(cashup)
                            .then(res=>{
                                let old_cashup_ref = firebase.database().ref(old_data_path+cashup_key)
                               return old_cashup_ref.set(null)
                            }).then(res=>{
                                console.log(res,cashup_key,'c..........');
                            }).catch(error=>{
                                console.log(error,cashup_key,'e............');
                            }) 
                        }
                        console.log("------[dane]-----------");


                    }).catch(error=>{
                        console.log(error,'error.....');
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONVERT_CASHUP',
                        page:PAGE_NAME, 
                    })
                } 
            },  
            DOWNLOAD_STOCK_ORDERS(){
                try {
                    let old_data = DATA.PATHS.old_data+this.MBS.actions.TEXT_UP(DATA.ITEMS.STOCK_ORDERS.values)+"/"
                    let old_data_path = this.MBS.actions.COMPANY_LINK(old_data,true)
                    console.log(old_data_path,"---------[begin]-------------");
                    let ref = firebase.database().ref(old_data_path) 
                    ref.once('value').then(DataSnapshot=>{
                        let data = DataSnapshot.val()
                        console.log(data,'----------[Starts]-----------');
                        for (const key in data) {
                            let order = data[key] 
                            let order_key = key 
                            let order_path = this.MBS.actions.COMPANY_LINK(
                                DATA.PATHS.stock_orders+order_key,true)
                            let order_ref = firebase.database().ref(order_path)
                            order_ref.set(order)
                            .then(res=>{
                                let old_order_ref = firebase.database().ref(old_data_path+order_key)
                               return old_order_ref.set(null)
                            }).then(res=>{
                                console.log(res,order_key,'c..........');
                            }).catch(error=>{
                                console.log(error,order_key,'e............');
                            }) 
                        }
                        console.log("------[dane]-----------");


                    }).catch(error=>{
                        console.log(error,'error.....');
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONVERT_CASHUP',
                        page:PAGE_NAME, 
                    })
                } 
            },  
            DOWNLOAD_SALE_ORDERS(){
                try {
                    let old_data = DATA.PATHS.old_data+this.MBS.actions.TEXT_UP(DATA.ITEMS.SALE_ORDERS.values)+"/"
                    let old_data_path = this.MBS.actions.COMPANY_LINK(old_data,true)
                    console.log(old_data_path,"---------[begin]-------------");
                    let ref = firebase.database().ref(old_data_path) 
                    ref.once('value').then(DataSnapshot=>{
                        let data = DataSnapshot.val()
                        console.log(data,'----------[Starts]-----------');
                        for (const key in data) {
                            let order = data[key] 
                            let order_key = key 
                            let day_shift_key = order.day_shift_key
                            let order_path = this.MBS.actions.COMPANY_LINK(
                                DATA.PATHS.days_shifts_sales+day_shift_key+"/"+order_key,true)
                            let order_ref = firebase.database().ref(order_path)
                            order_ref.set(order)
                            .then(res=>{
                                let old_order_ref = firebase.database().ref(old_data_path+order_key)
                               return old_order_ref.set(null)
                            }).then(res=>{
                                console.log(res,order_key,'c..........');
                            }).catch(error=>{
                                console.log(error,order_key,'e........***********************....');
                            }) 
                        }
                        console.log("------[dane]-----------");


                    }).catch(error=>{
                        console.log(error,'error.....');
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONVERT_CASHUP',
                        page:PAGE_NAME, 
                    })
                } 
            },  
            
            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) {   
                        if (action.code ===PAGE_NAME+"=CANCEL-IMPORT") {
                            if (action.YES) {
                                this.REST_DATA()
                            } else { 
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{ 
                            this.PAGE_SHOW =true
                        }     
                    }else{ 
                    }

                     
                },time)
            }
        },
        beforeDestroy(){ 
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            importUpdateOption(value){ 
                if (this.importOverwriteOption == value) {
                    this.importOverwriteOption = !value
                }
            },
            importOverwriteOption(value){ 
                if (this.importUpdateOption == value) {
                    this.importUpdateOption = !value
                }
            }
        },
  }
</script>
 
