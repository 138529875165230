<template>
    <div>   
        <v-divider color=""></v-divider>
        <mbs-page-tab 
        color="primary "
            :items="MY_LINKS"/> 
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider></v-divider> 
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <v-card hover> 
                        <v-card color="primary"  class="pb-2" outlined> 
                            <v-card flat
                                class="pa-3"
                                color="grey lighten-2"  p  >
                                <v-layout class="ma-0 pa-2" align-center> 
                                    <v-col>
                                        <v-card min-height="150" flat color="transparent" > 
                                            <div class="font-weight-bold not-f3 grey--text text--darken-4">Your Plan</div>
                                            <div class="font-weight-bold not-f8 primary--text">
                                                <span v-if="CurrentBillingPlanData">{{CurrentBillingPlanDetails.name}}</span>
                                                <span v-else>Kipcount Free</span>
                                            </div> 
                                            <v-subheader class="ma-0 pa-0">Features</v-subheader>
                                            <div v-if="!CurrentBillingPlanData">
                                                <v-layout class="ml-2" >
                                                    <v-icon>check</v-icon>
                                                    <span>Unremitted Number Users</span>
                                                </v-layout>
                                                <v-layout class="ml-2" >
                                                    <v-icon>check</v-icon>
                                                    <span>Warehouse</span>
                                                </v-layout> 
                                            </div>
                                            <div class="mb-1" v-else v-for="(plan,index) in CurrentBillingPlanDetails.join_billing_plan_features">
                                                <v-layout class="ml-2 " >
                                                    <v-icon>check</v-icon>
                                                    <span>{{plan.name}}</span>
                                                </v-layout> 
                                            </div>
                                            <v-layout v-if="CurrentBillingPlanData" class="ma-0 mt-4">
                                                <v-btn @click="VIEW_SELECTED_PLAN(CurrentBillingPlanDetails)"   small color="primary" rounded>Explore Plan to view more Features</v-btn>
                                            </v-layout> 
                                        </v-card> 
                                    </v-col> 


                                    <div class="text-right">  
                                        <div class="grey--text text--darken-2  " >
                                            <span class="font-weight-bold not-f4 mr-1">MWK</span>
                                            <span v-if="!CurrentBillingPlanData" class="font-weight-bold not-f8">{{ MBS.actions.money(0) }}</span>
                                            <span v-else class="font-weight-bold not-f8">{{ MBS.actions.money(LivePaymentBalance) }}</span>
                                        </div>
                                        <div class="font-weight-bold font-italic not-f3 secondary--text text--darken-1">BALANCE</div>
                                        
                                        <v-card hover @click="UPGRADE_PLAN(CurrentBillingPlanData)">
                                            <v-layout ma-0 mt-5 v-if="CurrentBillingPlanData" > 
                                                <div class=" mbs-border" :style="'--size:1px; --conner:5px; --color:'+MBS.data.color.primary+';'" > 
                                                    <v-layout v-if="PlanExpired(CurrentBillingPlanData)" class="ma-0 px-4 py-2" align-center  >
                                                        <div class="font-weight-bold not-f6 error--text">Expired On:</div>
                                                        <div  
                                                            class="font-weight-bold font-italic not-f3 grey--text text--darken-2 ml-1 mt-1">
                                                            {{MBS.date.convert(CurrentBillingPlanData.expiring_date,null,2)}}
                                                        </div> 
                                                    </v-layout>
                                                    <v-layout v-else class="ma-0 px-4 py-2" align-center> 
                                                        <div class="font-weight-bold not-f7 mr-2">Expire:</div>
                                                        <div class="text-left">
                                                            <div 
                                                                class="font-weight-bold font-italic not-f3 grey--text text--darken-2">
                                                                On: {{MBS.date.convert(CurrentBillingPlanData.expiring_date,null,4)}}
                                                            </div>
                                                            
                                                            <div  
                                                            class="font-weight-bold font-italic not-f3 grey--text text--darken-2">
                                                                In: {{LiveExpiringDate}}
                                                            </div> 
                                                        </div>
                                                    </v-layout>
                                                    <v-divider></v-divider>
                                                    <v-layout v-if="CurrentBillingPlanData" class="ma-0 primary px-4 py-2 white--text" align-center> 
                                                        <div class="font-weight-bold ">
                                                            <span class="font-weight-bold not-f  mr-2">Monthly Price:</span>
                                                            <span>MWK</span>
                                                            <span class="not-f7 ml-2">
                                                                {{MBS.actions.money(CurrentBillingPlanData.total_monthly_price)}}
                                                            </span> 
                                                        </div>
                                                    </v-layout>
                                                </div>
                                            </v-layout> 
                                        </v-card>
                                    </div>
                                </v-layout>
        
                                <v-layout row wrap class="ma-0 pa-2">
                                    <!-- <v-spacer></v-spacer> -->
                                    <v-btn v-if="!CurrentBillingPlanData" @click="VIEW_BILLING_PLANS()"  min-width="150" color="primary" class="mr-1"  
                                    outlined tile>Subscribe</v-btn>
                                    <v-btn v-if="CurrentBillingPlanData" @click="VIEW_BILLING_PLANS()"  min-width="150" color="primary" class="mr-1"  
                                    outlined tile>Change Plan</v-btn>
                                    <v-btn v-if="CurrentBillingPlanData" @click="UPGRADE_PLAN(CurrentBillingPlanData)" min-width="150" color="primary"  tile>Upgrade</v-btn>
                                </v-layout>
                            </v-card>
                        </v-card>
                    </v-card>

                    <br>
                    <v-card v-if="true" > 
                        <mbs-item-manager
                            table_title="Payment History"
                            table_title_icon="card_membership"
                            :add_vertical_lines="true"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="DATA.FIELDS.tills"
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="SubscriptionsData"   
                            :is_joined="true"
                            :path_item="PATH_ITEM"  
                            :select_items="Selectors"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>

            <!--Billing Plans -->
            <v-dialog
                v-model="dialog_billing_plans"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="1200px"
                transition="dialog-transition" >
                <v-card class="b1" rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">BILLING PLANS.</div> 
                        <v-spacer></v-spacer> 
                        <v-btn class="mr-2" color="secondary"  dark @click="REFRESH"><v-icon>refresh</v-icon>Refresh</v-btn>
                        <v-btn icon  dark @click="dialog_billing_plans=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text v-if="MBS.actions.SIZE(BillingPlansData)" >
                        <br>
                        <v-row>
                            <v-col v-for="(plan,index) in BillingPlansData" :key="index" cols="12" sm="6" md="4">
                                <v-card height="100%" min-height="500" hover class="primary pb-2" outlined rounded="lg"> 
                                    <v-card height="100%" class="pa-3" >
                                        <v-layout class="pt-2" column fill-height> 
                                            <v-card class="" flat rounded="lg">

                                                <v-layout class="ma-0 py-2 font-weight-bold " align-center > 
                                                    <span class="not-f7 grey--text text--darken-3">{{plan.name}}</span> 
                                                    <v-spacer></v-spacer> 
                                                </v-layout>
                                            </v-card>
                                             
                                            <v-card class="mt-5 pa-2 primary" dark outlined> 
                                                <v-layout class="ma-0" align-center >
                                                    <div>
                                                        <div class="font-weight-bold">Period </div>
                                                        <div class="not-f3 secondary--text text--lighten-2">(+{{   plan.monthly_price  }}/mo)</div>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="REMOVE_MONTH(plan)"  icon><v-icon size="18">mdi-minus-circle</v-icon></v-btn>
                                                    <span class="font-weight-bold not-f5">{{InputPayingMonths(plan) }}</span>
                                                    <v-btn @click="ADD_MONTH(plan)" icon><v-icon size="18">mdi-plus-circle</v-icon></v-btn>
                                                </v-layout>
                                            </v-card>
                                            <div class="mx-1">

                                                <div class="mt-8   font-weight-bold">
                                                    <span class="not-f3">MWK</span>
                                                    <span class=" not-f8">{{ MBS.actions.money(TotalPlanPayingPrice(plan)) }}</span>  for
                                                    <span class="font-italic not-f4 secondary--text text--darken-3">{{InputPayingMonths(plan)}} Month(s)</span>
                                                </div>
    
                                                <div class="mt-3  mb-2 font-weight-bold font-italic not-f2 ">{{ plan.description }}</div>
    
                                                <div class="mt-5 mx-2"> 
                                                    <v-layout row wrap v-for="(feature, fIndex) in plan.join_billing_plan_features ">
                                                        <v-icon class="mr-1">check</v-icon>
                                                        <div >{{feature.name}}</div>
                                                    </v-layout>
                                                </div> 
                                            </div>

                                            <v-spacer></v-spacer>
                                            <v-layout class="ma-0 pa-2" align-center justify-center>
                                                <v-btn @click="JOIN_PLAN(plan)" small min-width="150" rounded color="primary">Join Plan</v-btn>
                                            </v-layout>
                                        </v-layout> 
                                    </v-card> 
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <v-card v-else height="500">
                        <v-layout justify-center align-center fill-height>
                            <v-chip  large class="px-5" >
                                    <v-icon>warning</v-icon>  
                                    <div class="not-f5 ml-2 grey--text">No Billing Plan!</div>
                            </v-chip>
                        </v-layout>
                    </v-card>
                </v-card> 
            </v-dialog>  
            
            <!--View Plan -->
            <v-dialog
                v-model="dialog_view_selected_plan"
                :scrollable="false"
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="selected_plan">
                    <v-card class="b2"> 
                        <v-layout class="ma-0 px-2 pt-2" >
                            <v-spacer></v-spacer>
                            <v-btn @click="dialog_view_selected_plan=false" icon><v-icon>close</v-icon></v-btn>
                        </v-layout>
                        <v-layout row wrap class="ma-0 pa-5 ">
                            <div>
                                <div class="font-weight-bold not-f8 primary--text">{{selected_plan.name}}</div>
                                <div class="font-weight-bold font-italic not-f3 secondary--text text--darken-1">
                                    {{MBS.date.convert(MBS.date.today,null,2)}}
                                </div> 
                            </div>
                            <v-spacer></v-spacer>
                            <div class="text-right">
                                
                                <div class="grey--text text--darken-2">
                                    <span class="font-weight-bold not-f4 mr-1">MWK</span> 
                                    <span class="font-weight-bold not-f8">{{ MBS.actions.money(selected_plan.monthly_price)}}</span>
                                </div>
                                <div class="font-weight-bold font-italic not-f3 secondary--text text--darken-3">PER MONTH</div>
                            </div> 
                        </v-layout>
                    </v-card>
                    <v-card class="pa-5"> 
                        <v-subheader class="ma-0 pa-0">All Features</v-subheader>
                        <v-layout class="ma-0" row wrap v-for="(feature, fIndex) in selected_plan.join_billing_plan_features">
                            <v-icon class="mr-1">check</v-icon>
                            <div >{{feature.name}}</div>
                        </v-layout>  
                   
                        <v-layout class="ma-0 pt-5">
                            <v-icon class="pr-1">help</v-icon>
                            <div>
                                {{ selected_plan.description }}
                            </div>
                        </v-layout>
                    </v-card>
                     
                </v-card> 
            </v-dialog>  
            
            <!--update Plan -->
            <v-dialog
                v-model="dialog_upgrade_plan"
                :scrollable="false"
                :persistent="true" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" color="b1" v-if="dialog_upgrade_plan">
                    <v-layout column>
                        <v-card class="primary" dark> 
                            <v-layout class="ma-0 px-2 pt-2" >
                                <v-spacer></v-spacer>
                                <v-btn @click="dialog_upgrade_plan=false" icon><v-icon>close</v-icon></v-btn>
                            </v-layout>
                            <v-layout row wrap class="ma-0 pa-5 ">
                                <div>
                                    <div class="font-weight-bold not-f3 secondary--text text--lighten-">Your Current Plan</div>
                                    <div class="font-weight-bold not-f8 --text">{{selected_plan.name}}</div>
                                    <div class="font-weight-bold font-italic not-f3 grey--text text--lighten-1">
                                        Expiring On: {{MBS.date.convert(MBS.date.today,null,2)}}
                                    </div> 
                                    <div class="font-weight-bold font-italic not-f3 grey--text text--lighten-1">
                                        Remaining Months: {{LiveExpiringDate}}
                                    </div> 
                                </div>
                                <v-spacer></v-spacer>
                                <div class="text-right">
                                    
                                    <div class="grey--text text--lighten-2">
                                        <span class="font-weight-bold not-f4 mr-1">MWK</span> 
                                        <span class="font-weight-bold not-f8">{{ MBS.actions.money(LivePaymentBalance) }}</span>
                                    </div>
                                    <div class="font-weight-bold font-italic not-f3 secondary--text text--lighten-2">BALANCE</div>
                                </div> 
                            </v-layout>
                        </v-card>
    
                        <v-subheader class="mx-5 pa-0">Upgrade</v-subheader>

                        <v-card hover class="mt- mx-5 primary"  outlined> 
                            <v-card class=" pa-2" outlined>
                                
                                <v-layout class="ma-0" align-center >
                                    <div>
                                        <div class="font-weight-bold primary--text">Period (+{{ MBS.actions.money(MonthlyPlanPayingPrice(selected_plan)) }}/month)</div>
                                        <div class="not-f3 font-weight-bold ">
                                            <span>{{ MBS.date.moment(CurrentBillingPlanData.expiring_date).format('DD/MM/YYYY')}}</span> + 
                                            <span class=""> {{ InputPayingMonths(selected_plan) }} Month(s)</span> = 
                                            <span class="secondary--text text--darken-1">
                                                {{ MBS.date.moment(CurrentBillingPlanData.expiring_date).add(InputPayingMonths(selected_plan),'months').format('DD/MM/YYYY') }}
                                            </span>
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="REMOVE_MONTH(selected_plan)"  icon><v-icon size="18">mdi-minus-circle</v-icon></v-btn>
                                    <span class="font-weight-bold not-f5">{{InputPayingMonths(selected_plan) }}</span>
                                    <v-btn @click="ADD_MONTH(selected_plan)" icon><v-icon size="18">mdi-plus-circle</v-icon></v-btn>
                                </v-layout>
                            </v-card>
                        </v-card>
    
                        
    
                        <!-- <div class="mt-2 mx-5   font-weight-bold">
                            <span class="not-f3">MWK</span>
                            <span class=" not-f8">{{ MBS.actions.money(TotalPlanPayingPrice(selected_plan)) }}</span>  for
                            <span class="font-italic not-f4 secondary--text text--darken-3">{{InputPayingMonths(selected_plan)}} Month(s)</span>
                        </div> -->

                        <v-card tile class="pa-5 mx-5 mt-5 mb-2">
                            <v-list > 
                                <!-- //Addition features -->
                                <v-list-group v-model="input_selected_plan_expand.addition_feature">
                                    <template v-slot:activator>
                                        <v-list-item-action><v-icon>mdi-bookmark-box-multiple</v-icon></v-list-item-action>
                                        <v-list-item-content>
                                            <div>{{MBS.actions.SIZE(CurrentPlanAdditionFeatures())}} Addition Feature(s)</div>  
                                        </v-list-item-content> 
                                        <v-list-item-action v-if="!input_selected_plan_expand.addition_feature" class="font-weight-bold">
                                            {{ MBS.actions.money(CurrentPlanAdditionFeaturesMonthlyPrice()*InputPayingMonths(selected_plan)) }}
                                        </v-list-item-action> 
                                    </template> 
                                    <v-card class="mx-5 pa-2" outlined flat tile>
                                        <template>
                                            <v-simple-table v-if="MBS.actions.SIZE(CurrentPlanAdditionFeatures())"
                                                dense
                                                fixed-header
                                                height="" >
                                                <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width:'5px'">
                                                             
                                                        </th>
                                                        <th class="text-left">
                                                            Code
                                                        </th>
                                                        <th class="text-left">
                                                            Name
                                                        </th>
                                                        <th class="text-left">
                                                            Month(s)
                                                        </th> 
                                                        <th class="text-left">
                                                            Price
                                                        </th>
                                                        <th class="text-left" style="width:'5px'"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(feature, mfIndex) in CurrentPlanAdditionFeatures()" :key="mfIndex" >
                                                        <td style="width:60px;"><v-icon class="mr-1">check</v-icon></td>
                                                        <td class="with-divider-lr">{{ feature.billing_plan_feature_code }}</td>
                                                        <td>{{ feature.name }}</td>
                                                        <td class="with-divider-l text-center">{{ InputPayingMonths(selected_plan) }} </td>
                                                        <td class="with-divider-l text-right">{{ MBS.actions.money(toNumber(feature.monthly_price)*InputPayingMonths(selected_plan)) }}</td>
                                                        <td class="with-divider-l" style="width:60px;">
                                                            <v-btn icon @click="REMOVE_CURRENT_PLAN_ADDITION_FEATURE(feature)"><v-icon>delete</v-icon></v-btn>
                                                        </td>
                                                    </tr>
                                                    <tr class="b2 font-weight-bold">
                                                        <td style="width:60px;"><v-icon class="mr-1"></v-icon></td>
                                                        <td class=""> </td>
                                                        <td class=""> </td>
                                                        <td class="">Total:</td>
                                                        <td class="with-divider-l text-right">{{ MBS.actions.money(CurrentPlanAdditionFeaturesMonthlyPrice()*InputPayingMonths(selected_plan)) }}</td>
                                                        <td class="with-divider-l" style="width:60px;"> 
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template> 
                                        <div  >
                                            <v-divider  ></v-divider> 
                                            <v-layout class="ma-0 mr- b1" align-center justify-center >
                                                <!-- <v-spacer/>  -->
                                                <v-btn :disabled="false" @click="ADD_FEATURE()" class="mb-2 mt-4"    color="secondary" >
                                                    <div v-if="MBS.actions.SIZE(selected_plan.join_billing_plan_features)==0">Add Outlet Stock Level</div>
                                                    <div v-else>Add a Feature</div> 
                                                    <v-icon class="ml-3">mdi-plus-circle</v-icon>
                                                </v-btn>
                                            </v-layout> 
                                        </div>
                                    </v-card>
                                </v-list-group>

                                <v-divider></v-divider>
                                
                                <!-- //Main features -->
                                <v-list-group v-model="input_selected_plan_expand.main_features">
                                    <template v-slot:activator>
                                        <v-list-item-action><v-icon>mdi-bookmark-multiple</v-icon></v-list-item-action>
                                        <v-list-item-content>
                                            <div>{{MBS.actions.SIZE(selected_plan.join_billing_plan_features)}} Main Feature(s)</div>  
                                        </v-list-item-content> 
                                        <v-list-item-action v-if="!input_selected_plan_expand.main_features" class="font-weight-bold">
                                            {{ MBS.actions.money(toNumber(selected_plan.monthly_price)*InputPayingMonths(selected_plan)) }} 
                                        </v-list-item-action> 
                                    </template> 
                                    <v-card class="mx-5 pa-2" outlined flat tile>
                                        <template>
                                            <v-simple-table dense
                                                fixed-header
                                                height="" >
                                                <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left"  style="width:'5px'"> 
                                                        </th>
                                                        <th class="text-left">
                                                            Code
                                                        </th>
                                                        <th class="text-left">
                                                            Name
                                                        </th>
                                                        <th class="text-left">
                                                            Month(s)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(feature, mfIndex) in selected_plan.join_billing_plan_features" :key="mfIndex">
                                                        <td style="width:60px;"><v-icon class="mr-1">check</v-icon></td>
                                                        <td class="with-divider-lr">{{ feature.code }}</td>
                                                        <td>{{ feature.name }}</td>
                                                        <td class="with-divider-l text-right">
                                                            {{ InputPayingMonths(selected_plan) }}
                                                        </td> 
                                                    </tr>
                                                    <tr class="b2 font-weight-bold">
                                                        <td style="width:60px;"><v-icon class="mr-1"></v-icon></td>
                                                        <td class=""> </td> 
                                                        <td class="">Total Price:</td>
                                                        <td class="with-divider-l text-right">
                                                            {{ MBS.actions.money(toNumber(selected_plan.monthly_price)*InputPayingMonths(selected_plan)) }}
                                                        </td> 
                                                    </tr>
                                                </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template>  
                                        <div  >
                                            <v-divider  ></v-divider> 
                                            <v-card height="50" flat class="ma-0 mr- b1" align-center justify-center >
                                                 
                                            </v-card> 
                                        </div>
                                    </v-card>
                                </v-list-group>
                                
                            </v-list> 
                        </v-card> 
                        

                        <v-spacer></v-spacer>
    
                        <v-divider class="mt-2"/>
                        <v-layout row wrap class="ma-0 mt-1 mb-5 mt-2 mx-5">
                            <v-flex xs12 sm8 md9 lg10>
                                <v-card hover @click="PAY_UPDATE_PLAN(selected_plan)" class=" py-2 px-4 primary" dark flat> 
                                    <v-layout class="ma-0" align-center >
                                        <div>
                                            <div class="font-weight-bold not-f7">Pay</div> 
                                        </div>
                                        <v-spacer></v-spacer>
                                        <div class="white--text text--darken-2">
                                            <span class="font-weight-bold not-f4 mr-1">MWK</span> 
                                            <span class="font-weight-bold not-f7 ">{{ MBS.actions.money(TotalPlanPayingPrice(selected_plan)) }}</span>
                                        </div>
                                    </v-layout>
                                </v-card> 
                            </v-flex> 
                            <v-flex xs12 sm4 md3 lg2> 
                                <v-btn @click="dialog_upgrade_plan=false" :class="vs.xs?'mt-1':'ml-1'" width="100%" min-height="40px" height="100%" color="secondary" >Cancel</v-btn>
                            </v-flex> 
                        </v-layout>
                    </v-layout>
                </v-card> 
            </v-dialog>  

            <!-- Add Feature -->
            <v-dialog
                v-model="dialog_add_feature"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="500px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_add_feature">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">Add Feature</div> 
                        <v-spacer></v-spacer>
                        <v-btn icon  dark @click="dialog_add_feature=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text v-if="selected_plan">
                        <br> 
                        <form @submit.prevent="SUBMIT_SELECTED_PLAN_FEATURE(input)">
                            <mbs-input-fields
                                v-model="input"
                                :field_items="fields"
                                :select_items="Selectors"
                                :py="1"
                            />   
                            <v-layout class="ma-0 pt-5">
                                <v-spacer></v-spacer>
                                <v-btn :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1">Add Select Feature</v-btn>
                            </v-layout>
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.SUBSCRIPTIONS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.SUBSCRIPTIONS.names,
                NAME_ITEM:DATA.ITEMS.SUBSCRIPTIONS.name,
                VALUE_ITEMS:DATA.ITEMS.SUBSCRIPTIONS.values,
                VALUE_ITEM:DATA.ITEMS.SUBSCRIPTIONS.value,  

                dialog_view_selected_plan:false,
                dialog_billing_plans:false,
                dialog_upgrade_plan:false,
                dialog_add_feature:false,
                selected_plan:null,
                input:{},
                input_selected_plan_expand:{
                    main_features:false,
                    addition_feature:true,
                },
                inputLoading:false,
                current_plan_addition_features:null,
     
                table_actions:[
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},
                ],
                DATA:DATA,
                BILLING_PLAN_MAIN_FEATURES:[
                    {name:""}
                ],
                fields:[
                    {type:"autocomplete",value:"billing_plan_feature_code",name:"Name",label:"Select Feature",label_top:"Feature Name*", 
                        select:{
                            name:'UnselectedBillingPlanFeatures',
                            value:'code',
                            text:'name'
                        },
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                let third = item?index(item.item_category_name,queryText):false
                                if(!item){return first} 
                                return first || second || third
                            },
                            item_icon:{
                                name:'mdi-link',
                                tile:true
                            },
                            item_title:['name'],
                            item_subtitle:['monthly_price']
                        },
                        action:[
                            { 
                                item_values:"Features",
                                name:"Manage Plan Feature",
                                link:"/cpanel/billing/feature",
                                company_link:false,
                                append:true
                            }, 
                        ],
                    required:true,sm:12,md:12},    
                    {type:"autocomplete",value:"billing_plan_feature_code",name:"Name",label:"Amount",label_top:"Monthly Price*", 
                        select:{
                            name:'UnselectedBillingPlanFeatures',
                            value:'code',
                            text:'monthly_price'
                        },
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                let third = item?index(item.item_category_name,queryText):false
                                if(!item){return first} 
                                return first || second || third
                            },
                            item_icon:{
                                name:'mdi-link',
                                tile:true
                            },
                            item_title:['name'],
                            item_subtitle:['monthly_price']
                        },
                        action:[
                            { 
                                item_values:"Features",
                                name:"Manage Plan Feature",
                                link:"/cpanel/billing/feature",
                                company_link:false,
                                append:true
                            }, 
                        ],
                    required:true,disabled:true,sm:12,md:12},    
                ]
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
             
            ///////// 
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("settings",1,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.tills,true) 
                return path
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
            }),
            ...mapState({
                BillingPlans: state=> state.items[(DATA.ITEMS.BILLING_PLANS.values).toUpperCase()],
                JoinedBillingPlans: state=> state.join[(DATA.ITEMS.BILLING_PLANS.values).toUpperCase()], 
                BillingPlanFeatures: state=> state.items[(DATA.ITEMS.BILLING_PLAN_FEATURES.values).toUpperCase()],
                JoinedBillingPlanFeatures: state=> state.join[(DATA.ITEMS.BILLING_PLAN_FEATURES.values).toUpperCase()], 
                CurrentBillingPlan: state=> state.items[(DATA.ITEMS.CURRENT_BILLING_PLANS.values).toUpperCase()],
                JoinedCurrentBillingPlan: state=> state.join[(DATA.ITEMS.CURRENT_BILLING_PLANS.values).toUpperCase()], 
                Subscriptions: state=> state.items[(DATA.ITEMS.SUBSCRIPTIONS.values).toUpperCase()],
                JoinedSubscriptions: state=> state.join[(DATA.ITEMS.SUBSCRIPTIONS.values).toUpperCase()], 

                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                ItemDepartments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
                PrinterOptions: state=> state.app['PRINTER_OPTIONS'],
                ServerTypes: state=> state.app[(DATA.ITEMS.SERVER_TYPES.values).toUpperCase()],
                ComputerTypes: state=> state.app[(DATA.ITEMS.COMPUTER_TYPES.values).toUpperCase()], 
            }), 
            BillingPlanFeaturesData(){
                let items = this.JoinedBillingPlanFeatures?this.JoinedBillingPlanFeatures:this.BillingPlanFeatures 
                return items
            },
            UnselectedBillingPlanFeatures(){
                let selected_features = this.CurrentPlanAdditionFeatures()
                let features = this.BillingPlanFeaturesData 
                if(!features){return null}
                if(!selected_features){return features} 
                let items = features.filter(item=>{
                    let index = selected_features.findIndex(it=>it.billing_plan_feature_code == item.code )
                    return index == -1?true:false
                })
                return items
            },
            BillingPlansData(){
                let items = this.JoinedBillingPlans?this.JoinedBillingPlans:this.BillingPlans  
                return items
            },
            CurrentBillingPlanData(){
                let items = this.JoinedCurrentBillingPlan?this.JoinedCurrentBillingPlan:this.CurrentBillingPlan 
                return items
            },
            CurrentBillingPlanDetails(){
                let plan = this.MBS.actions.currentPlanDetails 
                console.log(plan,'Current Plan D...............');
                return plan
            },
            SubscriptionsData(){
                let items = this.JoinedSubscriptions?this.JoinedSubscriptions:this.Subscriptions
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false))    
                return list
            },

            
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"date",value:"created_at_server_",show:true},       
                    {id:0,name:"Transaction",value:"transaction_type",show:true},       
                    {id:0,name:"plan",value:"plan_details.name",show:true},      
                    {id:0,name:"Expire",value:"expiring_date",show:true},       
                    {id:0,name:"Period",value:"paying_months",align:"center",show:true},       
                    {id:0,name:"Amount",value:'amount_',align:"right",show:true},    
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        // {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"edit"}, 
                        // {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"delete"},  
                        {name:"View "+this.NAME_ITEM,action:"view_subscription",icon:"delete"},  
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            }, 
            Selectors(){ 
                let Outlets = this.Outlets 
                let Servers = this.Servers 
                let Computers = this.Computers 
                let Printers = this.Printers  
                let ItemDepartments = this.ItemDepartments 
                let PrinterOptions = this.PrinterOptions 
                let BillingPlans = this.BillingPlansData  
                let BillingPlanFeatures = this.BillingPlanFeatures
                let UnselectedBillingPlanFeatures = this.UnselectedBillingPlanFeatures
                return{
                    Outlets: Outlets, 
                    Servers: Servers, 
                    Computers: Computers, 
                    Printers: Printers,  
                    ItemDepartments: ItemDepartments, 
                    PrinterOptions: PrinterOptions, 
                    BillingPlans: BillingPlans,  
                    BillingPlanFeatures: BillingPlanFeatures,  
                    UnselectedBillingPlanFeatures: UnselectedBillingPlanFeatures,  
                }
            },
            TillsData(){ 
                let Tills = this.Tills
                let JoinedTills = this.JoinedTills   
                return JoinedTills?JoinedTills:Tills
            },
            DeviceData(){ 
                let Devices = this.Devices
                let JoinedDevices = this.JoinedDevices  
                return JoinedDevices?JoinedDevices:Devices
            },
            Printers(){
                let devices = this.DeviceData 
                if(!devices){return null}
                let printers = devices.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.device_type)
                     == this.MBS.actions.TEXT_UP(DATA.ITEMS.PRINTERS.value)
                }) 
                return printers
            },
            Computers(){
                let devices = this.DeviceData 
                if(!devices){return null}
                let printers = devices.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.device_type)
                     == this.MBS.actions.TEXT_UP(DATA.ITEMS.COMPUTERS.value)
                }) 
                return printers
            },
            Servers(){
                let devices = this.DeviceData 
                if(!devices){return null}
                let printers = devices.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.device_type)
                     == this.MBS.actions.TEXT_UP(DATA.ITEMS.SERVERS.value)
                }) 
                return printers
            }, 
            
            InputPayingMonths(){ 
                return plan=>{
                    let min = this.toNumber(plan.minimum_paying_months)
                    let months = this.toNumber(this.input[plan.code])
                    return min+months
                }
            },
            MonthlyPlanPayingPrice(){ 
                return plan=>{ 
                    let price = this.toNumber(plan.monthly_price)
                    let addition_price = this.toNumber(this.CurrentPlanAdditionFeaturesMonthlyPrice(plan))
                    return  price+addition_price
                }
            },
            TotalPlanPayingPrice(){ 
                return plan=>{
                    let months = this.InputPayingMonths(plan)
                    let price = this.MonthlyPlanPayingPrice(plan) 
                    return (months*price) 
                }
            },
            CurrentPlanAdditionFeatures(){ 
                return plan=>{
                    let current_plan_addition_features = this.current_plan_addition_features 
                    let join = this.MBS.actions.JOIN_BILLING_PLAN_FEATURES(current_plan_addition_features) 
                    return join
                }
            },
            CurrentPlanAdditionFeaturesMonthlyPrice(){ 
                return plan=>{
                    let features = this.CurrentPlanAdditionFeatures(plan)
                    if(!features){return 0}
                    let total_price = 0
                    features.forEach(feature => {
                        total_price = total_price + this.toNumber(feature.monthly_price)
                    });
                    return total_price
                }
            }, 

            online(){   
                return this.$store.getters.getOnlineStatus
            },
            liveTime(){
                let time = this.MBS.date.live 
                return time
            },
            LiveExpiringDate(){  
                let plan = this.CurrentBillingPlanData 
                let live = this.liveTime
                let moment = this.MBS.date.moment
                let expiring_date = plan?plan.expiring_date:null  
                return this.GetTimeRemaining(live,expiring_date)
                if (expiring_date) {
                    let diff_months = moment(expiring_date).diff(live,'months',false)
                    let diff_months2 = moment(expiring_date).diff(live,'months',true)
                    let diff_days = moment(expiring_date).diff(live,'days')
                    let diff_hours = moment(expiring_date).diff(live,'hours')
                    let diff_minute = moment(expiring_date).diff(live,'minutes')
                    let diff_seconds = moment(expiring_date).diff(live,'seconds')

                    let sec = diff_seconds - (diff_minute*60)
                    let min = diff_minute - (diff_hours*60)
                    let hours = diff_hours - (diff_days*24)
                    let days = diff_days - (diff_months*30.42).toFixed(0)
                    return diff_months+'m '+days+'d '+''+hours+'h '+min+'m '+sec+'s'
                } 
                return moment(expiring_date).format('yyyy-MM-D .')
            },
            GetTimeRemaining(){
                let moment = this.MBS.date.moment
                return (start, end)=>{
                    // Ensure valid format
                    const startDateMoment = moment(start );
                    const expireDateMoment = moment(end );

                    // Handle past expire date
                    if (expireDateMoment.isBefore(startDateMoment)) {
                        return "Expire date is in the past!";
                    }

                    // Calculate duration
                    const duration = moment.duration(expireDateMoment.diff(startDateMoment));

                    // Extract and format individual units
                    const months = Math.trunc(duration.asMonths());
                    const days = duration.days();
                    const hours = duration.hours();
                    const minutes = duration.minutes();
                    const seconds = duration.seconds();

                    // Pluralize units
                    const monthText = months === 1 ? "month" : "months";
                    const dayText = days === 1 ? "day" : "days";
                    const hourText = hours === 1 ? "hr" : "hrs";
                    const minuteText = minutes === 1 ? "min" : "mins";
                    const secondText = seconds === 1 ? "sec" : "secs";

                    // Format and return the result
                    return `${months} ${monthText}, ${days} ${dayText}, 
                    ${hours} ${hourText}, ${minutes} ${minuteText}, ${seconds} ${secondText}`;
                }
            },
            LivePaymentBalance(){  
                let plan = this.CurrentBillingPlanData 
                let live = this.liveTime
                let PlanExpired = this.PlanExpired(plan)
                if(PlanExpired){return 0}
                let moment = this.MBS.date.moment
                let expiring_date = plan?plan.expiring_date:null 
                let paying_months = this.toNumber(plan?plan.paying_months:null )
                let total_monty_price = this.toNumber( plan?plan.total_monthly_price:null )
                let total_price = total_monty_price*paying_months

                
                if (expiring_date) {
                    //expiring
                    let diff_months = moment(expiring_date).diff(live,'months',true)
                    let diff_days = moment(expiring_date).diff(live,'days')
                    let diff_hours = moment(expiring_date).diff(live,'hours')
                    let diff_minute = moment(expiring_date).diff(live,'minutes')
                    let diff_seconds = moment(expiring_date).diff(live,'seconds')
                    
                    

                    let sec = diff_seconds - (diff_minute*60)
                    let min = diff_minute - (diff_hours*60)
                    let hours = diff_hours - (diff_days*24) 


                    // return total_monty_price*diff_seconds/2628288
                    return total_monty_price*diff_months
                } 
                return this.MBS.actions.money(total_price)
            }, 
            PlanExpired(){  
                let live = this.liveTime
                return plan=>{ 
                    let moment = this.MBS.date.moment
                    let expiring_date = plan?plan.expiring_date:null  
                    if (!expiring_date) {return true}
                    return moment(expiring_date).isBefore(live) 
                }
            },
            currentPlanPermissions(){  
                let permissions = this.MBS.actions.currentPlanPermissions
                console.log(permissions,'permissions................');
                return permissions 
            },
               
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } else if (action == "manage_customer_groups") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/office/people/customers/groups",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            VIEW_BILLING_PLANS(){
                try { 
                    this.current_plan_addition_features = null
                    this.dialog_billing_plans= true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            VIEW_SELECTED_PLAN(plan){
                try {
                    this.selected_plan = plan
                    this.current_plan_addition_features = null
                    this.dialog_view_selected_plan = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_SELECTED_PLAN',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            UPGRADE_PLAN(plan){
                try {
                    console.log(plan,'p............');
                    this.input = []
                    this.current_plan_addition_features =plan.plan_details.added_plan_feature?plan.plan_details.added_plan_feature: null
                    this.selected_plan = this.MBS.actions.JOIN_BILLING_PLAN(plan.plan_details)
                    this.dialog_upgrade_plan = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_SELECTED_PLAN',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_MONTH(plan){
                try {
                    this.input[plan.code] = this.toNumber(this.input[plan.code])+1
                    setTimeout(() => {
                        this.input = {
                            ...this.input
                        } 
                    }, 100);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_MONTH',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            REMOVE_MONTH(plan){
                try {
                    let min = this.toNumber(plan.minimum_paying_months)
                    let months = this.toNumber(this.input[plan.code])
                    if (months) { 
                        this.input[plan.code] = months-1
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_MONTH',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            JOIN_PLAN(plan){
                try {
                    let moment = this.MBS.date.moment
                    // function addMonthsWithDecimals(date, months) {

                    //         // Input validation (optional):
                    //         if (!moment.isMoment(date)) {
                    //             throw new Error('Invalid date object provided');
                    //         }

                    //         // Extract integer and decimal parts of months:
                    //         const intMonths = Math.floor(months);
                    //         const decimalMonths = months - intMonths;

                    //         // Calculate exact days in target month (considering leap years):
                    //         const targetMonth = date.month();
                    //         const targetYear = date.year();
                    //         const daysInTargetMonth = new Date(targetYear, targetMonth + 1, 0).getDate();

                    //         // Create base duration for integer months:
                    //         const intMonthsDuration = moment.duration({ months: intMonths });

                    //         // Calculate proportional days for decimal part:
                    //         const proportionalDays = Math.round(decimalMonths * daysInTargetMonth);

                    //         // Create scaled duration for proportional days:
                    //         const decimalMonthsDuration = moment.duration({ days: proportionalDays });

                    //         // Add both durations to the date:
                    //         const newDate = date.add(intMonthsDuration).add(decimalMonthsDuration);

                    //         return newDate;
                    // }

                    // // Example usage:
                    // const currentDate = moment();
                    // const newDate = addMonthsWithDecimals(currentDate, 1.3);
                    // console.log(newDate.format('YYYY/MM/DD HH:mm:ss')); // Output: 2024-03-28 (approximately 1.3 months from current date)

                    // return













                    let company = this.SELECTED_COMPANY
                    let company_key = company?company.company_key:null 
                    let months = this.InputPayingMonths(plan)
                    let amount = this.TotalPlanPayingPrice(plan)
                    let plan_details={
                        company_key:company_key,
                        plan_details:plan,
                        total_monthly_price:plan.monthly_price,
                        paying_months:months,
                        amount:amount,
                        local_created_at:moment().valueOf(),
                        created_uid:this.us.uid,
                    }
                    console.log(plan_details,'plan_details.....');
                    let mbs_text = this.MBS.text.item_action("Plan","Join")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true, 
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=JOIN-PLAN", 
                            data:plan_details
                        }
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'JOIN_PLAN',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ADD_FEATURE(plan){
                try {
                    this.dialog_add_feature = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_FEATURE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            SUBMIT_SELECTED_PLAN_FEATURE(input){
                try {
                    console.log(input,'.........');
                    let plan = this.selected_plan
                    this.dialog_add_feature = false
                    let billing_plan_feature_code = input?input.billing_plan_feature_code:null
                    if (!this.current_plan_addition_features) {
                        this.current_plan_addition_features = []
                    }

                    let index = this.current_plan_addition_features.findIndex(feature=>feature.billing_plan_feature_code == billing_plan_feature_code )
                    if (index != -1) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"tit",
                            text:"test",
                        })
                        return
                    }
                    if (billing_plan_feature_code) {
                        this.current_plan_addition_features.push({
                            billing_plan_feature_code:billing_plan_feature_code
                        }) 
                        input.billing_plan_feature_code=null
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_SELECTED_PLAN_FEATURE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            REMOVE_CURRENT_PLAN_ADDITION_FEATURE(feature){
                try {
                    console.log(feature,'.........');
                    this.dialog_add_feature = false
                    let billing_plan_feature_code = feature?feature.billing_plan_feature_code:null
                    if (!this.current_plan_addition_features) {
                        this.current_plan_addition_features = []
                    }
                    if (billing_plan_feature_code) {
                        this.current_plan_addition_features = 
                        this.current_plan_addition_features.filter(item=>item.billing_plan_feature_code!=billing_plan_feature_code) 
                    }
                    setTimeout(() => { 
                    }, 10);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_CURRENT_PLAN_ADDITION_FEATURE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
             
            PAY_UPDATE_PLAN(plan){
                try {
                    let moment = this.MBS.date.moment
                    let company = this.SELECTED_COMPANY
                    let company_key = company?company.company_key:null 

                    let total_monthly_price = this.MonthlyPlanPayingPrice(plan)
                    let amount = this.TotalPlanPayingPrice(plan)
                    let paying_months = this.InputPayingMonths(plan)
                    let addition_plan_feature = this.CurrentPlanAdditionFeatures()

                    let plan_details = { 
                        name:plan.name,
                        added_plan_feature:addition_plan_feature?addition_plan_feature:null,
                    }
                    let details = {
                        company_key:company_key,
                        updated_uid:this.us.uid, 
                        local_updated_at:moment().valueOf(),
                        plan_details:plan_details,
                        total_monthly_price:total_monthly_price,
                        amount:amount,
                        paying_months:paying_months,
                        description:".",
                    }
                     
                    console.log(details,'details.....');
                    let mbs_text = this.MBS.text.item_action("Plan","Update")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true, 
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=UPDATE-PLAN", 
                            data:details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAY_UPDATE_PLAN',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            
            REFRESH(){
                try {
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.BILLING_PLANS.name,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-BILLING-PLAN", 
                            } 
                        })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAY_UPDATE_PLAN',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 

            RELOAD_BILLING_PLAN(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.BILLING_PLANS.values,
                        on:true, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{ 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.BILLING_PLANS.name,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                        console.log(res,'res...........');
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.BILLING_PLANS.name,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) { 
                        if (action.code ===PAGE_NAME+'=JOIN-PLAN') {
                            if (action.YES) {
                                // let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.current_billing_plans,true) 
                                // console.log(path,'path......');
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Joining Plan..."
                                }) 
                                setTimeout(() => {
                                    this.input.loading = true 
                                    this.MBS.actions.POST_REQUEST({
                                        action:DATA.ITEMS.CURRENT_BILLING_PLANS.values,
                                        path:"joinCompanyBillingPlan", 
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        console.log(res,'..........s');
                                        let data = res?res.body:null
                                        let current_amount = this.MBS.actions.money(data?data.current_amount:null)
                                        let addition_paying_months = this.toNumber(data?data.addition_paying_months:null)

                                        this.MBS.actions.progressDialog()
                                        this.dialog_billing_plans = false
                                        let mbs_text = this.MBS.text.item_action_success("Billing Plan","Joining")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'e.........');
                                        this.MBS.actions.progressDialog()
                                        let mbs_text = this.MBS.text.item_action_fail("Billing Plan","Joining")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, 100);     
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=UPDATE-PLAN') {
                            if (action.YES) { 
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating Plan..."
                                }) 
                                setTimeout(() => {
                                    this.input.loading = true 
                                    this.MBS.actions.POST_REQUEST({
                                        action:DATA.ITEMS.CURRENT_BILLING_PLANS.values,
                                        path:"updateCompanyBillingPlan", 
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        console.log(res,'..........s');
                                        let data = res?res.body:null
                                        let current_amount = this.MBS.actions.money(data?data.current_amount:null)
                                        let addition_paying_months = this.toNumber(data?data.addition_paying_months:null)

                                        this.MBS.actions.progressDialog()
                                        this.dialog_upgrade_plan = false
                                        let mbs_text = this.MBS.text.item_action_success("Billing Plan","Updating")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'e.........');
                                        this.MBS.actions.progressDialog()
                                        let mbs_text = this.MBS.text.item_action_fail("Billing Plan","Updating")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, 100);     
                            } else {
                                
                            }
                        }  
                    }
                    if (action.code ===PAGE_NAME+'=RELOAD-BILLING-PLAN') {
                        if (action.YES) {
                            this.RELOAD_BILLING_PLAN()
                        } else {
                            
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
    
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
