const crypto = require('crypto');

const algorithm = 'aes-256-ctr';
// const secretKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3';
//const iv = crypto.randomBytes(16);
const secretKey = '=zilimwabagabo====zilimwabagabo=';
const iv = '1234567890123456';
//const iv = '504e8b40b5019f83c87f2cd9663a42d2';

const encrypt = (text) => {

    const cipher = crypto.createCipheriv(algorithm, secretKey, iv); 
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

    // return {
    //     iv: iv.toString('hex'),
    //     content: encrypted.toString('hex')
    // };
    return encrypted.toString('hex')
};

const decrypt = (hash) => {

    // const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
    const decipher = crypto.createDecipheriv(algorithm, secretKey, iv);

    const decrypted = Buffer.concat([decipher.update(Buffer.from(hash, 'hex')), decipher.final()]);

    return decrypted.toString();
};

module.exports = {
    encrypt,
    decrypt
};