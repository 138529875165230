<template>
  <v-layout height="600" class="">    
    <v-layout v-if="false">
        <mbs-page-spinner :show="true" />   
    </v-layout>     
    <v-container v-else grid-list-lg> 
        <v-card class="mb-5" color="primary">
            <mbs-page-tab 
                :items="MyLinks"/>   
        </v-card>
        <v-card  min-height="300" outlined tile>
            <!-- // TOOLBAR -->
            <v-toolbar  rounded="" outlined    color="primary" class="mb-2" dark>  
                <template >
                    <v-toolbar-title>
                        Import or Export Company Data
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-divider vertical></v-divider> 

                    <v-btn 
                        icon @click="ON_PDF()">
                        <v-icon>mdi-file-pdf-box </v-icon>
                    </v-btn>
                    <v-btn 
                        icon @click="ON_EXCEL()">
                        <v-icon>mdi-microsoft-excel </v-icon>
                    </v-btn>
                    <v-btn 
                        icon @click="ON_EMAIL()">
                        <v-icon>mdi-email-outline</v-icon>
                    </v-btn>
                    <v-btn 
                        icon @click="ON_PRINTING()">
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <v-btn 
                        icon @click="ON_PRINTING()">
                        <v-icon>mdi-more</v-icon>
                    </v-btn>
                </template>
            </v-toolbar> 

            <!-- //IMPORT -->
            <v-card  @click="onPickFile()" v-if="!WB && !excel_loading"
                tile  flat  :height="vsh-378" min-height="222" 
                hover color="grey lighten-2 ma-1">
                <v-layout  
                    column justify-center 
                    align-center fill-height 
                    class="ma-0 font-weight-bold grey--text text--darken-1 not-f">
                    <v-icon size="100" color="grey">mdi-microsoft-excel</v-icon>
                    <div>Select / Drag and Drop to Import</div>
                    <div></div>
                </v-layout> 
            </v-card>

            <!-- //LOAD EXCEL -->
            <v-card v-else-if="excel_loading"
                tile  flat  :height="vsh-378" min-height="222" 
                hover color="grey lighten-2 ma-1"> 
                <v-layout 
                    class="ma-0" fill-height 
                    justify-center align-center>
                    <div>
                        <v-progress-circular
                            :rotate="-90"
                            :size="100"
                            :width="15"
                            :value="loadingValue"
                            color="primary"
                            >
                            {{ loadingValue }}
                            </v-progress-circular>
                    </div>
                </v-layout>
            </v-card>

            <!-- //IMPORTING STEPS -->
            <v-stepper v-else-if="WB" class="mx-1" tile flat
                v-model="importSteps"
                vertical >

                <!-- step1 -->
                <v-stepper-step
                    :complete="importSteps > 1"
                    step="1" >
                    Select Excel file
                    <small>The file with data to be imported</small>
                </v-stepper-step> 
                <v-stepper-content step="1">
                    <v-card
                        color="grey lighten-1" outlined
                        class="mb-12"
                        min-height="200px"
                    >
                    <v-subheader >
                        Excel File Details
                    </v-subheader> 
                    <v-list dense color="">
                        <v-list-item  
                            @click=""
                            >
                            <v-list-item-icon>
                                <v-icon>person</v-icon>
                            </v-list-item-icon>
    
                            <v-list-item-content>
                                <v-list-item-title v-html="'Author'"/> 
                                <v-list-item-subtitle v-html="WB?WB.Props.Author:'.'"/> 
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item  
                            @click=""
                            >
                            <v-list-item-icon>
                                <v-icon>person</v-icon>
                            </v-list-item-icon>
    
                            <v-list-item-content>
                                <v-list-item-title v-html="'Modified by'"/> 
                                <v-list-item-subtitle v-html="WB?WB.Props.LastAuthor:'.'"/> 
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item  
                            @click=""
                            >
                            <v-list-item-icon>
                                <v-icon>description</v-icon>
                            </v-list-item-icon>
    
                            <v-list-item-content>
                                <!-- <v-list-item-title v-html="'File Type'"/>  -->
                                <v-list-item-title v-html="WB?WB.Props.Application:'.'"/> 
                                <v-list-item-subtitle v-html="WB_FILE_DETAILS?WB_FILE_DETAILS.name:'.'"/> 
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item  
                            @click=""
                            >
                            <v-list-item-icon>
                                <v-icon>schedule</v-icon>
                            </v-list-item-icon>
    
                            <v-list-item-content>
                                <v-list-item-title v-html="'Date'"/> 
                                <v-list-item-subtitle v-html="WB?
                                MBS.date.moment(WB.Props.ModifiedDate).format('dddd,Do, MMMM YYYY HH:mm'):'.'"/> 
                            </v-list-item-content>
                        </v-list-item> 
                    </v-list>
                    </v-card>
                    <v-btn 
                        :loading="load"
                        @click.native="NEXT_STEP(2,1000)"
                        color="primary">
                        Continue
                        <v-icon >chevron_right</v-icon>
                    </v-btn> 
                    <v-btn text @click="CANCEL_IMPORT()">Cancel</v-btn>
                </v-stepper-content>

                <!-- step2 -->
                <v-stepper-step
                    :complete="importSteps > 2"
                    step="2">
                    Working Sheet(s)
                    <small>Select Working sheet(s) you want to import</small>
                </v-stepper-step> 
                <v-stepper-content step="2">
                    <v-card outlined
                        color="grey lighten-1"
                        class="mb-12"
                        min-height="200px" >
                        <v-layout class="mx-2 my-0">
                            <v-icon>attach_file</v-icon>
                            <v-subheader>Working Sheet(s)</v-subheader>
                        </v-layout>
                        <v-list
                            v-if="MBS.actions.SIZE(WB_SHEETS)>0"
                            min-height="180"
                            flat > 
                            <v-list-item-group
                                v-model="selected_sheets"
                                multiple
                                active-class="" >
                                <v-list-item v-for="(sheet,index) in WB_SHEETS" :key="index">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action> 
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action> 
                                        <v-list-item-content>
                                            <v-list-item-title>{{sheet}}</v-list-item-title>
                                            <v-list-item-subtitle>Sheet {{index+1}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item> 
                            </v-list-item-group>
                        </v-list>
                        <v-card min-height="180" v-else>
                            <v-layout fill-height justify-center align-center>
                                <v-icon>warning</v-icon>
                                <div>No Working Sheets</div>
                            </v-layout>
                        </v-card>
                    </v-card>
                    <v-btn  
                        @click.native="NEXT_STEP(1,1)">
                        <v-icon>chevron_left</v-icon>
                        Back
                    </v-btn>
                    <v-btn 
                        :loading="load"
                        :disabled="!(MBS.actions.SIZE(selected_sheets)>0)"
                        @click.native="NEXT_STEP(3,1000)"
                        color="primary">
                        Continue
                        <v-icon >chevron_right</v-icon>
                    </v-btn> 
                    <v-btn text @click="CANCEL_IMPORT()">Cancel</v-btn>
                </v-stepper-content>

                <!-- step3 -->
                <v-stepper-step
                    :complete="importSteps > 3"
                    step="3" >
                    Verify Sheet(s) Data
                    <small>Mach Working Sheet(s) with System Data type</small>
                </v-stepper-step> 
                <v-stepper-content step="3">
                    <v-card outlined
                        color="grey lighten-1"
                        class="mb-10"
                        min-height="200px" > 
                        <v-stepper v-model="sheetSteps" >
                            <v-stepper-header>
                                <template v-for="(sheet,index) in SelectedSheets" >
                                    <v-divider v-if="index != 0" :key="'m'+index"></v-divider>  
                                    <v-stepper-step :key="index"
                                        :complete="index+1 < sheetSteps"
                                        :step="(index+1)" > 
                                    </v-stepper-step> 
                                </template> 
                            </v-stepper-header>
                            <template v-for="(sheet,index) in SelectedSheets"> 
                                <v-stepper-items class="mb- pa-5" :key="index" v-show="sheetSteps == index+1" >  
                                    <v-card
                                        :class="MBS.data.animate.slide.inRight"
                                        class="mb-1" outlined
                                        color="grey lighten-1"
                                        min-height="200px">
                                        <v-layout class="ma-0 pa-2" align-center>
                                            <v-icon class="mr-2">mdi-file-delimited</v-icon>
                                            <div>{{sheetSteps}}.Sheet ({{(sheet.index)+1}})</div>
                                            <v-spacer></v-spacer>
                                            <v-btn  
                                                :disabled="!selectedSystemData[sheet.index]"
                                                class="mr-2"  
                                                color="primary"  
                                                @click="sheetSteps = sheetSteps+1" >
                                                View  
                                                <v-icon class="ml-2">mdi-eye</v-icon>
                                            </v-btn> 
                                            <v-btn color="primary" @click="REMOVE_SHEET(sheet.index)">Remove<v-icon>delete</v-icon></v-btn>
                                        </v-layout>
                                        <v-list min-height="180" color="b1" >
                                            <v-list-item>
                                                <v-list-item-action>
                                                <v-icon>mdi-paperclip </v-icon> 
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{sheet.name}} </v-list-item-title>
                                                    <v-list-item-subtitle>Sheet Name</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item> 
                                            <v-subheader class="mx-2">System Data Name</v-subheader>
                                            <v-autocomplete 
                                                class="mx-5"
                                                :disabled="GetProcessedSheetByIndex(sheet.index) != null"
                                                v-model="selectedSystemData[sheet.index]"
                                                filled outlined
                                                :items="SystemData"
                                                item-value="values"
                                                item-text="values"
                                                label="Select Data Name"
                                                :filter="(item, queryText, itemText) => { 
                                                    let index = (a,b)=>{
                                                        if(!a||!b){return false}
                                                        a = ''+a; b = ''+b
                                                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                    } 
                                                    let first = index(itemText,queryText)
                                                    let second = item?index(item.names,queryText):false
                                                    if(!item){return first} 
                                                    return first || second 
                                                }"
                                                                                >
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                        <v-list-item-avatar tile color="grey">
                                                            <img :src="data.item.image" v-if="data.item.image">
                                                            <v-icon v-else>mdi-paperclip</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.names"></v-list-item-title>
                                                            <v-list-item-subtitle v-html="data.item.values"></v-list-item-subtitle> 
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                            <v-layout row wrap class="ma-0 px-4 pt-5 pb-3">
                                                <v-btn v-if="index !=0"
                                                    color="primary" outlined
                                                    @click="NEXT_SHEET(true)" >
                                                    <v-icon>chevron_left</v-icon>
                                                </v-btn>
                                                <v-btn v-if="!GetProcessedSheetByIndex(sheet.index)"
                                                    :disabled="!selectedSystemData[sheet.index]"
                                                    class="ml-1"
                                                    color="primary"  
                                                    @click="PROCESS_SHEET(sheet,selectedSystemData[sheet.index])" > Process
                                                    <v-icon class="ml-2">mdi-checkbox-marked-circle-plus-outline </v-icon>
                                                </v-btn> 
                                                <v-btn v-if="GetProcessedSheetByIndex(sheet.index) && (index+1)!=MBS.actions.SIZE(SelectedSheets)"
                                                    color="primary" outlined
                                                    @click="NEXT_SHEET()" >
                                                    <v-icon>chevron_right</v-icon>
                                                </v-btn>
                                                <v-spacer></v-spacer> 
                                            </v-layout> 
                                        </v-list>
                                    </v-card> 
                                </v-stepper-items> 
                            </template> 
                            <v-subheader class="mb-2">Select, Mach and Process System Data before you Can Continue.</v-subheader>
                        </v-stepper>
                    </v-card>
                    <v-btn  
                        @click.native="NEXT_STEP(2,1)">
                        <v-icon>chevron_left</v-icon>
                        Back
                    </v-btn>
                    <v-btn 
                        :disabled="MBS.actions.SIZE(processedSheets) != MBS.actions.SIZE(SelectedSheets)"
                        :loading="load"
                        @click.native="NEXT_STEP(4,1000)"
                        color="primary">
                        Continue
                        <v-icon >chevron_right</v-icon>
                    </v-btn> 
                    <v-btn text @click="CANCEL_IMPORT()">Cancel</v-btn>
                </v-stepper-content>

                <!-- step4 -->
                <v-stepper-step step="4">
                    importing Options
                    <small>Select your best importing option</small>
                </v-stepper-step>
                <v-stepper-content step="4">
                    <v-card outlined
                        color="grey lighten-1"
                        class="mb-12"
                        min-height="200px" >
                        <v-layout class="mx-2 my-0">
                            <v-icon>mdi-upload</v-icon>
                            <v-subheader>Options</v-subheader>
                        </v-layout>
                        <v-list
                            flat > 
                            <v-list-item-group 
                                multiple
                                three-line
                                active-class="" >
                                <v-list-item v-model="importUpdateOption">
                                    <template v-slot:default="{ active }"> 
                                        <v-list-item-content>
                                            <v-list-item-title>Update</v-list-item-title>
                                            <v-list-item-subtitle>Add the import to existing data</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action> 
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action> 
                                    </template>
                                </v-list-item> 
                                <v-list-item v-model="importOverwriteOption">
                                    <template v-slot:default="{ active }">
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>Overwrite</v-list-item-title>
                                            <v-list-item-subtitle>Delete existing data and replace with import data</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action> 
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action> 
                                    </template>
                                </v-list-item> 
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                    <v-btn  :disabled="load"
                        @click.native="NEXT_STEP(3,1)">
                        <v-icon>chevron_left</v-icon>
                        Back
                    </v-btn>
                    <v-btn :disabled="!importOverwriteOption && !importUpdateOption"
                        :loading="load"
                        @click.native="IMPORT_DATA()"
                        color="primary">
                        Import Data
                        <v-icon >mdi-upload</v-icon>
                    </v-btn> 
                    <v-btn text @click="CANCEL_IMPORT()">Cancel</v-btn>
                </v-stepper-content>
            </v-stepper>
        </v-card>
        <mbs-excel-selector
            @onFileSelected="EXCEL_FILE_SELECTED" 
            @onNoFileSelected="NO_EXCEL_FILE_SELECTED" 
            @onLoadingFile="LOADING_FILE" 
            @onError="ON_FILE_ERROR" 
            /> 
    </v-container>  
  </v-layout> 
</template>

<script>
import DATA from '../../../plugins/DATA'
// const SerialPort = require("serialport")
// const readline = require("@serialport/parser-readline")
let PAGE_NAME = "IMPORT-EXPORT"
    export default {
        name:PAGE_NAME, 
        data() {
        return {
            PAGE_SHOW:false, 
            DATA:DATA,
            show:false,
            load: false,  
            excel_loading:false,
            loadingValue:10,  
            WB:null,
            WB_SHEETS:null,
            WB_PROPS:null,
            WB_FILE_DETAILS:null,
            JSON_SHEETS:null,
            selected_sheets:[],
            importSteps:1,
            sheetSteps:1,
            selectedSystemData:{},
            processedSheets:null,
            importUpdateOption:false,
            importOverwriteOption:false,


        }
        },
        created(){ 
            try {
                // console.log(SerialPort,readline,'l........');
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                })
            }
        },
        mounted() {
            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500)
             
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            us(){
                let returnUser = this.$store.getters.getUser
                return returnUser
            }, 
            ud(){
                let returnUser = this.$store.getters.getUserDetails
                return returnUser
            }, 
            loading(){ 
                const loading = this.$store.getters.getLoading
                return loading
            },
            processes(){ 
                const process = this.$store.getters.getProcesses
                return process
            }, 
            responses(){ 
                const re = this.$store.getters.getResponses
                return re
            },
            /////////
            MyLinks(){
                let links = this.MBS.actions.FIND_SUB_LINKS("settings",1,true) 
                return links
            },
            SelectedSheets(){
                 let indexes = this.selected_sheets
                if(!indexes) {return nul}
                let names = []
                indexes.forEach(index => {
                    let sheet = this.GetSheetName(index)
                    names.push({index:index,name:sheet})
                });
                names.sort(this.MBS.actions.dynamicSort("index",true)) 
                return names
            },
            SystemData(){
                let data = DATA.SYSTEM_DATA
                return data
            },
            GetSheetName(){
                let sheets = this.WB_SHEETS
                return index =>{
                    if(!sheets){return null}
                    return sheets[index]
                }
            },
            GetProcessedSheetByIndex(){
                let sheets = this.processedSheets
                return index =>{
                    if(!sheets){return null}
                    return sheets.find(sheet=>{
                        return sheet.index == index
                    })
                }
            }, 
            GeneralHeaders(){
                return [  
                    {value:"code"},      
                    {value:"name"},  
                ]
            }, 
            getSheetHeaders(){
                let headers = this.SystemData 
                return sheet=>{
                    if(!headers){return null}
                    let hd = headers.find(item=>{
                        return item.values == sheet
                    }) 
                    return hd?hd.headers:null
                }
            }, 
            
        },
        methods: { 
            clicked(){},
            onPickFile(){ 
                this.MBS.events.$emit('SELECT_EXCEL')
            },
            REST_DATA(){
                try {
                    this.show = false
                    this.load = false
                    this.excel_loading = false
                    this.loadingValue = 10
                    this.WB = null
                    this.WB_SHEETS = null
                    this.WB_PROPS = null
                    this.WB_FILE_DETAILS = null
                    this.JSON_SHEETS = null
                    this.selected_sheets = []
                    this.importSteps = 1
                    this.sheetSteps = 1
                    this.selectedSystemData = {}
                    this.processedSheets = null
                    this.importUpdateOption = false
                    this.importOverwriteOption = false
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REST_DATA',
                        page:PAGE_NAME, 
                    })
                } 
            },
            LOADING_FILE(file){
                try {
                    this.excel_loading = true 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            ON_FILE_ERROR(error){
                try {
                    this.excel_loading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            EXCEL_FILE_SELECTED(file,file_details,res){
                try {
                    this.loadingValue = 90
                    setTimeout(() => {
                        this.loadingValue = 100
                        setTimeout(() => { 
                            this.excel_loading = false
                            this.WB = file
                            this.WB_SHEETS = file?file.SheetNames:null
                            this.WB_PROPS = file?file.Props:null
                            this.WB_FILE_DETAILS = file_details
                            this.JSON_SHEETS = res?res.data:null 
                        }, 500);
                    }, 1000);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            NO_EXCEL_FILE_SELECTED(file){
                try {
                    this.excel_loading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NO_EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            CANCEL_IMPORT(){
                try {
                    let mbs_text = this.MBS.text.item_action("Import","Cancel")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CANCEL-IMPORT"
                        }
                    })   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_IMPORT',
                        page:PAGE_NAME, 
                    })
                } 
            },
            NEXT_STEP (step,time) {
                try {
                    this.load= true
                    setTimeout(()=>{
                        this.importSteps=step
                        this.load=false
                    },time) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            NEXT_SHEET (back,round) {
                try {
                    let size = this.MBS.actions.SIZE(this.selected_sheets)
                    if (back) {
                        if (this.sheetSteps == 1) {
                            if (round) {
                                this.sheetSteps = size 
                            }
                        }else{
                            this.sheetSteps = this.sheetSteps-1  
                        }
                    }else{
                        if (this.sheetSteps == size) {
                            if (round) {
                                this.sheetSteps = 1 
                            }
                        }else{
                            this.sheetSteps = this.sheetSteps+1  
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_SHEET',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            PROCESS_SHEET (sheet, systemData) {
                try {
                    let jsonSheets = this.JSON_SHEETS 
                    let json = jsonSheets?jsonSheets.find(element=>{
                        return element.name == sheet.name
                    }):null 
                    if (!this.processedSheets) {
                        this.processedSheets = []
                    }
                    let headers = this.getSheetHeaders(systemData)
                    console.log(headers,'headers.....');
                    let data = this.CREATE_SHEET_DATA(json.json,headers)
                    this.processedSheets.push({
                        ...sheet,
                        system_data:systemData,
                        data:data
                    })
                    
                    this.NEXT_SHEET() 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PROCESS_SHEET',
                        page:PAGE_NAME, 
                    })
                } 
            }, 

            CREATE_SHEET_DATA (json, headers) {
                try {
                    if(!json||!headers){return null}
                    let data_item = []
                    let number = 0 

                    console.log(json,'json....');
                    json.forEach(excel_item => {
                        let dt = new Date
                        let date = dt.toISOString()

                        let key_modified_item = {}
                        for (const key in excel_item) {
                            if (Object.hasOwnProperty.call(excel_item, key)) {
                                const element = excel_item[key];
                                key_modified_item[this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(key))]=element
                            }
                        }

                        //CREATE ARRAY
                        let item_array = {}
                        headers.forEach(element => { 
                            if (key_modified_item[element.value] && element.value) {
                                item_array[element.value] = key_modified_item[element.value] 
                            }
                        }); 
                        //ADD
                        data_item.push({
                            ...item_array,
                            created_at:date, 
                            uid:this.us.uid,
                            no:number
                        })
                    }); 
                    console.log(data_item,'data_item....');
                    return data_item
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SHEET_DATA',
                        page:PAGE_NAME, 
                    })
                } 
            },  
            CREATE_OPENING_STOCK (items) {
                try {
                    if(!items){return null}
                    let data_item = [] 
                    items.forEach(element => { 
                        data_item.push({
                            item_code:element.code, 
                            price:element.cost_price,
                            quantity:element.quantity,  
                        })
                    }); 

                    return {
                        code:1,
                        items:data_item,
                        order_type:DATA.ITEMS.OPENING_STOCK.value,
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SHEET_DATA',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            REMOVE_SHEET (index) {
                try {
                    let sheets =this.selected_sheets
                    if (sheets) {
                        this.selected_sheets = sheets.filter(sheet=>{
                            return sheet != index
                        }) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_SHEET',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            IMPORT_DATA () {
                try {
                    let update = this.importUpdateOption
                    let overwrite = this.importOverwriteOption
                    let sheets = this.processedSheets
                    if (!sheets) {
                        let mbs_text = this.MBS.text.check_code_error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })
                    }
                    // add opening balance 

                    if (update) {
                        let mbs_text = this.MBS.text.import_update("Excel")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                            btnNo:mbs_text.btnNo, 
                            action:{
                                code:PAGE_NAME+"=IMPORT-UPDATE",
                                data:sheets,
                            }
                        })
                    }if (overwrite) {
                        let mbs_text = this.MBS.text.import_overwrite("Excel")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                            btnNo:mbs_text.btnNo, 
                            action:{
                                code:PAGE_NAME+"=IMPORT-OVERWRITE",
                                data:sheets,
                            }
                        })

                        console.log(sheets);
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_SHEET',
                        page:PAGE_NAME, 
                    })
                } 
            },  
            IMPORT_UPDATE_DATA (sheets) {
                return new Promise((resolve,reject)=>{
                    let response = {
                        from:'IMPORT_UPDATE_DATA',
                        page:PAGE_NAME, 
                        payload:sheets, 
                    }
                    try { 
                        if(sheets){
                            let responses = []
                            sheets.forEach(async sheet => {
                                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS[sheet.system_data],true)
                                let payload = {
                                    items:sheet.data,
                                    action:sheet.system_data,
                                    path:path,
                                } 
                                try {
                                    let res =await this.$store.dispatch("fi_add_array",payload)
                                    responses.push({
                                        ...sheet,
                                        response:res
                                    })
                                } catch (error) {
                                    responses.push({
                                        ...sheet,
                                        response:{error:error}
                                    }) 
                                } 
                                if (this.MBS.actions.SIZE(sheets)==this.MBS.actions.SIZE(responses)) {
                                    resolve({
                                        success:true,
                                        responses:responses
                                    })
                                }
                            }); 
                        }else{
                            reject({
                                ...response,
                                success:false,
                                error:"No Sheet"
                            })
                        } 
                    } catch (error) {
                       reject({
                            ...response,
                            success:false,
                            error:error
                        })
                    }  
                })
            },
            IMPORT_OVERWRITE_DATA (sheets) {
                return new Promise((resolve,reject)=>{
                    let response = {
                        from:'IMPORT_UPDATE_DATA',
                        page:PAGE_NAME, 
                        payload:sheets, 
                    }
                    try { 
                        if(sheets){
                            let responses = []
                            sheets.forEach(async sheet => {
                                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS[sheet.system_data],true)
                                let isArray = true
                                let download_data = false
                                if (sheet.system_data == DATA.ITEMS.ITEMS.values) {
                                    download_data = true
                                }
                                let payload = {
                                    data:sheet.data,
                                    action:sheet.system_data,
                                    path:path,
                                    path_invalid:true,
                                    array:isArray,
                                    download_data:download_data,
                                } 
                                try {
                                    let res =await this.$store.dispatch("fi_set",payload)
                                     responses.push({
                                        ...sheet,
                                        response:res
                                    })
                                } catch (error) {
                                    responses.push({
                                        ...sheet,
                                        response:{error:error}
                                    }) 
                                } 
                                if (this.MBS.actions.SIZE(sheets)==this.MBS.actions.SIZE(responses)) {
                                    resolve({
                                        success:true,
                                        responses:responses
                                    })
                                }
                            }); 
                        }else{
                            reject({
                                ...response,
                                success:false,
                                error:"No Sheet"
                            })
                        } 
                    } catch (error) {
                       reject({
                            ...response,
                            success:false,
                            error:error
                        })
                    }  
                })
            }, 
            
            
            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) {   
                        if (action.code ===PAGE_NAME+"=CANCEL-IMPORT") {
                            if (action.YES) {
                                this.REST_DATA()
                            } else { 
                            }
                        } if (action.code ===PAGE_NAME+"=IMPORT-UPDATE") {
                            if (action.YES) {
                                this.load = true
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Importing.."
                                }) 
                                this.IMPORT_UPDATE_DATA(action.data)
                                // .then(res=>{
                                //     let responses = res?res.responses:null
                                //     let items = responses?responses.find(sheet=>{
                                //         return sheet.system_data == DATA.ITEMS.ITEMS.values
                                //     }):null
                                // })
                                .then(res=>{ 
                                    this.REST_DATA()
                                    this.load = false
                                    this.MBS.actions.progressDialog()
                                    let mbs_text = this.MBS.text.item_action_success("Data","Importing")
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:mbs_text.title,
                                        text:mbs_text.text,
                                        btnYes:mbs_text.btnYes,  
                                    }) 
                                }).catch(error=>{
                                   console.log(error);
                                   this.load = false
                                    this.MBS.actions.progressDialog()
                                   let mbs_text = this.MBS.text.item_action_success("Data","Importing")
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:mbs_text.title,
                                        text:mbs_text.text,
                                        btnYes:mbs_text.btnYes,  
                                    }) 
                                })
                            } else { 
                            }
                        } if (action.code ===PAGE_NAME+"=IMPORT-OVERWRITE") {
                            if (action.YES) {
                                this.load = true
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Importing.."
                                }) 
                                this.IMPORT_OVERWRITE_DATA(action.data)
                                .then(res=>{
                                    console.log(res,'res.........'); 
                                    this.REST_DATA()
                                    this.load = false
                                    this.MBS.actions.progressDialog()
                                }).catch(error=>{
                                    console.log(error,'error......');
                                    this.load = false
                                    this.MBS.actions.progressDialog()
                                })
                            } else { 
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{ 
                            this.PAGE_SHOW =true
                        }     
                    }else{ 
                    }

                     
                },time)
            }
        },
        beforeDestroy(){ 
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            importUpdateOption(value){ 
                if (this.importOverwriteOption == value) {
                    this.importOverwriteOption = !value
                }
            },
            importOverwriteOption(value){ 
                if (this.importUpdateOption == value) {
                    this.importUpdateOption = !value
                }
            }
        },
  }
</script>
 
