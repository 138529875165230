<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="AllQuotationsAndInvoices"  
                            :is_joined="true" 
                            :path_item="QUOTATIONS_AND_INVOICES_PATH"    

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.QUOTATIONS_AND_INVOICES.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.QUOTATIONS_AND_INVOICES.names,
                NAME_ITEM:DATA.ITEMS.QUOTATIONS_AND_INVOICES.name,
                VALUE_ITEMS:DATA.ITEMS.QUOTATIONS_AND_INVOICES.values,
                VALUE_ITEM:DATA.ITEMS.QUOTATIONS_AND_INVOICES.value,  
    
                table_actions:[
                    {type:"btn",action:"request_quotation",color:"secondary", outlined:false,  text:"Request for Quotation"}, 
                    {type:"btn",color:"secondary", text:"Receive", min_width:"",action:"receive",
                        actions:[
                            {icon:"",action:"received_quotation",text:"Receive Quotation"},
                            // {icon:"",action:"receive_stock",text:"Receive Stock"},
                            {icon:"",action:"receive_stock_with_invoice",text:"Receive Invoice with Stock "},
                            {icon:"",action:"received_invoice",text:"Received Invoice without Stock"},
                        ]
                    } , 
                ] 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()], 
            }), 
            AllQuotationsAndInvoices(){ 
                let QuotationsAndInvoices = this.QuotationsAndInvoices
                let JoinedQuotationsAndInvoices = this.JoinedQuotationsAndInvoices
                let items = JoinedQuotationsAndInvoices?JoinedQuotationsAndInvoices:QuotationsAndInvoices 
                if(!items){return null}
                let list = [...items]
                console.log(JoinedQuotationsAndInvoices,'JoinedQuotationsAndInvoices....');
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            }, 
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            }, 
            QUOTATIONS_AND_INVOICES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.quotations_and_invoices,true) 
                return path
            },  
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon',color:"secondary",size:35,show:true},   
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"create",value:'created_at_',show:true},   
                    {id:0,name:"Type",value:'type_name',show:true},     
                    {id:0,name:"code",align:"center",show:true},          
                    {id:0,name:"From",value:"from",show:true},     
                    {id:0,name:"Status",value:"status",show:true},     
                    {id:0,name:"items",value:'total_quantity_',show:true},   
                    {id:0,name:"Total Cost",value:'total_price_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Quotation/Invoice",action:"view_order_details",icon:"mdi-eye"}, 
                        {name:"Archive Quotation/Invoice",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },   
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "request_quotation") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/request-quotation",true))
                    }else if (action == "received_quotation") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/receive-quotation",true))
                    }else if (action == "received_invoice") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/receive-invoice",true))
                    }else if (action == "receive_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    }else if (action == "receive_stock_with_invoice") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock-with-invoice",true))
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true) 
                    console.log(path,'...');
                    if (action == "view_order_details") {
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
    
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
