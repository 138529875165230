<template> 
    <v-layout  align-center="" class="b1--text "> 
        <!-- // LINKS --> 
        <v-row class="b1--text not-shadow" >
            <div v-for="(link, index) in links" :key="index"> 
                <div class="not-f6 font-weight-bold font-italic white--text" v-if="(links?links.length:0)===index+1">
                    <div v-if="!$route.params.id">
                    {{link}}
                    </div>
                    <div v-else>
                    ...
                    </div>
                </div>
                <router-link v-else :to="getLink({name:link,index:index})" class="not-f6 not-line0 font-italic b1--text">
                    {{link===""?"Home":link}}
                    <span class="not-6 font-weight-bold px-1">/</span>
                </router-link> 
            </div>  
        </v-row>
        <v-spacer ></v-spacer>
        <!-- //PAGE NAME -->
        <div class="not-f8 font-weight-bold not-zindex-1">{{$route.name}}</div>
    </v-layout>
</template>

<script>
 
export default {
    props:['items','color','size','space'],
    data(){
        return{
            show:false, 
        }
    },
    mounted(){  
        
    },
    computed:{
        //commony
        mbs(){
            return this.MBS.actions
        },
        vs(){
                return this.$vuetify.breakpoint
        }, 
        vsn(){
            return this.vs.name
        },
        us(){
            return this.mbs.us
        },
        ud(){
            return this.mbs.ud
        },
        app(){ 
            return this.$store.getters.getApp
        },
        links(){
            let path = this.$route.path
            return path.split("/")
        }     
         
    },
    methods:{
        navOpen(){
            this.$emit('navOpen')    
        },
        getLink(item){
            let index = item.index
            let name = item.name
            let path = this.$route.path
            let id = this.$route.path

            if (!index) {
                return "/"
            }
            if (index===0) {
                return "/"
            }else {
                let link = path.split(name)  
                return link[0]+name
        }
    }
        

    },
    watch:{
      
    }
    

}
</script>
<style>
  
</style>

