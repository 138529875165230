<template>
    <v-dialog 
        v-model="mbs_dialog.show" 
        :max-width="mbs_dialog.size?mbs_dialog.size:550"                     
        :persistent='mbs_dialog.fixed'  >
        <v-card >
            <v-card-title class="headline primary lighten-1 white--text" 
                primary-title >
                {{mbs_dialog.title}}

                <v-spacer></v-spacer>
                <v-btn @click="onNotNo()" icon dark><v-icon>close</v-icon></v-btn>
            </v-card-title> 
            <form id="fieldsForm" @submit.prevent="onNotYes()" >
                <!-- // TEXT -->
                <v-card-text>
                    {{mbs_dialog.text}}
                </v-card-text> 

                <!-- // FIELD -->
                <v-card-text class="py-0">
                    <div v-for="(item, index) in mbs_dialog.field" :key="index" >
                        <v-flex xs12   > 
                            <v-text-field required  
                            filled="" autocomplete="one-time-code"  
                            v-model="item.value" 
                            :type="item.type"
                            :label="item.label"> 
                            </v-text-field > 
                        </v-flex>   
                    </div>
                    
                </v-card-text>

                <!-- // TEXT -->
                <v-card-text>
                    {{mbs_dialog.text2}}
                </v-card-text> 
                 
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="mbs_dialog.btnNo"
                        @click="onNotNo"
                        color="blue" 
                        text="">
                        {{mbs_dialog.btnNo}}
                    </v-btn>
                    <v-btn v-if="mbs_dialog.btnYes" 
                        color="blue" 
                        :loading="input.loading"
                        type="submit" 
                        text="">
                        {{mbs_dialog.btnYes}}
                    </v-btn>
                </v-card-actions>
            </form> 
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:['notShow'], 
    data(){
        return{
            show:false,
            input:{}
        } 
    }, 
    computed:{
        mbs_dialog(){
            return  this.$store.getters.getMBS_dialog_input
        }
    },
    methods:{
        onNotNo(){
            this.MBS.events.$emit('DIALOG_NO',{
                ...this.mbs_dialog.action,
                NO:true,
                YES:false
            }) 
            this.MBS.events.$emit('DIALOG_YES',{
                ...this.mbs_dialog.action,
                NO:true,
                YES:false
            }) 
            this.$store.dispatch('setMBS_dialog_input',{})
        },
        onNotYes(){
            let field = this.mbs_dialog?this.mbs_dialog.field:null
            let action = {}
            if (field) {
                field.forEach(element => {
                    action[element.field] = element.value
                });
            }
 
            this.MBS.events.$emit('DIALOG_YES',{
                ...this.mbs_dialog.action,
                ...action,
                NO:false,
                YES:true
            }) 
            this.$store.dispatch('setMBS_dialog_input',{})
        }
    },
    watch:{
        mbs_dialog(value){ 
        },
         
    } 
}
</script>
