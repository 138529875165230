<template> 
    <v-card 
        :height="height" 
        color="primary lighten-2"
        tile="">  
        <!-- //IMAGE -->
        <v-carousel 
            cycle=""
            show-arrows-on-hover=""
            hide-delimiter-background
            :hide-delimiters="!indicator"
            interval="9000"
            :height="height" >
            <v-carousel-item  
                v-for="(item,i) in items"
                :key="i"
                :src="item.link"
                lazy-src="https://picsum.photos/10/6?image=3" > 
                <v-overlay z-index="-1" class="not-shadow"  
                    :color="color" 
                    :opacity="item.opacity?item.opacity:opacity?opacity:'0.2'" 
                    absolute="">
                </v-overlay>     
                    <br><br>
                <v-layout class="" align-center="" fill-height="">
                    <v-container grid-list-sm > 
                        <v-col md="7" class="pa-0 ma-0"> 
                            <div v-if="item.title" :class="MBS.data.animate.flip.inY">
                                <div  :class="TITLE_SIZE+' font-weight-black'">{{item.title}}</div>   
                            </div>
                            <div v-if="item.text" :class="MBS.data.animate.back.inRight">
                                <div class="not-f6 py-5">{{item.text}}</div>  
                            </div>
                            <br>
                            <v-layout v-if="item.contacts" row wrap :class="MBS.data.animate.bounce.inUp">
                                <v-btn class="mr-2"
                                    large="" 
                                    tile=""
                                    to="/services"
                                    color="blue">See More</v-btn>
                                <v-btn class="mr-2"
                                    large="" 
                                    tile=""
                                    to="/contact"
                                    outlined=""
                                    color="white">Contact Us</v-btn> 
                            </v-layout>
                        </v-col>  
                    </v-container>
                </v-layout> 
            </v-carousel-item> 
        </v-carousel>   
    </v-card> 
</template>

<script> 
    export default {
        props:['height','color','indicator','opacity','items'],
        data(){
            return{
                show:false,  
            }
        },
        created(){ 
        },
        mounted(){   
        },
        computed:{ 
            vs(){
                return this.$vuetify.breakpoint
            },  
            TITLE_SIZE(){
              let n = 'not-f12' 
              name = this.vs.name 
              if(name=="xs"){
                  n = 'not-f9' 
              }else if (name=='sm') {
                  n = 'not-f10' 
              }else if (name=='md') {
                  n = 'not-f11' 
              }else if (name=='lg') {
                  n = 'not-f11' 
              }else if (name=='xl') {
                  n = 'not-f12' 
              }else{
                  n = 'not-f12' 
              }
              return n
          },
        },
        methods:{ 
        },
        watch:{ 
        } 

    }
</script>
<style>
  
</style>

