<template> 
    <v-layout fill-height="" align-center="">
        
        <div v-for="(item, index) in items" 
            :key="index"
            class="ml-5  not-f3 font-weight-bold">
            <v-hover v-slot:default="{ hover }">
                <router-link :to="item.link" class="not-line0">
                    <v-layout>
                        <!-- <v-icon size="12">mdi cloud_download_outline</v-icon> -->
                        <!-- //ACTIVE -->
                        <div v-if="$route.fullPath===item.link" :class="color?color:'c5--text'">
                            <div :class="hover?'c5--text ':''">{{mbs.TEXT_UP(item.name)}}</div>
                        </div>
                        <!-- //NOT ACTIVE -->
                        <div v-else :class="color?color:'white--text'">
                            <div :class="hover?'c5--text':tb.textColor+'--text'">{{mbs.TEXT_UP(item.name)}}</div>
                        </div>
                        
                    </v-layout> 
                </router-link>  
            </v-hover>
        </div>
    </v-layout>
</template>

<script>
 
export default {
    props:['items','color','color2','size','space'],
    data(){
        return{
            show:false, 
        }
    },
    
    mounted(){   
    },
    computed:{
        //commony
        mbs(){
            return this.MBS.actions
        },
        vs(){
                return this.$vuetify.breakpoint
        }, 
        vsn(){
            return this.vs.name
        },
        us(){
            return this.mbs.us
        },
        ud(){
            return this.mbs.ud
        },
        app(){ 
            return this.$store.getters.getApp
        },
        tb(){ 
            return this.$store.getters.getMBS_toolbar
        },
        tb(){ 
            return this.$store.getters.getMBS_toolbar
        },     
         
    },
    methods:{
      navOpen(){
        this.$emit('navOpen')    
      },
      onlogout(){
          this.show=false
          this.$store.dispatch('fi_signUserOut')
      },
      onCPanel(){
          this.show=false 
          this.$router.push('/cpanel')
      }
        

    },
    watch:{
      
    }
    

}
</script>
<style>
  
</style>

