<template>
  <div v-if="nav.show">
    <v-navigation-drawer   app
      v-model="nav.show" 
      :mini-variant="nav.min" mini-variant-width="80" 
      :clipped ="false" 
      :expand-on-hover ="false"
      :color="nav.color?nav.color:'b1' "
      :temporary="false" 
      :permanent="nav.min "
      >  
      <v-layout fill-height  column class=""> 

        <!-- //KIPCOUNT LOGO -->
        <div>
          <v-list class="">
            <v-list-item class="">
              <v-list-item-avatar  tile > 
                <v-img src="/logo/k-pos.svg"   /> 
              </v-list-item-avatar>
              <v-list-item-content class="ml-0">
                <v-list-item-title>
                  <div v-if="!nav.min"  class="not-f7 font-weight-bold primary--text">Kipcount</div> 
                </v-list-item-title>
                <!-- <v-list-item-subtitle>System</v-list-item-subtitle> -->
                  
              </v-list-item-content>
            </v-list-item>
            
          </v-list>
          <!-- <v-divider></v-divider>  -->
        </div> 
        
        <!-- //NAME COMPUTER -->
        <!-- <v-card class="mb-1 ml-2 mr-2 pa-0" outlined @click="" >  
          <v-list-item class="ma-0 pa-0">
            <v-list-item-avatar  tile  :class="nav.min?'':'ml-3'">  
              <v-icon   size="35">mdi-devices</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="ml-" >
              <v-list-item-title>
                <div v-if="!nav.min"  class="not-f5 font-weight-bold grey--text text--darken-1">NOT NAMED</div> 
              </v-list-item-title>
              <v-list-item-subtitle>Name your device</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar><v-icon>mdi-menu-down</v-icon></v-list-item-avatar>
          </v-list-item> 
        </v-card>  -->

        <mbs-named-computer
          :nav="nav"  
          />
        
        <!-- //BODY --> 
        <v-list   
          class="mt-0 pt-0"
          :class="nav.min?'mbs-scroll-y-hide':'mbs-scroll-y'"
          dense nav >  
          <template v-for="(item, index) in LINKS" >  
            <!-- Single -->  
            <div v-if="!item.sub_links || nav.min" :key="index"> 
              <v-list-item :to='item.link' color="secondary">
                <v-list-item-action>
                  <v-layout column justify-center align-center> 
                    <v-icon>{{item.icon}}</v-icon>
                    <div v-if="nav.min" class="font-weight-bold not-f1">{{item.shortname?item.shortname:item.name}}</div>
                  </v-layout>
                </v-list-item-action>
                <v-list-item-title>{{item.name}}</v-list-item-title>  
              </v-list-item> 
            </div>   
            <!-- Group -->
            <div v-else :key="index">
              <v-divider :key="'d_'+index" v-if="(index != 1 && !nav.min && item.subheader)" /> 
              <v-subheader v-if="!nav.min && item.subheader">{{item.subheader}}</v-subheader>
              <v-list-group  
                :ripple="true"  
                :no-action ="true" :to="nav.min?item.link:''"  
                active-class="secondary--text" > 
                <v-list-item slot="activator"  class="px-0"  > 
                  <v-list-item-action @click="MBS.actions.go(item.link)">
                    <v-layout column justify-center align-center> 
                      <v-icon>{{item.icon}}</v-icon>
                      <div v-if="nav.min" class="font-weight-bold not-f1 text-center">{{item.shortname?item.shortname:item.name}}</div>
                    </v-layout>
                  </v-list-item-action>
                  <v-list-item-title @click="MBS.actions.go(item.link)">{{ACTIONS.TEXT_UP1(item.name)}}</v-list-item-title>
                </v-list-item> 

                <!-- //SUB1 -->
                <v-list class="ml-3">
                <template v-for=" (sub_link, index2) in item.sub_links" >
                  <v-list-item
                    v-if="!sub_link.sub_links"
                    :key="index2"
                    :to="''+sub_link.link" >
                        <v-list-item-action>
                        <v-icon >{{sub_link.icon}}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title >{{sub_link.name}}</v-list-item-title>
                  </v-list-item>  
                  <v-list-group
                    v-else
                    :key="index2"
                    :ripple="false" 
                    :prepend-icon="sub_link.icon" 
                    :no-action ="true"   
                    active-class="secondary--text" >
                        <v-list-item slot="activator"  class="px-0"  > 
                          <v-list-item-title @click="MBS.actions.go(sub_link.link)">{{ACTIONS.TEXT_UP1(sub_link.name)}}</v-list-item-title>
                        </v-list-item> 

                        <!-- //SUB2 -->
                        <v-list class="ml-3">
                          <template v-for=" (sub_link2, index2) in sub_link.sub_links" >
                            <v-list-item
                              v-if="!sub_link2.sub_links"
                              :key="index2"
                              :to="''+sub_link2.link" >
                                  <v-list-item-action>
                                  <v-icon >{{sub_link2.icon}}</v-icon>
                                  </v-list-item-action>
                                  <v-list-item-title >{{sub_link2.name}}</v-list-item-title>
                            </v-list-item>  
                            <v-list-group
                              v-else
                              :key="index2"
                              :ripple="false" 
                              :prepend-icon="sub_link2.icon" 
                              :no-action ="true"   
                              active-class="secondary--text" >
                                  <v-list-item slot="activator"  class="px-0"  > 
                                    <v-list-item-title @click="MBS.actions.go(sub_link2.link)">{{ACTIONS.TEXT_UP1(sub_link2.name)}}</v-list-item-title>
                                  </v-list-item> 

                                  <!-- //SUB3 -->
                                  <v-list class="ml-3">
                                    <template v-for=" (sub_link3, index2) in sub_link2.sub_links" >
                                      <v-list-item
                                        v-if="!sub_link3.sub_links"
                                        :key="index2"
                                        :to="''+sub_link3.link" >
                                            <v-list-item-action>
                                            <v-icon >{{sub_link3.icon}}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-title >{{sub_link3.name}}</v-list-item-title>
                                      </v-list-item>  
                                      <v-list-group
                                        v-else
                                        :key="index2"
                                        :ripple="false" 
                                        :prepend-icon="sub_link3.icon" 
                                        :no-action ="true"   
                                        active-class="secondary--text" >
                                            <v-list-item slot="activator"  class="px-0"  > 
                                              <v-list-item-title @click="MBS.actions.go(sub_link3.link)">{{ACTIONS.TEXT_UP1(sub_link3.name)}}</v-list-item-title>
                                            </v-list-item> 

                                            <v-list-item
                                              v-for=" (sub_link4, index3) in sub_link3.sub_links"
                                              :key="'3_'+index3"
                                              :to="''+sub_link4.link" >
                                                  <v-list-item-action>
                                                  <v-icon >{{sub_link4.icon}}</v-icon>
                                                  </v-list-item-action>
                                                  <v-list-item-title >{{sub_link4.name}}</v-list-item-title>
                                            </v-list-item>  
                                      </v-list-group>   
                                    </template> 
                                  </v-list>
                            </v-list-group>   
                          </template> 
                        </v-list>
    
                  </v-list-group>   
                </template> 
                </v-list> 
              </v-list-group>  
            </div> 
          </template> 
        </v-list>  

        <!-- //BOTTOM -->
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-list   color="b1" nav>
          <v-list-item @click="nav.min=!nav.min">
            <v-list-item-action > 
                <v-icon v-if="nav.min">keyboard_arrow_right</v-icon> 
            </v-list-item-action>
            <v-list-item-content> 
              <div class="font-weight-bold font-italic not-f4 footer--text ">V {{DATA.APP.VERSION}}</div>
            </v-list-item-content>
            <v-list-item-action> 
                <v-icon v-if="!nav.min">keyboard_arrow_left</v-icon> 
            </v-list-item-action>
          </v-list-item> 
          
        </v-list> 
      </v-layout>
    </v-navigation-drawer> 
  </div>
</template>

<script>
  import DATA from "../../plugins/DATA"
  export default {
    props:[''],
    beforeCreate(){},
    created(){
        this.MBS.events.$on('NAVBAR_OPEN', this.NAVBAR_OPEN);
        // this.MBS.actions.nav({
        //   show:false
        // })
    },
    mounted(){
        
    },
    data(){
      return{
        show:true,
        mini: false,
        width:300,
        temporary:true,
        menuItems:null,
        DATA:DATA
      }
        
    },
    computed:{
      //common 
      vs(){
          return this.$vuetify.breakpoint
      }, 
      vsh(){
        return this.vs.height
      },
      us(){
          let returnUser = this.$store.getters.getUser
          return returnUser
      }, 
      ud(){
          let returnUser = this.$store.getters.getUserDetails
          return returnUser
      }, 
      loading(){ 
          const loading = this.$store.getters.getLoading
          return loading
      },
      processes(){ 
          const process = this.$store.getters.getProcesses
          return process
      }, 
      responses(){ 
          const re = this.$store.getters.getResponses
          return re
      },
      ACTIONS(){
          return this.MBS.actions
      }, 
      /////////
      tb(){ 
          return this.$store.getters.getMBS_toolbar
      }, 
      nav(){ 
        return this.$store.getters.getMBS_nav
      }, 
      LINKS(){
        let links = this.MBS.actions.LINKS  
        return links 
      },   
      SOCIAL_MEDIA_LINKS(){
          let links = this.$store.getters.getSocialMediaLinks
          return links
      },
      directoryCategories(){
          let links = this.$store.getters.getDirectoryCategories
          if (links) {
            links = links.filter(link=>{
              return link.type == 'parent'
            })
          }
          return links
      }
      
    },
    methods:{ 
      NAVBAR_OPEN(event){ 
        this.show = !this.show
      },
      onNotNo(){
        this.$emit('notNo') 
      },
      onNotYes(){
        this.$emit('notYes') 
      },  
      //LOAD
      FEEDBACK(t){
      if (!t) {
          t=100
      }
      setTimeout(()=>{
          let us = this.us
          let ud = this.ud
          let ps = this.processes
          let ld = this.loading 

          //CHECK USER 
          if (us) {
            this.menuItems=this.men2
          }else{
            this.menuItems=this.men1   
          } 

          //CHECK USER DETAILS 
          if (ud) {
                
          }else{
                
          } 
      },t)
    },
      
  },
    watch:{
        us(value){
          this.FEEDBACK(1)
        },
        ud(value){
          this.FEEDBACK(1)
        }, 
        show(value){ 
            let nav = this.nav
            let show = nav?nav.show:false
            console.log(show,'...');
          }, 
      }

  }
</script>
 
