<template  >
<v-layout column fill-height>
    <!-- //LOADING --> 
    <v-card  v-if="!PAGE_LOAD" 
        :height="'100%'" >
        <mbs-page-spinner 
            height="500"/> 
    </v-card>

    

    <!-- //SHOW-PAGE -->
    <div v-else class=" pt-3 ">    
        <v-container grid-list-xs>
            <v-card rounded="lg" >
                <v-stepper v-model="step" vertical flat> 
                    <v-card tile>
                        <mbs-page-tab
                        :items="pageTaps" />  
                    </v-card>
                    <h2 class="headline pt-4 pl-2  ">Creating new account</h2>
                    <!-- Step 1 signup with Phone-->
                    <v-stepper-step 
                        :complete="step > 1" 
                        step="1">
                        Phone number register
                    </v-stepper-step>
                    <v-stepper-content step="1">
                        <v-card
                            color="grey  lighten-2 primary--text " 
                            class="mb-5 pa-2">
                            <h2 class="font-weight-light">PHONE NUMBER</h2>
                            <v-card-text>
                                <v-layout row wrap >
                                    <v-flex xs12 md4 >
                                        <v-layout class="mr-1"> 
                                            <v-autocomplete
                                                v-model="select.country"
                                                :items="countryNames" 
                                                filled
                                                background-color="white"
                                                label="Country" >
                                                <template 
                                                    slot="selection"
                                                    slot-scope="data" >
                                                    <v-chip 
                                                        :input-value="data.selected"
                                                        :disabled="data.disabled"
                                                        :key="JSON.stringify(data.item)"
                                                        class="v-chip--select-multi"
                                                        
                                                        @input="data.parent.selectItem(data.item)" >
                                                        <v-avatar v-if="select.country!==''" 
                                                            class="mr-2" >
                                                                <span 
                                                                    style="font-size:30px"   >
                                                                    <flag :iso="select.country.split(':')[0]" />   
                                                                </span> 
                                                        </v-avatar>

                                                        {{ data.item.split(': ')[1] }}
                                                    </v-chip> 
                                                </template>
                                            </v-autocomplete>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                        <v-text-field
                                            filled
                                            background-color="white"
                                            id="inputPhone"  
                                            v-model="inputPhone"
                                            append-icon="phone" 
                                            label="Phone Number"></v-text-field> 
                                    </v-flex> 
                                </v-layout>
                            
                            </v-card-text>
                            <small >Select country and enter your phone number to continue.</small>
                        </v-card>  
                        <v-btn  
                            @click="CHECK_USER()"
                            :loading="load"
                            :disabled="!GET_PHONE_NUMBER(true).valid"
                            color="primary" >Continue
                            <v-icon  >chevron_right</v-icon>
                        </v-btn> 
                        <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                    </v-stepper-content>

                    <!-- Step 2 add user info --> 
                    <v-stepper-step 
                        :complete="step > 2"
                        step="2">User information
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <form id="personalForm" @submit.prevent="CREATE_USER()">
                            <v-card
                                color="grey  lighten-2 primary--text " 
                                class="mb-5 pa-3"
                                >
                                <h2 class="font-weight-light">INFORMATION</h2>
                                <v-card-text > 
                                    <v-row>
                                        
                                        <v-col 
                                            sm="6"
                                            cols="12">
                                            <v-text-field 
                                                filled 
                                                background-color="white"
                                                v-model="input.names"
                                                prepend-inner-icon="person"
                                                type="text"
                                                :mask="mask.name"
                                                required 
                                                label="Names"/>  
                                        </v-col> 
                                        <v-col 
                                            sm="6"
                                            cols="12">
                                            <v-text-field 
                                                filled 
                                                background-color="white"
                                                v-model="input.surname"
                                                prepend-inner-icon="person"
                                                type="text"
                                                :mask="mask.name"
                                                required 
                                                label="Surname"/>  
                                        </v-col>
                                        <v-col 
                                            sm="12"
                                            cols="12">
                                            <v-select  
                                                filled 
                                                background-color="white"
                                                :items="genderType"
                                                v-model="input.gender"
                                                label="Gender" 
                                                hint="Gender" required
                                                prepend-inner-icon="accessibility" /> 
                                            
                                        </v-col> 
                                        <!-- <v-col
                                            sm="6"
                                            cols="12">
                                            
                                            <v-menu  
                                                v-model="menu_date_picker" 
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto" >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="input.date_of_birth" 
                                                    label="Date of Birth" 
                                                    append-icon="mdi-calendar"
                                                    background-color="white" 
                                                    readonly filled required
                                                    type="date"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="input.date_of_birth"  
                                                    @input="menu_date_picker = false"
                                                    ></v-date-picker>
                                            </v-menu>
                                            
                                        </v-col> -->
                                        <!-- <v-col 
                                            sm="12"
                                            cols="12">
                                            <v-text-field 
                                                filled required
                                                background-color="white"
                                                v-model="input.next_of_kin_name"
                                                prepend-inner-icon="person"
                                                type="text"
                                                :mask="mask.name" 
                                                label="Next of kin name"/>  
                                        </v-col>  -->
                                        <!-- <v-col 
                                            sm="6"
                                            cols="12">
                                            <v-text-field 
                                                filled required
                                                background-color="white"
                                                v-model="input.next_of_kin_phone_number"
                                                prepend-inner-icon="person"
                                                type="text"
                                                :mask="mask.name" 
                                                label="Next of kin phone number"/>  
                                        </v-col>  -->
                                        <!-- <v-col
                                            sm="6"
                                            cols="12"> 
                                            <v-autocomplete
                                                v-model="input.next_of_kin_relation" 
                                                filled required
                                                prepend-inner-icon="person"
                                                background-color="white"
                                                :items="relationship"
                                                :item-value="'code'"
                                                :item-text="'name'"
                                                label="Next of kin relation" />
                                            
                                        </v-col> -->
                                        <!-- <v-col
                                            sm="6"
                                            cols="12"> 
                                            <v-autocomplete
                                                v-model="input.resident_district_code" 
                                                filled required
                                                prepend-inner-icon="map" 
                                                background-color="white"
                                                :items="districts"
                                                :item-value="'code'"
                                                :item-text="'name'"
                                                label="Select Resident District" />
                                            
                                        </v-col> -->
                                        <!-- <v-col
                                            sm="12"
                                            cols="12">
                                            <v-textarea 
                                                filled 
                                                background-color="white"
                                                required
                                                prepend-inner-icon="fa fa-map" 
                                                v-model="input.location_address"
                                                label="Location / Home Address"/> 
                                            
                                        </v-col> -->
                                        <!-- <v-col
                                            sm="6"
                                            cols="12"> 
                                            <v-autocomplete
                                                v-model="input.home_district_code" 
                                                filled required
                                                prepend-inner-icon="map" 
                                                background-color="white"
                                                :items="districts"
                                                :item-value="'code'"
                                                :item-text="'name'"
                                                label="Select Home District" /> 
                                        </v-col> -->
                                        
                                        <v-col>
                                            <div>To <strong>"Continue"</strong>, you must agree to the <v-btn color="c5" text to="/terms">Terms</v-btn> and <v-btn color="c5" text to="policy">Privacy Policy</v-btn></div>
                                            <v-checkbox
                                                v-model="input.agree"
                                                :rules="[v => !!v || 'You must agree to continue!']"
                                                label="Do you agree?"
                                                required
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>  
                                </v-card-text> 
                            </v-card>
                            <v-btn 
                                @click.native="NEXT_STEP(1,1000)">
                                <v-icon>chevron_left</v-icon>
                                Back
                            </v-btn>
                            <v-btn 
                                :loading="load"
                                type="submit"
                                color="primary">
                                Continue
                                <v-icon >chevron_right</v-icon>
                            </v-btn> 
                            <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                        </form>   
                    </v-stepper-content> 

                    <!-- Step 3 confirm number -->
                    <v-stepper-step 
                        :complete="step > 3" 
                        step="3">
                        Verify user's phone number
                    </v-stepper-step>
                    <v-stepper-content step="3">
                        <v-card
                            color="grey  lighten-2 primary--text " 
                            class="mb-5 pa-2">
                            <v-card-title > 
                                <h2 class="font-weight-light">VERIFY USER'S PHONE NUMBER</h2>
                                <v-spacer></v-spacer> 
                            </v-card-title>
                            <v-card-text> 
                                <v-card>
                                    <v-layout align-center class="pa-4 ">
                                        <div class="not-f7 font-weight-bold">{{GET_PHONE_NUMBER()?GET_PHONE_NUMBER(true).international:".."}}</div>
                                    </v-layout> 
                                </v-card>
                            </v-card-text>  
                            <small>Click the Verify Button bellow to send verification code sms to the users phone number.</small>
                        </v-card> 
                        <!-- <v-btn 
                            @click.native="NEXT_STEP(2,1000)">
                            <v-icon>chevron_left</v-icon>
                            Back
                        </v-btn> -->
                        <v-btn 
                            id="bth_verify_number"
                            :disabled="!GET_PHONE_NUMBER(true).valid"
                            color="primary" 
                            ref="bthVerifyNumber"
                            :loading="load" >Verify
                            <v-icon class="ml-2" >message</v-icon>
                        </v-btn>
                        <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                    </v-stepper-content> 

                    <!-- Step 4 confirm number -->
                    <v-stepper-step 
                        :complete="step > 4" 
                        step="4">
                        Confirm user's phone number
                    </v-stepper-step>
                    <v-stepper-content step="4">
                        <form id="code" @submit.prevent="CONFIRM_PHONE_NUMBER(input.phoneConfirm)">
                            <v-card
                                color="grey  lighten-2 primary--text " 
                                class="mb-5 pa-2">
                                <v-card-title > 
                                    <h2 class="font-weight-light">CONFIRM YOUR PHONE NUMBER</h2>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="REVERIFY_NUMBER()"
                                        small=""
                                        dark="" 
                                        class="blue" >Resend 
                                        <v-icon >refresh</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text> 
                                    <v-layout align-center>
                                        <v-text-field 
                                            filled
                                            background-color="white"
                                            :mask="mask.code"
                                            v-model="input.phoneConfirm"
                                            append-icon="announcement"
                                            type="number"
                                            label="Enter confirmation code"></v-text-field>
                                    </v-layout>
                                </v-card-text>  
                                <small>Please, Enter the confirmation code sent to your number.You can click Resend button to resend the code, if it's taking too long.</small>
                            </v-card>
                            <v-btn 
                                @click.native="step = 4">
                                <v-icon  >chevron_left</v-icon>
                                Back
                            </v-btn>
                            <v-btn 
                                :disabled="input.phoneConfirm.length<6"
                                color="primary" 
                                type="submit"
                                :loading="load" >Continue
                                <v-icon  >chevron_right</v-icon>
                            </v-btn>
                            <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                        </form>
                    </v-stepper-content> 

                    <!-- step 5 setup password --> 
                    <v-stepper-step 
                        id="step6"
                        :complete="step > 5"
                        step="5">Setup Password
                    </v-stepper-step>
                    <v-stepper-content step="5">
                        <form id="setEmailForm" @submit.prevent="UPDATE_PASSWORD()">
                            <v-card
                                color="grey  lighten-2 primary--text " 
                                class="mb-5 pa-2"
                                >
                                <h2 class="font-weight-light">Create user Password</h2>
                                <v-card-text > 
                                <!-- todo -->  
                                    <v-layout align-center>
                                        <v-text-field 
                                        filled
                                        background-color="white"
                                        prepend-inner-icon="lock"
                                        v-model="input.password"
                                        :append-icon="SHOW_PASSWORD ? 'visibility' : 'visibility_off'"
                                        @click:append="() => (SHOW_PASSWORD = !SHOW_PASSWORD)"
                                        :type="SHOW_PASSWORD ? 'password' : 'text'"
                                        name="input-10-1"
                                        label="Enter your password"
                                        hint="At least 8 characters"
                                        min="6"
                                        required 
                                        counter></v-text-field>
                                        
                                    </v-layout>
                                    <v-layout align-center>
                                        <v-text-field 
                                        filled
                                        background-color="white"
                                        prepend-inner-icon="lock"
                                        v-model="input.password_confirm"
                                        :append-icon="SHOW_PASSWORD ? 'visibility' : 'visibility_off'"
                                        @click:append="() => (SHOW_PASSWORD = !SHOW_PASSWORD)"
                                        :type="SHOW_PASSWORD ? 'password' : 'text'"
                                        name="input-10-1"
                                        label="Password_confirm"
                                        hint="At least 8 characters"
                                        min="8"
                                        required 
                                        counter></v-text-field> 
                                    </v-layout>
                                    
                                </v-card-text>
                                    <small>Create your User Password. it will be used for signing and other security issues.</small>
                            </v-card>
                            <v-btn 
                                @click.native="step = 5">
                                <v-icon  >chevron_left</v-icon>
                                Back
                            </v-btn>
                            <v-btn 
                                :loading="load"
                                type="submit"
                                color="primary"  
                            >Continue
                                <v-icon  >chevron_right</v-icon>
                            </v-btn>
                            
                            <v-btn text @click="CANCEL_SIGNING()">Cancel</v-btn>
                        </form>   
                    </v-stepper-content> 
                     
                </v-stepper>   
            </v-card> 
        </v-container>
    </div> 
</v-layout> 
</template>
 
<script>
    //firebase
    import firebase  from 'firebase/app'
    import 'firebase/auth' 
    import DATA from '../../../plugins/DATA'

    let PAGE_NAME = 'SIGN_UP' 
    export default {
        name:PAGE_NAME,
        data () {
        return {   
            PAGE_LOAD:false,    
            ALREADY_REGISTERED_US:null,
            ALREADY_REGISTERED_UD:null,
            USER_ALREADY_REGISTERED:false, 
            NEW_USER:null,
            VERIFICATION_ID:null,
            SHOW_PASSWORD: true,  
            DATA:DATA,
            pageTaps:[
                {name:"Register",link:"/signup"},
                {name:"Recover Password",link:"/recover-password"},
            ],
            
            
            load:false,
            step:1,  
            //---------selectors---------
            select:{
                signOption:'',
                accountType:'',
                businessType:'',
                country:''
            }, 
            input:{
                names:'',
                surname:'',
                gender:'',
                date_of_birth:'',
                phoneConfirm:'',
                email:'',
                password:'',
                passwordConfirm:'',
    
                district_code:'',
                location_area:'',
                location_street:'',
                agree:false
            }, 
            inputPhone:'',
            menu_date_picker:false,
            //-----------Form rules----------
            rules: { 
                it_os:[
                    val => (val || '').length > 0 || 'You must select OS'
                ],
                it_brand:'',
                it_name:'',
                it_model:'',  
            }, 
            //-----------mask---------------
            mask: {
                price:'########',
                year:'####',
                code:'######',
                phone:'phone',
                name:'Nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn',
                date:'date',
                yEmail:'nnnnnnnnnnnnnnnnnnnn'
            }, 
            genderType:['Male','Female'],
            relationship:['Family Member','Friend'],
            signOption:[
                "Use Phone Number","Use Gmail Account"
            ]
            
        }
        },
        created(){ 
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                })
            }
        },
        mounted(){ 
            try {
                //set header
                this.MBS.actions.header({
                    show:false,
                    type:'subToolbar',  
                    height:80 
                })
                // this.MBS.actions.toolbar({
                //     show:true,
                //     height:70,
                //     flat:false,
                //     color:'primary',
                //     textColor:'white', 
                //     side:'right'
                // }) 
                this.FEEDBACKS()   
                if (this.us) {
                    this.CONTINUE_REGISTER(this.ud)
                }
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                })
            }
        },
        computed: {
            //common 
            vs(){
                return this.$vuetify.breakpoint
            }, 
            us(){
                let returnUser = this.$store.getters.getUser
                return returnUser
            }, 
            ud(){
                let returnUser = this.$store.getters.getUserDetails
                return returnUser
            }, 
            loading(){ 
                const loading = this.$store.getters.getLoading
                return loading
            },
            processes(){ 
                const process = this.$store.getters.getProcesses
                return process
            }, 
            responses(){ 
                const re = this.$store.getters.getResponses
                return re
            },

            ///////// 
            districts(){ 
                const re = this.$store.getters.getDistricts
                return re
            },
            locations(){
                let districts =[]
                let all = this.$store.getters.getCustomCountries
                if(!all){return null}
                all.forEach(country => {
                    let allD = country.districts
                    allD.forEach(dis => {
                        districts.push(dis.name+' ('+dis.region+' Region)')
                    })
                })
                return districts   
            },
            countryNames(){
                let names = []
                let countries = this.$store.getters.getCountries 
                for (const key in countries) {
                    const cn = countries[key]
                    if (cn.alpha2!== undefined) {
                        names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                    } 
                } 
                //names=['Mw: Malawi (+265)']
                return names
            }, 
            iLocation(){ 
                const i = this.$store.getters.getIpLocation
                return i
            },
            backup_GET_PHONE_NUMBER(){
                try {
                    let us = this.us  
                    let input_country = ''+this.select.country
                    let input_phone = ''+this.inputPhone 
                    let countryNames = this.countryNames 
                    let PHONE = this.MBS.phone 
                    return all =>{
                        let current_phone = ''+input_phone 
                        let ip_phone = null 
                        let ip_countryPhone = null 

                        if (input_phone) {
                            input_phone = input_phone.replace(/[^0-9+]/g,'')
                        }
                        if (input_country) {
                            input_country = input_country.replace(/[^0-9]/g,'')
                        } 

                        if (input_phone) { 
                            ip_phone = PHONE(input_phone)
                        }
                        if (input_country) { 
                            ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                        } 

                        if (ip_phone) {
                            let possible = ip_phone.g?ip_phone.g.possible:null
                            if (possible) {
                                current_phone = ip_phone.g

                                if (countryNames) { 
                                    let regionCode = current_phone.regionCode
                                    let countryName = countryNames.find(item=>{
                                        return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                    })
                                    if (countryName != this.select.country) {
                                        this.select.country = countryName
                                    } 
                                }
                            }
                        }
                        if (ip_countryPhone) {
                            let possible = ip_countryPhone.g?ip_countryPhone.g.possible:null
                            if (possible) {
                                current_phone = ip_countryPhone.g
                            }
                        } 

                        if (current_phone.valid) {
                            if (this.inputPhone != current_phone.number.international ) { 
                                this.inputPhone = current_phone.number.international
                            }
                        } 

                        let returnData = {
                            ...current_phone,
                            ...current_phone.number,
                            phone:current_phone.number?current_phone.number.e164:'',
                            phone_number:current_phone.number?current_phone.number.e164:'',
                        }
                        // console.log(input_phone,'input_phone.........');
                        // console.log(input_country,'input_country.........');  
                        // console.log(ip_countryPhone,'ip_countryPhone.........');
                        // console.log(ip_phone,'ip_phone.........');  
                        // console.log(current_phone,'current_phone.........'); 
                        // console.log(returnData,'returnData.........'); 

                        if (all) {
                            return returnData
                        } else {
                            return current_phone.number?current_phone.number.e164:''
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }  
            },
            GET_PHONE_NUMBER(){
                try {
                    let us = this.us  
                    let input_country = ''+this.select.country
                    let input_phone = ''+this.inputPhone 
                    let countryNames = this.countryNames 
                    let PHONE = this.MBS.phone 
                    return all =>{
                        let current_phone = ''+input_phone 
                        let ip_phone = null 
                        let ip_countryPhone = null 

                        if (input_phone) {
                            input_phone = input_phone.replace(/[^0-9+]/g,'')
                        }if (input_country) {
                            input_country = input_country.replace(/[^0-9]/g,'')
                        } 

                        if (input_phone) { 
                            ip_phone = PHONE(input_phone)
                        }if (input_country) { 
                            ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                        } 

                        if (ip_phone) {
                            let possible = ip_phone.g?ip_phone.g.possible:null
                            if (possible) {
                                current_phone = ip_phone.g

                                if (countryNames) { 
                                    let regionCode = current_phone.regionCode
                                    let countryName = countryNames.find(item=>{
                                        return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                    })
                                    if (countryName != this.select.country) {
                                        this.select.country = countryName
                                    } 
                                }
                            }
                        }
                        if (ip_countryPhone) {
                            let possible = ip_countryPhone.a?ip_countryPhone.a.possible:null
                            if (possible) {
                                current_phone = ip_countryPhone.a
                            }
                        }else{

                        }

                        if (current_phone.valid) { 
                            if (''+this.inputPhone != ''+current_phone.number.international ) { 
                                setTimeout(() => {
                                    this.inputPhone = ''+current_phone.number.international  
                                }, 1);
                            }
                        } 
                        // console.log(input_phone,'input_phone.........');
                        // console.log(input_country,'input_country.........');  
                        // console.log(ip_countryPhone,'ip_countryPhone.........');
                        // console.log(ip_phone,'ip_phone.........');  
                        // console.log(current_phone,'current_phone.........'); 

                        if (all) {
                            return {
                                ...current_phone,
                                ...current_phone.number,
                                phone:current_phone.number?current_phone.number.e164:'',
                                phone_number:current_phone.number?current_phone.number.e164:'',

                            }
                        } else {
                            return current_phone.number?current_phone.number.e164:''
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }  
            },
        }, 
        methods: { 
            ADD_INPUT_DATA(ud){
                try { 
                    if (ud) {  
                        this.input.names = ud.names
                        this.input.surname = ud.surname
                        this.input.gender = ud.gender
                        // this.input.date_of_birth=ud.date_of_birth
                        this.inputPhone = ud.phone_number
                        this.input.email = ud.email 
                        // this.input.next_of_kin_name = ud.next_of_kin_name 
                        // this.input.next_of_kin_phone_number = ud.next_of_kin_phone_number 
                        // this.input.next_of_kin_relation = ud.next_of_kin_relation 
                        // this.input.location_address = ud.location_address  
                        setTimeout(() => {
                            this.USER_ALREADY_REGISTERED = true 
                        }, 500);
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_INPUT_DATA',
                        page:PAGE_NAME, 
                    })
                }
            },  
            //RECAPTACH
            createRecaptach(){
                try {
                    const self = this; 
                    setTimeout(()=>{ 
                        if (!window.recaptchaVerifier) {  
                            // Start Firebase invisible reCAPTCHA verifier
                            firebase.auth().useDeviceLanguage();
                            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('bth_verify_number', {
                                size: 'invisible',
                                callback: (res) => {
                                    // reCAPTCHA solved, allow signInWithPhone_number. 
                                    self. VERIFY_PHONE_NUMBER(); 
                                },
                                'expired-callback': function(res) {  
                                    self.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"TIME OUT",
                                        text:"To continue Registering, Click Continue Button or Cancel Button to cancel.",
                                        btnYes:"Continue",
                                        btnNoTxt:"Cancel",
                                        action:{
                                            data:PAGE_NAME+"=REGISTER-TIMEOUT"
                                        }
                                    }) 
                                }
                            });
                            self.setRecaptach()  
                        } else {
                            self.reSetRecaptach() 
                        } 
                    },50) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'createRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            },
            setRecaptach(){
                try {
                    window.recaptchaVerifier.render().then((widgetId) => {
                        window.recaptchaWidgetId = widgetId;
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'setRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            },
            reSetRecaptach(){
                try {
                    // window.recaptchaVerifier.render().then((widgetId)=> {
                    //     // grecaptcha.reset(widgetId)
                    //     window.recaptchaWidgetId = widgetId;
                    // })
                    window.recaptchaVerifier = null
                    this.createRecaptach() 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'reSetRecaptach',
                        page:PAGE_NAME, 
                    })
                }
            },  
            NEXT_STEP (step,time) {
                try {
                    this.load= true
                    setTimeout(()=>{
                        this.step=step
                        this.load=false
                    },time) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            async CHECK_USER(){
                try {   
                    let PHONE = this.GET_PHONE_NUMBER(true)
                    if (PHONE.valid) { 
                        this.load = true
                        try {
                            let phone_number = PHONE.phone 
                            let prams = {
                                phone_number:phone_number,
                                key:DATA.APP.key.apiKey
                            }
                            let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/checkUser',prams)
                            let body = res?res.body:null
                            this.load = false
                            let user = body?body.user:null
                            let user_details = body?body.user_details:null
                            this.ALREADY_REGISTERED_US = user
                            this.ALREADY_REGISTERED_UD = user_details
                            if (user_details) {
                                this.USER_ALREADY_REGISTERED = true
                                this.CONTINUE_REGISTER(user_details)
                            }else if(user) {
                                this.USER_ALREADY_REGISTERED = true
                                console.log(user,'user..v....');  
                                this.load = true
                                let resDelete = await this.$http.post(DATA.APP.SERVER_LINK+'app/deleteUser',prams)
                                let body = resDelete?resDelete.body:null 
                                this.load = false
                                if (body.deleted) {
                                   this.CHECK_USER() 
                                }
                            } else {
                                this.USER_ALREADY_REGISTERED = false
                                this.NEXT_STEP(2,10)
                            }  
                        } catch (error) {
                            this.load = false
                            this.ALREADY_REGISTERED_US = null
                            this.ALREADY_REGISTERED_UD = null
                            this.USER_ALREADY_REGISTERED = false
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.error_0.title,
                                text:this.MBS.text.error_0.text,
                                btnYes:this.MBS.text.error_0.btnYes,
                            })
                            this.MBS.actions.error({
                                error:{
                                    error:error,
                                    phone_number:PHONE
                                },
                                from:'CHECK_USER',
                                page:PAGE_NAME, 
                            }) 
                        }
                    } else {
                        this.MBS.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.error_0.title,
                            text:this.MBS.text.error_0.text,
                            btnYes:this.MBS.text.error_0.btnYes,
                        })
                        this.MBS.actions.error({
                            error:{
                                error:'Invalid Phone Number',
                                phone_number:PHONE
                            },
                            from:'CHECK_USER',
                            page:PAGE_NAME, 
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_USER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            CREATE_USER(){
                try { 
                    let self = this
                    let date = new Date  
                    self.load = true
                    self.NEW_USER = null
                    let password = DATA.APP.DEFAULT_PASSWORD
                    let phone_number = self.GET_PHONE_NUMBER()
                    if (!phone_number) {
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:self.MBS.text.error_0.title,
                            text:self.MBS.text.error_0.text,
                            btnYes:self.MBS.text.error_0.btnYes,
                        }) 
                    }
                    let email = phone_number+this.MBS.DATA.APP.EMAIL_SERVER

                    //creating user 
                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        title:'Creating user...'
                    })
                    let STEPS = 0
                    let request = null
                    if (this.USER_ALREADY_REGISTERED) {
                        console.log(this.USER_ALREADY_REGISTERED,11111);
                        request = firebase.auth().signInWithEmailAndPassword(email,password)
                    } else {
                        console.log(this.USER_ALREADY_REGISTERED,22222);
                        request = firebase.auth().createUserWithEmailAndPassword(email,password) 
                    }

                    request.then((userCredential) => { 
                        STEPS = 2
                        self.NEW_USER = userCredential.user;  
                        self.MBS.actions.progressDialog({
                            show:true,
                            fixed:true,
                            title:'Creating...'
                        })
                        return self.NEW_USER.updateProfile({ 
                            displayName: self.input.surname+" "+ self.input.names
                        })  
                    }) 
                    .then(()=> {  
                        STEPS = 3
                        self.MBS.actions.progressDialog({
                            show:true,
                            fixed:true,
                            title:'Registering  user...'
                        }) 
                        let detail ={ 
                            self_registered:true, 
                            platform:DATA.APP.NAME,
                            phone_number:phone_number, 
                            names:self.input.names,
                            surname:self.input.surname,
                            gender:self.input.gender,
                            // date_of_birth:self.input.date_of_birth ,
                            // next_of_kin_name:self.input.next_of_kin_name ,
                            // next_of_kin_phone_number:self.input.next_of_kin_phone_number ,
                            // next_of_kin_relation:self.input.next_of_kin_relation , 
                            // location_address:self.input.location_address, 
                            uid:self.NEW_USER.uid,
                            p_uid:self.MBS.crypt.encrypt(password),
                            email:email,
                            created_by:self.NEW_USER.uid,
                            created_at:date.toISOString()
                        }; 
                        return  firebase.database().ref('USERS/'+self.NEW_USER.uid).update(detail)
                    })    
                    .then(()=>{
                        //added  user details
                        STEPS = 4 
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.NEXT_STEP(3,100)
                        self.createRecaptach()
                        let mbs_text = self.MBS.text.user_registered_verify_number(self.NEW_USER.displayName);
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })   
                    }).catch(function(error) {  
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:error.message,
                            btnYes:"OK"
                        })  
                        self.MBS.actions.error({
                            error:{
                                error:error,
                                STEPS:STEPS
                            },
                            from:'CREATE_USER',
                            page:PAGE_NAME, 
                        })
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_USER',
                        page:PAGE_NAME, 
                    })
                }
            },   
            VERIFY_PHONE_NUMBER(){
                try {
                    this.load = true
                    let self = this
                    let phoneNumber = this.GET_PHONE_NUMBER();  
                    let appVerifier = window.recaptchaVerifier 
                    let provider = new firebase.auth.PhoneAuthProvider();

                    // Turn off phone auth app verification.
                    // firebase.auth().settings.appVerificationDisabledForTesting = true; 
                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        title:'Sending verification sms...'
                    }) 
                    provider.verifyPhoneNumber(phoneNumber, appVerifier)
                    .then(function (verificationId) {
                        self.VERIFICATION_ID = verificationId 
                        self.load=false
                        self.MBS.actions.progressDialog()
                        let mbs_text = self.MBS.text.verification_sent(phoneNumber);
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })  
                        self.NEXT_STEP(4,1)
                    }) 
                    .catch((error) => { 
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:error.message,
                            btnYes:"OK"
                        })  
                        self.MBS.actions.error({
                            error:error,
                            from:'VERIFY_PHONE_NUMBER',
                            page:PAGE_NAME, 
                        })
                    });
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VERIFY_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }
            },  
            CONFIRM_PHONE_NUMBER(verificationCode){
                try {
                    this.load = true
                    let self = this 
                    let phoneNumber = this.GET_PHONE_NUMBER();  
                    let verificationId = this.VERIFICATION_ID  
                    let STEPS = 0

                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        title:'Checking code...'
                    }) 
                    let phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
                    let registerAction = null
                    if (self.NEW_USER) {
                        registerAction = self.NEW_USER.updatePhoneNumber(phoneCredential) 
                        
                    }else{
                        registerAction = firebase.auth().signInWithCredential(phoneCredential)
                         
                    }
                    registerAction.then((res)=> {   
                        STEPS = 1
                        self.MBS.actions.progressDialog({
                            show:true,
                            fixed:true,
                            title:'Confirming code...'
                        }) 
                        if(!self.NEW_USER) { 
                            self.NEW_USER = res.user
                        }
                        let detail ={ 
                            phone_number_verified:true,  
                        }; 
                        return  firebase.database().ref('USERS/'+self.NEW_USER.uid).update(detail)
                    }) 
                    .then(() => {  
                        STEPS = 2
                        self.load=false
                        let mbs_text = self.MBS.text.phone_number_success_confirmed(phoneNumber)
                        self.MBS.actions.progressDialog()  
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })
                        self.NEXT_STEP(5,10)
                    }) 
                    .catch((error) => {  
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"ERROR",
                            text:error.message,
                            btnYes:"OK"
                        })  
                        self.MBS.actions.error({
                            error:{
                                error:error,
                                STEPS:STEPS
                            },
                            from:'CONFIRM_PHONE_NUMBER',
                            page:PAGE_NAME, 
                        })
                    }); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }
            },  
            REVERIFY_NUMBER(){
                try {
                    this.load = true
                    let number = this.us?this.us.phoneNumber:null
                    if (number) { 
                        this.reSetRecaptach()
                        this.NEXT_STEP(2)
                        // let phone = new this.MBS.phone(number)
                        // let b = phone?phone.b:null
                        // if(b.b){
                        //     this.inputPhone = b.b[2]
                        //     const phoneConfirmBtn = this.$refs.phoneConfirmBtn
                        //     setTimeout(() => {
                        //         // console.log(phoneConfirmBtn);

                        //         // phoneConfirmBtn.click(this.NEXT_STEP('number',10000)) 
                        //     },1000);  
                        // }

                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REVERIFY_NUMBER',
                        page:PAGE_NAME, 
                    })
                }
            },  
            CANCEL_SIGNING(){
                try { 
                    let number = this.GET_PHONE_NUMBER?this.GET_PHONE_NUMBER():'...'
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.cancel_registering(number).title,
                        text:this.MBS.text.cancel_registering(number).text,
                        btnYes:this.MBS.text.cancel_registering(number).btnYes,
                        btnNo:this.MBS.text.cancel_registering(number).btnNo,
                        action:{
                            code:PAGE_NAME+"=CANCEL-SIGNING"
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_SIGNING',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            UPDATE_PASSWORD(){
                try {
                    let password = this.input.password
                    let password_confirm = this.input.password_confirm
                    let checked = this.MBS.actions.PASSWORD_CHECKER(password,password_confirm,true)
                    let checked_password = checked?checked.checked:null
                    if(!checked_password){return} 
                    this.load=true 
                    var user = this.us 
                    self = this   
                    self.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        text:"Setting Up password.."
                    })
                    user.updatePassword(password)
                    .then(()=>{
                        self.MBS.actions.progressDialog({
                            show:true,
                            fixed:true,
                            text:"Setting Up User Profile.."
                        })
                        return  firebase.database().ref('USERS/'+user.uid).update({
                            password_protected:true, 
                            p_uid:self.MBS.crypt.encrypt(password)
                        })
                    })
                    .then(()=> {  
                        self.MBS.actions.go('/account')
                        self.load=false
                        self.MBS.actions.progressDialog()
                        self.MBS.actions.dialog({
                            show:true,
                            fixed:true, 
                            title:"SUCCESSFULLY SIGNED UP",
                            text:"Thank you, for registering. you can now enjoy our services.",
                            btnYes:"OK", 
                            action:{
                                // code:"=GO",
                                // to:"/account/profile"
                            }
                        })  
                        return 
                    })
                    .catch(function(error) {
                        // -----------------------  ERROR------------------
                        let code = error.code
                        self.load=false
                        self.MBS.actions.progressDialog()
                        if (code==="auth/requires-recent-login") {
                            self.MBS.actions.dialog({
                                show:true,
                                fixed:true,  
                                title:"CONFIRM PHONE NUMBER",
                                text:"This operation is sensitive and requires recent authentication. You must confirm your number again before retrying this request.",
                                btnYes:"OK", 
                                action:{
                                    code:PAGE_NAME+"=REVERIFY-NUMBER", 
                                } 
                            }) 
                        } else {
                            self.MBS.actions.dialog({
                                show:true,
                                fixed:true, 
                                title:"PASSWORD NOT SET",
                                text:"Something went wrong while trying to setup your password. Please, make sure to set it later in your users account. thank you.",
                                btnYes:"OK",
                                action:{
                                    code:"=GO",
                                    to:"/account/profile"
                                }
                            }) 
                        }
                        self.MBS.actions.error({
                            error:error,
                            from:'UPDATE_PASSWORD',
                            page:PAGE_NAME, 
                        })
                    }) 
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_PASSWORD',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            CONTINUE_REGISTER(user_details){
                try {  
                    this.USER_ALREADY_REGISTERED = true 
                    let self_registered = user_details?user_details.self_registered:null
                    let password_protected = user_details?user_details.password_protected:null
                    let phone_number_verified = user_details?user_details.phone_number_verified:null 
                    
                    if (password_protected) {
                        let mbs_text = this.MBS.text.signup_already_registered
                        this.MBS.actions.dialogInput({
                            show:true,
                            fixed:true, 
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=LOGIN-ALREADY-REGISTERED", 
                                user_details:user_details
                            },
                            field:[
                                {label:"Password",field:"password",type:'password'}
                            ] 
                        }) 
                    }else if (self_registered) { 
                        let mbs_text = this.MBS.text.signup_continue_add_information(user_details.phone_number)
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=CONTINUE-REGISTER-ADD-INFORMATION",
                                user_details:user_details 
                            },
                        })
                        this.select.signOption = 'Use Phone Number'
                        this.NEXT_STEP(2) 
                    }else{
                        let mbs_text = this.MBS.text.signup_verify_continue_add_password
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=CONTINUE-REGISTER-VERIFY-NUMBER",
                                user_details:user_details 
                            },
                        })
                        this.ADD_INPUT_DATA(user_details )
                        this.select.signOption = 'Use Phone Number'
                        this.NEXT_STEP(2)  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONTINUE_REGISTER',
                        page:PAGE_NAME, 
                    })
                }
            },   
            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) {   
                        if (action.code ===PAGE_NAME+"=CANCEL-SIGNING") {
                            if (action.YES) {
                                this.MBS.actions.logout(true)
                                this.MBS.actions.go('/')
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+"=LOGIN-ALREADY-REGISTERED") {
                            if (action.YES) { 
                                let user_details = action.user_details 
                                let p_uid = user_details?user_details.p_uid:null 
                                let email = user_details?user_details.email:null 
                                let password = action.password 
                                if (email) {
                                    firebase.auth().signInWithEmailAndPassword(email,password)
                                    .then(res=>{
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:this.MBS.text.login_successful.title,
                                            text:this.MBS.text.login_successful.text,
                                            btnYes:this.MBS.text.login_successful.btnYes, 
                                        })
                                        this.MBS.actions.go('/account')
                                    }).catch(error=>{
                                        setTimeout(() => {
                                            this.MBS.actions.dialogInput({
                                                show:true,
                                                fixed:true, 
                                                title:this.MBS.text.login_wrong_password.title,
                                                text:this.MBS.text.login_wrong_password.text,
                                                btnYes:this.MBS.text.login_wrong_password.btnYes,
                                                btnNo:this.MBS.text.login_wrong_password.btnNo,
                                                action:{
                                                    code:PAGE_NAME+"=LOGIN", 
                                                    user_details:user_details
                                                },
                                                field:[
                                                    {label:"Password",field:"password",type:'password'}
                                                ]
                
                                            })  
                                        }, 1000);
                                        this.MBS.actions.error({
                                            error:error,
                                            from:'DIALOG_YES',
                                            page:PAGE_NAME, 
                                        })
                                    })
                                }else{ 
                                    self.MBS.actions.dialog({
                                        show:true,
                                        fixed:true, 
                                        title:self.MBS.text.error_0.title,
                                        text:self.MBS.text.error_0.text,
                                        btnYes:self.MBS.text.error_0.btnYes,
                                    }) 
                                }
                            } else {
                                this.MBS.actions.go('/recover-password') 
                            }
                        } 
                        if (action.code ===PAGE_NAME+"=CONTINUE-REGISTER-ADD-INFORMATION") {
                            if (action.YES) { 
                                this.select.signOption = 'Use Phone Number'
                                this.ADD_INPUT_DATA(action.user_details )
                                this.NEXT_STEP(2) 
                            } else {
                                this.MBS.actions.logout(true)
                                this.select.signOption = ''
                                this.NEXT_STEP(1)
                            }
                        }
                        if (action.code ===PAGE_NAME+"=CONTINUE-REGISTER-VERIFY-NUMBER") {
                            if (action.YES) { 
                                this.select.signOption = 'Use Phone Number'
                                this.ADD_INPUT_DATA(action.user_details )
                                this.NEXT_STEP(3) 
                                this.NEW_USER = this.us
                                this.createRecaptach()
                            } else {
                                this.MBS.actions.logout(true)
                                this.select.signOption = ''
                                this.NEXT_STEP(1)
                            }
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            }, 
            FEEDBACKS(time=1000,event){
                try { 
                    setTimeout(()=>{
                        let us = this.us
                        let ud = this.ud
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses 
                        let i = this.iLocation 
                        
        
                        //get i and set country number 
                        if (i) {
                            this.select.country = i.country.code+ ': '+i.country.name+' ('+i.country.callingCode+')' 
                        }else{
                            //this.$store.dispatch('setILocation')
                        }  
                        
                        //Check process 
                        if (ps) {
                            let logging = ps.find(process=>{
                                return process.name == 'logging'
                            }) 
        
                            if (logging) {
                                this.PAGE_LOAD =false
                            }else{
                                this.VIEW_PAGE()
                            }     
                        }else{
                            this.VIEW_PAGE()
                        }    
                    },time) 
                } catch (error) {
                    
                }
            },  
            VIEW_PAGE(){
                try {
                    let us = this.us
                    let ud = this.ud
                    let status = ud?ud.signup_status:null

                    this.PAGE_LOAD = true 
                    //CHECK USER STATE
                    if (!us) {
                    }else{
                         
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_PAGE',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        beforeDestroy(){
            let us = this.us
            let ud = this.ud
            let password_protected =ud?ud.password_protected:null
            if (!password_protected) {
                this.MBS.actions.logout(true) 
            } 
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch:{ 
            us(value){
                this.FEEDBACKS(1000)
            },
            processes(value){
                this.FEEDBACKS(1000)
            },
            loading(value){
                this.FEEDBACKS(1000)
            }, 
            iLocation(value){
                this.FEEDBACKS(1)
            }, 
            inputPhone(value){
                this.USER_ALREADY_REGISTERED = false
                this.ALREADY_REGISTERED_US = null
                this.ALREADY_REGISTERED_UD = null
            }
        }
    }
</script>