 
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import browser from 'browser-detect'
import users from './../users'
import DATA from './../../plugins/DATA'  

export default{
    state: {  
      ERRORS:[],  
    },
    //___________________-GETTERS-_________________
    getters: { 
      getErrors(state) {
        return state.ERRORS
      },   
       
    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      ERROR(state, payload) { 
        // let ERRORS = [...state.ERRORS]
        let user = users?users.state.USER:null
        let uid = user?user.uid:null
        let no_upload = payload.no_upload?payload.no_upload:null
        let page = payload.page?payload.page:null
        let from = payload.from?payload.from:null
        let text = payload.text?payload.text:null
        let error = payload.error?payload.error:null
        let error_message = error?error.message?''+error.message:''+error:''
        let date = new Date
        date = date.toISOString()
         
        let Error = {
          page:page?page:'',
          from:from?from:'',
          text:text?text:'',
          error:error?error:'',
          // payload:payload?payload:"",
          error_message:error_message,
          date:date?date:'',
          browser:browser?{
            mobile:browser().mobile,
            name:browser().name,
            os:browser().os,
            version:browser().version,
            versionNumber:browser().versionNumber
          }:'',
          user:uid,
          code_version:DATA.APP.CODE_VERSION,
          app_version:DATA.APP.VERSION,
          database_version:DATA.APP.SERVER_VERSION,
        } 
        
        // console.log("FROM: "+from+':',Error);
        // return
        if (!DATA.production) {
          Error.payload=payload
          console.log("FROM: "+from+':',Error);
        }else if (no_upload) {
          
        }else{
          console.log("FROM: "+from+':',Error);
          // return
          let path = uid?DATA.PATHS.errors+uid:DATA.PATHS.errors+'unknown'
          let ref = firebase.database().ref(path).push(Error) 
          ref.then(res => { 
            if (!DATA.production) {
              console.log("Error ADDED",res); 
            }
          }).catch(error => { 
            if (error) {
              console.log("Error NOT ADDED",error);  
            }
          }) 
        }
        state.ERRORS.push(Error)      
      },   
       
    },
    //____________________-ACTIONS-_____________________
    actions: { 
      //-------------[SETTER]---------------
      ERROR({ commit,state}, payload) { 
        commit('ERROR', payload)
      },  
      addError({ commit,state}, payload) { 
        commit('ERROR', payload)
      },  
      restErrors({state}){
        state.responses = {
          res: null,
          val: null
        }
      } 
    }
}