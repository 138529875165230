<template>
  <v-layout height="600" class="">    
    <v-layout v-if="false">
        <mbs-page-spinner :show="true" />   
    </v-layout>     
    <v-container v-else grid-list-lg>
        <v-card :min-height="vsh-250" outlined color="">
        <v-card>
            <mbs-page-tab 
                :items="MY_LINKS"/>  
            
        </v-card>
            <v-card :min-height="vsh-300" flat color="transparent" >
                <v-layout class="px-4 py-5" justify-center align-center fill-height> 
                        <div>Currently not Available!..</div>
                </v-layout>  
            </v-card>
        </v-card>
    </v-container>   
  </v-layout> 
</template>

<script>
import DATA from '../../../plugins/DATA'
import errors from '../../../store/errors'
let PAGE_NAME = DATA.ITEMS.COMPANIES.values
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_SHOW:false, 
                DATA:DATA, 
                addCompanySteps:[
                    {name:"Company Name",step:1},
                    {name:"Company Types",step:2},
                    {name:"Company Details",step:3},
                ],
                currentStep:1,
                dialogAddCompany:false,
                inputLoading:false,
                inputLoadingCompanyKey:false,
                inputCompanyKey:"",
                inputCompanyName:"",
                input:{},
                runConfirm : null

            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500)
             
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            us(){
                let returnUser = this.$store.getters.getUser
                return returnUser
            }, 
            ud(){
                let returnUser = this.$store.getters.getUserDetails
                return returnUser
            }, 
            loading(){ 
                const loading = this.$store.getters.getLoading
                return loading
            },
            processes(){ 
                const process = this.$store.getters.getProcesses
                return process
            }, 
            responses(){ 
                const re = this.$store.getters.getResponses
                return re
            },
            /////////
            MY_LINKS(){
                let links = this.MBS.actions.FIND_SUB_LINKS("account",1) 
                return links
            },
            myCompanies(){ 
                const re = this.$store.getters.getCompanies
                return re
            },
        },
        methods: { 
            clicked(){}, 
            restDialogAddCompany(){
                this.input={}
                this.inputCompanyName=''
                this.inputCompanyKey=''
                this.inputLoading=false
                this.inputLoadingCompanyKey=false
            }, 
            COMPANY_NAME_CHANGE(company_name){
                try {  
                    if (company_name) {
                        this.inputLoadingCompanyKey = true
                        this.MBS.actions.KEY_NAME(company_name)
                        .then(res=>{ 
                            this.inputLoadingCompanyKey = false
                            this.inputCompanyKey = res?res.key_name:''
                        }).catch(error=>{
                            console.log(error,'...');
                            this.inputLoadingCompanyKey = false 
                        })  
                    } else {
                        this.inputCompanyKey = '' 
                        this.input.company_name = ''
                        this.input.company_key = ''
                    }
                } catch (error) {
                    this.inputLoadingCompanyKey = false
                    this.MBS.actions.error({
                        error:error,
                        from:'COMPANY_NAME_CHANGE',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            CONFIRM_KEY(company_name, timeout = 1000){
                try { 
                    this.inputLoadingCompanyKey = true
                    if (this.runConfirm) {
                        clearTimeout(this.runConfirm)
                        this.runConfirm = null
                    } 
                    this.runConfirm = setTimeout(() => {
                        console.log('...:',company_name);
                        if (this.input.company_key != company_name) {
                            let path = this.MBS.actions.TEXT_UP(DATA.APP.SERVER+"/"+DATA.ITEMS.COMPANIES.values)
                            this.MBS.actions.KEY_NAME(company_name,path)
                            .then(res=>{ 
                                this.inputLoadingCompanyKey = false
                                this.inputCompanyKey = res?res.key_name:''
                                this.input.company_name = company_name
                                this.input.company_key = this.inputCompanyKey
                            }).catch(error=>{
                                console.log(error,'...');
                                this.inputLoadingCompanyKey = false 
                            })  
                        } else {
                            this.inputCompanyKey = company_name
                            this.inputLoadingCompanyKey = false 
                        } 
                    }, timeout);
                } catch (error) {
                    this.inputLoadingCompanyKey = false
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_KEY',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            async NEXT_STEP(step , time = 1000){
                try { 
                    let input = this.input
                    let company_name = input?input.company_name:null
                    let company_key = input?input.company_key:null
                    let company_type = input?input.company_type:null
                    let company_size = input?input.company_size:null 
                    let currentStep = this.currentStep
                    let mbs_text = null
                    let next = ()=>{
                        this.inputLoading = true
                        setTimeout(() => {
                            this.inputLoading = false
                            this.currentStep = step
                        }, time);    
                    }

                    if (currentStep == 1) { 
                        if (!company_name) {
                            mbs_text = this.MBS.text.field_required("Company name")
                        }if (!company_key) {
                            mbs_text = this.MBS.text.field_required("Company key")
                        } 
                    } 

                    if (mbs_text) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })
                    } else {
                        if (step) {
                            next()
                        } else {
                            this.CREATE_COMPANY()
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async CREATE_COMPANY(){
                try { 
                    let input = this.input
                    let company_name = input?input.company_name:null
                    let company_key = input?input.company_key:null
                    let company_type = input?input.company_type:null
                    let company_size = input?input.company_size:null 
                    let company_phone_number = input?input.company_phone_number:null 
                    let company_email_address = input?input.company_email_address:null 
                    let currentStep = this.currentStep 
                    let mbs_text = null

                    let company_details = {
                        company_name:company_name?company_name:null, 
                        company_key:company_name?company_key:null, 
                        company_size:company_size?company_size:null, 
                        company_type:company_type?company_type:null, 
                        company_phone_number:company_phone_number?company_phone_number:null, 
                        company_email_address:company_email_address?company_email_address:null, 
                    }
                    

                    if (!company_key) {
                        mbs_text = this.MBS.text.field_required("Company key")
                    }

                    if (mbs_text) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })
                    } else {
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.COMPANIES.name,"Create")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=ADD-ITEM", 
                                data:{
                                    ...company_details
                                }
                            }
                        }) 
                          
                    }
                     
                } catch (error) {
                    this.inputLoading = false
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_COMPANY',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SUBMIT_COMPANY(data){
                try {
                    console.log(data);
                    if (data) {
                        this.inputLoading = true
                        let path = this.MBS.actions.TEXT_UP(DATA.APP.SERVER+"/"+DATA.ITEMS.COMPANIES.values)+"/"+data.company_key
                        this.$store.dispatch("fi_set",{
                            path:path,
                            data:data,
                            action:DATA.ITEMS.COMPANIES.values
                        }).then(res=>{
                            this.inputLoading = false
                            let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.COMPANIES.name,"Creating")
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.title,
                            })
                            this.dialogAddCompany = false
                        }).catch(error=>{
                            this.inputLoading = false
                            let data= error?error.data:null
                            let available= data?data.available:null
                            let mbs_text = null
                            
                            console.log(error,'err.......');
                            if (available == false) {
                                mbs_text = this.MBS.text.item_action_error_exist("Company Key","Used")
                            } else {
                                mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.COMPANIES.name,"Creating")
                            } 
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.title,
                            })
    
                        })
                        
                    } else {
                        mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.COMPANIES.name,"Creating")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.title,
                        })
                    }
                       
                } catch (error) {
                    this.inputLoading = false
                    this.MBS.actions.error({
                        error:{
                            error:error,
                            data:data
                        },
                        from:'SUBMIT_COMPANY',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async SELECT_COMPANY(company){
            try {
                await this.MBS.actions.SELECT_COMPANY(company)
                this.MBS.actions.go("/")
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SELECT_COMPANY',
                    page:PAGE_NAME, 
                })
            }  
        }, 
            //LOAD 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                this.SUBMIT_COMPANY(action.data)   
                            } else {
                                
                            }
                        }   
                        if (action.code ===PAGE_NAME+'=ADD-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_add_item = false 
                            } else { 
                            }
                        } 

                        if (action.code ===PAGE_NAME+'=CONFIRM-DELETE-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = true 
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm deleting this "+action.child?this.NAME_CHILD_ITEM:this.NAME_ITEM,btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=DELETE-ITEM",
                                            }
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } else {
                                    
                                }
                            }  
                        }  
                        if (action.code ===PAGE_NAME+'=DELETE-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = false
                                        let password = action.password
                                        password = password?this.MBS.crypt.encrypt(password):null 
                                        if (!password) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        }else if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                        this.$store.dispatch("fi_delete",{
                                                action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                                path:action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/"
                                            })  
                                        } 
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        }  

                        if (action.code ===PAGE_NAME+'=AUTO-GENERATE-CODE') { 
                            if (action.YES) {
                                this.EXCEL_DATA_ITEMS= this.MBS.actions.CODE_AUTO_GENERATE(this.EXCEL_DATA_ITEMS) 
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=AUTO-SET-CODE') { 
                            if (action.YES) {
                                this.inputCodeLoading = true
                                setTimeout(() => { 
                                    this.setAutoCode()
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }
                        }
                        if (action.code===PAGE_NAME+":DELETE-MESSAGE") {
                            if (action.YES) {
                                this.$store.dispatch("fi_delete",{
                                    action:"Message",
                                    path:"MBS/MESSAGES/"+action.key
                                })  
                            } else { 
                            }
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);      
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            inputCompanyName(value){
                this.COMPANY_NAME_CHANGE(value) 
            }, 
            inputCompanyKey(value){
                this.CONFIRM_KEY(value,3000) 
            }, 
            dialogAddCompany(value){
                this.restDialogAddCompany()
            }, 
        },
  }
</script>
 
