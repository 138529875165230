 
<template>
<v-card 
    :color="'transparent'" 
    tile="" flat=""> 
    <svg v-if="type==3" xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 487.68 406.41" :width="size?size:'100%'" > 
        <path :fill="color?color:cls_2_1" d="M665.42,635.33c-48.68-14.66-96.33-3.87-144.19,4.22,22.56-5.72,45-12,67.72-17C615.74,616.69,640.27,627.87,665.42,635.33Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_1" d="M666.52,528.76l.06,0h0l0,0Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_1" d="M666.52,528.76c-.31.13-.48.57,0,0Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_1" d="M797.66,539.41a22.29,22.29,0,0,1-10.54-1.78c-11.39-4.64-22.17-11.72-34-14.25-29.08-6.25-58-1.84-86.56,5.35,5.06,11.12,11.46,21.24,14.29,32.28,5.47,21.3,9.38,43,12.92,64.77,1.11,6.79,3.2,9.56,9.94,10.17,26.06,2.34,51.75,0,77.06-6,7.52-1.78,15.33-4.55,21.52-9,4.33-3.09,9.17-10.15,8.55-14.72-2.85-20.85-1.7-43-16.36-61.81,9.73-.8,15,3.39,18,10.5,2,4.63,2.56,9.92,4.93,14.29,1.24,2.27,5,3.18,7.61,4.7,1.35-2.52,3.89-5,3.86-7.54C828.68,549.43,814.24,537.43,797.66,539.41ZM709,557.71a10.86,10.86,0,0,1-.46,1.17,31.68,31.68,0,0,1-1.74,3.09,32.72,32.72,0,0,0-1.73,3.09h0c-.37-.27-.75-.53-1.14-.79-2.73-1.8-5.82-3.36-7.54-5.86-2-3-2.14-7.19-3.43-10.75C690.07,539.81,685,535,676,535.17c-1.81,0-3.64-1.22-5.46-1.87,25.77-10.5,33.75,4.82,38.54,22.28A3.83,3.83,0,0,1,709,557.71ZM800.28,596c-1.87-8.15-4.17-16.24-5.5-24.47-2.31-14.26-10.26-24-22.77-30.39-16.14-8.3-33.6-11-51.45-12.06-1.18-.06-2.36-.16-3.54-.25C761.27,523.35,801.4,533.23,800.28,596Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_1" d="M808.56,681.7c-5.72-1.33-6.45-4.38-2.62-8.57,10.55-11.52,10.24-25.35,9.33-41.09-7,15.52-9.82,18.61-18.49,20.25-20.95,4-41.93,7.76-62.87,11.8A30,30,0,0,1,712,660.27c-5.57-3.32-11.26-6.43-18.16-10.35.89,6.21,2,10.88,2.14,15.58a182.09,182.09,0,0,1-.5,25c-.29,2.68-4,7.16-6.11,7.11-10.54-.22-21.05-1.67-31.61-2.69,3.38-20.5-4.17-35.93-18.16-36.61A21.75,21.75,0,0,0,625.82,663c-8.71,7.32-8.3,17.75-6.18,28.63l-64-5.87c1.16-12.09-2.18-19-8.64-19.28-5.85-.25-8.17,4.67-8.16,17.68l-26.23-2.21a5.87,5.87,0,0,0,3.36,2q86,18,172,35.79a20.84,20.84,0,0,0,8.9-.53c25.87-6.4,51.69-13,77.52-19.49q26.83-6.79,53.65-13.63C821.56,684.57,815,683.21,808.56,681.7Zm-81.68-7.38-.6-3.18,71.5-13.45.6,3.3Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_1" d="M658.24,545.9c-3.81-10.59-9.58-12.59-19.32-6.81-12.42,7.39-24.77,14.91-37.15,22.36Q556.49,588.72,511.2,616c-9.63,5.82-12.94,12.48-11,22.32,22.69-6.38,45.62-11.89,67.9-19.33,25.3-8.44,49.85-10,75,.29,10.95,4.48,22.64,7.16,35.26,11.05C675.68,600.41,668,572.92,658.24,545.9Zm5.84,58.51C653.23,576.22,631,566,596.75,578.91c-17.22,6.51-33.48,15.57-50.19,23.46a15.6,15.6,0,0,1-7.2,1.9q23.85-13.24,47.69-26.48c13.3-7.36,26.54-14.83,40-22,11.46-6.08,22.52-2.79,27,9,5.53,14.64,9.7,29.78,14.47,44.7l-1.56.88C665.94,608.41,664.87,606.46,664.08,604.41Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_1" d="M510.08,324.65c-8.37,91.57-5.38,183.15-4.12,275-2.95-7.34-6-14.65-8.83-22A431.36,431.36,0,0,1,468,408.27c1-33.41,16.62-60.58,40.51-83.3C508.73,324.74,509.24,324.81,510.08,324.65Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_1" d="M462.13,586.1c-17.55-34-34.53-68.32-52.67-102-9.84-18.27-9.49-35.4.68-52.9,2.64-4.53,5.38-9,9.17-15.33l64.62,211.2C476.81,613.33,469.22,599.85,462.13,586.1Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_2" d="M364.12,498.54l101.74,133C461.29,628,456.52,624.68,452,621c-25-20.12-49.66-40.69-75.08-60.28-16.65-12.83-20.45-29-16.24-48.33C361.6,508.18,362.77,504,364.12,498.54Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_2" d="M416.29,644.18c-16-5.82-31.81-12.33-47.94-17.85-18.28-6.26-28.09-23.94-27.06-46.66,43.08,28,86.49,54.06,128,83C451.69,656.43,433.9,650.56,416.29,644.18Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_3" d="M454.4,683.16c-16.28,5.31-32.53,7.45-49.23,5-14.07-2-26.61-7.07-35.1-19-4-5.68-6.38-12.57-9.86-18.64Z" transform="translate(-341.22 -324.65)"/>
        <path :fill="color?color:cls_2_3" d="M398.23,707.82l73.5,6.49c-13.64,8.3-27.53,15.35-43.28,16.61C412.54,732.19,403.13,724.81,398.23,707.82Z" transform="translate(-341.22 -324.65)"/>
    </svg>

    <svg v-else-if="type==2" xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 976.32 406.41" :width="size?size:'100%'" >  
        <path :fill="color?color:cls_2_1" d="M372.41,626.45c-48.69-14.66-96.34-3.87-144.2,4.21,22.57-5.72,45-12,67.73-17C322.72,607.8,347.26,619,372.41,626.45Z" transform="translate(-48.2 -315.76)"/> 
        <path :fill="color?color:cls_2_1" d="M373.5,519.87l.06,0h0l0,0Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M373.5,519.87c-.31.13-.48.57,0,0Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M504.64,530.53a22.44,22.44,0,0,1-10.54-1.79c-11.39-4.64-22.17-11.72-34-14.25-29.08-6.24-58-1.83-86.56,5.35,5.06,11.12,11.46,21.25,14.3,32.28,5.46,21.3,9.37,43,12.92,64.77,1.1,6.8,3.19,9.57,9.93,10.17,26.06,2.35,51.75,0,77.06-6,7.52-1.79,15.33-4.56,21.52-9,4.33-3.09,9.17-10.15,8.55-14.72-2.84-20.85-1.7-43-16.36-61.81,9.73-.8,15,3.4,18,10.5,2,4.64,2.55,9.93,4.93,14.29,1.23,2.28,5,3.18,7.6,4.7,1.35-2.51,3.89-5,3.86-7.54C535.66,540.55,521.22,528.54,504.64,530.53Zm-88.71,18.29a10.86,10.86,0,0,1-.46,1.17,31.8,31.8,0,0,1-1.74,3.1,30.4,30.4,0,0,0-1.73,3.09h0c-.37-.28-.75-.54-1.14-.8-2.73-1.8-5.82-3.36-7.54-5.86-2-3-2.14-7.19-3.43-10.74-2.84-7.86-7.94-12.71-16.92-12.5-1.81,0-3.64-1.21-5.46-1.87,25.77-10.5,33.76,4.82,38.55,22.28A3.93,3.93,0,0,1,415.93,548.82Zm91.33,38.31c-1.87-8.15-4.17-16.23-5.5-24.47-2.31-14.25-10.26-24-22.77-30.39-16.14-8.3-33.6-11-51.45-12-1.18-.07-2.36-.17-3.54-.26C468.26,514.46,508.38,524.35,507.26,587.13Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M515.54,672.81c-5.72-1.33-6.45-4.38-2.62-8.56,10.55-11.52,10.24-25.36,9.33-41.1-7,15.53-9.82,18.61-18.49,20.26-20.94,4-41.93,7.75-62.87,11.8A30,30,0,0,1,419,651.38c-5.57-3.32-11.26-6.43-18.16-10.35.89,6.22,2,10.89,2.14,15.59a182,182,0,0,1-.5,24.94c-.29,2.69-4,7.16-6.11,7.11-10.54-.22-21-1.67-31.61-2.69,3.38-20.5-4.17-35.93-18.16-36.6a21.68,21.68,0,0,0-13.77,4.7c-8.7,7.32-8.3,17.75-6.18,28.63l-64-5.87c1.16-12.08-2.18-19-8.64-19.28-5.85-.25-8.16,4.68-8.15,17.69L219.58,673a5.9,5.9,0,0,0,3.36,2q86,18,172,35.8a20.85,20.85,0,0,0,8.9-.54c25.87-6.4,51.69-13,77.53-19.49Q508.22,684,535,677.15C528.55,675.68,522,674.33,515.54,672.81Zm-81.68-7.38-.6-3.18,71.5-13.44.6,3.29Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M365.22,537c-3.8-10.59-9.58-12.59-19.32-6.8-12.42,7.38-24.77,14.9-37.15,22.36q-45.28,27.25-90.57,54.53c-9.63,5.82-12.94,12.49-11,22.32,22.69-6.37,45.62-11.89,67.9-19.33,25.3-8.44,49.85-10,75,.29,10.95,4.49,22.64,7.16,35.26,11C382.66,591.53,374.93,564,365.22,537Zm5.84,58.51C360.21,567.33,338,557.06,303.73,570c-17.22,6.52-33.48,15.57-50.19,23.46a15.6,15.6,0,0,1-7.2,1.9q23.85-13.25,47.7-26.47c13.29-7.36,26.53-14.84,39.95-22,11.45-6.08,22.51-2.8,27,9,5.53,14.63,9.71,29.77,14.47,44.7l-1.56.88C372.92,599.52,371.85,597.57,371.06,595.52Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M217.07,315.76c-8.38,91.57-5.39,183.15-4.13,275-2.95-7.34-6-14.65-8.83-22A431.57,431.57,0,0,1,175,399.39c1-33.42,16.62-60.59,40.51-83.31C215.71,315.86,216.22,315.92,217.07,315.76Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M169.11,577.22c-17.55-34-34.53-68.33-52.67-102-9.84-18.27-9.49-35.4.68-52.89,2.64-4.54,5.39-9,9.17-15.33l64.62,211.19C183.79,604.44,176.2,591,169.11,577.22Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_2" d="M71.1,489.65l101.74,133c-4.57-3.58-9.34-6.9-13.86-10.53C134,592,109.32,571.47,83.9,551.88c-16.64-12.84-20.45-29-16.24-48.33C68.58,499.29,69.75,495.09,71.1,489.65Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_2" d="M123.27,635.29c-16-5.82-31.81-12.33-47.94-17.85-18.28-6.26-28.09-23.94-27.06-46.66,43.08,28.05,86.49,54.06,128.06,83C158.67,647.54,140.88,641.68,123.27,635.29Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_3" d="M161.38,674.28c-16.28,5.3-32.53,7.44-49.23,5-14.07-2-26.61-7.07-35.1-19-4-5.68-6.38-12.56-9.86-18.64Z" transform="translate(-48.2 -315.76)"/><path :fill="color?color:cls_2_3" d="M105.21,698.94l73.5,6.48c-13.64,8.3-27.53,15.36-43.27,16.61C119.52,723.3,110.11,715.92,105.21,698.94Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_4" d="M782,611.17l-.48-7,4.62-3.91c2.26,2.16,6.43,4.46,6.28,6.43-.19,2.56-3.83,4.86-6,7.27Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M600.71,584.54v-7.13h-8.09v11.85h18.43l-10.9,12.41h-7.53v25.05H580.21V573l-5.49-8h35.51a3,3,0,0,1,2.9,2.9v16.57Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M654.22,626.72H622.6a2.81,2.81,0,0,1-2-.85,2.74,2.74,0,0,1-.87-2V573l-5.49-8h17.9v49.36h9.69V565h12.42Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M691.76,565v12.42h-11v49.31H668.32V577.41h-3l-8,5.49V565Z" transform="translate(-48.2 -315.76)"/><path :fill="color?color:cls_2_1" d="M732.85,626.72H701.23a2.85,2.85,0,0,1-2-.85,2.73,2.73,0,0,1-.86-2V573l-5.5-8h17.91v49.36h9.69V565h12.41Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M773.9,567.89v25.39l-6.53,5.41,6.53,28H761c-.2-.84-.4-1.69-.61-2.56q-3.2-14.17-6.4-28.37v-3.37l7.53-6.41v-8.6h-9.69v49.31H739.43V573l-5.5-8H771a2.77,2.77,0,0,1,2.06.87A2.8,2.8,0,0,1,773.9,567.89Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_1" d="M800.93,584.54v-7.13h-8v11.85h18.38l-10.9,12.41h-7.48v12.68h8v-6.84l12.42,4.5v11.81a2.74,2.74,0,0,1-.87,2,2.83,2.83,0,0,1-2,.85h-30V573L775,565h35.47a3,3,0,0,1,2.9,2.9v16.57Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_3" d="M604.85,654.16V647h-9.69V684h9.69V665.71l12.41,4.54v23.19a2.74,2.74,0,0,1-.86,2,2.82,2.82,0,0,1-2,.84H582.75V642.66l-5.5-8h37.12a3,3,0,0,1,2.89,2.9v20.93Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_3" d="M655,696.33H623.41a2.87,2.87,0,0,1-2.9-2.89V634.61h31.57a2.76,2.76,0,0,1,2.06.87,2.8,2.8,0,0,1,.84,2v58.82ZM642.61,647h-9.69V684h9.69Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_3" d="M675.05,675.61l3.55,1.69-3.07-26-5.37,45H656.75L663,642.66l-5.54-8h29.59l7.22,61.72H680.89l-.56-4.37-6.66-3.54Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_3" d="M718.13,654.16V647h-9.69V684h9.69V665.71l12.42,4.54v23.19a2.75,2.75,0,0,1-.87,2,2.82,2.82,0,0,1-2,.84H696V642.66l-5.49-8h37.11a3,3,0,0,1,2.9,2.9v20.93Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_3" d="M771.59,658.44v37.89H759.22v-25h-9.69v25H737.12V642.61l-5.49-8h17.9v24.27h9.69V634.61h9.47a3,3,0,0,1,2,.84,2.9,2.9,0,0,1,.86,2.06Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_2" d="M864,565v12.42H853v49.3H840.56v-49.3h-3l-8,5.49V565Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_2" d="M901.29,626.71H869.72a2.87,2.87,0,0,1-2.9-2.89V565h31.57a2.77,2.77,0,0,1,2.06.87,2.8,2.8,0,0,1,.84,2v58.82Zm-12.37-49.3h-9.69v36.93h9.69Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_2" d="M942.38,626.71H910.76a2.82,2.82,0,0,1-2-.84,2.74,2.74,0,0,1-.86-2V573l-5.5-8h17.91v49.35H930V565h12.41Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_2" d="M983.43,567.89v25.39l-6.53,5.41,6.53,28H970.54l-.61-2.55q-3.2-14.17-6.4-28.37v-3.38l7.53-6.4v-8.6h-9.69v49.3H949V573l-5.5-8h37.07a2.77,2.77,0,0,1,2.06.87A2.8,2.8,0,0,1,983.43,567.89Z" transform="translate(-48.2 -315.76)"/>
        <path :fill="color?color:cls_2_2" d="M990,573l-5.49-8h37.11a3,3,0,0,1,2.9,2.9v22.36l-12.41-5.71v-7.13h-9.69v8.86l22.1,10.9v26.65a2.78,2.78,0,0,1-.86,2,2.84,2.84,0,0,1-2,.84H990V601.32l12.42,5.89v7.13h9.69v-9l-20.42-10a2.85,2.85,0,0,1-1.64-2.64Z" transform="translate(-48.2 -315.76)"/><rect :fill="color?color:cls_2_5" x="734.12" y="343.97" width="241.9" height="6.98" rx="1.05"/>
        <rect :fill="color?color:cls_2_2" x="734.12" y="354.8" width="241.9" height="6.98" rx="1.23"/>
    </svg>

    <svg v-else  xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 705.49 632.11"  
        :width="size?size:'100%'" > 
        <path :fill="color?color:cls1" d="M599.33,737.58l-.58-8.44,5.59-4.73c2.72,2.61,7.77,5.39,7.59,7.78-.23,3.09-4.63,5.87-7.24,8.78Z" transform="translate(-187.09 -208.47)"/> 

        <path :fill="color?color:cls2" d="M623.72,543.85c-58.87-17.73-116.49-4.69-174.37,5.09,27.28-6.92,54.41-14.57,81.9-20.57C563.64,521.3,593.31,534.82,623.72,543.85Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M625.05,415l.07,0h0l0,0Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M625.05,415c-.38.16-.59.69,0,0Z" transform="translate(-187.09 -208.47)"/>
        <path :fill="color?color:cls2" d="M783.63,427.85a26.93,26.93,0,0,1-12.73-2.16c-13.78-5.61-26.81-14.18-41.07-17.24-35.18-7.55-70.18-2.21-104.69,6.47,6.13,13.46,13.87,25.7,17.29,39,6.61,25.76,11.34,52.06,15.63,78.33,1.34,8.22,3.86,11.57,12,12.3,31.51,2.83,62.58,0,93.19-7.3,9.1-2.16,18.55-5.51,26-10.84,5.24-3.74,11.09-12.27,10.33-17.8-3.43-25.21-2-52-19.77-74.75,11.76-1,18.09,4.11,21.74,12.7,2.38,5.6,3.1,12,6,17.28,1.5,2.75,6,3.84,9.21,5.68,1.63-3,4.69-6.1,4.66-9.11C821.15,440,803.69,425.45,783.63,427.85ZM676.36,450a12.25,12.25,0,0,1-.56,1.42,39.89,39.89,0,0,1-2.1,3.74,36.5,36.5,0,0,0-2.09,3.74h0q-.68-.5-1.38-1c-3.31-2.18-7-4.07-9.12-7.09-2.46-3.59-2.59-8.7-4.15-13-3.43-9.5-9.6-15.36-20.47-15.11-2.18.06-4.4-1.46-6.6-2.26,31.17-12.7,40.83,5.83,46.62,26.94A4.61,4.61,0,0,1,676.36,450ZM786.8,496.3c-2.25-9.86-5-19.63-6.65-29.59-2.79-17.24-12.41-29-27.53-36.75-19.52-10-40.64-13.32-62.22-14.58-1.43-.08-2.85-.2-4.28-.31C739.64,408.42,788.16,420.38,786.8,496.3Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M796.82,599.92c-6.91-1.61-7.8-5.3-3.17-10.36,12.76-13.93,12.38-30.66,11.28-49.7-8.48,18.78-11.87,22.51-22.35,24.5-25.33,4.81-50.71,9.37-76,14.27A36.31,36.31,0,0,1,680,574c-6.74-4-13.63-7.78-22-12.51,1.07,7.51,2.41,13.16,2.58,18.84.29,10.06.48,20.21-.6,30.17-.35,3.24-4.87,8.65-7.39,8.6-12.74-.27-25.45-2-38.23-3.25,4.09-24.8-5-43.46-22-44.28a26.32,26.32,0,0,0-16.65,5.7c-10.53,8.85-10,21.47-7.48,34.62l-77.41-7.1c1.41-14.62-2.63-23-10.45-23.32-7.06-.3-9.87,5.66-9.86,21.39l-31.73-2.68a7.06,7.06,0,0,0,4.08,2.39q104,21.73,208,43.29a25.24,25.24,0,0,0,10.77-.64c31.28-7.74,62.51-15.67,93.75-23.58q32.44-8.21,64.87-16.48C812.55,603.39,804.66,601.75,796.82,599.92ZM698.05,591c-.25-1.29-.49-2.57-.73-3.85l86.47-16.26c.24,1.33.48,2.66.71,4Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M615,435.69c-4.6-12.8-11.58-15.23-23.36-8.23-15,8.93-29.95,18-44.93,27q-54.76,33-109.52,66c-11.65,7-15.65,15.1-13.3,27,27.44-7.72,55.16-14.39,82.11-23.38,30.59-10.21,60.28-12.1,90.67.35,13.24,5.42,27.38,8.66,42.64,13.36C636.12,501.62,626.77,468.36,615,435.69Zm7.07,70.76c-13.13-34.09-40-46.51-81.42-30.84-20.83,7.88-40.5,18.83-60.7,28.37a18.93,18.93,0,0,1-8.71,2.3q28.85-16,57.68-32c16.08-8.9,32.08-17.93,48.31-26.55,13.85-7.36,27.23-3.38,32.61,10.87,6.69,17.69,11.74,36,17.5,54.05l-1.89,1.07C624.35,511.29,623.06,508.93,622.1,506.45Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M391.3,208.47c-10.12,110.75-6.51,221.49-5,332.6-3.56-8.88-7.23-17.71-10.67-26.64A521.86,521.86,0,0,1,340.38,309.6c1.19-40.4,20.11-73.26,49-100.74C389.66,208.59,390.28,208.67,391.3,208.47Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M333.31,524.66c-21.23-41.13-41.76-82.63-63.7-123.37-11.9-22.09-11.47-42.81.83-64,3.19-5.48,6.51-10.89,11.08-18.54,26.53,86.69,52.32,171,78.15,255.41C351.06,557.58,341.88,541.28,333.31,524.66Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls3" d="M214.79,418.76l123,160.89c-5.51-4.33-11.29-8.34-16.75-12.74C290.81,542.58,261,517.7,230.27,494c-20.14-15.52-24.73-35.09-19.65-58.45C211.74,430.42,213.15,425.34,214.79,418.76Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls3" d="M277.87,594.89c-19.38-7-38.47-14.91-58-21.59-22.11-7.57-34-28.95-32.72-56.43C239.27,550.79,291.77,582.25,342,617.28,320.69,609.7,299.16,602.61,277.87,594.89Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls4" d="M324,642c-19.69,6.42-39.34,9-59.53,6.08-17-2.47-32.18-8.55-42.45-23-4.89-6.86-7.72-15.19-11.92-22.53Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls4" d="M256,671.86l88.88,7.84c-16.49,10-33.29,18.57-52.33,20.09C273.34,701.32,262,692.4,256,671.86Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M380.06,705.39v-8.63h-9.79v14.33h22.29l-13.18,15h-9.11v30.29h-15V691.47l-6.64-9.73h42.94a3.59,3.59,0,0,1,3.51,3.51v20Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M444.76,756.39H406.52a3.38,3.38,0,0,1-2.45-1,3.34,3.34,0,0,1-1.05-2.49V691.47l-6.64-9.73H418v59.69h11.72V681.74h15Z" transform="translate(-187.09 -208.47)"/><path :fill="color?color:cls2" d="M490.16,681.74v15H476.82v59.63h-15V696.76h-3.66l-9.68,6.64V681.74Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M539.86,756.39H501.62a3.42,3.42,0,0,1-2.46-1,3.34,3.34,0,0,1-1.05-2.49V691.47l-6.64-9.73h21.66v59.69h11.71V681.74h15Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M589.5,685.25V716l-7.9,6.54,7.9,33.9H573.91c-.25-1-.49-2-.73-3.09q-3.88-17.16-7.75-34.31v-4.08l9.11-7.74V696.76H562.82v59.63h-15v-65l-6.65-9.68H586a3.34,3.34,0,0,1,2.49,1A3.42,3.42,0,0,1,589.5,685.25Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls2" d="M622.19,705.39v-8.63h-9.73v14.33h22.23l-13.18,15h-9v15.33h9.73v-8.27l15,5.44v14.28a3.34,3.34,0,0,1-1.05,2.49,3.38,3.38,0,0,1-2.45,1H597.45V691.47l-6.65-9.73h42.9a3.33,3.33,0,0,1,2.45,1,3.37,3.37,0,0,1,1.05,2.46v20Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls4" d="M385.06,789.58V781H373.34v44.67h11.72V803.54l15,5.5v28a3.37,3.37,0,0,1-1,2.49,3.42,3.42,0,0,1-2.46,1H358.33V775.66l-6.64-9.73h44.88a3.57,3.57,0,0,1,3.5,3.51v25.32Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls4" d="M445.69,840.58H407.5a3.48,3.48,0,0,1-3.5-3.51V765.93h38.18a3.32,3.32,0,0,1,2.48,1.05,3.39,3.39,0,0,1,1,2.46v71.14Zm-15-59.63H419v44.67h11.71Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls4" d="M470,815.52l4.29,2-3.71-31.43L464,840.58H447.83l7.58-64.92-6.69-9.73H484.5l8.73,74.65H477l-.68-5.29L468.28,831Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls4" d="M522.05,789.58V781H510.34v44.67h11.71V803.54l15,5.5v28a3.34,3.34,0,0,1-1.05,2.49,3.42,3.42,0,0,1-2.46,1H495.32V775.66l-6.64-9.73h44.88a3.59,3.59,0,0,1,3.51,3.51v25.32Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls4" d="M586.71,794.76v45.82h-15V810.29H560v30.29H545v-65l-6.65-9.68H560v29.35h11.72V765.93H583.2a3.58,3.58,0,0,1,2.46,1,3.51,3.51,0,0,1,1.05,2.48Z" transform="translate(-187.09 -208.47)"/><path :fill="color?color:cls3" d="M698.46,681.74v15H685.12v59.63h-15V696.76h-3.66l-9.68,6.64V681.74Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls3" d="M743.55,756.39H705.37a3.48,3.48,0,0,1-3.51-3.51V681.74h38.19a3.33,3.33,0,0,1,2.48,1,3.42,3.42,0,0,1,1,2.46v71.14Zm-15-59.63H716.88v44.67h11.71Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls3" d="M793.25,756.39H755a3.42,3.42,0,0,1-2.46-1,3.34,3.34,0,0,1-1-2.49V691.47l-6.64-9.73h21.66v59.69h11.71V681.74h15Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls3" d="M842.88,685.25V716L835,722.49l7.89,33.9H827.3c-.25-1-.49-2-.73-3.09q-3.89-17.16-7.75-34.31v-4.08l9.1-7.74V696.76H816.21v59.63h-15v-65l-6.65-9.68h44.83a3.34,3.34,0,0,1,2.49,1A3.45,3.45,0,0,1,842.88,685.25Z" transform="translate(-187.09 -208.47)"/>
        
        <path :fill="color?color:cls3" d="M850.84,691.47l-6.65-9.73h44.88a3.59,3.59,0,0,1,3.51,3.51v27l-15-6.9v-8.63H865.85v10.72l26.73,13.18v32.22a3.34,3.34,0,0,1-1.05,2.49,3.42,3.42,0,0,1-2.46,1H850.84V725.68l15,7.12v8.63h11.71V730.55l-24.68-12.14a3.45,3.45,0,0,1-2-3.19Z" transform="translate(-187.09 -208.47)"/>
        
        <rect :fill="color?color:cls5" x="412.59" y="587.84" width="292.54" height="8.44" rx="1.05"/>
        <rect :fill="color?color:cls3" x="412.59" y="600.94" width="292.54" height="8.44" rx="1.23"/>
    </svg>
    
</v-card>
</template> 
<script> 
    export default {
        props:['size','color','color1','color2','color3','color4','color5','type','opacity','title','text','items' ],
        data(){
            return{
                show:false, 
                cls1:"#fff",
                cls2:"#31883a",
                cls3:"#c3471b",
                cls4:"#f48b26",
                cls5:"#21692b",
                
                cls_2_1:"#31883a",
                cls_2_2:"#c3471b",
                cls_2_3:"#f48b26",
                cls_2_4:"#fff",
                cls_2_5:"#21692b",
            }
        },
        created(){  

        },
        mounted(){  
            this.cls_2_1 = this.color1?this.color1:'#31883a'
            this.cls_2_2 = this.color2?this.color2:'#c3471b'
            this.cls_2_3 = this.color3?this.color3:'#f48b26'
            this.cls_2_4 = this.color4?this.color4:'#fff'
            this.cls_2_5 = this.color5?this.color5:'#21692b'
        },
        computed:{
              
        },
        methods:{ 
        },
        watch:{ 
        } 

    }
</script>
<style>
  
</style> 