<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <br>
                <v-layout class="ma-0">
                    <h1 class="grey--text text--darken-1">Return Sold Items</h1>
                    <v-spacer></v-spacer>
                    <v-btn @click="REFRESH_ITEMS()" color="primary"> Refresh sold items <v-icon>mdi-refresh</v-icon></v-btn>
                </v-layout>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <h4 class="grey--text text--darken-1">Order Details</h4>
                    <v-row>
                        <v-col cols="12" md="4">
                            <div>
                                Adding details for this order helps you stay on top of all your orders and means your staff can easily identify incoming items.</div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input"
                                :field_items="SalesReturnFields"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />  
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br>
                    <h4 class="grey--text text--darken-1">Sold Items</h4>
                    <v-row>
                        <v-col cols="12" lg="12" xl="3">
                            <v-layout class="ma-0" >
                                <div>
                                    Choose items to add to this order, or choose a CSV file of items to import.
                                </div>
                                
                                
                            </v-layout>
                        </v-col>
                        <v-col cols="12" lg="12" xl="9">
                            <v-card :height="vsh-170" outlined rounded="sm">
                                <v-layout fill-height column class="ma-0">
                                    <div v-if="false">
                                        <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                            <v-autocomplete 
                                                v-model="search_item"
                                                :disabled="inputLoading"
                                                :items="DataItems" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                label="Search or Scan to Add Item"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                :filter="(item, queryText, itemText) => { 
                                                    let first = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    if(!item){return first}
                                                    if(!item.group){return first}
                                                    let second = item.group.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    return first || second 
                                                }"
                                                >
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>local_offer</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete> 
                                            <v-btn :disabled="inputLoading"  class="ml-5" color="secondary">Import <v-icon class="ml-2">mdi-microsoft-excel </v-icon></v-btn> 
                                        </v-toolbar> 
                                    </div>
                                    <v-list dense class="font-weight-bold not-f3 ">
                                        <v-list-item dense >
                                            <v-list-item-action> <v-card flat width="45">
                                                
                                            </v-card> </v-list-item-action>
                                            <v-list-item-content>Item Name</v-list-item-content> 
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="90">
                                                    <v-layout class="ma-0" justify-center>
                                                        Sold
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="100">
                                                    <v-layout class="ma-0" >
                                                        Price  
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="80">
                                                    <v-layout class="ma-0" >
                                                        Disc
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                            <v-list-item-action>
                                                <v-card flat width="80">
                                                    <v-layout class="ma-0 pl-2" justify-center>
                                                    <div class="text-center">Total Paid</div> 
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>  
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="90">
                                                    <v-layout class="ma-0" justify-center>
                                                        Return
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="90">
                                                    <v-layout class="ma-0" justify-center>
                                                        Total Refund
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            
                                             
                                            <v-list-item-action>
                                                <v-card flat width="25">
                                                    <v-layout class="ma-0" justify-center>
                                                        
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                        </v-list-item>
                                    </v-list>
                                    <v-divider></v-divider>
                                    <v-layout v-if="!selected_items" fill-height align-center justify-center >
                                        No Item
                                    </v-layout>
                                    <v-virtual-scroll v-else 
                                        class=""
                                        :bench="2"
                                        :items="selected_items"
                                        :height="vsh-300"
                                        item-height="70"  > 
                                        <template v-slot="{ item,index }">
                                                <v-list-item  
                                                    :key="index" :to="item.link">
                                                    <v-list-item-action> 
                                                        <v-avatar
                                                            size="45" tile rounded="lg"
                                                            color="grey"  >
                                                            <v-img v-if="item.image"></v-img>
                                                            <v-icon v-else>local_offer</v-icon>
                                                        </v-avatar>
                                                    </v-list-item-action>
                                                    <v-list-item-content>{{item.name}}</v-list-item-content>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="70">
                                                            <v-text-field
                                                                :disabled="true"
                                                                v-mask="'####'" outlined
                                                                filled dense  required
                                                                v-model="item.quantity"/>
                                                        </v-card>
                                                    </v-list-item-action>  
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="100">
                                                            <v-text-field
                                                                :disabled="inputLoading || sale_order_key?true:false" required
                                                                v-mask="currencyMask" filled dense  outlined
                                                                v-model="item.sold_price" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="80">
                                                            <v-text-field
                                                                :disabled="inputLoading || sale_order_key?true:false" required
                                                                v-mask="currencyMask" filled dense  outlined
                                                                v-model="item.discount" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action>
                                                        <v-card color="transparent" flat width="100">
                                                            <v-layout class="ma-0 pr-2">
                                                                <v-spacer></v-spacer>
                                                                {{MBS.actions.money(CALCULATE_PRICE(item))}}
                                                            </v-layout>
                                                        </v-card>
                                                    </v-list-item-action> 
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="70">
                                                            <v-text-field
                                                                :disabled="inputLoading"
                                                                v-mask="'####'" outlined
                                                                filled dense  required
                                                                v-model="item.return_quantity"/>
                                                        </v-card>
                                                    </v-list-item-action>  
                                                    <v-list-item-action>
                                                        <v-card color="transparent" flat width="100">
                                                            <v-layout class="ma-0 pr-2">
                                                                <v-spacer></v-spacer>
                                                                {{MBS.actions.money(CALCULATE_REFUND_PRICE(item))}}
                                                            </v-layout>
                                                        </v-card>
                                                    </v-list-item-action> 
                                                    <v-list-item-action >
                                                        <v-btn @click="REMOVE_ITEM(item)"  v-if="false"
                                                            :disabled="inputLoading || sale_order_key?true:false"
                                                            small icon>
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                                <v-divider ></v-divider> 
                                        </template>  
                                    </v-virtual-scroll> 

                                    <v-spacer></v-spacer>
                                    <v-divider></v-divider>
                                    <div class="mx-6">
                                        <v-list-item>
                                            <v-list-item-content class="font-weight-bold grey--text text--darken-1">
                                                <v-list-item-title>Receipt: {{SizeItem.receipt}}/{{SizeItem.items}} Items</v-list-item-title>
                                                <!-- <v-list-item-subtitle>Items</v-list-item-subtitle> -->
                                            </v-list-item-content>
                                            <v-list-item-action class="font-weight-bold grey--text text--darken-1"> 
                                                TOTAL PAID
                                                {{MBS.actions.money(CALCULATE_TOTAL_QT_PRICE(selected_items))}}
                                                </v-list-item-action>
                                        </v-list-item> 
                                    </div>
                                    
                                    <input ref="btn_submit_order" type="submit" v-show="false">
                                    <v-card @click="SUBMIT()"  
                                        :disabled="SizeItem.return<1 || inputLoading" 
                                        :flat="SizeItem.return<1" 
                                           
                                        class="mx-5 my-4 v-btn" :color="SizeItem.return<1?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <div class="font-weight-bold not-f7">Return</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{SizeItem.return}}/{{SizeItem.receipt}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL REFUND MWK {{
                                                MBS.actions.money(CALCULATE_TOTAL_REFUND_PRICE(selected_items))
                                                }}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>   
                            
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "TRANSFER-STOCK"  
    export default {
        name:PAGE_NAME, 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                sale_order_key:null, 
                show_input:true, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 

                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
            }), 
            DataItems(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                //let CalculatedItems = this.CalculatedItems
                // if (CalculatedItems) {
                //     return CalculatedItems
                // }else 
                
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },
            SalesOrdersData(){ 
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders 
                // console.log(SaleOrders,'SaleOrders....');
                // console.log(JoinedSaleOrders,'JoinedStockOrders....');
                // return SaleOrders
                let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrders
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            },
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////  
            RequestForQuotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Quotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Invoices(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.INVOICES.value)  
                });
                return filtered_items
            },  
            ReceivedOrders(){
                let items = this.JoinedStockOrders
                if(!items){return null}
                let filtered_items = items.filter(item => {
                    return this.MBS.actions.TEXT_UP(item.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.STOCK_RECEIVES.value)  
                });
                return filtered_items
            },  
            SupplierCategories(){ 
                return this.$store.getters.getSupplierCategories
            },
            SaleOrders(){ 
                return this.$store.getters.getSaleOrders
            },
            SizeItem(){ 
                let items = this.selected_items
                if(!items){return 0}
                let items_size=this.MBS.actions.SIZE(items)
                let return_size=0
                let receipt_size=0
                items.forEach(element => {
                    let quantity = this.toNumber(element.quantity)
                    let return_quantity = this.toNumber(element.return_quantity)
                    receipt_size = receipt_size+quantity
                    return_size = return_size+return_quantity
                });
                return {
                    items:items_size,
                    receipt:receipt_size,
                    return:return_size,
                }
            },  
            CALCULATE_TOTAL_QT_PRICE(){
                return items=>{
                    let total_price = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let sold_price = item.sold_price?this.toNumber(item.sold_price):0
                            let discount = item.discount? this.toNumber(item.discount):0
                            let quantity = item.quantity?this.toNumber(item.quantity):1
                            total_price = total_price + (quantity*(sold_price-discount))
                        });  
                        return total_price
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_QT_PRICE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            CALCULATE_TOTAL_REFUND_PRICE(){
                return items=>{
                    let total_price = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let sold_price = item.sold_price?this.toNumber(item.sold_price):0
                            let discount = item.discount? this.toNumber(item.discount):0
                            let return_quantity = item.return_quantity?this.toNumber(item.return_quantity):1
                            total_price = total_price + (return_quantity*(sold_price-discount))
                        });  
                        return total_price
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_REFUND_PRICE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){
                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let RequestForQuotations = this.RequestForQuotations
                let Quotations = this.Quotations
                let Invoices = this.Invoices
                let ReceivedOrders = this.ReceivedOrders
                let SupplierCategories = this.SupplierCategories
                let SalesOrdersData = this.SalesOrdersData 
                let JoinedSaleOrders = this.JoinedSaleOrders 
                return {
                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    RequestForQuotations:RequestForQuotations,
                    Quotations:Quotations,
                    Invoices:Invoices,
                    ReceivedOrders:ReceivedOrders,
                    SupplierCategories:SupplierCategories,
                    SaleOrders:SalesOrdersData,
                }
            },
            DisabledFields(){
                let sale_order_key = this.sale_order_key 
                if (sale_order_key) {
                    return ['customer_name','teller_name','till_name','outlet_name','transaction_date']
                }
                return ['customer_name','teller_name','till_name','outlet_name','transaction_date']
            },
            SalesReturnFields(){
                let sale_order_key = this.sale_order_key 
                let fields = DATA.FIELDS.sales_return
                if (sale_order_key) {
                    return fields
                }
                return fields.filter(item=>{
                    return item.value == 'code'||item.value == 'sale_order_key'||item.value == 'return_date'
                })
            } 
        },
        methods: { 
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.DataItems
                    if (items) {
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
 
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = (item.quantity*1)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1,
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = item.sold_price?this.toNumber(item.sold_price):0
                    let discount = item.discount? this.toNumber(item.discount):0
                    let quantity = item.quantity?this.toNumber(item.quantity):1
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_REFUND_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = item.sold_price?this.toNumber(item.sold_price):0
                    let discount = item.discount? this.toNumber(item.discount):0
                    let return_quantity = item.return_quantity?this.toNumber(item.return_quantity):1
                    return return_quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_REFUND_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input  
                    let code = input?input.code:null  
                    let sale_order = input?input.sale_order:null 
                    let sale_order_key = sale_order?sale_order.key:null 
                    let day_shift_key = sale_order?sale_order.day_shift_key:null 
                    let company_key = sale_order?sale_order.company_key:null 
                    let description = input?input.description:null
                    let selected_items = this.selected_items
                    let items_size = this.MBS.actions.SIZE(selected_items)

                    console.log(sale_order,"sale order........");

                    if (items_size<1) {
                        console.log("error..");
                    }
                    
                    let stock_order_items = []
                    selected_items.forEach(element => {
                        let quantity = this.toNumber(element.quantity)
                        let discount = this.toNumber(element.discount)
                        let return_quantity = this.toNumber(element.return_quantity)
                        let sold_price = this.toNumber(element.sold_price)
                        if (return_quantity>0) {
                            stock_order_items.push({
                                item_key:element.item_key, 
                                sold_price:sold_price, 
                                price:sold_price, 
                                discount:discount, 
                                quantity:return_quantity, 
                                sold_quantity:quantity
                            }) 
                        }
                    });
                    let order_details = {
                        key:sale_order_key+"-"+code,  
                        code:code,  
                        sale_order_key:sale_order_key,  
                        day_shift_key:day_shift_key,  
                        company_key:company_key,  
                        order_type:DATA.ITEMS.SALES_RETURN.value,
                        items:stock_order_items
                    }  
                    if (description) {
                        order_details.description = description
                    }  
 
                    console.log(order_details,'order_details...');
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.ITEMS.names,"Return Sold")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_ORDER(order){
                try {  
                    let order_key = order.sale_order_key
                    let day_shift_key = order.day_shift_key
                    let company_key = order.company_key
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts_sales+day_shift_key+"/"+order_key,true,company_key)  
                    let details = {
                        action:DATA.ITEMS.SALES_RETURN.values,
                        data:order,
                        path:path
                    }
                    console.log(details,"dx ........");
                    if (!order || !path) {
                        console.log(order,'order error');
                        return
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_add",details).then(res=>{ 
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEMS.names,"Returned")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        }) 
                        let quotationLink = this.MBS.actions.COMPANY_LINK("/stock/stock-control/",true)
                        this.MBS.actions.go(-1) 
                         
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.QUOTATIONS.value,"Transferred")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADD_SALE_ORDER(sale_order_key){
                try {  
                    this.show_input = false
                    let orders = this.JoinedSaleOrders   
                    if(!sale_order_key){this.show_input = false; return}
                    if(!orders){this.show_input = false; return}
                    let order = orders.find(q=>{
                        return q.key == sale_order_key
                    })   
                    if (order) {
                        this.selected_items = []
                        setTimeout(() => {
                            if (order.joined_items  ) {
                                order.joined_items  .forEach(element => {
                                    this.selected_items.push({
                                        ...element,
                                        return_quantity:element.quantity
                                    })
                                }); 
                            }
                            this.input = {
                                ...this.input,  
                                customer_name : order.customer_name,
                                teller_name : order.teller_name,
                                till_name : order.till_name,
                                outlet_name : order.outlet_name,
                                transaction_date : order.local_created_at_,
                                description : order.description,
                                sale_order:order
                            }   
                        }, 1);
                        setTimeout(() => {
                            console.log(this.input,222);
                            this.show_input = true 
                        }, 1000);
                    }else{
                        this.show_input = false
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_SALE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            REFRESH_ITEMS(){
                let mbs_text = this.MBS.text.item_action("Sales Transactions","Refresh")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=RELOAD-ITEMS", 
                    } 
                })
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(v){  
                let sale_order_key =v?v.sale_order_key:null 
                if ( sale_order_key && sale_order_key != this.sale_order_key) {
                    this.sale_order_key = sale_order_key
                    this.ADD_SALE_ORDER(sale_order_key) 
                }else{ 
                }
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
