 
<template>
<v-card 
    :color="backgroundColor?backgroundColor:''"
    width="100%"
    tile="" flat="">

    <!-- TITLE -->
    <div class="not-f9 font-weight-bold text-center pb-9">
        {{MBS.actions.TEXT_UP11(title)}} 
    </div>
    <v-hover  v-slot="{hover}">
        <v-row @mouseover="mouseOver" @mouseleave="mouseLeave"> 
            <v-col sm="6">
            <v-layout column="" fill-height="" class="primary--text "> 
                <v-tabs
                    v-model="computerTrainingTab"
                    background-color="primary"
                    dark show-arrows="" >
                    <v-tab
                        v-for="(item, index) in items"
                        :key="index">
                        {{ item.name }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="computerTrainingTab">
                    <v-tab-item
                        v-for="(item,index) in items"
                        :key="index">
                        <v-card flat>
                            <v-card-text>{{ item.body }}</v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                <br>
                <v-spacer></v-spacer>
                <div>
                    <v-layout v-if="items[computerTrainingTab]" class="px-3"> 
                        <v-btn :to="items[computerTrainingTab].link" outlined="" color="primary">Read More</v-btn>  
                    </v-layout>  
                </div>
            </v-layout> 
            </v-col> 
            <v-col class="pt-0" sm="6" v-if="items[computerTrainingTab]"> 
                <div v-if="showImage">
                    <v-img :class="MBS.data.animate.back.inLeft" width="" :src="items[computerTrainingTab].image"></v-img>
                </div>
                <v-layout v-else fill-height="">
                    <br><br>
                    <mbs-page-spinner 
                        width="6"/> 
                </v-layout>
            </v-col>
        </v-row>  
    </v-hover>
     
</v-card>
</template> 
<script> 
    export default {
        props:['title','color','backgroundColor','items','time'],
        data(){
            return{
                show:true,  
                showImage:true,  
                computerTrainingTab:0,
                timeStop:false
            }
        },
        created(){ 
            this.runTimer()
        },
        mounted(){   
        },
        computed:{
              
        },
        methods:{ 
            run(){
                if (this.timeStop) {
                    this.runTimer() 
                    return
                }
                if(!this.items){
                    this.runTimer() 
                    return
                }
                let num = this.items.length
                let current = this.computerTrainingTab
                let cycle = 0
                if (current<num) {
                    cycle = current+1
                } else {
                    cycle = 0
                }  
                this.computerTrainingTab = cycle
                this.runTimer()
            },
            runTimer(){
                setTimeout(() => { 
                    this.run()
                }, this.time?this.time:10000);
            },

            mouseOver(mouse){
                this.timeStop = true 
            },
            mouseLeave(mouse){
                this.timeStop = false 
            }
        },
        watch:{ 
           computerTrainingTab(v){
               this.showImage = false
               setTimeout(() => {
                   this.showImage = true
               }, 100);
           } 
        } 

    }
</script>
<style>
  
</style> 