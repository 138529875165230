<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 43.6 39.03"
  :fill="iconColor" xml:space="preserve">

    <path d="M0,29.23V.1a1.9,1.9,0,0,1,.35,0c2.08.67,4.16,1.32,6.23,2a.9.9,0,0,1,.49.65q0,11.85,0,23.69a.72.72,0,0,1-.31.56C4.55,27.79,2.33,28.49,0,29.23Z"/><path d="M43.55,0v29.2L36.4,27V23.48c0-6.8.05-13.6.05-20.4,0-.59.14-.87.74-1C39.27,1.42,41.34.72,43.55,0Z"/><polygon points="0 29.46 7.19 27.21 7.19 2.46 0 0.14 0 29.46"/><polygon points="36.41 27.21 43.6 29.46 43.6 0.14 36.41 2.46 36.41 27.21"/><path d="M33.88,0H9.6C8.54,0,8.19.34,8.19,1.36q0,13.26,0,26.52c0,1,.32,1.36,1.31,1.36q12.26,0,24.51,0c1,0,1.33-.34,1.33-1.35V1.46C35.34.32,35,0,33.88,0ZM33,27H10.5V2.33H33Z"/><path d="M21.06,17.59h-8v8h8Z"/><path d="M22.47,25.61h8v-8h-8Z"/><path d="M25.74,8.2h-8v7.94h8Z"/><path d="M9.26,30.41c0,2.57,0,5.1,0,7.63a1,1,0,0,0,1.09,1H13c1,0,1.36-.31,1.37-1.39,0-.71,0-1.42,0-2.14H29.19c0,.83,0,1.62,0,2.41A1,1,0,0,0,30.3,39c1,0,1.94,0,2.91,0a1,1,0,0,0,1-1c0-2.51,0-5,0-7.59Zm2,3.35a.85.85,0,0,1-.85-.85.85.85,0,1,1,.85.85Zm2.15,0a.85.85,0,0,1-.85-.85.85.85,0,1,1,.85.85Zm2,.05a.85.85,0,1,1,.84-.85A.85.85,0,0,1,15.47,33.81Zm8.76,0c-1.63,0-3.27,0-4.9,0-.68,0-.67-.41-.67-.89s0-.9.68-.88,1.63,0,2.45,0,1.63,0,2.45,0c.62,0,.63.4.62.85S24.92,33.79,24.23,33.78ZM28,33.72a.85.85,0,1,1,.85-.85A.85.85,0,0,1,28,33.72Zm2.15,0a.85.85,0,1,1,.84-.85A.85.85,0,0,1,30.16,33.72Zm2,.05a.85.85,0,0,1-.85-.85.85.85,0,1,1,.85.85Z"/>

  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
