

export default {
  state: {
    mbs_header: {
      show: true, 
      color: 'primary',
      height:null,
      type: 'images', 
      images:[],
      time:3000,
      action: {}
    },
    mbs_nav: {
      show: true, 
      color: '', 
      type: 'lg',  
      min:true,
      action: {}
    },
    mbs_toolbar: {
      show: true,
      fixed: true,
      flat:true,
      color: 'primary',
      textColor: 'white',
      height:'80',
      title: '', 
      action: {}
    }, 
    mbs_sub_toolbar: {
      show: true,
      fixed: true,
      flat:true,
      color: 'primary',
      textColor: 'white',
      height:'80',
      title: '', 
      side:'right',
      action: {}
    }, 
    mbs_dialog: {
      show: false,
      fixed: false,
      title: '',
      text: '',
      icon: '',
      btnYes: '',
      btnNo: '',
      action: {}
    },
    mbs_dialog_confirm_password: {
      show: false,
      fixed: false,
      title: '',
      text: '',
      icon: '',
      btnYes: '',
      btnNo: '',
      btnYesIcon: '',
      btnNoIcon: '',
      btnYesColor: null,
      btnNoColor: null,
      action: {}
    },
    mbs_dialog_input: {
      show: false,
      fixed: false,
      title: '',
      fields:[
        {type:'text',label:''}
      ],
      text: '',
      text2: '',
      btnYes: '',
      btnNo: '',
      action: {}
    },
    mbs_progress_dialog: {
      show: false,
      fixed: true,
      color: 'primary',
      title:'',
      text: '', 
      action: {}
    },
    mbs_progress_toolbar: {
      show: false, 
      color: 'c2',
      background_color:'',
      height: 6, 
      top: 80, 
      no_subtoolbar:false,
      action: {}
    }, 
    mbs_alert_toolbar: {
      show: false, 
      min: false, 
      type: 'error', 
      text: '...', 
      btnYes: 'Fix Now', 
      btnNo: 'Later', 
      color: '', 
      icon: null, 
      items: [],  
      action: {}
    }, 
    alert_items:null,
    mbs_snackBar: {
      show: false,
      time: 6000,
      app:false,
      text: '...',
      color:'',
      textColor:"",
      lines:false,
      vertical:false,
      x: null,
      y: 'bottom',
      center: false,
      action: {}
    }, 
    mbs_sheet: {
      show: false,
      fixed: false,
      min: false,
      title: '',
      text: '',
      btnYes: '',
      btnNo: '', 
      action: {}
    },
    mbs_card_swipe: {
      show: false,
      fixed: false, 
      page: '', 
      action: {}
    }, 
    PAGE_LOAD:false, 

  },
  //___________________-GETTERS-_________________
  getters: {
    //---not
    getPAGE_LOAD(state) {
      return state.PAGE_LOAD
    },
    getMBS_header(state) {
      return state.mbs_header
    },
    getMBS_nav(state) {
      return state.mbs_nav
    },
    getMBS_toolbar(state) {
      return state.mbs_toolbar
    },
    getMBS_sub_toolbar(state) {
      return state.mbs_sub_toolbar
    },
    getMBS_dialog(state) {
      return state.mbs_dialog
    },
    getMBS_dialog_confirm_password(state) {
      return state.mbs_dialog_confirm_password
    },
    getMBS_dialog_input(state) {
      return state.mbs_dialog_input
    },
    getMBS_progress_dialog(state) {
      return state.mbs_progress_dialog
    }, 
    getMBS_progress_toolbar(state) {
      return state.mbs_progress_toolbar
    }, 
    getMBS_alert_toolbar(state) {
      return state.mbs_alert_toolbar
    }, 
    getAlertItems(state) {
      return state.alert_items
    }, 
    findAlertItem(state) {
      return (value,type)=>{
        let items = state.alert_items
        if(!items){return null}
        let filtered_item = items.find(alert=>{
          if (type == "description") {
            return alert.description == value
          }if (type == "type") {
            return alert.type == value
          }
          return alert.name == value
        })
        return filtered_item
      }
    }, 
    filterAlertItem(state) {
      return (value,type)=>{
        let items = state.alert_items
        if(!items){return null}
        let filtered_item = items.filter(alert=>{
          if (type == "description") {
            return alert.description == value
          }if (type == "type") {
            return alert.type == value
          }
          return alert.name == value
        })
        return filtered_item
      }
    }, 

    getMBS_snackbar(state) {
      return state.mbs_snackBar
    }, 
  },
  //___________________-MUTATIONS-___________________
  mutations: {
    updateToolbar(state, payload) {
      state.mbs_toolbar = payload
    }, 
    updateSubToolbar(state, payload) {
      state.mbs_sub_toolbar = payload
    },
  },
  //____________________-ACTIONS-_____________________
  actions: {
    setMBS_toolbar({ commit,state}, payload) {
      if(!payload){return}
      let mbs_toolbar = state.mbs_toolbar
      let show = payload.show?payload.show:false
      let fixed = payload.fixed===null || payload.fixed===undefined ?mbs_toolbar.fixed:payload.fixed
      let flat = payload.flat===null || payload.flat===undefined ?mbs_toolbar.flat:payload.flat 
      let color = payload.color?payload.color:mbs_toolbar.color
      let textColor = payload.textColor?payload.textColor:mbs_toolbar.textColor
      let height = payload.height?payload.height:mbs_toolbar.height
      let title = payload.title?payload.title:mbs_toolbar.title 
      let action = payload.action?payload.action:{}

      let toolbar = {
        show: show,
        fixed: fixed,
        flat: flat,
        color: color,
        textColor: textColor,
        height: height,
        title: title, 
        action: action
      }   
      commit('updateToolbar', toolbar) 
    }, 
    setMBS_sub_toolbar({ commit,state}, payload) {
      if(!payload){return}
      let mbs_toolbar = state.mbs_sub_toolbar
      let show = payload.show?payload.show:false
      let fixed = payload.fixed===null || payload.fixed===undefined ?mbs_toolbar.fixed:payload.fixed
      let flat = payload.flat===null || payload.flat===undefined ?mbs_toolbar.flat:payload.flat 
      let color = payload.color?payload.color:mbs_toolbar.color
      let textColor = payload.textColor?payload.textColor:mbs_toolbar.textColor
      let height = payload.height?payload.height:mbs_toolbar.height
      let title = payload.title?payload.title:mbs_toolbar.title 
      let side = payload.side?payload.side:mbs_toolbar.side 
      let action = payload.action?payload.action:{}

      let toolbar = {
        show: show,
        fixed: fixed,
        flat: flat,
        color: color,
        textColor: textColor,
        height: height,
        title: title, 
        side: side, 
        action: action
      }  
      commit('updateSubToolbar', toolbar) 
    },
    setMBS_header({ commit,state}, payload) {
      if(!payload){return}
      let mbs_header = state.mbs_header
      let show = payload.show?payload.show:false 
      let color = payload.color?payload.color:mbs_header.color
      let height = payload.height?payload.height:null
      let type = payload.type?payload.type:mbs_header.type
      let images = payload.images?payload.images:mbs_header.images
      let time = payload.time?payload.time:mbs_header.time
      let action = payload.action?payload.action:{}

      let toolbar = {
        show: show,  
        color: color,
        height: height,
        type: type,
        images: images,
        time: time,
        action: action
      } 
      state.mbs_header = toolbar
    }, 
    setMBS_nav({ commit,state}, payload) {
      if(!payload){return}
      let mbs_nav = state.mbs_nav
      let show = payload.show?payload.show:false 
      let color = payload.color?payload.color:mbs_nav.color 
      let type = payload.type?payload.type:mbs_nav.type 
      let min = payload.min?payload.min:payload.min===false?false:mbs_nav.min 
      let action = payload.action?payload.action:{}

      let toolbar = {
        show: show,  
        color: color, 
        type: type,
        min: min, 
        action: action
      }  
      state.mbs_nav = toolbar
    }, 
    setMBS_dialog({ commit,state}, payload) {
      if(!payload){return}
      let mbs_dialog = state.mbs_dialog
      let show = payload.show?payload.show:false 
      let fixed = payload.fixed?payload.fixed:false
      let title = payload.title?payload.title:null
      let text = payload.text?payload.text:null
      let icon = payload.icon?payload.icon:null
      let btnYes = payload.btnYes?payload.btnYes:null
      let btnNo = payload.btnNo?payload.btnNo:null
      let action = payload.action?payload.action:{}

      let newDialog = {
        show : show,
        fixed : fixed, 
        title : title,
        text : text,
        icon : icon,
        btnYes : btnYes,
        btnNo:btnNo,
        action:action
      } 
      state.mbs_dialog = newDialog
    },
    setMBS_dialog_confirm_password({ commit,state}, payload) {
      if(!payload){return}
      let mbs_dialog = state.mbs_dialog
      let show = payload.show?payload.show:false 
      let fixed = payload.fixed?payload.fixed:false
      let title = payload.title?payload.title:null
      let text = payload.text?payload.text:null
      let icon = payload.icon?payload.icon:null
      let btnYes = payload.btnYes?payload.btnYes:null
      let btnNo = payload.btnNo?payload.btnNo:null
      let btnYesIcon = payload.btnYesIcon?payload.btnYesIcon:null
      let btnNoIcon = payload.btnNoIcon?payload.btnNoIcon:null
      let btnYesColor = payload.btnYesColor?payload.btnYesColor:null
      let btnNoColor = payload.btnNoColor?payload.btnNoColor:null
      let action = payload.action?payload.action:{}

      let newDialog = {
        show : show,
        fixed : fixed, 
        title : title,
        text : text,
        icon : icon,
        btnYes : btnYes,
        btnNo:btnNo,
        btnYesIcon : btnYesIcon,
        btnNoIcon:btnNoIcon,
        btnYesColor : btnYesColor,
        btnNoColor:btnNoColor,
        action:action
      } 
      state.mbs_dialog_confirm_password = newDialog
    },
    setMBS_dialog_input({ commit,state}, payload) {
      if(!payload){return}
      let mbs_dialog = state.mbs_dialog_input
      let show = payload.show?payload.show:false 
      let fixed = payload.fixed?payload.fixed:false
      let title = payload.title?payload.title:null
      let text = payload.text?payload.text:null
      let field = payload.field?payload.field:[
        {type:'text',label:''}
      ]
      let text2 = payload.text2?payload.text2:null
      let btnYes = payload.btnYes?payload.btnYes:null
      let btnNo = payload.btnNo?payload.btnNo:null
      let action = payload.action?payload.action:{}

      let newDialog = {
        show : show,
        fixed : fixed, 
        title : title,
        text : text,
        field : field,
        text2 : text2,
        btnYes : btnYes,
        btnNo:btnNo,
        action:action
      } 
      state.mbs_dialog_input = newDialog
    },
    setMBS_progress_dialog({ commit,state}, payload) {
     
      if(!payload){return}
      let mbs_progress_dialog = state.mbs_progress_dialog
      let show = payload.show?payload.show:false 
      let fixed = payload.fixed?payload.fixed:false
      let title = payload.title?payload.title:null
      let text = payload.text?payload.text:null
      let color = payload.color?payload.color:'primary' 
      let action = payload.action?payload.action:{}

      let newDialog = {
        show : show,
        fixed : fixed, 
        title : title,
        text : text,
        color : color, 
        action:action
      }  
      state.mbs_progress_dialog = newDialog
    },
    setMBS_progress_toolbar({ commit,state}, payload) { 
      if(!payload){return}
      let mbs_progress_toolbar = state.mbs_progress_toolbar
      let show = payload.show?payload.show:false  
      let no_subtoolbar = payload.no_subtoolbar?payload.no_subtoolbar:mbs_progress_toolbar.no_subtoolbar
      let color = payload.color?payload.color:mbs_progress_toolbar.color
      let background_color = payload.background_color?payload.background_color:'' 
      let height = payload.height?payload.height:6 
      let top = payload.top?payload.top:6 
      let action = payload.action?payload.action:{}

      let newDialog = {
        show : show,
        no_subtoolbar : no_subtoolbar,
        color : color, 
        background_color : background_color, 
        height : height, 
        top : top, 
        action:action
      }  
      state.mbs_progress_toolbar = newDialog
    },
    setMBS_alert_toolbar({ commit,state}, payload) { 
      if(!payload){return}
      let mbs_alert_toolbar = state.mbs_alert_toolbar
      let show = payload.show?payload.show:false   
      let min = payload.min?payload.min:false   
      let type = payload.type?payload.type:mbs_alert_toolbar.type 
      let text = payload.text?payload.text:mbs_alert_toolbar.text 
      let btnYes = payload.btnYes?payload.btnYes:mbs_alert_toolbar.btnYes 
      let btnNo = payload.btnNo?payload.btnNo:mbs_alert_toolbar.btnNo 
      let color = payload.color?payload.color:'' 
      let icon = payload.icon?payload.icon:null 
      let items = payload.items?payload.items:[] 
      let action = payload.action?payload.action:{}

      let newDialog = {
        show : show,
        min : min,
        type : type,
        text : text,
        btnYes : btnYes,
        btnNo : btnNo,
        color : color, 
        icon : icon, 
        items : items,  
        action:action
      }  
      state.mbs_alert_toolbar = newDialog
    }, 
    setMBS_snackbar({ commit,state}, payload) { 
      if(!payload){return}
      let mbs_snackBar = state.mbs_snackBar
      let show = payload.show?payload.show:false 
      let time = payload.time?payload.time:mbs_snackBar.time  
      let app = payload.app?payload.app:mbs_snackBar.app 
      let text = payload.text?payload.text:'...' 
      let color = payload.color?payload.color:mbs_snackBar.color 
      let textColor = payload.textColor?payload.textColor:mbs_snackBar.textColor  
      let lines = payload.lines?payload.lines:false 
      let vertical = payload.vertical?payload.vertical:false 
      let x = payload.x?payload.x:mbs_snackBar.x 
      let y = payload.y?payload.y:mbs_snackBar.y 
      let center = payload.center?payload.center:mbs_snackBar.center 
      let action = payload.action?payload.action:{}

      let newSnackbar = {
        show : show,
        time : time, 
        app : app,
        text : text, 
        color : color,
        textColor : textColor,  
        lines : lines, 
        vertical : vertical,
        x : x, 
        y : y, 
        center : center, 
        action:action
      }  
      state.mbs_snackBar = newSnackbar
    }, 
    setMBS_card_swipe({ commit,state}, payload) { 
      if(!payload){return}
      let mbs_card_swipe = state.mbs_card_swipe
      let show = payload.show?payload.show:false 
      let fixed = payload.fixed?payload.fixed:false  
      let page = payload?.page 
      let action = payload.action?payload.action:{}

      let newDetails = {
        ...mbs_card_swipe,
        show : show,
        fixed : fixed, 
        page : page, 
        action:action
      }   
      state.mbs_card_swipe = newDetails
    }, 
    addAlertItem({ commit,state}, payload) { 
      if(!payload){return}
      if(!payload.name){return}

      if (!state.alert_items) {
        state.alert_items = []
      }  
      state.alert_items.push(payload)
    },
    addIfNoAlertItem({ commit,state}, payload) { 
      if(!payload){return}
      if(!payload.name){return}

      if (!state.alert_items) {
        state.alert_items = []
      }  
      let item = state.alert_items.find(alert=>{
        return alert.name == payload.name
      })
      if (!item) {
        state.alert_items.push(payload) 
      }
    },
    deleteAlertItem({ commit,state}, payload) { 
      if(!payload){return}
      if(!payload.name){return}

      if (!state.alert_items) {
        state.alert_items = []
      }  
      let items = state.alert_items.filter(alert=>{
        return alert.name != payload.name
      })
      if (!items) {
        state.alert_items = items
      }
    },
    deleteAllAlertItems({ commit,state}, payload) { 
      state.alert_items=null
    },
    setPAGE_LOAD({ commit,state}, payload) { 
      state.PAGE_LOAD=payload?payload:false
    },
  },
}