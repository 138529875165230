<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 33.94 19.61"
  :fill="iconColor" xml:space="preserve">

    <path d="M4.13,12.45H.82A.75.75,0,0,1,.06,12a.66.66,0,0,1,.18-.8,1.13,1.13,0,0,1,.6-.22q3.32,0,6.63,0a.75.75,0,0,1,.82.76.76.76,0,0,1-.85.76C6.34,12.46,5.23,12.45,4.13,12.45Z"/>
    
    <path d="M5.3,8.68H3a.76.76,0,0,1-.72-.75A.74.74,0,0,1,3,7.17c1.51,0,3,0,4.52,0a.72.72,0,0,1,.75.75.74.74,0,0,1-.78.75c-.74,0-1.48,0-2.21,0Z"/>
    
    <path class="cls-1" d="M19.91,11.23l11-9.41c.09-.07.17-.15.32-.29h-24l.31.29,11,9.43A.85.85,0,0,0,19.91,11.23Z"/>
    
    <path class="cls-1" d="M20.66,12.59a2.16,2.16,0,0,1-2.57.21,2.79,2.79,0,0,1-.38-.3l-2-1.71L7.24,18.06h24l-8.49-7.27C22,11.41,21.35,12,20.66,12.59Z"/>
    
    <path class="cls-1" d="M32.38,17.06V2.54L23.91,9.81Z"/>
    
    <path d="M33.87,2.41a3,3,0,0,0-3-2.4H7.47A2.93,2.93,0,0,0,4.64,2.26a9.33,9.33,0,0,0-.1,2,.7.7,0,0,0,.72.65A.72.72,0,0,0,6,4.23C6,4,6,3.84,6,3.65v-1l.12,0,8.4,7.2L6.16,17,6,17c0-.47,0-.95,0-1.42a.75.75,0,0,0-.76-.82.76.76,0,0,0-.75.83v.92a3,3,0,0,0,3.16,3.15H30.77a3,3,0,0,0,3.16-3.13q0-6.66,0-13.33A3.6,3.6,0,0,0,33.87,2.41ZM7.56,1.82l-.31-.29h24c-.15.14-.23.22-.32.29l-11,9.41a.85.85,0,0,1-1.33,0ZM7.24,18.06l8.49-7.27,2,1.71a2.79,2.79,0,0,0,.38.3,2.16,2.16,0,0,0,2.57-.21c.69-.58,1.37-1.18,2.08-1.8l8.49,7.27Zm25.14-1L23.91,9.81l8.47-7.27Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
