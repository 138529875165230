<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 32.39 30.63"
  :fill="iconColor" xml:space="preserve">

    <path class="cls-1" d="M31,3.55V16.66c-.81.29-1.6.58-2.39.85a1.42,1.42,0,0,1-.5.08c-1.19,0-2.37,0-3.56,0a1.59,1.59,0,0,1-.5-.15A13.4,13.4,0,0,0,22.52,17q-2.89-.54-5.81-1a11.74,11.74,0,0,0-5.42.49l-.24.06v-13h7V5.37c0,.29,0,.58,0,.88s0,.59.36.73.57,0,.8-.26L21,4.94l1.83,1.83a.64.64,0,0,0,.72.22.61.61,0,0,0,.39-.64c0-.92,0-1.85,0-2.8Z"/>
    
    <path class="cls-1" d="M16.17,19.43,16,20.58l3.37.6,2.78.5a2.38,2.38,0,0,0,2.78-1.42.63.63,0,0,1,.31-.27q2.75-1,5.49-2a1.17,1.17,0,0,1,1.6.67,1.15,1.15,0,0,1-.79,1.53l-9,3.28c-1.15.42-2.29.85-3.45,1.22a6.73,6.73,0,0,1-3.48,0,6.53,6.53,0,0,0-5,.9l-.11,0L6.81,20.41c3-2.52,6.31-3.88,10.26-3.2l5.66,1a1.3,1.3,0,0,1,1,.53,1.14,1.14,0,0,1,0,1.25,1.09,1.09,0,0,1-1.17.57c-1.24-.2-2.48-.43-3.72-.64Z"/>
    
    <path class="cls-1" d="M0,23l4.8-3.36,5.38,7.68-4.8,3.36Z"/>
    
    <path class="cls-1" d="M18.05,0V2.32H11c0-.6,0-1.21,0-1.81,0-.35.28-.51.72-.51h6.29Z"/>
    
    <path class="cls-1" d="M23.94,2.32V0h6.28c.55,0,.74.2.75.76V2.32Z"/>
    
    <path class="cls-1" d="M19.25,5V0h3.48V5L22,4.28l-.52-.52a.6.6,0,0,0-1,0Z"/>

  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
