<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 24 24"
  :fill="iconColor" xml:space="preserve"> 
  <path fill="currentColor" d="M16 10H4C2.9 10 2 10.9 2 12V19H18V12C18 10.9 17.11 10 16 10M16 14H12V12H16V14M15 9H5V4H15V9M22 7V13H20V7H22M20 15H22V17H20V15Z" />
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
