<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 28.71 27.27"
  :fill="iconColor" xml:space="preserve">

    <path d="M20.59,13.2c1.17.5,2.22,1,3.32,1.4,2.33.84,3.09,2.76,3.45,4.94a29,29,0,0,1,.28,3.1,9.8,9.8,0,0,0,.77,3.1l.3.71-2.24.24c-2,.2-4,.4-6.07.58-.13,0-.36-.07-.38-.16a5.06,5.06,0,0,0-1.69-2.4s0-.05,0,0l1.7-.89c.7.32,1.44.66,2.2,1,.22.09.5.17.61-.15s-.17-.4-.41-.47a4.73,4.73,0,0,1-3.66-4.42,1.37,1.37,0,0,0-.29-.64c-.79-1.12-1.61-2.22-2.38-3.35a5.18,5.18,0,0,1-.59-1.26,1,1,0,0,1,.38-1.12,1.09,1.09,0,0,1,1.25-.22,3.61,3.61,0,0,1,.84.61c.87.83,1.72,1.69,2.59,2.52a8.4,8.4,0,0,0,.9.67c.59.4,1.18.81,1.79,1.2.12.08.31.05.48.07,0-.17.06-.43,0-.5a16.85,16.85,0,0,0-1.43-1.09c-.22-.16-.49-.24-.72-.38a1.59,1.59,0,0,1-1-1.66A9,9,0,0,0,20.59,13.2Z"/>
    
    <path d="M17.91,19.42c-.77-1.08-1.56-2.14-2.31-3.24a6.15,6.15,0,0,1-.69-1.41,1.65,1.65,0,0,1,.64-2,1.75,1.75,0,0,1,2.14-.13,7.18,7.18,0,0,1,1,.83c.41.37.79.76,1.24,1.19a1.21,1.21,0,0,0,0-.19q0-6.36,0-12.7A1.77,1.77,0,0,0,18.09,0H1.91A1.78,1.78,0,0,0,0,1.93V22.31a1.64,1.64,0,0,0,1.77,1.78q7.44,0,14.87,0a10.19,10.19,0,0,0,1.74-.2,1.66,1.66,0,0,0,1.14-.72,5.62,5.62,0,0,1-1.38-3.28A1,1,0,0,0,17.91,19.42ZM4.1,4.9a.35.35,0,0,1,.35-.35H15.61A.35.35,0,0,1,16,4.9v.3a.35.35,0,0,1-.35.35H4.45A.35.35,0,0,1,4.1,5.2Zm0,1.79a.35.35,0,0,1,.35-.35H15.61a.35.35,0,0,1,.35.35V7a.35.35,0,0,1-.35.35H4.45A.35.35,0,0,1,4.1,7Zm0,1.79a.35.35,0,0,1,.35-.35H9.65a.36.36,0,0,1,.36.35v.3a.35.35,0,0,1-.36.35H4.43a.34.34,0,0,1-.35-.35Zm.34,12.33H4.1a.28.28,0,0,1-.28-.27V14.18a.29.29,0,0,1,.28-.28h.32a.29.29,0,0,1,.28.28v6.36A.28.28,0,0,1,4.42,20.81Zm1.33,0H5.43a.27.27,0,0,1-.27-.27V14.18a.28.28,0,0,1,.27-.28h.32a.28.28,0,0,1,.28.28v6.36A.27.27,0,0,1,5.75,20.81Zm1.34,0H6.77a.27.27,0,0,1-.28-.27V14.18a.28.28,0,0,1,.28-.28h.32a.28.28,0,0,1,.27.28v6.36A.27.27,0,0,1,7.09,20.81Zm1.33,0H8.1a.29.29,0,0,1-.28-.28V14.18a.28.28,0,0,1,.28-.27h.32a.28.28,0,0,1,.28.27v6.36A.29.29,0,0,1,8.42,20.82Zm1.34,0H9.44a.28.28,0,0,1-.28-.28V14.18a.27.27,0,0,1,.28-.27h.32a.27.27,0,0,1,.27.27v6.36A.28.28,0,0,1,9.76,20.82Zm1.33,0h-.32a.29.29,0,0,1-.28-.28V14.18a.28.28,0,0,1,.28-.27h.32a.28.28,0,0,1,.28.27v6.36A.29.29,0,0,1,11.09,20.82Zm1.34,0H12.1a.28.28,0,0,1-.27-.28V14.18a.27.27,0,0,1,.27-.27h.33a.27.27,0,0,1,.27.27v6.36A.28.28,0,0,1,12.43,20.82Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
