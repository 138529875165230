<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 29.88 26.77"
  :fill="iconColor" xml:space="preserve">

    <path d="M29.27,18.47a2.87,2.87,0,0,1-1.33,1.74c-3,2.12-6,4.27-9,6.4a.89.89,0,0,1-.44.15H5.89c0-.11,0-.2,0-.29q0-3.69,0-7.38A1,1,0,0,1,6,18.58a4.83,4.83,0,0,1,3.72-2.45,5.26,5.26,0,0,1,3.59.72.73.73,0,0,0,.38.08h6.07a1.27,1.27,0,0,1,1.31,1.54,1.24,1.24,0,0,1-1.28,1H16.19v1.69a.53.53,0,0,0,.12,0c1.25,0,2.5,0,3.74,0a2.9,2.9,0,0,0,2.7-2.28.82.82,0,0,1,.37-.56c.88-.61,1.77-1.21,2.61-1.86a2.16,2.16,0,0,1,3.5,1.22s0,.07,0,.1Z"/><path d="M5.91,0h6.17V10h6V0h6.2V.32q0,7.41,0,14.84a.51.51,0,0,1-.24.47c-.53.35-1,.73-1.58,1.11a1.33,1.33,0,0,1-.12-.16,3,3,0,0,0-2.54-1.36c-1.85,0-3.7,0-5.55,0a1.85,1.85,0,0,1-.65-.14,7.07,7.07,0,0,0-7.56.84L5.91,16Z"/><path d="M0,17.3H4.14v9.45H0Z"/><path d="M13.81,8.28V0h2.54V8.28Z"/><polygon points="28.75 9.35 28.75 0.01 27.19 0.01 27.19 9.35 26.06 9.35 27.97 12.66 29.88 9.35 28.75 9.35"/>

  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
