<template>
    <div>
        
        <v-menu v-if="true"
            transition="scale-transition"
            bottom="" 
            v-model="show"
            origin="center center" rounded="xl"
            :close-on-content-click="false"
            :nudge-width="200" >

            <template v-slot:activator="{ on }"> 

                <v-btn-toggle  rounded dense background-color="secondary" dark >
                    <v-btn  v-on="on" plain active
                        color="secondary" 
                        class="text-capitalize"  >
                        <v-icon class="mr-1" size="20">home</v-icon>
                        <div class="white--text">Home</div> 
                        <v-icon class="ml-2">arrow_drop_down</v-icon>  
                    </v-btn> 
                    <v-btn plain color="secondary" @click="back()"><v-icon class="ml-2">subdirectory_arrow_left</v-icon> </v-btn>
                </v-btn-toggle>
            </template> 
            <v-card>  
                <v-card-title class="secondary " >
                    <div class="white--text">VISITED PAGES</div>
                    <v-spacer></v-spacer> 
                        <v-btn color="white"   small outlined="" 
                        @click="go('/account/history')">History</v-btn>
                </v-card-title>
                <v-row class="px-5 py-3 primary my-0" >
                    <div v-for="(link, index) in links" :key="index" > 
                        <div class="not-f5 font-weight-bold font-italic white--text" v-if="(links?links.length:0)===index+1">
                            <div v-if="!$route.params.id">
                            {{link}}
                            </div>
                            <div v-else>
                            ...
                            </div>
                        </div>
                        <router-link v-else :to="getLink({name:link,index:index})" class="not-f4 not-line0 font-italic b1--text">
                            {{link===""?"home":link}}
                            <span class="not-6 font-weight-bold px1">/</span>
                        </router-link> 
                    </div>  
                </v-row>
                <v-divider></v-divider>
                <v-virtual-scroll
                    height="300"
                    item-height="50"
                    :items="history.visited_page">
                    <template v-slot ="{item, index}" >
                       
                        <v-list-item
                            :key="index" 
                            @click="go(item.fullPath)"> 
                            <v-list-item-action>
                                <v-icon>link</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{item.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>  
                         <v-divider></v-divider>
                    </template>
                </v-virtual-scroll>

                <v-card-actions>
                    <v-layout column>
                    <v-layout class="mb-1" >
                        
                    </v-layout>
                    <v-layout  >
                        <v-spacer></v-spacer> 
                        <v-btn class="mr-1" color="primary" rounded small outlined="" 
                        @click="back()"><v-icon>arrow_back</v-icon> page</v-btn>
                        <v-btn color="primary" rounded small outlined="" 
                        @click="go('/')"><v-icon>home</v-icon> Home</v-btn> 
                    </v-layout>

                    </v-layout>
                    
                </v-card-actions>
            </v-card>
        </v-menu>

    </div>
</template>

<script> 
export default {
    props:['snShow','snTime','snY','snX','snMode','snTx'],
    data(){
        return{
            show:false
        }
       
    },
    mounted() { 
        
    },
    computed:{
        //common 
        vs(){
            return this.$vuetify.breakpoint
        }, 
        us(){
            let returnUser = this.$store.getters.getUser
            return returnUser
        }, 
        ud(){
            let returnUser = this.$store.getters.getUserDetails
            return returnUser
        }, 
        loading(){ 
            const loading = this.$store.getters.getLoading
            return loading
        },
        processes(){ 
            const process = this.$store.getters.getProcesses
            return process
        }, 
        responses(){ 
            const re = this.$store.getters.getResponses
            return re
        },
        ACTIONS(){
            return this.MBS.actions
        },
        DATA(){
            return this.MBS.data
        },
        /////////
        history(){ 
            const re = this.$store.getters.getHistory
            return re
        },
        links(){
            let path = this.$route.path
            return path.split("/")
        }
         
    },
    methods:{
         

        onNotNo(){
            this.$emit('notNo')
        },
        snackbarClose(value){
            this.show = value
        },
        notClose(){
            this.show = false
        },

        goTo(page){
            this.notClose() 
            let to = page.goTo
            let deptIndex = to.indexOf('Department=')
            let itIndex = to.indexOf('Items=')
             let ownerIndex = to.indexOf('OwnerView=')
            if (to==='MyAccount') {
                this.$router.push('/myAccount' )   
            }else if (to==='MyProfile') {
                this.$router.push('/myAccount/myProfile' )
            }else if (to==='MyStore') {
                this.$router.push('/myAccount/myStore' )
            }else if (to==='MyCart') {
                this.$router.push('/myAccount/myCart' )
            }else if (to=='Pricing') {
                this.$router.push('/myAccount/pricing' )
            }else if (to=='Owners') {
                this.$router.push('/owners' )
            }
            
            else if (deptIndex!==-1) {
                let id = to.split('=')[1]
                this.$router.push('/department/'+id )    
            }else if (itIndex!==-1) {
                this.$router.push('/myAccount' )
            }else if (ownerIndex!==-1) {
                let id = to.split('=')[1]
                this.$router.push('/owners/'+id )    
            }
        },
        pageLoad(){
            let ld = this.loadedPages
            let added = []
            let page = []
            ld.forEach(p => {
                let deptIndex = p.indexOf('Department=')
                let itIndex = p.indexOf('Items=')
                let ownerIndex = p.indexOf('OwnerView=')
                if (p==='MyAccount') {
                    let index = added.indexOf(p)
                    if (index===-1) {
                        page.push({
                            icon:'account_circle', 
                            name:'My account',
                            goTo:p
                        })
                        added.push(p)
                    } 
                }else if (p==='MyProfile') {
                    let index = added.indexOf(p)
                    if (index==-1) {
                       page.push({
                           icon:'account_box', 
                           name:'My profile',
                           goTo:p
                        }) 
                       added.push(p)
                    } 
                }else if (p==='MyStore') {
                    let index = added.indexOf(p)
                    if (index==-1) {
                        page.push({
                            icon:'widgets', 
                            name:'My store',
                            goTo:p
                        })
                        added.push(p)
                    } 
                }else if (p==='MyCart') {
                    let index = added.indexOf(p)
                    if (index==-1) {
                        page.push({
                            icon:'add_shopping_cart', 
                            name:'My cart',
                            goTo:p
                        })
                        added.push(p)
                    } 
                }else if (p=='Pricing') {
                    let index = added.indexOf(p)
                    if (index==-1) {
                        page.push({
                            icon:'card_membership', 
                            name:'Pricing',
                            goTo:p
                        }) 
                        added.push(p)
                    }
                }else if (p=='Owners') {
                     let index = added.indexOf(p)
                    if (index==-1) {
                        page.push({
                            icon:'domain', 
                            name:p,
                            goTo:p
                        }) 
                        added.push(p)
                    }
                }

                else if (deptIndex!==-1) {
                    let id = p.split('=')[1]
                    let dept = this.department(id)
                    let index = added.indexOf(p)
                    if (index==-1) {
                        page.push({
                            ...dept,
                            goTo:p
                        }) 
                        added.push(p)
                    } 
                    
                     
                }else if (itIndex!==-1) {
                    // let id = p.split('=')[1]
                    // let deptID = id.split('__')[0]
                    // let subID = id.split('__')[1]
                    // let sub = this.subDepartment(deptID,subID)
                    // let index = added.indexOf(p)
                    // if (index==-1) {
                    //     page.push(sub) 
                    //     added.push(p)
                    // }    
                }else if (ownerIndex!==-1) {
                    let id = p.split('=')[1]
                    let dept = this.owner(id)
                    let index = added.indexOf(p)
                    if (index==-1) {
                        page.push({
                            ...dept,
                            goTo:p
                        }) 
                        added.push(p)
                    } 
                    
                     
                }


            });
            return page
        },
         
        back(){  
            this.$router.go(-1)
        },
        go(to){ 
            this.notClose()
            this.ACTIONS.go(to)
        },
        getLink(item){
            let index = item.index
            let name = item.name
            let path = this.$route.path
            let id = this.$route.path

            if (!index) {
                return "/"
            }
            if (index===0) {
                return "/"
            }else {
                let link = path.split(name)  
                return link[0]+name
            }
        }
    } 
}
</script>
