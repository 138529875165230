<template>
    <div>   
        <v-divider></v-divider>
        <mbs-page-tab 
            :items="MY_LINKS"/>  
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <!-- <v-btn @click="tryAction()" color="success">tryAction</v-btn> -->
                    <v-card >  
                        <mbs-item-manager
                            v-model="tab_input"
                            :table_tabs="TABLE_TABS"
                            :table_title="'Manage Receivables'"
                            :table_title_icon="'mdi-clipboard-text-clock'"
                            :add_vertical_lines="true"
                            :row_click="true" 
                            :row_click_action="false" 
                            :top_label="false"
                            :filter_date="false"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :show_expand="false"
                            :single_expand="true"
                            :item_key_expand="'code'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :name_child_items="NAME_ITEMS"
                            :name_child_item="NAME_ITEM"
                            :value_child_items="VALUE_ITEMS"
                            :value_child_item="VALUE_ITEM" 
                            :header_items="RECEIVABLES_ACCOUNT_HEADERS" 
                            :header_child_items="INVOICES_HEADERS" 
                            :data_items="CurrentTab?Sorted_Receivables_Paid:Sorted_Receivables_Unpaid"  
                            :data_child_items="UnpaidInvoicesData"  
                            :is_joined="true"   
                            :path_item="QUOTATIONS_AND_INVOICES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"    
                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            <!-- VIEW RECEIVABLE -->
            <div v-if="dialog_view_receivable">
                <v-dialog 
                    v-model="dialog_view_receivable"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="1200px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" class="b2">
                        <v-card @click="GO_TO_ACCOUNT(SelectedReceivable)" class="ma-4" hover>
                            <v-list>
                                <v-list-item> 
                                    <v-list-item-action> <v-icon size="80" class="mr-2">mdi-clipboard-text-clock</v-icon> </v-list-item-action> 
                                    <v-list-item-content> 
                                        <v-list-item-title>
                                            <div class="font-weight-bold not-f7 grey--text">{{MBS.actions.TEXT_UP(SelectedReceivable.name)}}</div> 
                                        </v-list-item-title> 
                                        <!-- <v-list-item-subtitle class="font-weight-bold not-f6 --text">{{ SelectedReceivable.unpaid_invoices_size}} invoice(s)</v-list-item-subtitle>  -->
                                        <v-list-item-subtitle class="font-weight-bold font-italic not-f4  secondary--text">
                                            {{ CurrentTab?SelectedReceivable.paid_invoices_size:SelectedReceivable.unpaid_invoices_size}} {{ CurrentTab?"Invoice Payment(s)":"Unpaid Invoice(s)" }} </v-list-item-subtitle> 
                                    </v-list-item-content> 
                                    <v-list-item-action>
                                        <v-layout row wrap>
                                            <div class="text-right ma-4">
                                                <div class="font-weight-bold not-f5 grey--text">TOTAL BALANCE</div>
                                                <div class="font-weight-bold not-f8 grey--text text--darken-1">
                                                    <span class="not-f4">MWK</span> 
                                                    {{ MBS.actions.money(SelectedReceivable.receivable_balance_amount) 
                                                }}</div>
                                            </div> 
                                            <!-- <v-btn class="primary" dark outlined icon color=""><v-icon>close</v-icon></v-btn> -->
                                        </v-layout>
                                    
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card :max-height="vsh-200" class="mbs-scroll-y"> 
                            <mbs-item-manager
                                :add_vertical_lines="true" 
                                :row_click="true"
                                :row_click_action="false"
                                :top_label="false"
                                :filter_date="false"
                                :filter_date_value="'local_created_at'"
                                :filter_default_days="'90'"
                                :name_items="CurrentTab?DATA.ITEMS.PAYMENTS.names:DATA.ITEMS.INVOICES.names"
                                :name_item="CurrentTab?DATA.ITEMS.PAYMENTS.name:DATA.ITEMS.INVOICES.name"
                                :value_items="CurrentTab?DATA.ITEMS.PAYMENTS.values:DATA.ITEMS.INVOICES.values"
                                :value_item="CurrentTab?DATA.ITEMS.PAYMENTS.value:DATA.ITEMS.INVOICES.value" 
                                :header_items="CurrentTab?PAYMENTS_HEADERS:INVOICES_HEADERS" 
                                :data_items="CurrentTab?SelectedReceivable.paid_invoices:SelectedReceivable.unpaid_invoices"  
                                :is_joined="true"   
                                :path_item="QUOTATIONS_AND_INVOICES_PATH"     
                                :table_actions="invoice_table_actions" 
                                @SELECT_TABLE_ACTION="TABLE_ACTION"
                                @SELECT_ITEM_ACTION="ITEM_ACTION2"
                            />  
                        </v-card> 
                    </v-card> 
                </v-dialog>  
            </div>

            <!-- SELECT & PAYMENT -->
            <div v-if="dialog_select_and_payment">
                <v-dialog 
                    v-model="dialog_select_and_payment"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" class="b2">  
                        <v-card @click="GO_TO_ACCOUNT(SelectedReceivable)" class="my-4 mx-5" hover v-if="SelectedReceivable?SelectedReceivable.name?true:false:false">
                            <v-list>
                                <v-list-item> 
                                    <v-list-item-action> <v-icon size="80" class="mr-2">mdi-clipboard-text-clock</v-icon> </v-list-item-action> 
                                    <v-list-item-content> 
                                        <v-list-item-title>
                                            <div class="font-weight-bold not-f7 grey--text">{{MBS.actions.TEXT_UP(SelectedReceivable.name)}}</div> 
                                        </v-list-item-title> 
                                        <!-- <v-list-item-subtitle class="font-weight-bold not-f6 --text">{{ SelectedReceivable.unpaid_invoices_size}} invoice(s)</v-list-item-subtitle>  -->
                                        <v-list-item-subtitle class="font-weight-bold font-italic not-f4  secondary--text">{{ SelectedReceivable.unpaid_invoices_size}} Unpaid invoice(s)</v-list-item-subtitle> 
                                    </v-list-item-content> 
                                    <v-list-item-action>
                                        <v-layout row wrap>
                                            <div class="text-right ma-4">
                                                <div class="font-weight-bold not-f5 grey--text">TOTAL BALANCE</div>
                                                <div class="font-weight-bold not-f8 grey--text text--darken-1">
                                                    <span class="not-f4">MWK</span>ss
                                                    {{ MBS.actions.money(SelectedReceivable.receivable_balance_amount) 
                                                }}</div>
                                            </div> 
                                            <!-- <v-btn class="primary" dark outlined icon color=""><v-icon>close</v-icon></v-btn> -->
                                        </v-layout>
                                    
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card> 
                        <v-card-text class="white" >
                            <br>
                            <v-divider class="mx-2 mb-5"></v-divider>   
                            <form @submit.prevent="MAKE_ACCOUNT_PAYMENT(SelectedReceivable,input.amount,paying_option)">
                                <v-layout row wrap class="ma-0 ">
                                    <v-flex xs12>
                                        <div class="ml-2">Select Receivable Account</div>
                                        <!-- v-model="paying_option" -->
                                        <v-autocomplete required
                                            v-model="selected_receivable_account_key"
                                            filled  
                                            :items="ReceivablesData"
                                            item-value="key"
                                            item-text="name" 
                                            class="mx-2"
                                        ></v-autocomplete> 
                                    </v-flex>
                                    
                                    <v-flex xs6>
                                        <div class="ml-2">Balance</div>
                                        <v-text-field   
                                            :value="MBS.actions.money(SelectedReceivable.receivable_balance_amount-toNumber(input.amount))"
                                            filled   disabled
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-text-field>  
                                    </v-flex> 
                                    <v-flex xs6>
                                        <div class="ml-2">Pay amount</div>
                                        <v-text-field required
                                            v-model="input.amount"
                                            filled  
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-text-field> 
                                    </v-flex>
                                    <v-flex xs12>
                                        <div class="ml-2">Payment Option</div>
                                        <!-- v-model="paying_option" -->
                                        <v-autocomplete required
                                            v-model="paying_option"
                                            filled  
                                            :items="PaymentMethodsData"
                                            item-value="key"
                                            item-text="name" 
                                            class="mx-2"
                                        ></v-autocomplete> 
                                    </v-flex>
                                </v-layout> 
                                <v-layout class="ma-0"> 
                                    <v-flex >
                                        <v-btn width="100%" :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2">Receive Payment</v-btn> 
                                    </v-flex> 
                                    <v-btn @click="dialog_select_and_payment = false" color="secondary" large class="mt-5 mb-2 ml-1">Cancel Payment</v-btn>
                                      
                                </v-layout>
                            </form>
                        </v-card-text> 
                    </v-card> 
                </v-dialog>  
            </div>
            
            <!-- RECEIVABLE ACCOUNT PAYMENT -->
            <div v-if="dialog_receivable_account_payment">
                <v-dialog 
                    v-model="dialog_receivable_account_payment"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" class="b2"> 
                        <v-card @click="GO_TO_ACCOUNT(SelectedReceivable)" class="my-4 mx-5" hover>
                            <v-list>
                                <v-list-item> 
                                    <v-list-item-action> <v-icon size="80" class="mr-2">mdi-clipboard-text-clock</v-icon> </v-list-item-action> 
                                    <v-list-item-content> 
                                        <v-list-item-title>
                                            <div class="font-weight-bold not-f7 grey--text">{{MBS.actions.TEXT_UP(SelectedReceivable.name)}}</div> 
                                        </v-list-item-title> 
                                        <!-- <v-list-item-subtitle class="font-weight-bold not-f6 --text">{{ SelectedReceivable.unpaid_invoices_size}} invoice(s)</v-list-item-subtitle>  -->
                                        <v-list-item-subtitle class="font-weight-bold font-italic not-f4  secondary--text">{{ SelectedReceivable.unpaid_invoices_size}} Unpaid invoice(s)</v-list-item-subtitle> 
                                    </v-list-item-content> 
                                    <v-list-item-action>
                                        <v-layout row wrap>
                                            <div class="text-right ma-4">
                                                <div class="font-weight-bold not-f5 grey--text">TOTAL BALANCE</div>
                                                <div class="font-weight-bold not-f8 grey--text text--darken-1">
                                                    <span class="not-f4">MWK</span> 
                                                    {{ MBS.actions.money(SelectedReceivable.receivable_balance_amount) 
                                                }}</div>
                                            </div> 
                                            <!-- <v-btn class="primary" dark outlined icon color=""><v-icon>close</v-icon></v-btn> -->
                                        </v-layout>
                                    
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card> 
                        <v-card-text class="white" v-if="SelectedReceivable">
                            <br>
                            <v-divider class="mx-2 mb-5"></v-divider>
                            <!-- <v-layout justify-center align-center v-if="toNumber(input.amount)>toNumber(selected_receivable_account.balance)">
                                <v-card class="text-center px-5 py-2" outlined>
                                    <div class="font-weight-bold">Give Change</div>
                                    <div>MKW {{MBS.actions.money(toNumber(input.amount) - toNumber(selected_receivable_account.balance))}}</div> 
                                </v-card>
                            </v-layout> -->
                            <form @submit.prevent="MAKE_ACCOUNT_PAYMENT(SelectedReceivable,input.amount,paying_option)">
                                <v-layout row wrap class="ma-0 ">
                                    <v-flex xs12>
                                        <div class="ml-2">Payment Option</div>
                                        <!-- v-model="paying_option" -->
                                        <v-autocomplete required
                                            v-model="paying_option"
                                            filled  
                                            :items="PaymentMethodsData"
                                            item-value="key"
                                            item-text="name" 
                                            class="mx-2"
                                        ></v-autocomplete> 
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="ml-2">Balance</div>
                                        <v-text-field   
                                            :value="MBS.actions.money(SelectedReceivable.receivable_balance_amount-toNumber(input.amount))"
                                            filled   disabled
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-text-field>  
                                    </v-flex> 
                                    <v-flex xs6>
                                        <div class="ml-2">Pay amount</div>
                                        <v-text-field required
                                            v-model="input.amount"
                                            filled  
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-text-field> 
                                    </v-flex> 
                                </v-layout> 
                                <v-layout class="ma-0"> 
                                    <v-flex >
                                        <v-btn width="100%" :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2">Receive Payment</v-btn> 
                                    </v-flex>
                                    
                                    <v-btn @click="dialog_receivable_account_payment = false" color="secondary" large class="mt-5 mb-2 ml-1">Cancel Payment</v-btn>
                                    
                                    <!-- <v-btn v-if="toNumber(input.amount)>toNumber(selected_receivable_account.balance)"  :loading="inputLoading" color="secondary" large type="submit" class="mt-5 mb-2 mr-1">Receive & Give Change</v-btn> -->
                                </v-layout>
                            </form>
                        </v-card-text> 
                    </v-card> 
                </v-dialog>  
            </div>
            
            <!-- INVOICE PAYMENT -->
            <div v-if="dialog_pay_bill">
                <v-dialog 
                    v-model="dialog_pay_bill"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" class="b2">
                        <v-card @click="SHOW_DIALOG_DOCUMENT(selected_item)" class="my-4 mx-5" hover>
                            <v-list>
                                <v-list-item> 
                                    <v-list-item-action> <v-icon size="80" class="mr-2">mdi-clipboard-text-clock</v-icon> </v-list-item-action> 
                                    <v-list-item-content> 
                                        <v-list-item-title>
                                            <div class="font-weight-bold not-f7 grey--text">
                                                {{MBS.actions.TEXT_UP(selected_item.customer_name)}} 
                                                <small>({{ }})</small>
                                            </div> 
                                        </v-list-item-title> 
                                        <!-- <v-list-item-subtitle class="font-weight-bold not-f6 --text">{{ selected_item.unpaid_invoices_size}} invoice(s)</v-list-item-subtitle>  -->
                                        <v-list-item-subtitle class="font-weight-bold font-italic not-f4  secondary--text">
                                            invoice {{selected_item.receipt_number_ }}
                                        </v-list-item-subtitle> 
                                    </v-list-item-content> 
                                    <v-list-item-action>
                                        <v-layout row wrap>
                                            <div class="text-right ma-4">
                                                <div class="font-weight-bold not-f5 grey--text">AMOUNT</div>
                                                <div class="font-weight-bold not-f8 grey--text text--darken-1">
                                                    <span class="not-f4">MWK</span>
                                                    {{ MBS.actions.money(selected_item.paid_amount) 
                                                }}</div>
                                            </div>  
                                        </v-layout>
                                    
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card> 
                        <v-card-text v-if="selected_item" class="white">
                            <br>
                            <v-card outline  flat> 
                                <v-list v-if="selected_item" dense class="mb-5" >
                                    <v-list-item> 
                                        <v-list-item-content>
                                            <v-list-item-title>Amount</v-list-item-title>
                                            <v-list-item-subtitle>{{selected_item.sum_amount_}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-list-item-title>Paid</v-list-item-title>
                                            <v-list-item-subtitle>{{selected_item.invoice_paid_amount_}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-list-item-title>Balance</v-list-item-title>
                                            <v-list-item-subtitle>{{selected_item.invoice_balance_amount_}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>   
                            </v-card>
                            <v-divider class="mx-2 mb-5"></v-divider> 
                            <form @submit.prevent="MAKE_INVOICE_PAYMENT(selected_item,input.amount,paying_option)">
                                <v-layout row wrap class="ma-0 ">
                                    
                                    <v-flex xs12>
                                        <div class="ml-2">Payment Option</div>
                                        <v-autocomplete required
                                            v-model="paying_option"
                                            filled  
                                            :items="PaymentMethodsData"
                                            item-value="key"
                                            item-text="name" 
                                            class="mx-2"
                                        ></v-autocomplete> 
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="ml-2">Balance</div>
                                        <v-text-field   
                                            :value="MBS.actions.money(selected_item.invoice_balance_amount-toNumber(input.amount))"
                                            filled   disabled
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-text-field>  
                                    </v-flex> 
                                    <v-flex xs6>
                                        <div class="ml-2">Pay amount</div>
                                        <v-text-field required
                                            v-model="input.amount"
                                            filled  
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-text-field> 
                                    </v-flex>

                                    
                                </v-layout> 
                                <v-layout class="ma-0"> 
                                    <v-flex >
                                        <v-btn width="100%" :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1">Receive Payment</v-btn>
                                    </v-flex> 
                                    <v-btn @click="dialog_pay_bill=false" color="secondary" large   class="mt-5 mb-2 ml-1">Cancel Payment</v-btn>
                                </v-layout>
                            </form>
                        </v-card-text> 
                    </v-card> 
                </v-dialog>  
            </div>

            <div v-if="dialog_doc_show"> 
                <mbs-dialog-doc-show
                    v-model="dialog_doc_show"
                    :width="'600'"
                    :title="input_show_doc.title"
                    :data="input_show_doc.data"
                    :data_key="input_show_doc.data_key"
                    :document_type="input_show_doc.document_type"
                    :height="input_show_doc.height"
                    :top_header="input_show_doc.top_header"
                    :bottom_header="input_show_doc.bottom_header"
                />  
            </div>

            <div v-if="autoPrint">
                <mbs-auto-print 
                    :show="true"  
                    :type="typePrint"  
                    :com_printer="com_printer"  
                    :data="itemPrint" />  
            </div>
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import { mdiInvoiceList } from '@mdi/js';
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.BILLS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                DATA:DATA,
                NAME_ITEMS:DATA.ITEMS.INVOICES.names,
                NAME_ITEM:DATA.ITEMS.INVOICES.name,
                VALUE_ITEMS:DATA.ITEMS.INVOICES.values,
                VALUE_ITEM:DATA.ITEMS.INVOICES.value,  
                dialog_select_and_payment:false,
                dialog_receivable_account_payment:false,
                dialog_pay_bill:false,
                dialog_view_receivable:false,
                dialog_doc_show:false,
                input:{},
                tab_input:{}, 
                input_show_doc:{
                    // title:"INVOICE..",
                    top_header:true
                }, 
                input_amount:null,
                input_balance:null,
                inputLoading:null,
                selected_item:null,
                selected_receivable_account:null,
                selected_receivable_account_key:null,
                autoPrint:false, 
                   
                table_actions:[  
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",btn:true,icon:"mdi-cash-plus",action:"receive_payment",color:"secondary", outlined:false,  text:"Receive Payment"},   
                    {type:"action",btn:true,icon:"mdi-cash-fast",action:"manage_receivables_accounts",color:"secondary", outlined:false,  text:"Manage Accounts"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"}, 
                    {type:"action",icon:"mdi-database-refresh",action:"reload_invoices",color:"secondary", outlined:false,  text:"Reload the Invoice"}, 
                ],
                
                invoice_table_actions:[  
                    {type:"action",btn:true,icon:"mdi-cash",action:"receive_account_payment",color:"secondary", outlined:false,  text:"Receive Payment"},   
                    {type:"action",btn:true,icon:"mdi-eye",action:"view_statement",color:"secondary", outlined:false,  text:"View Statement"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"}, 
                    {type:"action",btn:true,icon:"mdi-close",action:"close_dialog",color:"secondary", outlined:false,  text:"Close"},   
                ], 
                TOTAL_ITEM_VALUES:[  
                    {name:'Total Amount',value:'unpaid_amount',show_value:'unpaid_amount_',money:true},
                    {name:'Total Paid',value:'paid_amount',show_value:'sum_amount_',money:true},
                    {name:'Total Balance',value:'receivable_balance_amount',show_value:'receivable_balance_amount_',money:true},
                ],
                INVOICE_TOTAL_ITEM_VALUES:[  
                    {name:'Total Amount',value:'amount',show_value:'amount_',money:true},
                    {name:'Total Paid',value:'total_paid',show_value:'total_paid_',money:true},
                    {name:'Total Balance',value:'balance',show_value:'balance_',money:true},
                ],
                paying_option:0,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            /////////
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                SalesOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                DefaultPaymentMethods: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                Receivables: state=> state.items[(DATA.ITEMS.RECEIVABLES.values).toUpperCase()],
                JoinedReceivables: state=> state.join[(DATA.ITEMS.RECEIVABLES.values).toUpperCase()],
                PaidInvoices: state=> state.items[(DATA.ITEMS.PAID_INVOICES.values).toUpperCase()],
                JoinedPaidInvoices: state=> state.join[(DATA.ITEMS.PAID_INVOICES.values).toUpperCase()],
                UnpaidInvoices: state=> state.items[(DATA.ITEMS.UNPAID_INVOICES.values).toUpperCase()],
                JoinedUnpaidInvoices: state=> state.join[(DATA.ITEMS.UNPAID_INVOICES.values).toUpperCase()],
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
            }), 
            PaymentMethodsData(){
                let DefaultPaymentMethods = this.DefaultPaymentMethods
                let items = this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
                let options = [
                    ...DefaultPaymentMethods
                ]
                if (items) {
                    options.push(...items)
                }
                return options
            },
            ReceivablesData(){ 
                let items = this.JoinedReceivables?this.JoinedReceivables:this.Receivables 
                if(!items){return null}
                return [
                    // {name:"UNKNOWN",code:'unknown',key:'unknown'},
                    ...items
                ]
            },
            Joined_ReceivablesData(){ 
                let receivable = this.ReceivablesData
                let unpaid = this.UnpaidInvoicesData
                let paid = this.PaidInvoicesData 
                if(!receivable){return null} 
                let all_receivable=[
                    {name:"UNKNOWN"}
                ]
                all_receivable.push(...receivable)
                let joined = []
                all_receivable.forEach(item => {
                    let unpaid_invoices = null
                    let paid_invoices = null 
                    let unpaid_invoice = null
                    let paid_invoice = null 
                    if (item.key=="unknown") {
                        unpaid_invoices = unpaid?unpaid.filter(invoice=>!invoice.receivable_key):null
                        paid_invoices = paid?paid.filter(invoice=>!invoice.receivable_key):null
                        
                    } else { 
                        unpaid_invoices = unpaid?unpaid.filter(invoice=>invoice.receivable_key == item.key):null
                        paid_invoices = paid?paid.filter(invoice=>invoice.receivable_key == item.key):null 
                    }
                    if (unpaid_invoices) {
                        unpaid_invoice = unpaid_invoices[0]
                    }
                    if (paid_invoices) {
                        paid_invoice = paid_invoices[0]
                    }


                    joined.push({
                        ...item,
                        unpaid_invoices:unpaid_invoices,
                        unpaid_invoices_size:this.MBS.actions.SIZE(unpaid_invoices),
                        paid_invoices:paid_invoices,
                        paid_invoices_size:this.MBS.actions.SIZE(paid_invoices),
                        unpaid_invoice:unpaid_invoice,
                        paid_invoice:paid_invoice,
                        unpaid_invoice_date:this.toNumber(unpaid_invoice?.local_created_at),
                        paid_invoice_date:this.toNumber(paid_invoice?.local_created_at),
                        unpaid_invoice_date_:unpaid_invoice?.local_created_at_,
                        paid_invoice_date_:paid_invoice?.local_created_at_,
                    }) 
                    // if (this.MBS.actions.SIZE(paid_invoices)||this.MBS.actions.SIZE(unpaid_invoices)) {
                    // }
                }); 
                return joined
            },
            Sorted_Receivables_Paid(){ 
                let receivables = this.Joined_ReceivablesData
                if(!receivables){return null}
                let list = [...receivables] 
                list.sort(this.MBS.actions.dynamicSort("paid_invoice_date",false))  
                console.log(list,'Sorted_Receivables_Paid........');
                return list
            },
            Sorted_Receivables_Unpaid(){ 
                let receivables = this.Joined_ReceivablesData
                if(!receivables){return null}
                let list = [...receivables] 
                list.sort(this.MBS.actions.dynamicSort("unpaid_invoice_date",false))    
                console.log(list,'Sorted_Receivables_Unpaid........');
                return list
            },
            PaidInvoicesData(){ 
                let items = this.JoinedPaidInvoices?this.JoinedPaidInvoices:this.PaidInvoices 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list 
            },
            UnpaidInvoicesData(){  
                let items = this.JoinedUnpaidInvoices?this.JoinedUnpaidInvoices:this.UnpaidInvoices 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("key",false))  
                return list
            },
            SelectedReceivable(){
                let account = null
                let account_key = this.selected_receivable_account_key
                if (account_key) {
                    this.selected_receivable_account = this.$store.getters.getReceivableByKey(account_key) 
                }
                account = this.selected_receivable_account
                let accounts = this.ReceivablesData
                let unpaid = this.UnpaidInvoicesData
                let paid = this.PaidInvoicesData
                if(!account){return null}
                let this_unpaid = unpaid?unpaid.filter(invoice=>invoice.receivable_key == account.key):null 
                let this_paid = paid?paid.filter(invoice=>invoice.receivable_key == account.key):null 
                let update_account = accounts?accounts.find(item=>item.key == account.key):null
 
                return {
                    ...update_account?update_account:account,
                    unpaid_invoices:this_unpaid,
                    unpaid_invoices_size:this.MBS.actions.SIZE(this_unpaid),
                    paid_invoices:this_paid,
                    paid_invoices_size:this.MBS.actions.SIZE(this_paid),
                }
            },
            CurrentTab(){
                let tab_input = this.tab_input
                return this.toNumber(tab_input.current_table_tab)
            }, 
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.payments,true) 
                return path
            },
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("office",1,true) 
                return thisLinks
            }, 
            QUOTATIONS_AND_INVOICES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.quotations_and_invoices,true) 
                return path
            }, 
            TABLE_TABS(){
                return [  
                    {
                        name:"Unpaid Invoices",
                        show_if:(this_,item)=>this_.MBS.actions.SIZE(item.unpaid_invoices),
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{ 
                                let size = 0
                                if (data) {
                                    data.forEach(item => {
                                        size = size+MBS.SIZE(item.unpaid_invoices)
                                    }); 
                                } 
                                return  size+"/"+MBS.SIZE(data)
                            }
                        }
                    },
                    {
                        name:"Payments",show_if:(this_,item)=>this_.MBS.actions.SIZE(item.paid_invoices),
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{
                                let size = 0
                                if (data) {
                                    data.forEach(item => {
                                        size = size+MBS.SIZE(item.paid_invoices)
                                    }); 
                                } 
                                return size+"/"+MBS.SIZE(data)
                            }
                        }
                    }, 
                ]
            },

            RECEIVABLES_ACCOUNT_HEADERS(){
                let tab = this.CurrentTab
                let invoice = [ 
                    {id:1,name:"General",title:true},  
                    {id:0,icon:"mdi-clipboard-text-clock",value:'icon_image',color:"primary lighten-1",background_color:"b",size:40,tile:true,cellClass:'',class:' b ',align:'center',show:true},
                    // {id:0,name:"Recent Action",value:'created_at_',show:false},     
                    {id:0,name:"Recent Tr.",value:'unpaid_invoice_date_',show:true},    
                    {id:0,name:"code",align:"",show:true},     
                    {id:0,name:"Name",value:"name",show:true},       
                    {id:0,name:"invoices",value:"unpaid_invoices_size",align:"center",show:true},          
                    {id:0,name:"Amount",value:"receivable_unpaid_amount_",align:"right",show:true}, 
                    {id:0,name:"Paid",value:'receivable_paid_amount_',align:'right',show:true},   
                    {id:0,name:"Balance",value:'receivable_balance_amount_',align:'right',show:true},       
                    {id:0,name:"description",value:"description",show:false},         
                    {id:0,name:"updated Date",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Receive Payment",action:"receive_account_payment",icon:"mdi-cash",show_if:item=>item.receivable_balance_amount>0}, 
                        {name:"View Account Invoice(s)",action:"view_account_invoice",icon:mdiInvoiceList}, 
                        {name:"View Receivable Account",action:"view_receivable_account",icon:"mdi-clipboard-text-clock"}, 
                        {name:"Account Payment Statement",action:"view_statement",icon:"mdi-text-box"}, 
                    ]},  
                ] 
                let payment = [ 
                    {id:1,name:"General",title:true},  
                    {id:0,icon:"mdi-clipboard-text-clock",value:'icon_image',color:"primary lighten-1",background_color:"b",size:40,tile:true,cellClass:'',class:' b ',align:'center',show:true},
                    // {id:0,name:"Recent Action",value:'created_at_',show:false},     
                    {id:0,name:"Recent Tr.",value:'paid_invoice_date_',show:true},   
                    {id:0,name:"code",align:"",show:true},     
                    {id:0,name:"Name",value:"name",show:true},            
                    {id:0,name:"Payments",value:"paid_invoices_size",align:"center",show:true},         
                    {id:0,name:"Amount",value:"receivable_unpaid_amount_",align:"right",show:true}, 
                    {id:0,name:"Paid",value:'receivable_paid_amount_',align:'right',show:true},   
                    {id:0,name:"Balance",value:'receivable_balance_amount_',align:'right',show:true},       
                    {id:0,name:"description",value:"description",show:false},         
                    {id:0,name:"updated Date",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Receive Payment",action:"receive_account_payment",icon:"mdi-cash",show_if:item=>item.receivable_balance_amount>0}, 
                        {name:"View Account Payment(s)",action:"view_account_invoice",icon:mdiInvoiceList}, 
                        {name:"View Receivable Account",action:"view_receivable_account",icon:"mdi-clipboard-text-clock"}, 
                        {name:"Account Payment Statement",action:"view_statement",icon:"mdi-text-box"}, 
                    ]},  
                ]
                return tab?payment:invoice
            },
            INVOICES_HEADERS(){
                let tab = this.CurrentTab
                return [ 
                    {id:1,name:"General",title:true},  
                    {id:0,name:"",value:'icon_image',color:"primary lighten-1",background_color:"b",size:30,tile:true,cellClass:'',class:' b ',align:'center',show:true},  
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"date",value:'local_created_at_',show:true},   
                    {id:0,name:"time",value:'created_at_time',show:false},   
                    {id:0,name:"Invoice",value:"receipt_number_",show:true},     
                    {id:0,name:"Customer",value:"customer_name",show:true},     
                    {id:0,name:"Type",value:'order_type_name',show:false},     
                    {id:0,name:"payee",value:'receivable_name',show:false},     
                    {id:0,name:"code",align:"center",show:false},          
                    {id:0,name:"Due",value:"due_date",show:false},     
                    {id:0,name:"items",value:'total_quantity_',show:false},   
                    {id:0,name:"Amount",value:'sum_amount_',align:'right',show:true},   
                    {id:0,name:"Paid",value:'invoice_paid_amount_',align:'right',show:true},   
                    {id:0,name:"Balance",value:'invoice_balance_amount_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Receive Payment",action:"receive_invoice_payment",icon:"mdi-cash",show_if:item=>item.invoice_balance_amount>0}, 
                        {name:"View Invoice",action:"view_invoice",icon:mdiInvoiceList}, 
                        {name:"View Receivable Account",action:"view_receivable_account",icon:"mdi-clipboard-text-clock"}, 
                        {name:"View Statement",action:"view_statement",icon:"mdi-text-box"}, 
                        // {name:"Archive Invoice",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            }, 
            PAYMENTS_HEADERS(){
                let tab = this.CurrentTab
                return [ 
                    {id:1,name:"General",title:true},  
                    {id:0,name:"",value:'icon_image',color:"primary lighten-1",background_color:"b",size:30,tile:true,cellClass:'',class:' b ',align:'center',show:true},
                    {id:0,name:"date",value:'created_at_server_',show:true},   
                    {id:0,name:"time",value:'created_at_time',show:false},   
                    {id:0,name:"payment No.",value:"payment_number",align:"center",show:true},          
                    {id:0,name:"Pay",value:"pay_to",show:true},    
                    {id:0,name:"Invoice",value:"paid_invoices_size",align:'center',show:true},       
                    {id:0,name:"Method",value:"payment_method_name",show:true},       

                    {id:0,name:"Customer",value:"customer_name",show:false},    
                    {id:0,name:"Amount",value:'sum_amount_',align:'right',show:true},   
                    {id:0,name:"Paid",value:'amount_',align:'right',show:true},   
                    {id:0,name:"Balance",value:'invoice_balance_amount_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"View Payment",action:"view_payment",icon:"mdi-eye"}, 
                        {name:"View Paid Invoice(s)",action:"view_invoice",icon:"mdi-receipt"}, 
                        {name:"View Receivable Account",action:"view_receivable_account",icon:"mdi-eye"}, 
                        {name:"Archive Payment",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },  
            SalesOrdersData(){
                let SaleOrder = this.SalesOrders 
                let JoinedSaleOrders = this.JoinedSaleOrders
                let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrder
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            }, 
            Invoices(){
                let orders = this.SalesOrdersData 
                if(!orders){return null}
                let filtered_items = orders.filter(order=>{
                    return !order.archive && order.account_type == 'on_account'
                })
                return filtered_items
            }   
        },
        methods: { 
            tryAction(){
                let invoices = this.PaidInvoices
                let invoice = invoices?invoices[0]:null
                console.log(invoice,'invoice.........i.');
                this.$store.dispatch("join_sale_order_payment",{
                    data:invoice
                })
                .then(res=>{
                    console.log(res,'res.........i.');
                }).catch(error=>{
                    console.log(error,'error.........i.'); 
                })
            },
            TABLE_ACTION(action,item){
                try { 
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Invoices / Payments","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-INVOICES-PAYMENTS", 
                            } 
                        })
                    }else if (action == "reload_invoices") {
                        let mbs_text = this.MBS.text.item_action("Invoices","Reload")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-INVOICES", 
                            } 
                        })
                    }else if (action == "manage_invoices") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true))
                    }else if (action == "close_dialog") {
                        this.dialog_view_receivable = false
                    }else if (action == "receive_account_payment") {  
                        this.RECEIVE_ACCOUNT_PAYMENT(this.selected_receivable_account)
                    }else if (action == "receive_payment") {  
                        this.SELECT_AND_PAYMENT()
                    }else if (action == "manage_receivables_accounts") {  
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/office/accounts/receivable",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    if (action == "pay_bill") {  
                        this.input_amount = '' 
                        this.input = {}
                        this.selected_item = item

                        this.dialog_pay_bill = true
                    }else if (action == "receive_account_payment") {  
                        this.RECEIVE_ACCOUNT_PAYMENT(item)
                    }else if (action == "view_invoice") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-invoice/"+receipt_key)
                    }else if (action == "view_statement") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-invoice-statement/"+receipt_key)
                    }else if (action == "view_receivable_account") {
                        this.GO_TO_ACCOUNT(item)
                    }else if (action == "on_row_clicked") { 
                        this.selected_receivable_account = item
                        this.dialog_view_receivable = true
                        console.log(action,item);
                    }else if (action == "view_account_invoice") { 
                        this.selected_receivable_account = item
                        this.dialog_view_receivable = true
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION2(action,item){
                try { 
                    let tab = this.CurrentTab
                    if (tab) {
                        this.PAYMENTS_ITEM_ACTION(action,item)
                    } else {
                        this.INVOICE_ITEM_ACTION(action,item)
                    }

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION2',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            INVOICE_ITEM_ACTION(action,item){
                try { 
                    console.log(action,'..........;'); 
                    if (action == "on_row_clicked"||action == "receive_invoice_payment") {  
                        this.input_amount = '' 
                        this.input = {}
                        this.selected_item = item

                        this.dialog_pay_bill = true
                    }else if (action == "view_invoice") {
                        this.SHOW_DIALOG_DOCUMENT(item)
                    }else if (action == "view_receivable_account") { 
                        if (item.receivable) {
                            this.GO_TO_ACCOUNT(item.receivable) 
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INVOICE_ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            PAYMENTS_ITEM_ACTION(action,item){
                try { 
                    console.log(action,'..........;'); 
                    if (action == "on_row_clicked") {  
                        this.SHOW_DIALOG_DOCUMENT(item,"payment")
                    }else if (action == "view_payment") {
                        this.SHOW_DIALOG_DOCUMENT(item,"payment")
                    }else if (action == "view_invoice") {
                        console.log(item,'..................p');
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAYMENTS_ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            GO_TO_ACCOUNT(account){
                try {
                    let receivable_code = account?account.code:null
                    this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/office/accounts/receivable/"+receivable_code,true))
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'GO_TO_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SELECT_AND_PAYMENT(){
                try {
                    this.input_amount = '' 
                    this.input = {}
                    this.selected_receivable_account = {} 
                    this.selected_receivable_account_key = null
                    this.dialog_select_and_payment = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RECEIVE_ACCOUNT_PAYMENT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            RECEIVE_ACCOUNT_PAYMENT(item){
                try {
                    this.input_amount = '' 
                    this.input = {}
                    this.selected_receivable_account_key = null
                    this.selected_receivable_account = item 
                    this.dialog_receivable_account_payment = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RECEIVE_ACCOUNT_PAYMENT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            // MAKE_PAYMENT(item,amount,payment_option){
            //     try {
            //         let localCashup = this.LocalCashup           
            //         let cashup_key = localCashup?localCashup.key:null
            //         let day_shift_key = localCashup?localCashup.day_shift_key:null 
            //         if (!cashup_key||!day_shift_key) {
            //             let mbs_text = this.MBS.text.open_register_before_receive_payment("")
            //             this.MBS.actions.dialog({
            //                 show:true,
            //                 fixed:true,
            //                 title:mbs_text.title,
            //                 text:mbs_text.text,
            //                 btnYes:mbs_text.btnYes,
            //                 btnNo:mbs_text.btnNo,
            //                 action:{
            //                     code:"=GO",
            //                     to:this.MBS.actions.COMPANY_LINK("/sale",true)
            //                 }
            //             }) 
            //             return
            //         }

            //         amount = this.toNumber(amount)
            //         let transaction_key = item?item.key:null
            //         let transaction_type = item?item.order_type:null
            //         let payment_details={
            //             amount:amount,
            //             transaction_key:transaction_key,
            //             transaction_type:transaction_type,
            //             transaction_type:transaction_type,
            //             received_payment:true,
            //             cashup_key:cashup_key,
            //             day_shift_key:day_shift_key,
            //         }
            //         if (payment_option) {
            //             payment_details.payment_option_key = payment_option
            //         }

            //         if (!transaction_key || !amount) {
            //             console.log(item,amount,'payload required.......');
            //         }else{
            //             let mbs_text = this.MBS.text.item_action("Payment","Receive")
            //             this.MBS.actions.dialog({
            //                 show:true,
            //                 fixed:true,
            //                 title:mbs_text.title,
            //                 text:mbs_text.text,
            //                 btnYes:mbs_text.btnYes,
            //                 btnNo:mbs_text.btnNo,
            //                 action:{
            //                     code:PAGE_NAME+"=RECEIVE-PAYMENT",
            //                     data:payment_details
            //                 }
            //             }) 
            //         } 
            //     } catch (error) {
            //         this.MBS.actions.error({
            //             error:error,
            //             from:'MAKE_PAYMENT',
            //             page:PAGE_NAME, 
            //         }) 
            //     }
                
            // },
            MAKE_INVOICE_PAYMENT(invoice,amount,payment_option){
                try {
                    let SelectedCompany = this.SelectedCompany           
                    let company_key = SelectedCompany?SelectedCompany.company_key:null           
                    let localCashup = this.LocalCashup           
                    let current_cashup_key = localCashup?localCashup.key:null
                    let current_day_shift_key = localCashup?localCashup.day_shift_key:null 
                    let payment = this.$store.getters.getPaymentMethodByKey(payment_option)
                    let money_account_code = payment?payment.money_account_code:null
                    let money_account = this.$store.getters.getMoneyAccountByCode(money_account_code)
                    let money_account_key = money_account?money_account.key:null 

                    let receivable_key = invoice?invoice.receivable_key:null 
                    let sale_order_key = invoice?invoice.key:null
                    let day_shift_key = invoice?invoice.day_shift_key:null
                    let cashup_key = invoice?invoice.cashup_key:null

                    if (!cashup_key||!day_shift_key||!current_cashup_key||!current_day_shift_key) {
                        let mbs_text = this.MBS.text.open_register_before_receive_payment("")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:"=GO",
                                to:this.MBS.actions.COMPANY_LINK("/sale",true)
                            }
                        }) 
                        return
                    }

                    amount = this.toNumber(amount) 
                    let moment = this.MBS.date.moment
                    let local_created_at = moment?moment().valueOf():null 
                    let created_uid = this.us.uid

                    let payment_details={
                        company_key:company_key,
                        day_shift_key:day_shift_key,
                        cashup_key:cashup_key,
                        current_day_shift_key:current_day_shift_key,
                        current_cashup_key:current_cashup_key,
                        receivable_key:receivable_key,
                        sale_order_key:sale_order_key,
                        payment_option_key:payment_option,
                        money_account_key:money_account_key,
                        amount:amount, 
                        local_created_at:local_created_at, 
                        created_uid:created_uid, 
                    } 
                    console.log(payment_details,'payment_details.......');
                    let mbs_text = this.MBS.text.item_action("Payment","Receive")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=RECEIVE-INVOICE-PAYMENT",
                            data:payment_details
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'MAKE_INVOICE_PAYMENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            MAKE_ACCOUNT_PAYMENT(account,amount,payment_option){
                try {
                    let SelectedCompany = this.SelectedCompany           
                    let company_key = SelectedCompany?SelectedCompany.company_key:null           
                    let localCashup = this.LocalCashup           
                    let current_cashup_key = localCashup?localCashup.key:null
                    let current_day_shift_key = localCashup?localCashup.day_shift_key:null 
                    let payment = this.$store.getters.getPaymentMethodByKey(payment_option)
                    let money_account_code = payment?payment.money_account_code:null
                    let money_account = this.$store.getters.getMoneyAccountByCode(money_account_code)
                    let money_account_key = money_account?money_account.key:null 

                    if (!current_cashup_key||!current_day_shift_key) {
                        let mbs_text = this.MBS.text.open_register_before_receive_payment("")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:"=GO",
                                to:this.MBS.actions.COMPANY_LINK("/sale",true)
                            }
                        }) 
                        return
                    }

                    amount = this.toNumber(amount)
                    let receivable_key = account?account.key:null  
                    let moment = this.MBS.date.moment
                    let local_created_at = moment?moment().valueOf():null 
                    let created_uid = this.us.uid

                    let payment_details={
                        company_key:company_key,
                        current_day_shift_key:current_day_shift_key,
                        current_cashup_key:current_cashup_key, 
                        receivable_key:receivable_key, 
                        payment_option_key:payment_option,
                        money_account_key:money_account_key,
                        amount:amount, 
                        local_created_at:local_created_at, 
                        created_uid:created_uid, 
                    } 
                    console.log(payment_details,'payment_details.......');
                    let mbs_text = this.MBS.text.item_action("Payment","Receive")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=RECEIVE-ACCOUNT-PAYMENT",
                            data:payment_details
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'MAKE_INVOICE_PAYMENT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            async RECEIPT_PRINTER(payload){
                let response = {
                    from:'RECEIPT_PRINTER',
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve,reject)=>{
                    try { 
                        let printer = this.CurrentReceiptPrinter
                        let duplicate = payload?payload.duplicate:false
                        let order = payload?payload.order:null
                        let submit_order = payload?payload.submit_order:null 
                        if (false) {
                            let com_port = printer.com_port
                            let baud_rate = printer.baud_rate
                            let url = printer.url
                            let params = {
                                action:"sales",
                                SaleOrder:order,
                                duplicate:duplicate,
                                com_port:com_port,
                                baud_rate:baud_rate,
                                url:url,
                            }  
                            if (!submit_order&&!duplicate) {
                                resolve({success:false})
                                return
                            }
                            this.MBS.actions.FISCAL_PRINT(params).then(res=>{
                                let data = res?res.data:null
                                let allReceiptCount = data?data.allReceiptCount:null
                                let fiscalReceiptCount = data?data.fiscalReceiptCount:null
                                let order_key =allReceiptCount?allReceiptCount+"/"+fiscalReceiptCount:null
                                if (order_key && order) {
                                    order.order_key = order_key
                                } 
                                resolve({
                                    success:true,
                                    fiscalReceiptCount:fiscalReceiptCount,
                                    allReceiptCount:allReceiptCount,
                                    order_key:order_key,
                                    order:order,
                                })
                            }).catch(error=>{
                                let message = error.message 
                                console.log(error,'error...ZZZ ');
                                if (message) {
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"PRINTING ERROR",
                                        text:message,
                                        btnYes:"OK", 
                                        action:{
                                            // code:PAGE_NAME+"=CLEAR-SALES"
                                        }
                                    }) 
                                } else {
                                    message = 'Kipcount Connecter is offline. Please make to start the connecter and try again.'
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"PRINTING ERROR",
                                        text:message,
                                        btnYes:"OK", 
                                        action:{
                                            // code:PAGE_NAME+"=CLEAR-SALES"
                                        }
                                    }) 
                                }
                                response = {
                                    ...response,
                                    message:message,
                                    error:error
                                }
                                reject(response)
                            }) 
                        } else {
                            if (submit_order) {
                                resolve({success:true,order:order}) 
                            } else {
                                this.autoPrint = false
                                setTimeout(() => {
                                    this.itemPrint = order
                                    this.typePrint = 'payment'
                                    this.autoPrint = true  
                                }, 5); 
                                this.com_printer = true 
                                resolve({success:true}) 
                            }
                        }  
                    } catch (error) {
                        response = {
                            ...response,
                            error:error
                        }
                        this.MBS.actions.error(response) 
                        reject(response)
                    }  
                }) 
            },
            RELOAD_INVOICES_PAYMENTS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.UNPAID_INVOICES.values,
                        on:true, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        return this.$store.dispatch('fi_get_item',{
                            name:DATA.ITEMS.PAID_INVOICES.values,
                            on:true, 
                            cash:true, 
                            get:false, 
                            company_key:company_key, 
                        })
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Invoices / Payments","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Invoices / Payments","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
            RELOAD_INVOICES(){
                try {
                    let SelectedCompany = this.SelectedCompany           
                    let company_key = SelectedCompany?SelectedCompany.company_key:null           
                    let localCashup = this.LocalCashup           
                    let current_cashup_key = localCashup?localCashup.key:null
                    let current_day_shift_key = localCashup?localCashup.day_shift_key:null 
                    this.inputLoading = true 
                    this.MBS.actions.progressDialog({
                        show:true,
                        fixed:false,
                        title:"Reloading.."
                    }) 
                    let data = {
                        company_key:company_key,
                        current_cashup_key:current_cashup_key,
                        current_day_shift_key:current_day_shift_key, 
                    }
                    this.MBS.actions.POST_REQUEST({
                        action:this.VALUE_ITEM,
                        path:"refreshUnpaidInvoices",
                        data:data,
                        us:this.us
                    }).then(res=>{
                        this.inputLoading = false
                        this.MBS.actions.progressDialog()  
                        console.log(res,'res-----------------------******');  
                        let mbs_text = this.MBS.text.item_action_success("Invoices","Reloading")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error......');
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_fail("Invoices","Reloading")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                     
                } catch (error) {
                    
                }

            },
            SHOW_DIALOG_DOCUMENT(data,doc_type){
                try {
                    if(!data){return }
                    if(!this.input_show_doc){
                        this.input_show_doc = {}
                    } 

                    this.input_show_doc.data = data
                    // this.input_show_doc.data_key = data.key
                    this.input_show_doc.document_type = doc_type?doc_type:"invoice"
                    this.dialog_doc_show = true 
                    console.log(data,'i.......');
                    console.log(this.input_show_doc,'input_show_doc......');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHOW_DIALOG_DOCUMENT',
                        page:PAGE_NAME, 
                    }) 
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        // if (action.code ===PAGE_NAME+'=RECEIVE-PAYMENT') {
                        //     if (action.YES) {
                        //         setTimeout(() => {
                        //             this.inputLoading = true 
                        //             this.MBS.actions.progressDialog({
                        //                 show:true,
                        //                 fixed:false,
                        //                 title:"Updating"
                        //             }) 
                        //             this.$store.dispatch("fi_add",{
                        //                 action:this.VALUE_ITEM,
                        //                 path:this.PATH_ITEM,
                        //                 data:action.data,
                        //                 us:this.us
                        //             })
                        //             .then(res=>{
                        //                 let payment_details = res?res.body?res.body.payment_details:null:null 
                        //                 let code = payment_details?.code
                        //                 return this.$store.dispatch("join_sale_order_payment",{
                        //                     data:{
                        //                         ...payment_details,
                        //                         code:code,
                        //                         key:code,
                        //                     }
                        //                 })
                        //             })
                        //             .then(res=>{
                        //                 this.inputLoading = false
                        //                 this.dialog_pay_bill = false 
                        //                 this.MBS.actions.progressDialog() 
                        //                 this.input = {}
                        //                 console.log(res,'res-----------------------******');
                        //                 let payment_details = res?.data 
                        //                 this.RECEIPT_PRINTER({order:{...payment_details,reload:true}}) 
                        //             }).catch(error=>{
                        //                 console.log(error,'error......');
                        //                 this.inputLoading = false
                        //                 this.MBS.actions.progressDialog() 
                        //                 let mbs_text = this.MBS.text.item_action_fail("Payment","Receiving")
                        //                 this.MBS.actions.dialog({
                        //                     show:true,
                        //                     fixed:true,
                        //                     title:mbs_text.title,
                        //                     text:mbs_text.text,
                        //                     btnYes:mbs_text.btnYes,
                        //                     btnNo:mbs_text.btnNo, 
                        //                 })
                        //             })
                        //         }, this.MBS.data.ACTION_REFRESH_TIME);   
                        //     } else {
                                
                        //     }
                        // } 
                        if (action.code ===PAGE_NAME+'=RECEIVE-INVOICE-PAYMENT') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.MBS.actions.POST_REQUEST({
                                        action:this.VALUE_ITEM,
                                        path:"paySaleOrderInvoice",
                                        data:action.data,
                                        us:this.us
                                    })
                                    .then(res=>{ 
                                        let payment_details = res?res.body?res.body.payment_details:null:null 
                                        let code = payment_details?.code
                                        return this.$store.dispatch("join_sale_order_payment",{
                                            data:{
                                                ...payment_details,
                                                code:code,
                                                key:code,
                                            }
                                        })
                                    })
                                    .then(res=>{ 
                                        this.input = {}
                                        this.inputLoading = false
                                        this.dialog_pay_bill = false 
                                        this.MBS.actions.progressDialog() 
                                        let payment_details = res?.data 
                                        this.RECEIPT_PRINTER({order:{...payment_details,reload:true}}) 
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Payment","Receiving")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RECEIVE-ACCOUNT-PAYMENT') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.MBS.actions.POST_REQUEST({
                                        action:this.VALUE_ITEM,
                                        path:"payReceivableAccountInvoices",
                                        data:action.data,
                                        us:this.us
                                    })
                                    .then(res=>{
                                        let payment_details = res?res.body?res.body.payment_details:null:null 
                                        let code = payment_details?.code
                                        return this.$store.dispatch("join_sale_order_payment",{
                                            data:{
                                                ...payment_details,
                                                code:code,
                                                key:code,
                                            }
                                        })
                                    })
                                    .then(res=>{
                                        console.log(res,"res..............***11");
                                        this.inputLoading = false
                                        this.dialog_receivable_account_payment = false 
                                        this.dialog_select_and_payment = false 
                                        this.MBS.actions.progressDialog() 
                                        this.input = {}
                                        let payment_details = res?.data 
                                        this.RECEIPT_PRINTER({order:{...payment_details,reload:true}}) 
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Payment","Receiving")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-INVOICES-PAYMENTS') {
                            if (action.YES) {
                                this.RELOAD_INVOICES_PAYMENTS()
                            } else {
                                
                            }
                        }
                        if (action.code ===PAGE_NAME+'=RELOAD-INVOICES') {
                            if (action.YES) {
                                this.RELOAD_INVOICES()
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
