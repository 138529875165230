<template> 
    <v-card 
        :height="height" 
        color="primary lighten-2"
        tile="">  
        <!-- //IMAGE -->
        <v-img :src="link" :height="height" >  
            <v-layout class="px-5 white--text" column="" fill-height="">
                    <v-layout class="not-shadow " 
                        column="" 
                        justify-center="" 
                        align-center="" 
                        fill-height="">
                        <br><br>
                        <v-container grid-list-xs>
                            <v-layout justify-center="">
                                <v-col class="pa-0" md="12">
                                    <div class="text-center">
                                        <div  class="not-f9  font-weight-bold">{{title}}</div> 
                                        <div class="not-f7  ">{{text}}</div>   
                                    </div>
                                </v-col> 
                            </v-layout>
                        </v-container> 
                    </v-layout>
                    <v-spacer></v-spacer>
                    <v-container grid-list-xs>
                        <div class="px-3">
                            <mbs-toolbar-links />

                        </div>
                        
                    </v-container>
                <v-overlay  
                    :color="color" 
                    :opacity="opacity" 
                    :absolute="true" 
                    z-index="-1">
                </v-overlay> 
                
            </v-layout>
        </v-img>  
        
    </v-card> 
</template>

<script> 
    export default {
        props:['height','color','opacity','link','title','text'],
        data(){
            return{
                show:false,  
            }
        },
        created(){ 
        },
        mounted(){   
        },
        computed:{  
        },
        methods:{ 
        },
        watch:{ 
        } 

    }
</script>
<style>
  
</style> 