<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <br>
                <h1 class="grey--text text--darken-1">Request for Quotation</h1>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <h4 class="grey--text text--darken-1">Order Details</h4>
                    <v-row>
                        <v-col cols="12" md="4">
                            <div class="text-justify">
                                Adding details to this request helps you to track the beginning of the whole procurement process. RFQ is a process in which a company select suppliers and contractors to submit price quotes and bids for the chance to fulfill certain tasks or projects.
                            </div>
                        </v-col>
                        <v-col cols="12" md="8"> 
                            <mbs-input-fields v-if="true"
                                v-model="input"
                                :field_items="DATA.FIELDS.request_quotation"
                                :select_items="SelectItems"
                                /> 
                            <v-row v-if="false">
                                <v-col cols="12" sm="6" class="py-0">
                                    <small>Supplier *</small>
                                    <v-autocomplete required
                                        v-model="input.supplier_code"
                                        :items="Suppliers"
                                        :item-text="'name'"
                                        :item-value="'code'"
                                         @update:search-input="AT_SEARCH_INPUT"
                                        :disabled="inputLoading"
                                        label="Select Supplier"
                                        filled >
                                        <template v-slot:prepend-item  v-if="!search_input">
                                            <v-btn 
                                                :to="MBS.actions.COMPANY_LINK('/office/people/customers',true)"
                                                color="primary"  
                                                class="mx-3 my-2"  
                                                depressed  width="92%" >
                                                <v-icon>person_add</v-icon>
                                                <div class="ml-2 font-weight-bol">
                                                    Add new supplier
                                                </div>
                                            </v-btn>
                                        </template>
                                        <template v-slot:no-data>
                                                <!-- @click="" -->
                                            <v-btn  
                                                color="primary" 
                                                class="mx-3 my-8 no-uppercase"  tile
                                                outlined width="92%" >
                                                <v-icon>person_add</v-icon>
                                                <div class="ml-2 font-weight-bol">
                                                    Add
                                                    <strong>'{{search_input}}'</strong> as
                                                    new supplier
                                                </div>
                                            </v-btn> 
                                        </template> 
                                    </v-autocomplete> 
                                </v-col>
                                <v-col cols="12" sm="6"  class="py-0">
                                    <small>Delivered to *</small>
                                    <v-autocomplete required
                                        v-model="input.outlet_code"
                                        :items="Outlets"
                                        :item-text="'name'"
                                        :item-value="'code'" 
                                        :disabled="inputLoading"
                                        label="Outlet" 
                                        filled />  
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <small>Supplier Invoice (Optional)</small>
                                    <v-text-field required
                                        v-model="input.invoice_number"
                                        :disabled="inputLoading"
                                        label="Enter Invoice Number" 
                                        filled />  
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <small>Delivery Date</small>
                                    <v-text-field required
                                        v-model="input.delivery_date"
                                        :disabled="inputLoading"
                                        type="date"
                                        label="Delivery Date" 
                                        filled /> 
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <small>Order Number * </small>
                                    <v-text-field required
                                        label="Code" 
                                        v-model="input.code"
                                        filled />  
                                </v-col>  
                                <v-col cols="12" sm="6" class="py-0">
                                    <small>Payment Method * </small>
                                    <v-autocomplete required
                                        :items="PaymentMethods"
                                        :disabled="inputLoading"
                                        label="Select Method" 
                                        v-model="input.payment_method"
                                        filled />  
                                </v-col> 
                                <v-col cols="12" sm="12" class="py-0">
                                    <small>Description (Optional) </small>
                                    <v-textarea
                                        v-model="input.description"
                                        :disabled="inputLoading"
                                        label="Enter a note for this delivery" 
                                        filled />  
                                </v-col> 

                            </v-row> 
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br>
                    <h4 class="grey--text text--darken-1">Items</h4>
                    <v-row>
                        <v-col cols="12" md="3">
                            <div class="text-justify">
                                Select items you want to request the quotation for by searching or importing the excel document.
                            </div>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-card :height="vsh-170" outlined rounded="sm">
                                <v-layout fill-height column class="ma-0">
                                    <div>
                                        <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                            <v-autocomplete 
                                                v-model="search_item"
                                                :disabled="inputLoading"
                                                :items="DataItems" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                label="Search or Scan to Add Item"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                :filter="(item, queryText, itemText) => { 
                                                    let first = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    if(!item){return first}
                                                    if(!item.group){return first}
                                                    let second = item.group.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    return first || second 
                                                }"
                                                >
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>local_offer</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete> 
                                            <v-btn :disabled="inputLoading"  class="ml-5" color="secondary">Import <v-icon class="ml-2">mdi-microsoft-excel </v-icon></v-btn> 
                                        </v-toolbar> 
                                    </div>
                                    <v-list dense class="font-weight-bold not-f3 ">
                                        <v-list-item dense >
                                            <v-list-item-action> <v-card flat width="45">
                                                
                                            </v-card> </v-list-item-action>
                                            <v-list-item-content>Item Name</v-list-item-content>
                                            <v-list-item-action class="pr-2">
                                                <div>Current <br> Inventory</div>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="90">
                                                    <v-layout class="ma-0" justify-center>
                                                        QTY
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                             
                                            <v-list-item-action>
                                                <v-card flat width="25">
                                                    <v-layout class="ma-0" justify-center>
                                                        
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                        </v-list-item>
                                    </v-list>
                                    <v-divider></v-divider>
                                    <v-layout v-if="!selected_items" fill-height align-center justify-center >
                                        No Item
                                    </v-layout>
                                    <v-virtual-scroll v-else 
                                        class=""
                                        :bench="2"
                                        :items="selected_items"
                                        :height="vsh-300"
                                        item-height="70"  > 
                                        <template v-slot="{ item,index }">
                                                <v-list-item  
                                                    :key="index" :to="item.link">
                                                    <v-list-item-action> 
                                                        <v-avatar
                                                            size="45" tile rounded="lg"
                                                            color="grey"  >
                                                            <v-img v-if="item.image"></v-img>
                                                            <v-icon v-else>local_offer</v-icon>
                                                        </v-avatar>
                                                    </v-list-item-action>
                                                    <v-list-item-content>{{item.name}}</v-list-item-content>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="70">
                                                            <v-text-field
                                                                :disabled="inputLoading"
                                                                v-mask="'####'" outlined
                                                                filled dense  
                                                                v-model="item.quantity"/>
                                                        </v-card>
                                                    </v-list-item-action>  
                                                    <v-list-item-action >
                                                        <v-btn @click="REMOVE_ITEM(item)" :disabled="inputLoading"
                                                    small icon><v-icon>delete</v-icon></v-btn></v-list-item-action> 
                                                </v-list-item> 
                                                <v-divider ></v-divider> 
                                        </template>  
                                    </v-virtual-scroll> 

                                    <v-spacer></v-spacer>
                                    <v-divider></v-divider>
                                    
                                    <input ref="btn_submit_order" type="submit" v-show="false">
                                    <v-card @click="SUBMIT()"  
                                        :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                                        :flat="MBS.actions.SIZE(selected_items)<1" 
                                        hover  
                                        class="mx-5 my-4 v-btn" :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <div class="font-weight-bold not-f7">Submit</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL QTY {{CALCULATE_TOTAL_QT(selected_items)}}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>   
                            
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "REQUEST-QUOTATION"  
    export default {
        name:PAGE_NAME, 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            }), 
            DataItems(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                // let CalculatedItems = this.CalculatedItems
                // if (CalculatedItems) {
                //     return CalculatedItems
                // }else 
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////  
            PaymentMethods(){
                return this.$store.getters.getDefaultPaymentMethods
            }, 
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },    
            CALCULATE_TOTAL_QT(){
                return items=>{
                    let total_qt = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let quantity = item.quantity?item.quantity*1:1
                            total_qt = total_qt + quantity
                        });  
                        return total_qt
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_QT',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){
                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let SupplierCategories = this.SupplierCategories
                return {
                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    SupplierCategories:SupplierCategories,
                }
            }
        },
        methods: { 
            ADD_NEW_ITEM(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.ITEMS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_NEW_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.DataItems
                    if (items) {
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = (item.quantity*1)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1, 
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input
                    let code = input?input.code:null
                    let supplier_code = input?input.supplier_code:null 
                    let description = input?input.description:null
                    let selected_items = this.selected_items
                    let items_size = this.MBS.actions.SIZE(selected_items)

                    if (items_size<1) {
                        console.log("error..");
                    } 
                    let stock_order_items = []
                    selected_items.forEach(element => {
                        stock_order_items.push({
                            item_key:element.key, 
                            quantity:this.toNumber(element.quantity), 
                        })
                    });
                    let order_details = {
                        code:code,
                        supplier_code:supplier_code,
                        order_type:DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value,
                        items:stock_order_items
                    }
                    if (description) {
                        order_details.description = description
                    }  

                    console.log(input,'input....');
                    console.log(order_details,'order_details....');
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.name,"Submit")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_ORDER(order){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.quotations_and_invoices,true) 
                    if (!order || !path) {
                        console.log(order,'order error');
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_add",{
                        action:DATA.ITEMS.QUOTATIONS.values,
                        data:order,
                        path:path
                    }).then(res=>{ 
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.QUOTATIONS.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        }) 
                        // let quotationLink = this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true)
                        this.MBS.actions.go(-1) 
                         
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.QUOTATIONS.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(v){  
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
