<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <!-- <mbs-page-tab 
                            :items="MY_LINKS"/>  -->
                        <mbs-item-manager
                            :table_title="'List of Categories'"
                            :table_title_icon="'mdi-format-list-group'"
                            :add_vertical_lines="true"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="DATA.FIELDS.expense_categories"
                            :header_items="HEADER_ITEMS" 
                            :data_items="AllExpenseCategories"   
                            :path_item="PATH_ITEM"  

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.EXPENSE_CATEGORIES.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.EXPENSE_CATEGORIES.names,
                NAME_ITEM:DATA.ITEMS.EXPENSE_CATEGORIES.name,
                VALUE_ITEMS:DATA.ITEMS.EXPENSE_CATEGORIES.values,
                VALUE_ITEM:DATA.ITEMS.EXPENSE_CATEGORIES.value,  
                DATA:DATA,
 
                table_actions:[
                    {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Expense Category"}, 
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-cash-refund",action:"manage_expenses",color:"secondary",  text:"Manage Expense"}, 
                    {type:"action",icon:"mdi-finance",action:"expense_reports",color:"secondary",  text:"Expense Reports"}, 
                    {type:"action",icon:"mdi-delete",action:"manage_archives",color:"secondary",  text:"Archived Expense"}, 
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ] 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },  
            ///////// 
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("accounts",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.expense_categories,true) 
                return path
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:true},     
                    {id:0,name:"Name",value:"name",show:true},         
                    {id:0,name:"description",value:"description",show:true},         
                    {id:0,name:"Amount (MWK)",value:"total_amount_",align:"right",show:true},       
                    {id:0,name:"created Date",value:'created_at_',show:false},     
                    {id:0,name:"updated Date",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Transactions",action:"view_transaction",icon:"mdi-eye"}, 
                        {name:"Edit Expense Account",action:"edit_item",icon:"edit",show_if:item=>!item.default}, 
                        {name:"Archive Expense Account",action:"archive_item",icon:"mdi-delete",show_if:item=>!item.default}, 
                    ]}, 
                ]
            },  

            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
            }),
            ...mapState({
                Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()], 
                ExpenseCategories: state=> state.items[(DATA.ITEMS.EXPENSE_CATEGORIES.values).toUpperCase()],
                JoinedExpenseCategories: state=> state.join[(DATA.ITEMS.EXPENSE_CATEGORIES.values).toUpperCase()], 
                ExpenseAccounts: state=> state.items[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()],
                JoinedExpenseAccounts: state=> state.join[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()], 
                
                DefaultExpenseCategories: state=> state.app['DEFAULT_EXPENSE_CATEGORIES'],
                DefaultExpenseAccounts: state=> state.app['DEFAULT_EXPENSE_ACCOUNTS'],    
            }),  
            ExpensesData(){
                let items = this.JoinedExpenses?this.JoinedExpenses:this.Expenses 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("expense_date",false))    
                return list 
            },
            ExpenseCategoriesData(){
                let items = this.JoinedExpenseCategories?this.JoinedExpenseCategories:this.ExpenseCategories
                return items
            },
            ExpenseAccountsData(){
                let items = this.JoinedExpenseAccounts?this.JoinedExpenseAccounts:this.ExpenseAccounts
                return items
            },
            AllExpenseCategories(){
                let defaults = this.DefaultExpenseCategories
                let categories = this.ExpenseCategoriesData
                let all = []
                if(defaults){
                    defaults.forEach(category => {
                        all.push({
                            ...category,
                            default:true
                        }) 
                    });
                }
                if(categories){
                    all=[
                        ...all,
                        ...categories
                    ]
                } 
                return all
            },
             
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "manage_archives") {
                        let link = this.MBS.actions.COMPANY_LINK("/office/archive/expenses-accounts",true) 
                        this.MBS.actions.go(link)
                    }if (action == "manage_expenses") {
                        let link = this.MBS.actions.COMPANY_LINK("/office/reports/expenses",true) 
                        this.MBS.actions.go(link)
                    }if (action == "expense_reports") {
                        let link = this.MBS.actions.COMPANY_LINK("/office/expenses",true) 
                        this.MBS.actions.go(link)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    // let link = "/stock/stock-control/"+(item?item.key:null)
                    // const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_transaction") {
                        let mbs_text = this.MBS.text.option_not_available("View transactions")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
