<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 41.15 44.16"
  :fill="iconColor" xml:space="preserve"> 
    <rect x="32.82" y="12.81" width="0.23" height="1.03"/><rect x="28.57" y="11.59" width="1.03" height="0.23" transform="translate(0.24 23.99) rotate(-44.99)"/><rect x="36.68" y="11.21" width="0.23" height="1.03" transform="translate(2.49 29.46) rotate(-45)"/><rect x="34.91" y="12.37" width="0.23" height="1.03" transform="translate(-2.27 14.36) rotate(-22.46)"/><rect x="30.34" y="12.8" width="1.03" height="0.23" transform="translate(7.15 36.52) rotate(-67.58)"/><rect x="27.37" y="9.83" width="1.03" height="0.23" transform="translate(-1.68 11.44) rotate(-22.52)"/><rect x="37.88" y="9.42" width="0.23" height="1.03" transform="translate(14.28 41.23) rotate(-67.49)"/><rect x="37.9" y="7.73" width="1.03" height="0.23"/><rect x="26.95" y="7.73" width="1.03" height="0.23"/><rect x="36.31" y="3.84" width="1.03" height="0.23" transform="translate(7.95 27.16) rotate(-44.92)"/><rect x="28.95" y="3.46" width="0.23" height="1.03" transform="translate(5.69 21.71) rotate(-44.98)"/><rect x="30.73" y="2.28" width="0.23" height="1.03" transform="translate(1.27 12) rotate(-22.46)"/><rect x="37.5" y="5.64" width="1.03" height="0.23" transform="translate(0.68 14.94) rotate(-22.43)"/><rect x="34.52" y="2.68" width="1.03" height="0.23" transform="translate(19.06 34.11) rotate(-67.53)"/><rect x="27.78" y="5.24" width="0.23" height="1.03" transform="translate(11.9 29.32) rotate(-67.49)"/><path d="M33.45,8.09,33.37,8,33,4.12a.06.06,0,0,0-.07,0s0,0,0,0L32.52,8l-.08.05a.72.72,0,0,0,0,1l-.07.74a.16.16,0,0,0,.14.17h.92a.15.15,0,0,0,.15-.16h0l-.07-.73A.72.72,0,0,0,33.45,8.09Z"/><path d="M34.23,39.05V15.59a7.85,7.85,0,0,0,6.56-7.73h0a7.86,7.86,0,1,0-9.14,7.74V39.05H0v3.81H2.68v.63H8.51v-.63H32.92v1.3h5.84v-1.3h2.39V39.05Zm-7.8-31.2a6.52,6.52,0,0,1,6.51-6.51h.16v.87h.24V1.36a6.51,6.51,0,0,1,6.11,6.49h0a6.51,6.51,0,0,1-13,0Z"/><path d="M18.14,17.13H2.58V36.77h22.1V17.13Zm-11,13.41v5H5.6v-5H4.38l2-2.43,2,2.43Zm5,0v5H10.58v-5H9.36l2-2.43,2,2.43ZM10.62,24V17.69h6V24Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
