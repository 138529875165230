import { render, staticRenderFns } from "./DentalDepartment.vue?vue&type=template&id=25eb9166&"
import script from "./DentalDepartment.vue?vue&type=script&lang=js&"
export * from "./DentalDepartment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports