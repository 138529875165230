import Vue from 'vue'

//  SWIPER
//import VueAwesomeSwiper from 'vue-awesome-swiper' 
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'

// Vue Carousel
import VueCarousel from 'vue-carousel';

 
import 'swiper/swiper-bundle.css'
import 'animate.css'
import VueResource from 'vue-resource';
import FlagIcon from 'vue-flag-icon' 

//location
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);


//vue-number-animation
import VueNumber from 'vue-number-animation' 
Vue.use(VueNumber)

//vue visibility
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

//XLSX
import XLSX from "xlsx"

//
const Crypto = require('./crypto');

//
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
Vue.component('mbs-qr',vueQr)
//
import VueBarcode from '@chenfengyuan/vue-barcode';
Vue.component(VueBarcode.name, VueBarcode);
Vue.component('mbs-barcode', VueBarcode);

//print
import VueSimplePrintPlugin from 'vue-simple-print'; 
Vue.use(VueSimplePrintPlugin);

//mask
import VueMask from 'v-mask'
Vue.use(VueMask);

//outside click
// import vClickOutside from 'v-click-outside' 
// Vue.use(vClickOutside)

//to image
import * as htmlToImage from 'html-to-image';




Vue.use(FlagIcon) 
Vue.use(VueResource); 
//  TOOLBARS 
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(VueGlide)
Vue.use(VueCarousel);

//awesome-phonenumber
var PhoneNumber = require( 'awesome-phonenumber' );

//receipt line
const receiptline = require('receiptline');
import printJS from 'print-js'

// import axios from 'axios'; 
const axios = require("axios").default;
var qs = require('qs');

//number Mask
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: true,
});
const numberMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: true,
});

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);
import excel from 'vue-excel-export' 
Vue.use(excel)

//select input
import VueSelectInputUi from 'vue-select-input-ui';
import 'vue-select-input-ui/dist/vue-select-input-ui.css';

//
// import { HotTable } from '@handsontable/vue';
// import { 
//   HotTable, 
//   HotColumn, 
//   HotRow,
//   BaseEditorComponent 
// } from '@handsontable/vue';
// import { registerAllModules } from 'handsontable/registry';
// import 'handsontable/dist/handsontable.full.css';
// // register Handsontable's modules
// registerAllModules();

// Vue.component("hot-table",HotTable)
// Vue.component("hot-column",HotColumn)
// Vue.component("hot-row",HotRow)
// Vue.component("custom-editor",BaseEditorComponent)


//
import VueExcelEditor from 'vue-excel-editor'
Vue.use(VueExcelEditor)

import CityTable from "@banyinbanying/city-table"
Vue.use(CityTable);

import jsPDF from 'jspdf';

 
Vue.component('VueSelectInputUi', VueSelectInputUi);

Vue.mixin({
  data() {
    return {
      receiptline:receiptline,
      printJS:printJS,
      XLSX:XLSX,
      axios:axios, 
      qs:qs,
      currencyMask:currencyMask,
      numberMask:numberMask,
      MBS: {
        phone:PhoneNumber,
        crypt:Crypto,
        toImage:htmlToImage, 
        toPDF:jsPDF, 
      }, 
    }
  },
})