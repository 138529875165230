<template>
<div>
    <v-layout column>
        <v-divider></v-divider> 
        <mbs-page-tab 
            :items="MY_LINKS"/> 
        <v-divider></v-divider> 
        <v-divider></v-divider> 
        <v-divider></v-divider> 
        <v-card v-if="false" :height="vsh-250" color="b1">
            <mbs-page-spinner/> 
        </v-card>
        <v-layout v-else column>  
             
            <v-container grid-list-lg v-if="us">
                <v-layout row wrap class="mt-3"> 
                    <!-- SELLING SETTINGS AND TAXES -->
                    <v-flex  sm12 md6 >
                        <v-layout column fill-height class="ma-0"> 
                            <!-- Contact -->
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >{{MBS.actions.TEXT_UP11('Selling Settings')}}</v-toolbar-title> 
                                        </v-toolbar>
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in CompanySellingSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            <span v-if="setting.type=='select'">
                                                                <!-- v-model="input[setting.value]"  -->
                                                                <v-select  
                                                                    :outlined="false"
                                                                    :filled="true" 
                                                                    :dense="true"
                                                                    :multiple="setting.multiple"
                                                                    :append-icon="setting.append_icon"
                                                                    :prepend-icon="setting.prepend_icon"
                                                                    :prepend-inner-icon="setting.prepend_inner_icon"
                                                                    :required ="setting.required?true:false"
                                                                    :autofocus="setting.autofocus?true:false"
                                                                    v-model="CompanySettings[setting.key]"  
                                                                    @change="AT_INPUT_CHANGE($event,setting)"
                                                                    @update:search-input="AT_SEARCH_INPUT"
                                                                    :items="DataSource?DataSource[setting.select?setting.select.name:'']:null"
                                                                    :item-value="setting.select?setting.select.value:''"
                                                                    :item-text="setting.select?setting.select.text:''"
                                                                    :label="setting.label"
                                                                    :filter="setting.search?setting.search.filter:(setting, queryText, itemText) => { 
                                                                        let index = (a,b)=>{
                                                                            if(!a||!b){return false}
                                                                            a = ''+a; b = ''+b
                                                                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                                        } 
                                                                        let first = index(itemText,queryText) 
                                                                        return first  
                                                                    }"
                                                                    :disabled="false" > 
                                                                </v-select> 
                                                            </span> 
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <span v-if="setting.type=='select'">
                                                            <!-- <v-btn small outlined color="primary">Select <v-icon>mdi-menu-down</v-icon></v-btn> -->
                                                            <v-menu   v-if="false"
                                                                v-model="setting.menu_select"
                                                                :close-on-content-click="false" 
                                                                offset-y  offset-overflow
                                                                :nudge-width="150" rounded="xl"
                                                                >
                                                                <template v-slot:activator="{ on, attrs }">  
                                                                    <v-btn small outlined  v-on="on" class=" m-1"
                                                                        ref="btn_select" 
                                                                        color="primary">
                                                                        {{'Select'}}
                                                                        <v-icon class="ml-2">arrow_drop_down</v-icon>
                                                                    </v-btn> 
                                                                </template> 
                                        
                                                                <v-card  v-if="ud"  
                                                                    color="lighten-1" class="">  
                                                                    <v-card-title class="primary white--text py-" dark>
                                                                        Select<v-spacer></v-spacer> <v-btn @click="setting.menu_select=false" dark icon><v-icon>close</v-icon></v-btn>
                                                                    </v-card-title> 
                                                                    <v-list dense> 
                                                                        <div v-for="(item,index) in DataSource?DataSource[setting.select?setting.select.name:'']:null" :key="index">
                                                                            <v-list-item 
                                                                                :class="item == ''?'secondary--text':''" 
                                                                                @click="input[item.key]=!input[item.key]" 
                                                                                > 
                                                                                <v-list-item-action>
                                                                                    <v-icon v-if="input[item.key]">mdi-checkbox-marked</v-icon>
                                                                                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                                                                                </v-list-item-action>
                                                                                <v-list-item-content> 
                                                                                    {{item.name}}++
                                                                                </v-list-item-content>
                                                                            </v-list-item> 
                                                                        </div>
                                                                    </v-list> 
                                                                    <v-divider></v-divider>
                                                                    <v-layout class='ma-0 pb-3 pt-2 px-3'>
                                                                        <v-flex>
                                                                            <v-btn   width="100%" color="primary">Save</v-btn>
                                                                        </v-flex>
                                                                        <!-- <v-btn tile class="ml-" color="secondary">Cancel</v-btn> -->
                                                                    </v-layout>
                                                                </v-card>  
                                                            </v-menu>   
                                                        </span> 
                                                        <v-switch v-else
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 

                            <!-- Unique IDs -->
                            <v-spacer></v-spacer>
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="mt-3" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >
                                                {{MBS.actions.TEXT_UP11('UNIQUE ID / CODE SETTINGS')}}
                                            </v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in UniqueIdSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                            
                            <!-- Taxes -->
                            <v-spacer></v-spacer>
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="mt-3" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >
                                                {{MBS.actions.TEXT_UP11('TAX SETTINGS')}}
                                            </v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in TaxSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                        </v-layout> 
                    </v-flex> 
                    
                    <!-- CASHUPS AND SALES RECEIPT --> 
                    <v-flex  sm12 md6 >
                        <v-layout column fill-height class="ma-0">
                            <!-- Cashup / Z-READ Settings-->
                            <v-hover>
                                <template v-slot="{ hover }">
                                    <v-card 
                                        tile height="100%"
                                        :elevation="hover?12:''"  >
                                        <v-toolbar color="primary lighten-5" flat="" >  
                                            <v-toolbar-title>Till / Cashup / Z-READ Settings</v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in CashupSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                        <br>
                                    </v-card> 
                                </template>
                            </v-hover>
                            <v-spacer></v-spacer>

                            <!-- Sales Receipt -->
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="mt-3" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >
                                                {{MBS.actions.TEXT_UP11('Sales Receipt & Invoice Printing  Settings')}}
                                            </v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in ReceiptSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                            
                            <!-- Other Settings -->
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="mt-3" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >
                                                {{MBS.actions.TEXT_UP11('Other  Settings')}}
                                            </v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in OtherSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                        </v-layout> 
                    </v-flex> 
                    
                     
                  
                </v-layout> 
            </v-container> 
        </v-layout> 
    </v-layout>  
    <!-- DIALOG -->
    <v-layout column justify-center v-if="us">
        <!-- image dialog -->
         
    </v-layout> 
</div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'  
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "GENERAL_SETTINGS" 
    export default {
        name:PAGE_NAME, 
        data() {
            return { 
                page_current:1,
                page_size:10,
                DATA:DATA, 

                tab: null, 
                input:[], 
                inputLoading:false, 
            }
        },
        created() {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
            this.MBS.actions.setPAGE_LOAD(false)
            this.INITIALIZE_INPUT()
        },
        mounted() { 
            this.MBS.actions.header({
              show:true,
              type:'subToolbar',
              images:this.items,
              height:400 
            })
           this.FEEDBACKS(1000)
        },
        computed: {
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("company",2,true) 
                return thisLinks
            }, 
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
                CompanySellingSettings: state=> state.app["COMPANY_SELLING_SETTINGS"],  
                CashupSettings: state=> state.app["CASHUP_SETTINGS"],  
                ReceiptSettings: state=> state.app["RECEIPT_SETTINGS"],  
                TaxSettings: state=> state.app["TAX_SETTINGS"],  
                OtherSettings: state=> state.app["OTHER_SETTINGS"],  
                UniqueIdSettings: state=> state.app["UNIQUE_ID_SETTINGS"],  
                PricingTiers: state=> state.items[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                JoinedPricingTiers: state=> state.join[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                DefaultPricingTiers: state=> state.app['DEFAULT_PRICING_TIERS'], 
            }), 
            PricingTiersData(){
                return this.JoinedPricingTiers?this.JoinedPricingTiers:this.PricingTiers
            }, 
            AllPricingTiers(){
                let defaults = this.DefaultPricingTiers
                let tiers = this.PricingTiersData 
                let all = []
                if (defaults) {
                    all.push(...defaults)
                }if (tiers) {
                    tiers.forEach(tier => {
                        let index = all.findIndex(item=>item.key == tier.key)
                        if (index==-1) {
                            all.push(tier) 
                        } else {
                            all[index] = {
                                ...all[index],
                                ...tier
                            }
                        }
                    });
                }
                return all
            }, 
            DataSource(){
                let PricingTiers = this.AllPricingTiers
                return {
                    PricingTiers:PricingTiers
                }
            }
            
        },
        methods: {  
            CHECK_SELLING_SETTINGS(settings){
                let input = this.input
                let data = {
                    selling_list_descend:false,
                    duplicate_selling_item:false,
                    allow_selling_discount:false,
                    edit_selling_price:false,
                    both_selling_mode:false,
                    only_retail_model:false,
                    only_wholesale_model:false,
                    edit_opening_cash:false,
                    edit_closing_cash:false,
                    show_shortages:false,
                    fixed_closing_cash:false,
                } 
                let index_both_selling_mode = this.input.indexOf("both_selling_mode")
                let index_only_retail_model = this.input.indexOf("only_retail_model")
                let index_only_wholesale_model = this.input.indexOf("only_wholesale_model")

                if (settings.key === 'both_selling_mode') {
                    if (index_only_retail_model > -1) {  
                        this.input.splice(index_only_retail_model, 1);  
                    }
                    if (index_only_wholesale_model > -1) {  
                        this.input.splice(index_only_wholesale_model, 1);  
                    }
                }
                if (settings.key === 'only_retail_model') {
                    if (index_both_selling_mode > -1) {  
                        this.input.splice(index_both_selling_mode, 1);  
                    }
                    if (index_only_wholesale_model > -1) {  
                        this.input.splice(index_only_wholesale_model, 1);  
                    }
                }
                if (settings.key === 'only_wholesale_model') {
                    if (index_both_selling_mode > -1) {  
                        this.input.splice(index_both_selling_mode, 1);  
                    }
                    if (index_only_retail_model > -1) {  
                        this.input.splice(index_only_retail_model, 1);  
                    }
                }
                if (input) {
                    input.forEach(element => {
                        data[element] = true
                    });
                } 
                console.log(settings,'1111111');
                console.log(input,'input......');

                let mbs_text = this.MBS.text.update_item("Company Settings")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-COMPANY-SETTINGS",
                        data:data 
                    }

                })
            },  
            UPDATE_SETTING(settings,input,isInput){
                if(!settings){return false} 
                let CompanySettings = this.CompanySettings 
                console.log(CompanySettings,'CompanySettings.....');
                let data = {}
                if (isInput) { 
                    data[settings.key] = input
                } else {
                    data[settings.key] = CompanySettings?!CompanySettings[settings.key]:true  
                }

                let mbs_text = this.MBS.text.update_item("Company Settings")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-COMPANY-SETTINGS",
                        data:data 
                    }

                })
            },  
            INITIALIZE_INPUT(){
                let company_settings = this.CompanySettings
                if (company_settings) {
                    let active = []
                    for (const key in company_settings) {
                        if (company_settings[key]) {
                            active.push(key)
                        }
                    }
                    this.input = active
                } 
            },
            AT_INPUT_CHANGE(input,setting){
                console.log(input,'AT_INPUT_CHANGE...........');
                console.log(setting,'setting...........');
                this.UPDATE_SETTING(setting,input,true)
            },
            AT_SEARCH_INPUT(){
                console.log('AT_SEARCH_INPUT...........');
            },
 
            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) { 
                        if (action.code ===PAGE_NAME+"=UPDATE-COMPANY-SETTINGS") {
                            if (action.YES) { 
                                setTimeout(() => {  
                                    let f_detail = {
                                        action:DATA.ITEMS.COMPANY_SETTINGS.value,
                                        path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_settings,true),
                                        data:action.data,
                                        us:this.us
                                    }  
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        text:"Updating..."
                                    })
                                    this.input.loading = true 
                                    this.$store.dispatch("fi_update",f_detail)
                                    .then(res=>{  
                                        // let mbs_text = this.MBS.text.update_item_success(DATA.ITEMS.COMPANY_SETTINGS.value)
                                        // this.MBS.actions.dialog({
                                        //     show:true,
                                        //     fixed:true,
                                        //     title:mbs_text.title,
                                        //     text:mbs_text.text,
                                        //     btnYes:mbs_text.btnYes,  
                                        // }) 
                                        this.input.loading = false
                                        this.dialog_add_item = false 
                                        this.generatedCode = null 
                                        this.MBS.actions.progressDialog() 
                                        this.MBS.actions.restartResponses() 
                                    })
                                    .catch(error=>{ 
                                        console.log(error,'e...');
                                        let mbs_text = this.MBS.text.update_item_fail(DATA.ITEMS.COMPANY_SETTINGS.value)
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes, 
                                        })  
                                        this.input.loading = false
                                        this.MBS.actions.progressDialog() 
                                        this.MBS.actions.restartResponses()

                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            }else{
                                this.INITIALIZE_INPUT()
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            },  
            FEEDBACKS(t){
                try {
                    if (!t) {
                        t=400
                    }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses  
                        let i = this.iLocation  
                        
                    },t)
                } catch (error) {
                   this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: {
            us(v){
                this.FEEDBACKS(1)
            },
            ud(v){
                this.FEEDBACKS(1)
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            CompanySettings(){ 
                this.INITIALIZE_INPUT()
            }
        },
  }
</script>
 