<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 25.02 24.68"
  :fill="iconColor" xml:space="preserve">

    <path d="M25,20.28l-11.73,4.4V24.4c0-4.51,0-9,0-13.53a.32.32,0,0,1,.25-.36c1.13-.41,2.25-.84,3.38-1.26l.27-.09v3.89l.25-.08,2.67-1a.29.29,0,0,0,.22-.34c0-1.15,0-2.29,0-3.44a.28.28,0,0,1,.22-.31L25,6.21Z"/><path d="M11.73,24.68l-2-.75c-3.15-1.18-6.3-2.37-9.45-3.54A.35.35,0,0,1,0,20q0-6.75,0-13.5V6.22l1.62.6,9.84,3.69a.33.33,0,0,1,.27.37q0,6.75,0,13.51ZM3.13,17.5c0,.53,0,1,0,1.53,0,.07.09.16.16.19l1.28.48,1.68.63c0-.53,0-1,0-1.53,0-.07-.09-.16-.16-.19-.42-.17-.85-.32-1.28-.48Z"/><path d="M12.52,5.66l4.64,1.91-.19.1L12.68,9.28a.62.62,0,0,1-.33,0L.23,4.74.05,4.65l.21-.1L12.34,0a.5.5,0,0,1,.34,0L24.76,4.55l.23.11L24.07,5,20.5,6.34a.5.5,0,0,1-.36,0c-1.42-.62-2.83-1.26-4.25-1.9a.57.57,0,0,0-.48,0c-.88.39-1.76.76-2.64,1.13Z"/><path class="cls-1" d="M3.13,17.5l1.68.63c.43.16.86.31,1.28.48.07,0,.16.12.16.19,0,.5,0,1,0,1.53L4.58,19.7,3.3,19.22c-.07,0-.16-.12-.16-.19C3.13,18.53,3.13,18,3.13,17.5Z"/>

  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
