<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <form id="form_order_details" @submit.prevent="CREATE_EXPIRIES()" >
                    <div v-if="false">
                        <br>
                        <h1 class="grey--text text--darken-1">Add Expiring Items</h1>
                        <br>
                        <v-row>
                            <v-col cols="12" md="4">
                                <div class="text-justify">
                                    <h4 class="grey--text text--darken-1">Order Details</h4>
                                    Add details for this delivery for ease identification of all your deliveries. Stock receiving is the function of checking items delivered to the business after an order. This includes inspecting the quality, condition, and quantity of any incoming goods, and allocating them to a space in the warehouse.
                                </div>
                            </v-col>
                            <v-col cols="12" md="8"  > 
                                <mbs-page-spinner v-if="!show_input"
                                    height="500"/>  
                                <mbs-input-fields v-else
                                    v-model="input"
                                    :field_items="DATA.FIELDS.receive_stock"
                                    :select_items="SelectItems"
                                    :disabled_fields="DisabledFields"
                                    />  
                            </v-col> 
                        </v-row> 
                        <br><br><br>
                        <hr>
                        <br><br><br>
                        <h4 class="grey--text text--darken-1">Items</h4>
                    </div>
                    <v-row>
                        <v-col cols="12" md="3" v-if="false">
                            <div class="text-justify">
                                Select items you want to receive by searching or importing the excel document.
                            </div>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-card :height="vsh-170" outlined rounded="sm">
                                <v-layout fill-height column class="ma-0">
                                    <div>
                                        <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                            <v-autocomplete 
                                                v-model="search_item"
                                                :disabled="inputLoading"
                                                :items="DataItems" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                label="Search or Scan to Add Item"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                @update:search-input="AT_SEARCH_INPUT"
                                                :filter="(item, queryText, itemText) => { 
                                                        let index = (a,b)=>{
                                                            if(!a||!b){return false}
                                                            a = ''+a; b = ''+b
                                                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                        } 
                                                        let first = index(itemText,queryText)
                                                        let second = item?index(item.code,queryText):false 
                                                        if(!item){return first} 
                                                        return first || second 
                                                    }"
                                                >
                                                <template v-slot:prepend-item  v-if="!search_input">
                                                    <v-row class="ma-0 pl-3 pr-2">
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'6'" >
                                                            <v-btn  
                                                                color="primary" 
                                                                @click="ADD_NEW_ITEM()"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon  >add</v-icon>
                                                                <div class="ml-2 font-weight-bol">
                                                                    Add New Item
                                                                </div>
                                                            </v-btn>  
                                                        </v-col> 
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'6'" >
                                                            <v-btn  
                                                                color="primary" 
                                                                :to="MBS.actions.COMPANY_LINK('/stock/items',true)"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon  >add</v-icon>
                                                                <div class="ml-2 font-weight-bol">
                                                                    Manage Items
                                                                </div>
                                                            </v-btn>  
                                                        </v-col> 
                                                    </v-row>
                                                </template>
                                                <template v-slot:no-data   v-if="search_input">
                                                    <v-row class="ma-0 pl-3 pr-2">
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'12'"  >
                                                            <v-btn  
                                                                color="primary" 
                                                                @click="ADD_NEW_ITEM(search_input)"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon >add</v-icon> 
                                                                <div class="ml-2 font-weight-bol ">
                                                                    Add "{{search_input}}" as New Item
                                                                </div>
                                                            </v-btn> 
                                                        </v-col> 
                                                    </v-row>
                                                </template> 
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>local_offer</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete> 
                                            <v-btn :disabled="inputLoading"  class="ml-5" color="secondary">
                                            <!-- Import <v-icon class="ml-2">mdi-microsoft-excel </v-icon> -->
                                            </v-btn> 
                                        </v-toolbar> 
                                    </div>
                                    <v-list dense class="font-weight-bold not-f3 ">
                                        <v-list-item dense >
                                            <v-list-item-action> <v-card flat width="45">
                                                
                                            </v-card> </v-list-item-action>
                                            <v-list-item-content>Item Name</v-list-item-content>
                                             
                                            <v-list-item-action>
                                                <v-card flat width="200">
                                                    <v-layout class="ma-0 pl-2" justify-center>
                                                    <div class="text-center">Exp. Date</div> 
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>  
                                             
                                            <v-list-item-action>
                                                <v-card flat width="25">
                                                    <v-layout class="ma-0" justify-center>
                                                        
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                        </v-list-item>
                                    </v-list>
                                    <v-divider></v-divider>
                                    <v-layout v-if="!selected_items" fill-height align-center justify-center >
                                        No Item
                                    </v-layout>

                                    <v-card v-else :height="vsh-310"   class=" mbs-scroll-y" flat target="">
                                        <v-list two-line >
                                            <template v-for="(item, index) in selected_items">
                                                <v-list-group   
                                                    :style="item.expand?'border-left: 5px solid '+MBS.data.color.primary:''"
                                                    :key="index"
                                                    v-model="item.expand"
                                                    :no-action ="true" 
                                                    :sub-group ="false"
                                                    append-icon="" >
                                                    <template v-slot:activator> 
                                                        <v-list-item-action>  
                                                            <v-layout row wrap>
                                                                <v-btn  icon>
                                                                    <v-icon v-if="item.expand">expand_more</v-icon>
                                                                    <v-icon v-else>chevron_right</v-icon>
                                                                </v-btn> 
                                                                <v-avatar :class="item.expand?'':''"
                                                                    size="40"   
                                                                    rounded="sm"
                                                                    color="grey"  >
                                                                    <v-img v-if="item.image"></v-img>
                                                                    <v-icon v-else>local_offer</v-icon>
                                                                </v-avatar> 
                                                            </v-layout>
                                                        </v-list-item-action>  
                                                        <v-list-item-content>
                                                            {{item.quantity}} {{item.name}}
                                                        </v-list-item-content>
                                                          
                                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                                            <v-layout class="ma-0">
                                                                <v-spacer></v-spacer>
                                                                {{item.expiring_date}}
                                                            </v-layout>
                                                        </v-card></v-list-item-action>  
                                                        <v-list-item-action >
                                                            <v-btn @click="REMOVE_ITEM(item,index)" 
                                                            :disabled="inputLoading" small icon><v-icon>delete</v-icon></v-btn>
                                                        </v-list-item-action> 
                                                    </template>
                                                    <v-card   flat   class="mbs-z-index2 mx-" color="">
                                                        <v-card-text class="b1">
                                                            <v-row>
                                                                <v-col cols="12" sm="4" class="py-0 pr-0"> 
                                                                    <div>Stock</div> 
                                                                    <v-text-field dense color="primary"   
                                                                        filled outlined disabled=""
                                                                        v-model="item.number_quantity"  
                                                                        outline
                                                                    ></v-text-field>  
                                                                </v-col>
                                                                <v-col cols="12" sm="4" class="py-0 px-1" >
                                                                    <div>Other Exp. (QTY)</div>
                                                                        <v-text-field dense
                                                                            filled outlined disabled=""
                                                                            
                                                                            outline
                                                                        ></v-text-field> 
                                                                </v-col>
                                                                <v-col cols="12" sm="4" class="py-0 pl-0">
                                                                    <div>This Exp. (QTY)</div>
                                                                        <v-text-field dense
                                                                            filled outlined 
                                                                            v-model="item.quantity"
                                                                            outline  
                                                                        ></v-text-field> 
                                                                </v-col>
                                                                <v-col cols="12" sm="6" class="pr-1">
                                                                    <div>Manufacturing Date</div>
                                                                    <v-text-field dense
                                                                            filled outlined 
                                                                            v-model="item.manufacturing_date" 
                                                                            outline type="date"
                                                                        ></v-text-field> 
                                                                </v-col>
                                                                <v-col cols="12" sm="6" class="pl-">
                                                                    <div>Expiring Date</div>
                                                                    <v-text-field dense
                                                                            filled outlined 
                                                                            v-model="item.expiring_date"
                                                                            outline type="date"
                                                                        ></v-text-field> 
                                                                </v-col>
                                                            </v-row> 
                                                        </v-card-text>  
                                                    </v-card> 
                                                </v-list-group>
                                                <v-divider :key="'d'+index" ></v-divider>  
                                            </template>
                                        </v-list> 
                                    </v-card>  
                                    
                                    <v-virtual-scroll v-if="false"
                                        class=""
                                        :bench="2"
                                        :items="selected_items"
                                        :height="vsh-300"
                                        item-height="70"  > 
                                        <template v-slot="{ item,index }">
                                                <v-list-item  
                                                    :key="index" :to="item.link">
                                                    <v-list-item-action> 
                                                        <v-avatar
                                                            size="45" tile rounded="lg"
                                                            color="grey"  >
                                                            <v-img v-if="item.image"></v-img>
                                                            <v-icon v-else>local_offer</v-icon>
                                                        </v-avatar>
                                                    </v-list-item-action>
                                                    <v-list-item-content>{{item.name}}</v-list-item-content>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="70">
                                                            <v-text-field
                                                                :disabled="inputLoading || invoice_code?true:false"
                                                                v-mask="'####'" outlined
                                                                filled dense  required
                                                                v-model="item.quantity"/>
                                                        </v-card>
                                                    </v-list-item-action>  
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="100">
                                                            <v-text-field
                                                                :disabled="inputLoading || invoice_code?true:false" required
                                                                v-mask="currencyMask" filled dense  outlined
                                                                v-model="item.price" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="80">
                                                            <v-text-field
                                                                :disabled="inputLoading || invoice_code?true:false" 
                                                                v-mask="currencyMask" filled dense  outlined
                                                                v-model="item.discount" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action>
                                                        <v-card color="transparent" flat width="100">
                                                            <v-layout class="ma-0">
                                                                <v-spacer></v-spacer>
                                                                {{MBS.actions.money(CALCULATE_PRICE(item))}}
                                                            </v-layout>
                                                        </v-card>
                                                    </v-list-item-action> 
                                                    <v-list-item-action >
                                                        <v-btn @click="REMOVE_ITEM(item)" :disabled="inputLoading"
                                                    small icon><v-icon>delete</v-icon></v-btn></v-list-item-action> 
                                                </v-list-item> 
                                                <v-divider ></v-divider> 
                                        </template>  
                                    </v-virtual-scroll>  

                                    <v-spacer></v-spacer>
                                    <v-divider></v-divider>
                                    
                                    <input ref="btn_submit_order" type="submit" v-show="false">
                                    <v-card @click="SUBMIT()"  
                                        :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                                        :flat="MBS.actions.SIZE(selected_items)<1"  
                                        class="mx-5 my-4 v-btn" :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <div class="font-weight-bold not-f7">Add Expiring Items</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL {{
                                                CALCULATE_TOTAL_QTY(selected_items)
                                                }}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>   
                            
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container>  

        <!-- //DIALOGS -->
        <div>
            <mbs-item-manager
                :show="false" 
                :name_items="DATA.ITEMS.ITEMS.names"
                :name_item="DATA.ITEMS.ITEMS.name"
                :value_items="DATA.ITEMS.ITEMS.values"
                :value_item="DATA.ITEMS.ITEMS.value"
                :field_items="DATA.FIELDS.items"   
                :path_item="MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)"  
                :select_items="SelectItems"   
            />
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "RECEIVE-STOCK"  
    export default {
        name:PAGE_NAME, 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                invoice_code:null, 
                show_input:true, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 

                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
            }), 
            DataItems(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems  
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },   
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            //// 
             
            QuotationsAndInvoices(){
                return this.$store.getters.getQuotationsAndInvoices
            },  
            Joined_QuotationsAndInvoices(){
                let items = this.QuotationsAndInvoices 
                let joined = this.MBS.actions.JOIN({
                    items:items,
                    name:DATA.ITEMS.QUOTATIONS_AND_INVOICES.values
                })
                return joined
            }, 
            RequestForQuotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Quotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Invoices(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.INVOICES.value)  
                });
                return filtered_items
            },   
            CALCULATE_TOTAL_QTY(){
                return items=>{
                    let total_qty = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => {  
                            let quantity = this.toNumber(item.quantity)
                            total_qty = total_qty + quantity
                        });  
                        return total_qty
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_QTY',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){
                let ItemCategories = this.ItemCategories
                let Brands = this.Brands
                let Manufacturers = this.Manufacturers

                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let RequestForQuotations = this.RequestForQuotations
                let Quotations = this.Quotations
                let Invoices = this.Invoices
                let SupplierCategories = this.SupplierCategories
                return {
                    ItemCategories: ItemCategories,
                    Brands: Brands,
                    Manufacturers: Manufacturers, 
                    
                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    RequestForQuotations:RequestForQuotations,
                    Invoices:Invoices,
                    Quotations:Quotations,
                    SupplierCategories:SupplierCategories,
                }
            },
            DisabledFields(){
                let order_code = this.invoice_code
                if (order_code) {
                    return ['supplier_code','payment_method']
                }
                return null
            } 
        },
        methods: { 
            ADD_NEW_ITEM(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.ITEMS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_NEW_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.DataItems
                    if (items) {
                        setTimeout(() => {
                            this.search_item=null 
                        }, 500);
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        // if (item) {
                        //     item.quantity = (item.quantity*1)+1
                        // } else {
                        //     this.selected_items.push({
                        //         ...item_selected, 
                        //         quantity:1,
                        //         discount:0,
                        //         price:item_selected.cost_price
                        //     })   
                        // }
                        

                        this.selected_items.push({
                            ...item_selected, 
                            quantity:1,
                            expiring_date:this.MBS.date.tomorrow,
                            manufacturing_date:this.MBS.date.yesterday,
                            price:item_selected.cost_price
                        })  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let price = this.toNumber(item.price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_EXPIRIES(){
                try { 
                    let selected_items = this.selected_items
                    let items_size = this.MBS.actions.SIZE(selected_items)
                    let moment = this.MBS.date.moment

                    if (items_size<1) {
                        console.log("error..");
                    }
                    
                    let stock_order_items = []
                    selected_items.forEach(element => {
                        stock_order_items.push({
                            item_key:element.key, 
                            quantity:this.toNumber(element.quantity), 
                            expiring_date:moment?moment(element.expiring_date).valueOf():null, 
                            manufacturing_date:moment?moment(element.manufacturing_date).valueOf():null, 
                             
                        })
                    });
                    let order_details = stock_order_items 
 
                    console.log(order_details,'order_details....!');
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.EXPIRIES.names,"Add")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_EXPIRIES',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            async SUBMIT_EXPIRIES(items){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.expiries,true) 
                    if (!items || !path) {
                        console.log(items,'order error');
                    } 

                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    let responses = []
                    for (const item of items) { 
                        let res = await this.$store.dispatch("fi_update",{
                            action:DATA.ITEMS.STOCK_ORDERS.values,
                            data:item,
                            path:path+item.item_key+"/"+item.expiring_date
                        })
                        responses.push(res)
                    }
                    this.inputLoading = false
                    this.MBS.actions.progressDialog() 
                    let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.EXPIRIES.values,"Adding")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes, 
                    })   
                    this.MBS.actions.go(-1)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_EXPIRIES',
                        page:PAGE_NAME, 
                    })  
                    this.inputLoading = false
                    this.MBS.actions.progressDialog() 
                    let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.EXPIRIES.values,"Adding")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes, 
                    })  
                }
                
            },
            ADD_ORDER(invoice_code){
                try {  
                    this.show_input = false
                    let invoices = this.Invoices  
                    console.log(invoices,invoice_code,'000');
                    if(!invoice_code){this.show_input = false; return}
                    if(!invoices){this.show_input = false; return}
                    let invoice = invoices.find(q=>{
                        return q.code == invoice_code
                    })   
                    if (invoice) {
                        this.selected_items = []
                        setTimeout(() => { 
                            if (invoice.joined_items  ) {
                                invoice.joined_items.forEach(item => {
                                    this.selected_items.push({
                                        ...item,
                                        key:item.key?item.key:item.item_key
                                    })
                                });
                            }
                            this.input = {
                                ...this.input, 
                                description : invoice.description,
                                supplier_code : invoice.supplier_code, 
                                payment_method : invoice.payment_method, 
                                deliver_to_outlet_code : invoice.deliver_to_outlet_code, 
                            }  
                        }, 1);
                        setTimeout(() => {
                            console.log(invoices,222);
                            this.show_input = true 
                        }, 1000);
                    }else{
                        this.show_input = false
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_EXPIRIES(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(v){  
                let invoice_code =v?v.invoice_code:null 
                if ( invoice_code && invoice_code != this.invoice_code) {
                    this.invoice_code = invoice_code
                    this.ADD_ORDER(invoice_code) 
                }else{ 
                }
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
