<template>
  <v-layout column height="600" class="">   
    <v-divider></v-divider>  
    <mbs-page-tab 
        :items="OFFICE_LINKS"/> 
    <v-divider></v-divider>  
    <v-divider></v-divider>  
    <v-divider></v-divider>  
    <v-layout v-if="false"> 
        <mbs-page-spinner :show="true" />  
    </v-layout>     
    <v-container v-else grid-list-lg>  
        <v-layout row wrap class="px-4 py-5"> 
            <!-- //others -->
            <v-flex xs6 sm4 lg3 >
                <v-hover >
                    <template v-slot="{ hover  }">
                        <v-card  hover @click.prevent ="ADD_MONEY_ACCOUNT()"
                            rounded="xl"  :color="'secondary -1'"  
                            height="100%" class="pa-2">   
                            <v-layout column fill-height class="ma-0">
                                <v-spacer></v-spacer>
                                <v-responsive  aspect-ratio="1.8"  >
                                    <v-layout column fill-height align-center justify-center>
                                        <v-icon size="30" color="white">{{format_list_group_plus}}</v-icon>
                                        <div class="font-weight-bold white--text text--darken-1 not-f7 text-center">Create New Item Department</div>
                                    </v-layout>
                                </v-responsive>    
                            </v-layout>
                        </v-card> 
                    </template>
                </v-hover>
            </v-flex>
            <v-flex xs6 sm4 lg3 v-for="(link, index) in MY_LINKS" :key="index">
                <v-hover>
                    <template v-slot="{ hover  }">
                        <v-card  
                            :to="link.link"
                             
                            :elevation="hover ?12:''" class="b2"
                            hover  rounded="xl"
                            height="100%">  
                            <v-responsive  aspect-ratio="1.5"  >
                                <v-card rounded="xl" height="100%" :color="link.image_background_color" >
                                    <v-layout class="ma-0" align-center justify-center fill-height > 
                                        <v-card rounded="xl" color="transparent" height="100%" :width="link.image_size?link.image_size+'%':'50%'" tile flat>
                                            <v-menu    offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <div>
                                                        <v-layout  class="py-5" > 
                                                            <v-spacer></v-spacer> 
                                                            <v-btn 
                                                                dark
                                                                icon
                                                                v-on="on" >
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn> 
                                                        </v-layout> 
                                                    </div>
                                                </template> 
                                                <v-list v-if="true"> 
                                                    <v-list-item @click="VIEW_ACCOUNT(link)">
                                                        <v-list-item-action><v-icon>mdi-eye</v-icon></v-list-item-action>
                                                        <v-list-item-title>View Account</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="EDIT_ACCOUNT(link)">
                                                        <v-list-item-action><v-icon>edit</v-icon></v-list-item-action>
                                                        <v-list-item-title>Edit Account</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="ARCHIVE_ACCOUNT(link)">
                                                        <v-list-item-action><v-icon>delete</v-icon></v-list-item-action>
                                                        <v-list-item-title>Archive Account</v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item @click="DELETE_ACCOUNT(link)">
                                                        <v-list-item-action><v-icon>delete</v-icon></v-list-item-action>
                                                        <v-list-item-title>Delete Account</v-list-item-title>
                                                    </v-list-item> -->
                                                     
                                                </v-list> 
                                            </v-menu>  
                                            <v-layout   align-center justify-center column> 
                                                
                                                <v-icon class="mt-3" size="50" color="grey darken-">mdi-format-list-group</v-icon>
                                            </v-layout>
                                            <v-img  v-if="false"  
                                                :src="link.image?link.image:MBS.data.noImg" >  
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>  
                                            
                                        </v-card>
                                    </v-layout>  
                                </v-card>
                            </v-responsive>
                            <v-card-title >
                                <v-layout class="ma-0" >
                                    <div class="headline" >{{link.name}}</div> 
                                </v-layout>
                            </v-card-title> 
                        </v-card> 
                    </template>
                </v-hover>
            </v-flex>
        </v-layout>  
        <div>
            <mbs-item-manager
                :show="false" 
                :name_items="NAME_ITEMS"
                :name_item="NAME_ITEM"
                :value_items="VALUE_ITEMS"
                :value_item="VALUE_ITEM"
                :field_items="DATA.FIELDS.item_departments"   
                :path_item="ItemDepartmentsPath"  
                />
                <!-- :select_items="SelectInputFieldsItems"    -->
        </div>
    </v-container>  
  </v-layout> 
</template>

<script>
import DATA from '../../../../../plugins/DATA' 
import {mapState} from "vuex"
import { mdiFormatListGroupPlus } from '@mdi/js';
let PAGE_NAME = DATA.ITEMS.ITEM_DEPARTMENTS.values 
    export default {
        name:PAGE_NAME, 
        data() {
        return {
            PAGE_SHOW:false, 
            NAME_ITEMS:DATA.ITEMS.ITEM_DEPARTMENTS.names,
            NAME_ITEM:DATA.ITEMS.ITEM_DEPARTMENTS.name,
            VALUE_ITEMS:DATA.ITEMS.ITEM_DEPARTMENTS.values,
            VALUE_ITEM:DATA.ITEMS.ITEM_DEPARTMENTS.value,  
            DATA:DATA, 
            format_list_group_plus: mdiFormatListGroupPlus,
        }
        },
        mounted() {
            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500) 
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                ItemDepartments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
                JoinedItemDepartments: state=> state.join[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()], 
            }),  
            ItemDepartmentsData(){ 
                let ItemDepartments = this.JoinedItemDepartments?this.JoinedItemDepartments:this.ItemDepartments 
                return ItemDepartments
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
             
            /////////
            MY_LINKS(){  
                let accounts = this.ItemDepartmentsData 
                if(!accounts){return null}
                let allLinks = []
                accounts.forEach(element => {
                    allLinks.push({
                        ...element,
                        name:element.name,
                        link:this.MBS.actions.COMPANY_LINK("/stock/departments/"+element.code,true),
                        icon:'link', 
                        image_background_color:'primary lighten-2',
                        image_size:'80',
                        
                    })
                });  
                return allLinks
            },
            OFFICE_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("manage",2,true) 
                return thisLinks
            },
            ItemDepartmentsPath(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.item_departments,true) 
                return path
            }, 
        },
        methods: { 
            clicked(){},
            ADD_MONEY_ACCOUNT(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.ITEM_DEPARTMENTS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_MONEY_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ACCOUNT(item){
                try {     
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"edit_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ARCHIVE_ACCOUNT(item){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"archive_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ARCHIVE_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETE_ACCOUNT(item){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"delete_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            VIEW_ACCOUNT(item){
                try {    
                    this.MBS.actions.go(item.link)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            //LOAD
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 
