import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import {store} from './store'
import router from './router'
import './registerServiceWorker'
import './plugins/mbs_components'
import './plugins/views_components'
import './plugins/external_imports'
import './plugins/firebase'
import './plugins/main.css'
import formatNumber from './plugins/formatNumber';
import DATA from './plugins/DATA';


import {EventBus} from './plugins/eventBus' 
import {ActionBus} from './plugins/actionBus' 
import {DataBus} from './plugins/dataBus' 
import {TextBus} from './plugins/textBus' 
import {DateBus} from './plugins/dateBus' 



Vue.config.productionTip = false
Vue.mixin({
  data() {
    return {
      toNumber:formatNumber.toNumber,
      MBS: {
        events:EventBus, 
        actions:ActionBus,
        data:DataBus,
        DATA:DATA,
        color:DataBus.color,
        text:TextBus,
        date:DateBus
      }
    }
  },
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
