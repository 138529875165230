import Vue from 'vue';  
import { store } from '../store';
import vuetify from '../plugins/vuetify';
import browser from 'browser-detect' 


export const DataBus = new Vue({
    data(){
        return{
            noImg:'/system/no_img.png', 
            no_img:'/system/no_image.png',  
            scrolled:0,   
            ACTION_REFRESH_TIME:100, 
        }
    },
    
    computed:{ 
        app(){
            return store.getters.getApp
        }, 
        us(){
            let returnUser = store.getters.getUser
            return returnUser
        },  
        ud(){
            let returnUser = store.getters.getUserD
            return returnUser
        }, 
        loading(){ 
            const loading = store.getters.getLoading
            return loading
        },
        processes(){ 
            const process = store.getters.getProcesses
            return process
        }, 
        responses(){ 
            const re = store.getters.getResponses
            return re
        },
        color(){ 
            return vuetify.framework.theme.themes.light
        },
        animate(){
            return {
                back:{ 
                    inDown:"animate__animated animate__backInDown",
                    inLeft:"animate__animated animate__backInLeft",
                    inRight:"animate__animated animate__backInRight",
                    inUp:"animate__animated animate__backInUp", 
                    outDown:"animate__animated animate__backOutDown",
                    outLeft:"animate__animated animate__backOutLeft",
                    outRight:"animate__animated animate__backOutRight",
                    outUp:"animate__animated animate__backOutRight",
                },
                bounce:{
                    in:"animate__animated animate__bounceIn",
                    inDown:"animate__animated animate__bounceInDown",
                    inLeft:"animate__animated animate__bounceInLeft",
                    inRight:"animate__animated animate__bounceInRight",
                    inUp:"animate__animated animate__bounceInUp",
                    out:"animate__animated animate__bounceOut",
                    outDown:"animate__animated animate__bounceOutDown",
                    outLeft:"animate__animated animate__bounceOutLeft",
                    outRight:"animate__animated animate__bounceOutRight",
                    outUp:"animate__animated animate__bounceOutRight",
                },
                fade:{
                    in:"animate__animated animate__fadeIn",
                    inDown:"animate__animated animate__fadeInDown",
                    inDownBig:"animate__animated animate__fadeInDownBig",
                    inLeft:"animate__animated animate__fadeInLeft",
                    inLeftBig:"animate__animated animate__fadeInLeftBig",
                    inRight:"animate__animated animate__fadeInRight",
                    inRightBig:"animate__animated animate__fadeInRightBig",
                    inUp:"animate__animated animate__fadeInUp",
                    inUpBig:"animate__animated animate__fadeInUpBig",
                    inTopLeft:"animate__animated animate__fadeInTopLeft",
                    inTopRight:"animate__animated animate__fadeInTopRight",
                    inBottomLeft:"animate__animated animate__fadeInBottomLeft",
                    inBottomRight:"animate__animated animate__fadeInBottomRight",
                    out:"animate__animated animate__fadeOut",
                    outDown:"animate__animated animate__fadeOutDown",
                    outDownBig:"animate__animated animate__fadeOutDownBig",
                    outLeft:"animate__animated animate__fadeOutLeft",
                    outLeftBig:"animate__animated animate__fadeOutLeftBig",
                    outRight:"animate__animated animate__fadeOutRight",
                    outRightBig:"animate__animated animate__fadeOutRightBig",
                    outUp:"animate__animated animate__fadeOutUp",
                    outUpBig:"animate__animated animate__fadeOutUpBig",
                    outTopLeft:"animate__animated animate__fadeOutTopLeft",
                    outTopRight:"animate__animated animate__fadeOutTopRight",
                    outBottomLeft:"animate__animated animate__fadeOutBottomRight",
                    outBottomRight:"animate__animated animate__fadeOutBottomLeft",
                },
                flip:{
                    flip:"animate__animated animate__flip",
                    inX:"animate__animated  animate__flipInX",
                    inY:"animate__animated animate__flipInY",
                    outX:"animate__animated animate__flipOutX",
                    outY:"animate__animated animate__flipOutY", 
                },
                lightSpeed:{
                    inRight:"animate__lightSpeedInRight",
                    inLeft:"animate__lightSpeedInLeft",
                    outLight:"animate__lightSpeedOutRight",
                    outLeft:"animate__lightSpeedOutLeft", 
                },
                rotate:{
                    in:"animate__rotateIn", 
                    inDownLeft:"animate__rotateInDownLeft",
                    inDownRight:"animate__rotateInDownRight",
                    inUpLeft:"animate__rotateInUpLeft",
                    inUpRight:"animate__rotateInUpRight",
                    out:"animate__rotateOut", 
                    outDownLeft:"animate__rotateOutDownLeft",
                    outDownRight:"animate__rotateOutDownRight",
                    outUpLeft:"animate__rotateOutUpLeft",
                    outUpRight:"animate__rotateOutUpRight",
                     
                },
                zoom:{
                    in:"animate__animated animate__zoomIn",
                    inDown:"animate__animated animate__zoomInDown",
                    inLeft:"animate__animated animate__zoomInLeft",
                    inRight:"animate__animated animate__zoomInRight",
                    inUp:"animate__animated animate__zoomInUp",
                    out:"animate__animated animate__zoomOut",
                    outDown:"animate__animated animate__zoomOutDown",
                    outLeft:"animate__animated animate__zoomOutLeft",
                    outRight:"animate__animated animate__zoomOutRight",
                    outUp:"animate__animated animate__zoomOutRight",
                },
                slide:{ 
                    inDown:"animate__animated animate__slideInDown",
                    inLeft:"animate__animated animate__slideInLeft",
                    inRight:"animate__animated animate__slideInRight",
                    inUp:"animate__animated animate__slideInUp", 
                    outDown:"animate__animated animate__slideOutDown",
                    outLeft:"animate__animated animate__slideOutLeft",
                    outRight:"animate__animated animate__slideOutRight",
                    outUp:"animate__animated animate__slideOutRight",
                },
            }
        }, 
        browser(){
            return browser
        }
    }, 
});
 