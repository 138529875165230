<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <v-card>
                            <mbs-page-tab 
                                :items="MY_LINKS"/>  
                        </v-card>
                        <!-- <mbs-item-manager
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="FIELD_ITEMS"
                            :header_items="HEADER_ITEMS" 
                            :data_items="MySalesOrder"   
                            :path_item="SALES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        /> -->
                        <v-card :height="vsh-350" tile color="transparent">
                            <v-layout fill-height justify-center align-center>
                                <div class="font-weight-bold">Coming Soon!..</div> 
                            </v-layout>
                        </v-card>
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.INCOMES.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.SALES.names,
                NAME_ITEM:DATA.ITEMS.SALES.name,
                VALUE_ITEMS:DATA.ITEMS.SALE_ORDERS.values,
                VALUE_ITEM:DATA.ITEMS.SALE_ORDERS.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],     
                table_actions:[  
                    // {type:"btn",action:"order_stock",color:"secondary", outlined:false,  text:"Order Stock"}, 
                    // {type:"btn",action:"received_stock",color:"secondary",  text:"Received Stock"}, 
                    // {type:"btn",action:"return_stock",color:"secondary",  text:"Return Stock"}, 
                    // {type:"btn",action:"transfer_stock",color:"secondary",  text:"Transfer Stock"}, 
                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Items',value:'total_quantity'},
                    {name:'Total Sales Price',value:'total_sold_price',show_value:'total_sold_price_',money:true},
                ],
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("reports",2,true) 
                return thisLinks
            }, 
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            }, 
            
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Customer",value:'customer_name',show:true},   
                    {id:0,name:"code",value:"key",align:"center",show:true},         
                    {id:0,name:"Outlet",value:"outlet_name",show:true},         
                    {id:0,name:"Payment",value:"payment_method",show:false},     
                    {id:0,name:"Date",value:'local_created_at_',show:true},   
                    {id:0,name:"Items",value:'total_quantity',align:'right',show:true},   
                    {id:0,name:"Total Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Order",action:"view_order",icon:"mdi-eye"}, 
                        {name:"Archive Order",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            }, 
            DATA_ITEMS(){ 
                let items = this.$store.getters.getSaleOrders
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            },  
            MySalesOrder(){
                let orders = this.DATA_ITEMS
                let us = this.us
                let uid = us?us.uid:null 
                if(!orders||!uid){return null}
                let filtered_items = orders.filter(order=>{
                    return order.created_uid == uid
                })
                return filtered_items
            }  
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "order_stock") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/order-stock",true))
                    }else if (action == "received_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    }else if (action == "return_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/return-stock",true))
                    }else if (action == "transfer_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/transfer-stock",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true) 
                    console.log(path,'...');
                    if (action == "view_order") {
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
