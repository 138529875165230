<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 42.14 41.57"
  :fill="iconColor" xml:space="preserve">

   <path d="M42.14,34.16,22.39,41.57V41.1q0-11.39,0-22.78a.55.55,0,0,1,.43-.62c1.9-.69,3.79-1.41,5.69-2.12l.46-.16V22l.41-.14c1.5-.56,3-1.13,4.5-1.68a.5.5,0,0,0,.38-.56c0-1.94,0-3.87,0-5.8a.48.48,0,0,1,.37-.53l7.54-2.81Z"/><path d="M19.76,41.57,16.37,40.3l-15.91-6A.57.57,0,0,1,0,33.71Q0,22.34,0,11v-.49l2.73,1L19.31,17.7a.57.57,0,0,1,.46.63q0,11.37,0,22.74ZM5.28,29.47c0,.89,0,1.74,0,2.59,0,.11.16.26.28.31.7.29,1.42.55,2.14.82l2.83,1.06c0-.9,0-1.75,0-2.59,0-.11-.16-.27-.28-.32-.71-.28-1.43-.54-2.14-.81Z"/><path d="M21.09,9.53l7.81,3.22-.32.17c-2.41.9-4.82,1.81-7.23,2.7a.83.83,0,0,1-.54,0L.39,8,.08,7.84l.36-.17L20.78.05a.89.89,0,0,1,.58,0L41.7,7.67l.4.17-1.57.59c-2,.75-4,1.51-6,2.24a.83.83,0,0,1-.6,0q-3.59-1.58-7.16-3.2a.89.89,0,0,0-.81,0c-1.48.65-3,1.27-4.46,1.9Z"/><path class="cls-1" d="M5.28,29.47l2.83,1.06c.71.27,1.43.53,2.14.81.12.05.28.21.28.32,0,.84,0,1.69,0,2.59L7.71,33.19c-.72-.27-1.44-.53-2.14-.82-.12,0-.28-.2-.28-.31C5.27,31.21,5.28,30.36,5.28,29.47Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
