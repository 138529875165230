<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/>  
                        <div v-if="!input_table.current_table_tab"> 
                            <mbs-item-manager 
                                v-model="input_table"
                                :add_vertical_lines="true"
                                :table_tabs="tableTabs"
                                :top_label="false"
                                :filter_date="true"
                                :filter_date_value="'expense_date'"
                                :filter_default_days="'90'"
                                :name_items="NAME_ITEMS"
                                :name_item="NAME_ITEM"
                                :value_items="VALUE_ITEMS"
                                :value_item="VALUE_ITEM" 
                                :field_items="DATA.FIELDS.expenses"
                                :header_items="HEADER_ITEMS" 
                                :data_items="TAB_EXPENSES"   
                                :is_joined="true"  
                                :path_item="PATH_ITEM"  
                                :select_items="SELECT_ITEMS"  
                                :total_item_values="TOTAL_ITEM_VALUES"   
                                :table_actions="table_actions" 
                                @SELECT_TABLE_ACTION="TABLE_ACTION"
                            />
                        </div>
                        <div v-else>
                            <mbs-item-manager 
                                v-model="input_table"
                                :add_vertical_lines="true"
                                :table_tabs="tableTabs"
                                :top_label="false"
                                :filter_date="true"
                                :filter_date_only_display="true"
                             
                                :filter_default_days="'90'"
                                :name_items="NAME_ITEMS"
                                :name_item="NAME_ITEM"
                                :value_items="VALUE_ITEMS"
                                :value_item="VALUE_ITEM" 
                                :header_items="HEADER_ITEMS2" 
                                :data_items="TAB_EXPENSES"   
                                :is_joined="true"  
                                :path_item="PATH_ITEM"  
                                :select_items="SELECT_ITEMS"  
                                :total_item_values="TOTAL_ITEM_VALUES2"   
                                :table_actions="table_actions" 
                                @SELECT_TABLE_ACTION="TABLE_ACTION"
                            /> 
                        </div>
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.EXPENSES.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.EXPENSES.names,
                NAME_ITEM:DATA.ITEMS.EXPENSES.name,
                VALUE_ITEMS:DATA.ITEMS.EXPENSES.values,
                VALUE_ITEM:DATA.ITEMS.EXPENSES.value,  
                DATA:DATA,
                input_table:{}, 
                filter_date:{}, 
                tableTabs:[
                    {name:"Expenses",show_if:null},
                    {name:"Aggregated Expenses",show_if:null}, 
                ],
                table_actions:[
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Amount',value:'amount',show_value:'amount_',money:true},
                ],
                TOTAL_ITEM_VALUES2:[ 
                    // {name:'Closing Stock (QTY)',value:'number_quantity',show_value:"number_quantity"},
                    {name:'Total Amount',value:'total_amount',show_value:'total_amount_',money:true},
                ],
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            moment(){  
                return this.MBS.date.moment
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()], 
                ExpenseAccounts: state=> state.items[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()],
                JoinedExpenseAccounts: state=> state.join[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()], 
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                JoinedSuppliers: state=> state.join[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()], 
                DefaultBillAccount: state=> state.app[(DATA.ITEMS.DEFAULT_BILL_METHODS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
            }),  

            ExpensesData(){
                let items = this.JoinedExpenses?this.JoinedExpenses:this.Expenses 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("expense_date",false))    
                return list 
            },
            ExpensesAccountsData(){ 
                let ExpensesAccounts = this.ExpensesAccounts
                let JoinedExpensesAccounts = this.JoinedExpensesAccounts
                let items = JoinedExpensesAccounts?JoinedExpensesAccounts:ExpensesAccounts 
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            }, 
            ExpensesFiltered(){ 
                let allAccounts = this.ExpensesData 
                // let input_table = this.input_table 
                // let filter_date = input_table?input_table.filter_date:null 
                let filter_date = this.filter_date
                if (!filter_date) {return null}
                // console.log(allAccounts,'......');
                // return allAccounts 
                let from_date = filter_date?filter_date.from_date:null
                let to_date = filter_date?filter_date.to_date:null
                if(!allAccounts){return null}
                let filtered_items = allAccounts.filter(element => {
                    let mDate = this.moment(element.expense_date)
                    let between = mDate.isBetween(from_date,to_date,'day','[]')
                    return !element.archive && between
                }); 
                return filtered_items
            },
            CalculatedExpensesAccounts(){ 
                let expenses = this.ExpensesFiltered 
                if(!expenses){return null}
                let accounts = []
                for (const expense of expenses) {
                    let amount = this.toNumber(expense.amount)
                    let expense_account = expense.expense_account?expense.expense_account:{name:"Unknown",code:0}
                   
                    if (expense_account) {
                        let index =  accounts.findIndex(account=>{
                            return account.code == expense_account.code
                        })
                        if (index==-1) {
                            accounts.push({
                                ...expense_account,
                                total_amount:amount,
                                expense_number:1,
                                total_amount_:this.MBS.actions.money(amount),
                            })
                        } else {
                            accounts[index].expense_number = accounts[index].expense_number+1
                            accounts[index].total_amount = accounts[index].total_amount+amount
                            accounts[index].total_amount_ = this.MBS.actions.money(accounts[index].total_amount)
                        }
                    } 
                } 
                return accounts
            }, 
            SuppliersData(){
                let items = this.JoinedSuppliers?this.JoinedSuppliers:this.Suppliers
                return items
            }, 
            PaymentMethodsData(){
                let items = []
                let DefaultBillAccount = this.DefaultBillAccount  
                let DefaultPaymentMethod = this.DefaultPaymentMethod 
                let PaymentMethods= this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
                if(DefaultBillAccount){
                    items = [...DefaultBillAccount]
                }
                if(DefaultPaymentMethod){
                    items= [
                        ...items,
                        ...DefaultPaymentMethod
                    ]
                } 
                if(PaymentMethods){
                    items= [
                        ...items,
                        ...PaymentMethods
                    ]
                } 
                return items
            },
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("reports",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.expenses,true) 
                return path
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:true},     
                    {id:0,name:"Exp. date",value:'expense_date',show:true},   
                    {id:0,name:"Supplier",value:"supplier_name",show:true},     
                    {id:0,name:"Account",value:"expense_account_name",show:true},     
                    {id:0,name:"Payment",value:"payment_method_name",show:true},     
                    {id:0,name:"description",value:"description",show:true},         
                    {id:0,name:"amount",value:"amount",align:"right",show:true},     
                    {id:0,name:"uploaded at",value:'created_at_',show:false},     
                    {id:0,name:"update at",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit Expense Account",action:"edit_item",icon:"edit"}, 
                        {name:"Archive Expense Account",action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    // {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"Expenses",value:"expense_number",align:"center",show:true}, 
                    {id:0,name:"Amount",value:"total_amount_",align:"right",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },
            TAB_HEADERS(){
                let input_table = this.input_table 
                let current_table_tab = input_table?input_table.current_table_tab:null
                let header1 = this.HEADER_ITEMS
                let header2 = this.HEADER_ITEMS2
                if (current_table_tab!=0) {
                    return header2
                }
                console.log(input_table,'input_table....');
                return header2
            },
            TAB_EXPENSES(){
                let input_table = this.input_table 
                let current_table_tab = input_table?input_table.current_table_tab:null
                let expenses = this.ExpensesData
                let account = this.CalculatedExpensesAccounts
                if (current_table_tab==0) {
                    return expenses
                } 
                return account
            },
            AllExpense(){ 
                return this.$store.getters.getExpenses
            },   
            SELECT_ITEMS(){ 
                let ExpenseAccounts = this.ExpenseAccountsData  
                let Suppliers = this.SuppliersData
                let PaymentMethods = this.PaymentMethodsData   
                return {
                    ExpenseAccounts:ExpenseAccounts,
                    Suppliers:Suppliers,
                    PaymentMethods:PaymentMethods,
                }
            },
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "manage_expenses") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/office/accounts/expenses",true))
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            input_table(input_table){
                let filter_date = input_table?input_table.filter_date:null 
                this.filter_date = filter_date
            } 
        },
    }
</script>
 
