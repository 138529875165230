<template>
<div> 
    <v-layout column>
        <v-divider></v-divider>
    <mbs-page-tab 
        :items="MY_LINKS"/>  
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-divider></v-divider>
        <v-card v-if="!PAGE_LOAD" :height="vsh-250" color="b1">
            <mbs-page-spinner/> 
        </v-card>
        <v-layout v-else column>  
            <!-- PROFILE -->
            <v-container grid-list-lg v-if="us">
                <v-layout row wrap class="mt-3">
                    <!-- PROFILE PICTURE--> 
                    <v-flex sm12 md6    id="profilePicture">
                        <v-hover>
                            <template v-slot="{ hover}">
                                <v-card tile class=" " height="100%"
                                    :elevation="hover?12:''"> 
                                    <!--upload image -->
                                    <v-responsive  v-if="loadingImage"
                                        aspect-ratio="1" 
                                        class="b2" >   
                                        <v-layout   
                                            fill-height 
                                            align-center
                                            justify-center
                                            ma-0 > 
                                            <v-progress-circular  
                                                :rotate="-90"
                                                :size="100"
                                                :width="15"
                                                :value="processValue"
                                                color="white"
                                                >
                                                {{ processValue }}
                                            </v-progress-circular> 
                                        </v-layout>
                                    </v-responsive>

                                    <!-- IF IMAGE --> 
                                    <div v-else>
                                        <!-- IF IMAGE --> 
                                        <v-img v-if="ud?ud.photoURL:false" 
                                            aspect-ratio="1" 
                                            :src="ud.photoURL"
                                            lazy-src="https://picsum.photos/10/6?image=3"  
                                            >
                                            <!-- img loader -->
                                            <v-layout
                                                slot="placeholder"
                                                fill-height
                                                align-center
                                                justify-center
                                                ma-0 >
                                                <v-progress-circular 
                                                    indeterminate 
                                                    color="grey lighten-5">
                                                </v-progress-circular>
                                            </v-layout> 

                                             
                                            <v-layout 
                                                column
                                                fill-height  >
                                                <v-card-title>
                                                    <v-spacer></v-spacer>
                                                    <v-btn 
                                                        dark  
                                                        @click="deleteUserProfilePicture()"
                                                        icon class="mr-3">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                    <v-btn 
                                                        dark @click="addImage()" 
                                                        icon class="mr-3">
                                                        <v-icon>edit</v-icon>
                                                    </v-btn> 
                                                </v-card-title> 
                                                <v-spacer></v-spacer> 
                                                <v-card-title class="white--text pl-5 pt-5">
                                                    <div class="display-1 pl-5 pt-5">{{MBS.actions.TEXT_UP11(us?us.displayName:'..')}}</div>
                                                </v-card-title>
                                            </v-layout> 
                                            <v-overlay absolute z-index="-1">
                                            </v-overlay> 
                                        </v-img>

                                        <!-- //NO IMAGE  -->
                                        <v-responsive  v-else   
                                            class="primary px-3"
                                            aspect-ratio="1" > 
                                            <v-layout column fill-height  >
                                                <v-card-title>
                                                    <v-spacer></v-spacer> 
                                                    <v-btn 
                                                        dark @click="addImage()" 
                                                        icon class="">
                                                        <v-icon>edit</v-icon>
                                                    </v-btn> 
                                                </v-card-title>
                                                <v-layout fill-height justify-center align-center>
                                                    <span class="white--text headline">
                                                        {{MBS.actions.TEXT_UP11(us.displayName ? us.displayName[0] : "..")}}
                                                    </span> 
                                                </v-layout>
                                                <v-card-title class="white--text">
                                                    <div class="not-f8">{{us?us.displayName:'..'}}</div>
                                                </v-card-title>  
                                            </v-layout>
                                        </v-responsive> 
                                    </div> 

                                    <!-- INFO --> 
                                    <div  class="" >
                                        <v-toolbar v-if="false"  flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >Contacts</v-toolbar-title>
                                            <v-spacer></v-spacer> 
                                        </v-toolbar>
                                        <v-list two-line> 
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">event</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Registered on:</v-list-item-title>
                                                <v-list-item-subtitle>{{MBS.actions.convertDate(us?us.metadata.creationTime:'...')}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset/> 

                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">event_available</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Updated on</v-list-item-title>
                                                <v-list-item-subtitle>{{MBS.actions.convertDate(ud.updated?ud.updated:us.metadata.creationTime)}}</v-list-item-subtitle>
                                                </v-list-item-content> 
                                            </v-list-item>  
                                        </v-list>
                                    </div> 
                                </v-card> 
                            </template>
                        </v-hover>
                    </v-flex>

                    <!-- USER INFO -->
                    <v-flex  sm12 md6 >
                        <v-layout column fill-height class="ma-0">
                            <!-- kipCount Subscription -->
                            <v-hover>
                                <template v-slot="{ hover }">
                                    <v-card 
                                        tile height="100%"
                                        :elevation="hover?12:''"  >
                                        <v-toolbar color="primary lighten-5" flat="" > 
                                            <!-- <v-btn dark
                                                color="primary"   
                                                icon=""
                                                @click="editUserInformation()" > 
                                                <v-icon  >mdi-wallet</v-icon>
                                            </v-btn> -->
                                            <v-toolbar-title>kipCount Subscription</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <!-- <v-btn dark
                                                color="primary"  outlined
                                                icon=""
                                                @click="editUserInformation()" > 
                                                <v-icon  >edit</v-icon>
                                            </v-btn> -->
                                        </v-toolbar>

                                        <v-card-text>
                                            <!-- <v-layout class="ma-0 mb-3 mt-3">
                                                <v-icon color="primary" class="mr-5">mdi-account-cog </v-icon>
                                                <div>Account Type: </div>
                                                <v-spacer class="mbs-dotted-line mb-3 mx-1"></v-spacer>
                                                <div class="font-weight-bold not-f6">{{MBS.actions.TEXT_UP1(userGroup?userGroup.name:"Passenger")}}</div>
                                            </v-layout>
                                            
                                            <v-layout class="ma-0">
                                                <v-icon color="primary" class="mr-5">account_balance_wallet</v-icon>
                                                <div>Account Balance: </div>
                                                <v-spacer class="mbs-dotted-line mb-3 mx-1"></v-spacer>
                                                <div class="font-weight-bold  mr-1">MWK </div>
                                                <div class="font-weight-bold not-f7 mb-2">0.00</div>
                                            </v-layout> -->
                                            
                                        </v-card-text> 
                                        <v-layout justify-center class="ma-0" fill-height>
                                            <!-- <mbs-qr
                                            :text="us.email"
                                            :size="150"/>  -->
                                            <div>Currently not available!..</div>
                                             
                                        </v-layout> 
                                        <br>
                                    </v-card> 
                                </template>
                            </v-hover>
                            <v-spacer></v-spacer>
                            <!-- Contact -->
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="mt-3" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >{{MBS.actions.TEXT_UP11('Login Credentials')}}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <!-- <v-btn dark
                                                color="primary"  outlined
                                                icon=""
                                                > 
                                                <v-icon>edit</v-icon>
                                            </v-btn>  -->
                                        </v-toolbar>
                                        <v-list two-line> 
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">phone</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Phone number</v-list-item-title>
                                                <v-list-item-subtitle>{{ud.phone_number}}</v-list-item-subtitle>
                                                </v-list-item-content> 
                                                <v-list-item-action>
                                                    <!-- <v-btn dark
                                                        color="primary"  outlined
                                                        icon=""
                                                        > 
                                                        <v-icon>edit</v-icon>
                                                    </v-btn> -->
                                                </v-list-item-action> 
                                            </v-list-item> 
                                            <v-divider inset></v-divider>

                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">mdi-form-textbox-password </v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Password</v-list-item-title>
                                                <v-list-item-subtitle>*****************</v-list-item-subtitle>
                                                </v-list-item-content> 
                                                <v-list-item-action>
                                                    <v-btn dark
                                                        @click="editUserPassword()"
                                                        color="primary"  outlined
                                                        icon=""
                                                        > 
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>
                                                </v-list-item-action> 
                                            </v-list-item>  
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                        </v-layout> 
                    </v-flex> 
                </v-layout> 
            </v-container>

            <v-container grid-list-xs v-if="us">
                <v-row>
                    <!-- user account -->
                    <v-col cols="12" md="6" id="userInfo">
                        <v-hover>
                            <template v-slot="{ hover }">
                                <v-card 
                                    tile height="100%"
                                    :elevation="hover?12:''"  >
                                    <v-toolbar color="primary lighten-5" flat="" > 
                                        <v-toolbar-title>User Info</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn dark
                                            color="primary"  outlined
                                            icon=""
                                            @click="editUserInformation()" > 
                                            <v-icon  >edit</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-list class="mx-1" >
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-account-box </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                                <v-list-item-title>Names</v-list-item-title>
                                                <v-list-item-subtitle>{{ud.names}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item> 
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-account-box-multiple </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Surname</v-list-item-title>
                                            <v-list-item-subtitle>{{ud.surname}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item> 
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-cake-variant </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Date of Birth</v-list-item-title>
                                            <v-list-item-subtitle>{{ud.date_of_birth}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item>  
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">accessibility</v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Gender</v-list-item-title>
                                            <v-list-item-subtitle>{{ud.gender}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item>  
                                    </v-list>
                                </v-card>

                            </template>
                        </v-hover>
                    </v-col>

                    <!-- Next Of Kin Info -->
                    <v-col cols="12" md="6" id="nextOfKinInfo">
                        <v-hover>
                            <template v-slot="{ hover }">
                                <v-card 
                                    tile height="100%"
                                    :elevation="hover?12:''"  >
                                    <v-toolbar color="primary lighten-5" flat="" > 
                                        <v-toolbar-title>Next Of Kin Info</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn dark
                                            color="primary"  outlined
                                            icon=""
                                            @click="editNextOfKin()" > 
                                            <v-icon  >edit</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-list class="mx-1" >
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-account-box </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                                <v-list-item-title>Names</v-list-item-title>
                                                <v-list-item-subtitle>{{ud.next_of_kin_names}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item> 
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-account-box-multiple </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Surname</v-list-item-title>
                                            <v-list-item-subtitle>{{ud.next_of_kin_surname}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item> 
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-phone</v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Next of Kin Phone Number</v-list-item-title>
                                            <v-list-item-subtitle>{{ud.next_of_kin_phone_number}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item>  
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-gender-male-female </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Next of Kin Relationship</v-list-item-title>
                                            <v-list-item-subtitle>{{ud.next_of_kin_relationship}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item>  
                                    </v-list>
                                </v-card>

                            </template>
                        </v-hover>
                    </v-col> 
                </v-row>
                <v-row> 
                    <!-- ID -->
                    <v-col cols="12" sm="6" id="idInfo">
                        <v-hover>
                            <template v-slot="{ hover }">
                                <v-card 
                                    tile height="100%"
                                    :elevation="hover?12:''"  >
                                    <v-toolbar color="primary lighten-5" flat="" > 
                                        <v-toolbar-title>ID Information</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn dark
                                            color="primary"  outlined
                                            icon=""
                                            @click="editIdInfo()" > 
                                            <v-icon  >edit</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-list class="mx-1" >
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-card-account-details</v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                                <v-list-item-title>ID Type</v-list-item-title> 
                                                <v-list-item-subtitle>{{getIdType(ud.id_type)?getIdType(ud.id_type).name:"..."}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item> 
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-card-account-details-star</v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>ID Number</v-list-item-title>
                                            <v-list-item-subtitle>{{ud.id_number}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item> 
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">event</v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Issued Date</v-list-item-title>
                                            <v-list-item-subtitle>{{ud?ud.id_issued_date:'...'}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item>  
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">event_busy</v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Expiring Date</v-list-item-title>
                                            <v-list-item-subtitle>{{ud?ud.id_expiring_date:'...'}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                        </v-list-item>  
                                        <v-divider inset/> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary" :disabled="!(ud?ud.id_verified:false)">mdi-check-decagram </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Status</v-list-item-title>
                                            <v-list-item-subtitle>{{ud?ud.id_verified?"Verified":"Not Verified":'...'}}</v-list-item-subtitle>
                                            </v-list-item-content>  
                                            <v-list-item-action v-if="!(ud?ud.id_verified:false)">
                                                <v-btn color="primary" icon outlined><v-icon>edit</v-icon></v-btn>
                                            </v-list-item-action> 
                                        </v-list-item>  
                                    </v-list>
                                </v-card> 
                            </template>
                        </v-hover>
                    </v-col>

                    <!-- Address -->
                    <v-col cols="12" md="6" id="locationAddress">
                         <v-hover>
                            <template v-slot="{ hover}"> 
                                <v-card tile class="" height="100%" :elevation="hover?12:''">
                                    <v-toolbar flat="" color="primary lighten-5" > 
                                        <v-toolbar-title >Home Address</v-toolbar-title>
                                        <v-spacer></v-spacer> 
                                        <v-btn dark @click="editUserAddress()"
                                            color="primary"  outlined 
                                            icon=""
                                            > 
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-list two-line> 
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-flag</v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Country</v-list-item-title>
                                            <v-list-item-title>{{ud.location_country}}</v-list-item-title>
                                            </v-list-item-content>  
                                        </v-list-item> 
                                        <v-divider inset></v-divider>
                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-map-marker </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>District</v-list-item-title>
                                            <v-list-item-title>{{ud.location_district_code}}</v-list-item-title>
                                            </v-list-item-content>  
                                        </v-list-item> 
                                        <v-divider inset></v-divider>

                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-crosshairs-gps </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Area</v-list-item-title>
                                            <v-list-item-title>{{ud.location_area}}</v-list-item-title>
                                            </v-list-item-content> 
                                        </v-list-item>  
                                        <v-divider inset></v-divider>

                                        <v-list-item  >
                                            <v-list-item-action>
                                            <v-icon color="primary">mdi-office-building-marker </v-icon>
                                            </v-list-item-action> 
                                            <v-list-item-content>
                                            <v-list-item-title>Street / House number</v-list-item-title>
                                            <v-list-item-title>{{ud.location_address}}</v-list-item-title>
                                            </v-list-item-content> 
                                        </v-list-item> 
                                    </v-list>
                                </v-card> 
                            </template>
                        </v-hover>  
                    </v-col>
                </v-row> 
            </v-container>  
        </v-layout> 
    </v-layout>  
    <!-- DIALOG -->
    <v-layout column justify-center v-if="us">
        <!-- image dialog -->
        <mbs-image-crop
            :height="250"
            :width="250"
            :noRotate='false'
            @crop-success="cropSuccess"
            v-model="dialogImageCrop"
            imgFormat="jpg"
            lang-type="en" />
            
         
        <!-- User Info dialog -->
        <v-dialog v-model="dialogUserInfo" persistent max-width="800px"> 
            <v-card>
                <v-card-title class="primary lighten-1 white--text"> 
                    <span class="headline">UPDATE USER INFO</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogUserInfo=false" color="white"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text> 
                    <v-container grid-list-md>
                        <form id="personalForm" @submit.prevent="submitUserInfo()">
                            <v-layout row wrap>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled  
                                        v-model="input.names" 
                                        type="text"
                                        :mask="mask.name"
                                        required 
                                        label="Names"/> 
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled  
                                        :mask="mask.name"
                                        v-model="input.surname"  
                                        type="text"
                                        required 
                                        label="Surname"/>  
                                    
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-select  
                                        filled  
                                        :items="genderType"
                                        v-model="input.gender"
                                        label="Gender" 
                                        hint="Gender"  /> 
                                    
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <!-- <v-text-field 
                                        v-model="input.date_of_birth"
                                        label="Date of Birth"  
                                        filled  
                                        mask="date"
                                        hint="Date of Birth"/>   -->
                                    <v-menu  
                                        v-model="menu_date_picker" 
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto" >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="input.date_of_birth" 
                                            label="Date of Birth"  
                                            readonly filled required
                                            type="date"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="input.date_of_birth"  
                                            @input="menu_date_picker = false"
                                            ></v-date-picker>
                                    </v-menu>
                                </v-flex> 
                            </v-layout>  
                            <v-layout class="px-1">
                                <v-spacer></v-spacer>
                                <v-btn text
                                    @click.native="dialogUserInfo=false">
                                    cancel 
                                </v-btn>
                                <v-btn 
                                    :loading="input.loading" 
                                    type="submit"
                                    color="primary">
                                    Edit
                                    <v-icon class="pl-1"  size="20">edit</v-icon>
                                </v-btn> 
                                
                            </v-layout>
                            
                        </form> 
                    </v-container> 
                </v-card-text> 
            </v-card>
        </v-dialog>

        <!-- Next of Kin dialog -->
        <v-dialog v-model="dialogNextOfKin" persistent max-width="800px"> 
            <v-card>
                <v-card-title class="primary lighten-1 white--text"> 
                    <span class="headline">UPDATE NEXT OF KIN INFO</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogNextOfKin=false" color="white"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text> 
                    <v-container grid-list-md>
                        <form id="personalForm" @submit.prevent="submitNextOfKin()">
                            <v-layout row wrap >
                                <v-flex xs12 md6 >
                                    <v-layout class="mr-1"> 
                                        <v-autocomplete
                                            v-model="select.country"
                                            :items="countryNames" 
                                            filled height="60"
                                            label="Country Code" >
                                            <template 
                                                slot="selection"
                                                slot-scope="data" >
                                                <v-chip 
                                                    :input-value="data.selected"
                                                    :disabled="data.disabled"
                                                    :key="JSON.stringify(data.item)"
                                                    class="v-chip--select-multi"
                                                    clearable
                                                    @input="data.parent.selectItem(data.item)" >
                                                    <v-avatar v-if="select.country!==''" 
                                                        class="mr-2" >
                                                            <span 
                                                                style="font-size:30px"   >
                                                                <flag :iso="select.country.split(':')[0]" />   
                                                            </span> 
                                                    </v-avatar>

                                                    {{ data.item.split(': ')[1] }}
                                                </v-chip> 
                                            </template>
                                        </v-autocomplete>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 md6>
                                    <v-text-field
                                        filled 
                                        id="inputPhone"  
                                        v-model="inputPhone" 
                                        label="Next of Kin Phone Number"></v-text-field> 
                                </v-flex> 
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled  
                                        v-model="input.next_of_kin_names" 
                                        type="text"
                                        :mask="mask.name"
                                        required 
                                        label="Next of Kin Names"/> 
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled  
                                        :mask="mask.name"
                                        v-model="input.next_of_kin_surname"  
                                        type="text"
                                        required 
                                        label="Next of Kin Surname"/>  
                                    
                                </v-flex>   
                                <v-flex xs12 sm12> 
                                    <v-autocomplete
                                        v-model="input.next_of_kin_relationship" 
                                        filled required 
                                        :items="relationship"
                                        :item-value="'code'"
                                        :item-text="'name'"
                                        label="Next of kin relation" />
                            </v-flex>  
                            </v-layout>  
                            <v-layout v-if="!GET_PHONE_NUMBER(true).valid" align-center class="pt-5 c2--text font-weight-bold" >
                                <v-icon class="mr-1 mb-1">warning</v-icon>
                                <div>Make sure you enter a valid next of kin number before you can Save Changes!.</div>
                            </v-layout>
                            <v-layout class="px-1">
                                <v-spacer></v-spacer>
                                <v-btn text
                                    @click.native="dialogNextOfKin=false">
                                    cancel 
                                </v-btn>
                                <v-btn 
                                    tile
                                    :loading="input.loading" 
                                    :disabled="!GET_PHONE_NUMBER(true).valid"
                                    type="submit"
                                    color="primary">
                                    Save Changes
                                    <!-- <v-icon class="pl-1"  size="20">edit</v-icon> -->
                                </v-btn>  
                            </v-layout>
                            
                        </form> 
                    </v-container> 
                </v-card-text> 
            </v-card>
        </v-dialog>
        
        <!-- ID dialog -->
        <v-dialog v-model="dialogIdInfo" persistent max-width="800px"> 
            <v-card>
                <v-card-title class="primary lighten-1 white--text"> 
                    <span class="headline">UPDATE ID INFO</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogIdInfo=false" color="white"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text> 
                    <v-container grid-list-md>
                        <form id="personalForm" @submit.prevent="submitIdInfo()">
                            <v-layout row wrap>
                                <v-flex xs12 sm6> 
                                    <v-autocomplete
                                        v-model="input.id_type" 
                                        filled required 
                                        :items="IdTypes"
                                        :item-value="'value'"
                                        :item-text="'name'"
                                        label="ID Type" />
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled   
                                        v-model="input.id_number"  
                                        type="text"
                                        required 
                                        label="ID Number"/>   
                                </v-flex>  
                                <v-flex xs12 sm6>
                                    <v-menu  
                                        v-model="menu_id_issued_date" 
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y required
                                        min-width="auto" >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="input.id_issued_date" 
                                            label="Issued Date"  
                                            readonly filled required
                                            type="date"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker 
                                            v-model="input.id_issued_date"  
                                            @input="menu_id_issued_date = false"
                                            ></v-date-picker>
                                    </v-menu>
                                    
                                </v-flex>  
                                <v-flex xs12 sm6>
                                    <v-menu  
                                        v-model="menu_id_expiring_date" 
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto" >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="input.id_expiring_date" 
                                            label="Expiring Date"  
                                            readonly filled required
                                            type="date"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="input.id_expiring_date"  
                                            @input="menu_id_expiring_date = false"
                                            ></v-date-picker>
                                    </v-menu>
                                    
                                </v-flex>  
                            </v-layout>  
                            <v-layout class="px-1">
                                <v-spacer></v-spacer>
                                <v-btn text
                                    @click.native="dialogIdInfo=false">
                                    cancel 
                                </v-btn>
                                <v-btn 
                                    :loading="input.loading" 
                                    type="submit"
                                    color="primary">
                                    Edit
                                    <v-icon class="pl-1"  size="20">edit</v-icon>
                                </v-btn>  
                            </v-layout> 
                        </form> 
                    </v-container> 
                </v-card-text> 
            </v-card>
        </v-dialog>

        <!-- Number dialog -->
        <v-dialog v-model="dialogUserPhoneNumber" persistent max-width="500px"> 
            <v-card>
                <v-card-title> 
                    <span class="headline">UPDATE PHONE NUMBER</span>
                </v-card-title>
                <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 >
                            <v-text-field 
                                label="Main phone number" 
                                v-model="input.phoneNumber"></v-text-field>
                        </v-flex>
                        
                    </v-layout>
                </v-container>
                
                </v-card-text>
                <!-- <v-divider></v-divider> -->
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="dialogUserPhoneNumber= false">Close</v-btn>
                <v-btn color="blue darken-1" flat @click.native="savepHone()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- email dialog -->
        <v-dialog v-model="dialogUserEmail" persistent max-width="500px"> 
            <v-card>
                <v-card-title> 
                    <span class="headline">UPDATE EMAIL / PASSWORD</span>
                </v-card-title>
                <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field 
                                label="Email address" 
                                v-model="input.email"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-text-field 
                                v-model="input.password"
                                label="Password" 
                                hint="Password"></v-text-field>
                        </v-flex> 
                        <v-flex xs12 sm6 >
                            <v-text-field 
                                v-model="input.passwordAgain"
                                label="Again" 
                                hint="Again"></v-text-field>
                        </v-flex> 
                    </v-layout>
                </v-container>
                
                </v-card-text>
                <!-- <v-divider></v-divider> -->
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="dialogUserEmail = false">Close</v-btn>
                <v-btn color="blue darken-1" flat @click.native="saveEmail()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- password dialog -->
        <v-dialog v-model="dialogUserPassword" persistent max-width="700px"> 
            <v-card>
                <v-card-title class="primary" dark> 
                    <span class="headline white--text">UPDATE PASSWORD</span>
                    <v-spacer></v-spacer>
                    <v-btn color="white" icon @click="dialogUserPassword=false" >
                        <v-icon dark="">close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <form id="personalForm" @submit.prevent="submitUserPassword()">
                            <v-layout row wrap class="mt-5">
                                <v-flex xs12 >
                                    <v-text-field autocomplete="one-time-code"  
                                        filled  :disabled="inputLoading"
                                        v-model="input.current_password" 
                                        prepend-inner-icon="lock"
                                        :append-icon="currentPassword ? 'visibility' : 'visibility_off'"
                                        @click:append="() => (currentPassword = !currentPassword)"
                                        :type="currentPassword ? 'password' : 'text'" 
                                        required 
                                        label="Current password"/> 
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field autocomplete="one-time-code"  
                                        filled   :disabled="inputLoading"
                                        v-model="input.new_password"  
                                        prepend-inner-icon="lock"
                                        :append-icon="newPassword ? 'visibility' : 'visibility_off'"
                                        @click:append="() => (newPassword = !newPassword)"
                                        :type="newPassword ? 'password' : 'text'"
                                        required 
                                        label="New password"/>   
                                </v-flex> 
                                <v-flex xs12 sm6>
                                    <v-text-field autocomplete="one-time-code"  
                                        v-model="input.confirm_password"
                                        prepend-inner-icon="lock"
                                        :append-icon="confirmPassword ? 'visibility' : 'visibility_off'"
                                        @click:append="() => (confirmPassword = !confirmPassword)"
                                        :type="confirmPassword ? 'password' : 'text'"
                                        label="Confirm password"  
                                        filled   :disabled="inputLoading"
                                        hint="Confirm your new password"/> 
                                    
                                </v-flex> 
                            </v-layout>  
                            <v-layout class="px-1 mt-5">
                                <v-spacer></v-spacer>
                                <v-btn text
                                    @click.native="dialogUserPassword=false">
                                    cancel 
                                </v-btn>
                                <v-btn 
                                    :loading="inputLoading" 
                                    type="submit"
                                    color="primary">
                                    Update Password
                                    <v-icon class="pl-1"  size="20" v-if="false">edit</v-icon>
                                </v-btn> 
                                
                            </v-layout> 
                        </form> 
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog> 

        <!-- address dialog -->
        <v-dialog v-model="dialogUserAddress" persistent max-width="700px"> 
            <v-card>
                <v-card-title class="primary" dark> 
                    <span class="headline white--text">UPDATE ADDRESS</span>
                    <v-spacer></v-spacer>
                    <v-btn  dark icon @click="dialogUserAddress=false" >
                        <v-icon dark="">close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <form id="personalForm" @submit.prevent="submitUserAddress()">
                            <v-layout row wrap>
                                <v-flex xs12 sm12> 
                                    <v-autocomplete
                                        v-model="select.country"
                                        :items="countryNames2" 
                                        filled height="60"
                                        label="Select your Home Country" >
                                        <template 
                                            slot="selection"
                                            slot-scope="data" >
                                            <v-chip 
                                                :input-value="data.selected"
                                                :disabled="data.disabled"
                                                :key="JSON.stringify(data.item)"
                                                class="v-chip--select-multi"
                                                clearable
                                                @input="data.parent.selectItem(data.item)" >
                                                <v-avatar v-if="select.country!==''" 
                                                    class="mr-2" >
                                                        <span 
                                                            style="font-size:30px"   >
                                                            <flag :iso="select.country.split(':')[0]" />   
                                                        </span> 
                                                </v-avatar>

                                                {{ data.item.split(': ')[1] }}
                                            </v-chip> 
                                        </template>
                                    </v-autocomplete>
                                </v-flex>
                                <v-flex xs12 sm6 v-if="select.country =='MW: Malawi'" >
                                    <v-autocomplete
                                        filled  
                                        v-model="input.location_district_code" 
                                        :items="districts"
                                        item-text="name"
                                        item-value="code"
                                        :mask="mask.name"
                                        required 
                                        label="District"/> 
                                </v-flex>
                                <v-flex xs12 sm6 v-if="select.country =='MW: Malawi'" >
                                    <v-text-field 
                                        filled  
                                        :mask="mask.name"
                                        v-model="input.location_area"  
                                        type="text"
                                        required 
                                        label="Area"/>   
                                </v-flex> 
                                <v-flex xs12  >
                                    <v-textarea
                                        v-model="input.location_address"
                                        label="Address/ Street / House number"  
                                        filled   
                                        />  
                                </v-flex>  
                                
                            </v-layout>  
                            <v-layout class="px-1">
                                <v-spacer></v-spacer>
                                <v-btn text
                                    @click.native="dialogUserAddress=false">
                                    cancel 
                                </v-btn>
                                <v-btn tile
                                    :loading="input.loading" 
                                    type="submit"
                                    color="primary">
                                    Save changes
                                    <!-- <v-icon class="pl-1"  size="20">edit</v-icon> -->
                                </v-btn> 
                                
                            </v-layout>
                            
                        </form> 
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog> 
        
    </v-layout> 
</div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'  
    let PAGE_NAME = "MY_PROFILE" 
    export default {
        name:PAGE_NAME, 
        data() {
            return {  
                page_current:1,
                page_size:10,
                DATA:DATA,
                processValue:0,
                loadingImage:false,

                dialogUserInfo:false,
                dialogNextOfKin:false,
                dialogIdInfo:false,
                dialogUserPhoneNumber:false,
                dialogUserEmail:false,
                dialogUserPassword:false,
                dialogUserAddress:false,
                dialogImageCrop:false,
                menu_date_picker:false,
                menu_id_issued_date:false,
                menu_id_expiring_date:false,
                tab: null, 
                input:{}, 
                inputLoading:false,
                inputPhone:'',
                genderType:['Male','Female'],
                relationship:['Family Member','Friend'], 
                select:{
                    signOption:'',
                    accountType:'',
                    businessType:'',
                    country:''
                },
                mask: {
                    price:'########',
                    year:'####',
                    code:'######',
                    phone:'phone',
                    name:'Nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn',
                    date:'date',
                    yEmail:'nnnnnnnnnnnnnnnnnnnn'
                }, 
                map:{
                    latitude:['N','S'],
                    longitude:['W','E'],
                },
                BROCK_NO_STATUS:false,
                currentPassword:true,
                newPassword:true,
                confirmPassword:true,
            }
        },
        created() {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
            this.MBS.actions.setPAGE_LOAD(false)
        },
        mounted() {
            this.MBS.actions.header({
              show:true,
              type:'subToolbar',
              images:this.items,
              height:400 
            })
           this.FEEDBACKS(1000)
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            PAGE_LOAD(){
                let load = this.$store.getters.getPAGE_LOAD
                return load
            }, 
            us(){
                let returnUser = this.$store.getters.getUser
                return returnUser
            }, 
            ud(){
                let returnUser = this.$store.getters.getUserDetails
                return returnUser
            }, 
            loading(){ 
                const loading = this.$store.getters.getLoading
                return loading
            },
            processes(){ 
                const process = this.$store.getters.getProcesses
                return process
            }, 
            /////////
            MY_LINKS(){
                let links = this.MBS.actions.FIND_SUB_LINKS("account",1)  
                return links
            },
            responses(){ 
                const re = this.$store.getters.getResponses
                return re
            },
            ACTIONS(){
                return this.MBS.actions
            },
            // DATA(){
            //     return this.MBS.data
            // },
            EVENTS(){
                return this.MBS.events
            },
            IdTypes(){ 
                let items = this.$store.getters.getIdTypes
                return ["National ID","Passport","Driving License"]
            },
            getIdType(){ 
                let IdTypes = this.IdTypes
                if(!IdTypes){return null}
                return id_value =>{
                    let item = IdTypes.find(idType=>{
                        return idType.value == id_value
                    })
                    return item
                }
            },
            userGroup(){
                let ud = this.ud
                let user_group_code = ud?ud.user_group_code:null 
                let user_group = this.$store.getters.getUserGroupByCode(user_group_code)
                return user_group
            },
            districts(){ 
                const re = this.$store.getters.getDistricts
                return re
            },
            countryNames(){
                let names = []
                let countries = this.$store.getters.getCountries 
                for (const key in countries) {
                    const cn = countries[key]
                    if (cn.alpha2!== undefined) {
                        names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                    } 
                } 
                //names=['Mw: Malawi (+265)']
                return names
            }, 
            countryNames2(){
                let names = []
                let countries = this.$store.getters.getCountries 
                for (const key in countries) {
                    const cn = countries[key]
                    if (cn.alpha2!== undefined) {
                        names.push(cn.alpha2+': '+cn.name) 
                    } 
                } 
                //names=['Mw: Malawi (+265)']
                return names
            }, 
            iLocation(){ 
                const i = this.$store.getters.getIpLocation
                return i
            },
            // GET_PHONE_NUMBER(){
            //     try {
            //         let us = this.us  
            //         let input_country = ''+this.select.country
            //         let input_phone = ''+this.inputPhone 
            //         let countryNames = this.countryNames 
            //         let PHONE = this.MBS.phone 
            //         return all =>{
            //             let current_phone = ''+input_phone 
            //             let ip_phone = null 
            //             let ip_countryPhone = null 

            //             if (input_phone) {
            //                 input_phone = input_phone.replace(/[^0-9+]/g,'')
            //             }if (input_country) {
            //                 input_country = input_country.replace(/[^0-9]/g,'')
            //             } 

            //             if (input_phone) { 
            //                 ip_phone = PHONE(input_phone)
            //             }if (input_country) { 
            //                 ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
            //             } 

            //             if (ip_phone) {
            //                 let possible = ip_phone.a?ip_phone.a.possible:null
            //                 if (possible) {
            //                     current_phone = ip_phone.a

            //                     if (countryNames) { 
            //                         let regionCode = current_phone.regionCode
            //                         let countryName = countryNames.find(item=>{
            //                             return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
            //                         })
            //                         if (countryName != this.select.country) {
            //                             this.select.country = countryName
            //                         } 
            //                     }
            //                 }
            //             }
            //             if (ip_countryPhone) {
            //                 let possible = ip_countryPhone.a?ip_countryPhone.a.possible:null
            //                 if (possible) {
            //                     current_phone = ip_countryPhone.a
            //                 }
            //             }else{

            //             }

            //             if (current_phone.valid) {
            //                 if (this.inputPhone != current_phone.number.international ) { 
            //                     this.inputPhone = current_phone.number.international
            //                 }
            //             } 
            //             // console.log(input_phone,'input_phone.........');
            //             // console.log(input_country,'input_country.........');  
            //             // console.log(ip_countryPhone,'ip_countryPhone.........');
            //             // console.log(ip_phone,'ip_phone.........');  
            //             // console.log(current_phone,'current_phone.........'); 

            //             if (all) {
            //                 return {
            //                     ...current_phone,
            //                     ...current_phone.number,
            //                     phone:current_phone.number?current_phone.number.e164:'',
            //                     phone_number:current_phone.number?current_phone.number.e164:'',

            //                 }
            //             } else {
            //                 return current_phone.number?current_phone.number.e164:''
            //             } 
            //         } 
            //     } catch (error) {
            //         this.MBS.actions.error({
            //             error:error,
            //             from:'CHECK_PHONE_NUMBER',
            //             page:PAGE_NAME, 
            //         })
            //     }  
            // },
            GET_PHONE_NUMBER(){
                try {
                    let us = this.us  
                    let input_country = ''+this.select.country
                    let input_phone = ''+this.inputPhone 
                    let countryNames = this.countryNames 
                    let PHONE = this.MBS.phone 
                    return all =>{
                        let current_phone = ''+input_phone 
                        let ip_phone = null 
                        let ip_countryPhone = null 

                        if (input_phone) {
                            input_phone = input_phone.replace(/[^0-9+]/g,'')
                        }
                        if (input_country) {
                            input_country = input_country.replace(/[^0-9]/g,'')
                        } 

                        if (input_phone) { 
                            ip_phone = PHONE(input_phone)
                        }
                        if (input_country) { 
                            ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                        } 

                        if (ip_phone) {
                            let possible = ip_phone.g?ip_phone.g.possible:null
                            if (possible) {
                                current_phone = ip_phone.g

                                if (countryNames) { 
                                    let regionCode = current_phone.regionCode
                                    let countryName = countryNames.find(item=>{
                                        return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                    })
                                    if (countryName != this.select.country) {
                                        this.select.country = countryName
                                    } 
                                }
                            }
                        }
                        if (ip_countryPhone) {
                            let possible = ip_countryPhone.g?ip_countryPhone.g.possible:null
                            if (possible) {
                                current_phone = ip_countryPhone.g
                            }
                        } 

                        if (current_phone.valid) {
                            if (this.inputPhone != current_phone.number.international ) { 
                                this.inputPhone = current_phone.number.international
                            }
                        } 

                        let returnData = {
                            ...current_phone,
                            ...current_phone.number,
                            phone:current_phone.number?current_phone.number.e164:'',
                            phone_number:current_phone.number?current_phone.number.e164:'',
                        }
                        // console.log(input_phone,'input_phone.........');
                        // console.log(input_country,'input_country.........');  
                        // console.log(ip_countryPhone,'ip_countryPhone.........');
                        // console.log(ip_phone,'ip_phone.........');  
                        // console.log(current_phone,'current_phone.........'); 
                        // console.log(returnData,'returnData.........'); 

                        if (all) {
                            return returnData
                        } else {
                            return current_phone.number?current_phone.number.e164:''
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }  
            },
        },
        methods: { 
            addImage(){ 
                this.dialogImageCrop = true
            },
            editUserInformation(){
                this.input = {},
                this.input.names=this.ud?this.ud.names:''
                this.input.surname=this.ud?this.ud.surname:''
                this.input.date_of_birth=this.ud?this.ud.date_of_birth:''
                this.input.gender=this.ud?this.ud.gender:'' 
                this.dialogUserInfo = true
            },
            editNextOfKin(){
                this.input = {},
                this.input.next_of_kin_names=this.ud?this.ud.next_of_kin_names:''
                this.input.next_of_kin_surname=this.ud?this.ud.next_of_kin_surname:''
                this.input.next_of_kin_phone_number=this.ud?this.ud.next_of_kin_phone_number:''
                this.inputPhone=this.ud?this.ud.next_of_kin_phone_number:''
                this.input.next_of_kin_relationship=this.ud?this.ud.next_of_kin_relationship:'' 
                this.dialogNextOfKin = true
            },
            editIdInfo(){
                this.input = {},
                this.input.id_type=this.ud?this.ud.id_type:''
                this.input.id_issued_date=this.ud?this.ud.id_issued_date:''
                this.input.id_expiring_date=this.ud?this.ud.id_expiring_date:''
                this.input.id_number=this.ud?this.ud.id_number:'' 
                this.dialogIdInfo = true
            },
            editUserPhoneNumber(){
                this.input = {},
                this.input.phone_number=this.ud?this.ud.phone_number:'' 
                this.dialogUserPhoneNumber = true
            },
            editUserEmail(){
                this.input = {},
                this.input.email=this.ud?this.ud.email:'' 
                this.dialogUserEmail= true
            },
            editUserPassword(){
                this.input = {}, 
                this.dialogUserPassword = true
            },
            editUserAddress(){
                this.input = {},
                this.input.location_district_code=this.ud?this.ud.location_district_code:''
                this.input.location_area=this.ud?this.ud.location_area:''
                this.input.location_address=this.ud?this.ud.location_address:''  
                this.select.country=this.ud?this.ud.location_country:''  
                this.dialogUserAddress = true
            },

            submitUserInfo(){ 
                let data = {
                    names:this.input.names,
                    surname:this.input.surname,
                    gender:this.input.gender,
                    date_of_birth:this.input.date_of_birth,
                }
                let mbs_text = this.MBS.text.update_item("User Info")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-USER-PROFILE",
                        data:data 
                    }

                })
                
            },
            submitNextOfKin(){ 
                let data = {
                    next_of_kin_names:this.input.next_of_kin_names,
                    next_of_kin_surname:this.input.next_of_kin_surname,
                    next_of_kin_phone_number:this.inputPhone,
                    next_of_kin_relationship:this.input.next_of_kin_relationship,
                }
                if (!data.next_of_kin_relationship) {
                    let mbs_text = this.MBS.text.field_required("Next of Kin Relationship")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes, 
                    })
                    return
                }
                let mbs_text = this.MBS.text.item_action("Next of Kin Info","Update")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-USER-DETAILS",
                        data:data 
                    } 
                })
                
            },
            submitIdInfo(){  
                let data = {
                    id_type:this.input.id_type,
                    id_number:this.input.id_number,
                    id_issued_date:this.input.id_issued_date,
                    id_expiring_date:this.input.id_expiring_date,
                }
                if (!data.id_type) {
                    let mbs_text = this.MBS.text.field_required("ID Type")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes, 
                    })
                    return
                } if (!data.id_issued_date) {
                    let mbs_text = this.MBS.text.field_required("Issued Date")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes, 
                    })
                    return
                } if (!data.id_expiring_date) {
                    let mbs_text = this.MBS.text.field_required("Expiring Date")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes, 
                    })
                    return
                }  
                let mbs_text = this.MBS.text.item_action("ID Info","Update")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-USER-DETAILS",
                        data:data 
                    } 
                })
                
            },
            submitUserAddress(){
                let data = { 
                    location_address:this.input.location_address,
                    location_country:this.select.country,
                }
                if (this.input.location_area) {
                    data.location_area = this.input.location_area
                }if (this.input.location_district_code) {
                    data.location_district_code = this.input.location_district_code
                }
                if (!data.location_country) {
                    let mbs_text = this.MBS.text.field_required("Select Your Home Country")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes, 
                    })
                    return
                } 
                let mbs_text = this.MBS.text.item_action("Your Address","Update")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-USER-DETAILS",
                        data:data 
                    } 
                })
            },
            submitUserPassword(){
                let email = this.ud?this.ud.email:''
                let p_uid = this.ud?this.ud.p_uid:'' 
                let current_password = this.input.current_password
                let new_password = this.input.new_password
                let confirm_password = this.input.confirm_password
                let pass_uid = this.MBS.crypt.decrypt(p_uid) 
                if (!email) {
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"ERROR",
                        text:"Something went wrong. Restart your browser and try again.",
                        btnYes:"Ok"
                    })
                    return
                } 
                if (!pass_uid || pass_uid!==current_password) {
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"ERROR",
                        text:"Wrong current password",
                        btnYes:"Ok"
                    })
                    return
                }
                if (new_password!==confirm_password) {
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"ERROR",
                        text:"Password miss mach",
                        btnYes:"Ok"
                    })
                    return
                }
                if (new_password.length < 8) {
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"ERROR",
                        text:"Password mast at least be 8 characters or numbers",
                        btnYes:"Ok"
                    })
                    return
                }

                this.inputLoading = true
                this.MBS.actions.progressDialog({
                    show:true,
                    text:"Updating..."
                })
                this.$store.dispatch('fi_updateUser',{
                    current_password:current_password,
                    new_password:new_password,
                    current_email:email 
                }).then(res=>{
                    this.MBS.actions.dialog({
                        show:true,fixed:true,
                        title:"SUCCESSFUL",
                        text:"Password successfully updated.",
                        btnYes:"OK", 
                    }) 
                    this.inputLoading = false
                    this.dialogUserPassword = false
                    this.MBS.actions.progressDialog() 
                    this.MBS.actions.restartResponses() 
                }).catch(error=>{ 
                    this.MBS.actions.dialog({
                        show:true, fixed:true,
                        title:"NOT SUCCESSFUL",
                        text:error.error.message?error.error.message:"Error while updating the password. Please, again later",
                        btnYes:"OK",  
                    })  
                    this.inputLoading = false 
                    this.MBS.actions.progressDialog() 
                    this.MBS.actions.restartResponses()
                })
            }, 
            cropSuccess(imgDataUrl, field){  
                this.imgDataUrl = imgDataUrl; 
                this.$store.dispatch('fi_updateUserProfilePicture',{
                    data:{image:imgDataUrl}
                }) 
            },
            deleteUserProfilePicture(){
                 this.input.check = true
                this.MBS.actions.dialog({
                    show:true,
                    fixed:true,
                    title:"DELETE PROFILE PICTURE",
                    text:"Are you sure you want to delete your profile picture?",
                    btnYes:"DELETE",
                    btnNo:"CANCEL",
                    action:{
                      code:PAGE_NAME+"=DELETE-PROFILE"  
                    }
                })
            },

            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) {
                        if (action.code ===PAGE_NAME+"=DELETE-PROFILE") {
                            if (action.YES) { 
                                this.$store.dispatch("fi_deleteUserProfilePicture") 
                            }else{}
                        } 
                        if (action.code ===PAGE_NAME+"=UPDATE-USER-PROFILE") {
                            if (action.YES) { 
                                this.$store.dispatch('fi_updateUserProfile',{
                                    data:action.data
                                }) 
                            } 
                        }if (action.code ===PAGE_NAME+"=UPDATE-USER-DETAILS") {
                            if (action.YES) { 
                                this.$store.dispatch('fi_updateUserDetails',{
                                    data:action.data
                                }) 
                            } 
                        }
                        if (action.code ===PAGE_NAME+"=SETUP-PROFILE") {
                            if (action.YES) {
                                this.$store.dispatch("fi_update",{
                                    action:"CreateUserProfile",
                                    path:'USERS/'+this.us.uid,
                                    data:{
                                        registered:true
                                    }
                                })  
                            }else{}
                        }
                        if (action.code ===PAGE_NAME+"=VERIFY-PHONE-NUMBER") {
                            if (action.YES) {
                                this.MBS.actions.go("/signup")  
                            }else{
                                this.MBS.actions.go("/")  
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            },  
            FEEDBACKS(t){
                try {
                    if (!t) {
                        t=400
                    }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses  
                        let i = this.iLocation 
                        
        
                        //get i and set country number 
                        if (i) {
                            this.select.country = i.country.code+ ': '+i.country.name+' ('+i.country.callingCode+')' 
                        }else{
                            //this.$store.dispatch('setILocation')
                        }  
    
                        //PROCESSES
                        if (ps) {
                            let logging = ps.find(process=>{
                                return process.name == 'logging'
                            }) 
                            let userDetails = ps.find(process=>{
                                return process.name == 'getting/UserDetails'
                            }) 
    
                            let updatingUserDetails = ps.find(process=>{
                                return process.name == 'updating/userDetails'
                            }) 
                            let updatingUserProfile = ps.find(process=>{
                                return process.name == 'updating/userProfile'
                            })
                            let updatingUserEmailAndPassword = ps.find(process=>{
                                return process.name == 'updating/userEmailAndPassword'
                            })
                            let uploadingProfilePicture = ps.find(process=>{
                                return process.name == 'uploading/profilePicture'
                            })
                            let deletingProfilePicture = ps.find(process=>{
                                return process.name == 'deleting/profilePicture'
                            })
    
                            if (logging) {
                                this.MBS.actions.setPAGE_LOAD(false)
                            }else{
                                if (userDetails) {
                                    this.MBS.actions.setPAGE_LOAD(false)
                                } else {
                                    this.VIEW_PAGE()
                                }
                            } 
                            
                            if (updatingUserProfile) {
                                this.input.logging = true
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    text:"Updating..."
                                })
                            }
                            if (updatingUserDetails) {
                                this.input.logging = true
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    text:"Updating..."
                                })
                            }
                            if (updatingUserEmailAndPassword) {
                                this.input.logging = true
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    text:"Updating..."
                                })
                            }
                            if (deletingProfilePicture) {
                                this.input.logging = true
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    text:"deleting..."
                                })
                            }
                            if (uploadingProfilePicture) {
                                let value = uploadingProfilePicture.val
                                value = this.toNumber(value)
                                if (value >= 100) {
                                    if (this.loadingImage) {
                                        this.processValue = value
                                        setTimeout(() => {
                                            this.loadingImage = false
                                            this.processValue = 0
                                        }, 2000);
                                    }else{
                                        this.processValue = 0
                                    }
                                }else{
                                    this.loadingImage = true
                                    if (value == 0) {
                                        this.processValue = this.processValue+5 
                                    }
                                } 
                            }
    
    
                        }else{
                            this.MBS.actions.progressDialog()
                            this.VIEW_PAGE()
                        }
    
                        //RESPONSES
                        //
                        // user profile
                        if (res.res==='updating/CreateUserProfile/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                title:"SUCCESSFUL",
                                text:"Profile, successfully set",
                                btnYes:"OK", 
                            }) 
                            this.input.loading = false
                            this.dialogUserInfo = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/CreateUserProfile/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                title:"Error",
                                text:"Error while setting user profile. Please, again later",
                                btnYes:"OK",  
                            })  
                            this.input.loading = false 
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }
                        // user profile
                        if (res.res==='updating/userProfile/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                title:"SUCCESSFUL",
                                text:"Successfully, updated.",
                                btnYes:"OK", 
                            }) 
                            this.input.loading = false
                            this.dialogUserInfo = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/userProfile/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                title:"NOT SUCCESSFUL",
                                text:"Error while updating the user. Please, again later",
                                btnYes:"OK",  
                            })  
                            this.input.loading = false 
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }
                        // user details
                        if (res.res==='updating/userDetails/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                title:"SUCCESSFUL",
                                text:"Successfully, updated.",
                                btnYes:"OK", 
                            }) 
                            this.input.loading = false
                            this.dialogUserAddress = false
                            this.dialogNextOfKin = false
                            this.dialogIdInfo = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/userDetails/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                title:"NOT SUCCESSFUL",
                                text:"Error while updating the user. Please, again later",
                                btnYes:"OK",  
                            })  
                            this.input.loading = false 
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }
    
                         
    
                        
                    },t)
                } catch (error) {
                   this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            VIEW_PAGE(){
                try {
                    let us = this.us
                    let ud = this.ud
                    let password_protected = ud?ud.password_protected:null
    
                    if (!us) {
                        this.ACTIONS.goHistory(-1)
                        return
                    }
                    if (!password_protected) { 
                        let mbs_text = this.MBS.text.phone_number_mast_be_verified(us.phoneNumber)
                        this.ACTIONS.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=VERIFY-PHONE-NUMBER"  
                            }
                        })  
                        this.MBS.actions.setPAGE_LOAD(false)
                        this.BROCK_NO_STATUS=true 
                    }else{
                        this.MBS.actions.setPAGE_LOAD(true) 
                        if (this.BROCK_NO_STATUS) {
                            this.MBS.actions.dialog()
                            this.MBS.actions.progressDialog()
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_PAGE',
                        page:PAGE_NAME, 
                    })
                }
            },
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: {
            us(v){
                this.FEEDBACKS(1)
            },
            ud(v){
                this.FEEDBACKS(1)
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 