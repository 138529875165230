<template> 
    <v-dialog
        v-model="mbs_dialog.show"
        scrollable   
        :overlay="false"
        :max-width="mbs_dialog.size?mbs_dialog.size:700"
        :persistent='mbs_dialog.fixed' 
        transition="dialog-transition" >
        <v-card rounded="lg" v-if="mbs_dialog.show" > 
            <v-card-title class="primary">
                <div class="font-weight-bold b2--text"> 
                    <span >{{mbs_dialog.title}}</span> 
                </div> 
                <v-spacer></v-spacer> 
                <v-btn class="ml-9" @click="BTN_NO(mbs_dialog.action.silent_btn_no)" icon dark><v-icon >close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <br> 
                <v-card flat height="300" v-if="scanning">
                    <v-layout column fill-height align-center justify-center> 
                        <div>
                            <v-icon color="primary" size="100" class="ma-5">mdi-credit-card-scan</v-icon>
                            <v-progress-linear
                                color="primary"
                                indeterminate
                                :rounded="false"
                                height="8"
                            ></v-progress-linear> 
                        </div> 
                    </v-layout>
                </v-card>
                <div v-else>
                    <v-layout   justify-center v-if="mbs_dialog.icon"> 
                        <v-icon size="150">{{mbs_dialog.icon}}</v-icon>
                    </v-layout>
                    <div class="mb-2 mt-5">{{mbs_dialog.text}}</div>
                    <form @submit.prevent="CONFIRM(input.password)"> 
                        <v-layout row wrap class="mx-0 mb-6 mt-2"  > 
                             
                            <!-- //--------ENTER NUMBER FOR ALL USER -->
                            <v-flex xs12 md4 v-if="all_users" >
                                <v-layout class="mr-1"> 
                                    <v-autocomplete
                                        v-model="select.country"
                                        :items="countryNames" 
                                        solo outlined
                                        flat tile  
                                        height="58"
                                        label="Country" >
                                        <template 
                                            slot="selection"
                                            slot-scope="data" >
                                            <v-chip 
                                                :input-value="data.selected"
                                                :disabled="data.disabled"
                                                :key="JSON.stringify(data.item)"
                                                class="v-chip--select-multi"
                                                
                                                @input="data.parent.selectItem(data.item)" >
                                                <v-avatar v-if="select.country!==''" 
                                                    class="mr-2" >
                                                        <span 
                                                            style="font-size:30px"   >
                                                            <flag :iso="select.country.split(':')[0]" />   
                                                        </span> 
                                                </v-avatar>
    
                                                {{ data.item.split(': ')[1] }}
                                            </v-chip> 
                                        </template>
                                    </v-autocomplete>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 md8 v-if="all_users" >
                                <v-text-field class="mr-1" 
                                    filled required 
                                    autocomplete="one-time-code"   
                                    outlined  id="phone_number"  
                                    v-model="inputPhone"
                                    @focus="FOCUS_INPUT({value:'inputPhone',ref:'ref_phone_number'})"
                                    append-icon="phone" 
                                    :label="inputPhone?'':'Phone Number'"></v-text-field> 
                            </v-flex> 
    
                            <!-- //-----------ENTER PASSWORD -->
                            <v-flex xs12 >
                                <v-text-field required prepend-inner-icon="lock"
                                    v-model="input.password"
                                    @focus="FOCUS_INPUT({value:'password',ref:'ref_password'})"
                                    filled outlined
                                    label="" autofocus clearable
                                    :append-icon="psw1 ? 'visibility' : 'visibility_off'"
                                    @click:append="() => (psw1 = !psw1)"
                                    :type="psw1 ? 'password' : 'text'"
                                    autocomplete="one-time-code"  
                                    class="mr-1"  />  
                            </v-flex> 
    
                            <!-- //-----------SHOW KEYBOARD -->
                            <v-flex v-if="CompanySettings?.show_keyboard"  class="mr-2" outlined>  
                                <v-card > 
                                    <mbs-keyboard2 layoutName="appleIOS" @onChange="onChange" @onKeyPress="onKeyPress" /> 
                                </v-card>
                            </v-flex>
    
                            <!-- //------------ACTION BUTTONS -->
                            <v-flex xs12   class="my-3">
                                <v-layout row wrap class="ma-0 pr-1"> 
                                    <v-btn  @click="BTN_NO()"
                                        class="mr-2"
                                        :color="mbs_dialog.btnNoColor?mbs_dialog.btnNoColor:'primary'" 
                                        height="50"  >
                                        <div class="not-f5">
                                            <v-icon class="pr-1 pb-1" mbs_dialog.btnNoIcon>{{mbs_dialog.btnNoIcon}}</v-icon>
                                            {{mbs_dialog.btnNo?mbs_dialog.btnNo:"CANCEL"}}
                                        </div>
                                    </v-btn> 
                                    <v-flex  >
                                        <v-btn 
                                            type="submit"   
                                            :class="all_users?!GET_PHONE_NUMBER(true).valid:false"  
                                            :loading="inputLoading" 
                                            :color="mbs_dialog.btnYesColor?mbs_dialog.btnYesColor:'secondary'" 
                                            height="50" width="100%" >
                                            <div class="not-f5">
                                                <v-icon class="pr-1 pb-1" mbs_dialog.btnYesIcon>{{mbs_dialog.btnYesIcon}}</v-icon>
                                                {{mbs_dialog.btnYes?mbs_dialog.btnYes:'CONFIRM PASSWORD'}}
                                            </div>
                                        </v-btn>      
                                    </v-flex>    
                                </v-layout>
                            </v-flex> 
                            <!-- <v-btn @click="CONFIRM(null,ud)" color="success">text</v-btn> -->
                        </v-layout>   
                    </form> 
                </div>
            </v-card-text>  
        </v-card> 
    </v-dialog>  
</template>

<script>
import {mapState} from "vuex" 
import DATA from '../../plugins/DATA'
let NAME = 'DIALOG_CONFIRM_PASSWORD'
export default {
    props:['notShow'], 
    data(){
        return{
            show:false,
            scanning:false,
            input:{},
            inputPhone:'',
            inputLoading:false,
            psw1:true,
            select:{
                signOption:'',
                accountType:'',
                businessType:'',
                country:''
            },
            focused_field:null
        } 
    }, 
    created() {
        try { 
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN); 
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:NAME, 
            }) 
        }
    },
    mounted(){ 
        this.MBS.actions.cardSwipeAction({page:NAME})
        this.ADD_DEFAULT_COUNTRY()
    }, 
    computed:{
        route(){
            return this.$route
        },
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()], 
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
            mbs_card_swipe: state=> state.mbs['mbs_card_swipe'],
        }),
        mbs_dialog(){ 
            return  this.$store.getters.getMBS_dialog_confirm_password
        },
        btn_no_text(){
            let dialog = this.mbs_dialog 
            let btnNo = dialog?dialog.btnNo:null
            if(!btn){return ""}
             
        },
        all_users(){
            let dialog = this.mbs_dialog
            let action = dialog?dialog.action:null
            return action?action.all_users:null
        },
        get_access(){
            let dialog = this.mbs_dialog
            let action = dialog?dialog.action:null
            return action?action.get_access:null
        },
        phone_number_start(){
            let dialog = this.mbs_dialog
            let action = dialog?dialog.action:null
            return action?action.phone_number_start:null
        },
        countryNames(){
            let names = []
            let countries = this.$store.getters.getCountries 
            for (const key in countries) {
                 const cn = countries[key]
                if (cn.alpha2!== undefined) {
                    names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                } 
            } 
            //names=['Mw: Malawi (+265)'] 
            return names
        }, 
        iLocation(){ 
            const i = this.$store.getters.getIpLocation
            return i
        },
        GET_PHONE_NUMBER(){
            try {
                let us = this.us 
                let input_country = ''+this.select.country
                let input_phone = ''+this.inputPhone 
                let countryNames = this.countryNames 
                let PHONE = this.MBS.phone 
                return all =>{
                    let current_phone = ''+input_phone 
                    let ip_phone = null 
                    let ip_countryPhone = null 

                    if (input_phone) {
                        input_phone = input_phone.replace(/[^0-9+]/g,'')
                    }if (input_country) {
                        input_country = input_country.replace(/[^0-9]/g,'')
                    } 

                    if (input_phone) { 
                        ip_phone = PHONE(input_phone)
                    }if (input_country) { 
                        ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                    } 

                    if (ip_phone) {
                        let possible = ip_phone.g?ip_phone.g.possible:null
                        if (possible) {
                            current_phone = ip_phone.g

                            if (countryNames) { 
                                let regionCode = current_phone.regionCode
                                let countryName = countryNames.find(item=>{
                                    return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                })
                                if (countryName != this.select.country) {
                                    this.select.country = countryName
                                } 
                            }
                        }
                    }
                    if (ip_countryPhone) {
                        let possible = ip_countryPhone.g?ip_countryPhone.g.possible:null
                        if (possible) {
                            current_phone = ip_countryPhone.g
                        }
                    }else{

                    }

                    if (current_phone.valid) {
                        if (this.inputPhone != current_phone.number.international ) {
                            this.inputPhone = current_phone.number.international
                        }
                    }  
                    if (all) {
                        return {
                            ...current_phone,
                            phone:current_phone.number?current_phone.number.e164:''
                        }
                    } else {
                        return current_phone.number?current_phone.number.e164:''
                    } 
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'GET_PHONE_NUMBER',
                    page:PAGE_NAME, 
                })
            }  
        },
    },
    methods:{
        ADD_DEFAULT_COUNTRY(){
            this.select.country = "MW: Malawi (+265)" 
            let phone_number = this.ud?.phone_number
            if (this.phone_number_start && phone_number) {
                this.inputPhone = phone_number
            }
        },
        FOCUS_INPUT(field){
            this.focused_field = field 
        },
        RESET_ALL_DATA(){ 
            try { 
                Object.assign(this.$data, this.$options.data())   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        async CONFIRM(password,user){ 
            try { 
                this.inputLoading = true   
                password = password?this.MBS.crypt.encrypt(password):null 
                let ud = null
                let access_details = null
                let get_access = this.get_access
                let all_users = this.all_users
                let company = this.SELECTED_COMPANY
                let company_key = company?company.company_key:null
                let PHONE = this.GET_PHONE_NUMBER(true)
                let phone_number = PHONE?PHONE.phone:null

                if (all_users) { 
                    // let user = this.$store.getters.getUsersByPhoneNumber(phone_number)
                    let user = this.$store.getters.getCompanyUserByPhoneNumber(phone_number)
                    ud = user
                } else {
                    ud = this.ud
                }
                
                if (user) { 
                    ud = user 
                }else if (!password) { 
                    this.inputLoading = false   
                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                    return
                }else if (!ud) { 
                    this.inputLoading = false  
                    this.MBS.actions.dialog(null,true,false,"ERROR","User not found",null,"OK")
                    return
                }else if (password!==ud.p_uid) { 
                    this.inputLoading = false  
                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                    return
                } else { 
                }  


                 
                /// 
                this.inputLoading = false  
                if(get_access || all_users){
                    let get_access_details = {
                        user:ud, 
                        ...get_access
                    }
                    access_details = await this.GET_USER_ACCESS(get_access_details)
                    let get_company_user_group_name = get_access?.company_user_group_name
                    let access_company_user_group_name = access_details?.company_user_group_name
                    console.log(access_details,'access_details......');

                    if (!access_details?.access) {
                        this.MBS.actions.dialog(null,true,false,"ERROR","Access Denied!...",null,"OK") 
                        return
                    }else if(get_company_user_group_name){
                        if (get_company_user_group_name!=access_company_user_group_name) {
                            this.MBS.actions.dialog(null,true,false,"ERROR","Access Denied!. Require ("+get_company_user_group_name+" permissions)",null,"OK") 
                            return
                        }
                    }
                }
                setTimeout(() => {
                    this.scanning = false  
                    this.MBS.events.$emit('DIALOG_YES',{
                        ...this.mbs_dialog.action,
                        password:password,
                        access_details:access_details,
                        ud:ud,
                        NO:false,
                        YES:true
                    }) 
                    this.$store.dispatch('setMBS_dialog_confirm_password',{})
                }, 500);
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_START_SELLING',
                    page:NAME, 
                }) 
            }  
        },
        BTN_NO(silent_btn_no){ 
            if (!silent_btn_no) {
                this.MBS.events.$emit('DIALOG_YES',{
                    ...this.mbs_dialog.action,
                    NO:true,
                    YES:false
                })   
            }
            this.$store.dispatch('setMBS_dialog_confirm_password',{}) 
        },
        async ON_CARD_SWIPE(code){ 
            try {
                this.scanning = true
                let res = await this.MBS.actions.FIND_COMPANY_USER_BY_CARD_CODE({
                    card_code:code
                })
                let user = res?.user
                let company_user_group_code = user?.company_user_group_code
                setTimeout(() => {
                    this.scanning = false
                    this.CONFIRM(null,user)
                }, 1000);

                console.log(res,'c......................');   
            } catch (error) {
                console.log(error,'error............');
            }
        },
        async GET_USER_ACCESS(payload){ 
            try {
                let permission = this.GET_USER_ACCESS_({
                   ...payload
                }) 
                return permission
            } catch (error) {
                console.log(error,'error............');
            }
        },
        GET_USER_ACCESS_(payload){
            try { 
                let user = this.MBS.actions.JOIN_USER(payload?.user )
                let selectedCompany = this.MBS.actions.selectedCompany
                let company_key = selectedCompany?selectedCompany.key:null
                let companies = user?.companies 
                let company = companies && company_key?companies[company_key]:null
                let company_user_group_code = company?company.company_user_group_code:null
                let user_group_code = user?user.user_group_code:null
                let user_group = this.$store.getters.getUserGroupByCode(user_group_code)
                let company_user_group = this.$store.getters.getCompanyUserGroupByCode(company_user_group_code)

                console.log(user,'user................');
                let router = this.route
                // console.log(router,'router.........');
                let current_page_name = router.name
                let fullPath = router.fullPath
                let links = router.routes
                
                // let current_page_name = router.app.$route.name
                // let fullPath = router.app.$route.fullPath
                // let links = router.options.routes

                // let permissions = this.MBS.actions.currentPlanPermissions  
                let permissions = this.MBS.actions.permissions  
 
                let page_name = payload?payload.page_name:null
                let page_link = payload?payload.page_link:null
                let action_name = payload?payload.action_name:null 
                let permission_type = payload?payload.permission_type?payload.permission_type:'page' :'page' 
                
                

                let NAME = null
                if (!payload) {
                    NAME = current_page_name
                }else if (action_name) {
                    NAME = action_name  
                }else if (page_name) {
                    NAME = page_name  
                }else if (page_link) {
                    let action_link = links?links.find(link=>{
                        return link.path == page_link
                    }):null
                    let action_link_name = action_link?action_link.name:null
                    if (action_link_name) {
                        NAME = action_link_name  
                    } 
                }  

                console.log(NAME,"Name-------------1111");
                console.log(permission_type,"permission_type-------------1111");
                console.log(permissions,"permissions-------------1111");
                console.log(payload,"payload-------------1111");
                if (!NAME) {
                    return {
                        access:false,
                        error:'no name'
                    }
                }
                NAME = this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(NAME))
                let this_permission = permissions?permissions.find(p=>{
                    return (this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(p.name)) == NAME)
                        &&(this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(p.permission_type)) == permission_type)
                }):null

                let access_res={
                    name:NAME,
                    permission_type:permission_type,
                    access:false,
                    user_group:user_group,
                    user_group_name:user_group?.name,
                    company_user_group:company_user_group,
                    company_user_group_name:company_user_group?.name,
                    user_group_code:user_group_code,
                    company_user_group_code:company_user_group_code,
                    permission:this_permission
                }

                if (user) {  
                    if (user.email===DATA.APP.ADMIN_EMAIL) {
                      return {
                        ...access_res,
                        access:true
                      }  
                    } 
                }  
                if(!this_permission){
                    console.log('no permission 2');
                    return access_res
                } 

                console.log(this_permission,"Name-------------22");
                let user_permission = null
                if (this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(this_permission.access_type)) 
                    == 'public') {
                    return true
                }else if (this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(this_permission.access_type)) 
                    == this.MBS.actions.TEXT_SPACE0('public user')) {
                    if (us) {
                        return true
                    } else {
                        return false  
                    } 
                }else if (this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(this_permission.access_type)) 
                    == this.MBS.actions.TEXT_SPACE0('company')) {   
                    let company_user_group_permissions_object = company_user_group?company_user_group.permissions:null
                    let company_user_group_permissions = this.MBS.actions.OBJECT_ITEMS(company_user_group_permissions_object)
                    console.log(company_user_group_code,'code....');
                    console.log(company_user_group,'11....');
                    console.log(company_user_group_permissions_object,'22....');
                    console.log(company_user_group_permissions,'33....');
                    if (company_user_group_permissions) {
                        user_permission = company_user_group_permissions.find(permission=>{
                            return permission.permission_code == this_permission.code
                        })
                    } 
                }else{   
                    let user_group_permissions_object = user_group?user_group.permissions:null
                    let user_group_permissions = this.MBS.actions.OBJECT_ITEMS(user_group_permissions_object)
                    if (user_group_permissions) {
                        user_permission = user_group_permissions.find(permission=>{
                            return permission.permission_code == this_permission.code
                        })
                    } 
                }   
                
                access_res.user_permission=user_permission
                let view_permission = user_permission?user_permission.view_permission:null
                if (view_permission) {
                    console.log('yes permission 1');
                    return {
                        ...access_res,
                        access:true
                    }
                } else {
                    console.log(view_permission,'no permission 3');
                    return {
                        ...access_res,
                        access:false
                    }
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'GET_USER_ACCESS',
                    page:NAME, 
                })
            }

        },

        ///
        onChange(input) {
            // this.input = input;
        },
        onKeyPress(button) { 
            let focused_field = this.focused_field
            const field_value = focused_field?focused_field.value:null 
            if (button ) {
                let value = button
                if (field_value) { 
                    if (field_value == "inputPhone") {
                        if (value=="{bksp}") {
                            this.inputPhone = (""+this.inputPhone).slice(0,-1)
                        } else if (value.indexOf('{')==-1) { 
                            this.inputPhone =this.inputPhone+value 
                        } else { 
                        }
                    }else{ 
                        if(!this.input){this.input={}}
                        if (value=="{bksp}") {
                            this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                        }else if (value.indexOf('{')==-1) { 
                            this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value 
                        }else{ 
                        } 
                        setTimeout(() => {
                            this.input = {...this.input}
                        }, 1); 
                    }
                     
                }
            }
        },
        onInputChange(input) { 
            // this.input = input.target.value;
        },
        DIALOG_YES(action){ 
                try {
                    if (action.code) { 
                        if (action.code ===NAME+'=DISABLE-CARD') {
                            if (action.YES) {
                                setTimeout(() => {   
                                }, this.MBS.data.ACTION_REFRESH_TIME);     
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:NAME, 
                    }) 
                }  
            },  
        ON_QR_SCAN(code){
                try {
                    if (code) {  
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{
                            this.MBS.actions.dialog() 
                            let band1 = code.indexOf("%")!=-1?true:false
                            let band2 = code.indexOf(";")!=-1?true:false
                            let band3 = code.indexOf("+")!=-1?true:false
                            let close = code.slice(-1) 

                            if ((band1||band2||band3) && close=="?") {
                                let first_string = code.split("?")[0]  
                                code = first_string.slice(1) 
                                this.ON_CARD_SWIPE(code)
                            } else {  
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"ERROR",
                                    text:"Please, Scan again.",
                                    btnYes:"OK"
                                })
                            }  
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:NAME, 
                    })  
                } 
            },
    },
    beforeDestroy(){
        try {   
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
            this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
            this.MBS.actions.cardSwipeAction({page:null})
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'beforeDestroy',
                page:NAME, 
            })
        }
    },
    watch:{
        mbs_dialog(value){ 
            let show = value?.show
            if (show) {
                this.MBS.actions.cardSwipeAction({page:NAME})
            } else {
                this.MBS.actions.cardSwipeAction({page:null})
            }
            if (value) {
                this.RESET_ALL_DATA()
                this.ADD_DEFAULT_COUNTRY()
            }
        },   
    } 
}
</script>
