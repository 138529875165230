<template>
    <v-card color="transparent" :width="(key_width+8)*5" flat class="ma-0 ">
        <v-layout column class="ma-0 " justify-center>
            <v-layout  v-for="(keys,index) in KeyColumn(Keys,5)">
                 
                <v-btn  :width="key_width"  
                    class="mr-1 white mb-1  mbs-border" v-for="(key,index) in keys" 
                    @click="ON_KEY(key)"  >
                    {{ key.name }}
                </v-btn> 
            </v-layout>
            <div  v-if="key_function" > 
                <v-layout v-if="type=='number'" > 
                    <v-btn  class="mr-1 mb-1 white" 
                        @click="ON_KEY()" outlined >
                        <v-icon>mdi-symbol</v-icon>
                    </v-btn> 
                    
                    <v-flex>
                        <v-btn width="100%"  class="mr-1 mb-1 white" 
                            @click="ON_KEY({value:' '})" outlined >
                            <!-- <v-icon size="">mdi-keyboard-space</v-icon> -->
                        </v-btn> 
                    </v-flex>
                    <v-btn  class="mx-1 mb-1 white" 
                        @click="ON_KEY()" outlined >
                        <v-icon>mdi-numeric</v-icon>
                    </v-btn> 
                    <v-btn  class="mr-1 mb-1 white" 
                        @click="ON_KEY({value:'BACK_SPACE'})" outlined >
                        <v-icon size="20">mdi-backspace-outline</v-icon>
                    </v-btn> 
                </v-layout>
                <v-layout v-else>
                    <v-btn  class="mr-1 mb-1" 
                        @click="ON_KEY()" outlined >
                        <v-icon >mdi-apple-keyboard-shift</v-icon>
                    </v-btn> 
                    <v-btn  class="mr-1 mb-1" 
                        @click="ON_KEY()" outlined >
                        <v-icon>mdi-symbol</v-icon>
                    </v-btn> 
                    <v-btn  class="mr-1 mb-1" 
                        @click="ON_KEY()" outlined >
                        <v-icon>mdi-numeric</v-icon>
                    </v-btn> 
                    <v-flex>
                        <v-btn width="100%"  class="mr-1 mb-1" 
                            @click="ON_KEY({value:' '})" outlined >
                            <!-- <v-icon size="">mdi-keyboard-space</v-icon> -->
                        </v-btn> 
                    </v-flex>
                    <v-btn  class="mx-1 mb-1" 
                        @click="ON_KEY({value:','})" outlined >
                        ,
                    </v-btn> 
                    <v-btn  class="mx-1 mb-1" 
                        @click="ON_KEY({value:'.'})" outlined >
                        .
                    </v-btn> 
                    <v-btn  class="mr-1 mb-1" 
                        @click="ON_KEY({value:'BACK_SPACE'})" outlined >
                        <v-icon size="20">mdi-backspace-outline</v-icon>
                    </v-btn> 
                </v-layout>
            </div>
        </v-layout> 
    </v-card>
</template>

<script>
    import DATA from '../../plugins/DATA';
    let PAGE_NAME  = "MBS_KEYBOARD" 
    import Keyboard from "simple-keyboard";
    import "simple-keyboard/build/css/index.css";
    
    export default {
        name:PAGE_NAME,
        props:[
            'show','value', 'type','number','key_function', 
            'keys','column','row',
            'key_m','key_h','key_w',  
        ],
        data() {
            return { 
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                number_keys:[
                    {name:"0",value:0},
                    {name:"1",value:1},
                    {name:"2",value:2},
                    {name:"3",value:3},
                    {name:"4",value:4},
                    {name:"5",value:5},
                    {name:"6",value:6},
                    {name:"7",value:7},
                    {name:"8",value:8},
                    {name:"9",value:9}, 
                    {name:".",value:'.'}, 
                    {name:",",value:','}, 
                ],
                phone_keys:[
                    {name:"+",value:'+'}, 
                    {name:"0",value:0},
                    {name:"1",value:1},
                    {name:"2",value:2},
                    {name:"3",value:3},
                    {name:"4",value:4},
                    {name:"5",value:5},
                    {name:"6",value:6},
                    {name:"7",value:7},
                    {name:"8",value:8},
                    {name:"9",value:9},  
                ],
                character_keys:[  
                    {name:"A",value:'A'},  
                    {name:"B",value:'B'},  
                    {name:"C",value:'C'},  
                    {name:"E",value:'E'},  
                    {name:"G",value:'G'},  
                    {name:"H",value:'H'},  
                    {name:"I",value:'I'},  
                    {name:"J",value:'J'},  
                    {name:"K",value:'K'},  
                    {name:"L",value:'L'},  
                    {name:"M",value:'M'},  
                    {name:"N",value:'N'},  
                    {name:"O",value:'O'},  
                    {name:"P",value:'P'},  
                    {name:"R",value:'R'},  
                    {name:"S",value:'S'},  
                    {name:"T",value:'T'},  
                    {name:"U",value:'U'},  
                    {name:"V",value:'V'},  
                    {name:"W",value:'W'},  
                    {name:"X",value:'X'},  
                    {name:"Y",value:'Y'},  
                    {name:"Z",value:'Z'},   
                ], 
                all_keys:[ 
                    {name:"0",value:0},
                    {name:"1",value:1},
                    {name:"2",value:2},
                    {name:"3",value:3},
                    {name:"4",value:4},
                    {name:"5",value:5},
                    {name:"6",value:6},
                    {name:"7",value:7},
                    {name:"8",value:8},
                    {name:"9",value:9},  

                    {name:"A",value:'A'},  
                    {name:"B",value:'B'},  
                    {name:"C",value:'C'},  
                    {name:"E",value:'E'},  
                    {name:"G",value:'G'},  
                    {name:"H",value:'H'},  
                    {name:"I",value:'I'},  
                    {name:"J",value:'J'},  
                    {name:"K",value:'K'},  
                    {name:"L",value:'L'},  
                    {name:"M",value:'M'},  
                    {name:"N",value:'N'},  
                    {name:"O",value:'O'},  
                    {name:"P",value:'P'},  
                    {name:"R",value:'R'},  
                    {name:"S",value:'S'},  
                    {name:"T",value:'T'},  
                    {name:"U",value:'U'},  
                    {name:"V",value:'V'},  
                    {name:"W",value:'W'},  
                    {name:"X",value:'X'},  
                    {name:"Y",value:'Y'},  
                    {name:"Z",value:'Z'},   
                ], 
                symbol_keys:[  
                    {name:".",value:'.'},  
                    {name:",",value:','},  
                    {name:"-",value:'-'},  
                    {name:"+",value:'+'},  
                    {name:"*",value:'*'},  
                    {name:"%",value:'%'},  
                    {name:"@",value:'@'},  
                    {name:"?",value:'?'},  
                ], 
                key_width:60,
                capsLock:true,
                numberLock:false,
                symbolLock:false,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {   
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  

            Keys(){  
                let number_keys = this.number_keys
                let phone_keys = this.phone_keys
                let character_keys = this.character_keys
                let all_keys = this.all_keys
                let type = this.type
                let keys = all_keys
                if (type=="number") {
                    keys = number_keys
                }if (type=="phone") {
                    keys = phone_keys
                }if (type=="text") {
                    keys = character_keys
                }
                return keys
            },   
            KeyRows(){   
                return (keys,rows)=>{
                    if(!keys){return null}
                    const length = keys.length;
                    const chunkSize = Math.ceil(length / rows); // Calculate the size of each chunk

                    const dividedArray = [];

                    for (let i = 0; i < length; i += chunkSize) {
                        const chunk = keys.slice(i, i + chunkSize); // Extract a chunk of the original array
                        dividedArray.push(chunk); // Add the chunk to the dividedArray
                    }
                    console.log(chunkSize,"chunkSize,,,,,,,");
                    console.log(dividedArray,'dividedArray...');

                    return dividedArray; 
                }
            }, 
            KeyColumn(){   
                return (keys,columns)=>{
                    if(!keys){return null}
                    const length = keys.length;  
                    const dividedArray = [];

                    for (let i = 0; i < length; i += columns) {
                        const group = keys.slice(i, i + columns); // Extract a group of 4 elements from the original array
                        dividedArray.push(group); // Add the group to the dividedArray
                    } 
                    return dividedArray; 
                }
            }, 
              
        },
        methods: {   
            ON_KEY(key){
                try {    
                    setTimeout(() => { 
                        this.$emit("input",key)
                        this.MBS.events.$emit('MBS_KEYBOARD',key) 
                    }, 0); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            
            //---------------[MAIN FUNCTIONS]------------  
            DIALOG_YES(action){ 
                try {
                    if (action.code) {  
                        if (action.code ===PAGE_NAME+'=AUTO-GENERATE-CODE') { 
                            if (action.YES) {
                                this.EXCEL_DATA_ITEMS= this.MBS.actions.CODE_AUTO_GENERATE(this.EXCEL_DATA_ITEMS) 
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=AUTO-SET-CODE') { 
                            if (action.YES) {
                                this.inputCodeLoading = true
                                setTimeout(() => { 
                                    this.setAutoCode()
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        },
        watch: { 
            us(v){ 
            },   
        },
  }
</script>