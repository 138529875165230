<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card >  
                        <!-- <mbs-page-tab 
                            :items="MY_LINKS"/>  -->
                        <mbs-item-manager
                            :table_title="'Maternity Ward'"
                            :table_title_icon="'mdi-human-pregnant'"
                            :add_vertical_lines="true"
                            v-model="table_input"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEMS"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"  
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="PatientOrdersData"   
                            :table_tabs="TabItems"   
                            :is_joined="true"  
                            :path_item="PATH_ITEM"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                            @on_row_clicked="VIEW_ORDER"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
            <div>
                <!-- View ORDER  DIALOG -->
                <v-dialog
                    v-model="dialog_view_order"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="700px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" v-if="dialog_view_order" class="b1" > 
                        <v-card-title class="primary">
                            <v-layout class="font-weight-bold b2--text" align-center> 
                                <v-avatar   size="18" class="white mr-2">
                                    <v-avatar  
                                        size="16"
                                        :color="FindStageColor(SELECTED_ORDER.stage)" />  
                                </v-avatar>
                                <span >PATIENT</span> 
                                <span class="mx-1">@</span>  
                                <span class="mr-1">{{ MBS.actions.TEXT_UP(FindStageName(SELECTED_ORDER.stage)) }}</span>     
                                <v-icon dark class="mx-2" v-if="SELECTED_ORDER.medical_scheme_key">mdi-medical-bag</v-icon>
                            </v-layout> 
                            <v-spacer></v-spacer> 
                            <v-btn class="ml-9" @click="dialog_view_order=false" icon dark><v-icon >close</v-icon></v-btn>
                        </v-card-title>    
                        <v-card-text class="b1">
                            <br>
                            <v-card outlined>
                                <v-card-text> 
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-btn outlined large icon ><v-icon>mdi-human-wheelchair</v-icon></v-btn>
                                            </v-list-item-action>
                                            <v-list-item-content >
                                                <v-list-item-title class="pointer click " @click="MBS.actions.copyText(SELECTED_ORDER.customer_name,true)">{{ SELECTED_ORDER.customer_name }}</v-list-item-title>
                                                <v-list-item-subtitle class="pointer click " @click="MBS.actions.copyText(SELECTED_ORDER.customer.phone_number,true)" v-if="SELECTED_ORDER.customer">{{ SELECTED_ORDER.customer.phone_number }}</v-list-item-subtitle>
                                                <v-list-item-subtitle class="font-weight-bold not-f2" v-if="SELECTED_ORDER.customer">{{ SELECTED_ORDER.customer.location_address }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <div class="text-right">
                                                    <div class="secondary--text font-weight-bold">{{ SELECTED_ORDER.gender }}</div>
                                                    <div>{{ SELECTED_ORDER.age }} Years Old</div>
                                                </div>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-divider v-if="SELECTED_ORDER.expand_details"></v-divider>
                                        <v-list-item @click="
                                                SELECTED_ORDER.expand_details=!SELECTED_ORDER.expand_details 
                                                if (SELECTED_ORDER.expand_items) {
                                                    SELECTED_ORDER.expand_items=false
                                                }
                                            ">
                                            <v-list-item-content><div class="font-weight-bold">Order Details</div></v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn  icon>
                                                    <v-icon v-if="SELECTED_ORDER.expand_details">mdi-chevron-up</v-icon>
                                                    <v-icon v-else>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>  
                                    <div v-if="SELECTED_ORDER.expand_details" class="px-3" > 
                                        <v-layout align-center class="my-1">
                                            <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                                            <div>Initiated Time:</div>
                                            <div class="ml-2">{{ SELECTED_ORDER.created_at_ }}</div>
                                        </v-layout> 
                                        <v-layout align-center class="my-1 click pointer" @click="MBS.actions.copyText(SELECTED_ORDER.key,true)">
                                            <v-icon class="mr-2">mdi-receipt</v-icon>
                                            <div>Order Number:</div>
                                            <div class="ml-2">{{ SELECTED_ORDER.key }}</div>
                                            <!-- <v-icon color="secondary" class="ml-1 mb-2" size="17">mdi-clipboard-text-multiple</v-icon> -->
                                        </v-layout>
                                        <v-layout align-center class="my-1">
                                            <v-icon class="mr-2">mdi-medical-bag</v-icon>
                                            <div>Medical Scheme:</div>
                                            <div class="ml-2">{{ SELECTED_ORDER.medical_scheme_key}}</div>
                                        </v-layout>
                                        <v-layout align-center class="my-1">
                                            <v-icon class="mr-2">mdi-list-status</v-icon>
                                            <div>Status:</div>
                                            <div class="ml-2">{{ SELECTED_ORDER.order_status }}</div>
                                        </v-layout>
                                        <v-layout align-center class="my-1">
                                            <v-icon class="mr-2">mdi-cash-register</v-icon>
                                            <div>Initiated By:</div>
                                            <div class="ml-2">{{ SELECTED_ORDER.teller_name }}</div>
                                        </v-layout> 
                                        <v-layout column class="my-1  ">
                                            <!-- <v-icon class="mr-2">mdi-account-alert</v-icon> -->
                                            <v-card class="pa-" flat>
                                                <mbs-input-fields 
                                                        v-model="input"
                                                        :field_items="patient_description"
                                                    />
                                                
                                            </v-card>
                                            <!-- <div>Description:</div>
                                            <div class="ml-2">{{ SELECTED_ORDER.description }}</div>
                                            <div v-if="input">
                                                
                                            </div> -->
                                        </v-layout> 
                                    </div>
                                </v-card-text> 
                            </v-card> 
                        </v-card-text>  
                        <v-card-actions class="mr-2 mb-2 ">
                            <v-spacer/> 
                            <!-- v-model="menuPaymentOptions"  -->
                            <v-menu v-if="MBS.actions.SIZE(hospitalStages)>0"
                                :close-on-content-click="true" 
                                offset-y  offset-overflow
                                :nudge-width="90" rounded="xl"
                                >
                                <template v-slot:activator="{ on }">  
                                    <v-btn v-on="on"  color="primary" value="cancel">
                                        <v-icon right>
                                            mdi-account-arrow-right
                                        </v-icon>
                                        <span class="hidden-sm-and-down">Move Patient</span> 
                                        <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                    </v-btn>  
                                </template> 

                                <v-card>
                                    <v-card-title primary-title class="primary white--text"  >
                                        Move Patient to:
                                    </v-card-title>
                                    <v-list>
                                        <v-list-item v-for="(stage, index) in hospitalStages" 
                                        :key="index" @click="CONFIRM_UPDATING_ORDER(SELECTED_ORDER,{
                                                stage:stage.key,
                                                lab_test_inf:input.lab_test_inf,
                                                description:input.description,
                                            })">
                                            {{stage.name}}
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-menu>  
                            <v-btn text @click="dialog_view_order=false" color="primary" >
                                Cancel</v-btn>
 
                        </v-card-actions>
                    </v-card> 
                </v-dialog> 
                
                <!-- CHECK IN PATIENT  DIALOG -->
                <v-dialog
                    v-model="dialog_checkin_patient"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="700px"
                    transition="dialog-transition" >
                    <form @submit.prevent="CONFIRM_CHECKIN_PATIENT(SEARCHED_PATIENT,{order_status:'checkin'},input)"> 
                        <v-card rounded="lg" v-if="dialog_checkin_patient" > 
                            <v-card-title class="primary">
                                <div class="font-weight-bold b2--text"> 
                                    <span >CHECK-IN PATIENT</span> 
                                </div> 
                                <v-spacer></v-spacer> 
                                <v-btn class="ml-9" @click="dialog_checkin_patient=false" icon dark><v-icon >close</v-icon></v-btn>
                            </v-card-title>    
                            <v-card-text class="b1">
                                <br>
                                <v-card outlined>
                                    <v-card-text> 
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-btn outlined large icon ><v-icon>mdi-human-wheelchair</v-icon></v-btn>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ SEARCHED_PATIENT.name }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ SEARCHED_PATIENT.phone_number }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <div>
                                                        <div>{{ SEARCHED_PATIENT.gender }}</div>
                                                        <div>{{ SEARCHED_PATIENT.age }} Years Old</div>
                                                    </div>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list> 
                                        <v-list>
                                            <v-list-item @click="
                                                        SELECTED_ORDER.expand_details=!SEARCHED_PATIENT.expand_details 
                                                        if (SEARCHED_PATIENT.expand_items) {
                                                            SEARCHED_PATIENT.expand_items=false
                                                        }
                                                    ">
                                                <v-list-item-content><div class="font-weight-bold">Patient Details</div></v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn  icon>
                                                        <v-icon v-if="SEARCHED_PATIENT.expand_details">mdi-chevron-up</v-icon>
                                                        <v-icon v-else>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list> 
                                        <div v-if="SEARCHED_PATIENT.expand_details"> 
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-receipt</v-icon>
                                                <div>Order Number:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.key }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-cash-register</v-icon>
                                                <div>Initiated By:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.teller_name }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                                                <div>Initiated Time:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.created_at_ }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-timer-settings-outline</v-icon>
                                                <div>Estimated Time:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.estimated_time?SEARCHED_PATIENT.estimated_time:"..." }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">mdi-list-status</v-icon>
                                                <div>Status:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.order_status }}</div>
                                            </v-layout>
                                            <v-layout align-center class="my-1">
                                                <v-icon class="mr-2">home</v-icon>
                                                <div>Description:</div>
                                                <div class="ml-2">{{ SEARCHED_PATIENT.description }}</div>
                                            </v-layout> 
                                        </div> 
                                    </v-card-text> 
                                </v-card>

                                <!-- <v-card class="mt-2 py-4 px-4" outlined> 
                                    <mbs-input-fields
                                        v-model="input"
                                        :update_item="true" 
                                        :field_items="patient_checkin_fields"
                                        :select_items="Selectors" 
                                        />
                                </v-card> -->

     
                            </v-card-text>  
                            <!-- <v-card-actions>
                                <v-spacer/> 
                                <v-btn-toggle  dark  class="mb-2 mr-2" >
                                    <v-btn  @click="dialog_checkin_patient=false"  color="primary" value="exit">
                                        <span class="hidden-sm-and-down mx-3">Cancel</span>
                                        <v-icon>mdi-close</v-icon> 
                                    </v-btn> 
                                    <v-btn type="submit"  color="primary" value="cancel">
                                        <span class="hidden-sm-and-down">Checkin</span> 
                                        <v-icon right>
                                            mdi-head-check
                                        </v-icon>
                                    </v-btn>  
                                </v-btn-toggle>
                            </v-card-actions> -->
                        </v-card> 
                    </form>
                </v-dialog> 
                
                <!-- SCAN PATIENT -->
                <v-dialog
                    v-model="dialog_search_patient"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" v-if="dialog_search_patient" > 
                        <!-- <v-card-title class="primary">
                            <div class="font-weight-bold b2--text"> 
                                <span >CHECK-IN PATIENT</span> 
                            </div> 
                            <v-spacer></v-spacer> 
                            <v-btn class="ml-9" @click="dialog_search_patient=false" icon dark><v-icon >close</v-icon></v-btn>
                        </v-card-title>     -->
                        <v-card-text class="b1">
                            <v-card height="300" class="mt-9 mb-4 mx-2" outlined>
                                <v-layout fill-height align-center justify-center column>
                                    <div class="mb-9"> 
                                        <v-toolbar @click=" " rounded="" dark flat 
                                            :color="'primary'" 
                                            class="ma-">
                                            <v-autocomplete  class="mr-"
                                                v-model="search_item" 
                                                :items="CustomersData" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                :label="'Search for Patient'"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                :filter="(item, queryText, itemText) => { 
                                                    let index = (a,b)=>{
                                                            if(!a||!b){return false}
                                                            a = ''+a; b = ''+b
                                                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                        } 
                                                        let first = index(itemText,queryText)
                                                        let second = item?index(item.code,queryText):false
                                                        let third = item?index(item.brand_name,queryText):false
                                                        let forth = item?index(item.category_name,queryText):false
                                                        if(!item){return first}  
                                                        return first || second || third || forth 
                                                }"
                                                >
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>mdi-human-wheelchair</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-html="data.item.phone_number"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-html="data.item.next_of_kin_name"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>   
                                        </v-toolbar> 
                                    </div>

                                    <v-icon size="80">mdi-barcode-scan</v-icon>
                                </v-layout> 
                            </v-card>
                             
                        </v-card-text>  
                    </v-card> 
                </v-dialog>  

                <div v-if="ADDING_ITEM">
                    <mbs-item-manager 
                        :show="false" 
                        :add_item_action="ADDING_ITEM.add_item_action"
                        :name_items="ADDING_ITEM.names"
                        :name_item="ADDING_ITEM.name"
                        :value_items="ADDING_ITEM.values"
                        :value_item="ADDING_ITEM.value"
                        :field_items="ADDING_ITEM.fields"   
                        :path_item="ADDING_ITEM.path"  
                        :select_items="Selectors"  
                        @ADDED="ADDED" 
                    /> 
                </div>
            </div>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = 'DENTAL_DEPARTMENT' 
    export default {
        name:PAGE_NAME, 
        props:['id','order_code'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                input:null, 
                input:null, 
                table_input:null, 
                search_item:null, 
                SELECTED_ORDER:null, 
                SEARCHED_PATIENT:null, 
                SELECTED_PATIENT:null, 
                dialog_view_order:false, 
                dialog_search_patient:false, 
                dialog_checkin_patient:false, 
                menuPaymentOptions:false, 
                NAME_ITEMS:DATA.ITEMS.PATIENTS.names,
                NAME_ITEM:DATA.ITEMS.PATIENTS.name,
                VALUE_ITEMS:DATA.ITEMS.PATIENTS.values,
                VALUE_ITEM:DATA.ITEMS.PATIENTS.value,       
                table_actions:[ 
                    // {type:"action",btn:true,icon:"mdi-head-check",action:"checkin_patient",color:"secondary", outlined:false,  text:"Check-in Patient"},   
                    // {type:"action",btn:true,icon:"mdi-human-wheelchair",action:"register_patient",color:"secondary", outlined:false,  text:"Register New Patient"},   
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                DATA:DATA,
                ADDING_ITEM:null,  
                patient_checkin_fields:[  
                    {type:"autocomplete",value:"medical_scheme_key",label:"Select Scheme",label_top:"Patient Medical Scheme (Optional)",
                        select:{
                            name:'MedicalSchemes',
                            value:'key',
                            text:'name'
                        },
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-medical-bag',
                                tile:true
                            },
                            item_title:['name'],
                            item_subtitle:['full_name']
                        }, 
                    required:false,sm:12,md:12,step_name:"Name"},     
                    {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12,step_name:"Details"},   
                ], 
                patient_description:[      
                    {type:"textarea",value:"lab_test_inf",label:"Scanning info",label_tops:"Description (Optional)",
                    prepend_icon:'mdi-test-tube',required:false,sm:12,md:12,step_name:"Details",autofocus:true}, 
                    {type:"textarea",value:"description",label:"Description",label_tops:"Description (Optional)",
                    prepend_icon:'mdi-account-alert',required:false,sm:12,md:12,step_name:"Details",autofocus:false},   
                ], 
                all_stages:[
                    {code:1,color:'brown',key:"checkin",name:"Checkin"},
                    {code:2,color:'red',key:"waiting_triage",name:"Waiting for Triage"}, 
                    {code:2,color:'red',key:"waiting_scanning",name:"Waiting for Scanning"}, 
                    {code:2,color:'yellow',key:"scanning_started",name:"Scanning Started"}, 
                    {code:2,color:'yellow',key:"lab_test_started",name:"Lab Test Started"}, 
                    {code:2,color:'yellow',key:"triage",name:"Triage"}, 
                    {code:4,color:'red',key:"waiting_consultation",name:"Wait for Consultation"},
                    {code:4,color:'red',key:"waiting_consultation",name:"Wait for Consultation"},
                    {code:4,color:'blue',key:"consultation",name:"Consultation"},
                    {code:5,color:'green',key:"pharmacy",name:"Pharmacy"},
                    {code:6,color:'primary',key:"discharged",name:"Discharged"},
                ]
            }
        },
        created() {
            try {
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN); 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: { 
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({ 
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 

                Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()],
                JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                PatientOrders: state=> state.items[(DATA.ITEMS.PATIENT_ORDERS.values).toUpperCase()],
                JoinedPatientOrders: state=> state.join[(DATA.ITEMS.PATIENT_ORDERS.values).toUpperCase()],
                PatientHistories: state=> state.items[(DATA.ITEMS.PAGE_HISTORIES.values).toUpperCase()],
                JoinedPatientHistories: state=> state.join[(DATA.ITEMS.PAGE_HISTORIES.values).toUpperCase()],

            }),   
            ItemOrdersData(){  
                return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
            },
            PatientOrdersData(){  
                console.log(this.JoinedPatientOrders,'this.PatientOrders.......-');
                return this.JoinedPatientOrders?this.JoinedPatientOrders:this.PatientOrders
            }, 
            PatientHistoriesData(){  
                return this.JoinedPatientHistories?this.JoinedPatientHistories:this.PatientHistories
            },  
            LocalItemOrdersData(){  
                return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
            },  
            AllItemOrders(){  
                let local = this.LocalItemOrdersData
                let live = this.ItemOrdersData 
                return live
            },  
            ThisItemOrders(){  
                let item_orders = this.AllItemOrders
                let orderOption = this.ThisOrderOption
                let order_option_key = orderOption?orderOption.key:null 
                if(!item_orders || !order_option_key){return null}
                let item = item_orders.filter(order=>order.order_option_key == order_option_key) 
                return item
            },
            ItemOrderOptionsData(){  
                return this.JoinedItemOrderOptions?this.JoinedItemOrderOptions:this.ItemOrderOptions
            }, 
            TabItems(){  
                return [
                    // {na
                    {name:"Waiting",show_if:(this_,item)=>{  
                            const index = item.stage?item.stage.indexOf('waiting_maternity'):-1
                            return index!=-1
                        },indicator:{
                        color:'error',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }}, 
                    {name:"Started",show_if:(this_,item)=>{  
                            const index = item.stage?item.stage.indexOf('maternity_started'):-1
                            return index!=-1 
                        },indicator:{
                        color:'yellow',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                    {name:"Completed",show_if:(this_,item)=>{  
                            return item.stage=='maternity_completed'
                        },indicator:{
                        color:'blue',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }}, 
                    {name:"Pharmacy",show_if:(this_,item)=>{  
                            return item.stage=='pharmacy'
                        },indicator:{
                        color:'success',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }}, 
                    {name:"Discharged",show_if:(this_,item)=>{  
                            return item.stage=='Discharged'
                        },indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }}, 
                ]
            },  
            hospitalStages(){
                let input = this.table_input
                let tab = input?input.current_table_tab:null
                let stages = null
                if (tab==0) {
                    stages = [  
                        {code:4,key:"maternity_started",name:"Start"},
                        {code:5,key:"maternity_completed",name:"Complete"}, 
                        {code:5,key:"pharmacy",name:"Pharmacy"}, 
                    ] 
                } else if (tab==1) {
                    stages = [  
                        {code:5,key:"maternity_completed",name:"Complete"},
                        {code:5,key:"maternity_completed",name:"Discharged"},
                        {code:5,key:"pharmacy",name:"Pharmacy"}, 
                    ] 
                } else if (tab==2) {
                    stages = [   
                        {code:5,key:"dental_completed",name:"Discharged"},
                        {code:5,key:"pharmacy",name:"Pharmacy"}, 
                    ] 
                } else {
                    stages = [  
                    ] 
                }  
                return stages
            },
            FindStage(){ 
                return key=>{
                    return this.all_stages.find(stage=>stage.key==key)
                }
            },
            FindStageName(){ 
                return key=>{
                    return this.FindStage(key)?this.FindStage(key).name:'...'
                }
            },
            FindStageColor(){ 
                return key=>{
                    return this.FindStage(key)?this.FindStage(key).color:''
                }
            },
            route(){
                return this.$route
            },
            route(){
                return this.$route
            },
            
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("orders",1,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.patient_orders,true) 
                return path
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",tile:true,icon:"mdi-human-wheelchair",size:35,show:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Time",value:'local_created_at_',show:true},         
                    {id:0,name:"Gender",value:"gender",align:"left",show:true},       
                    {id:0,name:"Age",value:"age",align:"center",show:true},       
                    {id:0,name:"Name",value:"customer_name",show:true},       
                    {id:0,name:"Status",value:"stage",show:false},       
                    {id:0,name:"estimated",value:"estimated_time",show:false},       
                    {id:0,name:"Description",value:"description",show:false},       
                    {id:0,name:"update",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Order",action:"view_order",icon:"mdi-database-eye"}, 
                        // {name:"Update Estimated Time",action:"update_estimated",icon:"mdi-timer-sync-outline"}, 
                        {name:"Flag as Processing",action:"flag_as_processing",icon:"mdi-blender"}, 
                        {name:"Flag as Completed",action:"flag_as_completed",icon:"mdi-coffee-maker-check-outline"}, 
                        // {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },  
            Selectors(){ 
                let Customers = this.CustomersData  
                return  {   
                    Customers: Customers, 
                }
            }, 
            CustomersData(){  
                let Customers = this.Customers
                let JoinedCustomers = this.JoinedCustomers 
                return  JoinedCustomers?JoinedCustomers:Customers  
            } 
        },
        methods: { 
            copyText(textToCopy) { 
                // const tempInput = document.createElement("input");
                // tempInput.value = textToCopy;
                // document.body.appendChild(tempInput);
                // tempInput.select();
                // document.execCommand("copy");
                // document.body.removeChild(tempInput);

                // // Optionally, provide user feedback after copying
                // alert("Text copied to clipboard: " + textToCopy);

                console.log(navigator,'navigator....');

                navigator.clipboard.writeText(textToCopy).then(() => {
                    // alert("Text copied to clipboard: " + textToCopy);
                    this.MBS.actions.snackbar({
                        show:true,
                        time:2000,
                        color:'secondary',
                        y:'bottom',
                        text:"Text copied to clipboard: " + textToCopy,

                    })
                }).catch((error) => {
                    console.error("Failed to copy text: ", error);
                });
            },
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Orders","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "register_patient") {
                        this.REGISTER_PATIENT()
                    }else if (action == "checkin_patient") {
                        this.SELECTED_ORDER={}
                        this.dialog_search_patient = true
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item,option){
                try {     
                    if (action == "edit_item") {
                        this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                        this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                        this.ADDING_ITEM.fields =  DATA.FIELDS.items
                        this.ADDING_ITEM.add_item_action =  {
                            action_do:'Edit',
                            action_doing:'Editing'
                        }
                    }else if (action == "view_order") {
                        this.VIEW_ORDER(item)
                    }else if (action == "flag_as_processing") {
                        this.CONFIRM_UPDATING_ORDER(item,{order_status:"processing"})
                    }else if (action == "flag_as_completed") {
                        this.CONFIRM_UPDATING_ORDER(item,{order_status:"completed"})
                    }else{
                        console.log(action,item,option,'action,item,option..........');
                        this.MBS.actions.dialog(null,true,false,"Coming Soon!..","Current not working. Try again later.",null,"OK") 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            VIEW_ORDER(order){
                try {     
                    this.SELECTED_ORDER = {...order,expand_items:true,expand_details:false}
                    this.input={
                        description:order?order.description?order.description:' ':' ',
                        lab_test_inf:order?order.lab_test_inf?order.lab_test_inf:' ':' '
                    }
                    this.dialog_view_order = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_UPDATING_ORDER(order,data){
                try {     
                    let key=order?order.key:null
                    // let day_shift_key=order?order.day_shift_key:null 
                    let new_data = { 
                        ...data
                    }
                    let mbs_text = this.MBS.text.item_action("Patient","Move")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=UPDATE-ITEM-ORDER",
                            data:new_data,
                            path:this.PATH_ITEM+"/"+order.key
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_CHECKIN_PATIENT(order,data){
                try {     
                    let key=order?order.key:null
                    // let day_shift_key=order?order.day_shift_key:null 
                    let moment = this.MBS.date.moment
                    let local_created_at = moment?moment().valueOf():null
                    let new_data = { 
                        key:key,
                        stage:'checkin',
                        local_created_at:local_created_at
                    }
                    let mbs_text = this.MBS.text.item_action("Patient","Checkin")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,  
                        btnNo:mbs_text.btnNo,  
                        action:{
                            code:PAGE_NAME+"=CHECKIN-PATIENT",
                            data:new_data,
                            path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.patient_orders,true)+key
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RELOAD_ITEM_ORDERS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEM_ORDERS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEM_ORDERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEM_ORDERS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },

            
            //ADDING ITEMS
            REGISTER_PATIENT(input){
                try {  
                    this.ADDING_ITEM = {...DATA.ITEMS.PATIENTS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.customers,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.patients
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Register New',
                        action_doing:'Registering New'
                    }  
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.PATIENTS.name,
                            action:"add_item",
                            input:{name:input}
                        }) 
                        
                    }, 50);

                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REGISTER_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADDED(res){
                try { 
                    // this.RELOAD_DATA()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SEARCH_PATIENT(key,searched_patient){
                try { 
                    this.search_item=""
                    if (!searched_patient) {
                        const patients = this.CustomersData
                        if (!patients) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"No Patients",
                                text:"We find no patient. Please make sure that you are connected and try again or register patients.",
                                btnYes:"Refresh & Try Again",
                                btnNo:"Register Patient",
                                action:{
                                    code:""
                                }
                            })
                            return 
                        }
                        searched_patient = patients.find(patient=>patient.key==key) 
                    }
                    this.SEARCHED_PATIENT = searched_patient
                    this.dialog_search_patient = false
                    this.dialog_checkin_patient = true
 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SEARCH_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            FIND_CODE(code){
                try { 
                    const patients = this.CustomersData
                    const orders = this.PatientOrders
                    const histories = this.PatientHistories
                    console.log(code,'code.....');
                    console.log(patients,'patients.....');
                    console.log(orders,'orders.....');

                    if (patients) {
                        let patient = patients.find(item=>item.code == code)
                        if (patient) {
                            this.SEARCH_PATIENT(null,patient)
                        }
                    }
                    
                    if (orders) {
                        let order = orders.find(item=>item.key == code)
                        if (order) {
                            this.VIEW_ORDER(order) 
                        }
                    }
 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SEARCH_PATIENT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            ON_QR_SCAN(code){
                try {
                    if (code) {  
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 

                        }else{ 
                            this.FIND_CODE(code)
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=UPDATE-ITEM-ORDER') {
                            if (action.YES) {  
                                setTimeout(() => { 
                                    this.dialog_view_order=false
                                    let path = action.path 
                                    this.$store.dispatch("fi_update",{
                                        action:this.VALUE_ITEM,
                                        path:path,
                                        data:action.data, 
                                    }).then(res=>{
                                        let mbs_text = this.MBS.text.item_action_success("ORDER","Updated")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        let mbs_text = this.MBS.text.item_action_fail("ORDER","Updated")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=CHECKIN-PATIENT') {
                            if (action.YES) {  
                                setTimeout(() => { 
                                    this.dialog_view_order=false
                                    let path = action.path 
                                    // console.log(action,'action.....');
                                    // return
                                    this.$store.dispatch("fi_update",{
                                        action:this.VALUE_ITEM,
                                        path:path,
                                        data:action.data, 
                                    }).then(res=>{
                                        let mbs_text = this.MBS.text.item_action_success("Patient","Checkin")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                        this.dialog_checkin_patient = false
                                    }).catch(error=>{
                                        let mbs_text = this.MBS.text.item_action_fail("Patient","Checkin")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEM_ORDERS()
                            } else {
                                
                            }
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            beforeDestroy(){
                try {   
                    this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                    this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'beforeDestroy',
                        page:PAGE_NAME, 
                    })
                }
            },
            FEEDBACKS(time){  
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        watch: {  
            search_item(value){ 
                if (value) {
                    this.SEARCH_PATIENT(value) 
                }
            }, 
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
