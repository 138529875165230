<template>
    <div>
        <v-menu 
            v-model="menuDateSelect" 
            :close-on-content-click="false" 
            offset-y  offset-overflow
            :nudge-width="100" rounded="xl"
            >
            <template v-slot:activator="{ on, attrs }"> 
                <v-card   v-on="on" class="transparent"
                    width="100%" min-width="150"
                     min-height="20"  flat >
                    <v-layout fill-height align-center class="ma-0  ">
                        <v-icon class="mr-2" :color="icon_color">today</v-icon>
                        <div :class="color?color+'--text':'grey--text '" class="pb-1">
                            <div :class="title_color?title_color+'--text':''"  class="font-weight-bold not-f2 ">
                                <div v-if="difference==0">Today</div>
                                <div v-else-if="difference==1">Yesterday</div>
                                <div v-else>Last {{difference}} Days</div>
                            </div> 
                            <div 
                                class="font-weight-bold not-f3  text--darken-1">
                                {{moment(fromDate).format('Do, MMM YYYY')}} -
                                {{moment(toDate).format('Do, MMM YYYY')}}
                            </div> 
                        </div>
                       <v-icon class="ml-2" :color="icon_color">arrow_drop_down</v-icon>
                    </v-layout>
                    <!-- <div class="mt- mx-"><hr></div>  -->
                </v-card> 
            </template> 

            <v-card  v-if="ud"
                color="lighten-1" class="">   
                <v-toolbar tile flat color="secondary">
                    <div :class="'white--text pl-3'">
                        <div>
                            <div v-if="DIFF(inputFromDate,inputToDate)==0">Today</div>
                            <div v-else-if="DIFF(inputFromDate,inputToDate)==1">Yesterday</div>
                            <div v-else>Last {{DIFF(inputFromDate,inputToDate)}} Days</div>
                        </div> 
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="" dark text @click="DEFAULT()">Default</v-btn>
                </v-toolbar>
                
                <!-- //LAST DAYS -->
                <v-list dense class="mx-5">
                    <div  v-for="(item,index) in OptionalDays" :key="index" > 
                        <v-list-item @click="CHANGE_DATE(item)">
                            <div :class="DIFF(inputFromDate,inputToDate)==item.days?'secondary--text':''">{{item.name}}</div>
                        </v-list-item> 
                    </div> 
                </v-list>

                <div class="mx-5 mb-5">
                    <v-menu
                        ref="menuFromDate"
                        v-model="menuFromDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y 
                        max-width="290px"
                        min-width="auto" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-card v-on="on"  color="primary" dark class="px-3 py-1 mb-1">
                                <v-layout align-center class="ma-0">
                                    <div>
                                        <div class="font-weight-bold not-f2">From</div>
                                        <div>{{moment(inputFromDate).format('dddd, Do, MMMM YYYY')}}</div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-icon>today</v-icon> 
                                </v-layout> 
                            </v-card> 
                        </template>
                        <v-date-picker
                            v-model="inputFromDate" color="secondary"
                            no-title event-color="primary"
                            :max="max_from_date"
                            :min="min_from_date"
                            @input="menuFromDate = false"
                        ></v-date-picker>
                    </v-menu>
                    <v-menu
                        ref="menuFromDate"
                        v-model="menuToDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y 
                        max-width="290px"
                        min-width="auto" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-card v-on="on"     color="primary" dark class="px-3 py-1 mb-2">
                                <v-layout align-center class="ma-0">
                                    <div>
                                        <div class="font-weight-bold not-f2">To</div>
                                        <div>{{moment(inputToDate).format('dddd, Do, MMMM YYYY')}}</div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-icon>today</v-icon> 
                                </v-layout> 
                            </v-card> 
                        </template>
                        <v-date-picker
                            v-model="inputToDate"
                            :max="max_to_date"
                            :min="min_to_date"
                            no-title color="secondary"
                            @input="menuToDate = false"
                        ></v-date-picker>
                    </v-menu>
                    
                    
                </div>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="menuDateSelect = false">Cancel</v-btn>
                    <v-btn text color="" @click="APPLY_DATE()">Apply</v-btn>
                </v-card-actions> 
            </v-card>  
        </v-menu> 

        <!-- // login dialog -->
        <v-dialog v-model="dialog" tile persistent max-width="700px"> 
            <form @submit.prevent="LOGIN_USER()">
                <v-card  tile >
                    <v-card-title class="py-5 primary lighten-1 white--text">
                        <span class="headline">LOGIN</span>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog=!dialog" dark icon><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="mb-0 pb-0">
                        <v-container> 
                            <br> 
                            <v-layout row wrap >
                                <v-flex xs12 md4 >
                                    <v-layout class="mr-1"> 
                                        <v-autocomplete
                                            v-model="select.country"
                                            :items="countryNames" 
                                            solo outlined
                                            flat tile  
                                            height="58"
                                            label="Country" >
                                            <template 
                                                slot="selection"
                                                slot-scope="data" >
                                                <v-chip 
                                                    :input-value="data.selected"
                                                    :disabled="data.disabled"
                                                    :key="JSON.stringify(data.item)"
                                                    class="v-chip--select-multi"
                                                    
                                                    @input="data.parent.selectItem(data.item)" >
                                                    <v-avatar v-if="select.country!==''" 
                                                        class="mr-2" >
                                                            <span 
                                                                style="font-size:30px"   >
                                                                <flag :iso="select.country.split(':')[0]" />   
                                                            </span> 
                                                    </v-avatar>

                                                    {{ data.item.split(': ')[1] }}
                                                </v-chip> 
                                            </template>
                                        </v-autocomplete>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 md8>
                                    <v-text-field
                                        filled required
                                        id="phone_number"  
                                        v-model="inputPhone"
                                        append-icon="phone" 
                                        label="Phone number"></v-text-field> 
                                </v-flex> 
                                <v-flex xs12 >
                                    <v-text-field autocomplete="one-time-code"  
                                        filled required
                                        prepend-inner-icon="lock"
                                        v-model="input.password"
                                        :append-icon="psw1 ? 'visibility' : 'visibility_off'"
                                        @click:append="() => (psw1 = !psw1)"
                                        :type="psw1 ? 'password' : 'text'"
                                        name="input-10-1"
                                        label="Enter your password"
                                        hint="At least 8 characters"
                                        min="6" 
                                        counter></v-text-field>
                                </v-flex> 
                            </v-layout> 
                            <v-layout align-center>
                                <div>
                                    <v-btn 
                                        @click="onSignup()"
                                        text tile
                                        color="c1"><div>{{MBS.actions.TEXT_UP0("Don't have account")}}</div></v-btn> / 
                                    <v-btn 
                                        color="c1" 
                                        text tile
                                        @click="onForgotPassword()">forget password</v-btn>   

                                </div>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <div class="pl-3"> 
                        <v-subheader>Enter your <strong class="px-1">Kipcount Account</strong>  Credentials.</v-subheader>
                    </div> 
                    <v-card-actions class="pb-5 px-5">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text to="/signup" @click="dialog = false">Signup </v-btn>
                        <v-btn
                           :disabled="!GET_PHONE_NUMBER(true).valid"
                           :loading="inputLoading" 
                           type="submit" tile  
                           color="primary" class="px-5" 
                        >Login</v-btn>
                    </v-card-actions> 
                </v-card> 
            </form>
        </v-dialog> 
    </div>
</template> 
<script>
let NAME = 'DATE-FILTER'
import moment from 'moment'
export default { 
    props:['color','icon_color','title_color',
        'max_from_date','min_from_date','max_to_date','min_to_date',
        'default_days','optional_dates'], 
    data(){
        return{ 
            menuDateSelect:false,
            dialog:false,
            select:{
                signOption:'',
                accountType:'',
                businessType:'',
                country:''
            },
            psw1: true, 
            input:{ 
            },
            inputPhone:'',
            inputLoading:false,
            date:"17/01/2020 - 17/01/2020",
            inputFromDate:moment().subtract(5,'day').format('yyyy-MM-DD'),
            inputToDate:moment().format('yyyy-MM-DD'), 
            menuFromDate:false,
            menuToDate:false, 
            fromDate: null,
            toDate: null,
            difference: null,
        } 
    },
    created(){
        this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
        
    },
    mounted(){
        this.input={}
        this.inputLoading=false
        if (this.default_days) {
            let item = {from:this.default_days,from_type:"days"} 
            this.CHANGE_DATE(item)
        } 
        this.APPLY_DATE()
    },
    computed:{
        vs(){
            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        },
        us(){
            return this.$store.getters.getUser
        },
        isUS(){
            let us = this.us
            let ud = this.ud
            let us_uid = us?us.uid:null
            let ud_uid = us?ud.key:null
            if (!us_uid) {return null}
            if (!ud_uid) {return null}
            if (us_uid === ud_uid) {
                return true
            }else{
                return false
            }
        },
        ud(){
            return this.$store.getters.getUserDetails
        },
        loading(){  
            return this.MBS.actions.loading
        },
        processes(){  
            return this.MBS.actions.processes
        }, 
        responses(){  
            return this.MBS.actions.responses
        },
        moment(){ 
            return moment
        }, 
        lastDays(){ 
            return this.MBS.date.moment
        }, 
        OptionalDays(){ 
            let days = this.optional_days
            if (days) {
               return days 
            }
            return  [
                {name:"Today",days:0,from:"0",from_type:"days"},
                {name:"Last 7 Days",days:7,from:"7",from_type:"days"},
                {name:"Last 30 Days",days:30,from:"30",from_type:"days"},
                {name:"Last 3 Months",days:90,from:"90",from_type:"days"},
                {name:"Last 6 Months",days:180,from:"180",from_type:"days"},
                {name:"Last 12 Months",days:365,from:"12",from_type:"months"},
            ]
        }, 
    
        tb(){ 
            return this.$store.getters.getMBS_toolbar
        }, 
        links(){  
            return this.MBS.actions.WEB_LINKS
        }, 
        accountLinks(){
            let links = this.links
            if(!links){return null}
            let aLinks = links.filter(l=>{
                return l.important<1
            })

            return aLinks
        },
        countryNames(){
            let names = []
            let countries = this.$store.getters.getCountries 
            for (const key in countries) {
                 const cn = countries[key]
                if (cn.alpha2!== undefined) {
                    names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                } 
            } 
            //names=['Mw: Malawi (+265)']
            return names
        }, 
        iLocation(){ 
            const i = this.$store.getters.getIpLocation
            return i
        },
        GET_PHONE_NUMBER(){
            try {
                let us = this.us 
                let input_country = ''+this.select.country
                let input_phone = ''+this.inputPhone 
                let countryNames = this.countryNames 
                let PHONE = this.MBS.phone 
                return all =>{
                    let current_phone = ''+input_phone 
                    let ip_phone = null 
                    let ip_countryPhone = null 

                    if (input_phone) {
                        input_phone = input_phone.replace(/[^0-9+]/g,'')
                    }if (input_country) {
                        input_country = input_country.replace(/[^0-9]/g,'')
                    } 

                    if (input_phone) { 
                        ip_phone = PHONE(input_phone)
                    }if (input_country) { 
                        ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                    } 

                    if (ip_phone) {
                        let possible = ip_phone.a?ip_phone.a.possible:null
                        if (possible) {
                            current_phone = ip_phone.a

                            if (countryNames) { 
                                let regionCode = current_phone.regionCode
                                let countryName = countryNames.find(item=>{
                                    return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                })
                                if (countryName != this.select.country) {
                                    this.select.country = countryName
                                }
                                // console.log(this.select.country,'------',countryName);
                            }
                        }
                    }
                    if (ip_countryPhone) {
                        let possible = ip_countryPhone.a?ip_countryPhone.a.possible:null
                        if (possible) {
                            current_phone = ip_countryPhone.a
                        }
                    }else{

                    }

                    if (current_phone.valid) {
                        if (this.inputPhone != current_phone.number.international ) {
                            this.inputPhone = current_phone.number.international
                        }
                    } 
                    // console.log(input_phone,'input_phone.........');
                    // console.log(input_country,'input_country.........');  
                    // console.log(ip_countryPhone,'ip_countryPhone.........');
                    // console.log(ip_phone,'ip_phone.........');  
                    // console.log(current_phone,'current_phone.........'); 

                    if (all) {
                        return {
                            ...current_phone,
                            phone:current_phone.number?current_phone.number.e164:''
                        }
                    } else {
                        return current_phone.number?current_phone.number.e164:''
                    } 
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHECK_PHONE_NUMBER',
                    page:PAGE_NAME, 
                })
            }  
        },
         
    },
    methods:{
        DEFAULT(default_days){
            try { 
                if (!default_days) {
                    default_days = this.default_days
                }
                if (default_days) {
                    let item = {from:default_days,from_type:"days"} 
                    this.CHANGE_DATE(item)
                }    
                this.APPLY_DATE() 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_DATE',
                    page:NAME, 
                }) 
            }
            
        },
        APPLY_DATE(){
            try { 
                this.fromDate = this.inputFromDate
                this.toDate = this.inputToDate 
                this.difference = this.DIFF(this.fromDate,this.toDate)
                this.menuDateSelect = false  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_DATE',
                    page:NAME, 
                }) 
            }
            
        },
        CHANGE_DATE(item){
            try { 
                let from = item?item.from:null
                let from_type = item?item.from_type:null
                let to = item?item.to:null
                let to_type = item?item.to_type:null 
                if (from) {
                    this.inputFromDate = moment().subtract(from,from_type).format('yyyy-MM-DD') 
                }else {
                    this.inputFromDate = moment().format('yyyy-MM-DD') 
                }
                if (to) {
                    this.inputToDate = moment().subtract(to,to_type).format('yyyy-MM-DD') 
                }else {
                    this.inputToDate = moment().format('yyyy-MM-DD') 
                }
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_DATE',
                    page:NAME, 
                }) 
            }
            
        },
        DIFF(from,to,type="days"){
            try { 
                let a = moment(from)
                let b = moment(to)
                let diff = b.diff(a,type) 
                return diff
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHANGE_DATE',
                    page:NAME, 
                }) 
            }
            
        },
        //--------
        DIALOG_YES(action){ 
            if (action) {
                if (action.code ===NAME+"=ADD-SERVICE") {
                    if (this.input.check ) {
                        this.input.check = false 
                        this.$store.dispatch("fi_add",{
                            action:"Services",
                            path:"MBS/SETTINGS/Services/",
                            data:this.input
                        }) 
                    } 
                } 
                if (action.code ===NAME+"=REGISTER-TIMEOUT") {
                    
                }
                if (action.code ===NAME+"=GO-TO-PROFILE") {
                    this.MBS.actions.go('/profile')
                }
                if (action.code ===NAME+"=RELOAD-PAGE") {
                    this.RELOAD_PAGE()
                }
                if (action.code ===NAME+"=CANCEL-SIGNING") {
                    
                }
                
            }
        },  
         
         
    },
    watch:{ 
        us(value){ 
        },
        processes(value){ 
        },
        loading(value){ 
        }, 
        iLocation(value){ 
        },
        dialog(value){ 
            this.inputLoading = false
        },
        fromDate(value){ 
            this.$emit('input', {
                from_date:value,
                to_date:this.toDate,
                difference:this.difference,
            })
        },
        toDate(value){ 
            this.$emit('input', {
                from_date:this.fromDate,
                to_date:value,
                difference:this.difference,
            })
        },  
    }
}
</script> 