<template>
    <div>
        <div v-if="showInput">
            <input 
                type="file" id="fileInput" 
                ref="myFiles" 
                @change="FILE_SELECTED()"  
                style="display: none;"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                    application/vnd.ms-excel"> 
        </div>

        <v-dialog  
            v-model="notShow" 
            max-width="500"                     
            :persistent='true'  >
            <v-card class="" > 
                <v-progress-linear v-if="loading"
                    class="white ma-0 " 
                    :indeterminate="true"
                    height="5">
                </v-progress-linear>
    
                <v-card-title  
                    class="primary headline white--text"
                    primary-title >  
                    SELECT EXCEL FILES
                    <v-spacer></v-spacer>
                    <!-- side menu -->
                    <v-tooltip bottom  >
                        <template v-slot:activator="{ on1 }">
                            <v-menu   bottom left v-on="on1" >
                                <template v-slot:activator="{ on2 }">
                                    <v-btn v-on="on2" icon dark class="minWith">
                                        <v-icon >help</v-icon>
                                    </v-btn> 
                                </template>
                            </v-menu>
                        </template>
                        <span>Need Help Signing</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on3 }">
                            <v-btn v-on="on3" 
                                class ='ma-0' 
                                dark small
                                @click="onNotNo()"
                                icon>
                                <v-icon>close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                </v-card-title>
                <v-divider dark=""></v-divider>
               
                <v-card-text class="ma-0 pa-0" > 
                    <v-container  >
                        <!-- ON LOADING -->
                        <v-layout justify-center v-if="loading" class="pa-5">
                            <v-progress-circular
                                :rotate="-90"
                                :size="100"
                                :width="15"
                                :value="loadingValue"
                                color="primary"
                                >
                                {{ loadingValue }}
                                </v-progress-circular>
                        </v-layout>
                        <div v-else>
                            <!-- ON IN PUT -->
                            <div v-if="!files">
                                <v-card  @click="SELECT_EXCEL()" class="b2 ma-4"   flat="" tile="">
                                    <v-layout justify-center class="pa-5">
                                        <v-icon>attach_file</v-icon> 
                                        <div>
                                            SELECT EXCEL FILE
                                        </div>
                                    </v-layout>  
                                </v-card> 
                            </div>
                            <!-- ON SHOW RESULTS -->
                            <div v-else>
                                <v-list two-line>
                                    <template v-if="WB.Props">
                                        <v-subheader >
                                            File Details
                                        </v-subheader> 
                                        <v-list-item  
                                            @click=""
                                            >
                                            <v-list-item-icon>
                                                <v-icon>person</v-icon>
                                            </v-list-item-icon>
    
                                            <v-list-item-content>
                                                <v-list-item-title v-html="'Author'"/> 
                                                <v-list-item-subtitle v-html="WB.Props.Author?WB.Props.Author:'.'"/> 
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item  
                                            @click=""
                                            >
                                            <v-list-item-icon>
                                                <v-icon>person</v-icon>
                                            </v-list-item-icon>
    
                                            <v-list-item-content>
                                                <v-list-item-title v-html="'Modified by'"/> 
                                                <v-list-item-subtitle v-html="WB.Props.LastAuthor?WB.Props.LastAuthor:'.'"/> 
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item  
                                            @click=""
                                            >
                                            <v-list-item-icon>
                                                <v-icon>description</v-icon>
                                            </v-list-item-icon>
    
                                            <v-list-item-content>
                                                <v-list-item-title v-html="'File Type'"/> 
                                                <v-list-item-subtitle v-html="WB.Props.Application?WB.Props.Application:'.'"/> 
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item  
                                            @click=""
                                            >
                                            <v-list-item-icon>
                                                <v-icon>schedule</v-icon>
                                            </v-list-item-icon>
    
                                            <v-list-item-content>
                                                <v-list-item-title v-html="'Date'"/> 
                                                <v-list-item-subtitle v-html="WB.Props.ModifiedDate?WB.Props.ModifiedDate:'.'"/> 
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-if="WB.SheetNames">
                                        <v-subheader >
                                            attached Sheets
                                        </v-subheader> 
                                        <v-list-item v-for="(item, index) in WB.SheetNames" :key="index" 
                                            @click=""
                                            >
                                            <v-list-item-icon>
                                                <v-icon>attach_file</v-icon>
                                            </v-list-item-icon>
    
                                            <v-list-item-content>
                                                <v-list-item-title v-html="'Sheet '+(index+1)"/> 
                                                <v-list-item-subtitle v-html="item"/> 
                                            </v-list-item-content>
                                        </v-list-item> 
                                    </template>
                                </v-list>
    
                                <v-card-actions  >
                                    <v-spacer></v-spacer>
                                    <v-btn  
                                        @click="onNotNo"
                                        color="blue" 
                                        text="">Cancel</v-btn>
                                    <v-btn  
                                        @click="onNotYes"
                                        color="blue" dark=""
                                        >Select File</v-btn>
                                </v-card-actions>
    
                            </div> 
                        </div> 
                    </v-container>  
                </v-card-text> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import XLSX from "xlsx" 
    let PAGE_NAME = "EXCEL-SELECTOR"
    export default {
        props:['notShow'],
        data(){
            return{
                show:false,
                showInput:true,
                loading:false,
                loadingValue:10,
                files: null,  
                WB:null
            } 
        },  
        created() {
            try {
                this.MBS.events.$on('SELECT_EXCEL', this.SELECT_EXCEL);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){
            try {
                this.files=null 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
        },
        computed:{
            user(){
                return  this.$store.getters.getUser
            }
        },
        methods:{ 
            onNotNo(){
                this.$emit('notNo')
                this.files = null 
            }, 
            onNotYes(){
                this.$emit('notYes',this.WB)  
            },
            SELECT_EXCEL(){
                try { 
                    if (this.$refs.myFiles.files.length>0) {
                        this.showInput = false
                        setTimeout(() => {
                            this.showInput = true
                            setTimeout(() => {
                                this.$refs.myFiles.click()  
                            }, 10);
                        }, 50);
                    }else{
                        this.$refs.myFiles.click() 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'created',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            async FILE_SELECTED(){
                try {
                    this.$emit("onLoadingFile",null)
                    let files = this.$refs.myFiles.files
                    this.files = files
                    this.loading = true
                    this.loadingValue = 10 
                    
                    if (files.length==0) {
                        this.files = null
                        setTimeout(() => {
                            this.loadingValue = 100 
                        }, 500);
                        setTimeout(() => { 
                            this.MBS.actions.snackbar({
                                show:true,
                                time:10000,
                                text:'Not Selected',
                                color:'primary'
                            })
                            this.loading = false 
                            this.$emit("onNoFileSelected",null)
                        }, 1000); 
                    }else{
                        let file_details = files[0] 
                        let reader = new FileReader()
                        reader.readAsArrayBuffer(this.files[0]) 
                        reader.onload = async ()=>{
                            try {
                                this.loadingValue = 100
                                setTimeout(() => {
                                    // this.MBS.actions.snackbar({
                                    //     show:true,
                                    //     time:5000,
                                    //     text:'Successfully Selected',
                                    //     color:'primary'
                                    // }) 
                                    this.loading = false 
                                }, 1000);
        
                                let data = new Uint8Array(reader.result)
                                let wb = XLSX.read(data,{type:"array"})
                                this.WB = wb
                                let res = await this.CALCULATE_JSON(wb) 
                                this.$emit("onFileSelected",wb,file_details,res)  
                            } catch (error) {
                                this.MBS.actions.error({
                                    error:error,
                                    from:'FILE_SELECTED',
                                    page:PAGE_NAME, 
                                }) 
                                this.$emit("onError",error)
                            }
                        } 
                    }   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FILE_SELECTED',
                        page:PAGE_NAME, 
                    }) 
                    this.$emit("onError",error)
                }
            }, 
            CALCULATE_JSON(wb){
                return new Promise((resolve,reject)=>{
                    let response = {
                        from:'CALCULATE_JSON',
                        page:PAGE_NAME, 
                        payload:wb
                    }
                    try { 
                        let sheets = wb?wb.SheetNames:null
                        if(!sheets){
                            resolve({
                                success:false,
                                data:null
                            })
                        }
                        let json_sheets = []
                        sheets.forEach(element => {
                            let worksheet_item = wb.Sheets[element]
                            if (worksheet_item) {
                                let json_item = this.XLSX.utils.sheet_to_json(worksheet_item) 
                                if (json_item) {
                                    json_sheets.push({
                                        name:element,
                                        json:json_item
                                    })
                                }
                            } 
                        }); 
                        resolve({
                            success:true,
                            data:json_sheets
                        })
                    } catch (error) {
                        reject({
                            success:false,
                            error:error,
                            ...response
                        })
                    } 
                })
            },
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('SELECT_EXCEL', this.SELECT_EXCEL);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{
            user(value){  
            } 
            
        } 
    }
</script>
