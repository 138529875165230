let NAME = "NUMBER-F"

const REGEX_UNWANTED_CHARACTERS = /[^\d\-.,]/g
const REGEX_DASHES_EXEPT_BEGINNING = /(?!^)-/g
const REGEX_PERIODS_EXEPT_LAST = /\.(?=.*\.)/g

const formatNumber = (number) => {
  if (typeof number === 'string') {
    // this is a string
    if (number.includes(',') && !number.includes('.') ) {
      number = number+".00"
    }
  }else {
    number = number+""
  }
  // Handle exponentials
  if ((number.match(/e/g) ?? []).length === 1) {
    const numberParts = number.split('e')
    return `${formatNumber(numberParts[0])}e${formatNumber(numberParts[1])}`
  }

  const sanitizedNumber = number
    .replace(REGEX_UNWANTED_CHARACTERS, '')
    .replace(REGEX_DASHES_EXEPT_BEGINNING, '')

  // Handle only thousands separator
  if (
    ((sanitizedNumber.match(/,/g) ?? []).length >= 2 && !sanitizedNumber.includes('.')) ||
    ((sanitizedNumber.match(/\./g) ?? []).length >= 2 && !sanitizedNumber.includes(','))
  ) {
    return sanitizedNumber.replace(/[.,]/g, '')
  }

  return sanitizedNumber.replace(/,/g, '.').replace(REGEX_PERIODS_EXEPT_LAST, '')
} 
const formatNumberToNumber =(number) => {
  if (!number) {
      return 0
  }
  let return_number = Number(formatNumber(number))
  return isNaN(return_number)?0:return_number
}
const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",")=>{
  try {
      amount = formatNumberToNumber(amount) 
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (error) {
      // this.error({
      //     error:error,
      //     from:'money',
      //     page:NAME, 
      // })
    console.log(error,'formatMoney...');
  }
}
const formatSameLength = (number,size)=>{ 
  try {
      if (!number) {
          number = '0'
      }
      return number.toString().padStart(size?size:7, "0")   
  } catch (error) {
      this.error({
          error:error,
          from:'NUMBER_SAME_LENGTH',
          page:NAME, 
      })
  }
}

module.exports = {
    toNumber:formatNumberToNumber,
    toMoney:formatMoney,
    toSameLength:formatSameLength
};