<template> 
  <svg id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 42.14 41.57"
  :fill="iconColor" xml:space="preserve">

   <path d="M21.75,41.25l.41-.17,19.42-9.71a.58.58,0,0,0,.37-.63q0-9.51,0-19V11.2l-.49.23L22.2,21a.69.69,0,0,0-.46.72c0,6.33,0,12.65,0,19Z"/><path d="M14,18.17v7.12a.84.84,0,0,1-.49.87.81.81,0,0,1-.95-.27c-.34-.35-.68-.69-1-1-.59-.58-1.09-1.37-1.8-1.66a6.59,6.59,0,0,0-2.4-.08c-.86,0-1.1-.23-1.1-1.08,0-2.45,0-4.89,0-7.33a.6.6,0,0,0-.39-.63C4,13.19,2.23,12.28.42,11.38c-.12-.06-.25-.1-.39-.16,0,.1,0,.15,0,.2q0,9.71,0,19.41c0,.34.17.43.41.55L19.7,41l.47.22a1.42,1.42,0,0,0,0-.21q0-9.7,0-19.41a.69.69,0,0,0-.31-.5c-.78-.43-1.59-.81-2.38-1.21Z"/><path d="M41,9.93l-.48-.27c-1.92-1-3.84-1.91-5.76-2.89a.75.75,0,0,0-.8,0l-18.6,9.89c-.11.05-.21.12-.33.19a1,1,0,0,0,.13.11c1.88.95,3.75,1.89,5.64,2.81a.74.74,0,0,0,.56-.05l17-8.48Z"/><path d="M26.32,2.63a1,1,0,0,0-.13-.11c-1.66-.83-3.32-1.67-5-2.48a.7.7,0,0,0-.53,0L1.36,9.72c-.1,0-.19.13-.33.22,1.86.94,3.67,1.83,5.47,2.75a.92.92,0,0,0,1,0Q16.68,7.77,25.9,2.88C26,2.81,26.17,2.73,26.32,2.63Z"/><path d="M32.58,5.71c-1.4-.7-2.72-1.34-4-2a.84.84,0,0,0-.89,0q-7.45,4-14.92,8l-4,2.13L9.2,14c1.19.6,2.4,1.19,3.58,1.81a.81.81,0,0,0,.85,0L32.1,6Z"/><path d="M7.8,21.57a14.16,14.16,0,0,0,1.46,0,2,2,0,0,1,2,.81c.32.41.72.76,1.08,1.14.1-2,.11-4,.09-5.93,0-.12-.18-.29-.32-.36L9.2,15.77l-1.4-.69Z"/>
  </svg>

</template>

<script>
  export default {
    props: { 
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }, 
      
  }
</script>
 
