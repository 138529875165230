<template>
  <v-layout column height="600" class="">  
    <v-divider></v-divider>  
    <mbs-page-tab 
        :items="OFFICE_LINKS"/>  
    <v-divider></v-divider>  
    <v-divider></v-divider>  
    <v-divider></v-divider>  
    <v-layout v-if="false"> 
        <mbs-page-spinner :show="true" />  
    </v-layout>     
    <v-container v-else grid-list-lg> 
        <v-layout row wrap class="px-4 py-5"> 
            <!-- //MANAGE ITEMS -->
            <v-flex xs6 sm4 lg3 v-for="(link, index) in FULL_LINKS" :key="'full_'+index">
                <v-hover >  
                    <template v-slot="{ hover  }">
                        <v-card   hover :to="link.link2?link.link2:link.link" 
                            rounded="xl"  :color="link.image_background_color"  
                            height="100%" class="pa-2">   
                            <v-layout column fill-height class="ma-0">
                                <v-spacer></v-spacer>
                                <v-responsive aspect-ratio="1.1"  >
                                    <v-layout column fill-height align-center justify-center>
                                        <v-icon color="white" size="50">{{link.icon}}</v-icon>
                                        <div class="font-weight-bold white--text text--darken-1 not-f7 text-center">{{link.name}}</div>
                                    </v-layout>
                                </v-responsive>    
                            </v-layout>
                        </v-card> 
                    </template>
                </v-hover>
            </v-flex>

             
            <v-flex xs6 sm4 lg3 v-for="(link, index) in MY_LINKS" :key="index">
                <v-hover v-if="true">
                    <template v-slot="{ hover  }">
                        <v-card color="b2" 
                            :elevation="hover ?12:''"
                            :to="MBS.actions.COMPANY_LINK('/stock/manage/'+link.code,true)"
                            rounded="xl"  hover
                            height="100%">  

                            <!--Not my profile--> 
                            <v-responsive  aspect-ratio="1.4"  >
                                <v-card height="100%" 
                                    :color="link.image_background_color?link.image_background_color:'primary lighten-4'"  
                                    rounded="xl">
                                    <v-layout class="ma-0" align-center justify-center fill-height > 
                                        <v-card 
                                            color="transparent" 
                                            :width="link.image_size?link.image_size+'%':'50%'"
                                            
                                             tile flat>
                                            <v-layout fill-height v-if="link.images">
                                                <v-img  
                                                    position="center"
                                                    :src="link.image?link.image:MBS.data.noImg" > 
                                                    <template v-slot:placeholder>
                                                        <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                            ></v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>  
                                            </v-layout>
                                            <v-layout v-else fill-height justify-center align-center>
                                                <v-icon size="60" v-if="link.outlet_type_code == 'warehouse'">mdi-warehouse</v-icon>
                                                <v-icon size="60" v-else>mdi-store</v-icon>
                                            </v-layout>
                                        </v-card>
                                    </v-layout>  
                                </v-card>
                            </v-responsive> 
                            <v-spacer></v-spacer> 
                            <v-card-text class="py-2"> 
                                <small class="secondary--text font-weight-bold">{{link.outlet_type_name}}</small> 
                                <div class="font-weight-bold not-f5" >{{link.name}}</div>
                            </v-card-text>
                        </v-card> 
                    </template>
                </v-hover>
            </v-flex>
        </v-layout>  
        <div>
            <mbs-item-manager
                :show="false" 
                :name_items="NAME_ITEMS"
                :name_item="NAME_ITEM"
                :value_items="VALUE_ITEMS"
                :value_item="VALUE_ITEM"
                :field_items="DATA.FIELDS.money_accounts"   
                :path_item="MoneyAccountsPath"  
                />
                <!-- :select_items="SelectInputFieldsItems"    -->
        </div>
    </v-container>  
  </v-layout> 
</template>

<script>
import DATA from '../../../../plugins/DATA'  
// import { MongoClient } from 'mongodb';
// const uri = 'mongodb://localhost:27017/myapp';
// import MongoDB from '../../../../plugins/MongoDB';
import {mapState} from "vuex"
let PAGE_NAME = DATA.ITEMS.MONEY_ACCOUNTS.values 
    export default {
        name:PAGE_NAME, 
        data() {
        return {
            PAGE_SHOW:false, 
            NAME_ITEMS:DATA.ITEMS.MONEY_ACCOUNTS.names,
            NAME_ITEM:DATA.ITEMS.MONEY_ACCOUNTS.name,
            VALUE_ITEMS:DATA.ITEMS.MONEY_ACCOUNTS.values,
            VALUE_ITEM:DATA.ITEMS.MONEY_ACCOUNTS.value,  
            DATA:DATA, 
        }
        },
        mounted() {
            // console.log(MongoDB,'.....');


            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500) 
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
             
            /////////
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            }),  
            StockOutletsData(){ 
                let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets 
                return items
            },
            
            MY_LINKS(){  
                let outlets = this.StockOutletsData 
                if(!outlets){return null}
                let allLinks = []
                outlets.forEach(element => {
                    allLinks.push({
                        ...element,
                        name:element.name+" Stock",
                        link:this.MBS.actions.COMPANY_LINK("/stock/stock-items/"+element.code,true),
                        icon:'link',
                        // image:'/future_tours/svg/SVG/my_parcel.svg',
                        image_background_color:'primary lighten-2',
                        image_size:'80',
                        
                    })
                });  
                return allLinks
            }, 
            OFFICE_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            },
            FULL_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("manage",2,true) 
                console.log(thisLinks,'thisLinks...');
                return thisLinks
            },
            MoneyAccountsPath(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.money_accounts,true) 
                return path
            }, 
        },
        methods: { 
            clicked(){},
            ADD_MONEY_ACCOUNT(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.MONEY_ACCOUNTS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_MONEY_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ACCOUNT(item){
                try {     
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"edit_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ARCHIVE_ACCOUNT(item){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"archive_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ARCHIVE_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETE_ACCOUNT(item){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"delete_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            VIEW_ACCOUNT(item){
                try {    
                    this.MBS.actions.go(item.link)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            //LOAD
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 
