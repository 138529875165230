import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors' 
import 'material-design-icons/iconfont/material-icons.css' 
import '@mdi/font/css/materialdesignicons.css' 
import '@mdi/font/scss/materialdesignicons.scss' 
import 'font-awesome/css/font-awesome.min.css'
 

import bus_seat from './mbs-icons/bus-seat.vue'
import bus_seat_outlined from './mbs-icons/bus-seat-outlined.vue'
import click_malawi from './mbs-icons/click-malawi.vue'
import mbs from './mbs-icons/mbs.vue'
import mbs_color from './mbs-icons/mbs_color.vue'

import parcel from './mbs-icons/parcel.vue'
import parcel_gift from './mbs-icons/parcel_gift.vue'
import parcel_order from './mbs-icons/parcel_order.vue'
import parcel_delivered from './mbs-icons/parcel_delivered.vue'
import parcel_document from './mbs-icons/parcel_document.vue'
import parcel_fragile from './mbs-icons/parcel_fragile.vue'
import parcel_loaded from './mbs-icons/parcel_loaded.vue'
import parcel_loading from './mbs-icons/parcel_loading.vue'
import parcel_move from './mbs-icons/parcel_move.vue'
import parcel_move_more from './mbs-icons/parcel_move_more.vue'
import parcel_non_fragile from './mbs-icons/parcel_non_fragile.vue'
import parcel_offloading from './mbs-icons/parcel_offloading.vue'
import parcel_receiving from './mbs-icons/parcel_receiving.vue'
import parcel_sending from './mbs-icons/parcel_sending.vue'
import parcel_weighing from './mbs-icons/parcel_weighing.vue' 
import bus from './mbs-icons/bus.vue' 
import bus_on_road from './mbs-icons/bus_on_road.vue'  


import master_card from './mbs-icons/master_card.vue' 
import master_card2 from './mbs-icons/master_card2.vue' 
import visa from './mbs-icons/visa.vue' 

import kipcount_pos from './mbs-icons/kipcount_pos.vue' 

import printer_pos_add from './mbs-icons/printer_pos_add.vue'  
import printer_pos_alert from './mbs-icons/printer_pos_alert.vue'  
import printer_pos_off from './mbs-icons/printer_pos_off.vue'  
import printer_pos_pause from './mbs-icons/printer_pos_pause.vue'  
import printer_pos_play from './mbs-icons/printer_pos_play.vue'  
import printer_pos_plus from './mbs-icons/printer_pos_plus.vue'  
import printer_pos_remove from './mbs-icons/printer_pos_remove.vue'  


Vue.use(Vuetify); 

export default new Vuetify({
    icons: {
        iconfont: 'mdi', 
        values: {
            
            'click-malawi': { // name of our custom icon
                component: click_malawi, // our custom component
            },
            'bus-seat': {component: bus_seat},
            'bus-seat-outlined': {component: bus_seat_outlined},
            'mbs': {component: mbs},
            'mbs-color': {component: mbs_color},
            'parcel': {component: parcel},
            'parcel-gift': {component: parcel_gift},
            'parcel-order': {component: parcel_order},
            'parcel-delivered': {component: parcel_delivered}, 
            'parcel-document': {component: parcel_document}, 
            'parcel-fragile': {component: parcel_fragile}, 
            'parcel-loaded': {component: parcel_loaded}, 
            'parcel-loading': {component: parcel_loading}, 
            'parcel-move': {component: parcel_move}, 
            'parcel-move-more': {component: parcel_move_more}, 
            'parcel-non-fragile': {component: parcel_non_fragile}, 
            'parcel-offloading': {component: parcel_offloading}, 
            'parcel-receiving': {component: parcel_receiving}, 
            'parcel-sending': {component: parcel_sending}, 
            'parcel-weighing': {component: parcel_weighing},  
            'bus': {component: bus},  
            'bus-on-road': {component: bus_on_road},  

            'master-card': {component: master_card},  
            'master-card2': {component: master_card2},  
            'visa': {component: visa},  

            'kipcount-pos': {component: kipcount_pos},  

            'printer-pos-add': {component: printer_pos_add},  
            'printer-pos-alert': {component: printer_pos_alert},  
            'printer-pos-off': {component: printer_pos_off},  
            'printer-pos-pause': {component: printer_pos_pause},  
            'printer-pos-play': {component: printer_pos_play},  
            'printer-pos-plus': {component: printer_pos_plus},  
            'printer-pos-remove': {component: printer_pos_remove},  
        },
    },
    theme: {
        themes: {
            light: {
                primary: "#024959", 
                primaryDark: "#024959",
                secondary: "#049DBF",
                accent: "#F2D6B3", 
                error: colors.red.accent3,
                text_color: colors.grey.base,
                text_color1: colors.grey.darken1,
                text_color2: colors.grey.darken2,
                footer_bg: '1E1E1E',
                b1:"#f8f8f8",
                b2:"#bfc0c2",
                c1:"#362C21",
                c2:"#CF7817",
                c3:"#9C3E21",
                c4:"#749BA8",
                c5:"#0E7D21",
                c6:"#BC0030",
                nav: "#364150",
                footer: "#1E1E1E"
            },
            dark: {
                primary: colors.blue.lighten3,
            },
        },
    },
});

// Vue.directive('click-outside', {
//     bind () {
//         this.event = event => this.vm.$emit(this.expression, event)
//         this.el.addEventListener('click', this.stopProp)
//         document.body.addEventListener('click', this.event)
//     },   
//     unbind() {
//       this.el.removeEventListener('click', this.stopProp)
//       document.body.removeEventListener('click', this.event)
//     },
  
//     stopProp(event) { event.stopPropagation() }
// })
